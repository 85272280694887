import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveDespositionMutation } from "../../../../Services/general/General";
import { Loader } from "react-bootstrap-typeahead";
import { useAddSubTeleDispositionMutation } from "../../../../Services/Telephony/Telephony";

interface IProps {
    closeUpdateModal: () => void;
    setData: (data: any) => void;
    data: any;
    currentNode: any;
    SetLiftingAddDate: (data: any) => void;
    selectedTeam: any;
    refetch: any;
    dispositionId: any;
    parentNode:any;
}

const UpdateTeleDisposition: React.FC<IProps> = ({
    closeUpdateModal,
    setData,
    data,
    currentNode,
    SetLiftingAddDate,
    selectedTeam,
    refetch,
    dispositionId,
    parentNode
}) => {
    const [topic, setTopic] = useState(currentNode?.disposition || "");
    const [subDispositions, setSubDispositions] = useState(
        parentNode.sub_disposition || []
    );
   
    console.log(subDispositions,"subDispositions")
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [trigger, { isLoading }] = useAddSubTeleDispositionMutation();
    const [updatedDispo, setUpdatedDispo] = useState("");

    // Handle input change for the main topic
    const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTopic(event.target.value);
    };

    // Handle input change for a sub-disposition
    // const handleSubDispositionChange = (
    //     index: number,
    //     event: React.ChangeEvent<HTMLInputElement>
    // ) => {
    //     // const updatedSubDispositions = [...subDispositions];
    //     // updatedSubDispositions[index] = event.target.value;
    //     // setSubDispositions(updatedSubDispositions);
    //     setUpdatedDispo (event.target.value);
    //     console.log(setUpdatedDispo,"setSubDispositions")
    // };
const handleSubDispositionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
) => {
    const updatedSubDispositions = [...subDispositions];
    updatedSubDispositions[index].name = event.target.value;
    setSubDispositions(updatedSubDispositions);
};


    // Add a new sub-disposition
    // const addSubDisposition = () => {
    //     setSubDispositions([...subDispositions, ""]); // Add an empty string for the new sub-disposition
    // };
    const addSubDisposition = () => {
        setSubDispositions([...subDispositions, { id: null, name: "" }]); // Add as object
    };
    // Remove a sub-disposition
    // const removeSubDisposition = (index: number) => {
    //     const updatedSubDispositions = subDispositions.filter(
    //         (_, i) => i !== index
    //     );
    //     setSubDispositions(updatedSubDispositions);
    // };

    const removeSubDisposition = (index: number) => {
        const updatedSubDispositions = subDispositions.filter((_, i) => i !== index);
        setSubDispositions(updatedSubDispositions);
    };
    const removeLevelProperty = (node: any) => {
        const { level, ...rest } = node;
        if (rest.sub_disposition && rest.sub_disposition.length > 0) {
          rest.sub_disposition = rest.sub_disposition.map((subNode: any) =>
            removeLevelProperty(subNode)
          );
        }
        return rest;
      };
      
      const updateNodeDisposition = (
        nodes: any[],
        level: string,
        newDisposition: string
      ): any[] => {
        return nodes.map((node) => {
          if (node.level === level) {
            const updatedNode = { ...node, disposition: newDisposition };
            return removeLevelProperty(updatedNode); // Remove level from updated node
          } else if (node.sub_disposition) {
            const updatedNode = {
              ...node,
              sub_disposition: updateNodeDisposition(node.sub_disposition, level, newDisposition),
            };
            return removeLevelProperty(updatedNode); // Remove level from non-updated nodes
          } else {
            return removeLevelProperty(node); // Remove level from leaf nodes
          }
        });
      };

      const handleTopic = async () => {
        setIsSubmitting(true); // Disable multiple submissions
    
        try {
            // Update parentNode with the modified sub_disposition
            const updatedParentNode = {
                ...parentNode,
                disposition: parentNode.id === currentNode.id ? topic.trim() : parentNode.disposition, // Update 'disposition' if parent
                sub_disposition: [
                    ...parentNode.sub_disposition.map((sub) =>
                        sub.id === currentNode.id
                            ? { ...sub, name: topic.trim() } // Replace existing node
                            : sub
                    ),
                    ...subDispositions.filter((sub) => !sub.id), // Add new sub-dispositions (with no ID)
                ],
            };
    
            // Construct the payload for API request
            const payload = {
                id: parentNode.id, // Parent Node ID
                disposition: updatedParentNode.disposition, // Current disposition
                sub_disposition: updatedParentNode.sub_disposition.map((sub) => ({
                    id: sub.id, // Keep existing IDs
                    name: sub.name.trim(), // Trimmed names for sub-dispositions
                })),
                team: selectedTeam, // Associated team data
                type: parentNode.type, // Parent node type
            };
    
            // Trigger the API update call
            const response = await trigger({ body: payload, id: parentNode.id });
    
            if (response?.data) {
                toast.success("Disposition Updated Successfully!");
                refetch(); // Refresh the data to reflect updates
                closeUpdateModal(); // Close the modal
            } else {
                throw new Error("Failed to update disposition");
            }
        } catch (error) {
            toast.error("Error during update: " + error.message);
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    };
    
useEffect(() => {
    console.log("Parent Node:", parentNode);
    console.log("Current Node:", currentNode);
    console.log("SubDispositions:", subDispositions);
}, [parentNode, currentNode, subDispositions]);

    useEffect(() => {
        if (data) {
            SetLiftingAddDate(data); // Use the original data with levels intact
        }
    }, [data]);

    // Determine if the "Update" button should be disabled
    const isUpdateButtonDisabled = !topic.trim() || isSubmitting;

    return (
        <div>
            <div style={{ maxHeight: "25vh", overflow: "scroll" }}>
                <div className="mt-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Topic"
                        value={topic}
                        onChange={handleTopicChange}
                        disabled={isSubmitting} // Disable input while submitting
                    />
                </div>

              
                {/* If there are sub-dispositions, render them as editable fields */}
                {/* {subDispositions.length > 0 && (
    <div className="mt-4">
        <h5>Sub Dispositions</h5>
        {subDispositions.map((sub, index) => (
            <div
                key={sub.id || index} // Use `id` if available, fallback to index
                className="d-flex align-items-center mb-2"
            >
                <input
                    type="text"
                    className="form-control me-2"
                    placeholder={`Sub Disposition ${index + 1}`}
                    value={sub.name} // Access `name` property
                    onChange={(event) => handleSubDispositionChange(index, event)}
                    disabled={isSubmitting}
                />
                <button
                    className="btn btn-sm btn-danger"
                    onClick={() => removeSubDisposition(index)}
                    disabled={isSubmitting}
                >
                    Remove
                </button>
            </div>
        ))}
    </div>
)} */}

                {/* Button to add a new sub-disposition */}
               
            </div>

            <div className="d-flex justify-content-end mt-4">
                <button
                    type="reset"
                    className="btn btn-sm btn-white  me-2"
                    onClick={closeUpdateModal}
                    disabled={isSubmitting}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleTopic}
                    disabled={isUpdateButtonDisabled}
                >
                    {isLoading ? (
                        <>
                            Saving... <Loader />
                        </>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </div>
    );
};

export default UpdateTeleDisposition;
