import { format } from "date-fns";
import React, { useRef, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const Sent = ({ intervalData }) => {
  const chartRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  const emailResults = intervalData?.results || [];
  const hours = Array.from({ length: 24 }, (_, i) => i.toString());
  
  const series = emailResults.map(result => ({
    name: format(new Date(result.date), "dd-MM-yyyy"),
    data: hours.map(hour => result.hourly_count[hour] || 0),
  }));

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = async () => {
    try {
      if (!document.fullscreenElement && chartRef.current) {
        await chartRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error("Fullscreen mode failed", error);
    }
  };

  const options:any = {
    chart: {
      type: "line",
      height: 600,
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            {
              icon: isFullscreen
                ? '<i class="bi bi-fullscreen-exit"></i>'
                : '<i class="bi bi-arrows-fullscreen"></i>',
              title: isFullscreen ? "Exit Fullscreen" : "Fullscreen",
              class: "custom-fullscreen-icon mx-2",
              click: toggleFullscreen,
            },
          ],
        },
        export: {
          svg: { filename: "sent_email" },
          png: { filename: "send_email" },
          csv: { filename: "sent_email" },
        },
      },
    },
    xaxis: {
      type: "category",
      categories: hours,
      title: {
        text: "Hour of Day",
        style: { fontWeight: "normal" },
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    yaxis: {
      title: {
        text: "Sent Email Count",
        style: { fontWeight: "normal" },
      },
      labels: {
        formatter: function (val) {
          return Math.floor(val);
        },
      },
      min: 0,
      forceNiceScale: true,
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (val) {
          return `Hour: ${val}`;
        },
      },
    },
    colors: [
      "#FF5733", "#33FF57", "#3357FF", "#F39C12", "#8E44AD", "#FFFF00", "#3498DB",
    ],
  };

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: isFullscreen ? "100vh" : "600px",
        backgroundColor: "white",
        padding: isFullscreen ? "20px" : "0",
      }}
    >
      <ReactApexChart options={options} series={series} type="line" height="100%" />
    </div>
  );
};

export default Sent;
