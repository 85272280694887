import { useParams, useNavigate } from "react-router-dom"; // Updated import
import TicketLeftSideMenuCard from "../../../components/ticket/TicketLeftSideMenuCard";
import TicketRightSideMenu from "../../../components/ticket/TicketRightSideMenu";
import TicketPaneOpen from "../../../components/ticket/TicketPaneOpen";
import TicketListInfoCard from "../../../components/ticket/TicketListInfoCard";
import { useDetailTicketQuery } from "../../../Services/ticket/TicketDetail";
import { useEffect, useState,useRef } from "react";
import ReactModal from "react-modal";
import CreateTaskModal from "../../../components/ticket/ticketListInfo/CreateTaskModal";
import { useCreateTaskMutation } from "../../../Services/ticket/CreateTask";
import { useTaskQuery } from "../../../Services/ticket/Task";
import {
    useAuditTrailQuery,
    useGetForwardedAuditTrailCountQuery,
} from "../../../Services/ticket/Audit-Trail";
import CreateChildTicket from "../../../components/ticket/CreateChildTicket";
import PrevNextAction from "../../../components/common/PrevNextAction";
import { useTicketPaginationQuery } from "../../../Services/ticket/TicketList";
import { INCOMING_CALL } from "../../../constants";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { toast } from "react-toastify";
const TicketListInfo = () => {
    const params = useParams();
    const uuid = params.uuid;
    const [currentTicketId, setCurrentTicketId] = useState(uuid);
    const { data, refetch } = useDetailTicketQuery(currentTicketId);
    const [liftingKey, setLiftingKey] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState<any>(() => {
        const savedPage = sessionStorage.getItem("ticketCurrentPage");
        return Number(savedPage);
    });
    const [liftUiData, setLiftUiData] = useState(0);
    const [value, setValue] = useState(liftUiData ? "active" : "All");
    const [selectedWorkFlow, setselectedWorkFlow] = useState(
        sessionStorage.getItem("selectedWorkFlow") || ""
    );
    const [selectedTeamId, setSelectedTeamId] = useState(() => {
        const selectedTeamIdFromSession =
            sessionStorage.getItem("selectedTeamId");
        const userRole = localStorage.getItem("user_role");
        const userId = localStorage.getItem("User_Id");

        if (selectedTeamIdFromSession) {
            return selectedTeamIdFromSession;
        }

        if (userRole === "admin") {
            return "My Team";
        }

        if (userRole === "agent" || userRole === "manager") {
            return userId;
        }

        return null; // Fallback value if none of the conditions are met
    });
    const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>(
        () => {
            const sessionData = sessionStorage.getItem(
                "selectedFilterAndValue"
            );
            return sessionData
                ? JSON.parse(sessionData)
                : {
                      action__contact__email__icontains: "",
                      action__assignee__username__icontains: "",
                      title__icontains: "",
                      created: "",
                      id__icontains: "",
                      merchant_id: "",
                  };
        }
    );
    const initialStoreKey =
        sessionStorage.getItem("storeKey") || (liftUiData ? "active" : "All");
    const [storeKey, setStoreKey] = useState(initialStoreKey);
    const callActive = localStorage.getItem(INCOMING_CALL) === "true";
    const [direction, setDirection] = useState(null);
    const [isTicketLoading, setIsTicketLoading] = useState(false);

    const [createTask] = useCreateTaskMutation({});
    const {
        data: taskData,
        refetch: refetchTask,
        isLoading,
    } = useTaskQuery(uuid);

    const { data: auditTrailData, refetch: refetchAuditTrailData } =
        useAuditTrailQuery(uuid);

    const {
        data: auditTrailForwardedData,
        refetch: refetchAuditTrailForwardedData,
    } = useGetForwardedAuditTrailCountQuery(uuid);
    const { data: fetchedData, refetch: refetchTicket } =
        useTicketPaginationQuery(
            {
                currentPage,
                value,
                selectedWorkFlow,
                selectedTeamId,
                selectedFilterAndValue1,
                liftingKey,
                storeKey,
            },
            { skip: !selectedWorkFlow || callActive }
        );

    const navigate = useNavigate(); // Initialize useNavigate

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isChildTicketModalOpen, setIsChildTicketModalOpen] =
        useState<boolean>(false);
    const [getAllStatus, setGetAllStatus] = useState<any>([]);
    const [paneOpen, setPaneOpen] = useState<String>("LeadInformation");

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "550px",
        },
    };
    const customModalStyles1 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Default to full width
            maxWidth: "850px", // Max width for larger screens
            height: "80%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const afterOpenModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openChildTicketModal = () => {
        setIsChildTicketModalOpen(true);
    };
    const afterOpenChildTicketModal = () => {
        // subtitle.style.color = '#f00'
    };
    const closeChildTicketModal = () => {
        setIsChildTicketModalOpen(false);
    };
    const handleBackButtonClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const handleButtonClick = async (event, direction) => {
        event.preventDefault();
        setDirection(direction);
        setIsTicketLoading(true);
        const recordsPerPage = 5;
        const tickets = fetchedData?.results;
        const totalRecords = fetchedData?.count || 0;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        const currentIndex = tickets?.findIndex(
            (ticket) => ticket.id === currentTicketId
        );

        if (direction === "next") {
            if (currentIndex < tickets?.length - 1) {
                const nextTicketId = tickets[currentIndex + 1].id;
                setCurrentTicketId(nextTicketId);
                navigate(`/ticket/information/${nextTicketId}`, {
                    replace: true,
                });
                setConfirmationModalOpen(false)
            } else if (currentPage < totalPages) {
                setCurrentPage((prevPage) => {
                    const newPage = prevPage + 1;
                    sessionStorage.setItem("ticketCurrentPage", newPage);
                    return newPage;
                });
                setConfirmationModalOpen(false)

            } else {
                toast.warning("No more tickets available.");
                setConfirmationModalOpen(false)

            }
        } else if (direction === "previous") {
            if (currentIndex > 0) {
                const previousTicketId = tickets[currentIndex - 1]?.id;
                setCurrentTicketId(previousTicketId);
                navigate(`/ticket/information/${previousTicketId}`, {
                    replace: true,
                });
            } else if (currentPage > 1) {
                setCurrentPage((prevPage) => {
                    const newPage: any = prevPage - 1;
                    sessionStorage.setItem("ticketCurrentPage", newPage);
                    return newPage;
                });
            } else {
                toast.warning("No previous tickets available.");
            }
        }
        setIsTicketLoading(false);
    };

    useEffect(() => {
        const refetchData = async () => {
            setIsTicketLoading(true);
            await refetchTicket();
            if (direction === "next") {
                setCurrentTicketId(fetchedData?.results[0]?.id);
                navigate(`/ticket/information/${fetchedData?.results[0]?.id}`, {
                    replace: true,
                });
            } else if (direction === "previous") {
                const lastTicketId =
                    fetchedData?.results[fetchedData.results.length - 1]?.id;
                setCurrentTicketId(lastTicketId);
                navigate(`/ticket/information/${lastTicketId}`, {
                    replace: true,
                });
            }
            setIsTicketLoading(false);
        };

        if (direction) {
            refetchData();
        }
    }, [currentPage, fetchedData]);
    
  const leadInformationRef = useRef<HTMLDivElement>(null); // Create a reference to the component
      
          const scrollToTop = () => {
              if (leadInformationRef.current) {
                  leadInformationRef.current.scrollIntoView({ behavior: 'smooth' });
              }
          };
          useEffect(() => {
            const refetchData = async () => {
                setIsTicketLoading(true);
                await refetchTicket(); // Assuming this function refetches the data
        
                if (direction === "next" || direction === "previous") {
                    setPaneOpen("LeadInformation"); // Set the active tab
                }
        
                setIsTicketLoading(false);
        
                setDirection(null);
            };
        
            if (direction) {
                setPaneOpen("LeadInformation");
                refetchData();
            }
        }, [direction]); 

        leadInformationRef.current?.scrollIntoView();


    return (
        <>
            <div id="kt_app_content_container" className="app-container mx-5" ref={leadInformationRef}>
                {/* Back Button */}

                {/* begin::Products */}
                <div className="card card-flush">
                    {/* Start: The 2nd stack */}
                    <div
                        className="card-header align-items-center py-1 gap-2 gap-md- mt-4"
                        style={{
                            display: "flex",
                            width: "100%",
                            paddingLeft: "40px",
                        }}
                    >
                        <div
                            className="d-flex align-items-center"
                            style={{ width: "16%" }}
                        >
                            {/* Back Button */}
                            <button
                                className="btn btn-link mb-2"
                                onClick={handleBackButtonClick}
                            >
                                <i
                                    className="bi bi-arrow-90deg-left"
                                    style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Back"
                                ></i>
                            </button>
                            <h2 className="ms-3">Ticket Information</h2>

                        </div>

                        <div style={{ flex: "1" }}>
                            {/* <PrevNextAction onClick={handleButtonClick} /> */}
                        </div>
                        {/* Right-aligned buttons */}
                        <div
                            className="d-flex gap-3"
                            style={{
                                width: "18%",
                                display: "flex",
                                // paddingRight: "40px",
                                justifyContent: "end",
                            }}
                        >
                            {/* Create Child Ticket Button */}

                            {/* Create Task Button */}
                            {/* <div
                                className="card mw-175px p-1"
                                style={{ width: "150px" }}
                            >
                                <button
                                    className="btn btn-link text-nowrap w-100 text-center"
                                    onClick={openModal}
                                >
                                    <i className="bi bi-plus"></i> Create Task
                                </button>
                            </div> */}
                        </div>

                        {/* Modal */}
                        <ReactModal
                            isOpen={isModalOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customModalStyles}
                            contentLabel="Create Task"
                        >
                            <CreateTaskModal
                                closeModal={closeModal}
                                ticket={data}
                                createTask={createTask}
                                refetchTask={refetchTask}
                            />
                        </ReactModal>

                        <ReactModal
                            isOpen={isChildTicketModalOpen}
                            onRequestClose={closeChildTicketModal}
                            style={customModalStyles1}
                            contentLabel="New Ticket"
                        >
                            <CreateChildTicket
                                closeModal={closeChildTicketModal}
                                refetch={refetch}
                                uuid={uuid}
                            />
                        </ReactModal>
                    </div>
                    {/* End: The 2nd stack */}
                    {/* <div style={{ marginLeft: "59px", display: "flex", width: "100%", alignItems: "center" }}>
                        <div style={{width: "21%"}}>
                        </div>
                        <div style={{display: "flex", gap: "20px", width: "56%"}}>
                            <PrevNextAction onClick={handleButtonClick} />
                        </div>
                    </div> */}
 <div
                    className="card-header align-items-center py-1 gap-2 gap-md-5"
                    style={{
                        width: "99%",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <span
                        className="btn btn-link"
                        data-bs-toggle="tooltip"
                        title="Previous Ticket"
                        onClick={(event) =>
                            handleButtonClick(event, "previous")
                        }
                    >
                        <i
                            className="bi bi-arrow-left-square"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>
                    </span>

                    <span
                        className="btn btn-link"
                        data-bs-toggle="tooltip"
                        title="Next Ticket"
                        onClick={(event) => handleButtonClick(event, "next")}
                        style={{ marginRight: "17%" }}
                    >
                        <i
                            className="bi bi-arrow-right-square"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>
                    </span>
                </div>
                    {/* Start: The 3rd stack */}
                    <div
                        className="card-body d-flex flex-column"
                        style={{marginTop:"-3%" }}
                    >
                        {isTicketLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <LoadingSpinner />
                            </div>
                        ) : data?.length === 0 ? (
                            <div className="text-center">
                                No data available.
                            </div>
                        ) : (
                            <div className="container-fluid mt-5">
                                <div className="row">
                                    <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                                        <TicketLeftSideMenuCard
                                            tickets={data}
                                        />
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-12 mb-4">
                                        <TicketListInfoCard
                                            tickets={data}
                                            refetch={refetch}
                                            refetchAuditTrailData={
                                                refetchAuditTrailData
                                            }
                                            setGetAllStatus={setGetAllStatus}
                                            refetchAuditTrailForwardedData={
                                                refetchAuditTrailForwardedData
                                            }
                                            openChildTicketModal={
                                                openChildTicketModal
                                            }
                                        />
                                        <div className="mt-5">
                                            <div className="mt-2">
                                                <TicketPaneOpen
                                                    tickets={data}
                                                    refetch={refetch}
                                                    taskData={taskData}
                                                    refetchTask={refetchTask}
                                                    isLoading={isLoading}
                                                    refetchAuditTrailForwardedData={
                                                        refetchAuditTrailForwardedData
                                                    }
                                                    paneOpen={paneOpen}
                                                    setPaneOpen={setPaneOpen}
                                                    setConfirmationModalOpen={setConfirmationModalOpen}
                                                    confirmationModalOpen={confirmationModalOpen}
                                                    handleButtonClick={handleButtonClick}
                                                    auditTrailForwardedData={
                                                        auditTrailForwardedData
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                                        <TicketRightSideMenu
                                            auditTrailForwardedData={
                                                auditTrailForwardedData
                                            }
                                            setPaneOpen={setPaneOpen}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* End: The 3rd stack */}
                </div>
            </div>
        </>
    );
};

export default TicketListInfo;
