import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import {
  useAddNewCampaignMutation,
  useCampaignTeamsQuery,
  useGetTelephonyPriQuery,
} from "../../../../Services/campaign/CampaignMapping";
import { Loader } from "react-bootstrap-typeahead";
import { useAllUsersDataQuery } from "../../../../Services/user/Users";
import { toast } from "react-toastify";
import { useUsersByTeamIdQuery } from "../../../../Services/ticket/users/UsersByTeamId";
import { set } from "date-fns";

interface IProps {
  allCampaignData: any;
  closeCampaignModal: () => void;
  refetch: any;
  campaignToggle: any;
  setCampaignToggle: any;
}

interface ErrorState {
  [key: string]: string;
}

const AddNewCampaignModal = ({
  allCampaignData,
  closeCampaignModal,
  refetch,
  campaignToggle,
  setCampaignToggle,
}: IProps) => {
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  const [campaignName, setCampaignName] = useState<any>("");
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>("");
  const [endTime, setEndTime] = useState<any>("");
  const [DialerType, setDialerType] = useState<any>("");

  const [telephonyData, setTelephonyData] = useState<any>([]);
  const [selectedTelephony, setselectedTelephony] = useState<any>([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
      const [selectedCampaignType, setSelectedCampaignType] = useState('')

    const campaignType = ["inward", "outward"]

  

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errors, setErrors] = useState<ErrorState>({
    campaignName: "",
    selectedTeams: "",
    selectedUser: "",
    startTime: "",
    endTime: "",
    DialerType: "",
    selectedTelephony: "",
  });

  const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
    selectedTeams,
    {
      skip: selectedTeams.length === 0,
    }
  );
  const { data: teamsData } = useCampaignTeamsQuery({});

  const { data: telephonyPriData } = useGetTelephonyPriQuery({});

  const [triggerNewCampaign, { isLoading }] = useAddNewCampaignMutation();

  // Setting teams data
  useEffect(() => {
    if (teamsData && Array.isArray(teamsData)) {
      setTheTeams(teamsData);
    }
  }, [teamsData]);

  useEffect(() => {
    setTelephonyData(telephonyPriData?.results);
  }, [telephonyPriData]);

  console.log(telephonyData, "telephonyData");

  // Setting user list
  useEffect(() => {
    if (usersData && Array.isArray(usersData)) {
      setUserList(usersData);
      console.log(usersData, "usersData");
    }
  }, [usersData]);

  useEffect(() => {
    if (usersStatus === "pending") {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [usersStatus]);

  const handleToggleChange = () => {
    setCampaignToggle(!campaignToggle);
  };

  const validateForm = () => {
    const newErrors: ErrorState = {};

    //Campaign Name validation
    if (!campaignName) {
      newErrors["campaignName"] = "Campaign name is required";
    }
    // Teams validation
    if (!selectedTeams || selectedTeams.length === 0) {
      newErrors["selectedTeams"] = "Please select at least one team";
    }

    // User List validation
    if (!selectedUser) {
      newErrors["selectedUser"] = "Please select at least one user";
    }

    // Start Time validation
    if (!startTime) {
      newErrors["startTime"] = "Start time is required";
    }

    // End Time validation
    if (!endTime) {
      newErrors["endTime"] = "End time is required";
    } else if (
      startTime &&
      endTime &&
      new Date(startTime) >= new Date(endTime)
    ) {
      newErrors["endTime"] = "End time must be later than Start Time";
    }

    // Dialer Type validation
    if (!DialerType) {
      newErrors["DialerType"] = "Please select a dialer type";
    }

    // DID selection validation
    if (!selectedTelephony || selectedTelephony.length === 0) {
      newErrors["selectedTelephony"] = "Please select at least one DID";
    }

    // Set errors in state
    setErrors(newErrors);

    // Return whether the form is valid (no errors)
    return Object.keys(newErrors).length === 0;
  };

  const isAllSelected = selectedUser?.length === userList?.length;
  const handleUsersChange = (event: any) => {
    setIsButtonDisabled(false);
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      setSelectedUser(isAllSelected ? [] : userList.map((user) => user.id));
    } else {
      setSelectedUser(Array.isArray(value) ? value : [value.split(",")]); // Ensure value is always an array
    }
    setErrors({});
  };

  const isAllTelephonySelected =
    selectedTelephony?.length === telephonyData?.length;
  const handleDIDChange = (event: any) => {
    setIsButtonDisabled(false);
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      setselectedTelephony(
        isAllTelephonySelected ? [] : telephonyData?.map((tel) => tel.id)
      );
    } else {
      setselectedTelephony(value); // Update this line to accept an array of values
    }
    setErrors({});
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return;
    }

    try {
      setIsButtonDisabled(true);
      const obj = {
        name: campaignName,
        team: selectedTeams,
        users: selectedUser, // Assuming selectedUser is an object with an "id" property
        start_time: startTime,
        end_time: endTime,
        Dialer_type: DialerType,
        pris: selectedTelephony,
        is_active: campaignToggle,
        campaign_type: selectedCampaignType,

      };

      const response = await triggerNewCampaign({
        body: obj,
      });

      if (response?.data) {
        toast.success("Campaign added successfully!");
        closeCampaignModal();
        refetch();
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
      console.error(error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

      const campaignSelectHandler = (value:string) => {
          setSelectedCampaignType(value)
      }
  

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeCampaignModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Add Campaign
        </label>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <label className="form-check-label mx-2">Off</label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            checked={campaignToggle}
            onChange={handleToggleChange}
          />
          <label className="card-text">On</label>
        </div>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        {/* Campaign Name */}
        <div className="col-xl-6">
          {/* <InputLabel className="required">Campaign name</InputLabel> */}
          <label className="required">Campaign name</label>
          <TextField
            variant="outlined"
            fullWidth
            name="campaignName"
            value={campaignName}
            onChange={(e) => {
              setIsButtonDisabled(false);

              setErrors(errors?.campaign_name ? {} : errors);
              setCampaignName(e.target.value);
            }}
          />
          {errors.campaignName && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.campaignName)}
            </div>
          )}
        </div>

        {/* Assign to Teams */}
        <div className="col-xl-6">
          <FormControl fullWidth>
            <label className="required">Teams</label>
            <Select
              labelId="team-label"
              id="team-select"
              value={selectedTeams || ""}
              onChange={(e) => {
                setIsButtonDisabled(false);

                setErrors(errors?.selectedTeams ? {} : errors);
                setSelectedTeams(e.target.value);
              }}
              MenuProps={MenuProps}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Team
              </MenuItem>
              {theTeams?.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.selectedTeams && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.selectedTeams)}
            </div>
          )}
        </div>

        {/* User List */}
        <div className="col-xl-6">
          <FormControl fullWidth>
            <label className="required">Users</label>
            {selectedTeams && usersStatus === "pending" ? (
              <Loader />
            ) : (
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedUser}
                onChange={handleUsersChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (isAllSelected) {
                    return <div>All</div>;
                  }
                  return (
                    <div>
                      {selected?.map((userId: any) => {
                          const user = userList?.find(
                            (user) => user.id === userId
                          );
                          return user ? user.username : null; // Use username instead of name
                        })
                        .join(", ")}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  onClick={() =>
                    setSelectedUser(
                      isAllSelected ? [] : userList?.map((user) => user?.id)
                    )
                  }
                >
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={selectedUser?.length > 0 && !isAllSelected}
                  />
                  <ListItemText primary="All" />
                </MenuItem>
                {userList?.map((user: any) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox checked={selectedUser.indexOf(user.id) > -1} />
                    <ListItemText primary={user.username} />{" "}
                    {/* Use username here */}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors.selectedUser && (
              <div className="mt-2" style={{ color: "red" }}>
                {capitalizeFirstLetter(errors.selectedUser)}
              </div>
            )}
          </FormControl>
        </div>

        {/* Start Time */}
        <div className="col-xl-6">
          <label className="required">Start date and time</label>
          <TextField
            type="datetime-local"
            fullWidth
            value={startTime}
            onChange={(e) => {
              setIsButtonDisabled(false);
              setErrors(errors?.startTime ? {} : errors);
              setStartTime(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                style: { textTransform: "uppercase" }, // Optional: uppercase input text
              },
            }}
          />
          {errors.startTime && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.startTime)}
            </div>
          )}
        </div>

        {/* End Time */}
        <div className="col-xl-6">
          <label className="required">End date and time</label>
          <TextField
            type="datetime-local"
            fullWidth
            value={endTime}
            onChange={(e) => {
              setIsButtonDisabled(false);
              setErrors(errors?.endTime ? {} : errors);
              setEndTime(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                style: { textTransform: "uppercase" }, // Optional: uppercase input text
              },
            }}
          />
          {errors.endTime && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.endTime)}
            </div>
          )}
        </div>

           <div className="col-xl-6">
                            <InputLabel className="">Campaign type</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="select-label-assignee"
                                            id="select-assignee"
                                            value={selectedCampaignType}
                                            onChange={(e: any) => campaignSelectHandler(e.target.value)}
                                            required
                                        >
                                            <MenuItem value="" disabled>
                                                Select
                                            </MenuItem>
                                            {campaignType?.map((campaign: any) => (
                                                <MenuItem key={campaign} value={campaign}>
                                                    {capitalizeFirstLetter(campaign)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>                        </div>

        {/* Dialer Type */}
        <div className="col-xl-6">
          <label className="required">Dialer type</label>
          <FormControl fullWidth>
            <Select
              value={DialerType}
              onChange={(e) => {
                setIsButtonDisabled(false);
                setErrors(errors?.DialerType ? {} : errors);
                setDialerType(e.target.value);
              }}
              MenuProps={MenuProps}
            >
              <MenuItem disabled selected>
                Select
              </MenuItem>
              <MenuItem value="manual">Manual Dial</MenuItem>
              <MenuItem value="autoDial">Auto-Dial</MenuItem>
            </Select>
            {errors.DialerType && (
              <div className="mt-2" style={{ color: "red" }}>
                {capitalizeFirstLetter(errors.DialerType)}
              </div>
            )}
          </FormControl>
        </div>

        {/* Select DID */}
        <div className="col-xl-6">
          <FormControl fullWidth>
            <label className="required">Select DID</label>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={
                Array.isArray(selectedTelephony)
                  ? selectedTelephony
                  : [selectedTelephony]
              }
              onChange={handleDIDChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (isAllTelephonySelected) {
                  return <div>All</div>;
                }
                return (
                  <div>
                    {selected
                      ?.map((id: any) => {
                        const telephony = telephonyData?.find(
                          (tel) => tel?.id === id
                        );
                        return telephony ? telephony?.name : null; // Use username instead of name
                      })
                      .join(", ")}
                  </div>
                );
              }}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                onClick={() =>
                  setselectedTelephony(
                    isAllTelephonySelected
                      ? []
                      : telephonyData?.map((tel) => tel?.id)
                  )
                }
              >
                <Checkbox
                  checked={isAllTelephonySelected}
                  indeterminate={
                    selectedTelephony?.length > 0 && !isAllTelephonySelected
                  }
                />
                <ListItemText primary="All" />
              </MenuItem>
              {telephonyData?.map((tel: any) => (
                <MenuItem key={tel?.id} value={tel?.id}>
                  <Checkbox checked={selectedTelephony.indexOf(tel?.id) > -1} />
                  <ListItemText primary={tel?.name} /> {/* Use username here */}
                </MenuItem>
              ))}
            </Select>
            {errors.selectedTelephony && (
              <div className="mt-2" style={{ color: "red" }}>
                {capitalizeFirstLetter(errors.selectedTelephony)}
              </div>
            )}
          </FormControl>
        </div>

        <div className="text-end mt-5 mb-4">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={handleSubmit}
            disabled={isButtonDisabled}
          >
            {isLoading ? (
              <>
                Submitting... <Loader />
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewCampaignModal;
