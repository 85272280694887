import clsx from "clsx";
import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    AUTH_TOKEN,
    CALL,
    CALL_BREAK_REMARK,
    CALLBREAKID,
    CIRCLEONE_LOGIN_TYPE,
    CIRCLEONE_TEAM,
    CIRCLEONE_USER,
    CIRCLEONE_USERNAME,
    EXTENSION,
    QUEUE,
    TELEPHONEURL,
    TELEPHONY_LOGIN,
    TELEPHONYLOGIN,
    TELEPHONYLOGINID,
    TELPEHONYLOGINUSERID,
    USER_LOGIN_PASSWORD,
    USER_TEAM_ID,
} from "../../../constants";
import { Dropdown } from "../../../helpers/components/Dropdown";
import { useLayout } from "../../../layout/core";
import { createInitials } from "../../../utils/functions/createInitials";
import { KTSVG } from "../../../helpers/components/KTSVG";
import { maskEmail } from "../../../utils/functions/maskData";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import SaveModal from "./SaveModal";
import ReactModal from "react-modal";
import { useGetAllTeamsByIdQuery } from "../../../Services/email/EmailTemplate";
import { useServerConfigQuery } from "../../../Services/Telephony/Telephony";
import { useTelephonyExtensionsQuery } from "../../../Services/Telephony/TelephonyFrontend";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useTelephony } from "../../../components/context/TelephonyContext";
import { Loader } from "react-bootstrap-typeahead";
import { Skeleton } from "@mui/material";
import LoadingSkeleton from "../../../components/LoadingSkeleton";
import { useLogoutMutation } from "../../../Services/Auth/Auth";


const TopRightTools: React.FC = () => {
    const { config } = useLayout();
    const location = useLocation(); // Get the current location
    const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
    const [isUserMenuOpen2, setIsUserMenuOpen2] = useState<boolean>(false);
    const [permissionList, setPermissionList] = useState<any>([]);


    const headerUserDropdownMenuRef = useRef<HTMLDivElement>(null);
    const { data: teams, isLoading: teamsLoading } = useTeamsQuery({});
    const { data: getTeamsById } = useGetAllTeamsByIdQuery(
        localStorage.getItem("User_Id")
    );
    // const {data: telephony} = useServerConfigQuery({}, {skip: !telephonyClicked})
    //  const{data: telephonyExtension } =  useTelephonyExtensionsQuery(localStorage.getItem('User_Id'), {skip: !telephonyClicked})
    const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
    const [storingTelephonyServerData, setStoringTelephonyServerData] =
        useState([]);
    const [storingExtensionNo, setStoringExtensionNo] = useState("");
    const [storedData, setStoredData] = useState([]);
    const [storingUserName, setStoringUserName] = useState("");
    const [storingCallBreakId, setStoringCallBreakId] = useState("");
    const [closeTopRightTool, setCloseTopRightTool] = useState(false)
    const [triggerLogout] = useLogoutMutation();

    const [storingServerConfigData, setStoringServerConfigData] = useState<any>(
        {}
    );
    const [storeDResult, setStoredResult] = useState([]);
    const [queueus, setQueueus] = useState([]);

    const [modulePermission, setModulePermission] = useState<boolean>(false);

    const { setStoredTelephonyLoginDetails } = useTelephony()

    const {
        setServerConfig,
        setExtensionNo,
        setUserName,
        setQueueNames,
        setCallBreakId,
        setIsLoading,
        isLoading,
    } = useTelephony();

    const [loading, setLoading] = useState<boolean>(false);

    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const storedLogin = localStorage.getItem(TELEPHONY_LOGIN);
        return storedLogin === "true";
    }); // State to track login status

    const [selectedTeam, setSelectedTeam] = useState<any>([]);
    const tenantName = localStorage.getItem(CIRCLEONE_USER);
    const userName = localStorage.getItem(CIRCLEONE_USERNAME);
    const teamName = localStorage.getItem(CIRCLEONE_TEAM);
    const permissionToShow = localStorage.getItem("user-token");
    const password = localStorage.getItem(USER_LOGIN_PASSWORD)
    const teamId = localStorage.getItem(USER_TEAM_ID);

    useEffect(() => {
        if (permissionToShow) {
            const parsedPermission = JSON.parse(permissionToShow); // Parse the JSON string
            const teamPermissions = parsedPermission?.teams[0];
            // Find the key that you need dynamically
            const dynamicKey =
                Object.keys(teamPermissions).find((key) =>
                    key.includes("module_permissions")
                ) || Object.keys(teamPermissions)[0];
            const dynamicKey1 =
                Object.keys(teamPermissions).find(
                    (key) => key === "module_permissions"
                ) || Object.keys(teamPermissions)[0];
            if (dynamicKey1 === "module_permissions") {
                setModulePermission(true);
            } else {
                setModulePermission(false);
            }
            const permissionListValue = teamPermissions[dynamicKey] || []; // Ensure permissionListValue is an array
            setPermissionList(permissionListValue);
        }
    }, [permissionToShow]);

    const hasPermission = (permission) =>
        Array.isArray(permissionList) && permissionList.includes(permission);

    let initials = null;
    if (!!userName) {
        initials = createInitials(userName);
    }

    const closeUserMenu = () => {
        setIsUserMenuOpen(false);
        setIsUserMenuOpen2(false);
    };

    const userId = localStorage.getItem("User_Id");

    const toolbarButtonMarginClass = "ms-lg-3";
    const toolbarUserAvatarHeightClass = `symbol-30px symbol-md-40px ${isUserMenuOpen ? "show menu-dropdown" : ""
        }`;

    const teamChange = (team: any) => {
        setSelectedTeam(team);
        openSaveModal();
    };

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    const openSaveModal = () => {
        setIsSaveModalOpen(true);
    };

    const afterOpenSaveModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeSaveModal = () => {
        setIsSaveModalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                headerUserDropdownMenuRef.current &&
                !headerUserDropdownMenuRef.current.contains(
                    event.target as Node
                )
            ) {
                closeUserMenu();
            }
        };
        if (closeTopRightTool) {
            closeUserMenu();
        }


        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [closeTopRightTool]);

    useEffect(() => {
        if (storeDResult) {
            setStoringServerConfigData(storeDResult);
        }
    }, [storeDResult]);

    // useEffect(() => {
    //   if (telephony) {
    //     setTelephonyData(telephony?.results)
    //   }

    // }, [telephony])

    // useEffect(() => {
    //   if (telephonyExtension) {
    //     setTelephonyServerQueueData(telephonyExtension[0].queues); // Update state
    //     setTelephonyServerData(telephonyExtension)

    //   }
    // }, [telephonyExtension]);

    // const loopFunction = async (
    //     data: string[],
    //     dynamicBatchSize: number,
    //     processBatch: (batch: string[]) => Promise<void>,
    //     addBatch: (batch: string[]) => Promise<void>, // Add addBatch as a parameter
    //     pauseBatch: (batch: string[]) => Promise<void>
    // ) => {
    //     try {
    //         for (let i = 0; i < data.length; i += dynamicBatchSize) {
    //             const batch = data.slice(i, i + dynamicBatchSize); // Get the current batch of elements
    //             await processBatch(batch); // Process the batch
    //             await addBatch(batch); // Call addBatch after processBatch for the same batch
    //             await pauseBatch(batch);
    //         }
    //     } catch (error) {
    //         console.error("Error during batch processing:", error);
    //         // You can handle error reporting here as well, e.g. via toast.error
    //     }
    // };

    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const gettingQueues = localStorage.getItem(QUEUE);

    useEffect(() => {
        if (gettingQueues) {
            // Split the comma-separated string into an array
            const queuesArray = gettingQueues.split(",");
            setQueueus(queuesArray);
        }
    }, [gettingQueues]);

    const callBreakId = localStorage.getItem(CALLBREAKID);
    const telephonyLoginId = localStorage.getItem(TELEPHONYLOGINID);

    const telephoneURL = localStorage.getItem(TELEPHONEURL);

    const telephoneData = JSON.parse(telephoneURL);

    const telephonyHandler = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        setLoading(true);

        try {
            // Step 1: Fetch server configuration
            const serverConfigResponse = await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/telephony-server-config`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            if (
                !serverConfigResponse?.data?.results ||
                serverConfigResponse?.data?.results?.length === 0
            ) {
                throw new Error("Server configuration not found.");
            }

            const serverConfig = serverConfigResponse?.data?.results[0];
            setServerConfig(serverConfig);
            setStoredResult(serverConfig);
            setStoringServerConfigData(serverConfig);
            localStorage.setItem(TELEPHONEURL, JSON.stringify(serverConfig));

            // Step 2: Fetch telephony extension
            const telephonyExtensionResponse = await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/user-telephony-extension/?user_id=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            const telephonyData = telephonyExtensionResponse?.data?.[0];
            if (!telephonyData) {
                throw new Error("Telephony extension data not found.");
            }

            const queueNamesData = telephonyData?.queues?.map(
                (queue) => queue || []
            );
            setStoringTelephonyServerData(queueNamesData);

            const fetchingExtensionNo = telephonyData?.extension_no;
            const fetchingUserName = telephonyData?.user?.username;

            localStorage.setItem(EXTENSION, fetchingExtensionNo);
            setExtensionNo(fetchingExtensionNo);
            setStoringExtensionNo(fetchingExtensionNo);
            setUserName(fetchingUserName);
            setStoringUserName(fetchingUserName);

            const campaignString = queueNamesData
                ?.map((queue) => queue?.name)
                .join(",");
            localStorage.setItem(QUEUE, JSON.stringify(campaignString));

            // Process queues
            const processBatch = async (batch, operation) => {
                const batchPromises = batch.map(async (queue) => {

                    const sanitizedQueue = queue?.name?.replace(/['"]+/g, "").trim().replace(/\s+/g, "");

                    const url = localStorage.getItem(TELEPHONEURL);
                    const telephoneData = JSON.parse(url);
                    const apiUrl = telephoneData?.https_port > 0 ?
                        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${fetchingExtensionNo}&Operation=${operation}&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${fetchingUserName}&penalty=${queue?.penalty}`

                        :
                        `https://${telephoneData?.domain}/click2call/acd.php?AgentExten=${fetchingExtensionNo}&Operation=${operation}&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${fetchingUserName}&penalty=${queue?.penalty}`

                    await axios.post(
                        apiUrl,
                        // {
                        //     AgentExten: fetchingExtensionNo,
                        //     Operation: operation,
                        //     Queue: queue?.name,
                        //     SecKey: "2c41c5e95e052e9d03f423e9f578b9b3",
                        //     AgentName: fetchingUserName,
                        //     Penalty: queue?.penalty,
                        // },
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );
                });

                await Promise.all(batchPromises);
            };

            await processBatch(queueNamesData, "remove");
            await processBatch(queueNamesData, "add");

            // Step 3: Telephony login
            const telephonyLoginResponse = await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/telephony-login/`,
                { campaigns: campaignString },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            if (!telephonyLoginResponse?.data?.id) {
                throw new Error("Telephony login failed.");
            }

            setStoredTelephonyLoginDetails(telephonyLoginResponse?.data);
            localStorage.setItem("user_Id", telephonyLoginResponse?.data?.user_id)
            localStorage.setItem(TELEPHONYLOGINID, telephonyLoginResponse?.data?.id
            );
            localStorage.setItem(TELPEHONYLOGINUSERID, telephonyLoginResponse?.data?.user_id)

            await processBatch(queueNamesData, "pause");

            // Step 4: Call breaks
            const callBreaksResponse = await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/call-breaks/`,
                { break_remark: "Not Ready", user_id: +userId },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            setCallBreakId(callBreaksResponse?.data?.id);
            setStoringCallBreakId(callBreaksResponse?.data?.user_id);
            localStorage.setItem(
                CALL_BREAK_REMARK,
                callBreaksResponse?.data?.break_remark
            );
            localStorage.setItem(CALLBREAKID, callBreaksResponse?.data?.id);

            // Step 5: Update extension state
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/extension-states/`,
                { extension: fetchingExtensionNo, state: "pause" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            toast.success("Telephony loggedIn successfully!");
            localStorage.setItem(TELEPHONY_LOGIN, "true");
            setCloseTopRightTool(true)
            setIsLoading(false);

            setIsLoggedIn(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("An error occurred:", error);
            toast.error(
                error?.message || "An error occurred during telephony setup."
            );
            setIsLoggedIn(false);
            setIsLoading(false);
        }
        // finally {
        //     setLoading(false);
        //     setIsLoggedIn(false)

        //     setIsLoading(false);
        // }
    };
    const mobileNumber = localStorage.getItem("liftingMobileNumber");

    const callStatus = localStorage.getItem(CALL);

    const telephonyLogoutHandler = async (e: any) => {
        if (callStatus === "true") {
            toast.warning("You are on the call");
            return;
        }
        e.preventDefault();
        setLoading(true);
        setIsLoading(true);

        try {
            const url = localStorage.getItem(TELEPHONEURL);
            const telephoneData = JSON.parse(url || "{}");
            const extension = localStorage.getItem(EXTENSION);

            // Step 1: Unpause Batch
            const unpauseBatch = async (batch: any[]) => {
                const batchPromise = batch.map(async (queue) => {
                    const sanitizedQueue = queue.replace(/['"]+/g, "").trim().replace(/\s+/g, "");


                    try {
                        const url = telephoneData?.https_port > 0 ?
                            `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${extension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}`

                            :

                            `https://${telephoneData?.domain}/click2call/acd.php?AgentExten=${extension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}`

                        await axios.post(
                            url,
                            {
                                AgentExten: extension,
                                Operation: "unpause",
                                Queue: sanitizedQueue,
                                SecKey: "2c41c5e95e052e9d03f423e9f578b9b3",
                                AgentName: storingUserName,
                                Penalty: queue?.penalty,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                        AUTH_TOKEN
                                    )}`,
                                },
                            }
                        );
                    } catch (error) {
                        console.error(
                            `Error unpausing queue: ${queue?.name}`,
                            error
                        );
                    }
                });
                await Promise.all(batchPromise);
            };
            await unpauseBatch(queueus);

            // Step 2: Update Extension State
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/extension-states/`,
                { extension, state: "unpause" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Step 3: Fetch Inbound Call
            await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/inbound-call/?extension=${extension}&`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Step 4: Resume Call Break
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/call-breaks/${callBreakId}/`,
                { action: "resume" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Step 5: Remove Batch
            const removeBatch = async (batch: any[]) => {
                const batchPromise = batch.map(async (queue) => {
                    const sanitizedQueue = queue.replace(/['"]+/g, "").trim().replace(/\s+/g, "");


                    const url = telephoneData?.https_port > 0 ?
                        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${extension}&Operation=remove&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}`

                        :
                        `https://${telephoneData?.domain}/click2call/acd.php?AgentExten=${extension}&Operation=remove&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}`

                    try {
                        await axios.post(
                            url,
                            {
                                AgentExten: extension,
                                Operation: "remove",
                                Queue: sanitizedQueue,
                                SecKey: "2c41c5e95e052e9d03f423e9f578b9b3",
                                AgentName: storingUserName,
                                Penalty: queue?.penalty,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                        AUTH_TOKEN
                                    )}`,
                                },
                            }
                        );
                    } catch (error) {
                        console.error(
                            `Error removing queue: ${queue?.name}`,
                            error
                        );
                    }
                });
                await Promise.all(batchPromise);
            };
            await removeBatch(queueus);

            // Step 6: Logout
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/telephony-login/${telephonyLoginId}/`,
                { action: "logout", logout_reason: "Self Logout" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Clean up localStorage
            localStorage.removeItem(TELEPHONEURL);
            localStorage.removeItem(CALLBREAKID);
            localStorage.removeItem(EXTENSION);
            localStorage.removeItem(QUEUE);
            localStorage.setItem(TELEPHONY_LOGIN, "false");

            setExtensionNo("");
            setQueueNames([]);
            setServerConfig("");
            setUserName("");
            setCallBreakId("");
            toast.success("Telephony logout successfully!");
            setIsLoading(false);
            setIsLoggedIn(false);
            setLoading(false);
            setCloseTopRightTool(true)

        } catch (error) {
            console.error("Error during telephony logout process:", error);
            setIsLoading(false);
            setLoading(false);
        } finally {
            setLoading(false); // Stop loading spinner when all APIs are completed
            setIsLoading(false);
        }
    };
    const checkTeam = localStorage.getItem(CIRCLEONE_TEAM)
    const checkUsername = localStorage.getItem(CIRCLEONE_USERNAME)

    const restricteduser = checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io"


    return (
        <>
            <div
                className={clsx(
                    "d-flex align-items-center",
                    toolbarButtonMarginClass
                )}
                ref={headerUserDropdownMenuRef}
            >
                <div className="d-flex align-items-center">
                    <button className="btn btn-icon btn-sm btn-active-light-primary">
                        <i className="bi bi-bell-fill fs-1"></i>
                    </button>
                </div>
                <div
                    className={clsx(
                        "cursor-pointer symbol d-flex",
                        toolbarUserAvatarHeightClass
                    )}
                >
                    <div className="dropdown ">
                        <button
                            className="btn dropdown-toggle d-flex align-items-center text-dark fw-bolder my-1 fs-3"
                            type="button"
                            id="dropdownMenuButton3"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {teamName}
                        </button>
                        {getTeamsById?.role === "admin" ? (
                            <ul className="dropdown-menu dropdown-menu-end p-2">
                                {teams
                                    ?.slice() // Create a shallow copy of teams to avoid mutating the original array
                                    .sort((a: any, b: any) =>
                                        a.name.localeCompare(b.name)
                                    ) // Sort teams alphabetically by name
                                    .map((team: any) => (
                                        <div
                                            key={team.id} // Ensure each team has a unique key
                                            className={`dropdown-item ${teamName === team.name
                                                ? "primary"
                                                : ""
                                                }`}
                                            onClick={() => teamChange(team)}
                                        >
                                            {team.name}
                                        </div>
                                    ))}
                            </ul>
                        ) : (
                            <ul className="dropdown-menu dropdown-menu-end p-2">
                                {getTeamsById?.teams
                                    ?.slice() // Create a shallow copy of teams to avoid mutating the original array
                                    .sort((a: any, b: any) =>
                                        a.name.localeCompare(b.name)
                                    ) // Sort teams alphabetically by name
                                    .map((team: any) => (
                                        <div
                                            key={team.id} // Ensure each team has a unique key
                                            className={`dropdown-item ${teamName === team.name
                                                ? "primary"
                                                : ""
                                                }`}
                                            onClick={() => teamChange(team)}
                                        >
                                            {team.name}
                                        </div>
                                    ))}
                            </ul>
                        )}
                    </div>

                    <span
                        className={`symbol-label bg-light-dark text-primary fs-6 fw-bolder`}
                        onClick={() => setIsUserMenuOpen2(!isUserMenuOpen2)}
                    >
                        {initials?.label}
                    </span>
                </div>
                <Dropdown
                    menuRef={headerUserDropdownMenuRef}
                    droppedDown={isUserMenuOpen2}
                    setDroppedDown={setIsUserMenuOpen}
                >
                    <div className="card-body">
                        <div className="menu-content d-flex align-items-center px-3">
                            <div className="symbol symbol-50px me-5">
                                <span
                                    className={`symbol-label bg-light-dark text-primary fs-6 fw-bolder`}
                                >
                                    {initials?.label}
                                </span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="fw-bolder d-flex align-items-center fs-5">
                                    {!!tenantName ? tenantName : "Unknown User"}
                                </div>
                                <span className="fw-bold text-muted text-hover-primary fs-7">
                                    {!!userName
                                        ? maskEmail(userName)
                                        : "abc@xyz.com"}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="separator my-2"></div>
                    <div className="menu-item px-5">
                        <Link
                            to="/profile"
                            onClick={closeUserMenu}
                            className="menu-link px-5"
                        >
                            My Profile
                        </Link>
                    </div>
                    {/* {!restricteduser &&  */}
                    <div className="separator my-2"></div>
                    {/* } */}

                    <div className="menu-item px-5">
                        <a
                            href="https://botgo.io/support/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={closeUserMenu}
                            className="menu-link px-5"
                        >
                            Help
                        </a>
                    </div>

                    <div className="separator my-2"></div>

                    {!restricteduser &&
                        <>

                            {tenantName === "victorinox" ? <span style={{ color: "grey", padding: "4px 33px", cursor: "default" }}>Call History</span> :
                                <div className="menu-item px-5">
                                    <Link
                                        to="/call-history"
                                        onClick={closeUserMenu}
                                        className="menu-link px-5"
                                    >
                                        Call History
                                    </Link>
                                </div>
                            }
                        </>
                    }
                    {!restricteduser &&

                        <>
                            <div className="separator my-2"></div>

                            {hasPermission("phone:enable") && (
                                <>

                                    <div className="menu-item px-5" style={{ paddingLeft: "0px", display: "flex", justifyContent: "flex-start" }}>
                                        {tenantName === "victorinox" ? <span style={{ padding: "5px 15px", color: "grey" }}>Telephony Login</span> :
                                            <div style={{ paddingLeft: "0px", display: "flex", justifyContent: "flex-start", width: "100%" }}>
                                                {!isLoggedIn ? (
                                                    <span

                                                        className="menu-link px-5"
                                                        style={{
                                                            border: "none",
                                                            background: "transparent",
                                                            // color: "#7e8299",
                                                            // padding: "5px",
                                                            width: "100%",
                                                            paddingLeft: "0px",
                                                            // color: "grey",
                                                            // cursor: "default"

                                                        }}
                                                        onClick={telephonyHandler}
                                                    >
                                                        {loading ? (
                                                            <LoadingSkeleton />
                                                        ) : (
                                                            "Telephony Login"
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="menu-link px-5"
                                                        onClick={telephonyLogoutHandler}
                                                        style={{
                                                            border: "none",
                                                            background: "transparent",
                                                            // color: "grey",
                                                            // color: "#7e8299",
                                                            paddingLeft: "0px",

                                                            width: "100%",
                                                        }}
                                                    >
                                                        {loading ? (
                                                            <LoadingSkeleton />
                                                        ) : (
                                                            "Telephony Logout"
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        }
                                    </div>

                                    <div className="separator my-2"></div>

                                </>
                            )}

                        </>
                    }
                    <div className="menu-item px-5">
                        <div
                            className={`menu-link px-5 ${mobileNumber ? "disabled-link" : ""}`}
                            onClick={async (e) => {
                                if (mobileNumber) {
                                    e.preventDefault();
                                    toast.error("Call is active");
                                } else {
                                    console.log("working......");

                                    const payload = {
                                        username: userName,
                                        password: password,
                                        tenant: tenantName,
                                        team: String(teamId),
                                    };

                                    try {
                                        const response = await triggerLogout(payload); // Pass payload if needed
                                        if (response.data?.success) {
                                            localStorage.clear(); // Clear local storage
                                            window.location.reload(); // Reload the page
                                        } else {
                                            toast.error("Logout failed");
                                        }
                                    } catch (error) {
                                        toast.error("An error occurred while logging out");
                                    }
                                }
                            }}
                        >
                            Sign Out
                        </div>
                    </div>
                    {/* {!restricteduser &&
                        <>
                            <div className="separator my-2"></div>
                            {location.pathname !== "/client-login" && (
                                <div className="menu-item px-5">
                                    <Link to="/client-login" className="menu-link px-5">
                                        Client
                                    </Link>
                                </div>
                            )}
                        </>
                    } */}
                </Dropdown>
            </div>

            {/* begin::Aside Toggler */}
            {config.header.left === "menu" && (
                <div
                    className="d-flex align-items-center d-lg-none ms-2 me-n3"
                    title="Show header menu"
                >
                    <div
                        className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                        id="kt_header_menu_mobile_toggle"
                    >
                        <KTSVG
                            path="/media/icons/duotune/text/txt001.svg"
                            className="svg-icon-1"
                        />
                    </div>
                </div>
            )}

            <ReactModal
                isOpen={isSaveModalOpen}
                onAfterOpen={afterOpenSaveModal}
                onRequestClose={closeSaveModal}
                style={customModalStyles}
                contentLabel="Save"
            >
                <SaveModal
                    closeSaveModal={closeSaveModal}
                    selectedTeam={selectedTeam}
                />
            </ReactModal>
        </>
    );
};

export default TopRightTools;