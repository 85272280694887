/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSearchParams } from 'react-router-dom'
import ChatLeftPanel from '../../components/conversations/ChatLeftPanel/index'
import ChatRoom from '../../components/conversations/ChatRoom'
import { useContext, useState } from 'react'
import ChatHeaderComponents from '../../components/conversations/ChatHeaderComponents'
import ChatContext from '../../context/ChatContext'

const AllChats: React.FC<any> = () => {
  const [isData2, setIsData2] = useState()
  const [isData3, setIsData3] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  let chatRoomID: number = 0
  // let agentID: number = 0

  const type = searchParams.get('type')
  const chatRoomId = searchParams.get('chatRoomId')
  // const agentId = searchParams.get('agentId')
  const [disabledIndexes, setDisabledIndexes] = useState<any>([])
  const [chatPanels, setChatPanels] = useState<any[]>([])
  const maxPanels = 3
  const { state, setState } = useContext(ChatContext)

  const handleOpenChat = (user: any) => {
    if (chatPanels.length >= maxPanels) {
      alert(
        `You can't open more than ${maxPanels} chat panels. Please close one to open a new one.`
      )
      return
    }

    // Check if the chat panel for this user is already open
    if (chatPanels.find((panel) => panel.user.id === user.id)) {
      alert('Chat panel for this user is already open.')
      return
    }

    // Add a new panel with user data
    const newPanel = { id: Date.now(), user, isMinimized: false }
    setChatPanels([...chatPanels, newPanel])
    setDisabledIndexes([...disabledIndexes, user.id])

    if (!!user.emailVerified) {
      setState((prevState: any) => ({
        ...prevState,
        panelBasedConversation: [
          ...prevState.panelBasedConversation,
          { agentId: parseInt(user.id), agentName: user.name, conversation: [] }
        ]
      }))
    } else {
      setState((prevState: any) => ({
        ...prevState,
        panelBasedConversation: [
          ...prevState.panelBasedConversation,
          { chatRoomId: parseInt(user.id), conversation: [] }
        ]
      }))
    }
  }

  // Function to handle closing a chat panel
  const handleCloseChat = (id: any) => {
    const closedPanel = chatPanels.find((panel: { id: any }) => panel.id === id)
    setChatPanels(chatPanels.filter((panel: { id: any }) => panel.id !== id))

    if (closedPanel) {
      setDisabledIndexes(disabledIndexes.filter((userId: any) => userId !== closedPanel.user.id))
      setState((prevState: any) => {
        return {
          ...prevState,
          panelBasedConversation: prevState.panelBasedConversation.filter((conversation: any) => {
            return (
              conversation.chatRoomId !== parseInt(closedPanel.user.id) &&
              conversation.agentId !== parseInt(closedPanel.user.id)
            )
          })
        }
      })
    }
  }

  const handleMinimizeChat = (id: number) => {
    setChatPanels(
      chatPanels.map((panel: { id: number; isMinimized: any }) =>
        panel.id === id ? { ...panel, isMinimized: !panel.isMinimized } : panel
      )
    )
  }
  // Check if chatRoomId present or not in the url params
  if (chatRoomId !== null && chatRoomId !== undefined) {
    // Check if it is a NaN value or correct chatRoomId
    chatRoomID = !!Number(chatRoomId) ? Number(chatRoomId) : 0
    if (chatRoomID === 0 || chatRoomID > 2147483648) {
      //GraphQL's Int type typically supports values between 2,147,483,648 and 2,147,483,647
      // Create a new URL without 'chatRoomId'
      const url = new URL(window.location.href)
      url.searchParams.delete('chatRoomId')
      // Update the URL without 'chatRoomId'
      window.history.replaceState({}, document.title, url.toString())
    } else {
      // Check if chatRoomId is valid when it is an integer
      // This validation may be implemented inside ChatRoom / ChatInner component
    }
  }

  // Check if agentId present or not in the url params
  // if (agentId !== null && agentId !== undefined) {
  //   // Check if it is a NaN value or correct agentId
  //   agentID = !!Number(agentId) ? Number(agentId) : 0
  //   if (agentID === 0 || agentID > 2147483648) {
  //     //GraphQL's Int type typically supports values between 2,147,483,648 and 2,147,483,647
  //     // Create a new URL without 'agentId'
  //     const url = new URL(window.location.href)
  //     url.searchParams.delete('chatRoomId')
  //     // Update the URL without 'agentId'
  //     window.history.replaceState({}, document.title, url.toString())
  //   } else {
  //     // Check if agentId is valid when it is an integer
  //   }
  // }

  return (
    <div className='card card-flush'>
      {type === 'Assigned' && (
        <div className='card-header d-flex align-items-center'>
          <h2 className='p-4'>Chat</h2>
          <div>
            <ChatHeaderComponents />
          </div>
        </div>
      )}
      <div className='d-flex flex-column flex-lg-row mt-4 mx-4'>
        {(type === 'Unassigned' ||
          type === 'Assigned' ||
          type === 'Resolved' ||
          type === 'Chat-Report') && (
          <ChatLeftPanel
            type={type}
            chatRoomID={chatRoomID}
            isData2={isData2}
            isData3={isData3}
            handleOpenChat={handleOpenChat}
            disabledIndexes={disabledIndexes}
          />
        )}
        {(type === 'Unassigned' ||
          type === 'Assigned' ||
          type === 'Resolved' ||
          type === 'Chat-Report') && (
          //   !!chatRoomID &&
          //   chatRoomID > 0 &&
          //   chatRoomID <= 2147483648 &&
          <ChatRoom
            type={type}
            chatRoomID={chatRoomID}
            // agentID={agentID}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setIsData2={setIsData2}
            setIsData3={setIsData3}
            handleOpenChat={handleOpenChat}
            handleCloseChat={handleCloseChat}
            handleMinimizeChat={handleMinimizeChat}
            chatPanels={chatPanels}
            disabledIndexes={disabledIndexes}
          />
        )}
      </div>
    </div>
  )
}

export default AllChats

// Refer to this article to know more about comparing Arrays: https://masteringjs.io/tutorials/fundamentals/compare-arrays
