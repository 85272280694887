/* 
01 workflow just created should be displayed rightaway 
02 editing of a status to change its status category (toggle switch) must be facilitated, we've no provisions to edit the status type via API, though
*/
import { useState, useEffect } from 'react';
import { useTeamsQuery, useWorkflowsMutation } from '../../../../Services/settingsAPIs/CommonAPI';
import ReactModal from 'react-modal';
import { defaultModalStyles, defaultScrollableModalStyles } from '../../../common/CustomModalStyles';
import NewWorkflowModal from './NewWorkflowModal';
import NewStateModal from './NewStateModal';
import DeleteModal from './DeleteModal';
import { useDeleteClosedStageByIdMutation, useDeleteInProgressStageByIdMutation, useDeleteWorkflowByIdMutation } from '../../../../Services/settingsAPIs/TicketSettingsAPI';

const DeleteMessageBody = ({ bodyParams }) => {
  return (
    <div>
      {bodyParams.descriptionPromptEnabled ? (<>
        <p className='fs-5 fw-bold'>Are you sure you want to delete the following {bodyParams.deletionOf}?</p>
        <div className='card-text'>
          Title: <strong>{bodyParams.deletionItemTitle}</strong>
        </div>
        <div className='card-text'>
          Description: <strong>{bodyParams.deletionItemDescription}</strong>
        </div>
      </>) : (<>
        <p className='fs-5 fw-bold'>Are you sure?</p>
        <div className='card-text'>
          <span className="text-danger">Caution</span>: <strong>Please confirm to delete the {bodyParams.deletionOf} {bodyParams?.deletionItemCautionMsg}</strong>
        </div>
      </>)}
    </div>
  );
};

const WorkFlow = () => {
  const [theworkflows, setTheWorkflows] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<number>(-1)
  const [selectedWFInfo, setSelectedWFInfo] = useState<any>()

  const [isNewWFModalOpen, setIsNewWFModalOpen] = useState<boolean>(false);
  const openAddNewWorkflowModal = () => { setIsNewWFModalOpen(true) }
  const closeAddNewWorkflowModal = () => { setIsNewWFModalOpen(false); setWorkflowForEdit({ ...workflowForEdit, workflowEditEnabled: false }); fetchAndSetWorkflows(); }

  const [isNewStateModalOpen, setIsNewStateModalOpen] = useState<boolean>(false);
  const openNewStateModal = () => { setIsNewStateModalOpen(true) }
  const closeNewStateModal = () => { setIsNewStateModalOpen(false); setStatusForEdit({ ...statusForEdit, statusEditEnabled: false }); fetchAndSetWorkflows(); }

  const [isDeleteWFModalOpen, setIsDeleteWFModalOpen] = useState<boolean>(false);
  const openDeleteWFModal = () => { setIsDeleteWFModalOpen(true) }
  const closeDeleteWFModal = () => { setIsDeleteWFModalOpen(false); setWorkflowSelected(0); fetchAndSetWorkflows(); }

  const [statusForDeletion, setStatusForDeletion] = useState<any>();
  const [isDeleteStatusModalOpen, setIsDeleteStatusModalOpen] = useState<boolean>(false);
  const openDeleteStatusModal = () => { setIsDeleteStatusModalOpen(true) }
  const closeDeleteStatusModal = () => { setIsDeleteStatusModalOpen(false); fetchAndSetWorkflows(); }

  const [statusForEdit, setStatusForEdit] = useState<any>({ statusEditEnabled: false, statusData: null });
  const [workflowForEdit, setWorkflowForEdit] = useState<any>({ workflowEditEnabled: false, workflowData: null });
  const [triggerGetWorkflowsMutation] = useWorkflowsMutation()
  const [triggerDeleteWFMutation] = useDeleteWorkflowByIdMutation();
  const [triggerDeleteClosedStatusMutation] = useDeleteClosedStageByIdMutation();
  const [triggerDeleteInProgressStatusMutation] = useDeleteInProgressStageByIdMutation();
  const { data: teams, isLoading } = useTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [assignedMaxId, setAssignedMaxId] = useState<number>(0);

  const colorCode = (stage: any) => {
    switch (stage.status_type) {
      case 'active': { return 'border-success'; break; }
      case 'wip': { return 'border-danger'; break; }
      case 'closed': { return 'border-warning'; break; }
      case 'reopen': { return 'border-primary'; break; }
      default: { return 'border-secondary'; break; }
    }
  }

  const initiateWorkflowEdit = (stage: any) => {
    setWorkflowForEdit(
      {
        workflowEditEnabled: true,
        workflowData:
        {
          name: selectedWFInfo.name,
          active_status: selectedWFInfo.active_status,
          closed_status: selectedWFInfo.closed_status,
          reopen_status: selectedWFInfo.reopen_status,
          id: selectedWFInfo.id
        }
      }
    )
    openAddNewWorkflowModal();
  }

  const initiateStatusEdit = (stage: any) => {
    setStatusForEdit(
      {
        statusEditEnabled: true,
        statusData:
        {
          id: stage.status.id,
          status: stage.status.status,
          ticket_name: -1,
          assignment_team: stage.status.assignment_team?.id,
          visibility_team: stage.status.visibility_team?.id,
          ticket_name_mapped: stage.status.ticket_name_mapped,
          main_status: stage.status.main_status,
          wip_status: stage.status.wip_status,
          closed_status: stage.status.closed_status,
          disposition: stage.status.disposition?.id,
          user: stage.status.user,
          round_robin: stage.status.round_robin,
          FIFO: stage.status.FIFO,
          statusType: stage.status_type
        }
      }
    )
    openNewStateModal();
  }
  const fetchAndSetWorkflows = async () => { triggerGetWorkflowsMutation({}).then((res: any) => res.data && setTheWorkflows(res.data?.results)) }
  const addNewStatus = () => { setSelectedWFInfo(theworkflows?.find((x: any) => x.id === workflowSelected)); openNewStateModal(); }
  const deleteStatus = async (stage: any) => { setStatusForDeletion(stage); openDeleteStatusModal() }
  useEffect(() => { setSelectedWFInfo(theworkflows?.find((x: any) => x.id === workflowSelected)); }, [workflowSelected])
  useEffect(() => { fetchAndSetWorkflows() }, []);
  useEffect(() => { setTheTeams(teams) }, [teams]);
  useEffect(() => {
    if (theworkflows?.length) {

      /* const ami = theworkflows.length>0 ? Math.max(...theworkflows?.map((workflow:any)=>Math.max(...workflow?.all_statuses?.map((stage:any)=>Object.hasOwn(stage.status,'id')?stage.status.id:0)))): 1; */
      const ami = theworkflows.length > 0
        ? Math.max(...theworkflows.flatMap((workflow) =>
          workflow.all_statuses
            .filter((stage) => stage.status !== null && typeof stage.status === 'object' && 'id' in stage.status)
            .map((stage) => stage.status.id ?? 0)
        ), 0)
        : 1;

      setAssignedMaxId(ami);
    }
  }, [theworkflows]);

  return (<>
    <div className='card-body px-0 px-lg-10 py-10 py-lg-0' style={{marginLeft:"2%"}}>
      <div className="row my-4">
        <div className="col-12">
          <div className="card-toolbar d-lg-flex gap-5">
            <div className="d-flex flex-column">
              <label>Select workflow</label>
              <div className="min-w-250px">
                <select className="form-select" onChange={(e) => setWorkflowSelected(Number.parseInt(e.target.value))}>
                  <option selected disabled value={-1}>
                    Choose a workflow
                  </option>
                  {theworkflows?.map((theworkflow: any) => (<option value={theworkflow.id}>{theworkflow.name}</option>))}
                </select>
              </div>
            </div>
            <span className="input-group-text bg-white border-0 ms-auto align-self-start" id="basic-addon1">
              <div className="ms-auto d-flex">
                <button
                  className="input-group-text form-control btn-sm form-control align-items-center text-hover-primary fw-bold min-w-150px"
                  onClick={openAddNewWorkflowModal}>
                  <i className="bi bi-plus-lg me-2"></i>
                  <span>New workflow</span>
                </button>
              </div>
            </span>
          </div>
        </div>
        <ReactModal isOpen={isNewWFModalOpen} onRequestClose={closeAddNewWorkflowModal} style={defaultModalStyles} contentLabel='Add a new workflow'>
          <NewWorkflowModal closeModal={closeAddNewWorkflowModal} workflowForEdit={workflowForEdit} />
        </ReactModal>
        <ReactModal isOpen={isDeleteWFModalOpen} onRequestClose={closeDeleteWFModal} style={defaultModalStyles} contentLabel='Delete a selected workflow'>
          <DeleteModal
            closeModal={closeDeleteWFModal}
            deleteAPItrigger={triggerDeleteWFMutation}
            deletionIdentifier={workflowSelected}
            messageBody={<DeleteMessageBody bodyParams={{ descriptionPromptEnabled: false, deletionOf: "workflow" }} />}
            toastSuccessMessage="Workflow deleted successfully!"
          />
        </ReactModal>
        <ReactModal isOpen={isDeleteStatusModalOpen} onRequestClose={closeDeleteStatusModal} style={defaultModalStyles} contentLabel='Delete a selected status'>
          <DeleteModal
            closeModal={closeDeleteStatusModal}
            deleteAPItrigger={statusForDeletion?.status_type === 'closed' ? triggerDeleteClosedStatusMutation : triggerDeleteInProgressStatusMutation}
            deletionIdentifier={statusForDeletion?.status.id}
            messageBody={<DeleteMessageBody bodyParams={{ descriptionPromptEnabled: false, deletionOf: "stage" }} />}
            toastSuccessMessage="Stage deleted successfully!"
          />
        </ReactModal>
        <ReactModal isOpen={isNewStateModalOpen} onRequestClose={closeNewStateModal} style={defaultScrollableModalStyles} contentLabel="New State">
          <NewStateModal closeNewStateModal={closeNewStateModal} statusForEdit={statusForEdit} selectedWFinfo={selectedWFInfo} assignedMaxId={assignedMaxId} />
        </ReactModal>
      </div>
      <div className="row my-6">
        <div className="col d-flex">
          <input type="checkbox" style={{ outline: '1px solid green', border: "none", backgroundPosition: 'center', borderRadius: 0 }} disabled className="form-check-input" />
          <label htmlFor="" className="form-label ms-4">Open</label>
          <input type="checkbox" style={{ outline: '1px solid red', border: "none", backgroundPosition: 'center', borderRadius: 0 }} disabled className="ms-4 form-check-input" />
          <label htmlFor="" className="form-label ms-4">In progress</label>
          <input type="checkbox" style={{ outline: '1px solid yellow', border: "none", backgroundPosition: 'center', borderRadius: 0 }} disabled className="ms-4 form-check-input" />
          <label htmlFor="" className="form-label ms-4">Close</label>
          <input type="checkbox" style={{ outline: '1px solid blue', border: "none", backgroundPosition: 'center', borderRadius: 0 }} className="ms-4 form-check-input" id="reopenablestatus" />
          <label htmlFor="reopenablestatus" className="form-label ms-4">Reopen</label>
        </div>
      </div>
      <div className="row my-6">
        {theworkflows[0] && theworkflows.find((x: any) => x.id === workflowSelected)?.all_statuses.map((stage: any, index: number) => (<>
          <div className="col-auto d-flex mt-10">
            {stage.status_type !== "active" && index != 1 ? (<>
              <div className="mx-4 d-flex flex-column pb-2">
                <label>Assigned to</label>
                <div className="min-w-250px">
                  <select className="form-select form-select-sm" value={stage.status?.assignment_team ? stage.status.assignment_team.id : 0}>
                    <option value="select team" defaultValue={0}></option>
                    {theTeams.map((theTeam: any) => (<option value={theTeam.id}>{theTeam.name}</option>))}
                  </select>
                </div>
                <div className="d-flex">
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i>
                  <i className="bi bi-dash-lg fs-2 text-dark"></i><i className="text-dark bi bi-arrow-right fs-2"></i>
                </div>
                <label>Visible to</label>
                <div className="min-w-250px">
                  <select className="form-select form-select-sm" value={stage.status?.assignment_team ? stage.status.assignment_team.id : 0}>
                    <option value="select team"></option>
                    {theTeams.map((theTeam: any) => (<option value={theTeam.id}>{theTeam.name}</option>))}
                  </select>
                </div>
              </div>
            </>) : index === 1 && (<>
              <div className="d-flex align-items-center p-4">
                <i className="bi bi-dash-lg fs-2 text-dark"></i>
                <i className="bi bi-dash-lg fs-2 text-dark"></i>
                <i className="bi bi-dash-lg fs-2 text-dark"></i>
                <i className="text-dark bi bi-arrow-right fs-2"></i>
                {/* <i className="bi bi-caret-right-fill  fs-1"></i> */}
              </div>
            </>)}
            <div
              className={`border ${colorCode(stage)} d-flex flex-column justify-content-center align-items-center`}
              style={{
                width: "150px",
                height: "150px",
                overflow: "hidden", // Hide any overflowing content
                position: "relative",
              }}
            >
              <span
                className="text-center px-2"
                style={{
                  overflowY: "auto", // Enable vertical scrolling if content overflows
                  wordBreak: "break-word", // Break words to prevent horizontal overflow
                  maxHeight: "100%", // Keep the content within the height of the card
                  display: "block", // Ensure proper rendering of the text
                  textAlign: "center",
                }}
              >
                {stage.status_type === "active" || stage.status_type === "reopen"
                  ? stage.status
                  : stage.status.status}
              </span>
              <div className="d-flex">
                {stage.status_type === "active" || stage.status_type === "reopen" ? (
                  <>
                    <button
                      className="btn btn-link"
                      onClick={() => initiateWorkflowEdit(stage)}
                    >
                      <i className="bi text-hover-primary bi-pencil-fill fs-2 py-2"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-link"
                      onClick={() => initiateStatusEdit(stage)}
                    >
                      <i className="bi text-hover-primary bi-pencil-fill fs-2 py-2"></i>
                    </button>
                    <button className="btn btn-link" onClick={() => addNewStatus()}>
                      <i className="ps-1 bi text-hover-primary bi-plus-lg fs-2 py-2"></i>
                    </button>
                    <button className="btn btn-link" onClick={() => deleteStatus(stage)}>
                      <i className="ps-1 bi text-hover-primary bi-trash-fill fs-2 py-2"></i>
                    </button>
                  </>
                )}
              </div>
            </div>

          </div>
        </>))}
      </div>
      <div className="row my-6">
        <div className="col text-end">
          <i className={`bi bi-trash text-end fa-2x text-danger px-lg-10 px-0 ${workflowSelected === -1 ? 'd-none' : ''}`} style={{ cursor: "pointer" }} onClick={openDeleteWFModal}></i>
        </div>
      </div>
    </div>
  </>)
};

export default WorkFlow;