import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    AUTH_TOKEN,
    CALL,
    CALL_HISTORY_ID,
    EXTENSION,
    INCOMING_CALL,
    OUTGOING_CALL,
    TELEPHONEURL,
    USER_TEAM_ID,
} from "../../constants";
import { Box, Modal, Typography } from "@mui/material";

import {
    useCallHistoryIdMutation,
    useTelephonyDispositionQuery,
} from "../../Services/Telephony/TelephonyFrontend";
import { toast } from "react-toastify";
import { useTelephony } from "../context/TelephonyContext";
import TelephonyDisposition from "./TelephonyDisposition";
import LoadingSkeleton from "../LoadingSkeleton";
import ButtonLoadingSkeleton from "../ButtonsLoadingSkeleton";

interface IPROPS {
    setIsTransferCallActive: any;
    conferenceNumber: any;
    setInbound:any
    onClick?: () => void;
    style?: React.CSSProperties; // Add this line
    setCheckTransferCallNumberEntered: any;
    setCall: any;
    call: any;
    setIsCallPaused:any

    setIsConferenceCallActive:any
    setCheckConferenceCallNumberEntered:any
    pauseHandler:any

}

const TransferCall: React.FC<IPROPS> = ({
    call,
    setIsTransferCallActive,
    conferenceNumber,
    setCheckTransferCallNumberEntered,
    setCall,
    setIsConferenceCallActive,
    setIsCallPaused,
    setInbound,
    setCheckConferenceCallNumberEntered,
    pauseHandler
}) => {
    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
    const telephoneData = JSON.parse(gettingTelephonyData);
    const gettingExtension = localStorage.getItem(EXTENSION);
    const callHistoryId = localStorage.getItem(CALL_HISTORY_ID);
    const getTEAMID = localStorage.getItem(USER_TEAM_ID);
    const [storedDisposition, setStoredDisposition] = useState([]);
    const [openTelephonyDispositionModal, setOpenTelephonyDispositionModal] = useState(false)


    const {
        setCallActive,
        setCallDisconnected,
        setLifitinMobileNumber
   
    } = useTelephony();


    const [callHistoryTrigger] = useCallHistoryIdMutation();
            const outgoing = localStorage.getItem(OUTGOING_CALL)
            const incoming  = localStorage.getItem(INCOMING_CALL)
           const incomingCallHistoryId = localStorage.getItem("IncomingCallId")



    // const gettingCallHistoryId = outgoing  ? callHistoryId : incoming ? incomingCallHistoryId : null;
    const gettingCallHistoryId = outgoing === "true" ?  callHistoryId : incomingCallHistoryId;




    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;
    const mobileNumber = localStorage.getItem('liftingMobileNumber')

    
    const transferCallHandler = async () => {
        setIsTransferCallActive(true);
        
        if (!conferenceNumber) {
            toast.warning("Enter a number to transfer");
            return;
        }
        
        if (conferenceNumber === mobileNumber) {
            toast.error("Number shall be different")
            return
        }
        if (!/^\d{10}$/.test(conferenceNumber)) {
            toast.error("Please enter a valid 10-digit number");
            return;
        }
    
        setDisconnectCallLoader(true)
        toast.warning("Please wait while we are transferring a call");

        // Reset the liftingMobileNumber and update state
        setCheckTransferCallNumberEntered(true);


    
        const url = telephoneData?.https_port > 0 ? 
        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${conferenceNumber}&Type=blind&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`

        : 
        `https://${telephoneData?.domain}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${conferenceNumber}&Type=blind&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`



        try {
            // Transfer call API request
            const transferCall = await axios.post(
                url
            );

            // End-call action
            const callHistoryObj = {
                action: "end-call",
            };
           const transferCallDisposed =  await callHistoryTrigger({
                body: callHistoryObj,
                id: gettingCallHistoryId,
            });

            if (transferCallDisposed?.error) {
                throw new Error('Failed to transfer the call')

            }
            setIsConferenceCallActive(false)
            setLifitinMobileNumber("");
            localStorage.removeItem("liftingMobileNumber");
            localStorage.removeItem("telephony-timer");
            // localStorage.setItem(CALL, "false");
            setCallActive(false);
            setCallDisconnected(true);



           const dispositionCall =   await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/disposition/?team=${getTEAMID}&type=${outgoing ? "OB" : incoming ? "IB" : ""}&`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );
            
            if (dispositionCall?.data) {
                setStoredDisposition(dispositionCall?.data?.results);
            setOpenTelephonyDispositionModal(true);


            } else {
                toast.error("An error occured");
            }
            setDisconnectCallLoader(false)
        } catch (error) {
            setDisconnectCallLoader(false)
            toast.error("Failed to transfer call");
        }
    };

    const closeDispositionModall = () => {
        setOpenTelephonyDispositionModal(false);
    };


    const modalStyles = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
    };
    const {disconnectCallLoader, setDisconnectCallLoader } = useTelephony()





    

    return (
        <>
            <div>
                {disconnectCallLoader ? <ButtonLoadingSkeleton /> :
                <div>
                    <i
                        onClick={transferCallHandler}
                        style={{
                            fontSize: "15px",
                            padding: "8px",
                            background: "#3bbf3b",
                            borderRadius: "50%",
                            color: "white",
                        }}
                        className="bi bi-telephone-forward"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Transfer"
                    ></i>
                </div>
                }
            </div>

            <Modal
            open={openTelephonyDispositionModal}
            onClose={closeDispositionModall}
            aria-labelledby="telephony-disposition-modal-title"
            aria-describedby="telephony-disposition-modal-description"
        >
            <Box sx={modalStyles}>
                <Typography
                    id="telephony-disposition-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mb: 2 }}
                >
                    Disposition
                </Typography>
                <TelephonyDisposition
                    Disposition={storedDisposition}
                    setCall={setCall}
                    // isLoading={isLoading}
                    closeModal={closeDispositionModall}
                    setIsCallPaused={setIsCallPaused}
    pauseHandler={pauseHandler}

                    ticketId={gettingCallHistoryId}
                    setInbound={setInbound}
                    setIsConferenceCallActive={setIsConferenceCallActive}
                    setCheckConferenceCallNumberEntered={setCheckConferenceCallNumberEntered}

                    // value={""}

                />
            </Box>
        </Modal>

        </>
    );
};

export default TransferCall;
