import React, { useState, useEffect } from "react";
import {
    useAddAgentMappingExtensionsMutation,
    useEditAgentMappingExtensionsQuery,
    useEditAgentMappingUsersQuery,
    useUpdateAgentMappingExtensionsMutation,
} from "../../../../Services/Telephony/Telephony";
import {
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from "@mui/material";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import LoadingSpinner from "../../../LoadingSpinner";
import "./styles.scss";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";

interface IPROPS {
    closeModal: () => void;
    refetch: () => void;
    agentMappingQueueData: { id: number; name: string }[];
}

const EditAgentMapping: React.FC<IPROPS> = ({
    closeModal,
    refetch,
    agentMappingQueueData,
}) => {
    const [agentUsersData, setAgentUsersData] = useState([]);
    const [extensions, setExtensions] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [selectedExtensions, setSelectedExtensions] = useState<string>("");
    const [selectedQueueIds, setSelectedQueueIds] = useState<number[]>([]);
    const [selectedCallerType, setSelectedCallerType] = useState<string>("");
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [usersLoader, setUsersLoader] = useState(false);


    // APIs
    const {
        data: usersData,
        status: agentMappingUserStatus,
        refetch: refetchUserData,
    } = useEditAgentMappingUsersQuery({});
    const { data: extensionData } = useEditAgentMappingExtensionsQuery({});
    const [addAgentMappingTrigger] = useAddAgentMappingExtensionsMutation();

    const callerTypes = ["inbound", "autodial"];

    useEffect(() => {
        if (agentMappingUserStatus === "pending") {
            setUsersLoader(true);
        } else {
            setUsersLoader(false);
        }
    }, [agentMappingUserStatus]);

    useEffect(() => {
        if (usersData) {
            setAgentUsersData(usersData);
        } else {
            refetchUserData(); // Ensure data is fetched on first load
        }
    }, [usersData, refetchUserData]);

    useEffect(() => {
        if (extensionData) {
            setExtensions(extensionData);
        }
    }, [extensionData]);

    const handleQueueSelection = (event: any) => {
        const {
            target: { value },
        } = event;

        setSelectedQueueIds(value);
        setIsButtonDisabled(false)
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsButtonDisabled(true);

        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 3000);

        // if (!selectedCallerType || !selectedExtensions || !selectedUserId) {
        //     toast.error("All fields are required.");
        //     return;
        // }

        const updateAgentObj = {
            call_type: selectedCallerType,
            extension_no: selectedExtensions,
            queues: selectedQueueIds,
            user_id: selectedUserId,
        };

        try {
            const response = await addAgentMappingTrigger({
                body: updateAgentObj,
            });

            if (response?.data) {
                closeModal();
                refetch();
                toast.success("Updated successfully");
            } else if ("data" in response.error) {
                const errorData = response.error.data as {
                    queues?: string[];
                    extension_no?: string[];
                    call_type?: string[];
                    user_id?: string[];
                };

                setIsButtonDisabled(true);

                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 3000);

                const errorMessage =
                    errorData?.queues?.[0] ||
                    errorData?.extension_no?.[0] ||
                    errorData?.call_type?.[0] ||
                    errorData?.user_id?.[0] ||
                    "An error occurred";
                toast.error(errorMessage);
                setIsButtonDisabled(true);

                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 3000);
            }
        } catch {
            toast.error("An error occurred");
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 120,
            },
        },
    };


    return (
        <div
            className="addExtension_cont"
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
            <div>
                <label>Caller type</label>
                <select
                    className="form-control"
                    onChange={(e) => {
                        setSelectedCallerType(e.target.value)
                        setIsButtonDisabled(false)
                    }}
                    value={selectedCallerType}
                >
                    <option value="" disabled>
                        Select caller type
                    </option>
                    {callerTypes.map((caller, index) => (
                        <option key={index} value={caller}>
                            {capitalizeFirstLetter(caller)}
                        </option>
                    ))}
                </select>
            </div>

            <FormControl fullWidth>
                <label>Select user</label>
                <Select
                    labelId="user-select-label"
                    value={selectedUserId !== null ? selectedUserId : ""}
                    onChange={(e) => {
                        setSelectedUserId(Number(e.target.value))
                        setIsButtonDisabled(false)
                    }}
                    // label="Select user"
                    MenuProps={MenuProps}
                >

                    <MenuItem value="" disabled>
                        {usersLoader ? (
                            <>
                                Searching... <CircularProgress size={20} />
                            </>
                        ) : (
                            "Select User"
                        )}
                    </MenuItem>
                    

                            {!usersLoader && agentUsersData.map((user, index) => (
                                <MenuItem key={index} value={user.id}>
                                    {user.username}
                                </MenuItem>
                            ))}
                            

             

                </Select>
            </FormControl>

            {/* <div style={{ position: "relative" }}>
    <label style={{ marginBottom: "0.5rem", display: "inline-block" }}>Select user</label>
    <select
      maxMenuHeight={30}
        // className="form-control"
        style={{

            maxHeight: "200px", // Limits the dropdown height
            overflowY: "auto", // Adds a vertical scrollbar for overflowing content
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "0.5rem",
            position: "relative",
        }}
        value={selectedUserId || ""}
        onChange={(e) => setSelectedUserId(Number(e.target.value))}
    >
        <option value="" disabled>
            {usersLoader ? (
                <>
                    Searching...
                    <LoadingSpinner />
                </>
            ) : (
                "Select User"
            )}
        </option>
        {agentUsersData.map((user, index) => (
            <option key={index} value={user.id}>
                {user.username}
            </option>
        ))}
    </select>
</div> */}


<FormControl fullWidth>
<label>Select extension</label>

                <Select
                    labelId="user-select-label"
                    value={selectedExtensions || ""}
                    onChange={(e) => {
                        setSelectedExtensions(e.target.value)
                        setIsButtonDisabled(false)
                    
                    }}
                    // label="Select user"
                    MenuProps={MenuProps}
                >
                    <MenuItem value="" disabled>
                      select Entension
                    </MenuItem>

                
                            {extensions.map((extension, index) => (
                                <MenuItem 
                                key={index} value={extension?.extension}>
                            {extension.extension}
                                </MenuItem>
                            ))}
                   
                    {/* Map agentUsersData */}
                </Select>
            </FormControl>





            {/* <div>
                <select
                    className="form-control"
                    value={selectedExtensions}
                    onChange={(e) => setSelectedExtensions(e.target.value)}
                >
                    <option value="" disabled>
                        Select extension
                    </option>
                    {extensions.map((extension, index) => (
                        <option 
                        key={index} value={extension.extension}>
                            {extension.extension}
                        </option>
                    ))}
                </select>
            </div> */}
            {/* <InputLabel id="queue-select-label">Select queue</InputLabel> */}
            <FormControl fullWidth>
                <label>Select queue</label>
                <Select
                    labelId="queue-select-label"
                    id="queue-select"
                    multiple
                    MenuProps={MenuProps}
                    value={selectedQueueIds}
                    onChange={handleQueueSelection}
                    renderValue={(selected) =>
                        agentMappingQueueData
                            .filter((queue) => selected.includes(queue.id))
                            .map((queue) => queue.name)
                            .join(", ")
                    }
                >
                    {agentMappingQueueData.map((queue) => (
                        <MenuItem key={queue.id} value={queue.id}>
                            <Checkbox
                                checked={selectedQueueIds.includes(queue.id)}
                            />
                            <ListItemText primary={queue.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                }}
            >
                <button
                    type="reset"
                    className="btn btn-sm btn-white  me-2"
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditAgentMapping;
