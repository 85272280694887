import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import EmptyRows from "../../../../components/reusableComponent/EmptyRows";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
const Apexchart: React.FC<any> = ({ data }) => {

  const [modalData, setModalData] = useState<{ date: string, leads: number, allLeadsList: any }>({
    date: '',
    leads: 0,
    allLeadsList: []
  });
  const [chartData, setChartData] = useState<any>({
    options: {
      chart: {
        id: "leads-count",
        type: "area",
        toolbar: {
          show: false,
        },
        offsetX: -3,
        events: {
          click: (event: any, chartContext: any, config: any) => {
            const campaignId = Object.keys(data.lead_stats.leads_per_campaign)[0]; // Get the first campaign ID dynamically
            const leadsByDate = data.lead_stats.leads_per_campaign[campaignId].leads_by_date;
            const date = Object.keys(leadsByDate)[config.dataPointIndex];
            const leadDetails = leadsByDate[date];
            console.log(leadDetails, "leadDetails");
            openModal(date, leadDetails.lead_details, leadDetails.count);
          },

        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      xaxis: {
        categories: [],
        labels: {
          rotate: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return Math.floor(val); // Ensure that only whole numbers are shown
          },
        },
        min: 0,
        forceNiceScale: true,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      tooltip: {
        enabled: true, // Ensure tooltip is enabled
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          left: 25,
          right: 25,
        },
      },
    },
    series: [],
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [leadIds, setLeadIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const openModal = (date: string, leads: any, count: any) => {
    setModalData({
      date: date,
      leads: count,
      allLeadsList: leads
    })

    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedDate("");
    setLeadIds([]);
  };

  useEffect(() => {
    const leadsByDate = data?.lead_stats?.leads_per_campaign
      ? Object.values(data?.lead_stats?.leads_per_campaign).flatMap((campaign: any) =>
        Object.entries(campaign?.leads_by_date ?? {}).map(([date, leadData]: [string, any]) => ({
          date,
          leads: leadData?.count // Using "count" from the response
        }))
      )
      : [];

    const categories = leadsByDate.map((dateEntry: any) => {
      const [year, month, day] = dateEntry?.date?.split("-");
      return `${day}-${month}-${year}`;
    });

    const leads = leadsByDate.map((entry: any) => entry?.leads);

    // Update the chart data
    setChartData({
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories,
        },
      },
      series: [
        {
          name: "Leads Count",
          data: leads,
        },
      ],
    });
  }, [data]);

  const ticketDashboardDisposition = "disposition";
  const isSeriesEmpty = !chartData?.series?.length || !chartData?.series[0]?.data?.length;
  const [year, month, day] = modalData.date.split('-');
  const formattedDate = `${day}-${month}-${year}`;
  return (
    <div className="app py-5">
      {isSeriesEmpty ? (
        <p className="text-center" style={{ marginTop: "140px", height: "400px" }}>
          <EmptyRows value={ticketDashboardDisposition} />
        </p>
      ) : (
        <div className="row">
          <div className="mixed-chart">
            <Chart options={chartData.options} series={chartData.series} type="area" height="420px" />
          </div>
        </div>
      )}

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // This will center the modal both horizontally and vertically
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2, // Optional: Adds rounded corners to the modal
            width: '40vw', // Optional: Controls the modal's width, can be set to a specific value like '400px'
            maxHeight: '80vh', // Optional: Ensures the modal content is scrollable if content overflows
            overflowY: 'auto', // Enables scrolling if content overflows vertically

          }}
        >
          {/* Close Icon in Top-Right Corner */}
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'gray', // You can adjust this color as needed
            }}
          >
            <CloseIcon />
          </IconButton>

          <h2 id="modal-modal-title">{`Leads on ${formattedDate}`}</h2>
          <p id="modal-modal-description">
            Total leads: {modalData.leads}
          </p>

          {/* Table for account details */}
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Lead id</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>First name</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Email</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Phone number</th>
              </tr>
            </thead>
            <tbody>
              {modalData?.allLeadsList?.map((lead, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/campaign/lead/${lead.lead_id}`)}
                >
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.lead_id}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.first_name}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.email}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.phone_number}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Modal>
    </div>
  );
};

export default Apexchart;
