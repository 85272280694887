import React, { useEffect, useRef, useState } from "react";
import { CardContent } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { useGetAllCampaignQuery, useGetCampaignLeadReportQuery } from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";
import ReactModal from "react-modal";
import AddEmailToReport from "./AddEmailToReport/AddEmailToReport";
const CampaignLeadReport = () => {
  const navigate = useNavigate();

  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const { data: campaignData, isLoading: campaignDataLoading } = useGetAllCampaignQuery({});
  const [params, setParams] = useState<any>(null);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [campaignSelected, setCampaignSelected] = useState<any>([])
  const [isFilterModalOpen, setFilterIsModalOpen] = useState(false);
  const [columns, setCololumns] = useState<any>([
    { field: "srNo", headerName: "Sr.No", flex: 1, minWidth: 100, align: "center", headerAlign: "center" },
    { field: "createdDateTime", headerName: "Created Date and Time", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
    { field: "leadId", headerName: "Lead Id", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
    { field: "accountName", headerName: "Account Name", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
    { field: "firstName", headerName: "First Name", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
    { field: "lastName", headerName: "Last Name", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
    { field: "currentAssignee", headerName: "Current Assignee", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
    { field: "lastUpdatedDateTime", headerName: "Last Updated Date and Time", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
    { field: "status", headerName: "Status", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
    { field: "internalNotes", headerName: "Internal Notes", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
  ])


  const [selectQueue, setSelectQueue] = useState<any>('')

  const {
    data: CampaignLeadReport,
    isLoading: CampaignLeadReportLoading,
    error: CampaignLeadReportError
  } = useGetCampaignLeadReportQuery(params, {
    skip: !params,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });


  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };


  const handleQueueChange = (event: any) => {
    setSelectQueue(event.target.value);
  };



  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);
  useEffect(() => {
    if (CampaignLeadReportError) {
      setDataRows([]);
      setLocalLoading(false);
    }
  }, [CampaignLeadReportError])

  useEffect(() => {
    if (campaignData && Array.isArray(campaignData.results)) {
      const transformedData = campaignData.results.map((queue) => ({
        id: queue.id,
        name: queue.name,
      }));
      setCampaignSelected(transformedData);
    }
  }, [campaignData]);

  const handleSubmit = (e: React.FormEvent) => {
    console.log("Form submitted");
    e.preventDefault(); // Prevent form submission

    // Reset params before processing
    setParams(null);

    // Validation for date range
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (new Date(dateFrom) > new Date(dateTo)) {
      toast.error('"From Date" cannot be later than "To Date"');
      return;
    }

    // Validation for campaign selection
    if (!selectQueue || selectQueue === '') {
      toast.error('Please select a campaign from the dropdown');
      return;
    }

    // Build query params
    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
      timestamp: new Date().getTime(), // Add a unique timestamp to ensure reference change
    };

    if (selectQueue !== 'All') {
      const selectedQueue = campaignSelected.find(
        (queue: any) => queue.id === selectQueue
      );
      if (!selectedQueue) {
        toast.error('Invalid campaign selected');
        return;
      }
      queryParams.campaign = selectedQueue.id;
    }

    // Set params to trigger data fetching
    setParams(queryParams);
  };


  useEffect(() => {
    if (CampaignLeadReport === undefined) {
      setLocalLoading(false);
    } else if (Array.isArray(CampaignLeadReport)) {
      // Define static columns with minimum widths
      setCololumns([
        { field: "srNo", headerName: "Sr.No", flex: 1, minWidth: 100, align: "center", headerAlign: "center" },
        { field: "createdDateTime", headerName: "Created Date and Time", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
        { field: "leadId", headerName: "Lead Id", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
        { field: "accountName", headerName: "Account Name", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
        { field: "firstName", headerName: "First Name", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
        { field: "lastName", headerName: "Last Name", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
        { field: "email", headerName: "Email", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
        { field: "currentAssignee", headerName: "Current Assignee", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
        { field: "lastUpdatedDateTime", headerName: "Last Updated Date and Time", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
        { field: "status", headerName: "Status", flex: 1, minWidth: 150, align: "center", headerAlign: "center" },
        { field: "internalNotes", headerName: "Internal Notes", flex: 1, minWidth: 200, align: "center", headerAlign: "center" },
      ]);

      // Collect unique dynamic field keys
      const dynamicFieldKeys = new Set<string>();
      CampaignLeadReport.forEach((item) => {
        if (item.dynamic_fields) {
          Object.keys(item.dynamic_fields).forEach((key) => dynamicFieldKeys.add(key));
        }
      });

      // Add dynamic columns with minimum width
      const dynamicColumns = Array.from(dynamicFieldKeys).map((key) => ({
        field: key,
        headerName: key,
        flex: 1,
        minWidth: 150, // Minimum width for dynamic fields
        align: "center",
        headerAlign: "center",
      }));
      setCololumns((prevColumns) => [...prevColumns, ...dynamicColumns]);

      // Transform rows including dynamic fields
      const transformedRows = CampaignLeadReport.map((item, index) => {
        const dynamicFields = item.dynamic_fields
          ? Object.fromEntries(
            Object.entries(item.dynamic_fields).map(([key, value]) => [key, value ?? " "]) // Replace null with "N/A"
          )
          : {}; // Handle cases where dynamic_fields is undefined or null

        return {
          id: item.id,
          srNo: index + 1,
          createdDateTime: item.created,
          leadId: item.id,
          accountName: item.account_name || "-",
          firstName: item.first_name || "-",
          lastName: item.last_name || "-",
          email: item.email || "-",
          phoneNumber: item.phone_number || "-",
          currentAssignee: item.assignee?.username || "-",
          lastUpdatedDateTime: item.updated,
          status: item.status_type || "-",
          internalNotes: item.internal_notes || "-",
          ...dynamicFields, // Spread preprocessed dynamic fields into the row object
        };
      });

      setDataRows(transformedRows); // Update state with transformed data
    } else {
      setDataRows([]); // Reset if CampaignLeadReport is not an array
    }

    setLocalLoading(false);
  }, [CampaignLeadReport]);







  const downloaderRef = useRef(null);


  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);

  };


  const openFilterModal = () => {
    setFilterIsModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterIsModalOpen(false);
  };
  const customFilterModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: "50px",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };


  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };


  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);




  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  const handleOpen = () => {
    openFilterModal();
  };
  console.log(dataRows)
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">

        {/* Card Header */}
        <div className="card-header align-items-center py-1 gap-2 gap-md-5" style={{ justifyContent: 'flex-start' }}>
          {/* Back button */}
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: '-2px', cursor: 'pointer' }}
            className="ng-star-inserted"
            onClick={() => {
              navigate('/reports/campaign/reports-list')
            }}
          /> */}
          <button
              className="btn btn-link mb-2"
              onClick={() => {
                navigate("/reports/campaign/reports-list");
              }}
              style={{marginRight:"-17px"}}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3">Lead Summary</span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Prevent future dates
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Prevent future dates
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>





          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Queue-select-label">Campaign</InputLabel>
              <Select
                labelId="Queue-select-label"
                id="Queue-select"
                label="Campaign"
                value={selectQueue}
                onChange={handleQueueChange}
                MenuProps={MenuProps}
              >
                {" "}
                {/* <MenuItem key="all" value="All">
                  All
                </MenuItem> */}
                {campaignSelected?.map((queue: any) => (

                  <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>



          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            {/* <button className='btn btn-primary' style={{ height: '50px', whiteSpace: 'nowrap' }} onClick={handleOpen}>
              Add Email Id
            </button> */}
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>


            {/* CsvDownloader component outside the button */}
            <button className="btn btn-primary">
              <CsvDownloader
                ref={downloaderRef}
                filename="Lead-summary-report"
                datas={dataRows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}
              >Download</CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>



        <CardContent style={{ width: "100%" }}>
          {CampaignLeadReportLoading || localLoading || campaignDataLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={
                columns
              }
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                  whiteSpace: "nowrap", // Prevent wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  whiteSpace: "nowrap", // Prevent wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                }
              }}
            />
          )}
        </CardContent>


        <ReactModal
          isOpen={isFilterModalOpen}
          onRequestClose={closeFilterModal}
          style={customFilterModalStyles}
          contentLabel="Add Email Modal"
        >
          <AddEmailToReport
            closeFilterModal={closeFilterModal}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectQueue={selectQueue}
            campaignSelected={campaignSelected}

          />
        </ReactModal>

      </div>
    </div>
  );
}

export default CampaignLeadReport;
