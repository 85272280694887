import React from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
// import { UPDATE_CHATROOM_META_BY_ID } from '../../graphql/mutation'
// import { GET_CHAT_ROOM_META_BY_CHATROOM_ID } from '../../graphql/query'
import { toast } from 'react-toastify'

interface Props {
  data: { id: string; name: string; value: string }
  chatRoomId: number
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  closeEditMeta: () => void
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
}

const editMetaSchema = Yup.object().shape({
  value: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Value is required')
})

const EditMetaModal: React.FC<Props> = ({
  data,
  chatRoomId,
  setIsLoading,
  closeEditMeta,
  type
}) => {
  // const [updateChatRoomMeta, { loading: loadingAddMeta }] = useMutation(
  //   UPDATE_CHATROOM_META_BY_ID,
  //   {
  //     refetchQueries: [
  //       {
  //         query: GET_CHAT_ROOM_META_BY_CHATROOM_ID,
  //         variables: {
  //           input: {
  //             chatRoomId: chatRoomId
  //           }
  //         },
  //         context: {
  //           headers: {
  //             'access-control-allow-methods':
  //               type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //           }
  //         }
  //       }
  //     ],
  //     context: {
  //       headers: {
  //         'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //       }
  //     }
  //   }
  // )
  const initialValues = {
    value: data.value
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: editMetaSchema,
    onSubmit: (values, { resetForm }) => {
      // setIsLoading(true)
      // updateChatRoomMeta({
      //   variables: {
      //     input: {
      //       meta: [
      //         {
      //           id: parseInt(data.id),
      //           value: values.value
      //         }
      //       ]
      //     }
      //   }
      // })
        // .then((res: any) => {
        //   // setIsLoading(false)
        //   resetForm()
        //   closeEditMeta()
        //   console.log(res)
        //   // Need to rewrite the following logic
        //   if (parseInt(res.data.updateChatRoomMetaById[0].id) > 0) {
        //     toast.success('Succesfully Updated Chat Data')
        //   } else {
        //     toast.error('Unable to Add Data')
        //   }
        // })
        // .catch((err) => {
        //   // setIsLoading(false)
        //   toast.error('Sorry! Unexpected Error.')
        //   console.error(err)
        // })
    }
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{data.name}</label>
        <input
          {...formik.getFieldProps('value')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.value && formik.errors.value },
            {
              'is-valid': formik.touched.value && !formik.errors.value
            }
          )}
          type='text'
          name='value'
          autoComplete='off'
        />
        {formik.touched.value && formik.errors.value && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.value}</span>
          </div>
        )}
      </div>

      <div className='d-flex justify-content-end'>
        <button
          type='reset'
          className='btn btn-sm btn-white btn-active-light-primary me-2'
          onClick={closeEditMeta}
        >
          Cancel
        </button>

        <button
          type='submit'
          className='btn btn-sm btn-primary'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.value}
        >
          {/* {!loadingAddMeta && <span className='indicator-label'>Update</span>}
          {loadingAddMeta && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )} */}update
        </button>
      </div>
    </form>
  )
}

export default EditMetaModal
