import { gql } from '@apollo/client'
export const GET_ALL_TEAMS_AND_USERS = gql`
  query GetAllTeamsAndUsers($input: getAllTeamsAndUsersInput) {
    getAllTeamsAndUsers(input: $input) {
      status
      message
      data
    }
  }
`
export const GET_TEAMS = gql`
  query GetTeams {
    getTeams {
      id
      name
      description
      modifiedAt
      createdAt
    }
  }`


export const GET_INTERNAL_CHAT_ID = gql`
  query GetInternalChatId($input: internalChatIdInput!) {
    getInternalChatId(input: $input) {
      status
      message
      data
    }
  }`

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      name
      email
      status
      UserRole {
        roleId
        userId
        role {
          roleName
        }
      }
}}`

export const GET_USER_BY_ID = gql`
  query GetUserById {
    getUserById {
      id
      name
      email
      status
      contactNumber
      Designation
      Department
      emailVerified
      onboardingDone
      UserRole {
        roleId
        userId
      }
      franchiseId
      teamId
    }
  }`


export const GET_ALL_APPLICATION_IDS = gql`
query AllApplicationId($input: AllApplicationIdInput) {
  allApplicationId(input: $input) {
    id
    bgAccountId
    appIDString
    appName
    startDate
    endDate
  }
}
`
export const GET_CHAT_ROOM_META_BY_CHATROOM_ID = gql`
  query ChatRoomMetaByChatRoomId($input: ChatRoomIdInput!) {
    chatRoomMetaByChatRoomId(input: $input) {
      id
      name
      value
    }
  }
`


export const GET_SESSIONS_BY_CHAT_ROOM_ID = gql`
  query SessionsByChatRoomId($input: ChatRoomIdInput!) {
    sessionsByChatRoomId(input: $input) {
      id
      chatRoomId
      assignedAgentId
      startDate
      endDate
      botName
      messageRequest {
        id
        sessionId
        content
        inputType
        prevRequestId
        templateId
        intentId
        time
      }
      agentName
    }
  }
`
export const GET_ALL_MAPS = gql`
  query GetallMaps {
    getallMaps {
      id
      bgAccountId
      services
      configName
      value
    }
  }
`