import React from "react";
import { CloseButton } from "react-bootstrap";
import { useGetAuditTrailQuery } from "../../../Services/ticket/Audit-Trail";
import { formatDate } from "date-fns";
import { formatDatee } from "../../reusableComponent/DateFormat";

interface IProps {
  closeEditModal: () => void;
  id: any;
  auditTrailData: any;
}

const TrailEditModal: React.FC<IProps> = ({
  closeEditModal,
  auditTrailData,
  id,
}) => {
  const { data: TrailData } = useGetAuditTrailQuery(
    {
      id: auditTrailData?.id,
      activity: auditTrailData?.ticket_activity,
    },
    { skip: !auditTrailData }
  );

  const formatDateTime = (dateString: string) => {
    if (!dateString) return "";
    return new Intl.DateTimeFormat("en-IN", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    }).format(new Date(dateString));
  };

  return (
    <form>
      <div className="text-end">
        <CloseButton onClick={closeEditModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Trail Details
        </label>
      </div>

      <div className="w-100 mb-4">
        <label>Assigned By</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.assigned_by} // Example default value
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Status Type</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.new_status_type}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Priority</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.priority}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Workflow</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.workflow}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Assigned By</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.assigned_by}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>New Assignee</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.new_assignee}
          disabled
        />
      </div>
      {TrailData?.[0]?.dynamic_fields &&
        Object.keys(TrailData?.[0]?.dynamic_fields).length > 0 && (
          <>
            <div className="text-center mb-4">
              <label className="form-label fs-4 fw-bolder text-dark card-title">
                Ticket Information
              </label>
            </div>
            {Object.keys(TrailData?.[0]?.dynamic_fields).map((key) => (
              <div className="w-100 mb-4" key={key}>
                <label>{key.replace(/_/g, " ").toUpperCase()}</label>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  autoComplete="off"
                  value={
                    key.toLowerCase().includes("date") ||
                    key.toLowerCase().includes("time")
                      ? formatDateTime(TrailData?.[0]?.dynamic_fields[key])
                      : TrailData?.[0]?.dynamic_fields[key] || ""
                  }
                  disabled
                />
              </div>
            ))}
          </>
        )}
    </form>
  );
};

export default TrailEditModal;
