import React, { useEffect, useState } from 'react'
import axios from 'axios'

interface IProps {
  isLocation: any
}

const GeoLocation: React.FC<IProps> = ({ isLocation }) => {
  const [locationName, setLocationName] = useState('')

  useEffect(() => {
    if (isLocation?.locationData) {
      const [lat, lon] = isLocation.locationData.split(',')

      const fetchLocationName = async () => {
        try {
          const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
            params: {
              format: 'json',
              lat: lat,
              lon: lon
            }
          })
          const data = response.data
          if (data && data.display_name) {
            setLocationName(data.display_name)
          } else {
            setLocationName('Unknown location')
          }
        } catch (error) {
          console.error('Error fetching location name:', error)
          setLocationName('Location not available')
        }
      }

      fetchLocationName()
    }
  }, [isLocation?.locationData])

  return (
    <div>
      <div className='card mt-5 mb-3'>
        <div className='card card-flush'>
          <div
            className='card-header justify-content-start align-items-center'
            style={{
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              borderRadius: '6px',
              minHeight: '60px'
            }}
          >
            <div className='text-black-700 fs-5 ' style={{ width: '40%' }}>
              Source
            </div>
          </div>
        </div>
        <div className='card card-flush'>
          <div
            className='card-header justify-content-start align-items-center'
            style={{
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              borderRadius: '6px',
              minHeight: '60px'
            }}
          >
            <div className='text-black-700 fs-5 '>Pricing Page</div>
          </div>
        </div>
        <div className='card card-flush'>
          <div
            className='card-header'
            style={{
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              borderRadius: '6px',
              minHeight: '60px'
            }}
          >
            <div className='d-flex justify-content-start align-items-center'>
              <div className='text-gray-700 fs-5 fw-bolder'>
                <i className='bi bi-geo-alt fs-2'></i>
              </div>
              <div className='ms-3'>{locationName}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeoLocation
