import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";

interface IPROPS {
  getStatusData: string[];
  getFilteredData: any;
  acwCount: any;
  onCallCount: any;
  readyCount: any;
  pauseCount: any;
}

const PieChartRTM: React.FC<IPROPS> = ({
  getStatusData,
  getFilteredData,
  acwCount,
  onCallCount,
  readyCount,
  pauseCount,
}) => {
  const labels = ["On Call", "Ready", "Paused", "ACW"];

  const getStatusCounts = () => {
    const totalLoggedIn = getFilteredData.length;
    if (totalLoggedIn === 0) return [0, 0, 0, 0];

    return [onCallCount, readyCount, pauseCount, acwCount.length];
  };

  const [state, setState] = useState<any>({
    series: getStatusCounts(),
    options: {
      chart: { width: "50%", type: "pie" },
      labels: labels,
      theme: { monochrome: { enabled: false } },
      plotOptions: { pie: { dataLabels: { offset: -5 } } },
      dataLabels: {
        enabled: true,
        formatter: (val: number, opts: any) =>
          `${opts.w.globals.series[opts.seriesIndex]}`,
        style: { fontSize: "12px", fontWeight: "bold" },
      },
      legend: { show: true, position: "bottom", horizontalAlign: "center" },
    },
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      series: getStatusCounts(),
    }));
  }, [getStatusData, getFilteredData, acwCount, onCallCount, readyCount, pauseCount]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="pie" height="220px" />
      </div>
    </div>
  );
};

export default PieChartRTM;
