import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const LeadDetails = createApi({
  reducerPath: "leadTrail",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    leadTrailData: builder.query({
      query: (uuid) => {
        return {
          url: `v1/common/lead-status-change-log/${uuid}/?forward-lead=true`,
          method: "GET",
        };
      },
    }),

    leadInfo: builder.query({
      query: ({ id, activity }) => {
        return {
          url: `v1/common/lead-status-change-log/get_details/${id}/?activity=${activity}&`,
          method: "GET",
        };
      },
    }),
  }),
});
export const { useLeadTrailDataQuery, useLeadInfoQuery } = LeadDetails;
