import { Link, useLocation } from "react-router-dom";
import { CIRCLEONE_TEAM, CIRCLEONE_USER, CIRCLEONE_USERNAME } from "../../constants";

let menuItem = [
  {
    icon: "bi bi-people-fill",
    title: "Users",
    to: "users",
  },
  {
    icon: "bi bi-bag-check-fill",
    title: "Customer",
    to: "customer",
  },
  {
    icon: "bi bi-sliders",
    title: "General",
    to: "general",
  },
  {
    icon: "bi bi-ticket-detailed",
    title: "Ticket",
    to: "ticket",
  },
  {
    icon: "bi bi-envelope",
    title: "Email",
    to: "email",
  },

  {
    icon: "bi bi-menu-up",
    title: "Leads",
    to: "campaign",
  },

  {
    icon: "bi bi-telephone",
    title: "Telephony",
    to: "telephony",
  },

  {
    icon: "bi bi-gear",
    title: "Configuration",
    to: "integration",
    role: ["manager", "admin"],
  },
  // {
  //   icon: "bi bi-chat-text",
  //   title: "Chat",
  //   to: "chat",
  //   role: "admin",
  // },
];

const ListMenu = () => {
  const tenantName = localStorage.getItem(CIRCLEONE_USER);
  const location = useLocation();
  const userRole = localStorage.getItem("user_role");
  
  
  const checkTeam  = localStorage.getItem(CIRCLEONE_TEAM)
  const checkUsername = localStorage.getItem(CIRCLEONE_USERNAME)
  const notValidUser  = checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io" 

  // Filter menu items based on userRole
  const filteredMenu = menuItem.filter((item) => {
    if (notValidUser) {
      return item.title === "Leads" || item.title === "Configuration" || item.title === "Users"; 
    }
    return !item?.role || item?.role.includes(userRole);
  });

  const isDisabledForMeta = (title) =>
    (tenantName === "victorinox" && (title === "Telephony" || title === "Leads")) 
  // ||(tenantName === "csquare" && (title === "Email" || title === "Leads"));


  

  return (
    <>
      <div className="card-body px-4">
        <div className="menu-item">
          <div className="menu-content pb-3">
            <span className="menu-section fw-bolder text-uppercase fs-3 ls-1">
              Settings Configuration
            </span>
          </div>
        </div>
        {filteredMenu.map((value, index) => (
          <div className="menu-item" key={index}>
         
            <div
              className={`menu-link without-sub ${
                location.pathname.includes(value.to) ? "text-primary" : ""
              } ${isDisabledForMeta(value.title) ? "disabled-link" : ""}`}
            >
              {isDisabledForMeta(value.title) ? (
                // Render Telephony & Leads as disabled items
                <div className="d-flex disabled-menu">
                  <span className="menu-icon">
                    <i className={`${value.icon}`}></i>
                  </span>
                  <span className="menu-title fs-4 fw-bold text-muted" style={{cursor: "default"}}>
                    {value.title}
                  </span>
                </div>
              ) : (
                // Render other items normally
                <Link to={value.to} className="d-flex">
                  <span className="menu-icon">
                    <i className={`${value.icon}`}></i>
                  </span>
                  <span
                    className={`menu-title fs-4 fw-bold text-hover-primary ${
                      location.pathname.includes(value.to)
                        ? "text-primary"
                        : "text-muted"
                    }`}
                  >
                    {value.title}
                  </span>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ListMenu;


