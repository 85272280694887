import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const ticketSettingsApi = createApi({
  reducerPath: "tickets",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    ticketSchedules: builder.mutation({
      query: (team) => ({
        url: `v1/tickets/auto-ticket-scheduler/scheduler_list/${team}`,
        method: "GET",
      }),
    }),

    ticketSchedule: builder.mutation({
      query: (theSchedule) => ({
        url: `v1/tickets/auto-ticket-scheduler/${theSchedule}`,
        method: "GET",
      }),
    }),

    ticketMapToDisposition: builder.mutation({
      query: ({ body, teamId }) => ({
        url: `v1/common/disposition/${teamId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteTicketSchedule: builder.mutation({
      query: (scheduleForDeletion) => ({
        url: `v1/tickets/auto-ticket-scheduler/${scheduleForDeletion}`,
        method: "DELETE",
      }),
    }),

    newSchedule: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/auto-ticket-scheduler/`,
        method: "POST",
        body,
      }),
    }),

    newWorkflow: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/ticket-name/`,
        method: "POST",
        body,
      }),
    }),

    newTicketNotification: builder.mutation({
      query: (body) => ({
        url: `v1/common/notification/`,
        method: "POST",
        body,
      }),
    }),
    newInProgressStateInWorkflow: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/ticket-in-progress-status/`,
        method: "POST",
        body,
      }),
    }),
    editInProgressStateInWorkflow: builder.mutation({
      query: ({ statusId, body }) => ({
        url: `v1/tickets/ticket-in-progress-status/${statusId}`,
        method: "PUT",
        body,
      }),
    }),
    newClosedStateInWorkflow: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/ticket-closed-status/`,
        method: "POST",
        body,
      }),
    }),
    editClosedStateInWorkflow: builder.mutation({
      query: ({ statusId, body }) => ({
        url: `v1/tickets/ticket-closed-status/${statusId}`,
        method: "PUT",
        body,
      }),
    }),
    ticketRulesByTeam: builder.query({
      query: (selectedTeam) => ({
        url: `v1/tickets/ticket-rules/${selectedTeam}/`,
        method: "GET",
      }),
    }),
    udpateTicketRulesByTeam: builder.mutation({
      query: ({ teamId, body }) => ({
        url: `v1/tickets/ticket-rules/${teamId}/`,
        method: "PATCH",
        body,
      }),
    }),
    udpateWorkflowById: builder.mutation({
      query: ({ workflowId, body }) => ({
        url: `v1/tickets/ticket-name/${workflowId}/`,
        method: "PUT",
        body,
      }),
    }),
    deleteWorkflowById: builder.mutation({
      query: (workflowId) => ({
        url: `v1/tickets/ticket-name/${workflowId}/`,
        method: "DELETE",
      }),
    }),
    addChoiceToDynamicFieldDD: builder.mutation({
      query: (body) => ({
        url: `v1/common/dynamic-field-choices/`,
        method: "POST",
        body,
      }),
    }),
    editChoiceToDynamicFieldDD: builder.mutation({
      query: (body) => ({
        url: `v1/common/dynamic-field-choices/`,
        method: "PATCH",
        body,
      }),
    }),
    addOptionToDynamicFieldCheckbox: builder.mutation({
      query: (body) => ({
        url: `v1/common/dynamic-field-checkbox_multiple_choices/`,
        method: "POST",
        body,
      }),
    }),
    deleteOptionOfDynamicFieldCheckbox: builder.mutation({
      query: (multiChoiceId) => ({
        url: `v1/common/dynamic-field-checkbox_multiple_choices/${multiChoiceId.id}`,
        method: "DELETE",
      }),
    }),
    deleteInProgressStageById: builder.mutation({
      query: (stageId) => ({
        url: `v1/tickets/ticket-in-progress-status/${stageId}/`,
        method: "DELETE",
      }),
    }),
    deleteClosedStageById: builder.mutation({
      query: (stageId) => ({
        url: `v1/tickets/ticket-closed-status/${stageId}/`,
        method: "DELETE",
      }),
    }),
    tags: builder.query({
      query: () => ({
        url: `v1/customers/tags/`,
        method: "GET",
      }),
    }),
    deleteTicketField: builder.mutation({
      query: (fieldId) => ({
        url: `v1/common/dynamic-field-definitions/${fieldId}/`,
        method: "DELETE",
      }),
    }),
    deleteTicketFieldChoice: builder.mutation({
      query: (choiceId) => ({
        url: `v1/common/dynamic-field-choices/${choiceId?.id}/`,
        method: "DELETE",
      }),
    }),
    getActiveAutoTicketNotification: builder.query({
      query: (teamId) => ({
        url: `v1/common/notification/get_notification/${teamId}/auto_ticket/active/`,
        method: "GET",
      }),
    }),
    getActiveManualTicketNotification: builder.query({
      query: (teamId) => ({
        url: `v1/common/notification/get_notification/${teamId}/manual_ticket/active/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useTicketSchedulesMutation,
  useTicketScheduleMutation,
  useDeleteTicketScheduleMutation,
  useNewScheduleMutation,
  useNewWorkflowMutation,
  useNewTicketNotificationMutation,
  useTicketRulesByTeamQuery,
  useUdpateTicketRulesByTeamMutation,
  useTagsQuery,
  useUdpateWorkflowByIdMutation,
  useDeleteWorkflowByIdMutation,
  useNewInProgressStateInWorkflowMutation,
  useNewClosedStateInWorkflowMutation,
  useEditClosedStateInWorkflowMutation,
  useEditInProgressStateInWorkflowMutation,
  useDeleteClosedStageByIdMutation,
  useDeleteInProgressStageByIdMutation,
  useTicketMapToDispositionMutation,
  useAddChoiceToDynamicFieldDDMutation,
  useAddOptionToDynamicFieldCheckboxMutation,
  useDeleteOptionOfDynamicFieldCheckboxMutation,
  useDeleteTicketFieldChoiceMutation,
  useDeleteTicketFieldMutation,
  useGetActiveAutoTicketNotificationQuery,
  useGetActiveManualTicketNotificationQuery,
  useEditChoiceToDynamicFieldDDMutation,
} = ticketSettingsApi;
