import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    useTeamsQuery,
    useUpdateTeamMutation,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import { CIRCLEONE_USER } from "../../../../constants";

interface IPROPS {
    storeValue: any;
    setStoreValue: any;
    refetch: any;
    closeModal: any;
}

const EditTeamModal: React.FC<IPROPS> = ({
    storeValue,
    setStoreValue,
    refetch,
    closeModal,
}) => {
    const [storedId, setStoredId] = useState(null);
    const [name, setName] = useState(storeValue?.name);
    const [selectRole, setSelectRole] = useState(storeValue.role[0] || "");
    const [storeSelectedRoleData, setStoreSelectedRoleData] = useState<any>([]);
    const [checkBoxValue, setCheckBoxValue] = useState<any>([]);

    const tenantName = localStorage.getItem(CIRCLEONE_USER)


    const [
        trigger,
        {
            data: updatedTeamData,
            isLoading: updatedTeamLoading,
            isError: updatedTeamError,
        },
    ] = useUpdateTeamMutation();

    useEffect(() => {
        setStoredId(storeValue?.id);
    }, [storeValue?.id]);

    useEffect(() => {
        if (selectRole === "agent") {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            setStoreSelectedRoleData(
                storeValue?.agent_module_permissions || []
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            setStoreSelectedRoleData(
                storeValue?.manager_module_permissions || []
            );
        }
    }, [selectRole, storeValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mediaAccess = useMemo(
        () => [
            "dashboard",
            "users",
            "customers",
            "email",
            "ticket",
            "campaigns",
            "phone",
            "chat",
            "reports",
            "settings",
            // "social-media",
            // "whatsapp",
            // "knowledge-management",
            // "subscription",
        ],
        []
    );

    const namehandler = (e: any) => {
        const newName = e.target.value;
        setName(newName);
        setStoreValue((prev: any) => ({
            ...prev,
            name: newName,
        }));
    };

    const roleSelectHandler = (e: any) => {
        const selectedRole = e.target.value;

        setSelectRole(selectedRole);
        setStoreValue((prev: any) => ({
            ...prev,
            role: [selectedRole],
        }));
    };

    useEffect(() => {
        const initializeCheckBoxValue = () => {
            return mediaAccess.map((access) =>
                storeSelectedRoleData.some(
                    (role: string) => role === `${access}:enable`
                ) ||
                ((selectRole === "agent" || selectRole === "manager") &&
                    ["dashboard"].includes(access))
            );
        };
        setCheckBoxValue(initializeCheckBoxValue());
    }, [mediaAccess, storeSelectedRoleData, selectRole]);

    const checkBoxHandler = (index: any) => {
        const updatedCheckBoxValue = [...checkBoxValue];
        const permission = mediaAccess[index];

        if ((selectRole === "agent" || selectRole === "manager") && ["dashboard"].includes(permission)) {
            return;
        }

        // Toggle the checkbox value
        updatedCheckBoxValue[index] = !updatedCheckBoxValue[index];
        setCheckBoxValue(updatedCheckBoxValue);

        const updatedPermissions = mediaAccess
            .map((access, i) =>
                updatedCheckBoxValue[i] ? `${access}:enable` : null
            )
            .filter(Boolean);

        setStoreValue((prev) => ({
            ...prev,
            agent_module_permissions:
                selectRole === "agent"
                    ? updatedPermissions
                    : prev.agent_module_permissions,
            manager_module_permissions:
                selectRole === "manager"
                    ? updatedPermissions
                    : prev.manager_module_permissions,
        }));
    };

    const editTeamHandler = async () => {
        const spreadData = { ...storeValue };

        // Validation: Check if "phone" is selected but "customers" is not
        const phoneEnabled = storeValue.agent_module_permissions?.includes("phone:enable") ||
            storeValue.manager_module_permissions?.includes("phone:enable");
        const customersEnabled = storeValue.agent_module_permissions?.includes("customers:enable") ||
            storeValue.manager_module_permissions?.includes("customers:enable");

        if (phoneEnabled && !customersEnabled) {
            toast.warning("Please select customers");
            return; // Prevent submission
        }

        try {
            const response: any = await trigger({
                teamId: storedId,
                body: spreadData,
            });
            if (response.data) {
                toast.success("Updated Successfully");
                closeModal(true);
                refetch();
            }
            else if (response?.error) {
                const errorData = response?.error?.data;
                // Extract error messages from nested objects
                const extractMessages = (obj) => {
                    return Object.values(obj)
                        .map(value => (typeof value === "object" ? extractMessages(value) : value))
                        .flat();
                };
                const errorMessages = extractMessages(errorData).join(", ").replace(/"/g, '');
                toast.error(errorMessages || "An error occurred");
            }

            else {
                toast.error("An error occurred");
            }
        } catch (error) {
            toast.error("An error occurred");
        }
    };



    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                }}
            >
                <i
                    className="bi bi-x-lg"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => closeModal()}
                ></i>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Enter Team Name</label>
                <input
                    style={{ border: "1px solid #ccc", padding: "10px" }}
                    onChange={namehandler}
                    value={name}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Select Role</label>
                <select
                    style={{ padding: "10px" }}
                    onChange={roleSelectHandler}
                    value={selectRole}
                >
                    <option value={"agent"}>Agent</option>
                    <option value={"manager"}>Manager</option>
                </select>
            </div>
            <div style={{ fontWeight: "600", padding: "10px 0 0 0" }}>Module Permission</div>
            <div style={{ border: "1px solid #ccc" }}>
                <div className="bg-primary"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // border: "1px solid green",
                        padding: "15px",
                        // background: "#009ef7",
                        color: "white",
                        fontSize: "17px",
                        width: "100%",
                        marginBottom: "5px",
                    }}
                >
                    <span style={{ width: "50%" }}>Module Access</span>
                    <span style={{ flex: "1" }}>Access</span>
                </div>
                <div
                    style={{
                        maxHeight: "40vh",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        width: "100%",
                        marginBottom: "10px",
                        // padding: "10px",
                    }}
                >
                    {mediaAccess?.map((el: any, index: any) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                background: index % 2 !== 0 ? "rgb(232 234 241)" : "transparent",
                                padding: "5px",
                            }}
                        >
                            <p
                                style={{
                                    width: "50%",
                                    margin: "0",
                                    fontSize: "14px",
                                }}
                            >
                                {capitalizeFirstLetter(el === "campaigns" ? "Leads" : el)}
                            </p>
                            <p
                                style={{
                                    flex: "1",
                                    margin: "0",
                                    fontSize: "14px",
                                    display: "flex",
                                    gap: "5px",
                                }}
                            >
                                <span>
                                    <input
                                        type="checkbox"
                                        onChange={() => checkBoxHandler(index)}
                                        checked={checkBoxValue[index] || false}
                                        disabled={["chat"].includes(el) || (tenantName === "victorinox" && ["campaigns", "phone"].includes(el)) || (tenantName === "csquareee" && ["campaigns", "email"].includes(el))} // Disable for these values
                                    />
                                </span>
                                <span>Edit</span>
                            </p>
                        </div>
                    ))}

                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <button className="btn btn-primary" onClick={editTeamHandler}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditTeamModal;