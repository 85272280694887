import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../LoadingSpinner";
import {
    useDeleteQueueDataMutation,
    useQueueDataQuery,
} from "../../../../Services/Telephony/Telephony";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import EditQueueCreation from "./EditQueueCreation";
import DeleteContactModal from "../../../contact/contact/DeleteContactModal";
import AddQueueCreation from "./AddQueueCreation";
import { Loader } from "react-bootstrap-typeahead";
import * as XLSX from "xlsx";
import EmptyRows from "../../../reusableComponent/EmptyRows";


const QueueCreation = () => {
    const [telephonyQueueData, setTelephonyQueueData] = useState<any>([]);

    const [queueLoading, setQueueLoading] = useState(false);
    const [queueId, setQueueId] = useState("");
    const [queueEditModal, setQueueEditModal] = useState(false);
    const [queueAddModal, setQueueAddModal] = useState(false);
    const [queueDeleteModal, setQueueDeleteModal] = useState(false);
    const [openQueueAddModal, setOpenQueueAddModal] = useState(false);
    const [agentMappingDownloadButtonClicked,setAgentMappingDownloadButtonClicked] = useState<boolean>(false)
    const [downloadLoader, setDownloadLoader] = useState(false)



    const Queue = [
        { label: "Sr.No", key: "sno" },
        { label: "Queue", key: "queue" },
    ];

    const {
        data: queueData,
        isLoading,
        status: queueStatus,
        refetch,
    } = useQueueDataQuery({});

    const [deleteQueueTrigger, {}] = useDeleteQueueDataMutation();

    useEffect(() => {
        if (queueData) {
            setTelephonyQueueData(queueData);
        }
    }, [queueData]);
    useEffect(() => {
        if (queueStatus === "pending") {
            setQueueLoading(true);
        } else {
            setQueueLoading(false);
        }
    }, [queueStatus]);

    const EditModal = (id) => {
        setQueueId(id);
        setQueueEditModal(true);
    };

    const closeModal = () => {
        setQueueEditModal(false);
        setQueueAddModal(false);
        setQueueDeleteModal(false);
        setOpenQueueAddModal(false);
    };

    const deleteModal = (queue) => {
        setQueueId(queue);
        setQueueDeleteModal(true);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        overflow: "scroll",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
        paddingBottom: "0",
    };

    useEffect(() => {
        if (agentMappingDownloadButtonClicked) {

            downloadAgentMappingData()
        }

    }, [agentMappingDownloadButtonClicked])


    const downloadAgentMappingData = async () => {
        setAgentMappingDownloadButtonClicked(true)
        // setDownloadLoader(true)
        if ( telephonyQueueData && telephonyQueueData.length > 0) {
          const csvData = telephonyQueueData?.map((queue, index) => ({
            "Sr.No": index+1,
            Queue: queue?.name,
          }));
    
          const worksheet = XLSX.utils.json_to_sheet(csvData);
    
          const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
    
          const blob = new Blob([csvOutput], {
            type: "text/csv;charset=utf-8;",
          });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "Extension_Data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        //   setAgentLoader(false)
        setAgentMappingDownloadButtonClicked(false)

        // setDownloadLoader(false);

        // setDownloadAgentMappingTableData([]);
        } else {
            // setDownloadLoader(false)
        setAgentMappingDownloadButtonClicked(false)

        //   setAccountDownloadLoading(false);
        }
      };

    return (
        <div style={{marginLeft:"3%"}}>
                        <div style={{display: "flex", gap: "10px", width: "100%", marginBottom: "20px", alignItems:"baseline"}}>
                <div style={{width: "85%"}}>
                    <span className="input-group-text mt-2" id="basic-addon1">
                        Queue
                    </span>
                </div>
                <div style={{flex: "1", display: "flex", gap: "10px", justifyContent: "end", alignItems: "baseline"}}>
                    <div className="card-toolbar d-flex gap-2">
                        <button
                            className="input-group-text form-control btn-sm fw-bold d-flex align-items-center min-w-150px"
                            style={{justifyContent: "center", fontSize: "15px"}}
                            onClick={() => setOpenQueueAddModal(true)}
                        >
                            <i className="bi bi-plus-lg text-dark me-2"></i>
                            <span>Add queue</span>
                        </button>
                    </div>
                    <Tooltip title="Download extension Data">
                    <IconButton
                      onClick={() => {
                        setAgentMappingDownloadButtonClicked(true);

                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        downloadAgentMappingData;
                      }}
                    >
                      {downloadLoader ? (
                        <Loader />
                      ) : (
                        <i
                          className="bi bi bi-download"
                          style={{
                            fontSize: "25px",
                            color: "black",
                          }}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div
                        className="card  my-1 p-4"
                        style={{ backgroundColor: "#E4E4E47F" }}
                    >
                        <div className="row align-items-center">
                            {Queue?.map((card, index) => (
                                <div
                                    className="col"
                                    style={{
                                        textAlign:
                                            card.label === "Queue"
                                                ? "center"
                                                : "start",
                                    }}
                                    key={index}
                                >
                                    <label htmlFor="">
                                        <strong
                                            style={{
                                                display: "flex",
                                                gap: "2px",
                                                paddingRight:"10px"
                                            }}
                                        >
                                            {card?.label}
                                        </strong>
                                    </label>
                                </div>
                            ))}
                            <div className="col text-end">
                                <label
                                    htmlFor=""
                                    style={{ paddingRight: "25px" }}
                                >
                                    <strong>Action</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ maxHeight: "60vh", overflow: "scroll" }}>
                {!telephonyQueueData?.length ? <EmptyRows value={''}/> : 
                <div className="col" style={{ paddingRight: "0px" }}>
                    {isLoading || queueLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {telephonyQueueData?.length ? (
                                <>
                                    {telephonyQueueData?.map(
                                        (user: any, index: any) => (
                                            <div
                                                className="card mt-2 my-1 p-4"
                                                key={index}
                                            >
                                                <div className="row align-items-start">
                                                    <div className="col text-start">
                                                        <label
                                                            htmlFor=""
                                                            style={{
                                                                paddingLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </label>
                                                    </div>

                                                    <div className="col text-start" style={{paddingLeft:"22%"}}>
                                                        <label htmlFor="">
                                                            {user?.name}
                                                        </label>
                                                    </div>

                                                    <div
                                                        className="col text-center d-flex"
                                                        style={{
                                                            gap: "10px",
                                                            justifyContent:
                                                                "end",
                                                            paddingRight:
                                                                "25px",
                                                        }}
                                                    >
                                                        <button
                                                            className="text-hover-primary border-0 bg-white me-2"
                                                            title="Edit"
                                                            onClick={() =>
                                                                EditModal(
                                                                    user.id
                                                                )
                                                            }
                                                            // onClick={() => isEditModal(user)}
                                                        >
                                                            <i className="bi bi-pencil text-dark fs-4"></i>
                                                        </button>
                                                        <button
                                                            className="text-hover-danger border-0 bg-white me-2"
                                                            title="Delete"
                                                            onClick={() =>
                                                                deleteModal(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-trash text-dark fs-4"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            ) : (
                                ""
                                // <EmptyRows value={""} />
                            )}
                        </>
                    )}
                </div>
}
            </div>

            <Modal
                open={queueEditModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit queue
                    </Typography>

                    <Box>
                        <EditQueueCreation
                            closeModal={closeModal}
                            refetch={refetch}
                            queueId={queueId}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={queueDeleteModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        {/* <GridCloseIcon /> */}
                    </IconButton>
                    {/* <Typography
                                        id="modal-title"
                                        variant="h6"
                                        component="h2"
                                        sx={{ mb: 2 }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        Edit Extension

                                    </Typography> */}

                    <Box>
                        <DeleteContactModal
                            closeModal={closeModal}
                            setFieldsData={setTelephonyQueueData}
                            fieldsData={telephonyQueueData}
                            storingFieldData={queueId}
                            refetch={refetch}
                            trigger={deleteQueueTrigger}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openQueueAddModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add queue
                    </Typography>

                    <Box>
                        <AddQueueCreation
                            closeModal={closeModal}
                            refetch={refetch}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default QueueCreation;
