import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import { useAccessAccountAndTeamQuery } from "../../../Services/customer/FrontendCustomer";
import {
  useNewContactMutation,
  useTagsQuery,
  useTeamsQuery,
  useUserDynamicFieldsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { PhoneInput } from "react-international-phone";

interface IProps {
  closeModal: () => void;
  // emailNotPresent: any;
  // setSelectedContact: any;
  // storeNewEmailId: any;
  // setEmailNotPresent: any;
}

interface Team {
  id: string;
  name: string;
}

interface ErrorState {
  [key: string]: string;
}

const AddNewContactCampaignModal: React.FC<IProps> = ({
  closeModal,
 
  
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [accessibleTeams, setAccessibleTeams] = useState<Team[]>([]);
  const [tagsData, setTagsData] = useState([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [accountSelected, setAccountSelected] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [facebookId, setFacebookId] = useState("");
  const [dynamicData, setDynamicData] = useState<any[]>([]);
  const [dynamicFields, setDynamicFields] = useState<any>("");
  const [errors, setErrors] = useState<ErrorState>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsQuery({});
  const { data: teamAndAccount, isLoading: isLoadingAccounts } =
    useAccessAccountAndTeamQuery({});
  const { data: tags, isLoading: tagsLoading } = useTagsQuery({});

  const [trigger, { isLoading }] = useNewContactMutation();
  const [email, setEmail] = useState("");

  const modelName = "contact";
  const { data: contactDynamicData, isLoading: dynamicLoading } =
    useUserDynamicFieldsQuery(modelName);

  useEffect(() => {
    if (teamsData) {
      setAllTeams(teamsData);
      setAccessibleTeams(teamsData); // Initialize accessible teams to all teams
    }
  }, [teamsData]);

  useEffect(() => {
    if (tags) {
      setTagsData(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (accountSelected === "selectNoAccount") {
      setAccessibleTeams(allTeams); // Show all teams if "Select No Account" is selected
      setSelectedTeams([]); // Clear selected teams
      setSelectAll(false);
    } else if (teamAndAccount) {
      const selectedAccount = teamAndAccount.find(
        (acc) => acc.id === parseInt(accountSelected)
      );
      if (selectedAccount) {
        setAccessibleTeams(selectedAccount.accessible_teams);
        setSelectedTeams([]); // Clear selected teams when account changes
        setSelectAll(false);
      } else {
        setAccessibleTeams(allTeams);
        setSelectedTeams([]); // Clear selected teams
        setSelectAll(false);
      }
    }
  }, [accountSelected, allTeams, teamAndAccount]);

  useEffect(() => {
    if (contactDynamicData) {
      setDynamicData(contactDynamicData);
    }
  }, [contactDynamicData]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTeams([]);
    } else {
      const allTeamIds = accessibleTeams.map((team) => team.id);
      setSelectedTeams(allTeamIds);
    }
    setSelectAll(!selectAll);
  };

  const toggleTeamSelection = (teamId: string) => {
    const isSelected = selectedTeams.includes(teamId);

    let updatedTeams;

    if (isSelected) {
      // If the team is already selected, remove it from the selectedTeams array
      updatedTeams = selectedTeams.filter((id) => id !== teamId);
    } else {
      // Otherwise, add it to the selectedTeams array
      updatedTeams = [...selectedTeams, teamId];
    }

    setSelectedTeams(updatedTeams);

    // Check if all teams are selected to update the "Select All" checkbox state
    if (updatedTeams.length === accessibleTeams.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const renderValue = (selected: string[]) => {
    const selectedTeamNames = accessibleTeams
      .filter((team) => selected.includes(team.id))
      .map((team) => team.name);
    return selectedTeamNames.join(", ");
  };

  const tagSelectHandler = (tag: any) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        // If the selected tag is already present, remove it (clear the selection)
        return [];
      } else {
        // If the selected tag is not present, store it as the new value (replace any existing tags)
        return [tag];
      }
    });
  };

  const handleInputChange = (label, value) => {
    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };
  const validateForm = () => {
    const newErrors: ErrorState = {};

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      // Example: Add phone number validation logic here
      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        // If you have specific phone validation, you could add it here
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");

      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);

      return;
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = dynamicData.reduce((acc, field) => {
      acc[field.label] = dynamicFields[field.label] || "";
      return acc;
    }, {});

    const contactObj = {
      accessible_teams: selectedTeams,
      account: accountSelected,
      dynamic_fields: dynamicFieldData,
      email: email,
      phone_number: mobileNumber,
      tags: selectedTags,
      name: name,
      facebook_id: facebookId,
      twitter_id: twitterId,
      whatsapp_number: whatsappNumber,
    };
    setIsButtonDisabled(true);

    try {
      const response = await trigger(contactObj);

      if (response?.data) {
        toast.success("Added successfully");

        // setSelectedContact(response?.data);
        // setEmailNotPresent(false);
        closeModal();
      } else if (response?.error) {
        const error = response.error as {
          data?: { email?: string[]; name?: string[] };
        };
        const errorEmail = error?.data?.email?.[0];
        const errorName = error?.data?.name?.[0];
        const errorMessage = errorEmail || errorName;

        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          New Contact
        </label>
      </div>
      <div>
        <div className="row g-5 g-xl-8 mb-4">
          <div className="col-xl-6" style={{ width: "100%" }}>
            <label>Select Account</label>
            <select
              className="form-select select2-hidden-accessible"
              onChange={(event) => setAccountSelected(event.target.value)}
              value={accountSelected}
            >
              <option value="" selected>
                Select Account
              </option>
              {teamAndAccount?.map((acc) => (
                <option value={acc.id} key={acc.id}>
                  {acc?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="teams-select-label">Select Teams</InputLabel>
          <Select
            labelId="teams-select-label"
            multiple
            value={selectedTeams}
            onChange={(event) =>
              setSelectedTeams(event.target.value as string[])
            }
            input={<OutlinedInput label="Select Teams" />}
            renderValue={renderValue}
            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          >
            <MenuItem key="selectAll" value="selectAll">
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
              <ListItemText primary="Select All" />
            </MenuItem>
            {accessibleTeams.map((team) => (
              <MenuItem
                key={team.id}
                value={team.id}
                onClick={() => toggleTeamSelection(team.id)}
              >
                <Checkbox
                  checked={selectedTeams.includes(team.id)}
                  onChange={() => toggleTeamSelection(team.id)}
                />
                <ListItemText primary={team.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="row g-5 g-xl-8 mb-4">
          <div
            className="col-xl-6"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <label className="required">Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setName(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  name: "",
                }));
              }}
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
            {/* Display error message if exists */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <label className="required">Email</label>
          {/* <input className="form-control" value={storeNewEmailId} disabled /> */}
          <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  name: "",
                }));
              }}
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <label>Mobile No.</label>
            <input
              className="form-control"
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <label>Whatsapp No.</label>
            <input
              className="form-control"
              onChange={(e) => {
                setWhatsappNumber(e.target.value);
              }
            }
            />
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <div
            className="col-xl-6"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <label>Facebook Id </label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setFacebookId(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <div
            className="col-xl-6"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <label>Twitter Id </label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setTwitterId(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          {dynamicData?.map((el) => (
            <React.Fragment key={el?.label}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  className={
                    el.field_type === "check-box"
                      ? ""
                      : el?.is_required
                      ? "required"
                      : ""
                  }
                >
                  {capitalizeFirstLetter(
                    el?.field_type === "check-box" ? "" : el?.label
                  )}
                </label>
                {el?.field_type === "drop-down" ? (
                  <select
                    className="form-control"
                    value={dynamicFields[el?.label] || ""}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                  >
                    <option value="" selected>
                      Select an option
                    </option>
                    {el?.choices?.map((choice: any) => (
                      <option key={choice?.id} value={choice?.choice}>
                        {choice?.choice}
                      </option>
                    ))}
                  </select>
                ) : el?.field_type === "tel-phone" ? (
                  <div style={{ display: "flex", width: "100%" }}>
                    <PhoneInput
                      style={{ width: "100%" }}
                    defaultCountry="IN"
                      value={dynamicFields[el?.label] || "+91"}
                      onChange={(phone: string) =>
                        handleInputChange(el.label, phone)
                      }
                    />
                  </div>
                ) : el?.field_type === "check-box" ? (
                  <FormControl sx={{ width: "100%" }}>
                    <label className="required">
                      {capitalizeFirstLetter(el.label)}
                    </label>

                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={dynamicFields[el?.label] || []}
                      onChange={(e) =>
                        handleInputChange(el.label, e.target.value)
                      }
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        // Ensure selected is an array
                        if (Array.isArray(selected)) {
                          return selected.join(", ");
                        }
                        return "";
                      }}
                    >
                      {el?.multiple_choices?.map((choice: any) => (
                        <MenuItem key={choice?.id} value={choice?.choice}>
                          <Checkbox
                            checked={dynamicFields[el?.label]?.includes(
                              choice?.choice
                            )}
                          />
                          <ListItemText primary={choice?.choice} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <input
                    type={
                      el?.field_type === "date-time"
                        ? "datetime-local"
                        : el?.field_type === "email"
                        ? "email"
                        : el?.field_type === "number"
                        ? "number"
                        : el?.field_type === "date"
                        ? "datetime-local"
                        : "text"
                    }
                    placeholder={
                      el?.field_type === "email"
                        ? "Enter your email"
                        : el?.field_type === "number"
                        ? "Number"
                        : ""
                    }
                    className="form-control"
                    value={dynamicFields[el?.label] || ""}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                  />
                )}
              </div>
              {errors[el.label] && (
                <div style={{ color: "red" }}>
                  {capitalizeFirstLetter(errors[el.label])}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="row g-5 g-xl-8 mb-4">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h4 style={{ margin: "0" }}>Tags</h4>
            <div style={{ display: "flex", gap: "10px" }}>
              {tagsData?.map((tag) => (
                <p
                  key={tag.id}
                  onClick={() => tagSelectHandler(tag.id)}
                  className="mt-4"
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    background: selectedTags.includes(tag.id)
                      ? "#009ef7"
                      : "#cccccc",
                    color: selectedTags.includes(tag.id) ? "white" : "white",
                  }}
                >
                  {capitalizeFirstLetter(tag?.name)}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <button
          type="reset"
          className="btn btn-sm btn-white btn-active-light-danger me-2"
          onClick={closeModal}
        >
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={submitHandler}
          disabled={isButtonDisabled} // Disable button based on state
        >
          {isLoading ? (
            <>
              Submitting... <Loader />
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </>
  );
};

export default AddNewContactCampaignModal;
