import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import SettingRoutes from "../pages/settings";
import ActivityRoutes from "../pages/activity";
import ContactRoutes from "../pages/contact";
import EmailIndex from "../pages/email";
import TicketIndex from "../pages/ticket";
import DashboardRoutes from "../pages/dashboard";
import CampaignRoutes from "../pages/campaign";
import ChatRoutes from "../pages/chat";
import PhoneRoutes from "../pages/phone/Index";
import UserRoute from "../pages/users";
import AccountInfo from "../components/contact/accounts/AccountInfo";
import ContactInfo from "../components/contact/contact/ContactInfo";
import Reports from "../pages/report/Index";
import Profile from "../pages/profile/Profile";
import { useEffect, useState } from "react";
import { useWebSocket, WebSocketProvider } from "../context/WebSocketContext";
import Callhistory from "../pages/callhistory/index"
import ConversationsPage from "../pages/conversations";
import { UserProvider } from "../context/UserContext";
import { ChatProvider } from "../context/ChatContext";
const PrivateRoutes = () => {
  const [permissionList, setPermissionList] = useState<any>([]);

  const permissionToShow = localStorage.getItem("user-token");
  const location = useLocation();

  useEffect(() => {
    const gettingLocation = location.pathname;
    const first_word = gettingLocation.split("/")[1];
    localStorage.setItem("first_word", first_word);

    if (first_word === "ticket") {
      sessionStorage.removeItem("selectedFilter");
      sessionStorage.removeItem("selectedFilters");
      sessionStorage.removeItem("selectStarredFilters");
      sessionStorage.removeItem("selectStaredFilterAndValue");
      sessionStorage.removeItem("selectSentFilters");
      sessionStorage.removeItem("selectedSentFilterAndValue");
      sessionStorage.removeItem("inboxInitial_selectedTeamId");
      sessionStorage.removeItem("inboxInitial_currentPage");
      sessionStorage.removeItem("inboxInitial_perPage");
      sessionStorage.removeItem("selectedCampaignFilterAndValue");
      sessionStorage.removeItem("campaignCurrentPage");
      sessionStorage.removeItem("selectedOptionCampign");
      sessionStorage.removeItem("selectedCampaignId");
      sessionStorage.removeItem("selectedCampignFilters");
    }
    if (first_word === "email") {
      sessionStorage.removeItem("selectedTicketFilters");
      sessionStorage.removeItem("selectedFilterAndValue");
      sessionStorage.removeItem("ticketCurrentPage");
      sessionStorage.removeItem("selectedTeamId");
      sessionStorage.removeItem("selectedWorkFlow");
      sessionStorage.removeItem("selectedOption");
      sessionStorage.removeItem("selectedCampaignFilterAndValue");
      sessionStorage.removeItem("campaignCurrentPage");
      sessionStorage.removeItem("selectedOptionCampign");
      sessionStorage.removeItem("selectedCampignFilters");
  } 
  if (first_word === "campaign") {
      sessionStorage.removeItem("ticketCurrentPage");
      sessionStorage.removeItem("selectedTeamId");
      sessionStorage.removeItem("selectedWorkFlow");
      sessionStorage.removeItem("selectedFilter");
      sessionStorage.removeItem("selectStarredFilters");
      sessionStorage.removeItem("selectStaredFilterAndValue");
      sessionStorage.removeItem("selectSentFilters");
      sessionStorage.removeItem("selectedSentFilterAndValue");
      sessionStorage.removeItem("inboxInitial_selectedTeamId");
      sessionStorage.removeItem("inboxInitial_currentPage");
      sessionStorage.removeItem("inboxInitial_perPage");
      sessionStorage.removeItem("selectedTicketFilters");
      sessionStorage.removeItem("selectedOption");

    }
    if (
      first_word === "dashboard" ||
      first_word === "reports" ||
      first_word === "profile" ||
      first_word === "settings" ||
      first_word === "activity" ||
      first_word === "contact" ||
      first_word === "users" ||
      first_word === "chat"
    ) {
       sessionStorage.clear();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (permissionToShow) {
      const parsedPermission = JSON.parse(permissionToShow); // Parse the JSON string
      const teamPermissions = parsedPermission?.teams[0];

      // Find the key that you need dynamically
      const dynamicKey =
        Object.keys(teamPermissions).find((key) =>
          key.includes("module_permissions")
        ) || Object.keys(teamPermissions)[0];

      const permissionListValue = teamPermissions[dynamicKey] || []; // Ensure permissionListValue is an array
      setPermissionList(permissionListValue);
    }
  }, [permissionToShow]);

  const hasPermission = (permission) =>
    Array.isArray(permissionList) && permissionList.includes(permission);

  

  return (
    <UserProvider>
    <ChatProvider>
    
    <WebSocketProvider>
    <Routes>
      <Route element={<MasterLayout />}>
        {hasPermission("dashboard:enable") && (
          <Route path="dashboard/*" element={<DashboardRoutes />} />
        )}
        {hasPermission("campaigns:enable") && (
          <Route path="campaign/*" element={<CampaignRoutes />} />
        )}
        {hasPermission("customers:enable") && (
          <Route path="contact/*" element={<ContactRoutes />} />
        )}
        {hasPermission("ticket:enable") && (
          <Route path="ticket/*" element={<TicketIndex />} />
        )}
        {hasPermission("email:enable") && (
          <Route path="email/*" element={<EmailIndex />} />
        )}
        {hasPermission("users:enable") && (
          <Route path="users/*" element={<UserRoute />} />
        )}
        {/* {hasPermission("activity:enable") &&  */}
        <Route path="activity/*" element={<ActivityRoutes />} />
        {hasPermission("settings:enable") && (
          <Route path="settings/*" element={<SettingRoutes />} />
        )}
        {hasPermission("chat:enable") && (
          <Route path="chat/*" element={<ConversationsPage />} />
        )}   
        
        {hasPermission("reports:enable") && (
          <Route path="reports/*" element={<Reports />} />
        )}
        <Route path="/*" element={<PhoneRoutes />} />
        {hasPermission("customers:enable") && (
          <Route path="/customer/info/:id" element={<AccountInfo />} />
        )}
        {hasPermission("customers:enable") && (
          <Route path="/account/info/:id" element={<ContactInfo />} />
        )}

        {!hasPermission("dashboard:enable") && (
          <Route path="*" element={<Navigate to="/not-authorized" />} />
        )}
        <Route path="/profile" element={<Profile />} />
        <Route path="/call-history" element={<Callhistory />} />
      </Route>
    </Routes>
    </WebSocketProvider>

    </ChatProvider>
    </UserProvider>
  );
};

export { PrivateRoutes };
