import { useEffect, useState, useRef } from "react";
import MailCard from "../../../components/email/mail/CenterCard";
import MailHistory from "../../../components/email/mail/MailHistory";
import MailSideMenu from "../../../components/email/mail/MailRightSideMenu";
import ReplyCard from "../../../components/email/mail/ReplyCard";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  useMailHistoryQuery,
  useSpecificThreadsQuery,
  useUpdateDispositionMutation,
} from "../../../Services/email/EmailInbox";
import AssignTeam from "../../../components/email/inboxEmailModal/AssigneTeam";
import ReactModal from "react-modal";
import CustomerInfoEditModal from "../../../components/ticket/ticketListInfo/CustomerInfoEditModal";
import AccountInfoEditModal from "../../../components/ticket/ticketListInfo/AccountInfoEditModal";
import ReplyDispositionModal from "../../../components/ticket/ReplyDispositionModal";
import "../../contact/styles.scss";
import EmailTicketModal from "../../../components/email/inboxEmailModal/EmailTicketModal";

const Mail = () => {
  const [showReplyCard, setShowReplyCard] = useState(false);
  const [showContact, setShowContact] = useState(true);
  const [mailDetails, setMailDetails] = useState([]);
  const [otherMailDetails, setOtherMailDetails] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dispositionModal, setDispositionModal] = useState<boolean>(false);
  const [editCustomaerDetailModal, setEditCustomerDetailModal] =
    useState<boolean>(false);
  const [replyText, setReplyText] = useState<any>("");
  const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
  const [lastSelectedDisposition, setLastSelectedDisposition] =
    useState<any>("");
  const [mailHistoryContactId, setMailHistoryContactId] = useState<any>("");
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const params = useParams();
  const [refetch, setRefetch] = useState(false);
  const [storedDispositionData, setStoredDispositionData] = useState([]);
  const [storedTicketId, setStoredTicketId] = useState("");
  const [isModalOpenTicket, setIsModalOpenTicket] = useState(false);
  const [storedMailHistory, setStoredMailHistory] = useState([]);
  const [checkingForReplyApi, setCheckingForReplyApi] = useState(false);
  const [liftingReplyLoader, setLiftingReplyLoader] = useState(false);
  const replyCardRef = useRef<HTMLDivElement | null>(null); // Create a ref for ReplyCard

  const uuid = params.uuid;

  const {
    data: specificThreads,
    isLoading,
    refetch: refetchSpecificThreads,
  } = useSpecificThreadsQuery(uuid);

  const {
    data: mailHistory,
    isLoading: mailHistoryLoading,
    refetch: refetchMailHistory,
  } = useMailHistoryQuery(mailHistoryContactId, {
    skip: !mailHistoryContactId,
  });
  const [trigger, { isLoading: loading }] = useUpdateDispositionMutation();
  useEffect(() => {
    if (otherMailDetails?.action?.disposition?.disposition) {
    }
    setStoredDispositionData(
      otherMailDetails?.action?.disposition?.disposition
    );
  }, [otherMailDetails?.action?.disposition?.disposition]);

  useEffect(() => {
    if (specificThreads) {
      setOtherMailDetails(specificThreads);
      setMailHistoryContactId(specificThreads?.action?.contact?.id);
      setStoredTicketId(specificThreads?.action?.ticket?.id);
    }
  }, [specificThreads]);

  useEffect(() => {
    if (specificThreads?.emails) {
      setMailDetails(specificThreads?.emails);
    }
  }, [specificThreads]);

  useEffect(() => {
    if (refetch) {
      refetchSpecificThreads();
      setRefetch(false);
    }
  }, [refetch, refetchSpecificThreads]);

  const toggleReplyCard = (text: string) => {
    setReplyText(text);
    setShowReplyCard((prev) => {
      const shouldShow = !prev;

      // Scroll to the ReplyCard if "Reply All" is clicked and showing the card
      if (text === "replyAll" || (text === "reply" && shouldShow)) {
        setTimeout(() => {
          replyCardRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }

      return shouldShow;
    });
  };

  const handleToggle = () => {
    setShowContact(!showContact);
  };

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    return nameParts.map((part) => part[0]).join("");
  };
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "450px",
    },
  };

  const customModalStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
    },
  };
  const customModalStyles3 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "550px", // Max width for larger screens
      margin: "auto",
      OverflowY: "auto",
      maxHeight: "600px",
    },
  };

  const customModalStylesTicket = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "850px", // Max width for larger screens
      height: "80%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const afterOpenModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalTicket = () => {
    setIsModalOpenTicket(true);
  };

  const closeModalTicket = () => {
    setIsModalOpenTicket(false);
  };
  const openCustomerDetailModal = () => {
    setEditCustomerDetailModal(true);
  };

  const afterEditCustomerDetailOpenModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeEditCustomerDetailModal = () => {
    setEditCustomerDetailModal(false);
  };

  const openDispositionModal = () => {
    setDispositionModal(true);
  };

  const afterOpenDispositionModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeDispositionModal = () => {
    setDispositionModal(false);
  };

  const handleSave = () => {
    closeDispositionModal();
    refetchSpecificThreads();
    setLastSelectedDisposition(selectedTopics[selectedTopics.length - 1]);
  };
  const openAccountModal = () => {
    setIsAccountModalOpen(true);
  };

  const closeAccountModal = () => {
    setIsAccountModalOpen(false);
  };

  const assigneeName = otherMailDetails?.action?.assignee
    ? `${otherMailDetails?.action?.assignee?.username} `
    : "Unassigned";

  useEffect(() => {
    if (mailHistory) {
      setStoredMailHistory(
        mailHistory &&
          mailHistory[0]?.disposition &&
          mailHistory[0]?.disposition?.disposition
      );
    }
  }, [mailHistory]);

  const emails = "";
  const editorHtml = "";
  const cc = "";

  const bcc = "";
  const templateAttachment = "";
  const files = "";

  return (
    <div id="kt_app_content_container" className="app-container mx-5 mb-2">
      {/*begin::Products*/}
      <div className="card card-flush">
        <div className="card-header align-items-center py-1 gap-2 gap-md-5">
          <span className="p-4 fs-3 text-gray-800 fw-bold">Mail</span>
        </div>
        {/*begin::Card header*/}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="container-fluid mt-5">
            <div className="row mx-2">
              <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                <div
                  className="card card-flush"
                  style={{ marginLeft: "-30px" }}
                >
                  <div className="card px-2 mb-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="card-text mx-5">
                        Customer Information
                      </span>

                      {showContact !== null ? (
                        <button className="btn text-hover-primary">
                          <i
                            className="bi bi-pencil-fill text-dark mx-n5 fs-3"
                            onClick={
                              showContact
                                ? openCustomerDetailModal
                                : openAccountModal
                            }
                          ></i>
                        </button>
                      ) : (
                        <div
                          className="btn text-hover-primary"
                          style={{
                            cursor: "default",
                          }}
                        >
                          <div
                            className="bi bi-pencil-fill text-dark mx-n5 fs-3"
                            style={{
                              visibility: "hidden",
                            }}
                          ></div>
                        </div>
                      )}

                      <ReactModal
                        isOpen={editCustomaerDetailModal}
                        onRequestClose={closeEditCustomerDetailModal}
                        style={customModalStyles3}
                        contentLabel="Csutomer modal"
                      >
                        <CustomerInfoEditModal
                          data={otherMailDetails?.action?.contact}
                          closeModal={closeEditCustomerDetailModal}
                          refetch={refetchSpecificThreads}
                        />
                      </ReactModal>

                      <ReactModal
                        isOpen={isAccountModalOpen}
                        onRequestClose={closeAccountModal}
                        style={customModalStyles3}
                        contentLabel="Edit Account Information"
                      >
                        {/* Replace this with your account editing modal component */}
                        <AccountInfoEditModal
                          data={otherMailDetails?.action?.contact} // Assuming account data is part of updateCustomer
                          closeModal={closeAccountModal}
                          refetch={refetchSpecificThreads}
                        />
                      </ReactModal>
                    </div>
                  </div>
                  <div className="custom-style">
                    <div className="card px-2">
                      <div className="d-flex align-items-center flex-row py-4 gap-1 mx-5">
                        <span className="card-text">Account</span>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={showContact}
                            onChange={handleToggle}
                          />
                        </div>
                        <span className="card-text mx-n1">Contact</span>
                      </div>
                    </div>
                  </div>

                  {showContact ? (
                    <>
                      <div className="card px-2">
                        <div
                          className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                          data-bs-toggle="tooltip"
                          title={otherMailDetails?.action?.contact?.name}
                        >
                          <span
                            className="card-text"
                            data-bs-toggle="tooltip"
                            title="Name"
                          >
                            Name:
                          </span>
                          <div className="card-text text-truncate cursor-pointer">
                            {otherMailDetails?.action?.contact?.name}
                          </div>
                        </div>
                      </div>

                      <div className="card px-2">
                        <div
                          className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                          data-bs-toggle="tooltip"
                          title={otherMailDetails?.action?.contact?.email}
                        >
                          <i
                            className="bi bi-envelope fs-2 cursor-pointer"
                            data-bs-toggle="tooltip"
                            title="Email"
                          ></i>
                          <span>: </span>
                          <div className="card-text text-truncate cursor-pointer">
                            {otherMailDetails?.action?.contact?.email}
                          </div>
                        </div>
                      </div>

                      <div className="card px-2">
                        <div
                          className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                          data-bs-toggle="tooltip"
                          title={
                            otherMailDetails?.action?.contact?.phone_number
                          }
                        >
                          <i
                            className="bi bi-telephone fs-2 cursor-pointer"
                            data-bs-toggle="tooltip"
                            title="Phone Number"
                          ></i>
                          <span>: </span>
                          <span className="text-truncate cursor-pointer">
                            {otherMailDetails?.action?.contact?.phone_number}
                          </span>
                        </div>
                      </div>

                      <div className="card px-2">
                        <div
                          className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1"
                          data-bs-toggle="tooltip"
                          title="WhatsApp"
                        >
                          <i
                            className="bi bi-whatsapp fs-2 cursor-pointer"
                            data-bs-toggle="tooltip"
                            title="Whatsapp "
                          ></i>
                          <span>: </span>
                          <span className="text-truncate cursor-pointer">
                            {otherMailDetails?.action?.contact?.whatsapp_number
                              ? otherMailDetails?.action?.contact
                                  ?.whatsapp_number
                              : "NA"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Business Name */}
                      <div className="card px-2">
                        <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
                          <span className="card-text">Business Name:</span>
                          <div className="card-text text-truncate">
                            {otherMailDetails?.action?.contact?.account?.name ||
                              "NA"}
                          </div>
                        </div>
                      </div>

                      {/* Tags */}
                      <div className="card px-2">
                        <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
                          <span className="card-text">Tags:</span>
                          <div className="card-text text-truncate">
                            {otherMailDetails?.action?.contact?.account?.tags
                              ?.length > 0
                              ? otherMailDetails.action.contact.account.tags
                                  .map((tag) => tag.name)
                                  .join(", ")
                              : "NA"}
                          </div>
                        </div>
                      </div>

                      {/* Team */}
                      <div className="card px-2">
                        <div className="d-flex align-items-center flex-row py-4 gap-5 mx-5 my-1">
                          <span className="card-text">Team:</span>
                          <div
                            className="card-text text-truncate"
                            style={{
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={
                              otherMailDetails?.action?.contact?.account
                                ?.accessible_teams?.length > 0
                                ? otherMailDetails.action.contact.account.accessible_teams
                                    .map((team) => team.name)
                                    .join(", ")
                                : "NA"
                            }
                          >
                            {otherMailDetails?.action?.contact?.account
                              ?.accessible_teams?.length > 0
                              ? otherMailDetails.action.contact.account.accessible_teams
                                  .map((team) => team.name)
                                  .join(", ")
                              : "NA"}
                          </div>
                        </div>
                      </div>

                      {/* Accessible Teams */}
                    </>
                  )}
                </div>
              </div>

              <div className="col-xl-8 col-lg-8 col-md-12 mb-4">
                <div className="flex-lg-row-fluid">
                  <div className="card">
                    <div className="card-header align-items-center py-5 gap-5">
                      <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                          <button
                            className="btn btn-active-light-primary btn-icon"
                            title="Reply"
                            onClick={() => toggleReplyCard("reply")}
                          >
                            <i className="bi bi-reply fs-2"></i>
                          </button>
                          <button
                            className="btn btn-active-light-primary btn-icon"
                            title="Reply all"
                            onClick={() => toggleReplyCard("replyAll")}
                          >
                            <i className="bi bi-reply-all-fill fs-2"></i>
                          </button>

                          {otherMailDetails?.action?.ticket?.id ? (
                            <button
                              className="btn btn-active-light-primary btn-icon"
                              title="Process Ticket"
                            >
                              <Link
                                to={`/ticket/information/${otherMailDetails?.action?.ticket?.id}`}
                              >
                                <i className="bi bi-tag fs-2"></i>
                              </Link>
                            </button>
                          ) : (
                            <button
                              className="btn btn-active-light-primary btn-icon"
                              title="Create Ticket"
                              onClick={openModalTicket}
                            >
                              <i className="bi bi-tag fs-2"></i>
                            </button>
                          )}

                          <button
                            className="btn btn-active-light-primary btn-icon"
                            title="View Disposition"
                            onClick={openDispositionModal}
                          >
                            <i
                              className="bi bi-eye-fill"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </button>
                        </div>

                        <div
                          className="d-flex cursor-pointer"
                          onClick={openModal}
                        >
                          <div className="symbol symbol-circle">
                            <span className="symbol-label bg-light-danger text-danger fw-bolder">
                              {getInitials(assigneeName)}
                            </span>
                          </div>
                          <span
                            className="assign-name mt-4"
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            {assigneeName}
                            <i
                              className="fas fa-chevron-down"
                              style={{
                                marginLeft: "5px",
                              }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-5">
                      <MailCard
                        otherMailDetails={otherMailDetails}
                        mailDetails={mailDetails}
                      />
                    </div>
                  </div>
                  <div
                    className="card-body p-1"
                    style={{ margin: "0" }}
                    ref={replyCardRef}
                  >
                    {showReplyCard && (
                      <ReplyCard
                        otherMailDetails={otherMailDetails}
                        mailDetails={mailDetails}
                        replyText={replyText}
                        uuid={uuid}
                        refetchSpecificThreads={refetchSpecificThreads}
                        setShowReplyCard={setShowReplyCard}
                        storedMailHistory={storedDispositionData}
                        storedTicketId={storedTicketId}
                      />
                    )}
                  </div>
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mt-5 p-2">
                    <h4>History</h4>
                  </div>
                  <div className="card w-100 mt-2">
                    <MailHistory mailHistory={mailHistory} />
                  </div>
                </div>
              </div>

              {/* end:: The middle stack */}
              {/* start:: The right side stack */}
              <div className="col-xl-2 col-lg-4 col-md-12 mb-4">
                <MailSideMenu />
              </div>
              {/* end:: The right side stack */}
            </div>
          </div>
        )}

        {/*end::Card toolbar*/}

        <ReactModal
          isOpen={isModalOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customModalStyles}
          contentLabel="Email templates"
        >
          <AssignTeam
            closeModal={closeModal}
            setectedEmailId={uuid}
            refetchData={() => setRefetch(true)}
          />
        </ReactModal>

        <ReactModal
          isOpen={dispositionModal}
          onAfterOpen={afterOpenDispositionModal}
          onRequestClose={closeDispositionModal}
          style={customModalStyles2}
          contentLabel="Disposition"
        >
          <ReplyDispositionModal
            isLoading={loading}
            trigger={trigger}
            closeModal={closeDispositionModal}
            storedDisposition={storedDispositionData}
            refetch={refetchSpecificThreads}
            ticketId={storedTicketId}
            otherMailDetails={otherMailDetails}
            emails={emails}
            editorHtml={editorHtml}
            cc={cc}
            bcc={bcc}
            templateAttachment={templateAttachment}
            files={files}
            setCheckingForReplyApi={setCheckingForReplyApi}
            setLiftingReplyLoader={setLiftingReplyLoader}
          />
        </ReactModal>

        <ReactModal
          isOpen={isModalOpenTicket}
          onRequestClose={closeModalTicket}
          style={customModalStylesTicket}
          contentLabel="New Ticket"
        >
          <EmailTicketModal
            closeModal={closeModalTicket}
            refetch={refetchSpecificThreads}
            specificThreads={specificThreads}
          />
        </ReactModal>
      </div>
      {/*end::Card header*/}
    </div>
  );
};

export default Mail;
