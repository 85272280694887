import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import WhatsAppModal from './WhatsAppModal';
import DeleteWhatAppModal from './DeleteWhatAppModal';
import {
    useGetTenantDataQuery,
    useUpdateTenantDataMutation, useGetWhatsAppConfigurationQuery
    ,
} from "../../../../Services/settingsAPIs/CommonAPI";
import LoadingSpinner from "../../../../components/LoadingSpinner";


const WhatsAppConfiguration = () => {
    // State to manage the enable/disable status
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpenStatus, setModalOpenStatus] = useState<any>("configure")
    const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
    const [seletedEditData, setSeletedEditData] = useState<any>([])
    const defaultCompactFormModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            maxWidth: "850px", // Max width for larger screens
            height: "50%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
            background: "#ffffff",
        },
    };
    const { data: getTanentData, status: getTanentDataStatus } = useGetTenantDataQuery({});
    const { data: whatsAppData, status: getWhatsAppDataStatus, refetch } = useGetWhatsAppConfigurationQuery({});
    const [selectedWhatsId, setSelectedWhatsId] = useState<any>("");
    const [trigger] = useUpdateTenantDataMutation();
    // Handler for toggling WhatsApp configuration
    const handleWhatsAppToggle = async () => {
        try {
            // Toggle the current state
            const newStatus = !isWhatsAppEnabled;
            setIsWhatsAppEnabled(newStatus);

            // Call the mutation and pass the payload
            const response = await trigger({
                whatsapp_ticket_notification_enabled: newStatus,
            });

        } catch (error) {
            console.error("Error toggling WhatsApp configuration:", error);
        }
    };

    useEffect(() => {
        if (getTanentDataStatus === "pending" || getWhatsAppDataStatus === "pending") {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [getTanentDataStatus, getWhatsAppDataStatus]);

    const closeEditModal = () => {
        setEditModal(false);
    };
    const openEditModal = (modalstatus, item) => {
        setModalOpenStatus(modalstatus)
        setSeletedEditData(item)
        setEditModal(true)
    }
    const closeDeleteModal = () => {
        setIsDeleteButtonClicked(false);
    };
    const openDeleteModal = (id: any) => {
        setSelectedWhatsId(id);
        setIsDeleteButtonClicked(true);
    };

    useEffect(() => {
        if (getTanentData) {
            setIsWhatsAppEnabled(getTanentData.whatsapp_ticket_notification_enabled);
        }
    }, [getTanentData]);

    const deleteCustomModalStyles: ReactModal.Styles = {
        content: {
            top: "50%",
            left: "50%",
            width: "30%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    return (
        <div style={{marginLeft:"3%"}}>
            <div className='row'>
            <div className="col-10">
                <span className="input-group-text mt-2" id="basic-addon1">
                    WhatsApp configuration
                </span>
            </div>
            <div className='col'>

            <div
                    className="mt-5"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: '16px',marginRight:"4%",marginLeft:"-8%" }}>Disable</span>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isWhatsAppEnabled}
                                onChange={handleWhatsAppToggle}
                            />
                        </div>
                        <span style={{ fontSize: '16px' }}>Enable</span>
                    </div>

                    {/* Show the Configure button only when isWhatsAppEnabled is true */}
                    {(isWhatsAppEnabled && whatsAppData?.results?.length === 0) && (
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => openEditModal("configure", null)}
                        >
                            Configure
                        </button>
                    )}
                </div>
            </div>
            </div>
          
            {isLoading ? <LoadingSpinner /> : (<>





                {isWhatsAppEnabled && (
                    <div className="row mx-0 mb-2 rounded-3 py-4" style={{ maxHeight: "60vh", marginBottom: "50px" }}>
                        <div className="row" style={{ tableLayout: 'fixed', borderCollapse: 'collapse', paddingRight: "0px" }}>
                            <div
                                className="col px-2"
                                style={{ paddingRight: "5px", paddingLeft: "5px" }}
                            >
                                <div
                                    className="card px-2 my-1 min-w-600px"
                                    style={{
                                        backgroundColor: "#E4E4E47F",
                                    }}
                                >
                                    {/* Header */}
                                    <div className="row align-items-center py-4">
                                        <div className="col text-start ps-5">
                                            <strong>Sr.No</strong>
                                        </div>
                                        <div className="col text-center">
                                            <strong>Phone number id</strong>
                                        </div>
                                        <div className="col text-center">
                                            <strong>User access token</strong>
                                        </div>
                                        <div className="col text-center">
                                            <strong>API version</strong>
                                        </div>
                                        <div className="col text-end me-2">
                                            <strong style={{ paddingRight: "10px" }}>Actions</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Rows */}

                        <div className="row" style={{ tableLayout: 'fixed', borderCollapse: 'collapse', paddingRight: "0px" }}>
                            <div
                                className="col px-2"
                                style={{ paddingRight: "5px", paddingLeft: "5px" }}
                            >
                                {whatsAppData?.results && whatsAppData?.results.map((item, index) => (
                                    <div
                                        className="card px-2 my-1 min-w-600px"
                                        draggable="true"
                                        style={{ cursor: "grab", padding: "7px 0px" }}
                                        key={index}
                                    >
                                        <div className="row align-items-center">
                                            {/* ID */}
                                            <div className="col text-start ps-5">
                                                <label>{index + 1}</label>
                                            </div>

                                            {/* Phone Number ID */}
                                            <div className="col text-center">
                                                <label>{item.phone_number_id}</label>
                                            </div>

                                            {/* User Access Token */}
                                            <div className="col text-center">
                                                <label>{item.user_access_token}</label>
                                            </div>

                                            {/* API Version */}
                                            <div className="col text-center">
                                                <label>{item.api_version}</label>
                                            </div>

                                            {/* Actions */}
                                            <div className="col text-end me-6">
                                                <button
                                                    className="text-hover-primary border-0 bg-white me-2"
                                                    title="Edit"
                                                    onClick={() => openEditModal("edit", item)}
                                                >
                                                    <i className="bi bi-pencil fs-4"></i>
                                                </button>
                                                <button
                                                    className="text-hover-danger border-0 bg-white"
                                                    title="Delete"
                                                    onClick={() => openDeleteModal(item.id)}

                                                >
                                                    <i className="bi bi-trash fs-4"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            </div>
                    </div>

                )}
            </>)}

            <ReactModal
                isOpen={editModal}
                onRequestClose={closeEditModal}
                style={defaultCompactFormModalStyles}
            >
                <WhatsAppModal
                    closeModal={closeEditModal}
                    editModalData={seletedEditData}
                    editModal={editModal}
                    modalOpenStatus={modalOpenStatus}
                    refetch={refetch}
                />
            </ReactModal>

            <ReactModal
                isOpen={isDeleteButtonClicked}
                onRequestClose={closeDeleteModal}
                style={deleteCustomModalStyles}
            >
                <DeleteWhatAppModal
                    closeModal={closeDeleteModal}
                    fieldsData={selectedWhatsId}
                    refetch={refetch}
                />
            </ReactModal>
        </div>
    );
};

export default WhatsAppConfiguration;
