import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const Chat = createApi({
    reducerPath: "chat",
    baseQuery: dynamicBaseQuery,
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => ({
        chatEnableAndDisable: builder.mutation({
            query: (flag ) => ({
                url: `v1/common/crm-bg/1/?bg_id_enabled=${flag}`,
                method: "PUT",
            }),
        }),
        crmBgAccountCheck: builder.query({
            query: () => ({
                url: "v1/common/crm-bg/1",
                method: "GET",
            }),
        }),
    }),
});

export const { useChatEnableAndDisableMutation, useCrmBgAccountCheckQuery } = Chat;
