import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const TelephonyFrontend = createApi({
    reducerPath: "frontendtelephony",
    baseQuery: dynamicBaseQuery,
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => ({
        telephonyExtensions: builder.query({
            query: (id) => ({
                url: `v1/users/user-telephony-extension/?user_id=${id}`,
                method: "GET",
            }),
        }),

        telephonyPRI: builder.query({
            query: () => ({
                url: `v1/telephony/telephony-pri/`,
                method: "GET",
            }),
        }),

        telephonyServer: builder.query({
            query: () => ({
                url: `v1/telephony/telephony-server-config/`,
                method: "GET",
            }),
        }),

        createTelephonyServer: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/telephony-server-config/`,
                method: "POST",
                body
            }),
        }),

        deleteTelephonyServer: builder.mutation({
            query: (id) => ({
                url: `v1/telephony/telephony-server-config/${id?.id}`,
                method: "DELETE",
                
            }),
        }),

        // telephonyExtension: builder.query({
        //     query: (id) => ({
        //         url: `v1/telephony/inbound-call/?extension=${id}&`,
        //         method: "GET",
        //     }),
        // }),

        inboundCall: builder.query({
            query: (id) => ({
                url: `v1/telephony/inbound-call/?extension=${id}&`,
                method: "GET",
            }),
        }),

        extensionState: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/extension-states/`,
                method: "POST",
                body,
            }),
        }),

        callBreak: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/users/call-breaks/${id}/`,
                method: "POST",
                body,
            }),
        }),

        callBreakWithoutId: builder.mutation({
            query: (body) => ({
                url: `v1/users/call-breaks/`,
                method: "POST",
                body,
            }),
        }),


        customerContactNumber: builder.query({
            query: (id) => ({
                url: `v1/customers/contacts/?phone_number__endswith=${id}&`,
                method: "GET",
            }),
        }),

        callHistory: builder.mutation({
            query: (body) => ({
                url: `v1/users/call-history/`,
                method: "POST",
                body,
            }),
        }),

        callHistoryId: builder.mutation({
            query: ({body, id}) => ({
                url: `v1/users/call-history/${id}/`,
                method: "POST",
                body,
            }),
        }),



       

        callExtensionState: builder.query({
            query: (id) => ({
                url: `v1/telephony/get-extension-state/?extension=${id}&`,
                method: "GET",
            }),
        }),

        telephonyDisposition: builder.query({
            query: (id) => ({
                url: `v1/telephony/disposition/?team=${id}&type=OB&`,
                method: "GET",
            }),
        }),

        // Incoming CALL

     

        // v1/telephony/inbound-call/?extension=7020&

    }),
});

export const {
    useDeleteTelephonyServerMutation,
    useCreateTelephonyServerMutation,
    useTelephonyExtensionsQuery,
    useTelephonyPRIQuery,
    useTelephonyServerQuery,
    // useTelephonyExtensionQuery,
    useExtensionStateMutation,
    useInboundCallQuery,
    useCallBreakMutation,
    useCallBreakWithoutIdMutation,
    useCustomerContactNumberQuery,
    useCallHistoryMutation,
    useCallHistoryIdMutation,
    useCallExtensionStateQuery,
    useTelephonyDispositionQuery

} = TelephonyFrontend;
