import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import EmptyRows from "../../../../components/reusableComponent/EmptyRows";
import { useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
interface IProps {
  data: any; // Consider defining a more specific type
}

const PieChart: React.FC<IProps> = ({ data }) => {
  const [chartData, setChartData] = useState<any>({
    series: [],
    options: {
      chart: {
        width: "50%",
        type: "pie",
      },
      labels: [],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number, opts: any) => {
          return opts.w.globals.series[opts.seriesIndex]; // Show lead count
        },
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: number, opts?: any) => {
            console.log(opts, "opts");
            console.log(val, "val");


            const total = opts.config?.series?.reduce(
              (acc: number, num: number) => acc + num,
              0
            );
            console.log(total, "total");
            const percentage = ((val / total) * 100).toFixed(1);
            return `${percentage}%`; // Show percentage on hover
          },
        },
      },
      legend: {
        show: true, // Enable legend
        position: "bottom",
      },
    },
  });
  const [modalData, setModalData] = useState<any>({
    status: '',
    count: 0,
    allLeadsList: []
  });
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.lead_stats?.campaign_summary) {

      const campaigns = Object.values(data.lead_stats.campaign_summary) as {
        campaign_name: string;
        total_leads: number;
        lead_details: {
          lead_id: string;
          first_name: string;
          email: string;
          phone_number: string;
        };
      }[];

      const labels = campaigns.map((c) => c.campaign_name);
      const series = campaigns.map((c) => c.total_leads);

      const leadDetails = campaigns.map((c) => c.lead_details);

      setChartData((prev) => ({
        ...prev,
        series,
        options: {
          ...prev.options,
          labels,
        },
        leadDetails, // if you want to use lead details elsewhere
      }));
    }
  }, [data]);

  const openModal = (status: string, count: any, allLeadsList: any) => {
    setModalData({
      status: status,
      count: count,
      allLeadsList: allLeadsList
    })

    setModalOpen(true);
  };


  const closeModal = () => {
    setModalOpen(false);
  };
  const handleChartClick = (event: any, chartContext: any, config: any) => {
    const status = chartData.options.labels[config.dataPointIndex];
    const campaignSummary = data?.lead_stats?.campaign_summary;
    const keys = Object.keys(campaignSummary);
    const selectedData = campaignSummary[keys[config.dataPointIndex]];

    console.log(selectedData, "test");
    // const leadStageStats = data?.lead_stats?.lead_stage_stats;
    // const firstKey = Object.keys(leadStageStats)[0];
    // const leadsByStatus = leadStageStats[firstKey] || {};
    // console.log(leadsByStatus, "leadsByStatus");
    // const getData = status.split(" ")[0].toLowerCase();;

    // const count = leadsByStatus[getData]?.count || 0;
    const allLeadsList = selectedData?.lead_details || [];

    openModal(selectedData?.campaign_name, selectedData?.total_leads, allLeadsList);
  };
  const ticketDashboardDisposition = "disposition"

  return (
    <div
      className="card"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "20px",
      }}
    >
      <div className="card-body pb-0">
        <h5 className="card-title text-center  ">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">Number of leads per campaign</span>
        </h5>
        <div
          id="budgetChart"
          style={{
            minHeight: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {chartData.series.length === 0 ? (
            <p className="text-center">
              <EmptyRows value={ticketDashboardDisposition} />
            </p>
          ) : (

            <ReactApexChart
              options={{
                ...chartData.options,
                chart: {
                  events: {
                    dataPointSelection: handleChartClick
                  }
                }
              }}
              series={chartData.series}
              type="pie"
              height="300px"
            />
          )}
        </div>
      </div>


      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // This will center the modal both horizontally and vertically
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2, // Optional: Adds rounded corners to the modal
            width: '40vw', // Optional: Controls the modal's width, can be set to a specific value like '400px'
            maxHeight: '80vh', // Optional: Ensures the modal content is scrollable if content overflows
            overflowY: 'auto', // Enables scrolling if content overflows vertically

          }}
        >
          {/* Close Icon in Top-Right Corner */}
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'gray', // You can adjust this color as needed
            }}
          >
            <CloseIcon />
          </IconButton>


          <h2 id="modal-modal-title">{`Lead campaign ${modalData.status.charAt(0).toLowerCase() + modalData.status.slice(1)}`}</h2>

          {/* Table for account details */}
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Lead id</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>First name</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Email</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Phone number</th>
              </tr>
            </thead>
            <tbody>
              {modalData?.allLeadsList?.map((lead, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/campaign/lead/${lead.lead_id}`)}
                >
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.lead_id}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.first_name}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.email}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.phone_number}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Modal>
    </div>
  );
};

export default PieChart;
