import { useState, useEffect } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { toast } from "react-toastify";
import { useSaveNewEscallationMutation } from "../../../../Services/settingsAPIs/CommonAPI";

const AddTagBasedNewLevel = ({
  closeModal,
  currentEscalationLevel,
  selectedTag,
  refetch,
}) => {
  const [emailLists, setEmailLists] = useState({});
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(0);
  const [escalationLevel, setEscalationLevel] = useState(
    currentEscalationLevel
  );
  const tagInfoIndex = selectedTag?.id || "default";

  const [triggerSaveEscallation] = useSaveNewEscallationMutation();

  useEffect(() => {
    setEscalationLevel(currentEscalationLevel);
  }, [currentEscalationLevel]);


  const handleSave = () => {
    const level = `Escalation Level ${escalationLevel}`;
    const email = emailLists[tagInfoIndex] || [];
    const durationInMs = firstValue * 3600000 + secondValue * 60000;

    const payload = {
      level: {
        [level]: {
          email,
          duration_in_ms: durationInMs,
        },
      },
    };

    try {
      triggerSaveEscallation({
        body: payload,
        escalationLevel: level,
        id: selectedTag.tag_id,
      });
      toast.success("Escalation saved successfully");
      refetch();
      closeModal();
    } catch (error) {
      console.error("Error saving escalation:", error);
      toast.error("Failed to save escalation. Please try again.");
    }
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <div className="row">
          <div className="col">
            <label className="fs-4" htmlFor="">
              Add Level
            </label>
          </div>
        </div>

        {/* Table Headers */}
        <div className="row min-w-1000px mt-3 fw-bold text-center">
          <div className="col-3 text-start ps-10">
            <strong>Escalation Level</strong>
          </div>
          <div className="col-1 text-center">
            <strong>Hours</strong>
          </div>
          <div className="col-1 text-center">
            <strong>Minutes</strong>
          </div>
          <div className="col-7 text-center">
            <strong>Mail ID</strong>
          </div>
        </div>

        {/* Input Section */}
        <div className="row min-w-1000px">
          <div className="col">
            <div className="input-group-text mt-2 bg-white">
              <div className="row w-100 align-items-center">
                <div className="col-3 text-start">
                  <input
                    className="form-control border-0"
                    type="text"
                    value={`Escalation level ${escalationLevel}`}
                    readOnly
                  />
                </div>
                {/* Hours Counter */}
                <div className="col-1 text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <label>{firstValue}</label>
                    <div className="d-flex flex-column ms-2">
                      <i
                        className="bi bi-caret-up-fill cursor-pointer"
                        onClick={() => setFirstValue(firstValue + 1)}
                      ></i>
                      <i
                        className="bi bi-caret-down-fill cursor-pointer"
                        onClick={() =>
                          setFirstValue(firstValue > 0 ? firstValue - 1 : 0)
                        }
                      ></i>
                    </div>
                  </div>
                </div>
                {/* Minutes Counter */}
                <div className="col-1 text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <label>{secondValue}</label>
                    <div className="d-flex flex-column ms-2">
                      <i
                        className="bi bi-caret-up-fill cursor-pointer"
                        onClick={() => setSecondValue(secondValue + 1)}
                      ></i>
                      <i
                        className="bi bi-caret-down-fill cursor-pointer"
                        onClick={() =>
                          setSecondValue(secondValue > 0 ? secondValue - 1 : 0)
                        }
                      ></i>
                    </div>
                  </div>
                </div>
                {/* Email Input */}
                <div className="col-7 text-center">
                  <ReactMultiEmail
                    placeholder="Input your Email Address"
                    emails={emailLists[tagInfoIndex] || []}
                    onChange={(updatedEmails) =>
                      setEmailLists((prev) => ({
                        ...prev,
                        [tagInfoIndex]: updatedEmails,
                      }))
                    }
                    getLabel={(email, index, removeEmail) => (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="row mt-2">
        <div className="col text-end">
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AddTagBasedNewLevel;
