import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import RoutingAddModal from "./RoutingAddModal";
import {
  useCampaignConfigurationQuery,
  useCampaignTeamsData1Query,
  useCampaignTeamsDataQuery,
  useCampaignTeamsQuery,
  useDeleteCampaignConfigurationMutation,
} from "../../../../Services/campaign/CampaignMapping";
import EditCampaignRoutingModal from "./EditCampaignRoutingModal";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../LoadingSpinner";
import DeleteContactModal from "../../../contact/contact/DeleteContactModal";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "25%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const customResetPassModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    maxWidth: "850px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const CampaignRouting = () => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [deleteCampaign, setDeleteCampaign] = useState(null);
  const [EditCampaign, setEditCampaign] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { data: teamsData } = useCampaignTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [thecampaignTeamData, setThecampaignTeamData] = useState([]);
  const [isCampaignConfigurationData, setIsCampaignConfigurationData] =
    useState([]);

  const userRole = localStorage.getItem("user_role");

  const { data: campaignTeamsData } = useCampaignTeamsData1Query(selectedTeam, {
    skip: !selectedTeam,
  });

  const [triggerDeleteCampaign] = useDeleteCampaignConfigurationMutation(
    selectedCampaign?.id
  );
  const {
    data: campaignConfigurationData,
    isLoading,
    refetch,
  } = useCampaignConfigurationQuery(selectedCampaign, {
    skip: !selectedCampaign,
  });

  useEffect(() => {
    setTheTeams(teamsData);
  }, [teamsData]);

  useEffect(() => {
    setThecampaignTeamData(campaignTeamsData?.results);
  }, [campaignTeamsData]);

  useEffect(() => {
    setIsCampaignConfigurationData(campaignConfigurationData);
  }, [campaignConfigurationData]);

  const handleTeamDropdown = (event: any) => {
    const selectedTeamId = Number.parseInt(event.target.value);

    // Reset the states when the team changes
    setSelectedTeam(selectedTeamId);
    setSelectedCampaign(null); // Reset the selected campaign
    setIsCampaignConfigurationData([]);
  };

  const handleTeamDataDropdown = (event: any) => {
    setSelectedCampaign(Number.parseInt(event.target.value));
  };

  const openModal = () => {
    // Check if a team and a workflow are selected
    if (!selectedTeam || !selectedCampaign) {
      toast.error("Please select both team and campaign");
      return; // Exit the function early if not selected
    }

    setIsModalOpen(true); // Open the modal if both are selected
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEditModal = (campaign) => {
    setEditCampaign(campaign);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = (campaign) => {
    setDeleteCampaign(campaign);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div className="col" style={{ marginBottom: "20px" ,marginLeft:"2%"}}>
        <span className="input-group-text mt-2" id="basic-addon1">
          Campaign routing
        </span>
      </div>
      <div className="row mb-5" style={{marginLeft:"2%"}}>
        <div className="col-2 mw-200px">
          <label>Select team</label>
          <select
            className="form-select select2-hidden-accessible"
            onChange={handleTeamDropdown}
          >
            <option value="" selected disabled>
              Select team
            </option>
            {theTeams &&
              theTeams.map((team, index) => (
                <option key={index} value={team.id}>
                  {team.name}
                </option>
              ))}
          </select>
        </div>

        {selectedTeam && (
          <div className="col-2 mw-200px">
            <label>Select campaign</label>
            <select className="form-select" onChange={handleTeamDataDropdown}>
              <option value="" selected disabled>
                Select a campaign
              </option>
              {thecampaignTeamData?.map((campaign, index) => (
                <option key={index} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="col-2 mw-150px text-end ms-auto p-5">
          <button
            className="input-group-text align-items-center text-hover-primary fw-bold"
            onClick={openModal}
            style={{ marginLeft: "42%" }}
          >
            <i className="bi bi-plus-lg me-2"></i>
            Add
          </button>
        </div>

        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={customModalStyles}
          contentLabel="Add a new campaign configuration"
        >
          <RoutingAddModal closeModal={closeModal} refetch={refetch} />
        </ReactModal>
      </div>

      <div className="row" style={{ paddingRight: "0" ,marginLeft:"2%"}}>
        <div
          className="col px-2"
          style={{ paddingRight: "5px", paddingLeft: "5px" }}
        >
          <div className="card my-1" style={{ backgroundColor: "#E4E4E47F" }}>
            <div className="row align-items-center py-4">
              <div className="col text-start ps-10">
                <label>
                  <strong>Sr.No</strong>
                </label>
              </div>
              <div className="col-3 text-start ps-10">
                <strong>Campaign name</strong>
              </div>
              <div className="col-3 text-start ps-10">
                <label>
                  <strong>Team name</strong>
                </label>
              </div>
              <div className="col-2 text-start ps-10">
                <label>
                  <strong>Routing name</strong>
                </label>
              </div>

              <div className="col-2 text-center">
                <label>
                  <strong>Action</strong>
                </label>
              </div>
            </div>
          </div>

          {/* Table Content */}
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <LoadingSpinner />
            </div>
          ) : (
            isCampaignConfigurationData?.map((field, index) => (
              <div key={field.label} className="card my-1">
                <div className="row align-items-center py-3">
                  {/* Serial Number */}
                  <div className="col text-start ps-10">{index + 1}</div>

                  {/* Campaign Name */}
                  <div className="col-3 text-start ps-10">
                    {field?.campaign?.name}
                  </div>

                  {/* Team Name */}
                  <div className="col-3 text-start ps-10">
                    {field?.campaign?.team?.name}
                  </div>

                  {/* Routing Name */}
                  <div className="col-2 text-start ps-10">
                    {/* Determine routing name based on conditions */}
                    {field?.round_robin === true
                      ? "Round Robin"
                      : field?.manual_routing === true
                      ? "Individual Assignment"
                      : field?.default_routing === true
                      ? "Team Assignment"
                      : field?.FIFO === true
                      ? "Active Login RR"
                      : field?.load_balancer === true
                      ? "Load Balancer"
                      : ""}
                    {/* Default fallback */}
                  </div>

                  {/* Action */}
                  <div className="col-2 text-center d-flex justify-content-center">
                    {/* Edit Icon with Tooltip */}
                    <p
                      className="text-hover-primary me-3"
                      role="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                      onClick={() => openEditModal(field)}
                    >
                      <i className="bi bi-pencil fs-4 text-hover-primary" />
                    </p>

                    {/* Delete Icon with Tooltip */}
                    {userRole !== "agent" ? (
                      <p
                        className="text-hover-danger"
                        role="button"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete"
                        onClick={() => openDeleteModal(field)}
                      >
                        <i className="bi bi-trash fs-4 text-hover-danger" />
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            ))
          )}

          <ReactModal
            isOpen={isEditModalOpen}
            onRequestClose={closeEditModal}
            style={customModalStyles}
            contentLabel="Edit Campaign configuration"
          >
            <EditCampaignRoutingModal
              EditCampaign={EditCampaign}
              closeEditModal={closeEditModal}
              refetch={refetch}
              isLoading={isLoading}
            />
          </ReactModal>

          <ReactModal
            isOpen={isDeleteModalOpen}
            onRequestClose={closeDeleteModal}
            style={customResetPassModalStyles}
            contentLabel="Delete Campaign configuration"
          >
            <DeleteContactModal
              closeModal={closeDeleteModal}
              setFieldsData={setIsCampaignConfigurationData}
              fieldsData={isCampaignConfigurationData}
              storingFieldData={deleteCampaign}
              refetch={refetch}
              trigger={triggerDeleteCampaign}
            />
          </ReactModal>
        </div>
      </div>
    </>
  );
};

export default CampaignRouting;
