import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    AUTH_TOKEN,
    CALL,
    CALL_BREAK_REMARK,
    CALL_BREAK_USER_ID,
    CALL_HISTORY_ID,
    CALLBREAKID,
    EXTENSION,
    INCOMING_CALL,
    OUTGOING_CALL,
    QUEUE,
    TELEPHONEURL,
    TELEPHONY_LOGIN,
    TELEPHONYLOGINID,
} from "../../constants";
import { usePauseCodeDataQuery } from "../../Services/Telephony/Telephony";
import {
    useCallBreakMutation,
    useCallBreakWithoutIdMutation,
    useCallExtensionStateQuery,
    useCallHistoryMutation,
    useExtensionStateMutation,
    useInboundCallQuery,
    // useTelephonyExtensionQuery,
    useTelephonyPRIQuery,
    useTelephonyServerQuery,
} from "../../Services/Telephony/TelephonyFrontend";
import TelephonyTimer from "./TelephonyTimer";
import DialPad from "./DialPad";
import CallDisconnect from "./CallDisconnect";
import PauseCall from "./PauseCall";
import "./styles.scss";
import TransferCall from "./TransferCall";
import Conference from "./Conference";
import ThirdPartCallDisconnect from "./ThirdPartCallDisconnect";
import { useTelephony } from "../context/TelephonyContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "react-bootstrap-typeahead";
import CustomerInfo from "./CustomerInfo";
import { Box, Modal } from "@mui/material";


const TelephonyFrontend = () => {
    const [telephonyPriData, setTelephonyPriData] = useState([]);
    const [call, setCall] = useState<any>(() => {
        const storedCall = localStorage.getItem(CALL);
        return storedCall === "true"; // Convert string to boolean
    });

    const [isCallPaused, setIsCallPaused] = useState<boolean>(false);
    const [getpauseCode, setGetPauseCode] = useState([]);
    const [queueus, setQueueus] = useState([]);
    const [unPauseResponse, setUnPauseResponse] = useState("");
    const [inbound, setInbound] = useState([]);
    const [pauseCodeValue, setPauseCodeValue] = useState("");
    const [openDialPad, setOpenDialpad] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [pri, setPri] = useState(null);
    const [callHistoryResponsee, setCallHistoryResponsee] = useState<any>({});
    const [isCallDisconnected, setIsCallDisconnected] = useState(false);
    const [isTransferCallActive, setIsTransferCallActive] =
        useState<boolean>(false);
    const [isConferenceCallActive, setIsConferenceCallActive] =
        useState<boolean>(false);
    const [conferenceNumber, setConferenceNumber] = useState("");
    const [isCallPause, setIsCallPause] = useState(false);
    const [conferenceCallApiCalled, setConferenceCallApiCalled] =
        useState(false);
    const [isPauseCodeSelectedManual, setIsPauseCodeSelectedManual] = useState(false)
    const [checkingExtensionState, setCheckingExtensionState] = useState('')

    const [storingCustomerData, setStoringCustomerData] = useState<any>([]);

    const [gettingCallResumed, setGettingCallResumed] = useState(false);

    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const [isActive, setIsActive] = useState(true);
    const [isCustomerHistoryModalOpen, setIsCustomerHistoryModalOpen] =
        useState(false);

    const gettingExtension = localStorage.getItem(EXTENSION);
    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
    const getingTelephonyLoginStatus = localStorage.getItem(TELEPHONY_LOGIN);
    const callBreakId = localStorage.getItem(CALLBREAKID);
    const callBreakRemark = localStorage.getItem(CALL_BREAK_REMARK);
    const gettingUserId = localStorage.getItem("user_Id")
    const gettingQueues = localStorage.getItem(QUEUE);
    const getUserId = localStorage.getItem(CALL_BREAK_USER_ID);
    const outgoingCall = localStorage.getItem("telephony_outgoing")
    const incoming = localStorage.getItem(INCOMING_CALL) === "true"
    const storingUserName = localStorage.getItem('circleone-username')
    const telephonyLoginId = localStorage.getItem(TELEPHONYLOGINID);


    const navigate = useNavigate();

    const {
        callDisconnected,
        setLifitinMobileNumber,
        liftingMobileNumber,
        setCallDisconnected,
        setCallActive,
        callActive,
        setIsLoading,
        setStoredIncomingCallContactData,
        setInboundCount,
    } = useTelephony();


    useEffect(() => {
        if (telephonyPriData?.length === 1) {
            setPri(telephonyPriData[0].phone_no);
        } else {
            setPri(""); // Reset to default if multiple options exist
        }
    }, [telephonyPriData]);



    useEffect(() => {
        if (callDisconnected) {
            setLifitinMobileNumber("");
            setConferenceNumber("");
            setIsCustomerHistoryModalOpen(false)
        }
    }, [callDisconnected]);

    const [
        extensionStateTrigger,
        { data: extensionStateData, status: extensionStateStatus },
    ] = useExtensionStateMutation();
    const {
        data: inboundCallData,
        status: inboundCallStatus,
        isLoading: inboundCallLoading,
        refetch: inboundCallRefetch,
    } = useInboundCallQuery(gettingExtension, {
        skip: !gettingExtension || !isCallPaused || inbound[0]?.id
    });
    const [callBreakTrigger, { data: callBreakData, status: callBreakStatus }] =
        useCallBreakMutation();
    const [
        callBreakWithoutIdTrigger,
        { data: callBreakWithoutIdData, status: callBreakWithoutIdStatus },
    ] = useCallBreakWithoutIdMutation();


    // useEffect(() => {
    //     const obj = {
    //         break_remark: pauseCodeValue,
    //         user_id: +getUserId,
    //     }

    //     callBreakWithoutIdTrigger(obj)
    // }, [callDisconnected])

    const telephoneData = JSON.parse(gettingTelephonyData);

    useEffect(() => {
        if (callBreakRemark) {
            setPauseCodeValue(callBreakRemark);
        }
    }, [callBreakRemark]);


    useEffect(() => {
        if (gettingQueues) {
            console.log(gettingQueues)
            // Split the comma-separated string into an array
            const queuesArray = gettingQueues.split(",");
            setQueueus(queuesArray);
        }
    }, [gettingQueues]);


    useEffect(() => {
        if (inboundCallData) {
            setInbound(inboundCallData?.results);
            setInboundCount(inboundCallData?.count);
        }
    }, [inboundCallData]);

    const {
        data: telephonyPri,
        status: telephonyPriStatus,
        isLoading: telephonyPriLoading,
    } = useTelephonyPRIQuery({});
    const {
        data: callData,
        status: callStatus,
        isLoading: callLoading,
    } = useTelephonyServerQuery({}, { skip: !call || !gettingTelephonyData });

    const {
        data: pauseCodeData,
        status: pauseCodeStatus,
        isLoading: pauseCodeLoading,
        // refetch: pauseCodeRefetch,
    } = usePauseCodeDataQuery({});

    const [pauseCodeLoad, setPauseCodeLoad] = useState(false)

    useEffect(() => {
        if (pauseCodeStatus === "pending") {
            setPauseCodeLoad(true)

        }
        else {
            setPauseCodeLoad(false)
        }

    }, [pauseCodeStatus])

    const { data: callExtemsionData, refetch: callExtensionRefetch } =
        useCallExtensionStateQuery(gettingExtension, {
            skip: !call || !gettingExtension,
        });


    useEffect(() => {
        if (callExtemsionData) {

            setCheckingExtensionState(callExtemsionData?.state)
        }

    }, [callExtemsionData, callExtemsionData?.state])


    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const [callHistoryTrigger, { isLoading: callHistoryStatus }] =
        useCallHistoryMutation();

    // useEffect(() => {
    //     if (callHistoryStatus === "pending") {
    //         setIsLoading(true)
    //     }
    //     else if (callHistoryStatus === "fulfilled")  {
    //         setIsLoading(false)
    //     }

    // }, [callHistoryStatus])

    const location = useLocation();

    useEffect(() => {
        if (!call) {
            setLifitinMobileNumber("");
        }
    }, [location.pathname, call]);


    const callHistoryObj = {
        call_type: "inbound",
        called_from_phone_no: inbound[0]?.did_no,
        campaign_ivr_msg: inbound[0]?.queue_name,
        campaign_name: inbound[0]?.queue_name,
        customer_phone_no: inbound[0]?.customer_phone,
        extension_no: gettingExtension,
        inbound_unique_id: inbound[0]?.unique_id,
        language: "",
        user_id: +gettingUserId,
        // user_id: storedTelephonyLoginDetails?.user_id,

    };

    useEffect(() => {
        if ((outgoingCall === "true" || incoming) && call && gettingExtension) {
            const timer = setInterval(() => {
                if (!call) return;
                callExtensionRefetch();
            }, 3000);

            setCallDisconnected(false);

            return () => clearInterval(timer);
        }
    }, [call, callExtensionRefetch, gettingExtension, outgoingCall, incoming, setCallDisconnected]);


    useEffect(() => {
        const fetchCallHistory = async () => {
            if (inbound[0]) {
                localStorage.setItem(INCOMING_CALL, "true");
                try {
                    setCall(true);
                    localStorage.setItem(CALL, "true");

                    setLifitinMobileNumber(inbound[0]?.customer_phone);

                    localStorage.setItem(
                        "liftingMobileNumber",
                        inbound[0]?.customer_phone
                    );
                    setStoredIncomingCallContactData(inbound[0]?.contacts);

                    const response = await callHistoryTrigger(callHistoryObj);

                    localStorage.setItem("IncomingCallId", response?.data?.id);

                    if (response?.data?.id) {
                        const extensionObj = {
                            extension: gettingExtension,
                            state: "busy",
                        };
                        await extensionStateTrigger(extensionObj);

                    }

                    setIsCustomerHistoryModalOpen(true);
                } catch (error) {
                    console.error("Error fetching call history:", error);
                }
            }
        };

        fetchCallHistory();
    }, [inbound]);

    const closeCustomerHistoryModal = () => {
        setIsCustomerHistoryModalOpen(false);

    };

    useEffect(() => {
        if (gettingExtension && isCallPaused) {
            const timer = setInterval(() => {
                if (!inbound[0]?.id) {
                    inboundCallRefetch();
                } else {
                    clearInterval(timer);
                }
            }, 5000);

            return () => clearInterval(timer);
        }
    }, [gettingExtension, isCallPaused, inbound]);

    var interactionId =
        new Date().getFullYear().toString() +
        "" +
        "0" +
        (new Date().getMonth() + 1).toString().slice(-2) +
        "" +
        new Date().getDate().toString() +
        "" +
        new Date().getHours() +
        "" +
        new Date().getMinutes() +
        "" +
        new Date().getSeconds();

    useEffect(() => {
        if (pauseCodeData) {
            setGetPauseCode(pauseCodeData);
        }
    }, [pauseCodeData]);

    useEffect(() => {
        if (telephonyPri) {
            setTelephonyPriData(telephonyPri?.results);
        }
    }, [telephonyPri]);


    useEffect(() => {
        // Retrieve the saved number from localStorage
        const savedNumber = localStorage.getItem("liftingMobileNumber");
        if (savedNumber) {
            setLifitinMobileNumber(savedNumber);
        }
    }, []);

    const handleMobileNumberChange = () => {
        if (!isClosing) {
            setOpenDialpad(true);
        }
    };

    const closeModal = () => {
        setIsClosing(true); // Set the closing flag
        setOpenDialpad(false);

        // Allow time for the modal to fully close before resetting the flag
        setTimeout(() => {
            setIsClosing(false);
        }, 300);
    };

    // useEffect(() => {
    //     const callStatuss = localStorage.setItem(CALL, call.toString());
    // }, [call]);

    useEffect(() => {
        if (storingCustomerData?.length === 1) {
            navigate(`/account/info/${storingCustomerData[0]?.id}`, {
                state: gettingObjectData[0],
            });
        } else if (storingCustomerData?.length > 1) {
            navigate("/contact/customer/contact");
        }
    }, [storingCustomerData]);

    const gettingObjectData = storingCustomerData?.map((el: any) => ({
        id: el?.id,
        name: el?.name,
        email: el?.email,
        phone_number: el?.phone_number,
        account: el?.account,

    }));


    const callhandler = async () => {
        if (getingTelephonyLoginStatus !== "true") {
            toast.error("Please login to telephony");
            return;
        }

        if (callActive) {
            toast.warning("Call is already active");
            return;
        }

        if (!liftingMobileNumber) {
            toast.error("Mobile number is required")
            return

        }

        if (liftingMobileNumber.length != 10) {
            toast.error("Mobile number is incorrect");
            return;
        }


        if (!pri) {
            toast.error("Unable to place a call without a valid PRI");
            return;
        }


        if (isCallPaused) {
            toast.warning("Please resume the call")
            return
        }

        if (pauseCodeValue !== "Manual Dial") {
            toast.warning(
                `Please select manual dial to do an outbound call`
            );
            return;
        }

        if (!gettingCallResumed || !liftingMobileNumber) {
            toast.warning(
                !gettingCallResumed
                    ? "Resume the call"
                    : "Mobile number is required"
            );
            return;
        }

        try {

            const url = telephoneData?.https_port > 0
                ? `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/calls.php?CustPhone=91${liftingMobileNumber}&AgentExten=${gettingExtension}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&CallerID=${pri}&interactionid=${interactionId}`
                : `https://${telephoneData?.domain}/click2call/calls.php?CustPhone=91${liftingMobileNumber}&AgentExten=${gettingExtension}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&CallerID=${pri}&interactionid=${interactionId}`;

            const callResponse = await axios.post(url);

            if (!callResponse.data) {
                toast.error("Unable to place a call")
                return

            }

            // Step 4: Fetch customer contact information

            const gettingUserDataResponse = await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/?phone_number__endswith=${liftingMobileNumber}&`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );
            // setTelephonyContactLoading(false);

            if (gettingUserDataResponse) {
                setStoringCustomerData(gettingUserDataResponse?.data);
            }

            // Step 5: Update extension state

            // const extensionObj = {
            //     extension: gettingExtension,
            //     state: "busy",
            // };
            // await extensionStateTrigger(extensionObj);

            // Step 6: Create a call history entry
            const callHistoryObj = {
                call_type: "outbound",
                called_from_phone_no: liftingMobileNumber,
                campaign_name: pauseCodeValue,
                customer_phone_no: liftingMobileNumber,
                extension_no: gettingExtension,
                outbound_account_code: interactionId,
                user_id: +getUserId,
            };
            const callHistoryResponse = await callHistoryTrigger(
                callHistoryObj
            );


            if (callHistoryResponse?.error) {
                toast.error("Unable to place a call")
                setIsLoading(false)
                return

            }

            setCallHistoryResponsee(callHistoryResponse?.data);
            localStorage.setItem(
                CALL_HISTORY_ID,
                callHistoryResponse?.data?.id
            );

            const extensionObj = {
                extension: gettingExtension,
                state: "busy",
            };
            const extensionResponse = await extensionStateTrigger(extensionObj);


            if (extensionResponse?.error) {
                toast.error("Unable to place a call")
                setIsLoading(false)
                return

            }

            localStorage.setItem(OUTGOING_CALL, "true");
            closeModal();
            setCall(true);
            localStorage.setItem(CALL, "true");
            setCallDisconnected(false);
            setCallActive(true);
            setIsLoading(false);
            setOpenDialpad(false)
            toast.success(`Placing call to ${liftingMobileNumber}`);

        } catch (error) {
            setIsLoading(false);

            console.error("Error in callhandler:", error);
            toast.error(
                "An error occurred while making the call. Please try again."
            );
        }
    };

    const [codeLoading, setCodeLoading] = useState(false)

    // PAUSE HANDLER

    const pauseHandler = async () => {

        setPauseCodeValue("");

        if (localStorage.getItem(OUTGOING_CALL) === "true" || localStorage.getItem(INCOMING_CALL) === "true") {
            toast.warning("Cannot change on the live call")
            return
        }
        setCodeLoading(true)


        const unpauseBatch = async (batch) => {
            const batchPromise = batch?.map(async (queue) => {
                try {
                    const sanitizedQueue = queue.replace(/['"]+/g, "").trim().replace(/\s+/g, "");

                    const url = telephoneData?.https_port > 0 ?
                        `https://${telephoneData?.domain
                        }:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${+gettingExtension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=${pauseCodeValue}&break_id=${callBreakId}`
                        :
                        `https://${telephoneData?.domain
                        }/click2call/acd.php?AgentExten=${+gettingExtension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=${pauseCodeValue}&break_id=${callBreakId}`


                    const response = await axios.post(
                        url,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );

                    setUnPauseResponse(response?.data);
                    setGettingCallResumed(false);
                    setIsCallPaused(true);


                } catch (error) {
                    console.log(error);
                    setCodeLoading(false)
                }
            });

            // Wait for all APIs in this batch to complete
            await Promise.all(batchPromise);

            if (inbound) {
                const extensionObj = {
                    extension: +gettingExtension,
                    state: "unpause",
                };
                const response = await extensionStateTrigger(extensionObj);

                if (response?.data) {
                    const callBreakObj = {
                        action: "resume",
                    };
                    const callBreakResponse = await callBreakTrigger({
                        body: callBreakObj,
                        id: callBreakId,
                    });
                    localStorage.setItem(
                        CALL_BREAK_USER_ID,
                        callBreakResponse?.data?.user_id
                    );
                }
            }
        };
        await unpauseBatch(queueus);
        setCodeLoading(false)

        // await unpauseBatch(queueNamesData, "add");
    };

    // RESUME HANDLER

    const resumeHandler = async () => {

        if (!pauseCodeValue) {
            toast.error("Select break value");
            return;
        }

        setIsCallPaused(false);

        const pauseBatch = async (batch) => {
            const batchPromise = batch?.map(async (queue) => {
                try {
                    const sanitizedQueue = queue.replace(/['"]+/g, "").trim().replace(/\s+/g, "");
                    const url = telephoneData?.https_port > 0 ?
                        `https://${telephoneData?.domain
                        }:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${+gettingExtension}&Operation=pause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=${pauseCodeValue}&break_id=${callBreakId}`
                        :
                        `https://${telephoneData?.domain
                        }/click2call/acd.php?AgentExten=${+gettingExtension}&Operation=pause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&PauseCode=${pauseCodeValue}&break_id=${callBreakId}`


                    const response = await axios.post(
                        url,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    AUTH_TOKEN
                                )}`,
                            },
                        }
                    );

                    setUnPauseResponse(response?.data);
                } catch (error) {
                    console.log(error);
                }
            });

            await Promise.all(batchPromise);

            if (inbound) {
                const callBreakObj = {
                    break_remark: pauseCodeValue,
                    user_id: +getUserId,
                };
                const callBreakResponse = await callBreakWithoutIdTrigger(
                    callBreakObj
                );

                if (callBreakResponse?.data) {
                    localStorage.setItem(
                        CALLBREAKID,
                        callBreakResponse?.data?.id
                    );

                    if (pauseCodeValue === "Manual Dial") {
                        setIsPauseCodeSelectedManual(true)

                        toast.success(
                            "Status changed to Manual dial successfully"
                        );
                    }

                    const extensionObj = {
                        extension: gettingExtension,
                        state: "pause",
                    };
                    const response = await extensionStateTrigger(extensionObj);

                    if (response?.data) {
                        setGettingCallResumed(true);
                    }
                }
            }
        };
        await pauseBatch(queueus);
    };

    const pauseCodeChangeHandler = (e: any) => {
        setPauseCodeValue(e.target.value);
    };


    const priHandler = (e) => {
        setPri(e.target.value);
    };


    const conferenceNumberHandler = (e) => {
        const input = e.target.value;

        if (/^\d*$/.test(input) && input.length <= 10) {
            setConferenceNumber(input);
        }
    };


    const [selectedControl, setSelectedControl] = useState(null);

    const handleSelection = (control) => {
        setSelectedControl(control);
    };

    const isSelected = (control) => selectedControl === control;

    const [thirdPartyCallDisconnected, setThirdPartyCallDisconnected] =
        useState(false);
    const [checkTransferCallNumberEntered, setCheckTransferCallNumberEntered] =
        useState(false);
    const [checkConferenceCallNumberEntered, setCheckConferenceCallNumberEntered] = useState(false);

    const muiModalStyles = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2, // Optional for rounded corners
    };
    const isTelephonyLogin = localStorage.getItem('telephony_login') === 'true';
    const resetInactivityTimer = () => {
        setLastActivityTime(Date.now());
        setIsActive(true);
    };

    useEffect(() => {
        if (!isTelephonyLogin) {
            return; // Skip activity tracking if telephony_login is not true
        }

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastActivityTime > 3600000) { // 1 hour = 3600000ms
                if (isActive) {
                    telephonyLogoutHandler();
                    setIsActive(false);
                }
            }
        };

        const activityInterval = setInterval(checkInactivity, 60000); // Check every 1 minute

        return () => clearInterval(activityInterval);
    }, [lastActivityTime, isActive, isTelephonyLogin]);


    // Attach event listeners for mouse and keyboard activity
    useEffect(() => {
        const handleMouseClick = () => resetInactivityTimer();
        const handleKeyPress = () => resetInactivityTimer();

        document.addEventListener('click', handleMouseClick);
        document.addEventListener('keydown', handleKeyPress);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('click', handleMouseClick);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const telephonyLogoutHandler = async () => {
        if (call === "true") {
            toast.warning("You are on the call");
            return;
        }
        try {
            const url = localStorage.getItem(TELEPHONEURL);
            const telephoneData = JSON.parse(url || "{}");
            const extension = localStorage.getItem(EXTENSION);

            // Step 1: Unpause Batch
            const unpauseBatch = async (batch: any[]) => {
                const batchPromise = batch.map(async (queue) => {
                    const sanitizedQueue = queue.replace(/['"]+/g, "").trim().replace(/\s+/g, "");


                    try {
                        const url = telephoneData?.https_port > 0 ?
                            `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${extension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}/`

                            :

                            `https://${telephoneData?.domain}/click2call/acd.php?AgentExten=${extension}&Operation=unpause&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}/`

                        await axios.post(
                            url,
                            {
                                AgentExten: extension,
                                Operation: "unpause",
                                Queue: sanitizedQueue,
                                SecKey: "2c41c5e95e052e9d03f423e9f578b9b3",
                                AgentName: storingUserName,
                                Penalty: queue?.penalty,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                        AUTH_TOKEN
                                    )}`,
                                },
                            }
                        );
                    } catch (error) {
                        console.error(
                            `Error unpausing queue: ${queue?.name}`,
                            error
                        );
                    }
                });
                await Promise.all(batchPromise);
            };
            await unpauseBatch(queueus);

            // Step 2: Update Extension State
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/extension-states/`,
                { extension, state: "unpause" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Step 3: Fetch Inbound Call
            await axios.get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/telephony/inbound-call/?extension=${extension}&`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Step 4: Resume Call Break
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/call-breaks/${callBreakId}/`,
                { action: "resume" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            // Step 5: Remove Batch
            const removeBatch = async (batch: any[]) => {
                const batchPromise = batch.map(async (queue) => {
                    const sanitizedQueue = queue.replace(/['"]+/g, "").trim().replace(/\s+/g, "");


                    const url = telephoneData?.https_port > 0 ?
                        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/acd.php?AgentExten=${extension}&Operation=remove&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}/`

                        :
                        `https://${telephoneData?.domain}/click2call/acd.php?AgentExten=${extension}&Operation=remove&Queue=${sanitizedQueue}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3&AgentName=${storingUserName}&Penalty=${queue?.penalty}/`

                    try {
                        await axios.post(
                            url,
                            {
                                AgentExten: extension,
                                Operation: "remove",
                                Queue: sanitizedQueue,
                                SecKey: "2c41c5e95e052e9d03f423e9f578b9b3",
                                AgentName: storingUserName,
                                Penalty: queue?.penalty,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                        AUTH_TOKEN
                                    )}`,
                                },
                            }
                        );
                    } catch (error) {
                        console.error(
                            `Error removing queue: ${queue?.name}`,
                            error
                        );
                    }
                });
                await Promise.all(batchPromise);
            };
            await removeBatch(queueus);

            // Step 6: Logout
            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/telephony-login/${telephonyLoginId}/`,
                { action: "logout", logout_reason: "Session Inactive", inactive: "true" },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );


            // Clean up localStorage
            localStorage.removeItem(TELEPHONEURL);
            localStorage.removeItem(CALLBREAKID);
            localStorage.removeItem(EXTENSION);
            localStorage.removeItem(QUEUE);
            localStorage.setItem(TELEPHONY_LOGIN, "false");


            toast.success("Telephony logout successfully!");
            setIsLoading(false);
            window.location.reload()


        } catch (error) {
            console.error("Error during telephony logout process:", error);
            setIsLoading(false);

        } finally {
            // Stop loading spinner when all APIs are completed
            setIsLoading(false);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
            }}
        >
            {/* <button onClick={() => setIsCustomerHistoryModalOpen(true)}>sdcsdc</button> */}
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                        <TelephonyTimer
                            callPaused={isCallPaused}
                            call={call}
                            isCallPause={isCallPause}
                        />
                    </div>

                    <div style={{ display: "flex", gap: "10px" }}>

                        <>
                            {!isConferenceCallActive &&
                                !isTransferCallActive ? (
                                <input
                                    className="form-control"
                                    placeholder="Mobile No."
                                    onFocus={handleMobileNumberChange}
                                    onChange={handleMobileNumberChange}
                                    value={liftingMobileNumber}
                                    type="text"
                                    disabled={call}
                                />
                            ) : isConferenceCallActive ? (
                                <input
                                    className="form-control"
                                    placeholder="Please enter number."
                                    onChange={conferenceNumberHandler}
                                    value={conferenceNumber}
                                    type="text"
                                />
                            ) : null}
                        </>

                        {isTransferCallActive &&
                            !checkTransferCallNumberEntered && (
                                <input
                                    className="form-control"
                                    placeholder="Please enter number."
                                    onChange={conferenceNumberHandler}
                                    value={conferenceNumber}
                                    type="number"
                                />
                            )}

                        {!call && (
                            <select
                                className="form-select"
                                onChange={priHandler}
                                value={pri}
                                disabled={callActive}
                            >
                                {/* Show "Select PRI" only if there are multiple options */}
                                {telephonyPriData?.length > 1 && (
                                    <option value="">Select PRI</option>
                                )}

                                {telephonyPriData?.map((el) => (
                                    <option key={el?.phone_no} value={el?.phone_no}>
                                        {el?.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>


                    {openDialPad && (
                        <DialPad
                            open={openDialPad}
                            setLifitinMobileNumber={(number) => {
                                setLifitinMobileNumber(number);
                                localStorage.setItem(
                                    "liftingMobileNumber",
                                    number
                                );
                            }}
                            onClose={closeModal}
                            callhandler={callhandler}
                            liftingMobileNumber={liftingMobileNumber}
                        />
                    )}
                    {call ? (
                        <div style={{ display: "flex", gap: "10px" }}>
                            {!checkConferenceCallNumberEntered ? (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                        alignItems: "center",
                                        marginRight: "50px",
                                    }}
                                >
                                    <CallDisconnect
                                        setIsCallDisconnected={
                                            setIsCallDisconnected
                                        }
                                        checkingExtensionState={checkingExtensionState}
                                        setInbound={setInbound}

                                        isCallDisconnected={isCallDisconnected}
                                        setCall={setCall}
                                        call={call}
                                        setIsCallPaused={setIsCallPaused}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        setCheckConferenceCallNumberEntered={setCheckConferenceCallNumberEntered}
                                        pauseHandler={pauseHandler}

                                    // style={{
                                    //     transform: isSelected("disconnect")
                                    //         ? "scale(1.2)"
                                    //         : "scale(1)",
                                    //     transition: "transform 0.3s ease",
                                    //     cursor: "pointer",
                                    // }}
                                    />
                                    <PauseCall
                                        mobileNumber={liftingMobileNumber}
                                        setIsCallPause={setIsCallPause}
                                        isCallPause={isCallPause}
                                        onClick={() => handleSelection("pause")}
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <TransferCall
                                        setIsCallPaused={setIsCallPaused}

                                        setInbound={setInbound}
                                        setCall={setCall}
                                        call={call}
                                        setCheckTransferCallNumberEntered={
                                            setCheckTransferCallNumberEntered
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        setIsTransferCallActive={
                                            setIsConferenceCallActive
                                        }
                                        setCheckConferenceCallNumberEntered={setCheckConferenceCallNumberEntered}

                                        conferenceNumber={conferenceNumber}
                                        pauseHandler={pauseHandler}

                                        onClick={() =>
                                            handleSelection("transfer")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <Conference
                                        setIsCallPause={setIsCallPause}

                                        setCheckConferenceCallNumberEntered={
                                            setCheckConferenceCallNumberEntered
                                        }
                                        checkConferenceCallNumberEntered={
                                            checkConferenceCallNumberEntered
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        conferenceNumber={conferenceNumber}
                                        isConferenceCallActive={
                                            isConferenceCallActive
                                        }
                                        call={call}
                                        callExtensionRefetch={
                                            callExtensionRefetch
                                        }
                                        setConferenceCallApiCalled={
                                            setConferenceCallApiCalled
                                        }
                                        onClick={() =>
                                            handleSelection("conference")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        width: "20%",
                                    }}
                                >

                                    <PauseCall
                                        mobileNumber={liftingMobileNumber}
                                        setIsCallPause={setIsCallPause}
                                        isCallPause={isCallPause}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />

                                    <CallDisconnect
                                        setIsCallDisconnected={
                                            setIsCallDisconnected
                                        }
                                        checkingExtensionState={checkingExtensionState}

                                        setInbound={setInbound}

                                        isCallDisconnected={isCallDisconnected}
                                        setCall={setCall}
                                        call={call}
                                        setIsCallPaused={setIsCallPaused}
                                        pauseHandler={pauseHandler}

                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        setCheckConferenceCallNumberEntered={setCheckConferenceCallNumberEntered}
                                    // style={{
                                    //     transform: isSelected("disconnect")
                                    //         ? "scale(1.2)"
                                    //         : "scale(1)",
                                    //     transition: "transform 0.3s ease",
                                    //     cursor: "pointer",
                                    // }}
                                    />

                                    <ThirdPartCallDisconnect
                                        setCheckConferenceCallNumberEntered={
                                            setCheckConferenceCallNumberEntered
                                        }
                                        setThirdPartyCallDisconnected={
                                            setThirdPartyCallDisconnected
                                        }
                                        setIsConferenceCallActive={
                                            setIsConferenceCallActive
                                        }
                                        conferenceNumber={conferenceNumber}
                                        onClick={() =>
                                            handleSelection("disconnect")
                                        }
                                        style={{
                                            transform: isSelected("disconnect")
                                                ? "scale(1.2)"
                                                : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <i
                                    className="bi bi-telephone"
                                    style={{ fontSize: "20px" }}
                                    onClick={callhandler}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Call"
                                ></i>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    {getingTelephonyLoginStatus === "true" ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                // width: !isCallPaused ? "30%" : "40%",
                                // justifyContent: "end",
                                // marginLeft: "20%",
                                // marginRight: "70px",
                                // width: "30%",
                            }}
                        >
                            <div>
                                {!isCallPaused ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "7px",
                                            border: "1px solid #e4e6ef",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <span style={{ fontSize: "15px" }}>
                                            On pause
                                        </span>
                                        <span>
                                            <i
                                                className="bi bi-pause-circle"
                                                style={{ fontSize: "20px" }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Resume"
                                                onClick={pauseHandler}
                                            // onClick={() =>

                                            //     setIsCallPaused(true)
                                            // }
                                            ></i>
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        onClick={resumeHandler}

                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                            border: "1px solid #e4e6ef",
                                            borderRadius: "5px",
                                            padding: "10px 15px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <span style={{ fontSize: "15px" }}>
                                            Resume
                                        </span>
                                        <span>
                                            <i
                                                className="bi bi-play-circle-fill"
                                                style={{ fontSize: "20px" }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Pause"

                                            ></i>
                                        </span>
                                    </div>
                                )}
                            </div>
                            {pauseCodeLoad ? (
                                <div className="d-flex align-items-center">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="ms-2">Loading...</span>
                                </div>
                            ) : isCallPaused ? (
                                <select
                                    style={{ width: "auto", maxWidth: "200px" }}
                                    className="form-select"
                                    onChange={pauseCodeChangeHandler}
                                    value={pauseCodeValue}
                                >
                                    <option value="">Select Pause Code</option>
                                    {/* <option>Manual Dial</option>
        <option>Not Ready</option> */}
                                    {getpauseCode?.map((code) => (
                                        <option value={code?.name} key={code?.id}>
                                            {code?.name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <div>
                                    {codeLoading ? <Loader /> : <button
                                        className="btn btn-primary"
                                        style={{
                                            padding: "7px 10px",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {pauseCodeValue}
                                    </button>}

                                </div>
                            )}

                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {/* <CustomerInfo inboundData={"sdcsd"} closeModal={closeModal} /> */}

            {/* <ReactModal
                isOpen={isCustomerHistoryModalOpen}
                onRequestClose={closeCustomerHistoryModal}
                style={customModalStyles}
            >
                <CustomerInfo
                    inboundData={inbound}
                    closeModal={closeCustomerHistoryModal}
                />
            </ReactModal> */}

            <Modal
                open={isCustomerHistoryModalOpen}
                onClose={closeCustomerHistoryModal}
                disableEnforceFocus
                disableAutoFocus
            >
                <Box sx={muiModalStyles}>
                    <CustomerInfo
                        inboundData={inbound}
                        closeModal={closeCustomerHistoryModal}
                    />
                </Box>
            </Modal>
            {/* <div>
            <span
              onClick={toggleTelephony}
              style={{padding: isTelephonyVisible ? "4px" : "0 4px"}}
              // className="telephony-toggle-btn btn btn-outline-primary"
            >
              {isTelephonyVisible ? (
                <>
                   <i className="bi bi-chevron-up"></i>
                </>
              ) : (
                <>
                   <i className="bi bi-chevron-down"></i>
                </>
              )}
            </span>
            </div> */}
            {/* <CustomerInfo
                    inboundData={inbound}
                    closeModal={closeCustomerHistoryModal}
                /> */}
        </div>
    );
};

export default TelephonyFrontend;
