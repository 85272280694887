import React, { useEffect, useState } from "react";
import {
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllTeamsQuery,
  useGetIssueTypeWiseTATReportQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const IssueTypeWiseTatReport = () => {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [params, setParams] = useState<any>(null);
  const [selectedTeams, setSelectedTeams] = useState<any>([]);
  const [selectedTeamsParam, setSelectedTeamsParam] = useState<string[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [localLoading, setLocalLoading] = useState(false);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const { data: teamsData, isLoading: teamsLoading } = useGetAllTeamsQuery({});
  const [loading, setLoading] = useState(false);



  const handleDownloadClick = () => {
    setLoading(true);
    
    // Simulate download completion (due to CsvDownloader's lack of a completion callback)
    setTimeout(() => {
      setLoading(false);
    }, 500); // Small delay to simulate user interaction feedback
  };

  const {
    data: issueTypeWiseTatReport,
    isLoading: issueTypeWiseTatReportLoading,
  } = useGetIssueTypeWiseTATReportQuery(params, {
    skip: !params,
  });
  const [theTeams, setTheTeams] = useState<any>([]);
  useEffect(() => {
    // Debugging teamsData
    const teams = teamsData?.teams;
    if (Array.isArray(teams)) {
      setTheTeams(teams); // Only set if it's an array
    } else {
      setTheTeams([]); // Fallback to an empty array if it's not an array
    }
  }, [teamsData]);
  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  const columns: GridColDef[] = [
    {
      field: "issue_type",
      headerName: "Issue Type",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_tickets",
      headerName: "Total Tickets",
      flex: 1,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed_tickets",
      headerName: "Total Closed",
      flex: 1,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "active_tickets",
      headerName: "Total Active",
      flex: 1,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pending_tickets",
      headerName: "Total Pending",
      flex: 1,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed_percent",
      headerName: "Closed%",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pending_percent",
      headerName: "Pending%",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed_within_sla",
      headerName: "Closed Within SL",
      flex: 1,
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed_with_sla_breach",
      headerName: "Closed SL Breach",
      flex: 1,
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pending_within_sla",
      headerName: "Pending Within SL",
      flex: 1,
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pending_with_sla_breach",
      headerName: "Pending SL Breach",
      flex: 1,
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed_within_sla_percent",
      headerName: "Closed Within SL(%)",
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closed_with_sla_breach_percent",
      headerName: "Closed SL Breach(%)",
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pending_within_sla_percent",
      headerName: "Pending Within SL(%)",
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pending_with_sla_breach_percent",
      headerName: "Pending SL Breach(%)",
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
    },
  ];
  

  useEffect(() => {
    if (Array.isArray(issueTypeWiseTatReport)) {
      // Check if it's an array
      const transformedRows = issueTypeWiseTatReport.map(
        (item: any, index: number) => ({
          ...item,
          id: index + 1,
          closed_percent: `${item.closed_percent}%`,
          pending_percent: `${item.pending_percent}%`,
          closed_within_sla_percent: `${item.closed_within_sla_percent}%`,
          closed_with_sla_breach_percent: `${item.closed_with_sla_breach_percent}%`,
          pending_within_sla_percent: `${item.pending_within_sla_percent}%`,
          pending_with_sla_breach_percent: `${item.pending_with_sla_breach_percent}%`,
        })
      );

      setDataRows(transformedRows);
    } else {
      setDataRows([]); // Set to an empty array if not an array
    }

    setLocalLoading(false);
  }, [issueTypeWiseTatReport]);

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };
  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (!selectedTeamsParam.length) {
      toast.error('Please select "Team"');
      return;
    }

    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };

    if (selectedTeamsParam.length > 1) {
      queryParams.team_ids = selectedTeamsParam.join(","); // Convert array to comma-separated string
    } else if (selectedTeamsParam.length <= 1) {
      queryParams.team_id = selectedTeamsParam;
    }

    setParams(queryParams);
  };

  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);
    setSelectedTeams([]);
    setDataRows([]);
  };

  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  const handleTeamsChange = (event: any) => {
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      setSelectedTeams(
        selectedTeams.length === theTeams.length
          ? []
          : theTeams.map((team) => team.id)
      );
    } else {
      // Convert the value to an array of strings
      setSelectedTeams(typeof value === "string" ? value.split(",") : value);
    }
  };

  useEffect(() => {
    setSelectedTeamsParam(selectedTeams);
  }, [selectedTeams]);

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };
  const isAllSelected = selectedTeams.length === theTeams.length;
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/ticket/reports-list");
            }}
          /> */}
           <button
              className="btn btn-link mb-2"
              onClick={() => {
                navigate("/reports/ticket/reports-list");
              }}
              style={{marginRight:"-17px"}}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            Category Analytics
          </span>
        </div>

        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Select Teams
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedTeams}
                onChange={handleTeamsChange}
                input={<OutlinedInput label="Select Teams" />}
                renderValue={(selected) => {
                  if (isAllSelected) {
                    return <div>All</div>;
                  }
                  return (
                    <div>
                      {selected
                        .map((teamId) => {
                          const team = theTeams.find((t) => t.id === teamId);
                          return team ? team.name : null;
                        })
                        .join(", ")}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  onClick={() =>
                    setSelectedTeams(
                      isAllSelected ? [] : theTeams.map((team) => team.id)
                    )
                  }
                >
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={selectedTeams?.length > 0 && !isAllSelected}
                  />
                  <ListItemText primary="All" />
                </MenuItem>
                {theTeams.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
                    <ListItemText primary={team.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>

            <button className="btn btn-primary" onClick={handleDownloadClick} disabled={loading}>
              {loading ? 'Loading...' : (
                <CsvDownloader
                  filename="Category Analytics"
                  datas={dataRows}
                  columns={columns.map((col) => ({
                    id: col.field,
                    displayName: col.headerName,
                  }))}
                  separator=","
                  wrapColumnChar={`"`}
                >
                  Download
                </CsvDownloader>
              )}
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        <CardContent style={{ width: "100%" }}>
          {teamsLoading || issueTypeWiseTatReportLoading || localLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                  whiteSpace: "nowrap", // Prevent wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  whiteSpace: "nowrap", // Prevent wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                }
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default IssueTypeWiseTatReport;
