import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  useAllThreadsStarredQuery,
  useUpdateStarredMutation,
} from "../../../Services/email/EmailInbox";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ReactModal from "react-modal";
import AssigneeBulkTeam from "../../../components/email/inboxEmailModal/AssigneeBulkTeam";
import { toast } from "react-toastify";
import StarredFilterModal from "../../../components/email/StarredFilterModal";

const customFilterModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxWidth: "50px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const Starred = () => {
  const sessionKeyPrefix = "starredInitial_";
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [allThreads, setAllThreads] = useState<any>();
  const [countAllThreads, setCountAllThreads] = useState<any>(0);
  const parseSessionValue = (value: string | null) => {
    if (!isNaN(Number(value))) {
      return Number(value);
    }
    return value;
  };
  // Initialize state with values from sessionStorage or default values, ensuring numbers are parsed correctly
  const [perPage, setPerPage] = useState<number>(
    parseInt(sessionStorage.getItem(`${sessionKeyPrefix}perPage`) || "5", 10)
  );
  const [selectedTeamId, setSelectedTeamId] = useState<any>(() => {
    const selectedTeamIdFromSession = sessionStorage.getItem(`${sessionKeyPrefix}selectedTeamId`);
    const userRole = localStorage.getItem("user_role");
    const userId = localStorage.getItem("User_Id");

    // If there's a selected team ID in sessionStorage, use it
    if (selectedTeamIdFromSession) {
      return parseSessionValue(selectedTeamIdFromSession);
    }

    // If the user role is admin, set to "My Team"
    if (userRole === "admin") {
      return "My Team";
    }

    // If the user role is agent or manager, set to the user ID from localStorage
    if (userRole === "agent" || userRole === "manager") {
      return userId;
    }

    // Fallback value if no conditions match
    return null;
  });

  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(
      sessionStorage.getItem(`${sessionKeyPrefix}currentPage`) || "1",
      10
    )
  );
  const [selectStaredFilterAndValue, setSelectStaredFilterAndValue] =
    useState<any>({
      action__contact__email__icontains: "",
      action__assignee__username__icontains: "",
      original_subject__icontains: "",
      created: "",
      action__priority__icontains: "",
    });

  const [selectStaredFilterAndValue1, setSelectStaredFilterAndValue1] =
    useState<any>(() => {
      const sessionData = sessionStorage.getItem("selectStaredFilterAndValue");
      return sessionData
        ? JSON.parse(sessionData)
        : {
          action__contact__email__icontains: "",
          action__assignee__username__icontains: "",
          original_subject__icontains: "",
          created: "",
          action__priority__icontains: "",
        };
    });
  const status: any = "closed";
  const {
    data: allThreadsData,
    isLoading,
    refetch: refetchAllThreads,
  } = useAllThreadsStarredQuery({
    currentPage,
    perPage,
    selectedTeamId,
    selectStaredFilterAndValue1,
    status,
  });
  const [trigger] = useUpdateStarredMutation();
  const [isLoadingPageChange, setIsLoadingPageChange] =
    useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    if (refetch) {
      refetchAllThreads();
      setRefetch(false);
    }
  }, [refetch, refetchAllThreads]);

  useEffect(() => {
    setRefetch(true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem(`${sessionKeyPrefix}selectedTeamId`, selectedTeamId);
    sessionStorage.setItem(`${sessionKeyPrefix}currentPage`, "1");
    refetchAllThreads();
  }, [selectedTeamId]);

  useEffect(() => {
    sessionStorage.setItem(`${sessionKeyPrefix}perPage`, perPage.toString());
    sessionStorage.setItem(
      `${sessionKeyPrefix}currentPage`,
      currentPage.toString()
    );
    refetchAllThreads();
  }, [perPage, currentPage]);

  useEffect(() => {
    setIsLoadingPageChange(true);
    if (allThreadsData?.results) {
      setAllThreads(allThreadsData?.results);
      setCountAllThreads(allThreadsData?.count);
      setIsLoadingPageChange(false);
    } else {
      setIsLoadingPageChange(false);
    }
  }, [allThreadsData]);

  const handleHeaderCheckboxChange = () => {
    setSelectAll(!selectAll);
    const allRows = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    const selectedIds = Array.from(allRows)
      .filter((row) => row.value !== "")
      .map((row) => row.value);
    setSelectedRows(!selectAll ? selectedIds : []);
  };

  const handleRowCheckboxChange = (e) => {
    const selectedId = e.target.value;
    if (e.target.checked) {
      setSelectedRows((prevState) => [...prevState, selectedId]);
    } else {
      setSelectedRows((prevState) =>
        prevState.filter((id) => id !== selectedId)
      );
    }
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name?.split(" ");
    return nameParts?.map((part) => part[0]?.toUpperCase()).join("");
  };

  const convertHtmlIntoText = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const textContent = doc.body.textContent || "";
    return truncateText(textContent, 100); // Truncate to 100 characters for example
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const mailSentOrReceivedDate = (mailDate) => {
    const date = new Date(mailDate);
    const options: any = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleChange = (event) => {
    setPerPage(event.target.value);
    setIsLoadingPageChange(true);
  };

  const emailPerPage = perPage;
  // Pagination
  const totalLeads = countAllThreads;
  const totalPages = Math.ceil(totalLeads / emailPerPage);

  const indexOfLastLead = currentPage * emailPerPage;
  const indexOfFirstLead = indexOfLastLead - emailPerPage;

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setIsLoadingPageChange(true);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setIsLoadingPageChange(true);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setIsLoadingPageChange(true);
    }
  };

  // Generate snake handle pagination numbers
  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5; // Maximum visible pagination numbers

    if (totalPages <= maxVisibleNumbers) {
      // If total pages are less than or equal to max visible numbers, show all
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const middleIndex = Math.ceil(maxVisibleNumbers / 2); // Middle index of the pagination numbers
      let startPage = currentPage - middleIndex + 1;
      let endPage = currentPage + middleIndex - 1;

      if (startPage < 1) {
        // If start page is less than 1, adjust start and end page accordingly
        endPage = startPage + maxVisibleNumbers - 1;
        startPage = 1;
      } else if (endPage > totalPages) {
        // If end page is greater than total pages, adjust start and end page accordingly
        startPage = endPage - maxVisibleNumbers + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const handleTeamChange = (e) => {
    setSelectedTeamId(e.target.value);
    setCurrentPage(1);
    setIsLoadingPageChange(true);
  };
  const handleRefreshClick = () => {
    window.location.reload();
  };
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      height: "400px",
    },
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const afterOpenModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const assigneBulkEmailHandler = () => {
    if (selectedRows.length === 0) {
      return toast.error("Please select email.");
    } else {
      openModal();
    }
  };
  function capitalizeFirstLetter(name) {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const handleStarClick = async (id: any, isStarred: any) => {
    setIsLoadingPageChange(true);
    const requestBody = {
      is_starred: !isStarred,
    };
    try {
      const response = await trigger({ body: requestBody, id: id });

      if (response?.data) {
        refetchAllThreads();
      } else if (response?.error) {
        setIsLoadingPageChange(false);

        toast.error("Unable to update");
      }
    } catch (error) {
      toast.error("An error occurred");
      setIsLoadingPageChange(false);
    }
  };

  const handleSubmit = (filterValues) => {
    setIsLoadingPageChange(true);
    setSelectStaredFilterAndValue1(filterValues);
    setIsLoadingPageChange(false);
  };

  return (
    <div id="kt_app_content_container" className="app-container">
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          <div className="card  card-flush">
            <div className="card-header align-items-center py-1 gap-2 gap-md-5">
              <span className="fs-3 text-gray-800 fw-bold">Starred</span>
            </div>
            <hr className="seperator mt-0"></hr>

            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
              {/* begin::Actions */}
              <div className="d-flex flex-wrap gap-2">
                {/* begin::Checkbox */}
                <div
                  className="form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7"
                  title="Select All"
                >
                  <input
                    className="form-check"
                    type="checkbox"
                    onChange={handleHeaderCheckboxChange}
                    checked={selectAll}
                  />
                </div>
                {/* end::Checkbox */}
                {/* begin::Reload */}
                <a
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="top"
                  aria-label="Refresh"
                  data-bs-original-title="Refresh"
                  data-kt-initialized={1}
                  onClick={handleRefreshClick}
                  title="Refresh"
                >
                  <i className="bi bi-arrow-clockwise fs-2" />
                </a>
                {/* end::Reload */}
                {/* begin::Archive */}
                <a
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="top"
                  aria-label="Assignee"
                  data-bs-original-title="Assignee"
                  data-kt-initialized={1}
                  title="Assignee"
                  onClick={assigneBulkEmailHandler}
                >
                  <i className="bi bi-person-fill fs-2" />
                </a>

                {/* end::Delete */}
              </div>

              {/* end::Actions */}
              {/* begin::Actions */}
              <div className="d-flex" style={{ height: "40px" }}>
                {/* begin::Search */}
                <div className="w-200 mw-200px" style={{ marginRight: "20px" }}>
                  <FormControl style={{ width: "150px" }}>
                    <InputLabel id="team-select-label">Select assignee</InputLabel>
                    <Select
                      labelId="team-select-label"
                      id="team-select"
                      value={selectedTeamId}
                      onChange={handleTeamChange}
                      label="Select Teams"
                    >
                      <MenuItem key="all" value="My Team">
                        My Team
                      </MenuItem>
                      <MenuItem
                        key="all"
                        value={localStorage.getItem("User_Id")}
                      >
                        Self
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className="w-100 mw-150px"
                  style={{ marginRight: "20px", marginLeft: "8px" }}
                >
                  <Button
                    variant="outlined"
                    className="text-hover-primary fw-bold"
                    onClick={openFilterModal}
                    sx={{
                      height: 50,
                      width: 140,
                      border: "1px solid #ccc",
                      gap: "5px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <i className="bi bi-filter text-dark fs-2" />
                    <span
                      className="ms-auto me-2 text-dark"
                      style={{
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                      }}
                    >
                      Search filter
                    </span>
                  </Button>
                </div>

                <ReactModal
                  isOpen={isFilterModalOpen}
                  onRequestClose={closeFilterModal}
                  style={customFilterModalStyles}
                  contentLabel="Starred Filter Modal"
                >
                  <StarredFilterModal
                    closeFilterModal={closeFilterModal}
                    selectStaredFilterAndValue={selectStaredFilterAndValue}
                    setSelectStaredFilterAndValue={setSelectStaredFilterAndValue}
                    handleSubmit={handleSubmit}
                    setIsLoadingPageChange={setIsLoadingPageChange}
                  />
                </ReactModal>

                {/* end::Search */}
              </div>
              {/* end::Actions */}
            </div>

            <div className="card-body p-0 ">
              {/* begin::Table */}
              <div
                id="kt_inbox_listing_wrapper"
                className="dt-container dt-bootstrap5 dt-empty-footer"
              >
                <div className="table-responsive">
                  <table
                    className="table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable"
                    id="kt_inbox_listing"
                  >
                    <tbody>
                      {isLoading || isLoadingPageChange ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          {allThreads?.map((details) => (
                            <tr>
                              <td className="ps-9">
                                <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                  <input
                                    className="form-check"
                                    type="checkbox"
                                    value={details?.id}
                                    onChange={handleRowCheckboxChange}
                                    checked={selectedRows.includes(
                                      String(details?.id)
                                    )}
                                  />
                                </div>
                              </td>
                              <td className="min-w-80px">
                                <a
                                  className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  aria-label="Star"
                                  data-bs-original-title="Star"
                                  data-kt-initialized={1}
                                  onClick={(e) =>
                                    handleStarClick(
                                      details?.id,
                                      details?.is_starred
                                    )
                                  }
                                >
                                  <i
                                    className={`bi bi-star${details?.is_starred ? "-fill" : ""
                                      } fs-3`}
                                    style={{
                                      color: details?.is_starred
                                        ? "#FFD700"
                                        : "",
                                    }}
                                  />
                                </a>
                              </td>
                              <td className="w-150px w-md-200px">
                                <Link
                                  to={`/email/inbox/${details?.id}`}
                                  className="d-flex align-items-center text-gray-900"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-35px me-3">
                                      <span className="symbol-label bg-light-dark text-primary fs-6 fw-bolder">
                                        {getInitials(
                                          details?.action?.contact?.name
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                      <span className="fw-semibold">
                                        {capitalizeFirstLetter(
                                          details?.action?.contact?.name
                                        )}
                                      </span>
                                      <span className="text-primary">
                                        {details?.action?.ticket
                                          ? details?.action?.ticket?.id
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </td>

                              <td>
                                <div
                                  className="text-gray-900 gap-1 pt-2"
                                  style={{ width: "600px" }}
                                >
                                  <Link
                                    to={`/email/inbox/${details?.id}`}
                                    className="text-gray-900"
                                  >
                                    <span className="fw-bold">
                                      {details?.subject}
                                    </span>
                                    <span className="fw-bold d-none d-md-inline">
                                      {" "}
                                      -{" "}
                                    </span>
                                    <span className="d-none d-md-inline text-muted">
                                      {convertHtmlIntoText(
                                        details?.action?.thread?.emails[
                                          details?.action?.thread?.emails
                                            ?.length - 1
                                        ]?.html_content
                                      )}
                                    </span>
                                  </Link>
                                </div>
                              </td>
                              <td className="w-200px ">
                                <span className="fw-semibold">
                                  {" "}
                                  {details?.action?.assignee?.username
                                    ? capitalizeFirstLetter(
                                      details?.action?.assignee?.username
                                    )
                                    : "Unassigned"}
                                </span>
                              </td>
                              <td className="w-200x  text-end fs-7 pe-3">
                                <span className="fw-semibold">
                                  {" "}
                                  {mailSentOrReceivedDate(
                                    details?.action?.thread?.emails[
                                      details?.action?.thread?.emails?.length -
                                      1
                                    ]?.mail_sent_or_received_on
                                  )}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* end::Table */}

              {/* Pagination */}
              <div className="row mt-4 p-3">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div className="d-flex items-center gap-2 order-2 m-5">
                    <div>Show</div>
                    <select
                      className="select select-sm w-40"
                      data-datatable-size="true"
                      name="perpage"
                      value={perPage}
                      onChange={handleChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                    </select>
                    <div>per page</div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_table_users_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                          }`}
                      >
                        <button
                          type="button"
                          aria-controls="kt_table_users"
                          data-dt-idx="0"
                          className="btn primary page-link"
                          onClick={prevPage}
                        >
                          <i className="previous"></i>
                        </button>
                      </li>
                      {generatePaginationNumbers().map((pageNumber) => (
                        <li
                          key={pageNumber}
                          className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                            }`}
                        >
                          <button
                            type="button"
                            aria-controls="kt_table_users"
                            data-dt-idx={pageNumber}
                            className="btn primary page-link"
                            onClick={() => paginate(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                          }`}
                      >
                        <button
                          type="button"
                          aria-controls="kt_table_users"
                          data-dt-idx="0"
                          className="btn primary page-link"
                          onClick={nextPage}
                        >
                          <i className="next"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Email templates"
      >
        <AssigneeBulkTeam
          closeModal={closeModal}
          setectedEmail={selectedRows}
          refetchData={() => setRefetch(true)}
          setSelectedRows={setSelectedRows}
        />
      </ReactModal>
    </div>
  );
};
export default Starred;
