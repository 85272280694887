import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EmptyRows from "../../../../components/reusableComponent/EmptyRows";
import { useNavigate } from "react-router-dom";

interface IProps {
  data: any; // Define a more specific type if possible
  outwardLeadButton: any
}

const LeadsOverview: React.FC<IProps> = ({ data, outwardLeadButton }) => {
  const [chartData, setChartData] = useState<any>({
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        offsetX: -3,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const selectedChannelIndex = config.seriesIndex;
            const selectedDateIndex = config.dataPointIndex;

            if (selectedDateIndex !== undefined && selectedDateIndex !== null) {
              openModal(selectedChannelIndex, selectedDateIndex);
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      xaxis: {
        categories: [],
        labels: {
          rotate: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return Math.floor(val); // Ensure that only whole numbers are shown
          },
        },
        min: 0, // Ensure minimum is
        forceNiceScale: true,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#25D366", "#1877F2", "#FF9900", "#FF4500", "#800080", "#808080"],
    },
    series: [],
  });
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{ channel: string, date: string, leads: number, allLeadsList: any }>({
    channel: '',
    date: '',
    leads: 0,
    allLeadsList: []
  });

  const openModal = (channelIndex: number, dateIndex: string) => {
    const selectedChannel = data?.lead_stats.channel_leads[channelIndex].channel_name;
    const selectedChannelDate = data.lead_stats.channel_leads[channelIndex]?.leads_by_date;
    const formatDate = (selectedDate) => {
      if (!selectedDate) return "";

      const [year, month, day] = selectedDate.split("-");
      return `${day}-${month}-${year}`;
    };

    if (selectedChannelDate) {
      const dateKeys = Object.keys(selectedChannelDate); // Get all date keys
      const selectedDate = dateKeys[dateIndex]; // Get the specific date based on dateIndex
      console.log(selectedDate, "selectedDate")
      if (selectedDate) {
        console.log(selectedChannelDate[selectedDate], "selectedChannelDate")
        const countValue = selectedChannelDate[selectedDate]?.count ?? 0; // Get count, default to 0 if undefined
        const allLeadsList = selectedChannelDate[selectedDate]?.lead_details
          ?? [];
        setModalData({
          channel: selectedChannel,
          date: formatDate(selectedDate),
          leads: countValue,
          allLeadsList: allLeadsList
        });
        setModalOpen(true);
      } else {

      }
    }



  };

  const closeModal = () => {
    setModalOpen(false);
  };

  console.log(outwardLeadButton)
  useEffect(() => {
    if (data?.lead_stats?.channel_leads) {
      const channels = data.lead_stats.channel_leads;
  
      const categories = Object.keys(channels[0]?.leads_by_date || {}).map(date => {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      });
  
      const filteredChannels = channels.filter(channel => {
        const channelNameLower = channel.channel_name.toLowerCase();
        if (!outwardLeadButton) {
          return channelNameLower === "whatsapp";
        } else {
          return channelNameLower === "whatsapp" || channelNameLower === "manual";
        }
      });
  
      const series = filteredChannels.map((channel) => ({
        name: channel.channel_name?.charAt(0)?.toUpperCase() + channel?.channel_name?.slice(1),
        data: Object.values(channel.leads_by_date).map((entry: any) => entry.count),
      }));
  
      setChartData((prevData) => ({
        ...prevData,
        options: {
          ...prevData.options,
          xaxis: { ...prevData.options.xaxis, categories },
        },
        series,
      }));
    }
  }, [data, outwardLeadButton]);
  


  const leadDashboard = "LeadDashboard";
  const noDataPresent = !data?.lead_stats?.channel_leads || data.lead_stats.channel_leads.length === 0;
  return (
    <div className="card">
      <div className="card-body pb-0">
        <h5 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            {!outwardLeadButton ? 'Whatsapp leads' : 'Leads by channel'}

          </span>
        </h5>
        {noDataPresent ? (
          <p className="text-center" style={{ height: "450px" }}>
            <EmptyRows value={leadDashboard} />
          </p>
        ) : (
          <div className="app">
            <div className="row">
              <div className="mixed-chart">
                <Chart options={chartData.options} series={chartData.series} type="bar" height="450px" />
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // This will center the modal both horizontally and vertically
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2, // Optional: Adds rounded corners to the modal
            width: '40vw', // Optional: Controls the modal's width, can be set to a specific value like '400px'
            maxHeight: '80vh', // Optional: Ensures the modal content is scrollable if content overflows
            overflowY: 'auto', // Enables scrolling if content overflows vertically

          }}
        >
          {/* Close Icon in Top-Right Corner */}
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'gray', // You can adjust this color as needed
            }}
          >
            <CloseIcon />
          </IconButton>

          <h2 id="modal-modal-title">{`Leads for ${modalData.channel} on ${modalData.date}`}</h2>
          <p id="modal-modal-description">
            Total leads: {modalData.leads}
          </p>

          {/* Table for account details */}
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Lead id</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>First name</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Email</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Phone number</th>
              </tr>
            </thead>
            <tbody>
              {modalData?.allLeadsList?.map((lead, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/campaign/lead/${lead.lead_id}`)}
                >
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.lead_id}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.first_name}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.email}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.phone_number}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Modal>

    </div>
  );
};

export default LeadsOverview;
