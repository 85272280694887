import React from 'react'
// import { PageTitle } from '../../layout/core'
import AllChats from './AllChats'

const ConversationsPage: React.FC<any> = () => {
  return (
    <>
      <AllChats />
    </>
  )
}

export default ConversationsPage
