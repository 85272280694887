import React, { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  Tooltip,
  Grid,
  Typography,
  CardContent,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import { format } from "date-fns";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../components/app/Fixes.css";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import { Link, useNavigate } from "react-router-dom";
import { displayName } from "react-quill";

interface Ticket {
  id: number;
  title: string;
  created: string;
  status_type: string;
  description: string;
  assignee: string;
  raisedBy: string;
  priority: string;
  category: string;
  dueDate: string;
}

interface IProps {
  ticket: { results: Ticket[]; count: number };
  currentPage: number;
  setCurrentPage: any;
  selectedTickets: any;
  setSelectedTickets: any;
  ticketWorkflow: any;
  value: any;
  setValue: any;
  setStoreKey: any;
  setLiftUiData: any;
  refetch: any;
  storingTicketData: any;
  useData: any;
}

const TicketCardView: React.FC<IProps> = ({
  ticket,
  currentPage,
  setCurrentPage,
  selectedTickets,
  setSelectedTickets,
  ticketWorkflow,
  value,
  setValue,
  setStoreKey,
  setLiftUiData,
  refetch,
  storingTicketData,
  useData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize] = useState<number>(5);
  const [selectAll, setSelectAll] = useState(false);
  const [uiData, setUiData] = useState([]);
  const [keyState, setKeyState] = useState([]);
  const [ticketTabLoading, setTicketTabLoading] = useState<boolean>(false);
  const ticketCount = ticket?.count ? ticket?.count : 0;

  const navigate = useNavigate();

  const rows =
    ticket?.results?.map((ticket: any) => ({
      id: ticket?.id,
      title: ticket?.title,
      created: format(new Date(ticket?.created), "yyyy-MM-dd HH:mm"),
      status_type: ticket?.status_type,
      displayName: ticket?.status_display,
      description: ticket?.description,
      assignee: ticket?.action?.assignee?.username,
      raisedBy: ticket?.created_by?.username,
      priority: ticket?.priority,
      category: ticket?.action?.disposition?.workflow?.name,
      dueDate: ticket?.dueDate,
    })) || [];

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const newSelectedTickets = event.target.checked
      ? [...selectedTickets, id]
      : selectedTickets.filter((ticketId) => ticketId !== id);

    setSelectedTickets(newSelectedTickets);
    if (!event.target.checked) {
      setSelectAll(false);
    }
  };

  const totalPages = Math.ceil(ticketCount / pageSize);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5;

    if (totalPages <= maxVisibleNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getStatusStyle = (status_type: string) => {
    switch (status_type) {
      case "active":
        return { backgroundColor: "#56d85b", color: "white" }; // $green-300
      case "closed":
        return { backgroundColor: "#90dcf9", color: "white" }; // $cyan-300
      case "reopen":
        return { backgroundColor: "#f44336", color: "white" }; // $red-200
      case "wip":
        return { backgroundColor: "#ffeb3b", color: "black" }; // $yellow-200
      default:
        return { backgroundColor: "#6c757d", color: "white" }; // Default color
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };

  const processData = async () => {
    const uiFormattedData = [];
    const keysForState = [];

    // Ensure ticketWorkflow is defined and not null before proceeding
    if (ticketWorkflow && typeof ticketWorkflow === "object") {
      await Object.entries(ticketWorkflow)?.forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Handle objects like { Open: 8 }
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            uiFormattedData.push({
              key: nestedKey,
              value: nestedValue,
            });
            keysForState.push(key);
          });
        } else if (Array.isArray(value)) {
          // Handle arrays of objects [{ Closed: 1 }, { ss: 0 }]
          value.forEach((item) => {
            if (item && typeof item === "object") {
              Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                uiFormattedData.push({
                  key: nestedKey,
                  value: nestedValue,
                });
                keysForState.push(key);
              });
            }
          });
        }
      });

      setUiData(uiFormattedData);
      setKeyState(keysForState);
    } else {
      console.error("ticketWorkflow is undefined or null");
    }
  };

  useEffect(() => {
    processData();
  }, [ticketWorkflow]);

  useEffect(() => {
    setSelectAll(rows.length > 0 && selectedTickets.length === rows.length);
  }, [selectedTickets, rows]);

  useEffect(() => {
    setLoading(true); // Set loading to true when component mounts or updates
    setTimeout(() => {
      setLoading(false); // Simulate loading completion after a delay (replace with actual data fetching)
    }, 3000);
  }, [currentPage]);

  useEffect(() => {
    setLiftUiData(uiData);
  }, [uiData]);

  useEffect(() => {
    setTicketTabLoading(true); // Set tab loading to true when the component mounts
    setTimeout(() => {
      setTicketTabLoading(false); // Simulate tab loading completion after a delay (replace with actual data fetching)
    }, 3000);
  }, [value]); // Add value as a dependency to re-run the effect when the tab changes
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedTickets(ticket?.results.map((row) => row.id));
    } else {
      setSelectedTickets([]);
    }
  };

  useEffect(() => {
    if (selectedTickets.length === ticket?.results.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedTickets]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy HH:mm:ss");
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5"
            style={{ padding: "0 2.25rem", display: "flex" ,width:"103%"}}
          >
            <Box
              sx={{
                width: "96%",
                background: "#FFFFFF",
                borderRadius: "6px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for better elevation
                marginLeft: "30px",
              }}
            >
              <Tabs
                value={uiData.findIndex((item) => item.key === value)}
                onChange={(event, newValue) => {
                  setStoreKey(keyState[newValue]);
                  setValue(uiData[newValue].key);
                  setCurrentPage(1);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                sx={{
                  "& .MuiTab-root": {
                    minWidth: 0, // Set minimum width to 0
                    flexGrow: 1, // Add flexGrow to make tabs responsive
                    padding: "12px 24px",
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "12px",
                  },
                  "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root":
                  {
                    display: "none",
                  },
                  "& .MuiTab-root:not(:last-child)": {
                    borderRight: "1px solid #e0e0e0", // Thicker, more pronounced divider
                  },
                  "& .MuiTab-root:hover": {
                    backgroundColor: "#f5f5f5", // Adds a hover effect for better interaction
                  },
                  "& .Mui-selected": {
                    fontWeight: 600, // Highlights selected tab more
                  },
                }}
              >
                {uiData?.map((ticketsInfo, index) => (
                  <Tooltip key={index} title={`${ticketsInfo.key} (${ticketsInfo.value})`} arrow>
                    <Tab
                      key={index}
                      label={truncateDescription(
                        `${ticketsInfo.key} (${ticketsInfo.value})`,
                        25
                      )}
                      sx={{ 
                        width: "20%", // Ensure all tabs take full width 
                       maxWidth: "none", // Remove max width restriction 
                       display: "flex", // Ensure flexibility 
                      justifyContent: "center", // Center the text
                       }}
                    />
                  </Tooltip>
                ))}
              </Tabs>
            </Box>
          </div>

          {ticketTabLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="tab-content d-flex" style={{ position: "relative", width: "100%", overflow: "scroll" }}>
              <div className="row g-9">

                <div className="col-md-12">

                  <div
                    // className="mb-9 d-flex flex-wrap justify-content-between"
                    style={{ marginLeft: "40px", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", }}
                  >

                    <input type="checkbox"
                      className="mb-xl-9 h-100"
                      style={{ marginTop: "18px", position: "absolute", top: "-43%", left: "14px", backgroundColor: "black", cursor: "pointer", width: "15px", borderColor: 'black', borderWidth: '4px', borderStyle: 'solid', }}
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    {rows?.map((row: any) => (
                      <div
                        key={row.id}
                        className="d-flex flex-column mx-3 p-2"
                        style={{ flex: "1 1 300px", minWidth: "282px" }}
                      >
                        <Link
                          to={`/ticket/information/${row.id}`}
                          className="text-hover-primary fs-5 fw-bold flex-grow-1"
                        >
                          <Card
                            key={row.id}
                            className="mb-6 mb-xl-9"
                            style={{
                              minHeight: "350px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <CardContent>
                              <div className="d-flex align-items-center mb-3">
                                <Checkbox
                                  checked={selectedTickets.includes(row.id)}
                                  onChange={(event) =>
                                    handleSelect(event, row.id)
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <Typography className="fs-6 fw-semibold text-gray-600">
                                  Ref ID: {row.id}
                                </Typography>
                              </div>

                              <Typography
                                className="fs-6 fw-semibold text-gray-600 mb-3"
                                data-bs-toggle="tooltip"
                                title={row.title}
                              >
                                Title: {truncateDescription(row.title, 28)}
                              </Typography>

                              <div
                                className="badge badge-light mb-3"
                                style={{ ...getStatusStyle(row.status_type) }}
                                data-bs-toggle="tooltip"
                                title={row.displayName}
                              >
                                <span className="fw-bold">
                                  Status:{" "}
                                  {truncateDescription(row.displayName, 10)}
                                </span>
                              </div>

                              {row.description && (
                                <Typography
                                  className="fs-6 fw-semibold text-gray-600 mb-3"
                                  title={row.description}
                                >
                                  Description:
                                  <div className="badge badge-light">
                                    {truncateDescription(row.description, 30)}
                                  </div>
                                </Typography>
                              )}
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Created: {formatDate(row.created)}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Assignee:{" "}
                                {capitalizeFirstLetter(
                                  row.assignee === undefined
                                    ? "Unassigned"
                                    : row.assignee
                                )}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-3">
                                Raised by: {capitalizeFirstLetter(row.raisedBy)}
                              </Typography>
                              <Typography className="fs-6 fw-semibold text-gray-600 mb-5">
                                Priority: {capitalizeFirstLetter(row.priority)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="row mx-4 pt-2 mb-2">
        {/* Page information */}
        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </div>

        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="kt_table_users_paginate"
          >
            <ul className="pagination">
              {/* Previous button */}
              <li
                className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                  }`}
              >
                <button
                  type="button"
                  aria-controls="kt_table_users"
                  data-dt-idx="0"
                  className="btn primary page-link"
                  onClick={prevPage}
                >
                  <i className="previous"></i>
                </button>
              </li>

              {/* Page numbers */}
              {generatePaginationNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                    }`}
                >
                  <button
                    type="button"
                    aria-controls="kt_table_users"
                    data-dt-idx={pageNumber}
                    className="btn primary page-link"
                    onClick={() => paginate(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              {/* Next button */}
              <li
                className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <button
                  type="button"
                  aria-controls="kt_table_users"
                  data-dt-idx="0"
                  className="btn primary page-link"
                  onClick={nextPage}
                >
                  <i className="next"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketCardView;
