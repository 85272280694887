import { useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { CloseButton } from 'react-bootstrap'
// import { GET_ALL_TEAMS_AND_USERS } from '../../graphql/query'
import { createInitials } from '../../utils/functions/createInitials'
import ReactModal from 'react-modal'
import TransferChatConfirmModal from './TransferChatConfirmModal'

interface IProps {
  closeTransferModal: () => void
  chatRoomID: number
  setUpdateChatRoomsFlag: any
  chatRoomsArrayRef: any
}

const TransferChatModal: React.FC<IProps> = ({
  closeTransferModal,
  chatRoomID,
  setUpdateChatRoomsFlag,
  chatRoomsArrayRef
}) => {
  const [isTransferConfirmModalOpen, setIsTransferConfirmModalOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<{ id: number; name: string } | null>(null)

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '15px',
      border: 'none',
      boxShadow: '0 2px 8px 0 #00000029'
    }
  }

  const openTransferConfirmModal = (user: { id: number; name: string }) => {
    setSelectedUser(user)
    setIsTransferConfirmModalOpen(true)
  }

  const afterOpenTransferConfirmModal = () => {
    // subtitle.style.color = '#f00'
  }

  const closeTransferConfirmModal = () => {
    setIsTransferConfirmModalOpen(false)
    setSelectedUser(null)
  }

  // const { data, error, loading } = useQuery(GET_ALL_TEAMS_AND_USERS, {
  //   context: {
  //     headers: {
  //       'access-control-allow-methods': 'Human-hand-off'
  //     }
  //   }
  // })

  // if (loading) return <div>Loading...</div>
  // if (error) return <div>Error loading data</div>

  return (
    <div className='card h-450px w-600px p-4'>
      <div className='d-flex justify-content-between mx-0 px-0 mb-2'>
        <span className='mb-2 me-auto'>
          <span className='ms-1 fs-3 fw-bolder'>Transfer Chat to...</span>
        </span>
        <CloseButton onClick={closeTransferModal} />
      </div>
      <div className='card-body scroll-y p-0'>
        {/* {data.getAllTeamsAndUsers.map((team: any) => (
          <div key={team.id} className='border border-3 border-muted-500 rounded-2 mt-2'>
            <table className='table table-row-dashed table-row-gray-300 mb-0'>
              <thead>
                <tr className='fw-bolder fs-6 text-primary bg-light-primary'>
                  <th className='px-4'>{team.name}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='h-auto scroll-y ps-3' id='kt_chat_contacts_body'>
                      {team.users.map((user: any) => {
                        const { label, state } = createInitials(user.name)
                        return (
                          <div
                            key={user.id}
                            className='d-flex flex-stack py-4 px-4 border border-2 mb-1'
                          >
                            <div className='d-flex align-items-center'>
                              <div className='symbol ps-1 symbol-40px symbol-circle me-3'>
                                <span
                                  className={`symbol-label bg-light-${state} text-${state} fs-6 fw-bolder`}
                                >
                                  {label}
                                </span>
                              </div>
                              <div className='ms-1 d-flex flex-column'>
                                <div className='d-flex flex-lg-column flex-xl-row justify-content-between'>
                                  <div
                                    className='fs-7 fw-bold text-gray-900 text-hover-primary mb-1'
                                    role='button'
                                    onClick={() =>
                                      openTransferConfirmModal({ id: user.id, name: user.name })
                                    }
                                  >
                                    {user.name}
                                  </div>
                                  <div className='text-muted fw-bold'>ID-{user.id}</div>
                                </div>
                                <div
                                  className='fw-semibold fw-bold text-muted fs-8'
                                  style={{ wordBreak: 'break-word' }}
                                >
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <div className='separator separator-dashed d-none'></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))} */}
      </div>
      {selectedUser && (
        <ReactModal
          isOpen={isTransferConfirmModalOpen}
          onAfterOpen={afterOpenTransferConfirmModal}
          onRequestClose={closeTransferConfirmModal}
          style={customModalStyles}
          contentLabel='Add Meta Data'
        >
          <TransferChatConfirmModal
            closeTransferConfirmModal={closeTransferConfirmModal}
            userId={selectedUser.id}
            userName={selectedUser.name}
            chatRoomID={chatRoomID}
            setUpdateChatRoomsFlag={setUpdateChatRoomsFlag}
            chatRoomsArrayRef={chatRoomsArrayRef}
          />
        </ReactModal>
      )}
    </div>
  )
}

export default TransferChatModal
