import React, { useState } from 'react'
import { Dropdown } from '../../helpers/components/Dropdown'
import { parse } from 'path'

interface IProps {
  chatWindowDropdownMenuRef: React.MutableRefObject<any[]>
  index: number
  handleOpenChat: () => void
  isDisabled: boolean
  user: any
  id: any
}

const TeamActionMenu: React.FC<IProps> = ({
  chatWindowDropdownMenuRef,
  index,
  handleOpenChat,
  isDisabled,
  user,
  id
}) => {
  const [chatWindowMenuDroppedDown, setChatWindowMenuDroppedDown] = useState<boolean>(false)

  const handleTeam = () => {
    setChatWindowMenuDroppedDown(!chatWindowMenuDroppedDown)
  }

  const isDisabledWindow = parseInt(id) === parseInt(user.id)

  return (
    <>
      <button
        className={`btn btn-sm btn-icon btn-active-light-primary`}
        onClick={() => handleTeam()}
        disabled={isDisabledWindow}
      >
        <i className='bi bi-three-dots-vertical fs-2'></i>
      </button>
      <Dropdown
        menuRef={chatWindowDropdownMenuRef.current[index]}
        droppedDown={chatWindowMenuDroppedDown}
        setDroppedDown={setChatWindowMenuDroppedDown}
      >
        <div className='px-4'>
          <p
            className={`link-primary hoverable mb-0 ${isDisabled ? 'text-muted' : ''}`}
            role='button'
            onClick={!isDisabled ? handleOpenChat : undefined}
          >
            <i className='bi bi-chat-left-text link-primary'></i>
            <span className='px-2'>Open Window</span>
          </p>
        </div>
      </Dropdown>
    </>
  )
}

export default TeamActionMenu
