import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../helpers";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { useEffect, useRef, useState } from "react";
import ChangePassword from "./components/ChangePassword";
import VerificationLink from "./components/VerificationLink";
import ResetPassword from "./components/ResetPassword";

const AuthLayout = () => {
  const fetchingTenant = window.location.hostname.split(".")[0];
  const [botId, setBotId] = useState<string | null>(null);


  // useEffect(() => {
  //   const fetchBotId = async () => {
  //     if (!fetchingTenant) return;

  //     try {
  //       // Remove "graphql" from the URL if it exists
  //       const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(/\/?graphql\/?$/, "");

  //       const response = await fetch(`${apiUrl}/tenant/get-bots/${fetchingTenant}`);
  //       const data = await response.json();
  //       if (data && data.data[0].appIDString) {
  //         setBotId(data.data[0].appIDString); // Assuming the first bot is the one needed
  //       }
  //     } catch (error) {
  //       console.error("Error fetching bot ID:", error);
  //     }
  //   };

  //   fetchBotId();
  // }, [fetchingTenant]);

  // useEffect(() => {
  //   if (botId) {
  //     (function (d, m) {
  //       var botgoSettings = {
  //         appId: botId,
  //         "botgo-primary-color": "",
  //         "botgo-bot-bubble-color": "",
  //         "botgo-header-height": "60px",
  //         initiallyShow: false,
  //       };
  //       var s = document.createElement("script");
  //       s.type = "text/javascript";
  //       s.async = true;
  //       var ts = Math.round(Date.now() / 1000);
  //       s.src = "https://botgo.io/chatbotPluginDemo/embedd.js?t=" + ts;
  //       var h = document.getElementsByTagName("head")[0];
  //       h.appendChild(s);
  //       (window as any).botgo = m;
  //       m._globals = botgoSettings;
  //     })(document, (window as any).botgo || {});
  //   }
  // }, [botId]);



  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/dozzy-1/14.png"
        )})`,
        // backgroundSize: 'cover'
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        {/* begin::Logo */}
        <a href="#" className="mb-12 position-absolute top-0 start-0">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/CircleOne.png")}
            className="h-70px"
          />
        </a>
        {/* end::Logo */}

        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div
        className="d-flex flex-center flex-column-auto p-5"
        style={{ flexDirection: "column", gap: "20px" }}
      >
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="#" className="text-muted text-hover-primary px-2">
            About
          </a>
          <a href="#" className="text-muted text-hover-primary px-2">
            Contact Us
          </a>
        </div>
        <div>
          <h5>Powered by Botgo Technologies Pvt. Ltd © 2024</h5>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="update-password" element={<ChangePassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="email-sent" element={<VerificationLink />} />
      <Route index element={<Navigate to="/auth/login" />} />
    </Route>
  </Routes>
);

export { AuthPage };
