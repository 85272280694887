import React, { PropsWithChildren, useContext } from "react";
import clsx from "clsx";
import ConfigContext from "../../context/ConfigContext";
import { toAbsoluteUrl } from "../../helpers";
// import { displayTime } from '../../utils/functions/displayTime'
import ChatContext from "../../context/ChatContext";
import { useQuery } from "@apollo/client";
// import { GET_SESSIONS_BY_CHAT_ROOM_ID } from '../../graphql/query'
import UserContext from "../../context/UserContext";

interface IProps {
  chatRoomId: number | undefined;
  side: string;
  time: string;
  type: "Unassigned" | "Assigned" | "Resolved" | "Chat-Report";
  item: any;
}

const Intent: React.FC<PropsWithChildren<IProps>> = ({
  chatRoomId,
  side,
  time,
  children,
  type,
  item,
}) => {
  // const {
  //   error: error2,
  //   data: data2,
  //   refetch: refetchSessionsByChatRoomId
  // } = useQuery(GET_SESSIONS_BY_CHAT_ROOM_ID, {
  //   skip: !chatRoomId, // Skip query if chatRoomId is undefined
  //   variables: {
  //     input: {
  //       chatRoomId: chatRoomId || 0 // Default chatRoomId to 0 if undefined
  //     }
  //   },
  //   context: {
  //     headers: {
  //       'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //     }
  //   }
  // })

  const config = useContext(ConfigContext);
  const { state } = useContext(ChatContext);
  const { currentUser } = useContext(UserContext);

  let justifyContent;
  if (side === "rhs" || side === "end-user") {
    justifyContent = "start";
  } else if (side === "lhs" || side === "bot" || side === "dash-user") {
    justifyContent = "end";
  } else if (side === "center") {
    justifyContent = "center";
  } else if (item?.senderId === parseInt(currentUser.id)) {
    justifyContent = "end";
  } else if (item?.senderId !== parseInt(currentUser.id)) {
    justifyContent = "start";
  }

  const contentClass = `justify-content-${justifyContent} mb-10`;
  const chatRoom = state.chatRooms.find(
    (chatRoomList: any) => parseInt(chatRoomList.id) === chatRoomId
  );
  let endUserName = "End User";

  if (chatRoom) {
    const chatRoomMeta = chatRoom.chatRoomMeta;

    if (chatRoomMeta?.length > 0) {
      const nameMetaData = chatRoomMeta.find(
        (metaData: any) => metaData.name === "Name"
      );

      if (nameMetaData) {
        endUserName = nameMetaData.value;
      } else if (chatRoom.id) {
        endUserName = chatRoom.id;
      }
    } else if (chatRoom.id) {
      endUserName = chatRoom.id;
    }
  }

  let alignItemsClass;
  if (side === "rhs" || side === "end-user") {
    alignItemsClass = "start";
  } else if (side === "lhs" || side === "bot" || side === "dash-user") {
    alignItemsClass = "end";
  } else if (item?.senderId === parseInt(currentUser.id)) {
    alignItemsClass = "end";
  } else if (side === "center") {
    alignItemsClass = "center";
  } else if (item?.senderId !== parseInt(currentUser.id)) {
    alignItemsClass = "start";
  }

  // if (error2) return <h1>{error2.message}</h1>

  // if ((!!chatRoomId && type === 'Resolved') || type === 'Chat-Report') {
  //   refetchSessionsByChatRoomId({
  //     input: {
  //       chatRoomId: chatRoomId
  //     }
  //   })
  // }

  return (
    <div className={clsx("d-flex", contentClass)}>
      <div
        className={clsx(
          "d-flex flex-column align-items",
          `align-items-${alignItemsClass}`
        )}
      >
        <div className="d-flex align-items-center mb-2">
          {(side === "lhs" || side === "bot") && (
            <>
              <div className="ms-3">
                <span className="text-muted fs-7 mb-1 me-2">
                  {!!time
                    ? new Intl.DateTimeFormat("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                        timeZone: "Asia/Kolkata",
                      }).format(new Date(time))
                    : ""}
                </span>
                <span
                  role="button"
                  className="fs-5 fw-bolder text-gray-900 text-hover-primary me-2"
                >
                  {/* Bot */}
                  {/* {!!data2?.sessionsByChatRoomId[0]?.botName
                    ? data2?.sessionsByChatRoomId[0]?.botName
                    : 'Bot'} */}
                </span>
              </div>
              <div className="symbol symbol-35px symbol-circle">
                <img
                  style={config?.theme?.botAvatarStyle}
                  src={config?.theme?.botAvatar}
                  alt=""
                />
              </div>
            </>
          )}
          {(side === "rhs" ||
            side === "end-user" ||
            side === "dash-user-rhs") && (
            <>
              <div className="symbol symbol-35px symbol-circle ">
                <img
                  alt="End User Pic"
                  src="https://chatbot-project.s3.ap-south-1.amazonaws.com/botgo/assets/blank.svg"
                />
              </div>
              <div className="ms-3">
                <span
                  role="button"
                  className="fs-5 fw-bolder text-gray-900 text-hover-primary me-2"
                >
                  {item?.senderId === parseInt(currentUser?.id)
                    ? "You"
                    : side === "dash-user-rhs"
                    ? item?.data?.sender.name
                    : "End User"}
                </span>
                <span className="text-muted fs-7 mb-1 me-2">
                  {!!time
                    ? new Intl.DateTimeFormat("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                        timeZone: "Asia/Kolkata",
                      }).format(new Date(time))
                    : "Just Now"}
                </span>
              </div>
            </>
          )}
          {side === "dash-user" && (
            <>
              <div className="me-3">
                <span className="text-muted fs-7 mb-1">
                  {!!time
                    ? new Intl.DateTimeFormat("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                        timeZone: "Asia/Kolkata",
                      }).format(new Date(time))
                    : "Just Now"}
                </span>
                <span
                  role="button"
                  className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-2"
                >
                  {/* {type === 'Assigned' || item?.senderId === parseInt(currentUser.id)
                    ? 'You'
                    : data2?.sessionsByChatRoomId[0]?.assignedAgentId !== null
                    ? data2?.sessionsByChatRoomId[0]?.agentName
                    : 'user'} */}
                  {/* You */}
                </span>
              </div>
              <div className="symbol symbol-35px symbol-circle ">
                <img
                  alt="Pic"
                  src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                />
              </div>
            </>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Intent;
