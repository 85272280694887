import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const emailHandler = (e: any) => {
    setEmail(e.target.value);
  };

  const passwordResetHandler = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email is required");
      return;
  }
    const forgotPassObj = {
      username: email,
      tenant:window.location.hostname.split(".")[0],
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_BASE_URL}/v1/forgot-password/`,
        forgotPassObj
      );

      if (response?.status === 200) {
        localStorage.setItem("email", email);
        localStorage.setItem("tenant", window.location.hostname.split(".")[0]);

        toast.success(response?.data?.message[0]);
        console.log(response?.data?.message[0],"response?.data?.message[0]")
        console.log(response?.data,"error")

        setTimeout(() => {
          navigate("/verify-email");
        }, 2000);

        setLoading(false);
      } else {
        toast.error("An error occured123");
      }
    } catch (error) {
      console.log(error,"error")
      setLoading(false);
      toast.error("Please enter valid email address");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Email
          </label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            className="form-control form-control-lg form-control-solid"
            onChange={emailHandler}
            value={email}
          />
        </div>

  
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            onClick={passwordResetHandler}
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">
              {loading ? (
                <>
                  Submiting... <Loader />
                </>
              ) : (
                "Submit"
              )}
            </span>
          </button>
          <Link to="/auth/login">
            <button
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
            >
              Cancel
            </button>
          </Link>{" "}
        </div>
      </form>
    </>
  );
}
