import React, { useContext, useEffect } from 'react'
import ChatPanelBody from './ChatPanelBody'
import { IChatbotState } from '../../utils/interfaces/IChatbotState'
import ChatContext from '../../context/ChatContext'
// import { GET_INTERNAL_CHAT_ID } from '../../graphql/query'
import { useQuery } from '@apollo/client'
import UserContext from '../../context/UserContext'
import { GET_INTERNAL_CHAT_ID } from '../../graphql/query'

interface ChatPanelProps {
  id: number
  index: number
  isMinimized: boolean
  onClose: (id: number) => void
  onMinimize: (id: number) => void
  user: any // Updated to receive user data
  chatRoomId: number
  handleMarkMessgaeAsRead: any
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
  convArrayRef: any
  updateConvRef: any
}

const ChatPanelComponent: React.FC<ChatPanelProps> = ({
  id,
  index,
  isMinimized,
  onClose,
  onMinimize,
  user,
  type,
  chatRoomId,
  handleMarkMessgaeAsRead,
  convArrayRef,
  updateConvRef
}) => {
  const panelWidth = 400 // Panel width in pixels
  const spacing = 20
  const { currentUser } = useContext(UserContext)
  const { state, setState, getPrevMessages, getAgentPrevMessages } = useContext(ChatContext)
  const userChat = user.emailVerified ? undefined : user.id

  const { data, loading, error, refetch } = useQuery(GET_INTERNAL_CHAT_ID, {
    variables: {
      input: {
        recieverId: user.id,
        senderId: parseInt(currentUser.id)
      }
    },
    skip: !user.emailVerified
  })

  const agentChatId = data?.getInternalChatId?.data?.uchatId || 0

  useEffect(() => {
    if (type === 'Assigned' && user.emailVerified) {
      refetch()
      if (agentChatId) {
        getAgentPrevMessages(parseInt(agentChatId))
          .then((res: any) => {
            if (!!res.data && res.data.length > 0) {
              const newConvArray = res.data
              convArrayRef.current = newConvArray
              updateConvRef.current = true

              setState((prevState: IChatbotState) => {
                const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
                  (panel) => {
                    if (panel.agentId === user.id) {
                      return {
                        ...panel,
                        conversation: newConvArray
                      }
                    }
                    return panel
                  }
                )

                return {
                  ...prevState,
                  panelBasedConversation: updatedPanelBasedConversation
                }
              })
            } else {
              setState((prevState: IChatbotState) => {
                const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
                  (panel) => {
                    if (panel.agentId === user.id) {
                      return {
                        ...panel,
                        conversation: []
                      }
                    }
                    return panel
                  }
                )

                return {
                  ...prevState,
                  panelBasedConversation: updatedPanelBasedConversation
                }
              })
            }
          })
          .catch(() => {
            console.log('No Conversations')
          })
      }
    } else {
      getPrevMessages(parseInt(userChat))
        .then((res: any) => {
          if (!!res.messages && res.messages.length > 0) {
            const newConvArray = res.messages
            convArrayRef.current = newConvArray
            updateConvRef.current = true

            setState(
              (prevState: IChatbotState) => {
                const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
                  (panel) => {
                    if (panel.chatRoomId === user.id) {
                      return {
                        ...panel,
                        conversation: newConvArray
                      }
                    }
                    return panel
                  }
                )

                return {
                  ...prevState,
                  panelBasedConversation: updatedPanelBasedConversation
                }
              },
              (updatedState: IChatbotState) => {
                console.log('Conversation loaded for chatRoom ID: ' + user.id)
              }
            )
          } else {
            setState((prevState: IChatbotState) => {
              const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
                (panel) => {
                  if (panel.chatRoomId === user.id) {
                    return {
                      ...panel,
                      conversation: []
                    }
                  }
                  return panel
                }
              )

              return {
                ...prevState,
                panelBasedConversation: updatedPanelBasedConversation
              }
            })
          }
        })
        .catch(() => {
          console.log('No Conversations')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, agentChatId, userChat, refetch])

  useEffect(() => {
    const convArray = convArrayRef.current

    if (updateConvRef.current === true) {
      setState((prevState: IChatbotState) => {
        const updatedPanelBasedConversation = prevState.panelBasedConversation.map((panel) => {
          if (panel.chatRoomId === parseInt(userChat)) {
            return {
              ...panel,
              conversation: convArray
            }
          }
          return panel
        })

        return {
          ...prevState,
          panelBasedConversation: updatedPanelBasedConversation
        }
      })

      return () => {
        updateConvRef.current = false
      }
    }
  })

  useEffect(() => {
    const convArray = convArrayRef.current

    if (updateConvRef.current === true) {
      setState((prevState: IChatbotState) => {
        const updatedPanelBasedConversation = prevState.panelBasedConversation.map((panel) => {
          if (panel.agentId === parseInt(agentChatId)) {
            return {
              ...panel,
              conversation: convArray
            }
          }
          return panel
        })

        return {
          ...prevState,
          panelBasedConversation: updatedPanelBasedConversation
        }
      })

      return () => {
        updateConvRef.current = false
      }
    }
  })

  if (loading) {
    return <h1>Loading...</h1>
  }

  if (error) {
    return <h1>Error: {error.message}</h1>
  }

  return (
    <div
      className={`chat-panel ${isMinimized ? 'minimized' : ''}`}
      style={{
        right: `${30 + index * (panelWidth + spacing)}px`, // Adjust position based on index
        width: `${panelWidth}px`
        // zIndex: '200'
      }}
    >
      <div className='card-header' id='kt_chat_messenger_header'>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            {user?.name || 'CONV'} - {user?.id}
          </div>
        </div>
        <div className='chat-controls card-toolbar'>
          <button onClick={() => onMinimize(id)}>
            {isMinimized ? (
              <i className='bi bi-dash text-black fs-1'></i>
            ) : (
              <i className='bi bi-dash text-black fs-1'></i>
            )}
          </button>
          <button onClick={() => onClose(id)}>
            <i className='bi bi-x text-black fs-1'></i>
          </button>
        </div>
      </div>

      {!isMinimized && (
        <div className='chat-body'>
          <ChatPanelBody
            id={id}
            onClose={onClose}
            type={type}
            isAgentData={user}
            handleMarkMessgaeAsRead={handleMarkMessgaeAsRead}
          />
        </div>
      )}
    </div>
  )
}

export default ChatPanelComponent
