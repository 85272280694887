import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatDatee } from "../../../../components/reusableComponent/DateFormat";
import DateTicketDashboard from "../../../../components/common/DateTicketDashboard";

interface IPROPS {
  channelWiseData: any;
}

const capitalizeAndFormat = (str) => {
  if (!str) return "";
  const formatted = str.replace(/_/g, " "); // Replace underscores with spaces
  return formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase();
};

const ChannelWise: React.FC<IPROPS> = ({ channelWiseData }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [liftingDates, setLiftingDates] = useState([]);

  const startDate =
    liftingDates.length > 0 ? formatDatee(liftingDates[0]?.startDate) : null;
  const endDate =
    liftingDates.length > 0 ? formatDatee(liftingDates[0]?.endDate) : null;

  // Extract categories and series data from `channelWiseData.ticket_by_channel`
  const categories = channelWiseData?.ticket_by_channel
    ? channelWiseData.ticket_by_channel.map((item) =>
        capitalizeAndFormat(item.channel)
      )
    : [];

  const seriesData = [
    {
      name: "Tickets",
      data: channelWiseData?.ticket_by_channel
        ? channelWiseData?.ticket_by_channel?.map((item) => item.channel_count)
        : [],
    },
  ];

  const maxDataValue =
    seriesData.length > 0 ? Math.max(...seriesData[0].data) : 0;

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = async () => {
    try {
      if (!document.fullscreenElement && chartRef.current) {
        await chartRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error("Fullscreen mode failed", error);
    }
  };

  const options: any = {
    chart: {
      type: "bar",
      height: isFullscreen ? "100vh" : 450,
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            {
              icon: isFullscreen
                ? '<i class="bi bi-fullscreen-exit"></i>'
                : '<i class="bi bi-arrows-fullscreen"></i>',
              title: isFullscreen ? "Exit Fullscreen" : "Fullscreen",
              class: "custom-fullscreen-icon mx-2",
              click: toggleFullscreen,
            },
          ],
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories, // Categories for the X-axis
      title: {
        text: "Channel", // X-axis label
        style: {
          fontWeight: "normal",
        },
      },
    },
    yaxis: {
      max: maxDataValue, // Ensure y-axis goes above the highest value
      min: 0,
      tickAmount: maxDataValue, // Adjust if needed; based on maxDataValue or fixed number
      labels: {
        formatter: (value) => Math.floor(value), // Format labels as integers
      },
      title: {
        text: "Ticket Count", // Y-axis label
        style: {
          fontSize: "13px",
          fontWeight: "normal",
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const hasData = categories.length > 0;

  return (
    <div className="card card-xxl-stretch">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            Channel wise tickets
          </span>
        </h3>
      </div>

      <div
        ref={chartRef}
        className="card-body"
        style={{
          width: "100%",
          height: isFullscreen ? "100vh" : 450,
          backgroundColor: "white",
          padding: isFullscreen ? "20px" : "0",
        }}
      >
        {hasData ? (
          <ReactApexChart
            options={options}
            series={seriesData}
            type="bar"
            height="100%"
          />
        ) : (
          <div className="text-center p-4">
            <p className="fs-4 fw-bold text-gray-600">No data available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelWise;
