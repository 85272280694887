import { useEffect, useState } from "react";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import {
  useTicketRulesByTeamQuery,
  useUdpateTicketRulesByTeamMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../LoadingSpinner";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";

const PriorityBasedSLA = () => {
  const [selectedTeam, setSelectedTeam] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSlaLoading, setIsSlaLoading] = useState(false);
  const { data: teamsData } = useTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [emails, setEmails] = useState([]);
  const [slaRules, setSlaRules] = useState([]);
  const [originalEmails, setOriginalEmails] = useState([]);
  const [originalSlaRules, setOriginalSlaRules] = useState([]);
  const {
    data: ticketData,
    refetch,
    isFetching,
  } = useTicketRulesByTeamQuery(selectedTeam, {
    skip: !selectedTeam,
  });

  const [triggerUpdateOnTicketRulesByTeamId] =
    useUdpateTicketRulesByTeamMutation();

  useEffect(() => {
    if (isFetching) {
      setIsSlaLoading(true);
    } else {
      setIsSlaLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (ticketData) {
      setEmails(ticketData.ticket_resolution_sla_emails || []);
      setOriginalEmails(ticketData.ticket_resolution_sla_emails || []);
      const formattedSlaRules = Object.entries(ticketData.sla_rules || {}).map(
        ([priority, time]) => ({
          priority,
          time,
        })
      );
      setSlaRules(formattedSlaRules);
      setOriginalSlaRules(formattedSlaRules);
    }
  }, [ticketData]);

  useEffect(() => {
    setTheTeams(teamsData || []);
  }, [teamsData]);

  const handleTimeChange = (index, type, action) => {
    setSlaRules((prevRules) => {
      return prevRules.map((rule, i) => {
        if (i === index) {
          let newTime = rule.time;
          if (type === "hours") {
            if (action === "increment") {
              newTime += 60 * 60 * 1000; // Add 1 hour
            } else if (action === "decrement" && newTime >= 60 * 60 * 1000) {
              newTime -= 60 * 60 * 1000; // Subtract 1 hour
            }
          } else if (type === "minutes") {
            if (action === "increment") {
              newTime += 60 * 1000; // Add 1 minute
            } else if (action === "decrement" && newTime >= 60 * 1000) {
              newTime -= 60 * 1000; // Subtract 1 minute
            }
          }

          console.log(
            `Updated time for priority ${
              rule.priority
            }: ${newTime} ms (${Math.floor(
              newTime / (1000 * 60 * 60)
            )}h ${Math.floor((newTime / (1000 * 60)) % 60)}m)`
          );

          return { ...rule, time: newTime };
        }
        return rule;
      });
    });
  };

  console.log(emails, "emails");

  const handleSave = async (e) => {
    e.preventDefault();

    if (!selectedTeam) {
      toast.error("Please select a team first.");
      return;
    }

    setIsLoading(true);

    const payload = {
      ticket_resolution_sla_emails: emails,
      sla_rules: slaRules.reduce((acc, { priority, time }) => {
        acc[priority] = time;
        return acc;
      }, {}),
    };

    try {
      const response = await triggerUpdateOnTicketRulesByTeamId({
        body: payload,
        teamId: selectedTeam,
      }).unwrap();

      if (response) {
        toast.success("SLA rules updated successfully!");
        await refetch();
      }
    } catch (error) {
      toast.error("Failed to update SLA rules.");
    } finally {
      setIsLoading(false);
    }
  };

  const isChanged =
    JSON.stringify(emails) !== JSON.stringify(originalEmails) ||
    JSON.stringify(slaRules) !== JSON.stringify(originalSlaRules);

  return (
    <div>
      <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5" style={{marginLeft:"0%"}}>
        <div className="row mb-2">
          <div className="w-100 mw-200px">
            <label>Select team</label>
            <select
              className="form-select"
              onChange={(e) => setSelectedTeam(e.target.value)}
              value={selectedTeam}
              disabled={isLoading}
            >
              <option value="">Select team</option>
              {theTeams.map((team, index) => (
                <option key={index} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedTeam && (
          <>
            <div className="row">
              <div className="col">
                <div
                  className="card my-1 min-h-40px justify-content-center"
                  style={{ backgroundColor: "rgba(228, 228, 228, 0.498)" }}
                >
                  <div className="row align-items-center">
                    <div className="col-4 text-start ps-10">
                      <strong>Priority</strong>
                    </div>
                    <div className="col-1 text-center">
                      <strong>Hours</strong>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-1 text-center">
                      <strong>Minutes</strong>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4 text-center">
                      <strong>Mail ID</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isSlaLoading ? (
              <div className="text-center mt-3">
                <LoadingSpinner />
              </div>
            ) : (
              slaRules?.map(({ priority, time }, index) => (
                <div className="row" key={index}>
                  {/* <div className="col"> */}
                  <div className="input-group-text mt-2 bg-white">
                    <div className="row w-100 align-items-center">
                      <div className="col-4 text-start ps-5">
                        {capitalizeFirstLetter(priority)}
                      </div>
                      <div className="col-1 d-flex align-items-center justify-content-center">
                        {/* <input
                            type="number"
                            className="form-control border-0 py-0 text-center"
                            value={Math.floor(time / (1000 * 60 * 60))}
                            min={0}
                            readOnly
                          /> */}
                        {Math.floor(time / (1000 * 60 * 60))}
                        <div className="d-flex flex-column ms-2">
                          <i
                            className="bi bi-caret-up-fill cursor-pointer"
                            onClick={() =>
                              handleTimeChange(index, "hours", "increment")
                            }
                          ></i>
                          <i
                            className="bi bi-caret-down-fill cursor-pointer"
                            onClick={() =>
                              handleTimeChange(index, "hours", "decrement")
                            }
                          ></i>
                        </div>
                      </div>
                      <div className="col-1"></div>
                      <div className="col-1 d-flex align-items-center justify-content-center">
                        {/* <input
                            type="number"
                            className="form-control border-0 py-0 text-center"
                            value={Math.floor((time / (1000 * 60)) % 60)}
                            min={0}
                            readOnly
                          /> */}

                        {Math.floor((time / (1000 * 60)) % 60)}
                        <div className="d-flex flex-column ms-2">
                          <i
                            className="bi bi-caret-up-fill cursor-pointer"
                            onClick={() =>
                              handleTimeChange(index, "minutes", "increment")
                            }
                          ></i>
                          <i
                            className="bi bi-caret-down-fill cursor-pointer"
                            onClick={() =>
                              handleTimeChange(index, "minutes", "decrement")
                            }
                          ></i>
                        </div>
                      </div>
                      <div className="col-1"></div>
                      <div className="col-4 text-center">
                        <ReactMultiEmail
                          placeholder="Input your Email Address"
                          emails={emails[priority] || []} // Retrieve emails for specific priority
                          onChange={(updatedEmails) => {
                            setEmails((prev) => ({
                              ...prev,
                              [priority]: updatedEmails, // Update only the specific priority's emails
                            }));
                          }}
                          getLabel={(email, index, removeEmail) => (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              ))
            )}
            <div className="row">
              <div className="col text-end mt-2">
                <button
                  className="btn btn-primary ms-4 min-w-100px"
                  onClick={handleSave}
                  disabled={isLoading || !isChanged}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PriorityBasedSLA;
