import React, { useState } from 'react'
import { Dropdown } from '../../helpers/components/Dropdown'
import TransferChatModal from './TransferChatModal'
import ReactModal from 'react-modal'

interface IProps {
  assignedDropdownMenuRef: any
  assignedMenuDroppedDown: boolean
  setAssignedMenuDroppedDown: any
  chatRoomID: number
  setUpdateChatRoomsFlag: any
  chatRoomsArrayRef: any
}

const AssignedChatDropdown: React.FC<IProps> = ({
  assignedDropdownMenuRef,
  assignedMenuDroppedDown,
  setAssignedMenuDroppedDown,
  chatRoomID,
  setUpdateChatRoomsFlag,
  chatRoomsArrayRef
}) => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState<boolean>(false)
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '15px',
      border: 'none',
      boxShadow: '0 2px 8px 0 #00000029'
    }
  }

  const openTransferModal = () => {
    setIsTransferModalOpen(true)
    setAssignedMenuDroppedDown(false)
  }

  const afterOpenTransferModal = () => {
    // subtitle.style.color = '#f00'
  }

  const closeTransferModal = () => {
    setIsTransferModalOpen(false)
  }
  return (
    <div>
      {' '}
      <div className='me-2' ref={assignedDropdownMenuRef}>
        <button
          className={`btn btn-sm btn-icon btn-active-light-primary ${
            assignedMenuDroppedDown ? 'show menu-dropdown' : ''
          }`}
          onClick={() => setAssignedMenuDroppedDown(!assignedMenuDroppedDown)}
        >
          <i className='bi bi-three-dots fs-2'></i>
        </button>
        <Dropdown
          menuRef={assignedDropdownMenuRef}
          droppedDown={assignedMenuDroppedDown}
          setDroppedDown={setAssignedMenuDroppedDown}
          width={200}
          styles={{ borderRadius: '10px' }}
        >
          <div className='card-body p-2'>
            <div onClick={openTransferModal}>
              <button className='d-flex btn btn-active-light-primary w-100 pe-4'>
                <i className='bi bi-arrow-right fs-2 me-8 fw-bold text-black'></i>
                <div className='ms-n3'> Transfer to...</div>
              </button>
            </div>
            <ReactModal
              isOpen={isTransferModalOpen}
              onAfterOpen={afterOpenTransferModal}
              onRequestClose={closeTransferModal}
              style={customModalStyles}
              contentLabel='Add Meta Data'
            >
              <TransferChatModal
                closeTransferModal={closeTransferModal}
                chatRoomID={chatRoomID}
                setUpdateChatRoomsFlag={setUpdateChatRoomsFlag}
                chatRoomsArrayRef={chatRoomsArrayRef}
              />
            </ReactModal>
            {/* <div className='d-flex'>
              <button className='btn btn-active-light-primary w-100'>
                <i className='bi bi-ban fs-2 me-4 fw-bold text-black'></i> Ban Customer
              </button>
            </div>
            <div className='d-flex'>
              <button className='btn btn-active-light-primary w-100'>
                <i className='bi bi-x-lg fs-2 me-4 fw-bold text-black'></i> Stop the Chat
              </button>
            </div> */}
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default AssignedChatDropdown
