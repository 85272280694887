import { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Highcharts3d from "highcharts/highcharts-3d.js";
import Cylinder from "highcharts/modules/cylinder.js";
import Funnel from "highcharts/modules/funnel3d";
import { useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

Highcharts3d(Highcharts);
Cylinder(Highcharts);
Funnel(Highcharts);



const transformData = (leadStats) => {
  if (!leadStats || Object.keys(leadStats).length === 0) return [];

  const key = Object.keys(leadStats)[0]; // Getting the first key (dynamic number)
  const stageStats = leadStats[key];

  return Object.entries(stageStats).map(([stage, details]: any, index) => ({
    name: stage,
    y: details.count,
    color: Highcharts.getOptions().colors[index % Highcharts.getOptions().colors.length], // Assigning color
  }));
};
const LeadStatus = ({ data }) => {
  const [modalData, setModalData] = useState<any>({
    status: '',
    count: 0,
    allLeadsList: []
  });
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "funnel3d",
      options3d: {
        enabled: true,
        alpha: 10,
        depth: 50,
        viewDistance: 50
      }
    },
    title: { text: "" },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'vertical',
      x: 0,
      y: 20,
      itemStyle: {
        cursor: "pointer"
      },
      useHTML: true
    },
    plotOptions: {
      series: {

        dataLabels: {
          enabled: true,
          align: 'center',
          verticalAlign: "bottom",
          y: 30,  // Adjust Y-position to avoid overlap
          format: '{point.y}',
          style: {
            fontSize: "14px",
            color: "white",
            textOutline: "none",  // This removes the black outline

          }
        },
        point: {
          events: {
            click: function () {
              openModal(this.name, this.y);
            }
          }
        },
        neckWidth: "30%",
        neckHeight: "25%",
        width: "70%",
        height: "70%"
      }
    },
    series: [{
      name: "Lead Stages",
      data: []
    }]
  });

  // Function to handle legend click event



  useEffect(() => {
    if (data?.lead_stats?.lead_stage_stats) {
      const updatedData = transformData(data.lead_stats.lead_stage_stats);
      console.log(data.lead_stats.lead_stage_stats)
      setChartOptions((prev) => ({
        ...prev,
        series: [{ ...prev.series[0], data: updatedData }]
      }));
    }
  }, [data]);

  useEffect(() => {
    const elements: any = document.getElementsByClassName("highcharts-credits");
    if (elements.length > 0) {
      elements[0].style.display = "none";
    }
  }, []);
  const openModal = (status: string, count: any) => {
    console.log(status);
    console.log(count);

    // Find the dynamic key (121 in this case)
    const leadStageStats = data?.lead_stats?.lead_stage_stats;
    const dynamicKey = Object.keys(leadStageStats || {})[0]; // Assuming only one key exists, else adjust accordingly

    // Extract lead details for the given status
    const allLeadsList = leadStageStats?.[dynamicKey]?.[status]?.lead_details || [];

    setModalData({
      status: status,
      count: count,
      allLeadsList: allLeadsList
    });

    setModalOpen(true);
  };


  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    console.log(chartOptions, "chartOptions")

  }, [chartOptions])
  const handleLegendClick = (name) => {
    setChartOptions((prev) => ({
      ...prev,
      series: [
        {
          ...prev.series[0],
          data: prev.series[0].data.map((item) => ({
            ...item,
            sliced: item.name === name ? !item.sliced : false, // Highlight clicked item
            selected: item.name === name ? !item.selected : false,
          })),
        },
      ],
    }));
    const selectedDataPoint = chartOptions.series[0].data.find((item) => item.name === name);

    if (selectedDataPoint) {
      openModal(selectedDataPoint.name, selectedDataPoint.y);
    }
  };


  return (
    <div className="card card-flush h-lg-100">
      <div className="card-header pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            Lead status
          </span>
        </h3>
      </div>
      <div className="custom-legend justify-content-center" style={{ display: "flex", gap: "10px", padding: "10px" }}>
        {chartOptions.series[0].data.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontWeight: item.selected ? "bold" : "normal", // Visually indicate selection
            }}
            onClick={() => handleLegendClick(item.name)}
          >
            <div style={{ width: "12px", height: "12px", backgroundColor: item.color, marginRight: "5px" }} />
            <span style={{
              color: "rgb(55, 61, 63)",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Helvetica, Arial, sans-serif"
            }}>
              {item.name}
            </span>
          </div>
        ))}
      </div>
      <div className="row align-items-center mx-0 w-100">

        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>


      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // This will center the modal both horizontally and vertically
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2, // Optional: Adds rounded corners to the modal
            width: '40vw', // Optional: Controls the modal's width, can be set to a specific value like '400px'
            maxHeight: '80vh', // Optional: Ensures the modal content is scrollable if content overflows
            overflowY: 'auto', // Enables scrolling if content overflows vertically

          }}
        >
          {/* Close Icon in Top-Right Corner */}
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'gray', // You can adjust this color as needed
            }}
          >
            <CloseIcon />
          </IconButton>


          <h2 id="modal-modal-title">{`Lead status ${modalData.status.charAt(0).toLowerCase() + modalData.status.slice(1)}`}</h2>

          {/* Table for account details */}
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Lead id</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>First name</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Email</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Phone number</th>
              </tr>
            </thead>
            <tbody>
              {modalData?.allLeadsList?.map((lead, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/campaign/lead/${lead.lead_id}`)}
                >
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.lead_id}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.first_name}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.email}
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
                    {lead.phone_number}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Modal>

    </div>
  );
};

export default LeadStatus;
