import ReactDOM from 'react-dom/client'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/

import './assets/scss/style.scss'
// import './customStyles/styles.scss'
import { MainAppRoutes } from './routes/mainAppRoutes'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Provider } from 'react-redux'
import { store } from './ReduxStore/Store'
import { TelephonyProvider } from './components/context/TelephonyContext'
import { AuthProvider } from './components/context/AuthContext'
import { NotificationProvider } from './context/NotificationModalContenxt'
import { ApolloProvider } from '@apollo/client'
import client from './shared/apollo-client'

ReactDOM.createRoot(document.getElementById('root')!).render(

  <Provider store={store}>
    <AuthProvider>
      <NotificationProvider>
        <ApolloProvider client={client}>
          <TelephonyProvider>
            <ToastContainer transition={Slide} />
            <MainAppRoutes
            />
          </TelephonyProvider>
        </ApolloProvider>
      </NotificationProvider>
    </AuthProvider>
  </Provider>,
  // document.getElementById('root')
)
