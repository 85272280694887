import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faFileExcel
} from '@fortawesome/free-solid-svg-icons'

const ImageTemplate: React.FC<any> = ({ message, side, payload }) => {
  return (
    <>
      <p
        className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
        dangerouslySetInnerHTML={{
          __html: message
        }}
      />
      <div className='image-template-container rounded'>
        <div className='image-container my-1'>
          {payload.map((item: any, index: number) => {
            if (item.type === 'gif' || item.type === 'image') {
              return (
                <img
                  key={index}
                  src={item.url}
                  alt=''
                  style={{ maxWidth: '250px', height: 'auto' }}
                />
              )
            } else if (item.type === 'video') {
              return <video key={index} src={item.url} controls={true} />
            } else if (item.type === 'doc' || item.type === 'docx') {
              return (
                <a target='_blank' rel='noreferrer' href={item.url}>
                  <FontAwesomeIcon
                    style={{ width: '50px', height: '70px', marginTop: '10px' }}
                    icon={faFileWord}
                  />
                </a>
              )
            } else if (item.type === 'pdf') {
              return (
                <a target='_blank' rel='noreferrer' href={item.url}>
                  <FontAwesomeIcon
                    style={{ width: '50px', height: '70px', marginTop: '10px', color: 'maroon' }}
                    icon={faFilePdf}
                  />
                </a>
              )
            } else if (item.type === 'xls' || item.type === 'xlsx') {
              return (
                <a target='_blank' rel='noreferrer' href={item.url}>
                  <FontAwesomeIcon
                    style={{ width: '50px', height: '70px', marginTop: '10px', color: 'green' }}
                    icon={faFileExcel}
                  />
                </a>
              )
            } else if (item.type === 'ppt' || item.type === 'pptx') {
              return (
                <a target='_blank' rel='noreferrer' href={item.url}>
                  <FontAwesomeIcon
                    style={{ width: '50px', height: '70px', marginTop: '10px', color: 'red' }}
                    icon={faFilePowerpoint}
                  />
                </a>
              )
            }
            return null
          })}
        </div>
      </div>
      {side === 'lhs' && <i className='bi bi-check-all text-primary'></i>}
    </>
  )
}

export default ImageTemplate
