import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const SkeletonChatHistory = () => {
  return (
    <div className=' w-100 w-350px mx-lg-0'>
      <SkeletonTheme baseColor='#EBEBEB' highlightColor='#f5F5F5'>
        <div className='card card-flush h-800px'>
          <div className='skeleton-body d-flex justify-content-start flex-column mt-9 mx-6'>
            <Skeleton className='skeleton border border-1 mx-1' width='75%' height='100%' />
            <Skeleton className='skeleton border border-1 mx-1 mt-3' width='95%' height='130%' />
            <div className='d-flex justify-content-start flex-row mt-7'>
              <Skeleton className='skeleton border border-1 mx-1' width='18rem' height='3rem' />
              <Skeleton className='skeleton border border-1 mx-1' width='4rem' height='3rem' />
            </div>
          </div>
          <div className='skeleton-body'>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  )
}

export default SkeletonChatHistory
