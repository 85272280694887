import React from 'react'
import TextTemplate from './templates/TextTemplate'
import ImageTemplate from './templates/ImageTemplate'
import Intent from './intent'
import { IChatbotState } from '../../utils/interfaces/IChatbotState'
import { v4 as uuidv4 } from 'uuid'

interface IProps {
  chatRoomId: number
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
  previousValue: IChatbotState['previousValue']
  conversation: IChatbotState['conversation']
  flagMessageRead: any
  token?: IChatbotState['token']
  trigger?: (
    id: any,
    userInputValue?: string | undefined,
    fromAdmin?: boolean | undefined
  ) => Promise<void>
  onButtonClicked?: (nextIntentId: number, value: any, id: any, formdata: any) => void
  uploadFileUrl: string
  apiKey?: string
}

const IntentRenderer: React.FC<IProps> = ({
  chatRoomId,
  previousValue,
  conversation,
  type,
  flagMessageRead,
  uploadFileUrl,
  apiKey
}): any => {
  let conv = conversation?.slice(0)

  return conv?.map((item, index) => {
    let message = item?.MessageGroupId ? item?.data?.content?.message : item?.content?.message

    const regex = new RegExp('{previousValue:\\d+}', 'g')
    if (regex.test(message) && previousValue) {
      let matches = message.match(regex)
      matches = Array.from(matches)
      matches.forEach((match: any) => {
        let prevId = parseInt(match.substring(1, match.length - 1).split(':')[1])
        const prevVal = previousValue.find((itemValue) => itemValue.id === prevId)
        if (!!prevVal) {
          message = message.replace(match, prevVal.value)
          item.content.message = message
        }
      })
    }

    if (item?.content?.human) {
      return (
        <Intent
          type={type}
          chatRoomId={chatRoomId}
          side={item?.MessageGroupId ? item?.side : item?.content?.side}
          key={`unique-key${uuidv4()}`}
          time={item?.time}
          item={item}
        >
          {TextTemplate({
            message: item?.MessageGroupId ? item?.data?.content?.message : item?.content?.message,
            side: item?.MessageGroupId ? item?.side : item?.content?.side,
            readByRecipientId: item?.readByRecipientId,
            uploadFileUrl: uploadFileUrl,
            apiKey: apiKey
          })}
        </Intent>
      )
    } else if (item?.content?.metadata) {
      if (item?.content?.metadata?.templateId === 11) {
        return (
          <Intent
            type={type}
            chatRoomId={chatRoomId}
            side={item?.MessageGroupId ? item?.side : item?.content?.side}
            key={`unique-key${uuidv4()}`}
            time={item?.time}
            item={item}
          >
            {ImageTemplate({
              id: item?.content?.id,
              side: item?.MessageGroupId ? item?.side : item?.content?.side,
              message: item?.MessageGroupId ? item?.data?.content?.message : item?.content?.message,
              payload: item?.content?.metadata?.payload
            })}
          </Intent>
        )
      } else {
        return (
          <Intent
            type={type}
            chatRoomId={chatRoomId}
            side={item?.MessageGroupId ? item?.side : item?.content?.side}
            key={`unique-key${uuidv4()}`}
            time={item?.time}
            item={item}
          >
            {TextTemplate({
              message: item?.MessageGroupId ? item?.data?.content?.message : item?.content?.message,
              side: item?.MessageGroupId ? item?.side : item?.content?.side,
              readByRecipientId: item?.readByRecipientId,
              uploadFileUrl: uploadFileUrl,
              apiKey: apiKey
            })}
          </Intent>
        )
      }
    } else {
      return (
        <Intent
          type={type}
          chatRoomId={chatRoomId}
          side={item?.MessageGroupId ? item?.side : item?.content?.side}
          key={index}
          time={item?.time}
          item={item}
        >
          {TextTemplate({
            message: item?.MessageGroupId ? item?.data?.content?.message : item?.content?.message,
            side: item?.MessageGroupId ? item?.side : item?.content?.side,
            readByRecipientId: item?.readByRecipientId,
            uploadFileUrl: uploadFileUrl,
            apiKey: apiKey
          })}
        </Intent>
      )
    }
  })
}

export default IntentRenderer
