import Apexchart from "../charts/ApexChart"
interface IProps {
  data: any; // Define a type for your data if possible
}
const LeadCountCard: React.FC<IProps> = ({ data }) => {

  return (<>
    {/*<!-- Lead Count Card -->*/}
    <div className="card">
      <div className="card-body pb-0">
        {/* <h5 className="card-title">Lead count</h5> */}


        <h5 className="card-title align-items-start flex-column">
          <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">Lead count</span>
        </h5>

        <div id="budgetChart" style={{ minHeight: "400px", userSelect: "none" }} className="echart">
          <div style={{ position: "relative", padding: "0px", margin: "0px", borderWidth: "0px", cursor: "default" }}>
            <Apexchart data={data} />
          </div>
        </div>
      </div>
    </div>
    {/*<!-- End Lead Count Card -->*/}
  </>)
}

export default LeadCountCard