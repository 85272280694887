import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
    useAccountDynamicFieldQuery,
    useDeleteAccountsAttachmentMutation,
    useGetAccountInfoQuery,
    useUpdateAccountInfoDetailsMutation,
    useUserInfoDetailsQuery,
} from "../../../Services/customer/FrontendCustomer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    useTagsQuery,
    useTeamsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { toast } from "react-toastify";

import {
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Typography,
    Box,
    Modal,
    IconButton,
    Tooltip,
    Chip,
    Button,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import { isFieldDisabled } from "../../reusableComponent/FieldDisabled";
import LoadingSpinner from "../../LoadingSpinner";
import AddExistingContact from "./AddExistingContact";
import { GridCloseIcon } from "@mui/x-data-grid";
import CustomerHistory from "./CustomerHistory";
import ContactModal from "../contact/ContactModal";
import AttachmentList from "../../user/AttachmentList";

interface RestItems {
    accessible_teams: Array<any>;
    tags: Array<any>;
}

interface Tag {
    id: string;
    name: string;
}
interface Team {
    id: string;
    name: string;
}

interface ErrorState {
    [key: string]: string;
}

const initialRestItems: RestItems = {
    accessible_teams: [],
    tags: [],
};

const AccountInfo = () => {
    const { id } = useParams();
    const { data, refetch } = useUserInfoDetailsQuery(id);

    const [dynamicFields, setDynamicFields] = useState({});
    const [initialDynamicFields, setInitialDynamicFields] = useState({});
    const [restItems, setRestItems] = useState<RestItems>(initialRestItems);
    const [accountName, setAccountName] = useState(data?.name);
    const [selectedTeams, setSelectedTeams] = useState<any>([]);
    const [selectTags, setSelectTags] = useState<any>([]);
    const [tagsData, setTagsData] = useState<any>([]);
    const [resultData, setResultData] = useState<any>([]);
    const [teamAdded, setTeamAdded] = useState<any>([]);

    const [requiredFields, setRequiredFields] = useState<Set<string>>(
        new Set()
    );
    const [errorMessages, setErrorMessages] = useState({});
    const [errors, setErrors] = useState({});
    const [accountInfoStatus, setAccountInfoStatus] = useState("");
    const [isSomethingAdded, setIsSomethingAdded] = useState(false);
    const [isExistingContactModalOpen, setIsExistingContactModalOpen] =
        React.useState<boolean>(false);
    const [isAddContactModalOpen, setIsAddContactModalOpen] =
        React.useState<boolean>(false);
    const [isCustomerHistoryVisible, setIsCustomerHistoryVisible] =
        React.useState(false);
         const [attachments, setAttachments] = useState([]);
            const [files, setFiles] = useState([])

    const [isEditAccountDetailsEnable, setisEditAccountDetailsEnable] =
        useState(
            localStorage.getItem("isEditAccountDetailsEnable") === "true"
                ? true
                : false
        );

    const { data: tags } = useTagsQuery({});
    const { data: teams } = useTeamsQuery({});
    const [trigger] = useUpdateAccountInfoDetailsMutation();
   const [accountAttachmentDeleteTrigger] =  useDeleteAccountsAttachmentMutation()
    const {
        data: accountContactInfo,
        refetch: refetchAccountInfo,
        status,
    } = useGetAccountInfoQuery(id);
    const { data: dynamicData } = useAccountDynamicFieldQuery({});

    useEffect(() => {
        setAccountInfoStatus(status);
    }, [status]);

    const isAdmin = localStorage.getItem("user_role");

    const navigate = useNavigate();

    useEffect(() => {
        if (accountContactInfo) {
            setResultData(accountContactInfo?.results);
        }
    }, [accountContactInfo]);

    useEffect(() => {
        if (data) {
            setAccountName(data?.name);
        }
    }, [data]);

    useEffect(() => {
        if (data?.dynamic_fields) {
            setDynamicFields(data?.dynamic_fields);
            setInitialDynamicFields(data.dynamic_fields);
        }
    }, [data?.dynamic_fields]);

    useEffect(() => {
        if (data) {
            setRestItems(data as RestItems);
        }
    }, [data]);

    useEffect(() => {
        if (tags) {
            setTagsData(tags);
        }
    }, [tags]);

    useEffect(() => {
        if (teams) {
            setSelectedTeams(teams);
        }
    }, [teams]);

    useEffect(() => {
        if (dynamicData) {
            const requiredFieldLabels = dynamicData
                .filter((field) => field.is_required)
                .map((field) => field.label);
            setRequiredFields(new Set(requiredFieldLabels));
        }
    }, [dynamicData]);

    const handleInputChange = (key: any, value: any) => {
        setIsSomethingAdded(true);
        setErrors({});
        setDynamicFields((prevState) => ({
            ...prevState,
            [key]: value,
        }));

        setErrorMessages((prevState) => ({
            ...prevState,
            [key]: "",
        }));
    };

    const handleDropdownChange = (key, value) => {
        setIsSomethingAdded(true);

        setErrors({});
        setDynamicFields((prevFields) => ({
            ...prevFields,
            [key]: value,
        }));
    };

    const handleTeamClick = (teamDetails: Team) => {
        setIsSomethingAdded(true);

        setRestItems((prevState) => {
            const teamExists = prevState.accessible_teams.some(
                (team) => team.id === teamDetails.id
            );

            if (teamExists) {
                return {
                    ...prevState,
                    accessible_teams: prevState.accessible_teams.filter(
                        (team) => team.id !== teamDetails.id
                    ),
                };
            } else {
                return {
                    ...prevState,
                    accessible_teams: [
                        ...prevState.accessible_teams,
                        teamDetails,
                    ],
                };
            }
        });
    };

    const tagSelectHandler = (tags: any) => {
        setIsSomethingAdded(true);

        setRestItems((prevState) => {
            const tagExists = prevState.tags.some((tag) => tag.id === tags.id);

            if (tagExists) {
                return {
                    ...prevState,
                    tags: [],
                };
            } else {
                const newTag: Tag = { id: tags.id, name: tags.name };
                return {
                    ...prevState,
                    tags: [newTag],
                };
            }
        });
    };

    const initialTeamIds = restItems?.accessible_teams?.map((team) => team.id);
    const mergedTeams = Array.from(new Set([...initialTeamIds, ...teamAdded]));

    const initialTagIds = restItems?.tags?.map((tags) => tags.id);
    const mergedTags = Array.from(new Set([...initialTagIds, ...selectTags]));

    const validateForm = () => {
        const newErrors: ErrorState = {};

        dynamicData.forEach((field: any) => {
            const fieldValue = dynamicFields[field.label];

            if (field.is_required && !fieldValue) {
                newErrors[field.label] = `${field.label} is required`;
            }
            if (
                field?.is_required &&
                field.field_type === "check-box" &&
                !fieldValue
            ) {
                newErrors[field.label] = `${field.label} is required`;
            }

            if (
                field.is_required &&
                field.field_type === "tel-phone" &&
                fieldValue
            ) {
                const isValidPhone =
                    /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
                        fieldValue
                    );
                if (!isValidPhone) {
                    newErrors[
                        field.label
                    ] = `${field.label} must be a valid phone number`;
                }
            }

            if (field?.field_type === "email" && field.is_required) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(dynamicFields[field.label])) {
                    newErrors[field.label] = "Invalid email format";
                }
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const saveButtonHandler = async (e) => {
        e.preventDefault();
        const isFormValid = validateForm();
        let isEmailValid = true;
        let emailError = "";

        const emailFieldLabel = "email";
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailValue = dynamicFields[emailFieldLabel]?.trim();

        if (emailValue && !emailPattern.test(emailValue)) {
            isEmailValid = false;
            emailError = "Invalid email address";
        }

        const errors = {};

        if (!isFormValid || !isEmailValid) {
            toast.error(emailError || "Please fill all required fields.");
            return;
        }

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }

        let editedFields = Object.keys(dynamicFields).reduce((acc, key) => {
            const field = dynamicData.find((field) => field.label === key);

            const isFieldEditableForRole =
                isAdmin === "admin" || field?.is_editable;
            const initialFieldValue = initialDynamicFields[key];
            let currentFieldValue = dynamicFields[key];

            if (
                isFieldEditableForRole &&
                currentFieldValue !== initialFieldValue
            ) {
                if (field?.field_type === "date") {
                    const formattedDate = new Date(currentFieldValue)
                        .toISOString()
                        .replace("Z", "+00:00");
                    acc[key] = formattedDate;
                } else {
                    acc[key] = currentFieldValue;
                }
            }

            return acc;
        }, {});

        const contactInfoObj = {
            accessible_teams: mergedTeams,
            dynamic_fields: editedFields,
            name: accountName,
            tags: mergedTags,
            attachments: attachments?.filter((att) => !att.id),

        };

        try {
            const response = await trigger({ body: contactInfoObj, id: id });
            if (response?.data) {
                toast.success("Edited Successfully");

                // Reset `editedFields` after successful response
                editedFields = {};
                refetch();
                setFiles([])

                setIsSomethingAdded(false);
            } else if (response && "error" in response) {
                const errorData = response.error as any;
                toast.error(errorData?.data?.dynamic_fields?.email[0]);
            }
        } catch (error) {
            // Handle error
        }
    };

    const capitalizeFirstLetter = (string: any) => {
        return (
            string &&
            string?.charAt &&
            string?.charAt(0)?.toUpperCase() + string.slice(1)
        );
    };

    const viewMoreHandler = (results) => {
        navigate(`/account/info/${results.id}`);
    };

    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (id) => {
        setHoveredCard(id);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const getDataTimeFormatted = (date) => {
        if (date && date.length > 0) {
            const dateObj = new Date(date);

            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(dateObj.getUTCDate()).padStart(2, "0");
            const hours = String(dateObj.getUTCHours()).padStart(2, "0");
            const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

            return `${year}-${month}-${day}T${hours}:${minutes}`;
        }
        return "";
    };

    // Function to get current date-time formatted as 'YYYY-MM-DDTHH:mm'
    const getCurrentDateTimeFormatted = () => {
        const now = new Date();

        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const hours = String(now.getUTCHours()).padStart(2, "0");
        const minutes = String(now.getUTCMinutes()).padStart(2, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const openExistingContactModal = () => {
                setIsExistingContactModalOpen(true);
    };

    const openAddContactModal = () => {
        setIsAddContactModalOpen(true);
    };

    const closeAddContactModal = () => {
        setIsAddContactModalOpen(false);
    };

    const openCustomerHistoryModal = () => {
        // setStoringCustomerHistoryIf(id);

        setIsCustomerHistoryVisible(true);
    };

    const closeCustomerHistoryModal = () => {
        setIsCustomerHistoryVisible(false);
    };

    const closeExistingContactModal = () => {
        setIsExistingContactModalOpen(false);
    };

    const editContactEnable = () => {
        setisEditAccountDetailsEnable(!isEditAccountDetailsEnable);
    };

    const handleBackButtonClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const style2 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "65%",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "1px solid white",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

    const style12 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        overflow: "scroll",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
    };

    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        height: "25%",
        overflow: "hidden",
        bgcolor: "background.paper",
        border: "none",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

     useEffect(() => {
            if (data) {
                setAttachments(data?.attachments);
            }
        }, [data]);

     const handleDelete = async(id) => {

        
             const attId = {
                   attachment_id: id
                 }
                 const response = await accountAttachmentDeleteTrigger(attId)
           
                 if (response?.data) {
                   setAttachments((prevAttachments) =>
                     prevAttachments.filter((attachment) => attachment.id !== id)
                   
                );
                setIsSomethingAdded(true)
                 }
                 else {
                   toast.error("Unable to delete an attachment")
                 }
        };
    
        const handleDeleteFile = (fileToDelete) => () => {
            setAttachments((prevFiles) =>
                prevFiles.filter((file) => file !== fileToDelete)
            );
        };
    
        const handleFileChange = async (event) => {
            setIsSomethingAdded(true)

            const newFiles = Array.from(event.target.files);
            const filePromises = newFiles.map((file: any) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e: any) => {
                        const fileData = {
                            file_name: file.name,
                            file: e.target.result.split(",")[1], // Extracting Base64 part
                        };
                        resolve(fileData);
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                });
            });
    
            try {
                const fileDataArray = await Promise.all(filePromises);
                setAttachments((prevFiles) => [...prevFiles, ...fileDataArray]);
                setFiles((prevFiles) => [...prevFiles, ...fileDataArray])
    
            } catch (error) {
                console.error("Error reading files:", error);
                toast.error(
                    "An error occurred while uploading files. Please try again."
                );
            }
        };

    return (
        <div className="cont" style={{ padding: "20px 10px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    padding: "0 20px",
                }}
            >
                <div className="">
                    <button
                        className="btn btn-link mb-2"
                        onClick={handleBackButtonClick}
                    >
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Back"
                            className="bi bi-arrow-90deg-left"
                            style={{
                                fontSize: "20px",
                                color: "black",
                                cursor: "pointer",
                            }}
                        ></i>
                    </button>
                </div>
                <div className="d-flex">
                    <h3>Edit account</h3>
                </div>
                <span
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <button
                        className="text-hover-primary border-0  me-2"
                        style={{ background: "transparent" }}
                        onClick={openExistingContactModal}
                    >
                        <i
                            data-placement="top"
                            title="Add existing contact"
                            className="bi bi-person-lock text-dark fs-4"
                            style={{ cursor: "pointer" }}
                        ></i>
                    </button>

                    <button
                        className="text-hover-primary border-0 me-2"
                        style={{ background: "transparent" }}
                        onClick={openAddContactModal}
                    >
                        <i
                            data-placement="top"
                            title="Add new contact "
                            className="bi bi-person-slash text-dark fs-4"
                            style={{ cursor: "pointer" }}
                        ></i>
                    </button>

                    <button
                        className="text-hover-primary border-0  me-2"
                        style={{ background: "transparent" }}
                        onClick={openCustomerHistoryModal}
                    >
                        <i
                            data-placement="top"
                            title="Account history"
                            className="bi bi-file-earmark-spreadsheet text-dark fs-4"
                            style={{ cursor: "pointer" }}
                        ></i>
                    </button>
                </span>
            </div>

            {/* top */}

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%",
                    padding: "0 20px",
                }}
            >
                <label className="required">Account Name</label>
                <input
                    className="form-control"
                    value={accountName}
                    onChange={(e) => {
                        setAccountName(e.target.value);
                        setIsSomethingAdded(true);
                    }}
                    disabled={!isEditAccountDetailsEnable}
                    style={{ padding: "10px" }}
                />
            </div>

            <div
                style={{
                    gap: "20px",
                    width: "100%",
                    alignItems: "center",
                    padding: "0 20px",
                }}
            >
                {/* Middle */}
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: "40px",
                        alignItems: "baseline",
                    }}
                >
                    {dynamicData &&
                        dynamicData.map((field: any) => {
                            const {
                                id,
                                field_type,
                                label,
                                choices,
                                multiple_choices,
                            } = field;
                            const fieldKey = label;

                            return (
                                <div
                                    key={id}
                                    className="dynamic-field"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <label
                                        className={
                                            requiredFields.has(fieldKey)
                                                ? "required"
                                                : ""
                                        }
                                    >
                                        {capitalizeFirstLetter(fieldKey)}
                                    </label>
                                    {field_type === "drop-down" ? (
                                        <select
                                            disabled={
                                                !isEditAccountDetailsEnable ||
                                                isFieldDisabled(
                                                    field?.is_editable
                                                )
                                            }
                                            className="form-control"
                                            value={
                                                typeof dynamicFields[
                                                    fieldKey
                                                ] === "string" ||
                                                typeof dynamicFields[
                                                    fieldKey
                                                ] === "number"
                                                    ? (dynamicFields[
                                                          fieldKey
                                                      ] as string | number) // Type assertion
                                                    : "" // Default value
                                            }
                                            onChange={(e) =>
                                                handleDropdownChange(
                                                    fieldKey,
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" selected>
                                                Select an option
                                            </option>
                                            {choices?.map((choice: any) => (
                                                <option
                                                    key={choice.id}
                                                    value={choice.choice}
                                                >
                                                    {choice.choice}
                                                </option>
                                            ))}
                                        </select>
                                    ) : field_type === "tel-phone" ? (
                                        <div
                                            className="telephone-field"
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                            }}
                                        >
                                            <PhoneInput
                                                disabled={
                                                    !isEditAccountDetailsEnable ||
                                                    isFieldDisabled(
                                                        field?.is_editable
                                                    )
                                                }
                                                style={{
                                                    width: "100%",
                                                }}
                                                defaultCountry="IN"
                                                value={
                                                    dynamicFields[label] || "+91"
                                                }
                                                onChange={(phone: string) =>
                                                    handleInputChange(
                                                        label,
                                                        phone
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : field_type === "check-box" ? (
                                        <FormControl sx={{ width: "100%" }}>
                                            <Select
                                                style={{
                                                    background:
                                                        !isEditAccountDetailsEnable
                                                            ? "transparent"
                                                            : "white",
                                                }}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                disabled={
                                                    !isEditAccountDetailsEnable ||
                                                    isFieldDisabled(
                                                        field?.is_editable
                                                    )
                                                }
                                                multiple
                                                value={
                                                    Array.isArray(
                                                        dynamicFields[label]
                                                    )
                                                        ? (dynamicFields[
                                                              label
                                                          ] as string[])
                                                        : []
                                                }
                                                onChange={(e) => {
                                                    const value = e.target
                                                        .value as string[];
                                                    handleInputChange(
                                                        label,
                                                        value
                                                    );
                                                }}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) =>
                                                    (selected as string[]).join(
                                                        ", "
                                                    )
                                                }
                                            >
                                                {multiple_choices?.map(
                                                    (choice: any) => (
                                                        <MenuItem
                                                            key={choice?.id}
                                                            value={
                                                                choice?.choice
                                                            }
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    Array.isArray(
                                                                        dynamicFields[
                                                                            label
                                                                        ]
                                                                    )
                                                                        ? (
                                                                              dynamicFields[
                                                                                  label
                                                                              ] as string[]
                                                                          ).includes(
                                                                              choice?.choice
                                                                          )
                                                                        : false
                                                                }
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    choice?.choice
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    ) : field_type === "text-area" ? (
                                        <textarea
                                            className="form-control"
                                            disabled={
                                                !isEditAccountDetailsEnable ||
                                                isFieldDisabled(
                                                    field?.is_editable
                                                )
                                            }
                                            value={
                                                dynamicFields[field.label] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Enter text here"
                                            rows={2} // Adjust the number of rows as needed
                                        />
                                    ) : field_type === "date-time" ? (
                                        <input
                                            disabled={
                                                !isEditAccountDetailsEnable ||
                                                isFieldDisabled(
                                                    field?.is_editable
                                                )
                                            }
                                            className="form-control"
                                            max={getCurrentDateTimeFormatted()} // Restrict to current date-time
                                            value={
                                                getDataTimeFormatted(
                                                    dynamicFields[field.label]
                                                ) || ""
                                            }
                                            type="datetime-local"
                                            placeholder=""
                                            onChange={(e) =>
                                                handleInputChange(
                                                    fieldKey,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    ) : field_type === "date" ? (
                                        <input
                                            disabled={
                                                !isEditAccountDetailsEnable ||
                                                isFieldDisabled(
                                                    field?.is_editable
                                                )
                                            }
                                            className="form-control"
                                            max={getCurrentDateTimeFormatted()} // Restrict to current date-time
                                            value={
                                                getDataTimeFormatted(
                                                    dynamicFields[field.label]
                                                ) || ""
                                            }
                                            type="datetime-local"
                                            placeholder=""
                                            onChange={(e) =>
                                                handleInputChange(
                                                    fieldKey,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    ) : (
                                        <input
                                            className="form-control"
                                            disabled={
                                                !isEditAccountDetailsEnable ||
                                                isFieldDisabled(
                                                    field?.is_editable
                                                )
                                            }
                                            type={
                                                field?.is_masked
                                                    ? "text"
                                                    : field_type === "time"
                                                    ? "time"
                                                    : field_type === "email"
                                                    ? "email"
                                                    : field_type === "number"
                                                    ? "number"
                                                    : "text"
                                            }
                                            placeholder={
                                                field_type === "email"
                                                    ? "Enter your email"
                                                    : field_type === "number"
                                                    ? "Number"
                                                    : ""
                                            }
                                            style={{ padding: "10px" }}
                                            value={(() => {
                                                const fieldValue =
                                                    dynamicFields[fieldKey];

                                                if (
                                                    field_type === "date" &&
                                                    fieldValue instanceof Date
                                                ) {
                                                    return fieldValue
                                                        .toISOString()
                                                        .slice(0, 10);
                                                }
                                                if (
                                                    field_type === "time" &&
                                                    fieldValue instanceof Date
                                                ) {
                                                    return fieldValue
                                                        .toTimeString()
                                                        .slice(0, 5);
                                                }

                                                return typeof fieldValue ===
                                                    "string" ||
                                                    typeof fieldValue ===
                                                        "number"
                                                    ? String(fieldValue)
                                                    : "";
                                            })()}
                                            onChange={(e) => {
                                                handleInputChange(
                                                    fieldKey,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    )}

                                    {errors[fieldKey] && (
                                        <div style={{ color: "red" }}>
                                            {errors[fieldKey]}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>

            <div className="tags_teams-combo">
                {tagsData?.length > 0 ? (
                    <div
                        className="tags_cont"
                        style={{ padding: "0 20px 20px 20px" }}
                    >
                        <h3 className="heading">Tags</h3>
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                overflow: "scroll",
                            }}
                            // rgb(126 130 133);
                        >
                            {tagsData?.map((tags: any) => (
                                <p
                                    onClick={() => tagSelectHandler(tags)}
                                    key={tags?.id}
                                    style={{
                                        borderRadius: "50px",
                                        padding: "7px 15px",
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                        background:
                                            restItems?.tags.find(
                                                (t) => t.id === tags.id
                                            ) || selectTags.includes(tags.id)
                                                ? "rgb(141 146 149)"
                                                : "white",
                                        border:
                                            restItems?.tags.find(
                                                (t) => t?.id === tags?.id
                                            ) || selectTags?.includes(teams?.id)
                                                ? "none"
                                                : "1px solid rgb(211 211 211)",
                                        color:
                                            restItems?.tags.find(
                                                (t) => t.id === tags.id
                                            ) || selectTags.includes(tags.id)
                                                ? "white"
                                                : "black",
                                    }}
                                >
                                    {capitalizeFirstLetter(tags?.name)}
                                </p>
                            ))}
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {selectedTeams?.length > 0 ? (
                    <div className="teams_cont" style={{ padding: "0 20px" }}>
                        <h3 className="heading">Teams</h3>
                        <div
                            className="teams_name"
                            style={{ flexWrap: "wrap" }}
                        >
                            {selectedTeams?.map((teams) => (
                                <p
                                    onClick={() => handleTeamClick(teams)}
                                    key={teams.id}
                                    style={{
                                        cursor: "pointer",
                                        borderRadius: "50px",
                                        padding: "7px 15px",
                                        whiteSpace: "nowrap",
                                        background:
                                            restItems?.accessible_teams?.find(
                                                (t) => t.id === teams.id
                                            ) || teamAdded.includes(teams.id)
                                                ? "rgb(141 146 149)"
                                                : "white",
                                        border:
                                            restItems?.accessible_teams?.find(
                                                (t) => t?.id === teams?.id
                                            ) || teamAdded.includes(teams.id)
                                                ? "none"
                                                : "1px solid rgb(211 211 211)",
                                        color:
                                            restItems?.accessible_teams.find(
                                                (t) => t.id === teams.id
                                            ) || teamAdded.includes(teams.id)
                                                ? "white"
                                                : "black",
                                    }}
                                >
                                    {capitalizeFirstLetter(teams?.name)}
                                </p>
                            ))}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
{/* 
            <AttachmentList attachments={attachments} onDelete={handleDelete}/>

            <div className="col-xl-6" style={{padding: "0 10px", }}>
                       
                        <div className="mt-2">
                            <Button
                             
                                component="label"
                                className="text-primary"
                                startIcon={<AttachFileIcon />}
                            >
                                Add attachment
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </Button>
                            <Tooltip
                                title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
                                arrow
                            >
                                <IconButton
                                    aria-label="info"
                                    style={{
                                        verticalAlign: "middle",
                                        marginLeft: "11px",
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div> */}

                    {files.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                mt: 2,
                            }}
                        >
                            {files?.map((file, index) => (
                                <Chip
                                    key={index}
                                    label={file.file_name}
                                    onDelete={handleDeleteFile(file)}
                                />
                            ))}
                        </Box>
                    )}


            <div className="save_button">
                <button
                    className="btn btn-primary"
                    onClick={saveButtonHandler}
                    disabled={!isSomethingAdded}
                >
                    Save
                </button>
            </div>
            {accountInfoStatus === "pending" ? (
                <LoadingSpinner />
            ) : (
                <>
                    {resultData?.length > 0 && (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <h3>Contacts</h3>
                            </div>

                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                        "repeat(4, minmax(0, 1fr))",
                                    gap: "40px",
                                    padding: "20px",
                                    overflow: "scroll",
                                }}
                            >
                                {resultData?.map((results) => {
                                    const isHovered =
                                        hoveredCard === results.id;

                                    return (
                                        <div
                                            style={{
                                                borderRadius: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",

                                                boxShadow: isHovered
                                                    ? "0 4px 12px rgba(0, 0, 0, 0.2)"
                                                    : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                transform: isHovered
                                                    ? "scale(1.02)"
                                                    : "scale(1)",
                                                transition:
                                                    "transform 0.3s ease, box-shadow 0.3s ease",
                                            }}
                                            onMouseEnter={() =>
                                                handleMouseEnter(results.id)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <p
                                                style={{
                                                    padding: "10px",
                                                    textAlign: "center",
                                                    background:
                                                        "rgb(178 183 185)",
                                                    color: "white",
                                                    margin: "0",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    borderTopRightRadius:
                                                        "10px",
                                                    borderTopLeftRadius: "10px",
                                                }}
                                            >
                                                {results?.name}
                                            </p>
                                            <p
                                                style={{
                                                    margin: "0",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: " 5px 10px",
                                                    borderBottom:
                                                        "1px solid #d9d9d9",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        padding: "10px 0px",
                                                    }}
                                                >
                                                    Name
                                                </span>
                                                <span>{results?.name}</span>
                                            </p>
                                            <p
                                                style={{
                                                    margin: "0",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: " 5px 10px",
                                                    borderBottom:
                                                        "1px solid #d9d9d9",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        padding: "10px 0px",
                                                    }}
                                                >
                                                    Email
                                                </span>
                                                <span>{results?.email}</span>
                                            </p>
                                            <button
                                                onClick={() =>
                                                    viewMoreHandler(results)
                                                }
                                                className="btn btn-secondary"
                                                style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                    background: "transparent",
                                                    color: "rgb(178 183 185)",
                                                 
                                                }}
                                                onMouseEnter={(e) =>
                                                    (e.currentTarget.style.color =
                                                        "black")
                                                }
                                                onMouseLeave={(e) =>
                                                    (e.currentTarget.style.color =
                                                        "rgb(178 183 185)")
                                                }
                                            >
                                                View more
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </>
            )}

            <Modal
                open={isAddContactModalOpen}
                onClose={closeAddContactModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style12, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeAddContactModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                        }}
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        Contact
                    </Typography>

                    <Box>
                        <ContactModal
                            closeModal={closeAddContactModal}
                            refetchingData={refetch}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isCustomerHistoryVisible}
                onClose={closeCustomerHistoryModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style2, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeCustomerHistoryModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                        }}
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        Customer History
                    </Typography>

                    <Box>
                        <CustomerHistory />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isExistingContactModalOpen}
                onClose={closeExistingContactModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style1, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeExistingContactModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                        }}
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        Add Contact
                    </Typography>

                    <Box>
                        <AddExistingContact
                            closeModal={closeExistingContactModal}
                            existingContactData={id}
                            refetch={refetchAccountInfo}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default AccountInfo;
