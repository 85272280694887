import React from "react";
import { CloseButton } from "react-bootstrap";

interface IPROPS {
    closeStatusModal: any;
    creatingNewContactDetails: any;
    closeStatusModall: any;
    refetch: any;
    handleStatusUpdate: any;
}

const AddingLeadIntoContact: React.FC<IPROPS> = ({
    closeStatusModal,
    creatingNewContactDetails,
    closeStatusModall,
    refetch,
    handleStatusUpdate,
}) => {
    const handleSubmit = () => {
        creatingNewContactDetails();
        handleStatusUpdate();
        refetch();
    };

    return (
        <div style={{ height: "100%" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                    }}
                >
                    <CloseButton onClick={closeStatusModal} />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <label style={{ fontSize: "20px" }}>
                            Do you want to add this lead into contact?
                        </label>
                    </div>
                    <div className="d-flex justify-content-end mt-10">
                        <button
                            type="reset"
                            className="btn btn-sm btn-white me-3"
                              onClick={closeStatusModal}
                            //   disabled={isLoading}
                        >
                            Cancel
                        </button>

                        <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            onClick={handleSubmit}
                            //   disabled={isLoading}
                        >
                            Submit
                            {/* {isLoading ? "Updating..." : "Update"} */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddingLeadIntoContact;
