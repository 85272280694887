import React, { useEffect, useRef, useState } from "react";
import { CardContent, Checkbox, ListItemText, OutlinedInput, styled } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { useGetIntervelWiseInboundCallsReportQuery, useGetAllQueueQuery } from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";
import { set } from "lodash";
import { MultiSelect } from "react-multi-select-component";
const InboundCallDetailReport = () => {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState<string>("");
  const [params, setParams] = useState<any>(null);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const { data: queueData, isLoading: queueLoading, } = useGetAllQueueQuery({});
  const [selectedQueues, setSelectedQueues] = useState<any>([]);
  const [options, setOptions] = useState<any>([])
  const [intervalValue, setIntervalValue] = useState("15")
  const [isAllSelected, setIsAllSelected] = useState(false)
  const {
    data: intervalWiseInboundCallsReport,
    isLoading: intervalWiseInboundCallsReportLoading,
    error: intervalWiseInboundCallsReportError
  } = useGetIntervelWiseInboundCallsReportQuery(params, {
    skip: !params,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });



  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const columns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr.No",
      minWidth:140 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "queue_name",
      headerName: "Queue Name",
      minWidth:140 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "interval",
      headerName: "Interval",
      minWidth:140 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "calls_offered",
      headerName: "Calls Offered",
      minWidth:150 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unique_offered",
      headerName: "Unique Offered",
      minWidth:150 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "answered",
      headerName: "Answered",
      minWidth:140 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unique_answered",
      headerName: "Unique Answered",
      minWidth:150 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "not_answered",
      headerName: "Not Answered",
      minWidth:150 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unique_not_answered",
      headerName: "Unique Not Answered",
      minWidth:180 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandoned_within_30_seconds",
      headerName: "Abandoned Within 30 Seconds Of Wait Time ",
      minWidth:286,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandoned_within_10_seconds",
      headerName: "Abandoned Within 10 Seconds Of Wait Time",
      minWidth:286 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandoned_within_15_seconds",
      headerName: "Abandoned Within 15 Seconds Of Wait Time",
      minWidth:286 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandoned_within_60_seconds",
      headerName: "Abandoned Within 60 Seconds Of Wait Time ",
      minWidth:286,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandoned_percent",
      headerName: "Abandoned %",
      minWidth:140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "answered_percent",
      headerName: "Answered %",
      minWidth:180 ,
      align: "center",
      headerAlign: "center",
    },
 
    {
      field: "final_abandoned_count",
      headerName: "Final Abandoned Count",
      minWidth:180 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "abandonedpercent",
      headerName: "Final Abd %",
      minWidth:180 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "finalpercent",
      headerName: "Final Answered %",
      minWidth:180 ,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sl_percent",
      headerName: "SL%",
      minWidth:140 ,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "slex_percent",
    //   headerName: "Service Level(Excluding Abandoned)",
    //   align: "center",
    //   headerAlign: "center",
    // },
  ];






  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  useEffect(() => {
    if (queueData) {
      console.log(queueData,"queueData")
      // Transform queueData to match the expected format
      const transformedOptions = queueData.map(item => ({
        label: item.name, // Use 'name' for the display text
        value: item.id    // Use 'id' as the unique identifier
      }));

      setOptions(transformedOptions);
    }
  }, [queueData]);

  useEffect(() => {
    console.log(intervalWiseInboundCallsReportError);

    if (intervalWiseInboundCallsReportError) {
      // Check if the error is of type FetchBaseQueryError
      if ('data' in intervalWiseInboundCallsReportError) {
        const errorMessage = intervalWiseInboundCallsReportError.data?.[0] || "An unexpected error occurred.";
        toast.error(errorMessage);
        setLocalLoading(false);
      } else {
        // If the error is a SerializedError or other, show a generic error
        toast.error("An unexpected error occurred.");
        setLocalLoading(false);
      }
    }
  }, [intervalWiseInboundCallsReportError]);





  const handleSubmit = (e) => {
    e.preventDefault();

    if (!dateFrom) {
      toast.error('Please select a date');
      return;
    }

    if (selectedQueues.length === 0) {
      toast.error('Please select a queue');
      return;
    }

    // Map the selectedQueues to extract the `label` values
    // const selectedQueueLabels = selectedQueues.map((queue) => queue.label);
    const selectedQueueLabels = selectedQueues.map((queueId) => {
      const queue = options.find((q) => q.value === queueId);
      return queue ? queue.label : null;
    });
    const queryParams = {
      gte: `${dateFrom}`, // Combine dateFrom with the start of the day
      lte: `${dateFrom}`, // Combine dateFrom with the end of the day
      interval: Number(intervalValue), // Ensure intervalValue is properly converted to a number
      queuename: selectedQueueLabels, // Use the extracted queue labels
    };
    console.log('Query Params:', queryParams);
    setParams(queryParams);


  };



  useEffect(() => {

    if (intervalWiseInboundCallsReport === undefined) {
      setLocalLoading(false);
    } else if (Array.isArray(intervalWiseInboundCallsReport)) {
      const transformedRows = intervalWiseInboundCallsReport.map((item, index) => {
        return {
          id: index + 1,
          sr_no: index + 1, // Sr.No (Index-based, starting from 1)
          queue_name: item.queue_name.join(', '), // Assuming queue_name is an array
          interval: item.interval,
          calls_offered: item.calls_offered,
          unique_offered: item.unique_offered,
          answered: item.answered,
          unique_answered: item.unique_answered,
          not_answered: item.not_answered,
          unique_not_answered: item.unique_not_answered,
          abandoned_within_30_seconds: item.abandoned_within_30_seconds,
          abandoned_within_10_seconds: item.abandoned_within_10_seconds,
          abandoned_within_15_seconds: item.abandoned_within_15_seconds,
          abandoned_within_60_seconds: item.abandoned_within_60_seconds,
          
          abandoned_percent: item.abandoned_percent,
          answered_percent: item.answered_percent,
          final_abandoned_count:item.final_abandoned_count,
          finalpercent:item.answered_percent,
          abandonedpercent:item.abandoned_percent,
          sl_percent: item.sl_percent,
          slex_percent: item.slex_percent
        };

      });

      setDataRows(transformedRows);
    } else {
      setDataRows([]); // Reset if data is not an array
    }

    setLocalLoading(false);
  }, [intervalWiseInboundCallsReport]);



  const downloaderRef = useRef(null);

  const resetFilters = () => {
    setDateFrom("");

    setParams(null);
    setSelectedQueues([]);
  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };


  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };


  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  const handleQueueChange = (event) => {
    const { target: { value } } = event;
    if (value.includes("all")) {
      setIsAllSelected(!isAllSelected);
      setSelectedQueues(isAllSelected ? [] : options.map(option => option.value));
    } else {
      setSelectedQueues(value);
      setIsAllSelected(value.length === options.length);
    }
  };
  
  const CustomMenu = styled('div')({
    backgroundColor: '#f0f0f0', // Set your desired background color here
    maxHeight: '400px', // Set the desired height here
  });
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* Back button */}
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/telephony/reports-list");
            }}
          /> */}
          <button
              className="btn btn-link mb-2"
              onClick={() => {
                navigate("/reports/telephony/reports-list");
              }}
              style={{marginRight:"-17px"}}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            {" "}
            Interval-Wise Inbound Calls
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Interval-select-label">Interval</InputLabel>
              <Select
                labelId="Interval-select-label"
                id="Interval-select"
                label="Interval"
                value={intervalValue}
                 onChange={(e) => setIntervalValue(e.target.value)}
              >
                <MenuItem value="15">15</MenuItem>
                <MenuItem value="30">30</MenuItem>
                <MenuItem value="60">60</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* <div className="w-100 mw-150px">
            <MultiSelect
              options={options}
              value={selectedQueues}
              onChange={setSelectedQueues}
              labelledBy="Queue"
            />
          </div> */}
<div className="w-100 mw-150px">
  <FormControl fullWidth>
    <InputLabel id="queue-select-label">Select Queues</InputLabel>
    <Select
      labelId="queue-select-label"
      id="queue-select"
      multiple
      value={selectedQueues}
      onChange={handleQueueChange}
      input={<OutlinedInput label="Select Queues" />}
      renderValue={(selected) => {
        if (isAllSelected) {
          return <div>All</div>;
        }
        return (
          <div>
            {selected
              .map((queueId) => {
                const queue = options.find((q) => q.value === queueId);
                return queue ? queue.label : null;
              })
              .join(", ")}
          </div>
        );
      }}
      MenuProps={MenuProps}
    >
      <MenuItem
        value="all"
        onClick={() => setIsAllSelected(!isAllSelected)}
      >
        <Checkbox checked={isAllSelected} indeterminate={selectedQueues.length > 0 && !isAllSelected} />
        <ListItemText primary="All" />
      </MenuItem>
      {options.map((queue) => (
        <MenuItem key={queue.value} value={queue.value}>
          <Checkbox checked={selectedQueues.indexOf(queue.value) > -1} />
          <ListItemText primary={queue.label} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</div>


          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>


            {/* CsvDownloader component outside the button */}
            <button className="btn btn-primary">
              <CsvDownloader
                ref={downloaderRef}
                filename={`imterval-wise-inbound-report`}
                datas={dataRows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}

              >Download</CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>
        <CardContent style={{ width: "100%" }}>
          {intervalWiseInboundCallsReportLoading || localLoading || queueLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={
                columns
              }
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              // pageSizeOptions={[5, 10, 15, 20,25,30,35,40,45]}
              pageSizeOptions={[10,20,30,40,50,60,70,80,90,100]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>

      </div>
    </div>
  );
};
export default InboundCallDetailReport;
