import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import touch from "../../assets/images/touch_app-24px.jpg";
import QuickReply from "../../assets/images/quickreply-24px.jpg";
import LoadingSpinner from "../../components/LoadingSpinner";
import { format } from "date-fns"; // Import format from date-fns
import {
  Tabs,
  Tab,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  Tooltip,
} from "@mui/material";
import {
  useChangeStatusQuery,
  useMeeQuery,
} from "../../Services/settingsAPIs/CommonAPI";
import { Dropdown } from "react-bootstrap";
import ChangeStatusModal from "../../components/ticket/ChangeStatusModal";
import ReactModal from "react-modal";
import { useAuditTrailQuery } from "../../Services/ticket/Audit-Trail";

interface IProps {
  ticket: any;
  currentPage: any;
  setCurrentPage: any;
  selectedTickets: any;
  setSelectedTickets: any;
  ticketWorkflow: any;
  value: any;
  setValue: any;
  setStoreKey: any;
  setLiftUiData: any;
  refetch: any;
  storingTicketData: any;
  useData: any;
}

interface Disposition {
  id: string;
}

interface Action {
  disposition: Disposition;
}

interface Ticket {
  action: Action;
  // Other properties...
}

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const TicketListView: React.FC<IProps> = ({
  ticket,
  currentPage,
  setCurrentPage,
  selectedTickets,
  setSelectedTickets,
  ticketWorkflow,
  value,
  setValue,
  setStoreKey,
  setLiftUiData,
  refetch,
  storingTicketData,
  useData,
}) => {
  // State for selected tickets
  const ticketCount = ticket?.count ? ticket?.count : 0;
  const [pageSize] = useState<number>(5);
  const [selectAll, setSelectAll] = useState(false);
  const params = useParams();
  const uuid = params.uuid;

  const { data: auditTrailData, refetch: refetchAuditTrailData } =
    useAuditTrailQuery(uuid, {
      skip: !uuid,
    });

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [originalStatus, setOriginalStatus] = useState("");
  const [selectedAllStatus, setAllSelectedStatus] = useState("");
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const [storingTicketId, setStoringTicketId] = useState("");
  const [storingDispositionId, setStoringDispositionId] = useState("");

  const { data: ticketData, refetch: refetchAuditTrail } = useChangeStatusQuery(
    ticket?.results?.[0]?.action?.disposition?.id,
    {
      skip: !ticket?.results?.[0]?.action?.disposition?.id,
    }
  );

  const [tabLoading, setTabLoading] = useState<boolean>(false); // New state variable to track tab loading

  const openStatusModal = () => {
    setOriginalStatus(selectedStatus);
    setStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setSelectedStatus(originalStatus);
    setStatusModalOpen(false);
  };

  const handlerStatus = (allStatus) => {
    setAllSelectedStatus(allStatus);
    setSelectedStatus(allStatus.status_type);
    openStatusModal();
  };

  const formatStatusData = (status) => {
    if (typeof status.status === "string") {
      // Open and Reopen statuses
      return {
        status_type: status.status_type,
        status: status.status,
      };
    } else {
      // WIP and Closed statuses
      return {
        id: status?.status?.id,
        status_type: status?.status_type,
        status: status?.status?.status,
      };
    }
  };

  const statusOptions = ticketData?.all_statuses?.map((status) =>
    formatStatusData(status)
  );

  useEffect(() => {
    const defaultSelections = ticket?.results?.reduce(
      (acc: any, status: any) => {
        acc[status?.id] = status?.status_display || ""; // Assuming 'id' and 'status_display' exist
        return acc;
      },
      {}
    );

    setSelectedStatus(defaultSelections || {});
  }, [ticket]);

  const handleTicketSelect = (ticketId: string) => {
    setSelectedTickets((prevSelectedTickets) => {
      const isSelected = prevSelectedTickets.includes(ticketId);
      if (isSelected) {
        // Unselect the ticket
        const updatedSelection = prevSelectedTickets.filter(
          (id) => id !== ticketId
        );
        return updatedSelection;
      } else {
        // Select the ticket
        const updatedSelection = [...prevSelectedTickets, ticketId];

        return updatedSelection;
      }
    });
  };

  const totalPages = Math.ceil(ticketCount / pageSize);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5;

    if (totalPages <= maxVisibleNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };



  useEffect(() => {
    setLoading(true); // Set loading to true when component mounts or updates
    setTimeout(() => {
      setLoading(false); // Simulate loading completion after a delay (replace with actual data fetching)
    }, 3000);
  }, [currentPage]);

  // Format the created date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy HH:mm:ss");
  };

  const getStatusStyle = (status_type: string) => {
    switch (status_type) {
      case "active":
        return { backgroundColor: "#56d85b", color: "white" }; // $green-300
      case "closed":
        return { backgroundColor: "#90dcf9", color: "white" }; // $cyan-300
      case "reopen":
        return { backgroundColor: "#f44336", color: "white" }; // $red-200
      case "wip":
        return { backgroundColor: "#ffeb3b", color: "black" }; // $yellow-200
      default:
        return { backgroundColor: "#6c757d", color: "white" }; // Default color
    }
  };

  const capitalizeFirstLetter = (string: string | undefined | null) => {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };

  const [uiData, setUiData] = useState([]);
  const [keyState, setKeyState] = useState([]);

  const processData = async () => {
    const uiFormattedData = [];
    const keysForState = [];

    // Ensure ticketWorkflow is defined and not null before proceeding
    if (ticketWorkflow && typeof ticketWorkflow === "object") {
      await Object.entries(ticketWorkflow)?.forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Handle objects like { Open: 8 }
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            uiFormattedData.push({
              key: nestedKey,
              value: nestedValue,
            });
            keysForState.push(key);
          });
        } else if (Array.isArray(value)) {
          // Handle arrays of objects [{ Closed: 1 }, { ss: 0 }]
          value.forEach((item) => {
            if (item && typeof item === "object") {
              Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                uiFormattedData.push({
                  key: nestedKey,
                  value: nestedValue,
                });
                keysForState.push(key);
              });
            }
          });
        }
      });

      setUiData(uiFormattedData);
      setKeyState(keysForState);
    } else {
      console.error("ticketWorkflow is undefined or null");
    }
  };

  const handleStatusUpdate = () => {
    // logic to handle status update goes here
    setStatusModalOpen(false);
  };

  useEffect(() => {
    processData();
  }, [ticketWorkflow]);

  useEffect(() => {
    setLiftUiData(uiData);
  }, [uiData]);

  useEffect(() => {
    setTabLoading(true); // Set tab loading to true when the component mounts
    setTimeout(() => {
      setTabLoading(false); // Simulate tab loading completion after a delay (replace with actual data fetching)
    }, 3000);
  }, [value]); // Add value as a dependency to re-run the effect when the tab changes

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const handleStatusChangeeee = (ticketId) => {
    setStoringDispositionId(ticketId?.action?.disposition?.id);
    setStoringTicketId(ticketId?.id);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedTickets(ticket?.results.map((row) => row.id));
    } else {
      setSelectedTickets([]);
    }
  };

  useEffect(() => {
    if (selectedTickets.length === ticket?.results.length && ticket?.results.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedTickets]);

  console.log("selectedTickets", selectedTickets);
  console.log(selectAll)
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5"
            style={{ padding: "0 2.25rem", display: "flex",width:"102%" }}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <Box
              sx={{
                width: "96%",
                background: "#FFFFFF",
                borderRadius: "6px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // subtle shadow for better elevation
                marginLeft: "30px",
              }}
            >
              <Tabs
                value={uiData.findIndex((item) => item.key === value)}
                onChange={(event, newValue) => {
                  setStoreKey(keyState[newValue]);
                  setValue(uiData[newValue].key);
                  setCurrentPage(1);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                sx={{
                  "& .MuiTab-root": {
                    minWidth: 0, // Set minimum width to 0
                    flexGrow: 1, // Add flexGrow to make tabs responsive
                    padding: "12px 24px",
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "12px",
                  },
                  "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root":
                  {
                    display: "none",
                  },
                  "& .MuiTab-root:not(:last-child)": {
                    borderRight: "1px solid #e0e0e0", // Thicker, more pronounced divider
                  },
                  "& .MuiTab-root:hover": {
                    backgroundColor: "#f5f5f5", // Adds a hover effect for better interaction
                  },
                  "& .Mui-selected": {
                    fontWeight: 600, // Highlights selected tab more
                  },
                }}
              >
                {uiData?.map((ticketsInfo, index) => (
                
                 <Tooltip key={index} title={`${ticketsInfo.key} (${ticketsInfo.value})`} arrow>
                  <Tab
                    key={index}
                    label={truncateDescription(
                      `${ticketsInfo.key} (${ticketsInfo.value})`,
                      25
                    )}
                    sx={{ 
                      width: "20%", // Ensure all tabs take full width 
                     maxWidth: "none", // Remove max width restriction 
                     display: "flex", // Ensure flexibility 
                    justifyContent: "center", // Center the text
                     }}
                  />
                  </Tooltip>
                ))}
              </Tabs>
            </Box>
          </div>

          {tabLoading ? (
            <LoadingSpinner />
          ) : (
            <div
              className="card-header align-items-center py-1 my-1 gap-2 gap-md-5 d-flex flex-column flex-md-row mb-5"
              style={{ border: "none" }}
            >
              {ticket?.results?.map((ticket: any) => (
                <div
                  key={ticket?.id}
                  className="d-flex align-items-center w-100 mb-2"
                >
                  <input
                    type="checkbox"
                    checked={selectedTickets.includes(ticket?.id)}
                    onChange={() => handleTicketSelect(ticket?.id)}
                  />

                  <div className="col-xl-12 mx-auto" style={{width:"102%"}}>
                    <Link to={`/ticket/information/${ticket?.id}`}>
                      <div className="card-body">
                        <div className="card mb-n10">
                          <div>
                            <div className="d-flex flex-stack mb-2">
                              <div className="d-flex flex-column w-100">
                                {/* REF ID Section */}
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="p-2 text-gray-800 text-hover-primary fw-bold">
                                      {ticket.id}
                                    </div>
                                    <div className="p-2 fs-3 text-gray-800 text-hover-primary fw-bold">
                                      {ticket.title}
                                    </div>
                                    <i className="fs-2 mx-2 bi bi-telephone"></i>
                                  </div>
                                  {/* end::REF ID Section */}

                                  {/* Conditionally render Add Attachment Icon Button */}
                                  {ticket.attachments?.length > 0 && (
                                    <button className="btn text-dark justify-content-center">
                                      <i
                                        className="bi bi-paperclip fs-1 text-dark"
                                        style={{
                                          transform: "rotate(90deg)",
                                        }}
                                      ></i>
                                    </button>
                                  )}
                                </div>
                                {/* end::Wrapper */}

                                {/* New Line */}
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="p-0"
                                      style={{
                                        ...getStatusStyle(ticket.status_type),
                                        width: "150px",
                                        textAlign: "center",
                                      }}
                                      data-bs-toggle="tooltip"
                                      title={ticket?.status_display}
                                    >
                                      <span className="fw-bold">
                                        {truncateDescription(
                                          ticket.status_display,
                                          10
                                        )}
                                      </span>
                                    </div>

                                    <div
                                      className="text-gray-400 text-hover-primary fw-bold mx-10"
                                      data-bs-toggle="tooltip"
                                      title={ticket?.description}
                                    >
                                      {truncateDescription(
                                        ticket?.description,
                                        120
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card"
                            style={{
                              backgroundColor: "#E4E4E47F",
                            }}
                          >
                            <div className="d-flex flex-wrap">
                              {ticket?.action.thread && (
                                <Link
                                  to={`/email/inbox/${ticket?.action.thread[0]?.id}`}
                                  className="btn-link-wrapper"
                                >
                                  <button
                                    className="btn text-dark rounded-0 justify-content-center w-100 h-100"
                                    style={{
                                      borderRight:
                                        "1px solid rgba(112, 112, 112, 0.5)",
                                    }}
                                    data-bs-toggle="tooltip"
                                    title="Process Email"
                                  >
                                    <i className="text-dark bi-chat-left-fill fs-2"></i>
                                  </button>
                                </Link>
                              )}

                              {!ticket?.action.thread && (
                                <Link
                                  to="/email/compose"
                                  className="btn-link-wrapper"
                                >
                                  <button
                                    className="btn text-dark rounded-0 justify-content-center w-100 h-100"
                                    style={{
                                      borderRight:
                                        "1px solid rgba(112, 112, 112, 0.5)",
                                    }}
                                    data-bs-toggle="tooltip"
                                    title="Compose Email"
                                  >
                                    <i className="text-dark bi bi-pencil-square fs-2"></i>
                                  </button>
                                </Link>
                              )}

                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "150px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Assignee
                                </div>
                                <span className="text-center text-gray-800 text-hover-primary fw-bold">
                                  {capitalizeFirstLetter(
                                    ticket?.action?.assignee?.username.split(
                                      "@"
                                    )[0] ?? "Unassigned"
                                  )}
                                </span>
                              </div>

                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "150px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Customer Name
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {capitalizeFirstLetter(
                                    ticket?.action?.contact.name
                                  )}
                                </span>
                              </div>

                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "150px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Raised By
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {ticket?.created_by?.username}
                                </span>
                              </div>

                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "120px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Priority
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {capitalizeFirstLetter(ticket?.priority)}
                                </span>
                              </div>

                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "120px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Workflow
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {ticket?.action?.disposition?.workflow?.name}
                                </span>
                              </div>
                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "150px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Created At
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {formatDate(ticket?.created)}
                                </span>
                              </div>

                              <div
                                style={{
                                  borderRight:
                                    "1px solid rgba(112, 112, 112, 0.5)",
                                  width: "150px",
                                  textAlign: "center",
                                }}
                              >
                                <div className="text-center text-gray-400 text-hover-primary fw-bold mt-3">
                                  Current Team
                                </div>
                                <span className="text-gray-800 text-hover-primary fw-bold">
                                  {ticket?.team_assignee?.name}
                                </span>
                              </div>
                              {/* Change Status  */}
                              <div
                                className="text-center d-flex align-items-center mx-5"
                                style={{ minWidth: "180px" }}
                              >
                                <FormControl
                                  className="w-100"
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <InputLabel
                                    id="status-select-label"
                                    style={{ zIndex: 0 }}
                                  >
                                    Change Status
                                  </InputLabel>
                                  <Select
                                    labelId={`status-select-label-${ticket.id}`}
                                    id={`status-select-${ticket.id}`}
                                    value={selectedStatus[ticket.id] || ""} // Use the selected status for this ticket
                                    onChange={() =>
                                      handleStatusChangeeee(ticket)
                                    }
                                    label="Change Status"
                                    MenuProps={MenuProps}
                                  >
                                    {statusOptions?.map((status: any) => (
                                      <MenuItem
                                        key={status.id}
                                        value={status.status}
                                        onClick={() => handlerStatus(status)} // Existing click handler
                                        data-bs-toggle="tooltip"
                                        title={status?.status}
                                      >
                                        {truncateDescription(
                                          status?.status,
                                          25
                                        )}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  <ReactModal
                                    isOpen={isStatusModalOpen}
                                    onRequestClose={closeStatusModal}
                                    style={customModalStyles}
                                    contentLabel="Change Status"
                                  >
                                    <ChangeStatusModal
                                      data={ticketData}
                                      closeStatusModal={closeStatusModal}
                                      refetch={refetch}
                                      refetchAuditTrail={refetchAuditTrail}
                                      selectedStatus={selectedStatus}
                                      handleStatusUpdate={handleStatusUpdate}
                                      refetchAuditTrailData={
                                        refetchAuditTrailData
                                      }
                                      selectedAllStatus={selectedAllStatus}
                                      storingTicketId={storingTicketId}
                                      refetchAuditTrailForwardedData={refetchAuditTrailData}
                                    />
                                  </ReactModal>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <div className="row mx-4 pt-2 mb-2">
        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          {/* Display the page count */}
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </div>
        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="kt_table_users_paginate"
          >
            <ul className="pagination">
              {/* Previous button */}
              <li
                className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                  }`}
              >
                <button
                  type="button"
                  aria-controls="kt_table_users"
                  data-dt-idx="0"
                  className="btn primary page-link"
                  onClick={prevPage}
                >
                  <i className="previous"></i>
                </button>
              </li>

              {/* Page numbers */}
              {generatePaginationNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                    }`}
                >
                  <button
                    type="button"
                    aria-controls="kt_table_users"
                    data-dt-idx={pageNumber}
                    className="btn primary page-link"
                    onClick={() => paginate(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              {/* Next button */}
              <li
                className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <button
                  type="button"
                  aria-controls="kt_table_users"
                  data-dt-idx="0"
                  className="btn primary page-link"
                  onClick={nextPage}
                >
                  <i className="next"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketListView;
