import React, { Suspense, lazy } from "react";

import LeadCountCard from "./cards/LeadCountCard";
import PieChart from "./charts/PieChart";
import HighChartFunnel from "./charts/HighChartFunnel";
import CustomDate from "../../../components/common/CustomDate";
import { useEffect, useState } from "react";
import { useGetAllCampaignDashboardDataQuery, useGetAllCampaignDashboardDataSummaryQuery } from "../../../Services/dashboard/dashboard";
import { useGetAllTeamsQuery } from "../../../Services/reports/tickets/ReportTickets";
import { useCampaignTeamsDataQuery } from "../../../Services/campaign/CampaignMapping";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import WhatsappDeliveredLeads from "./charts/WhatsappDeliveredLeads";
import WhatsappReadLeads from "./charts/WhatsappReadLeads";
import LeadsOverview from "./charts/BarChart";
import WhatsappTemplateByLead from "./charts/WhatsappTemplateByLead";
import LeadStatus from "./charts/LeadStatus";
import WhatsappRespondLead from "./charts/WhatsappRespondLead";
import WhatsappDeliveredLead from './charts/WhatsappDeliveredLeads'
// const LeadCountCard = lazy(() => import("./cards/LeadCountCard"));
// const PieChart = lazy(() => import("./charts/PieChart"));
// const LeadsOverview = lazy(() => import("./charts/BarChart"));
// const WhatsappTemplateByLead = lazy(() => import("./charts/WhatsappTemplateByLead"));
// const LeadStatus = lazy(() => import("./charts/LeadStatus"));
// const WhatsappRespondLead = lazy(() => import("./charts/WhatsappRespondLead"));
// const WhatsappDeliveredLead = lazy(() => import('./charts/WhatsappDeliveredLeads'))
// const whatsappReadLead = lazy(() => import('./charts/WhatsappReadLeads'))



const CampaignDashboard = () => {

  const location = useLocation()
  const checkToggleState = location.state


  console.log(checkToggleState, "checkotootoot")


  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [selectedTeam, setSelectedTeam] = useState(localStorage.getItem('teamId'));
  const [theTeams, setTheTeams] = useState([]);
  const [campaignDashboardData, setCampaignDashboardData] = useState<any>([]);
  const [campaignDashboardSummaryData, setCampaignDashboardSummaryData] = useState<any>([]);
  const [params, setParams] = useState<any>({ dashboard_type: 'lead' });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [loading, setLoading] = useState(false);


  const [outwardLeadButton, setOutwardLeadButton] = useState<boolean>(true);



  const { data: teamsData, isLoading: teamsLoading, error } = useGetAllTeamsQuery({});
  const { data: dashboardData, status: campaignDashboardStatus } = useGetAllCampaignDashboardDataQuery(params, {
    skip: !params.team || !params.start_date || !params.end_date || !params.campaign || params.outwardLeadButton === undefined
  });
  const { data: dashboardDataSummary, isLoading, error: CampaignFetchError, status: campaignDashboardStatus1 } = useGetAllCampaignDashboardDataSummaryQuery(params, {
    skip: !params.team || !params.start_date || !params.end_date || params.outwardLeadButton === undefined
  });

  // const { data: workflow } = useTicketWorkflowIdQuery(selectedTeam, { skip: !selectedTeam });
  const { data: workflow } = useCampaignTeamsDataQuery({ selectedTeam, outwardLeadButton }, {
    skip: !selectedTeam,
  });
  const [selectedWorkFlow, setselectedWorkFlow] = useState('');


  useEffect(() => {
    if (teamsData) {
      setTheTeams(teamsData?.teams);
    }
  }, [teamsData]);
  useEffect(() => {
    if (campaignDashboardStatus === "pending" || campaignDashboardStatus1 === "pending") {
      setLoading(true)
    }
    else {
      setLoading(false)
    }

  }, [campaignDashboardStatus, campaignDashboardStatus1])

  useEffect(() => {
    if (dashboardDataSummary) {
      setCampaignDashboardSummaryData(dashboardDataSummary)
    }

  }, [dashboardDataSummary])

  useEffect(() => {
    if (workflow?.results?.length > 0) {
      setselectedWorkFlow(workflow?.results[0]?.id);
      setParams((prevParams) => ({
        ...prevParams,
        campaign: workflow?.results[0]?.id,
      }));
    }
  }, [workflow]);

  useEffect(() => {
    if (dashboardData) {
      setCampaignDashboardData(dashboardData);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (dateRange) {
      const formatToDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      setParams((prevParams) => ({
        ...prevParams,
        start_date: formatToDate(dateRange[0].startDate),
        end_date: formatToDate(dateRange[0].endDate),
      }));
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedTeam) {
      setParams((prevParams) => ({
        ...prevParams,
        team: selectedTeam,
      }));

    }
  }, [selectedTeam]);

  const handleWorkflowChange = (event) => {
    setselectedWorkFlow(event.target.value);
    setParams((prevParams) => ({
      ...prevParams,
      campaign: event.target.value
    }));
  };


  const handleSwitchToggle = () => {
    setOutwardLeadButton(!outwardLeadButton);

  };



  useEffect(() => {
    if (checkToggleState === "outward") {
      setOutwardLeadButton(true)
    }
    else if (checkToggleState === "inward") {

      setOutwardLeadButton(false)
    }
    else {
      setOutwardLeadButton(true)
    }


  }, [checkToggleState])

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      outwardLeadButton,
    }));
  }, [outwardLeadButton]);

  const [issLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  const tabTitles = ['Outbound', 'Inbound'];
  return (
    <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5 fade-in">
      <div id="kt_app_toolbar" className="app-toolbar py-3">
        <div id="kt_app_toolbar_container" className="app-container ms-4 me-4 mb-4 d-flex flex-stack">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h2>{outwardLeadButton ? "Outbound lead dashboard" : "Inbound lead dashboard"}</h2>
          </div>


          <div className="custom-style d-flex align-items-center">

            {/* <div className='d-flex flex-column'>
              <ul
                className='nav nav-stretch nav-line-tabs border-transparent flex-nowrap mb-5'
                style={{ margin: 'auto' }}
              >
                {tabTitles.map((title, index) => (
                  <li className='nav-item' key={title}>
                    <span
                      className={`nav-link cursor-pointer ${outwardLeadButton === (title === 'Outbound') ? 'active fw-bolder' : ''}`}
                      onClick={() => handleSwitchToggle()}
                      role='tab'
                    >
                      {title}
                    </span>
                  </li>
                ))}
              </ul>
            </div> */}
            {/* <div className="btn-group" role="group" aria-label="Lead Type Toggle" style={{ gap: '8px' }}>
              <button
                type="button"
                className={`btn ${outwardLeadButton ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => !outwardLeadButton && handleSwitchToggle()}
              >
                Outbound
              </button>
              <button
                type="button"
                className={`btn ${!outwardLeadButton ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => outwardLeadButton && handleSwitchToggle()}
              >
                Inbound
              </button>
            </div> */}
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-10">
            <div
              className="w-100 mw-250px"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-9px",
                  left: "5px",
                  background: "white",
                }}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "12px",
                  }}
                >
                  Lead type
                </p>
              </div>
              {/*begin::Select2*/}
              <select
                className='form-select'
                value={outwardLeadButton ? 'Outbound' : 'Inbound'}
                onChange={() => handleSwitchToggle()}
              >
                {tabTitles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
              {/*end::Select2*/}
            </div>
            <div className="w-100 mw-250px" style={{ whiteSpace: "nowrap" }}>
              <CustomDate dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <div
              className="w-100 mw-250px"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-9px",
                  left: "5px",
                  background: "white",
                }}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "12px",
                  }}
                >
                  Team
                </p>
              </div>
              {/*begin::Select2*/}
              <select
                className="form-select"
                value={selectedTeam}
                onChange={(e: any) => {
                  setSelectedTeam(e.target.value);
                }}
              >
                <option value="">Select team</option>
                {theTeams.map((team: any) => (
                  <option
                    key={team.id}
                    value={team.id}
                  >
                    {team.name}
                  </option>
                ))}
              </select>
              {/*end::Select2*/}
            </div>

            <div className="w-100 mw-250px" style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "-9px", left: "5px", background: "white" }}>
                <p style={{ margin: "0", fontSize: "12px" }}>Campaign</p>
              </div>
              <select
                className="form-select"
                onChange={handleWorkflowChange}
                value={selectedWorkFlow}
              >
                <option value="" selected>
                  Select Campaign
                </option>
                {workflow?.results?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div className="card-body pt-0">

          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {outwardLeadButton ? (<>
                <section className="section dashboard">
                  <div className="row p-2">
                    {/* Left side columns */}
                    <div className="col-lg-8">
                      <LeadCountCard data={campaignDashboardData} />
                    </div>
                    {/* Right side columns */}
                    <div className="col-lg-4">
                      <PieChart data={campaignDashboardSummaryData} />
                    </div>
                  </div>
                </section>
                <section>
                  <div className="row p-2">
                    <div className="col-lg-6">
                      <LeadsOverview data={campaignDashboardData}  outwardLeadButton={outwardLeadButton}/>
                    </div>
                    {/* Campaign Insight */}
                    <div className="col-lg-6">
                      <WhatsappTemplateByLead data={campaignDashboardData} />
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-lg-6">
                      <LeadStatus data={campaignDashboardData} />

                    </div>
                    {/* Customer engagement */}
                    <div className="col-lg-6">
                      <WhatsappRespondLead data={campaignDashboardData} />
                    </div>

                    <div className="col-lg-6">
                      <WhatsappDeliveredLead data={campaignDashboardData} />
                    </div>
                    <div className="col-lg-6">
                      <WhatsappReadLeads data={campaignDashboardData} />
                    </div>

                  </div>
                </section>

              </>) : (<>

                <section className="section dashboard">
                  <div className="row p-2">
                    {/* Left side columns */}
                    <div className="col-lg-8">
                      <LeadCountCard data={campaignDashboardData} />
                    </div>
                    {/* Right side columns */}
                    <div className="col-lg-4">
                      <PieChart data={campaignDashboardSummaryData} />
                    </div>
                  </div>
                </section>

                <section>
                  <div className="row p-2">
                    <div className="col-lg-6">
                      <LeadsOverview data={campaignDashboardData}  outwardLeadButton ={outwardLeadButton}/>
                    </div>
                    {/* Campaign Insight */}
                    <div className="col-lg-6">
                      <LeadStatus data={campaignDashboardData} />
                    </div>
                  </div>


                </section>
              </>)}

            </>)}
        </div>
      </div>
      {/*end::Card body*/}

      {/*end::Dashboard*/}
    </div>
  );
};

export default CampaignDashboard;
