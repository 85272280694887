import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const SkeletonCard = () => {
  return (
    <div className=' w-100 w-lg-350px w-md-100 mx-lg-0 mb-md-2'>
      <SkeletonTheme baseColor='#EBEBEB' highlightColor='#f5F5F5'>
        <div className='card card-flush h-500px'>
          <div className='skeleton-body d-flex justify-content-center flex-row mt-9 mx-6'>
            <Skeleton className='skeleton border border-1 mx-2' width='7rem' height='100%' />
            <Skeleton className='skeleton border border-1 mx-2' width='7rem' height='100%' />
          </div>
          <div className='skeleton-body mt-9'>
            <div className='d-flex flex-row mt-5'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
            <div className='d-flex flex-row mt-8'>
              <Skeleton circle className='circle-skeleton ms-12' width={45} height={45} />
              <Skeleton className='skeleton ms-5 mt-5' width='11rem' height={20} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  )
}

export default SkeletonCard
