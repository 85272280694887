import { useState, useContext, useRef } from 'react'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import IntentRenderer from './intent-renderer'
import ChatContext from '../../context/ChatContext'
import Intent from './intent'
import MsgLoader from './templates/msgloader'
import axios from 'axios'
import LocationShare from './LocationShare'
import { useQuery } from '@apollo/client'
import { GET_ALL_MAPS } from '../../graphql/query'

interface IProps {
  chatRoomId?: number
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
  onAssign: any
  handleMarkMessgaeAsRead: any
}

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL

const ChatInner: React.FC<IProps> = ({
  chatRoomId,
  // agentID,
  type,
  onAssign,
  handleMarkMessgaeAsRead
}) => {
  const [chatUpdateFlag, toggleChatUpdateFlag] = useState<boolean>(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [uploadFileUrl, setUploadFileUrl] = useState<string>('')
  const [showLocationShare, setShowLocationShare] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { state, bottomRef, onChange, onSubmit } = useContext(ChatContext)
  const maxLength = 5000000 // 5MB
  const currentPanel = state.panelBasedConversation.find(
    (panel: any) => panel.chatRoomId === chatRoomId
  )

  const { data, loading, error } = useQuery(GET_ALL_MAPS)

  const formatSizeInMB = (size: number) => {
    return (size / (1024 * 1024)).toFixed(2) + ' MB'
  }

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const newChatInputValue = state.chatInput.value + emojiData.emoji
    onChange({
      target: {
        name: state.chatInput.name,
        value: newChatInputValue
      }
    })
  }

  const handleDropdownItemClick = (item: string) => {
    const newChatInputValue = state.chatInput.value + ' ' + item
    onChange({
      target: {
        name: state.chatInput.name,
        value: newChatInputValue
      }
    })
    setShowDropdown(false)
  }

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      if (files[0].size <= maxLength) {
        try {
          // Upload the file
          const fileData = files[0]
          const data = {
            files: [
              {
                fileName: fileData.name,
                mimeType: fileData.type
              }
            ]
          }
          const response = await axios.post(FILE_UPLOAD_URL as string, data)
          const uploadURL = response.data[0].uploadURL

          await axios.put(uploadURL, fileData)
          const fileUrl = response.data[0].url
          setUploadFileUrl(fileUrl)
          // Set the file name or URL in the chat input
          const newChatInputValue = state.chatInput.value + ` ${fileUrl}`
          onChange({
            target: {
              name: state.chatInput.name,
              value: newChatInputValue
            }
          })
        } catch (err) {
          console.error(err)
        }
      } else {
        alert(`File ${files[0].name} exceeds the size limit ${formatSizeInMB(maxLength)}.`)
      }
    }
  }

  const handleLocationSelect = (locationLink: string) => {
    // onChange({
    //   target: {
    //     name: state.chatInput.name,
    //     value: locationLink
    //   }
    // })
    sendMessage(locationLink)
    setShowLocationShare(false)
  }

  const uploadFileName = uploadFileUrl.split('-').pop()

  const sendMessage = (e: any) => {
    const trimmedValue = state.chatInput.value.trim() || e
    if (!!uploadFileUrl) {
      if (uploadFileUrl.length > 0) {
        toggleChatUpdateFlag((flag: boolean) => !flag)
        onSubmit(e, currentPanel)
        toggleChatUpdateFlag((flag: boolean) => !flag)
        setUploadFileUrl('')
        textareaRef.current?.focus()
      }
    } else {
      if (trimmedValue.length > 0) {
        toggleChatUpdateFlag((flag: boolean) => !flag)
        setTimeout(() => {
          onSubmit(e, currentPanel)
          toggleChatUpdateFlag((flag: boolean) => !flag)
          textareaRef.current?.focus()
        }, 500)
      }
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      sendMessage(e)
    }
  }

  const cannedResponse = [
    'Sure thing! If you have any other questions, Ill be happy to help!',
    'Hi there! 👋 How can I help you today?',
    'Can I help you with anything else? ',
    'Thank you for your patience. ',
    'I`m still on it. Please bear with me for a moment. '
  ]

  const renderFooter = () => {
    if (type === 'Resolved' || type === 'Chat-Report') {
      return (
        <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
          <div className='d-flex flex-stack'></div>
        </div>
      )
    } else if (type === 'Unassigned') {
      return (
        <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center me-2'></div>
            <button
              className='btn btn-primary'
              onClick={() => onAssign(true)}
              disabled={chatRoomId === 0}
            >
              Assign Chat
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
          <textarea
            className='form-control form-control-flush mb-3'
            rows={1}
            placeholder='Type a message'
            name={state.chatInput.name}
            value={!!uploadFileUrl ? uploadFileName : state.chatInput.value}
            onChange={onChange}
            onKeyDown={onEnterPress}
            ref={textareaRef}
            disabled={!chatRoomId}
            onFocus={handleMarkMessgaeAsRead}
          ></textarea>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
              <button
                className='btn btn-active-light-primary me-1 p-2'
                style={{ border: 'none', background: 'transparent' }}
                type='button'
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                disabled={chatUpdateFlag || !chatRoomId}
                data-bs-placement='top'
                title='Emoji Picker'
              >
                <i className='bi bi-emoji-smile fs-1'></i>
              </button>
              <button
                className='btn btn-active-light-primary p-2'
                type='button'
                onClick={handleFileUploadClick}
                disabled={chatUpdateFlag || !chatRoomId}
                data-bs-placement='top'
                title='File Transfer'
              >
                <i className='bi bi-paperclip fs-2'></i>
              </button>
              <input
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <button
                className='btn btn-active-light-primary p-2'
                type='button'
                onClick={() => setShowDropdown(!showDropdown)}
                disabled={chatUpdateFlag || !chatRoomId}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Canned Responses'
              >
                <i className='bi bi-hash fs-1'></i>
              </button>
              <button
                className='btn btn-active-light-primary p-2'
                type='button'
                onClick={() => {
                  if (data?.getallMaps?.length === 0) {
                    alert(
                      'Location sharing is unavailable. Please configure maps before using this feature.'
                    )
                  } else {
                    setShowLocationShare(true)
                  }
                }}
                disabled={chatUpdateFlag || !chatRoomId}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Location Sharing'
              >
                <i className='bi bi-geo-alt fs-2'></i>
              </button>
            </div>
            <div>
              <button
                className='btn btn-primary'
                type='button'
                onClick={sendMessage}
                // disabled={chatUpdateFlag || !chatRoomId || !agentID }
              >
                Send
              </button>
            </div>
          </div>
          {showEmojiPicker && (
            <div className='emoji-picker'>
              <EmojiPicker onEmojiClick={handleEmojiClick} style={{ width: 'auto' }} />
            </div>
          )}
          {showDropdown && (
            <div
              className='dropdown-menu show card-body border border-1 p-4'
              style={{
                transform: 'translate(-8%, -155%)',
                boxShadow: ' 0 6px 20px 0 rgb(19 19 23 / 30%)',
                width: '350px'
              }}
            >
              {cannedResponse.map((item: string) => (
                <p className='' key={item} onClick={() => handleDropdownItemClick(item)}>
                  {item}
                </p>
              ))}
            </div>
          )}
          {showLocationShare && (
            <div
              className='position-absolute'
              style={{
                bottom: '18%',
                left: '5%',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                width: '90%'
              }}
            >
              <LocationShare
                onLocationSelect={handleLocationSelect}
                onClose={() => setShowLocationShare(false)}
                data={data}
              />
            </div>
          )}
        </div>
      )
    }
  }

  const apiKey = data?.getallMaps
    ?.find((item: any) => item.configName.includes('API Key'))
    ?.value?.replace(/"/g, '')

  if (loading)
    return (
      <div className='card shadow-sm border'>
        <div className='card-header bg-light'>
          <div className='d-flex align-items-center justify-content-between'>
            <h5 className='mb-0'>Loading...</h5>
            <button className='btn btn-sm btn-icon' onClick={() => setShowLocationShare(false)}>
              <i className='bi bi-x fs-2'></i>
            </button>
          </div>
        </div>
        <div className='card-body text-center my-4'>
          <div className='spinner-border spinner-border-sm text-primary' role='status'></div>
          <p className='mt-3 mb-0 text-muted'>Loading map configuration...</p>
        </div>
      </div>
    )

  if (error)
    return (
      <div className='card shadow-sm border'>
        <div className='card-header bg-light d-flex justify-content-between align-items-center'>
          <h5 className='mb-0'>Error</h5>
          <button className='btn btn-sm btn-icon' onClick={() => setShowLocationShare(false)}>
            <i className='bi bi-x fs-2'></i>
          </button>
        </div>
        <div className='card-body text-center text-danger my-4'>
          <p>Error loading map configuration. Please try again later.</p>
        </div>
      </div>
    )

  return (
    <div className='card-body' id='kt_chat_messenger_body'>
      <div className='scroll-y me-n5 pe-5 mh-650px'>
        <div className='d-flex justify-content-center mb-4'>
          <div
            className='p-1 rounded bg-light text-dark fw-bold text-center'
            style={{ width: 'fit-content' }}
          >
            {!!currentPanel?.conversation[0]?.time &&
              new Intl.DateTimeFormat('en-IN', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'Asia/Kolkata'
              }).format(new Date(currentPanel?.conversation[0]?.time))}
          </div>
        </div>
        {chatRoomId !== undefined && (
          <>
            <IntentRenderer
              apiKey={apiKey}
              chatRoomId={chatRoomId}
              previousValue={state.previousValue}
              conversation={currentPanel?.conversation}
              type={type}
              flagMessageRead={state.flagMessageRead}
              uploadFileUrl={uploadFileUrl}
            />
            {state.msgLoading.active === true ? (
              <Intent
                chatRoomId={chatRoomId}
                side='rhs'
                key='loader-intent-key'
                time=''
                type={type}
                item={() => {}}
              >
                {MsgLoader({ side: 'end-user' })}
              </Intent>
            ) : (
              ''
            )}
          </>
        )}
        <div ref={bottomRef} />
      </div>
      {renderFooter()}
    </div>
  )
}

export { ChatInner }
