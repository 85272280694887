import clsx from "clsx";
import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
/* import SideMenu from '../sections/aside/SideMenu' */
import Footer from "../sections/Footer";
import HeaderWrapper from "../sections/header/HeaderWrapper";
import { PageDataProvider, useLayout } from "./core";
import LoadingSpinner from "../components/LoadingSpinner";
import SideBarWrapper from "../sections/SideBarWrapper";
import TelephonyFrontend from "../components/TelephonyFrontend/TelephonyFrontend";
import "./styles.scss";
import { useTelephony } from "../components/context/TelephonyContext";
import SkeletonLoader from "../components/SkeletonLoader";
import { TELEPHONY_LOGIN } from "../constants";

const MasterLayout: React.FC = () => {
  const { classes } = useLayout();

  const [isTelephonyVisible, setIsTelephonyVisible] = useState(true);

  const { isLoading } = useTelephony();

  const toggleTelephony = () => {
    setIsTelephonyVisible((prev) => !prev); // Toggle visibility
  };

  const checkTelephonyLogin = localStorage.getItem(TELEPHONY_LOGIN);

  return (
    <PageDataProvider>
      <div className="page d-lg-flex flex-row flex-column-fluid">
        <SideBarWrapper />
        <div className="wrapper d-flex flex-column flex-row-fluid px-0 pt-15 mt-lg-20">
          <HeaderWrapper />

          {checkTelephonyLogin === "true" && (
            <div
              style={{
                padding: isTelephonyVisible ? "5px 20px" : "0px",
              }}
              className="telephony-container"
            >
              {/* {isLoading ? (
                                <SkeletonLoader />
                            ) : ( */}
              <div
                className={clsx("telephony-wrapper", {
                  "telephony-hide": !isTelephonyVisible,
                })}
              >
                <TelephonyFrontend />
              </div>
              {/* )} */}

              <span
                onClick={toggleTelephony}
                style={{
                  padding: isTelephonyVisible ? "4px" : "0 25px",
                }}
                className=""
                // className="telephony-toggle-btn btn btn-outline-primary"
              >
                {isTelephonyVisible ? (
                  <>
                    <i
                      className="bi bi-chevron-up"
                      style={{ color: "black" }}
                    ></i>
                  </>
                ) : (
                  <>
                    <i
                      className="bi bi-chevron-down"
                      style={{ color: "black" }}
                    ></i>
                  </>
                )}
              </span>
            </div>
          )}
          <div
            id="kt_content"
            className="d-flex flex-column flex-column-fluid mt-n12"
          >
            {/* <Toolbar /> */}
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <div id="kt_content_container" className="w-100">
                <Suspense fallback={<LoadingSpinner />}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </PageDataProvider>
  );
};

export { MasterLayout };
