import { CloseButton } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { useState } from "react";
import { useNewWorkflowMutation, useUdpateWorkflowByIdMutation } from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { toast } from "react-toastify";
interface IProps {
  closeModal: any
  workflowForEdit: any
}

const NewWorkflowModal: React.FC<IProps> = ({ closeModal, workflowForEdit }) => {
  const initialValues = {
    id: 0,
    name: workflowForEdit.workflowEditEnabled ? workflowForEdit.workflowData.name : "",
    active_status: workflowForEdit.workflowEditEnabled ? workflowForEdit.workflowData.active_status : "",
    is_assigned_to_creator_on_close: true,
    resolved_status: null,
    closed_status: workflowForEdit.workflowEditEnabled ? workflowForEdit.workflowData.closed_status : "",
    reopen_status: workflowForEdit.workflowEditEnabled ? workflowForEdit.workflowData.reopen_status : "",
    closed_assignment_team: null,
    closed_visibility_team: null,
    in_progress_statuses: [],
    team: null,
    closed_status_visible: false
  }

  const newWorkflowSchema = Yup.object().shape({
    name: Yup.string().required('Please provide a ticket name'),
    active_status: Yup.string().required('Please provide a active stage'),
    reopen_status: Yup.string().required('Please provide a reopen stage'),
    closed_status: Yup.string().required('Please provide a closed stage'),
  });

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionStatus, setFormSubmissionStatus] = useState("");
  const [triggerNewWorkflowMutation] = useNewWorkflowMutation();
  const [triggerUpdateWorkflowMutation] = useUdpateWorkflowByIdMutation();

  const submitForm = async (valObj: any) => {
    const promise = new Promise((resolve, reject) => {
    })
    try {
      workflowForEdit.workflowEditEnabled ?
        triggerUpdateWorkflowMutation({ workflowId: workflowForEdit.workflowData.id, body: valObj })
          .then((res: any) => {
            if (res.data) { toast.success("udpated the workflow successfully"); closeModal() } else alert('failed to update')
          })
        : triggerNewWorkflowMutation(valObj)
          .then((res: any) => {
            if (res.data) { toast.success("a new workflow has been created"); closeModal() }
            else { toast.error("failed to create a workflow") }
          })
    } catch (error) {
      console.error('Error making the POST request:', error); setFormSubmissionStatus('Error submitting the form')
    } finally { setSubmitting(false) /* Set submitting to false to enable the form again */ }
    return promise
  }

  const formik = useFormik({
    initialValues,
    validationSchema: newWorkflowSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const formSubmissionValues = workflowForEdit.workflowEditEnabled ? {
        "name": values.name,
        "active_status": values.active_status,
        "closed_status": values.closed_status,
        "reopen_status": values.reopen_status,
        "is_assigned_to_creator_on_close": true,
        "closed_assignment_team": null,
        "closed_visibility_team": null,
        "id": workflowForEdit.workflowData.id
      } :
        {
          "name": values.name,
          "active_status": values.active_status,
          "closed_status": values.closed_status,
          "reopen_status": values.reopen_status,
          "is_assigned_to_creator_on_close": true,
          "closed_assignment_team": null,
          "closed_visibility_team": null,
          "in_progress_statuses": [],
          "team": null,
        }
      setLoading(true)
      submitForm(formSubmissionValues)
        .then((res: any) => {
          closeModal()
          if (res.data.signIn.status === 201) {
            /* setLoading(false) */
            closeModal()
              ``
          } else {
            /* setLoading(false) */
            setSubmitting(false)
          }
        })
        .catch((err: any) => {
          console.log(err)
          /* setLoading(false) */
          setSubmitting(false)
          console.error(err)
        })

    }
  });
  return (
    <div>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>

      <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate onSubmit={formik.handleSubmit}>
        <div className="text-start mb-4">
          <label className="form-label fs-2 fw-bolder text-dark card-title">
            {workflowForEdit.workflowEditEnabled ? 'Edit' : 'New'} Workflow
          </label>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          {/*ticket name feed*/}
          <div className="col-xl-12">
            <div className="d-flex flex-column">
              <label className="required">Add a ticket name</label>
              <input
                id='name'
                name='name'
                onChange={formik.handleChange}
                value={formik.values.name} type="text" className="form-control" />

              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          {/*active stage feed*/}
          <div className="col-xl-12">
            <div className="d-flex flex-column">
              <label className="required">Active stage</label>
              <input
                id='active_status'
                name='active_status'
                onChange={formik.handleChange}
                value={formik.values.active_status} type="text" className="form-control" />

              {formik.touched.active_status && formik.errors.active_status && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.active_status}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          {/*closed stage feed*/}
          <div className="col-xl-12">
            <div className="d-flex flex-column">
              <label className="required">Closed stage</label>
              <input
                id='closed_status'
                name='closed_status'
                onChange={formik.handleChange}
                value={formik.values.closed_status} type="text" className="form-control" />

              {formik.touched.closed_status && formik.errors.closed_status && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>Please provide a closed stage</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row g-5 g-xl-8 mb-6">
          {/*reopen stage feed*/}
          <div className="col-xl-12">
            <div className="d-flex flex-column">
              <label className="required">Reopen stage</label>
              <input
                id='reopen_status'
                name='reopen_status'
                onChange={formik.handleChange}
                value={formik.values.reopen_status} type="text" className="form-control" />

              {formik.touched.reopen_status && formik.errors.reopen_status && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.reopen_status}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-end mt-6 mb-4">
          <button className={`btn btn-sm btn-primary`} type="submit">
            Save
          </button>
        </div>

      </form>
    </div>
  )
}

export default NewWorkflowModal
