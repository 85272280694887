import axios from "axios";
import React, { useState, useEffect } from "react";
import { CALL_HISTORY_ID, EXTENSION, INCOMING_CALL, OUTGOING_CALL, TELEPHONEURL } from "../../constants";
import { useCallExtensionStateQuery, useCallHistoryIdMutation, useExtensionStateMutation } from "../../Services/Telephony/TelephonyFrontend";
import { toast } from "react-toastify";
import LoadingSkeleton from "../LoadingSkeleton";
import { useTelephony } from "../context/TelephonyContext";
import ButtonLoadingSkeleton from "../ButtonsLoadingSkeleton";

interface IPROPS {
    mobileNumber:any
    setIsCallPause:any
    isCallPause:any
    onClick?: () => void;
    style?: React.CSSProperties;
}

const PauseCall: React.FC<IPROPS> = ({mobileNumber, setIsCallPause, isCallPause, onClick, style}) => {
    // const [isCallPaused, setIsCallPaused] = useState(false);

    const outgoing = localStorage.getItem(OUTGOING_CALL)
    const incoming  = localStorage.getItem(INCOMING_CALL)

    const incomingCallHistoryId = localStorage.getItem("IncomingCallId")
    const CallHistoryId = localStorage.getItem(CALL_HISTORY_ID)

    const gettingCallHistoryId = outgoing === "true" ? CallHistoryId :  incomingCallHistoryId ;


    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);
    const gettingExtension = localStorage.getItem(EXTENSION);
  


    const telephoneData = JSON.parse(gettingTelephonyData);
    const [extensionStateTrigger] = useExtensionStateMutation()
    const [callHistoryId] = useCallHistoryIdMutation()

    const {data} = useCallExtensionStateQuery(gettingExtension, {skip: !isCallPause})

    const { disconnectCallLoader, setDisconnectCallLoader } = useTelephony();



    const pauseCallHandler = async() => {
        setIsCallPause(!isCallPause);
        
        setDisconnectCallLoader(true)

        const url = telephoneData?.https_port > 0 ?
        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${mobileNumber}&Type=${isCallPause ?  "retrieve" : "hold"}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`

        : 
        `https://${telephoneData?.domain}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${mobileNumber}&Type=${isCallPause ?  "retrieve" : "hold"}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`


        try {
        const pauseCall = await axios.post(
            url
            )

        const extensionObj = {
            state: isCallPause ? "resume-call" : "hold",
            extension: +gettingExtension

        }
        await extensionStateTrigger( extensionObj)

        const callHistoryIdObj = {
            action: isCallPause ? "resume-call" : "hold-call"
    
        }

       const response =  await callHistoryId({body: callHistoryIdObj, id: gettingCallHistoryId})

    //    setDisconnectCallLoader(true)
    //    try {


        if (response?.data) {
            toast.success(isCallPause ? "Call Resumed" : "Call Paused")

 
 
        }
 
        else if (response?.error) {
         toast.error(isCallPause ? "Unable to resume" : "Unable to paused")
         setDisconnectCallLoader(false)
         return 
 
        }
        setDisconnectCallLoader(false)
        
       } catch (error) {
        setDisconnectCallLoader(false)

        console.log(error)
        toast.error("An error occured")
        
       }

    
    };


 
    return (
        <div>
            {disconnectCallLoader ? <ButtonLoadingSkeleton /> : 
            <div onClick={onClick} style={style}>
                {!isCallPause ? 
                <i
                    onClick={pauseCallHandler}
                    style={{ fontSize: "15px", background: "#ebeb0a", borderRadius: "50%", color: "white", padding: "8px" }}
                    className="bi bi-pause-fill"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hold"
                ></i>
                : 

                <i
                onClick={pauseCallHandler}
                    style={{ fontSize: "15px", background: "#ebeb0a", borderRadius: "50%", color: "white", padding: "8px" }}
                    className="bi bi-play-fill"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Resume"
                ></i>
}
            </div>
            }
        </div>
    );
};

export default PauseCall;
