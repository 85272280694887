import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Inbox = ({ intervalData }) => {
   const chartRef = useRef<HTMLDivElement>(null);
   const [isFullscreen, setIsFullscreen] = useState(false);
  const results = intervalData?.results || [];
  const categories = Object.keys(results[0]?.hourly_count || {});

  const series = results.map((day) => ({
    name: format(new Date(day.date), "dd-MM-yyyy"),
    data: Object.values(day.hourly_count),
  }));


   useEffect(() => {
      const handleFullscreenChange = () => {
        const isFull = !!document.fullscreenElement;
        setIsFullscreen(isFull);
  
        // Force ApexCharts to resize when fullscreen changes
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 300);
      };
  
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      return () => {
        document.removeEventListener("fullscreenchange", handleFullscreenChange);
      };
    }, []);
  
    const toggleFullscreen = async () => {
      try {
        if (!document.fullscreenElement && chartRef.current) {
          await chartRef.current.requestFullscreen();
        } else if (document.exitFullscreen) {
          await document.exitFullscreen();
        }
      } catch (error) {
        console.error("Fullscreen mode failed", error);
      }
    };
  const options:any = {
    chart: {
      type: "line",
      height: 625,
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            {
              icon: isFullscreen
                ? '<i class="bi bi-fullscreen-exit"></i>'
                : '<i class="bi bi-arrows-fullscreen"></i>',
              title: isFullscreen ? "Exit Fullscreen" : "Fullscreen",
              class: "custom-fullscreen-icon mx-2",
              click: toggleFullscreen,
            },
          ],
        },
      },
    },
    xaxis: {
      type: "category",
      categories: categories,
      title: {
        text: "Hour of Day",
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    yaxis: {
      title: {
        text: "Inbox Email Count",
        style: {
          fontWeight: "normal",
        },
      },
      labels: {
        formatter: function (val) {
          return Math.floor(val);
        },
      },
      min: 0,
      forceNiceScale: true,
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (val) {
          return `Hour: ${val}`;
        },
      },
    },
    colors: [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#F39C12",
      "#8E44AD",
      "#FFFF00",
      "#3498DB",
    ],
  };

  return (
    <div
    ref={chartRef}
    style={{
      width: "100%",
      height: isFullscreen ? "100vh" : 625,
      backgroundColor: "white", // Prevents black screen issue
      padding: isFullscreen ? "20px" : "0",
    }}
  >
    <ReactApexChart options={options} series={series} type="line" height="100%"/>
    </div>
  );
};

export default Inbox;