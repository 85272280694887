import React, {
    useEffect,
    useState,
    useContext,
    createContext,
    ReactNode,
    PropsWithChildren,
    useRef,
} from "react";
import { WS_URL } from "../env";
import LeadEventModal from "./LeadEventModal";
import ReactModal from "react-modal";

const ChatContext = createContext<any>(null);

interface IProps {
    children?: ReactNode;
}

export const WebSocketProvider: React.FC<PropsWithChildren<IProps>> = ({ children }) => {
    const WebSocket_URL = `wss://${localStorage.getItem("circleone-user")}.${WS_URL}` || "";
    const socketRef = useRef<WebSocket | null>(null);
    const [isSocketOpen, setIsSocketOpen] = useState(false);
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const [numOfTimesWebsocketDisconnected, setNumOfTimesWebsocketDisconnected] = useState(0);
    const [serverResponse, setServerResponse] = useState<any>(null);
    const [leadEventData, setLeadEventData] = useState<any>(null);

    const [state, setState] = useState({
        enableUserInput: true,
        reconnectChat: false,
    });

    const authToken = localStorage.getItem("auth-token");

    useEffect(() => {
        if (leadEventData) {
            setIsNotificationModalOpen(true);
        }
    }, [leadEventData]);

    // Function to establish WebSocket connection
    const connectWebSocket = () => {
        if (!authToken) {
            console.error("Auth token not found. Cannot establish WebSocket connection.");
            return;
        }

        if (socketRef.current) {
            console.warn("WebSocket already exists. Avoiding duplicate connections.");
            return;
        }

        console.log("Connecting to WebSocket:", WebSocket_URL);
        const ws = new WebSocket(WebSocket_URL);
        socketRef.current = ws;

        ws.onopen = () => {
            console.log("WebSocket connected!");
            setIsSocketOpen(true); // ✅ Ensuring state update
            console.log("isSocketOpen set to TRUE");

            if (authToken && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ token: authToken }));
            }

            if (numOfTimesWebsocketDisconnected > 0) {
                console.warn("Reconnection attempt:", numOfTimesWebsocketDisconnected);
                setNumOfTimesWebsocketDisconnected(0);
            }
        };

        ws.onmessage = (event) => onMessage(event);
        ws.onclose = () => handleSocketClose();
        ws.onerror = (error) => console.error("WebSocket error:", error);
    };

    // Handle WebSocket closure & reconnection
    const handleSocketClose = () => {
        console.warn("WebSocket closed!");
        setIsSocketOpen(false); // ✅ Ensuring state update
        console.log("isSocketOpen set to FALSE");

        setState((prevState) => ({
            ...prevState,
            enableUserInput: false,
            reconnectChat: true,
        }));

        setNumOfTimesWebsocketDisconnected((prev) => prev + 1);

        // Attempt reconnection after 5 seconds
        setTimeout(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                console.log("WebSocket closed on unmount");
            }
        };
    }, []);

    // Handle incoming WebSocket messages
    const onMessage = (event: MessageEvent) => {
        console.log("Message from server:", event.data);
        try {
            const response = JSON.parse(event.data);
            console.log("Parsed Response:", response);

            setServerResponse(response);

            if (response.notification_type === "auth-success") {
                console.log("Successfully authenticated with WebSocket.");
            } else if (Array.isArray(response)) {
                const firstEvent = response[0];
                if (firstEvent.notification_type === "lead-event") {
                    handleLeadEvent(firstEvent.data);
                }
            } else if (response.notification_type === "lead-event") {
                handleLeadEvent(response.data);
            } else if (response.action === "ping") {
                console.log("Received PING, sending PONG...");
                sendMessage({ action: "pong" });
            } else if (response.type === "error") {
                console.error("Error received from server:", response.error);
            }
        } catch (error) {
            console.error("Error parsing WebSocket message:", error);
        }
    };

    const handleLeadEvent = (leadData: any) => {
        setLeadEventData(leadData);
        console.log("LEAD_EVENT-DATA:", leadData);
        sendMessage({ type: "lead-event", id: leadData?.id });
    };
    const sendMessage = (message: any) => {
        if (!socketRef.current) {
            console.warn("WebSocket instance is not created yet.");
            return;
        }

        if (socketRef.current.readyState !== WebSocket.OPEN) {
            console.warn("WebSocket is not open. Cannot send message.");
            return;
        }

        socketRef.current.send(JSON.stringify(message));
        console.log("Message sent:", message);
    };
    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "350px",
        },
    };

    return (
        <ChatContext.Provider
            value={{
                state: {
                    socket: socketRef.current,
                    isSocketOpen,
                    numOfTimesWebsocketDisconnected,
                    serverResponse,
                    state,
                },
                sendMessage,
            }}
        >
            {children}
            <ReactModal
                isOpen={isNotificationModalOpen}
                onRequestClose={() => setIsNotificationModalOpen(false)}
                style={customModalStyles}
                contentLabel="New Contact"
            >
                <LeadEventModal
                    leadEventData={leadEventData}
                    onClose={() => setIsNotificationModalOpen(false)}
                />
            </ReactModal>
        </ChatContext.Provider>
    );
};

export const useWebSocket = () => useContext(ChatContext);