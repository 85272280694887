import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const AuditTrail = createApi({
  reducerPath: "auditTrail",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    auditTrail: builder.query({
      query: (uuid) => {
        return {
          url: `v1/tickets/ticket-audit-trail/${uuid}/`,
          method: "GET",
        };
      },
    }),


    getAuditTrail: builder.query({
      query: ({ id, activity }) => {
        return {
          url: `v1/tickets/ticket-audit-trail/get_details/${id}/?activity=${activity}&`,
          method: "GET",
        };
      },
    }),

    getForwardedAuditTrailCount: builder.query({
      query: (id) => {
        return {
          url: `v1/tickets/ticket-audit-trail/${id}/?forward-ticket=true`,
          method: "GET",
        };
      },
    }),
    addAttachments: builder.mutation({
      query: (body) => {
        return {
          url: `v1/tickets/ticket-attachment/`,
          method: "POST",
          body
        };
      },
    }),
  }),
});

export const { useAuditTrailQuery, useGetAuditTrailQuery, useGetForwardedAuditTrailCountQuery , useAddAttachmentsMutation } = AuditTrail;
