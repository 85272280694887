import React from 'react'
import { useMutation } from '@apollo/client'
// import { DELETE_CHATROOM_META } from '../../graphql/mutation'
// import { GET_CHAT_ROOM_META_BY_CHATROOM_ID } from '../../graphql/query'
import { toast } from 'react-toastify'

interface Props {
  data: { id: string; name: string; value: string }
  chatRoomId: number
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  closeDeleteMeta: () => void
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
}

const DeleteMetaModal: React.FC<Props> = ({
  data,
  chatRoomId,
  setIsLoading,
  closeDeleteMeta,
  type
}) => {
  // const [deleteChatRoomMeta, { loading }] = useMutation(DELETE_CHATROOM_META, {
  //   refetchQueries: [
  //     {
  //       query: GET_CHAT_ROOM_META_BY_CHATROOM_ID,
  //       variables: {
  //         input: {
  //           chatRoomId: chatRoomId
  //         }
  //       },
  //       context: {
  //         headers: {
  //           'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //         }
  //       }
  //     }
  //   ],
  //   context: {
  //     headers: {
  //       'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //     }
  //   }
  // })

  const deleteThisMeta = (id: number) => {
    console.log(chatRoomId)
    // setIsLoading(true)
    // deleteChatRoomMeta({
    //   variables: {
    //     input: {
    //       chatRoomMetaId: id
    //     }
    //   }
    // })
    //   .then((res: any) => {
    //     // setIsLoading(false)
    //     closeDeleteMeta()
    //     console.log(res)
    //     // Need to rewrite the following logic
    //     if (parseInt(res.data.deleteChatRoomMeta.id) > 0) {
    //       toast.success('Succesfully Deleted Chat Data')
    //     } else {
    //       toast.error('Unable to Add Data')
    //     }
    //   })
    //   .catch((err) => {
    //     // setIsLoading(false)
    //     toast.error('Sorry! Unexpected Error.')
    //     console.log(err)
    //   })
  }

  return (
    <div className='card-body'>
      <p className='fs-5 fw-bold'>Are you sure you want to delete the following data ?</p>
      <div className='card-text'>
        <strong>{data.name}:</strong>&ensp; {data.value}
      </div>

      <div className='d-flex justify-content-end mt-10'>
        <button
          type='reset'
          className='btn btn-sm btn-white btn-active-light-primary me-2'
          onClick={closeDeleteMeta}
        >
          Cancel
        </button>

        <button
          type='submit'
          className='btn btn-sm btn-danger'
          // disabled={loading ? true : false}
          onClick={() => {
            deleteThisMeta(parseInt(data.id))
          }}
        >
          {/* {!loading && <span className='indicator-label'>Delete</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )} */}  Delete 
        </button>
      </div>
    </div>
  )
}

export default DeleteMetaModal
