import React, { useEffect, useState } from "react";
import { useCampaignDataQuery } from "../../../../Services/campaign/CampaignMapping";
import LoadingSpinner from "../../../LoadingSpinner";
import ReactModal from "react-modal";
import AddNewCampaignModal from "./AddNewCampaignModal";
import DeleteCampaignModal from "./DeleteCampaignModal";
import EditCampaignModal from "./EditCampaignModal";
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxHeight: "80%",
    maxWidth: "650px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const customResetPassModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    maxWidth: "850px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const CampaignCreation = () => {
  const [campaignFields, setCampaignFields] = useState([]);
  const [isCampaignModalOpen, setIsCampaignModalOpen] =
    useState<boolean>(false);

  const [campaignToggle, setCampaignToggle] = useState(true);

  const [isEditCampaignModalOpen, setIsEditCampaignModalOpen] = useState(false);
  const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] =
    useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState<number>(20);
  const userRole = localStorage.getItem("user_role");

  const {
    data: allCampaignData,
    isLoading,
    refetch,
    isError,
  } = useCampaignDataQuery({
    currentPage,
  });

  const CamapaignDataCount = allCampaignData?.count
    ? allCampaignData?.count
    : 0;

  useEffect(() => {
    if (allCampaignData) {
      setCampaignFields(allCampaignData.results);
    }
  }, [allCampaignData]);

  const totalPages = Math.ceil(CamapaignDataCount / pageSize);

  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5;

    if (totalPages <= maxVisibleNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const openCampaignModal = () => {
    setIsCampaignModalOpen(true);
  };

  const closeCampaignModal = () => {
    setIsCampaignModalOpen(false);
  };

  const openEditCampaignModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsEditCampaignModalOpen(true);
  };

  const closeEditCampaignModal = () => {
    setIsEditCampaignModalOpen(false);
  };

  const openDeleteCampaignModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsDeleteCampaignModalOpen(true);
  };

  const closeDeleteCampaignModal = () => {
    setIsDeleteCampaignModalOpen(false);
  };

  return (
    <div className="card-body px-0 px-lg-5" style={{ overflowY: "hidden" ,marginLeft:"3%"}}>
      <div className="row mb-5">
        <div className="col-10">
          <span className="input-group-text" id="basic-addon1">
            Campaign creation
          </span>
        </div>

        <div className="col-2 d-flex justify-content-end">
          <div className="card-toolbar d-flex gap-2">
            <button
              className="input-group-text form-control btn-sm fw-bold d-flex align-items-center min-w-150px"
              onClick={openCampaignModal}
            >
              <i className="bi bi-plus-lg text-dark me-2"></i>
              <span>Add campaign</span>
            </button>
          </div>
        </div>

        <ReactModal
          isOpen={isCampaignModalOpen}
          onRequestClose={closeCampaignModal}
          style={customModalStyles}
          contentLabel="Add Campaign "
        >
          <AddNewCampaignModal
            allCampaignData={allCampaignData}
            closeCampaignModal={closeCampaignModal}
            refetch={refetch}
            campaignToggle={campaignToggle}
            setCampaignToggle={setCampaignToggle}
          />
        </ReactModal>
      </div>

      {/* Table Header */}
      <div className="row" style={{ paddingRight: "0" }}>
        <div
          className="col px-2"
          style={{ paddingRight: "5px", paddingLeft: "5px" }}
        >
          <div className="card my-1" style={{ backgroundColor: "#E4E4E47F" }}>
            <div className="row align-items-center py-4">
              <div className="col-2 text-start ps-10">
                <label>
                  <strong>Sr.No</strong>
                </label>
              </div>
              <div className="col-2 text-start ">
                <strong>Campaign name</strong>
              </div>
              <div className="col-2 text-center">
                <label>
                  <strong>Dialer type</strong>
                </label>
              </div>
              <div className="col-2 text-center">
                <label>
                  <strong>Team</strong>
                </label>
              </div>
              <div className="col-2 text-center">
                <label>
                  <strong>Current status</strong>
                </label>
              </div>
              <div className="col-2 text-center">
                <label>
                  <strong>Action</strong>
                </label>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="d-flex justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            campaignFields.map((campaign, index) => (
              <div key={campaign.id} className="card my-1">
                <div className="row align-items-center py-3">
                  {/* Serial Number */}
                  <div className="col-2 text-start ps-10">{index + 1}</div>
                  {/* Campaign Name */}
                  <div className="col-2 text-start">{campaign.name}</div>
                  {/* Dialer Type */}
                  <div className="col-2 text-center">
                    {campaign.dialler_type}
                  </div>
                  {/* Team */}
                  <div className="col-2 text-center">
                    {campaign.team?.name || ""}
                  </div>
                  {/* Current Status */}
                  <div className="col-2 text-center">
                    {campaign.is_active ? (
                      <span className="badge bg-success">Active</span>
                    ) : (
                      <span className="badge bg-secondary">Inactive</span>
                    )}
                  </div>
                  {/* Action */}
                  <div className="col-2 text-center d-flex justify-content-center">
                    {/* Edit Icon with Tooltip */}
                    {/* <p
                      className="link-primary hoverable me-3"
                      role="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                      onClick={() => openEditCampaignModal(campaign)}
                    >
                      <i className="bi bi-pencil-square link-primary" />
                    </p> */}
                    <button
                      className="text-hover-primary border-0 bg-white me-2"
                      title="Edit"
                      onClick={() => openEditCampaignModal(campaign)}
                    >
                      <i className="bi bi-pencil text-dark fs-4"></i>
                    </button>

                    {/* Delete Icon with Tooltip */}

                    {userRole !== "agent" ? (
                      <button
                        className="text-hover-danger border-0 bg-white me-2"
                        title="Delete"
                        onClick={() => openDeleteCampaignModal(campaign)}
                      >
                        <i className="bi bi-trash text-dark fs-4"></i>
                      </button>
                    ) : null}
                  </div>

                  <ReactModal
                    isOpen={isEditCampaignModalOpen}
                    onRequestClose={closeEditCampaignModal}
                    style={customModalStyles}
                    contentLabel="Edit Campaign"
                  >
                    <EditCampaignModal
                      selectedCampaign={selectedCampaign}
                      closeEditCampaignModal={closeEditCampaignModal}
                      refetch={refetch}
                      isLoading={isLoading}
                    />
                  </ReactModal>

                  <ReactModal
                    isOpen={isDeleteCampaignModalOpen}
                    onRequestClose={closeDeleteCampaignModal}
                    style={customResetPassModalStyles}
                    contentLabel="Delete Campaign"
                  >
                    <DeleteCampaignModal
                      selectedCampaign={selectedCampaign}
                      closeDeleteCampaignModal={closeDeleteCampaignModal}
                      refetch={refetch}
                    />
                  </ReactModal>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignCreation;
