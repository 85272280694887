import React, { useEffect, useMemo, useState } from "react";
import { useUsersTeamMutation } from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import { CIRCLEONE_USER } from "../../../../constants";

interface IPROPS {
    closeModal: any;
    refetch: any
}

const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const AddTeamModal: React.FC<IPROPS> = ({ closeModal, refetch }) => {
    const [name, setName] = useState<string>("");
    const [selectRole, setSelectRole] = useState<any>("agent");
    const [checkBoxValue, setCheckBoxValue] = useState<any>(["dashboard:enable"]);
    const [agentCheckBox, setAgentCheckBox] = useState<any>(["dashboard:enable"]);
    const [managerCheckBox, setManagerCheckBox] = useState<any>(["dashboard:enable"]);

    const tenantName = localStorage.getItem(CIRCLEONE_USER)

    const [trigger, { data: addTeamData, isLoading, isError }] =
        useUsersTeamMutation();

    const mediaAccess = useMemo(
        () => [
            "dashboard",
            "users",
            "customers",
            "email",
            "ticket",
            "campaigns",
            "phone",
            "chat",
            "reports",
            "settings",
            // "social-media",
            // "whatsapp",
            // "knowledge-management",
            // "subscription",
        ],
        []
    );

    const nameHandler = (e) => {
        setName(e.target.value);

    };
    // Initialize checkBoxValue based on the role selected
    useEffect(() => {
        if (selectRole === 'agent') {
            setCheckBoxValue(agentCheckBox);
            setManagerCheckBox(["dashboard:enable"]); // Reset manager checkboxes
        } else if (selectRole === 'manager') {
            setCheckBoxValue(managerCheckBox);
            setAgentCheckBox(["dashboard:enable"]); // Reset agent checkboxes
        }
    }, [selectRole]);

    // Handle role selection
    const roleSelectHandler = (e) => {
        setSelectRole(e.target.value);

    };

    // Handle checkbox selection
    const checkBoxHandler = (access) => {


        const accessWithSuffix = `${access}:enable`;

        if (selectRole === 'agent') {
            setAgentCheckBox((prevState) =>
                prevState.includes(accessWithSuffix)
                    ? prevState.filter((item) => item !== accessWithSuffix)
                    : [...prevState, accessWithSuffix]
            );
        } else if (selectRole === 'manager') {
            setManagerCheckBox((prevState) =>
                prevState.includes(accessWithSuffix)
                    ? prevState.filter((item) => item !== accessWithSuffix)
                    : [...prevState, accessWithSuffix]
            );
        }

        setCheckBoxValue((prevState) =>
            prevState.includes(accessWithSuffix)
                ? prevState.filter((item) => item !== accessWithSuffix)
                : [...prevState, accessWithSuffix]
        );
    };

    // check box
    // add 


    const AddTeamHandler = async (e: any) => {
        e.preventDefault();

        // Check if "phone" is selected but "customers" is not
        if (
            (selectRole === "agent" && agentCheckBox.includes("phone:enable") && !agentCheckBox.includes("customers:enable")) ||
            (selectRole === "manager" && managerCheckBox.includes("phone:enable") && !managerCheckBox.includes("customers:enable"))
        ) {
            toast.warning("Please select customers as well");
            return; // Prevent further execution if the condition is met
        }

        const addTeamObj = {
            name: name,
            agent_module_permissions: selectRole === "agent" ? agentCheckBox : [],
            manager_module_permissions: selectRole === "manager" ? managerCheckBox : [],
            role: [selectRole],
        };

        try {
            const response: any = await trigger(addTeamObj);
            if (response?.data) {
                toast.success("Added Successfully");
                refetch();
                closeModal(true);
            } else if (response?.error) {
                const errorData = response?.error?.data;
                // Extract error messages from nested objects
                const extractMessages = (obj) => {
                    return Object.values(obj)
                        .map(value => (typeof value === "object" ? extractMessages(value) : value))
                        .flat();
                };
                const errorMessages = extractMessages(errorData).join(", ").replace(/"/g, '');
                toast.error(errorMessages || "An error occurred");
            } else {
                toast.error("An error occurred");
            }
        } catch (error) {
            toast.error("An error occurred");
        }
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                }}
            >
                <i
                    className="bi bi-x-lg"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => closeModal()}
                ></i>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Enter Team Name</label>
                <input
                    style={{ border: "1px solid grey", padding: "10px" }}
                    onChange={nameHandler}
                    value={name}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Select Role</label>
                <select
                    style={{
                        padding: "10px",
                        border: "1px solid grey",
                        background: "transparent",
                    }}
                    onChange={roleSelectHandler}
                    value={selectRole}
                >
                    <option value={"agent"}>Agent</option>
                    <option value={"manager"}>Manager</option>
                </select>
            </div>
            {/* <div><input type="checkbox"/><label>Branch Portal</label></div> */}
            <div
                style={{
                    padding: "10px 0 0 0",
                    marginBottom: "0px",
                    fontWeight: "600"

                }}
            >
                Module Permission
            </div>
            <div style={{ border: "1px solid #ccc" }}>
                <div
                    className="bg-primary"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // border: "1px solid green",
                        padding: "10px",
                        // background: "#009ef7",
                        color: "white",
                        fontSize: "17px",
                        width: "100%",
                        marginBottom: "5px",
                    }}
                >
                    <span style={{ width: "50%" }}>Module Access</span>
                    <span style={{ flex: "1" }}>Access</span>
                </div>
                <div
                    style={{
                        maxHeight: "40vh",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        width: "100%",
                        marginBottom: "10px",
                        // padding: "10px",
                    }}
                >
                    {mediaAccess?.map((el: any, index: any) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                background:
                                    index % 2 !== 0
                                        ? "rgb(232 234 241)"
                                        : "transparent",
                                padding: "5px",
                            }}
                        >
                            <p
                                style={{
                                    width: "50%",
                                    margin: "0",
                                    fontSize: "14px",
                                }}
                            >
                                {capitalizeFirstLetter(el === 'campaigns' ? 'leads' : el)}
                            </p>
                            <p
                                style={{
                                    flex: "1",
                                    margin: "0",
                                    fontSize: "14px",
                                    display: "flex",
                                    gap: "5px",
                                }}
                            >

                                <span>
                                    <input
                                        type="checkbox"
                                        onChange={() => checkBoxHandler(el)}
                                        checked={checkBoxValue.includes(`${el}:enable`)}
                                        disabled={["chat", "dashboard"].includes(el) || (tenantName === "victorinox" && ["campaigns", "phone"].includes(el)) || (tenantName === "csquareeeee" && ["campaigns", "email"].includes(el))}
                                    />
                                </span>
                                <span
                                    style={{
                                        color:
                                            el === "dashboard"
                                                ? "#999"
                                                : "#000",
                                    }}
                                >
                                    Edit
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <button className="btn btn-primary" onClick={AddTeamHandler}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default AddTeamModal;
