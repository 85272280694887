import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import ReactModal from "react-modal";
import AddNewTicketContactModal from "./AddNewTicketContactModal";
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  IconButton,
  InputLabel,
  ListItemText,
  Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import {
  useCreateTicketMutation,
  useDispositionQuery,
  useGetDispositionStatusQuery,
  useTicketDynamicFieldsQuery,
} from "../../Services/ticket/CreateTicket";
import moment from "moment";
import { useTeamsQuery } from "../../Services/settingsAPIs/CommonAPI";
import axios from "axios";
import {
  Autocomplete,
  Checkbox,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Box,
  Chip,
} from "@mui/material";
import EmailTemplateModal from "../email/EmailTemplateModal";
import { AUTH_TOKEN, USER_TEAM_ID } from "../../constants";
import LoadingSpinner from "../LoadingSpinner";
import { Loader } from "react-bootstrap-typeahead";
import "react-phone-number-input/style.css";
import { PhoneInput } from "react-international-phone";
import { values } from "lodash";
import { capitalizeFirstLetter } from "../reusableComponent/CapitalLetter";
import { isFieldDisabled } from "../reusableComponent/FieldDisabled";

interface IProps {
  closeModal: () => void;
  setIsLoading: any;
  refetch: any;
  refetchWorkflow: any;
  selectedWorkflow: any;
}

interface ErrorState {
  [key: string]: string;
}

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    height: "600px",
  },
};

const TicketModal: React.FC<IProps> = ({
  closeModal,
  setIsLoading,
  refetch,
  refetchWorkflow,
  selectedWorkflow,
}) => {
  const [createTicket, { isLoading: loading }] = useCreateTicketMutation();
  const [loadingOl, setLoading] = useState<boolean>(false);
  const [isAddNewContactModalOpen, setAddNewContactModalOpen] = useState(false);
  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedContact, setSelectedContact] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [emailNotPresent, setEmailNotPresent] = useState<any>(false);
  const [selectedTemplateDescription, setSelectedTemplateDescription] =
    useState<any>("");
  const [ticketName, setTicketName] = useState<any>("");
  const [storeTemplateData, setStoreTemplateData] = useState<any>([]);
  const [templateAttachment, setTemplateAttachment] = useState<any[]>([]);
  const [externalNotetextareaValue, setExternalNoteTextareaValue] = useState(
    []
  );
  const [internalNoteValue, setInternalNoteValue] = useState([]);
  const [fetchTemplate, setFetchTemplates] = useState(false);

  const [showAdditionalField, setShowAdditionalField] = useState(false);
  const { data: ticketDynamicFields } = useTicketDynamicFieldsQuery({});
  const [files, setFiles] = useState([]);
  const [storeNewEmailId, setStoreNewEmailId] = useState("");
  const [isEditCOntactDetailsEnabled, setIsEditContactDetailsEnabled] = useState(false)
  const [statusOptions, setStatusOptions] = useState([])

  //Dynamic Fields //
  const [dynamicData, setDynamicData] = useState<any[]>([]);
  const [dynamicFields, setDynamicFields] = useState<any>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errors, setErrors] = useState<ErrorState>({
    title: "",
    ticketDescription: "",
    Email: "",
    Disposition: "",
  });

  const teamAssigneID = localStorage.getItem("teamId");

  const getTeamId = localStorage.getItem(USER_TEAM_ID);
  const [checkValue, setCheckValue] = useState<any>([]);
  const { data: Disposition } = useDispositionQuery(getTeamId);
  const [lastSelectedId, setLastSelectedId] = useState(null);


  const [options, setOptions] = useState(Disposition);
  const [selectedIds, setSelectedIds] = useState([]);
  const [skipDispositionQuery, setSkipDispositionQuery] =
    useState<boolean>(true);
  const [selectedTeams, setSelectedTeams] = useState<any>();

  const [status, setStatus] = useState("");
  const [statusId, setStatusId] = useState("");


  const [selectedStatusId, setSelectedStatusId] = useState("")
  const [emailValidation, setEmailValidation] = useState(false);

  const {
    data: fetchDispositionStatus,
    isLoading,
    error,
  } = useGetDispositionStatusQuery(lastSelectedId, {
    skip: skipDispositionQuery,
  });

  const apiPort = process.env.REACT_APP_API_PROTOCOL;

  useEffect(() => {
    setCheckValue(Disposition);
  }, [Disposition]);
  // Used this

  // useEffect(() => {
  //   setDynamicData([]);
  //   if (ticketDynamicFields) {
  //     setDynamicData(ticketDynamicFields);
  //   }
  // }, [ticketDynamicFields]);


  useEffect(() => {
    setDynamicData([]);
    if (ticketName) {
      setDynamicData([]);
      let filterDataById = ticketDynamicFields.filter(
        (name) => name?.ticket_name === ticketName
      );
      setDynamicData(filterDataById);
    }
  }, [ticketDynamicFields, ticketName]);

  // end




  useEffect(() => {

  }, [selectedStatusId])

  const validateForm = () => {
    const newErrors: ErrorState = {};

    if (!dynamicFields.title) {
      newErrors.title = "Title is required";
    }

    if (!dynamicFields.description) {
      newErrors.ticketDescription = "Ticket description is required";
    }

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      // Example: Add phone number validation logic here
      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        // If you have specific phone validation, you could add it here
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (!selectedIds || selectedIds.length === 0) {
        newErrors.selectedIds = "Disposition is required";
      }

      if (emailNotPresent) {
        newErrors.email = "Email is not present in the suggestions";
      }
      // Validation for Channel Field
      if (!dynamicFields.ticketChannel) {
        newErrors.ticketChannel = "Channel is required";
      }
      // Validation for Remarks
      if (!dynamicFields.dispositionDescription) {
        newErrors.dispositionDescription = "Remarks are required";
      }

      if (field.field_type === "text-area" && field.label === "remarks") {
        if (!fieldValue) {
          newErrors[field.label] = `${field.label} is required`;
        }
      }

      if (!status) {
        newErrors.status_type = "Status is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fetchingTenant = window.location.hostname.split(".")[0];

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return;
    }

    setIsButtonDisabled(true);



    const dynamicFieldData = Object.keys(dynamicFields).reduce((acc, key) => {
      const field = dynamicData.find((field) => field.label === key);
      if (field?.field_type === "date") {
        const formattedDate = new Date(dynamicFields[key])
          .toISOString()
          .replace("Z", "+00:00");
        acc[key] = formattedDate;
      } else {
        acc[key] = dynamicFields[key] || "";
      }
      return acc;
    }, {});

    try {
      const obj = {
        title: dynamicFields.title,
        description: dynamicFields.description,
        contact: selectedContact.id,
        ticket_channel: dynamicFields.ticketChannel,
        team: selectedTeams === undefined ? null : selectedTeams,
        disposition: lastSelectedId,
        disposition_description: dynamicFields.dispositionDescription,
        dynamic_fields: dynamicFieldData,
        status: status === "wip" ? statusId : null, // WIP
        closed_status: status === "closed" ? statusId : null, // closed
        status_type: status, // Use status type
        customer_notes: externalNotetextareaValue,
        internal_notes: internalNoteValue,
        attachments: files,
        template_attachments: templateAttachment?.map(
          (attachment) => attachment?.id
        ),
        team_assignee: selectedTeams === undefined ? null : selectedTeams, //need to be update
      };

      const response: any = await createTicket(obj);

      if (response.data) {
        setIsLoading(false); // Reset loading state
        closeModal(); // Close modal
        toast.success("Ticket created successfully!"); // Show success toast
        refetch();
        setLoading(true);
        // refetchWorkflow();
      } else {
        if (response.error) {
          const errorData = response.error.data;
          // Iterate over the keys in errorData and display the first error message found
          Object.keys(errorData).forEach((key) => {
            const errorMessage = errorData[key][0]; // Assuming the first error in the array is what you want
            toast.error(`${key}: ${errorMessage}`);
          });
          setIsLoading(false); // Reset loading state
        } else {
          setIsLoading(false); // Reset loading state
          toast.error("Failed to create ticket.");
        }
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      toast.error("An unexpected error occurred.");
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };

  const searchContactByMailID = (skey: string) => {
    axios
      .get(
        `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/search-by-email/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          params: {
            email: skey,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) setSuggestions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const openContactModal = () => {
    setAddNewContactModalOpen(true);
  };

  const closeContactModal = () => {
    setAddNewContactModalOpen(false);
  };

  useEffect(() => {
    teamsData && setTheTeams(teamsData);
  }, [teamsData]);

  const openTemplateModal = async () => {
    setIsModalOpen(true);
    setFetchTemplates(true);

    const response = await axios.get(
      `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/emails/templates/?page=1&`,
      {
        headers: {
          Authorization: `BEARER ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      }
    );
    setStoreTemplateData(response?.data);
  };

  const closeTemplateModal = () => {
    setIsModalOpen(false);
  };

  //External Note

  const externalNotehandler = useCallback(
    (e: any) => {
      const values = e.target.value
        .split("\n")
        .map((line: any) => ({ content: line, attachments: [] }));
      setExternalNoteTextareaValue(values);
    },
    [setExternalNoteTextareaValue]
  );

  useEffect(() => {
    if (selectedTemplateDescription && selectedTemplateDescription.content) {
      setExternalNoteTextareaValue(
        Array.isArray(selectedTemplateDescription.content)
          ? selectedTemplateDescription.content.map((item) => ({
            content: item,
          }))
          : [{ content: selectedTemplateDescription.content, attachments: [] }]
      );
    }
  }, [selectedTemplateDescription]);

  const internalNotehandler = (e) => {
    const newValue = e.target.value;

    if (newValue.trim() === "") {
      setInternalNoteValue([]);
    } else {
      // Otherwise, update the state with the new value
      const updatedNotes = [
        {
          content: newValue,
          attachments: [],
        },
      ];
      setInternalNoteValue(updatedNotes);
    }
  };

  // Disposition


  useEffect(() => {
    if (lastSelectedId) {
      const dispositionItem = checkValue?.find(
        (item) =>
          item.id === lastSelectedId ||
          item.sub_topics.some((sub) => sub.id === lastSelectedId)
      );
      setTicketName(dispositionItem?.ticket_name);

      if (dispositionItem) {
        if (dispositionItem?.sub_topics.length > 0) {
          const matchingSubTopic = dispositionItem?.sub_topics;
          setCheckValue(matchingSubTopic);
          setSkipDispositionQuery(true);
          setShowAdditionalField(false);
        } else {
          setSkipDispositionQuery(false);
          setShowAdditionalField(true);
        }
      }
    }
  }, [lastSelectedId]);
  



  const handleChange = (e) => {
    const { name, value } = e.target;
    const level = parseInt(name.replace("Level", ""));

    const newSelectedIds = [...selectedIds];
    newSelectedIds[level] = parseInt(value);

    // Reset subsequent IDs
    for (let i = level + 1; i < newSelectedIds.length; i++) {
      newSelectedIds[i] = undefined;
    }
    setSelectedIds(newSelectedIds);

    // Update last selected ID
    setLastSelectedId(parseInt(value));

    // Determine options for the next level
    let currentOptions = Disposition;
    for (let i = 0; i < level; i++) {
      const selectedId = newSelectedIds[i];
      if (selectedId !== undefined) {
        const found = currentOptions.find((item) => item.id === selectedId);
        if (found) {
          currentOptions = found.sub_topics;
        } else {
          currentOptions = [];
          break;
        }
      }
    }

    setOptions(currentOptions);

    // Handle new disposition selection
    const dispositionItem = currentOptions.find(
      (item) => item.id === parseInt(value)
    );
    if (dispositionItem) {
      if (dispositionItem.sub_topics.length > 0) {
        // If the new disposition has sub-topics, update the checkValue state
        setCheckValue(dispositionItem.sub_topics);
        setSkipDispositionQuery(true);
        setShowAdditionalField(false);
      } else {
        // If the new disposition does not have sub-topics, update the ticket name
        setTicketName(dispositionItem.ticket_name);
        setSkipDispositionQuery(false);
        setShowAdditionalField(true);
      }
      setErrors({});
    }
  };



  const filterDispositionData = (data) => {
    return data
      .map((item) => {
        // Recursively filter sub-topics
        const filteredSubTopics = filterDispositionData(item.sub_topics || []);
  
        // Keep only sub-topics with a ticket_name OR valid descendants
        const validSubTopics = filteredSubTopics.filter(
          (sub) => sub.ticket_name !== null || sub.sub_topics.length > 0
        );
  
        // Check if this item should be kept:
        const isValid = item.ticket_name !== null || validSubTopics.length > 0;
  
        return isValid
          ? { ...item, sub_topics: validSubTopics } // Keep valid topics
          : null; // Remove invalid topics
      })
      .filter(Boolean); // Remove null values
  };
  
  // Apply filtering before rendering
  const filteredDisposition = useMemo(() => {
    return Disposition ? filterDispositionData(Disposition) : [];
  }, [Disposition]);
  
  const renderDropdowns = (data, level) => {
    if (!data || data.length === 0) {
      return null;
    }
  
    return (
      <>
        {level > 0 && <label className="required mt-4">Select Disposition</label>}
        <select
          name={`Level${level}`}
          className="form-select form-select-lg form-select-solid"
          onChange={handleChange}
          value={selectedIds[level] || ""}
        >
          <option value="" disabled>
            Select
          </option>
          {data.map((option) => (
            <option key={option.id} value={option.id}>
              {option.topic}
            </option>
          ))}
        </select>
        {selectedIds[level] &&
          renderDropdowns(
            data.find((item) => item.id === selectedIds[level])?.sub_topics || [],
            level + 1
          )}
      </>
    );
  };

  // Current implementation:
  // we are checking the lastSelectedId and if it's ticket_name is null then we make the setSkipDispositionQuery(false).
  // REQUIREMENT:
  // I want you to pre-finalize those sub_topics whose ticket_name is null which willl be lastSelected and eliminate that from the list.

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };
  const formatStatusData = (status) => {
    const generateUUID = () => uuidv4(); // Generate a UUID

    if (typeof status.status === "string") {
      // Open and Reopen statuses (no ID)
      return {
        status_type: status.status_type,
        status: status.status,
        id: null, // No ID for statuses without ID
        rid: generateUUID(), // Generate a UUID for rid
      };
    } else {
      // WIP and Closed statuses (with ID)
      return {
        id: status?.status?.id,
        status_type: status?.status_type,
        status: status?.status?.status,
        rid: generateUUID(), // Generate a UUID for rid
      };
    }
  };
  useEffect(() => {
    if (fetchDispositionStatus?.all_statuses) {
      const statusOptions1 = fetchDispositionStatus?.all_statuses?.map((status) =>
        formatStatusData(status)
      );
      setStatusOptions(statusOptions1);
    }


  }, [fetchDispositionStatus]);

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
    } catch (error) {
      console.error("Error reading files:", error);
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  useEffect(() => {
    if (error !== undefined) {
      toast.error("No workflow mapped for this disposition");
    }
  }, [error]);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailNotPresent(false);
      setEmailValidation(true)
      setStoreNewEmailId("");

    } else {
      searchContactByMailID(email);
      setEmailValidation(false)
      setStoreNewEmailId(email);
    }
  };


  // const handleEmailChange = (event) => {
  //   const email = event.target.value;
  //   searchContactByMailID(email);
  //   setEmailNotPresent(false);
  //   setStoreNewEmailId(email);
  // };

  const handleEmailBlur = (event) => {
    const email = event.target.value;
    // Show error if the email is not selected from suggestions
    const emailExists = suggestions.some(
      (suggestion) => suggestion.email === email
    );

    if (emailExists) {
      setEmailNotPresent(false);
    } else {
      setEmailNotPresent(true);
    }
    setErrors({});
  };

  const handleInputChange = (label, value) => {
    setErrors({});
    // Check if value is in "date-time" format (e.g., ISO 8601 string or valid date)
    const field = dynamicData.find((field) => field.label === label);
    if (!isNaN(new Date(value).getTime()) && field?.field_type === "date-time") {
      // If the value is a valid date, process the date value
      const localDate = new Date(value);
      // If you want to store the date in UTC, use toISOString()
      const utcDate = new Date(localDate.toISOString());
      setDynamicFields((prevFields) => ({
        ...prevFields,
        [label]: utcDate.toISOString(), // Store as ISO string in UTC
      }));
    } else if (label === "remarks") {
      setDynamicFields((prevState) => ({ ...prevState, remarks: value }));
    }
    else {
      setDynamicFields((prevState) => ({ ...prevState, [label]: value }));
    }
  };
  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setErrors({});
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      // Manually format the date to ensure no timezone conversion issues
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  // Channel Fields
  const channelOptions = [
    { value: "inbound_phone", label: "Inbound Phone" },
    { value: "outbound_phone", label: "Outbound Phone" },
    { value: "App", label: "App" },
    { value: "Email", label: "Email" },
    { value: "Chat", label: "Chat" },
    { value: "Whatsapp", label: "WhatsApp" },
    { value: "Facebook", label: "Facebook" },
  ];
  const handleStatusChange = (value) => {
    console.log("selectedStatusId", value)
    if (value) {
      const matchedStatus = statusOptions.find((status) => status.rid === value);
      if (matchedStatus) {
        setSelectedStatusId(value)
        console.log("selectedStatusId", matchedStatus)
        // status: status === "wip" ? statusId : null, // WIP
        // closed_status: status === "closed" ? statusId : null, // closed
        // status_type: status, // Use status type
        setStatus(matchedStatus.status_type)
        setStatusId(matchedStatus.id)
      }
    }
  }

  useEffect(() => {

    console.log(storeNewEmailId)
  }, [storeNewEmailId])

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const getFormattedDate = (date: string) => {
    if (!date) return "";

    const dt = new Date(date); // Parses the UTC date
    const year = dt.getFullYear();
    const month = String(dt.getMonth() + 1).padStart(2, "0");
    const day = String(dt.getDate()).padStart(2, "0");
    const hours = String(dt.getHours()).padStart(2, "0");
    const minutes = String(dt.getMinutes()).padStart(2, "0");

    // Format for datetime-local in UTC, ignoring timezone conversions
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };


  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Create Ticket
        </label>
      </div>
      <div className="w-100 mb-4">
        <label className="required">Title</label>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="off"
          value={dynamicFields.title || ""}
          onChange={(e) => handleInputChange("title", e.target.value)}
          className="form-control form-control-lg form-control-solid"
        />
        {errors.title && <div className="text-danger">{errors.title}</div>}
      </div>

      <div className="w-100 mb-4">
        <label className="required">Ticket Description</label>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          autoComplete="off"
          value={dynamicFields.description || ""}
          onChange={(e) => handleInputChange("description", e.target.value)}
          className="form-control form-control-lg form-control-solid"
        />
        {errors.ticketDescription && (
          <div className="text-danger">{errors.ticketDescription}</div>
        )}
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Customer Details
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        <div className="col-xl-6">
          <label className="required">Email id</label>
          <Autocomplete
            freeSolo
            options={suggestions.map((option) => option.email)}
            onInputChange={(event, newInputValue) => {
              searchContactByMailID(newInputValue);
            }}
            onChange={(event, newValue) => {
              const selectedSuggestion = suggestions.find(
                (suggestion) => suggestion.email === newValue
              );
              setSelectedContact(selectedSuggestion ? selectedSuggestion : 0);
              // Reset email not present error when an option is selected
              setEmailNotPresent(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="form-control form-control-lg form-control-solid"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={emailNotPresent}
              />
            )}
          />
          {emailNotPresent && storeNewEmailId !== "" ? (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert">
                Email is not present. Please create a new contact.
              </span>
            </div>
          ) : (
            errors.email && (
              <div className="fv-plugins-message-container text-danger">
                <span role="alert">{errors.email}</span>
              </div>
            )
          )}

          {(emailValidation && emailNotPresent) && (<>  <div className="fv-plugins-message-container text-danger">
            <span role="alert">
              Enter a valid email
            </span>
          </div></>)}
        </div>
        <div className="col-xl-6">
          <label className="">Customer Name</label>
          <input
            className="form-control form-control-lg form-control-solid" // {clsx(
            type="text"
            autoComplete="off"
            value={emailNotPresent ? "" : selectedContact?.name}
            disabled
          />
        </div>
        {emailNotPresent && storeNewEmailId !== "" && (
          <>
            <div className="text-end mt-6 mb-4">
              <button
                className="btn btn-sm btn-secondary"
                onClick={openContactModal}
                type="button"
              >
                <i className="bi bi-plus fs-2 me-2"></i>
                Create Contact
              </button>
            </div>
          </>
        )}
        <ReactModal
          isOpen={isAddNewContactModalOpen}
          onRequestClose={() => setAddNewContactModalOpen(false)}
          style={customModalStyles}
          contentLabel="New Contact"
        >
          <AddNewTicketContactModal
            closeModal={() => setAddNewContactModalOpen(false)}
            emailNotPresent={emailNotPresent}
            setSelectedContact={setSelectedContact}
            storeNewEmailId={storeNewEmailId}
            setEmailNotPresent={setEmailNotPresent}
          />
        </ReactModal>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Channel
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-8">
        {/* Select Channel */}
        <div className="col-xl-6">
          <label className="required">Select Channel</label>
          <FormControl
            fullWidth
            className="form-control form-control-lg form-control-solid"
          >
            <Select
              labelId="channel-label"
              id="channel-select"
              value={dynamicFields.ticketChannel || ""}
              onChange={(e) =>
                handleInputChange("ticketChannel", e.target.value)
              }
              required
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {channelOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.ticketChannel && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.ticketChannel)}
            </div>
          )}
        </div>

        {/* Assign to Team */}
        <div className="col-xl-6">
          <label>Assign to Team</label>
          <FormControl
            fullWidth
            className="form-control form-control-lg form-control-solid"
          >
            <Select
              labelId="team-label"
              id="team-select"
              value={selectedTeams || ""}
              onChange={(e) => setSelectedTeams(e.target.value)}
              MenuProps={MenuProps}
            >
              <MenuItem value="" disabled>
                Select Team
              </MenuItem>
              {theTeams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Disposition
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-6">
        <div className="col-xl-6">
          <label className="required">Disposition</label>
          {renderDropdowns(filteredDisposition, 0)}

          {errors.selectedIds && (
            <div className="mt-2" style={{ color: "red" }}>
              {capitalizeFirstLetter(errors.selectedIds)}
            </div>
          )}
        </div>

        <div className="col-xl-6">
          <label className="required">Remarks</label>
          <textarea
            className="form-control form-control-lg form-control-solid"
            rows={1}
            autoComplete="off"
            value={dynamicFields.dispositionDescription}
            onChange={(e) =>
              handleInputChange("dispositionDescription", e.target.value)
            }
          />
          {errors.dispositionDescription && (
            <div className="text-danger">{errors.dispositionDescription}</div>
          )}
        </div>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Ticket Details
        </label>
      </div>

      <div className="row g-5 g-xl-8 mb-4">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {showAdditionalField &&
              error === undefined &&
              dynamicData?.map((field: any) => (
                <React.Fragment key={field?.label}>
                  <div key={field.label} className="col-xl-4 mb-3">
                    <InputLabel htmlFor={`dynamic_fields.${field.label}`}>
                      {field.label}
                      {field.is_required && <span className="required"></span>}
                    </InputLabel>
                    {field.field_type === "text" && (
                      <TextField
                        disabled={isFieldDisabled(field?.is_editable)}
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}
                        id={`dynamic_fields.${field.label}`}
                        variant="outlined"
                        fullWidth
                        // disabled={!field?.is_editable}
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "email" && (
                      <TextField
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        type="email"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "number" && (
                      <TextField
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        type="number"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "date" && (
                      <TextField
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        type="date"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            style: { textTransform: "uppercase" }, // Optional: uppercase input text
                          },
                        }}
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "time" && (
                      <TextField
                        type="time"
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "date-time" && (
                      <TextField
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        type="datetime-local"
                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        defaultValue={
                          getFormattedDate(dynamicFields[field.label]) || dynamicFields[field.label] || ""
                        }
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            style: { textTransform: "uppercase" }, // Optional: uppercase input text
                          },
                        }}
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "text-area" && (
                      <TextField
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        id={`dynamic_fields.${field.label}`}
                        // disabled={!field?.is_editable}
                        variant="outlined"
                        fullWidth
                        multiline
                        value={dynamicFields[field.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field.label, e.target.value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {field.field_type === "drop-down" && (
                      <FormControl fullWidth>

                        <Select

                          disabled={isFieldDisabled(field?.is_editable)}
                          style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                          labelId={`select-label-${field.label}`}
                          // disabled={!field?.is_editable}
                          id={`select-${field.label}`}
                          value={dynamicFields[field.label] || ""}
                          onChange={(e) =>
                            handleInputChange(field.label, e.target.value)
                          }
                          required={field.is_required}
                        >
                          <MenuItem selected disabled>
                            Select
                          </MenuItem>
                          {field?.choices?.map((option: any) => (
                            <MenuItem key={option.id} value={option.choice}>
                              {option.choice}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {field.field_type === "check-box" && (
                      <FormControl fullWidth>
                        <Select
                          style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                          disabled={isFieldDisabled(field?.is_editable)}

                          labelId={`checkbox-label-${field.label}`}
                          // disabled={!field?.is_editable}

                          id={`checkbox-${field.label}`}
                          required={field.is_required}
                          multiple
                          value={dynamicFields[field.label] || []} // Ensure value is an array of IDs
                          onChange={(e: any) =>
                            handleCheckboxChange(e, field.label)
                          }
                          renderValue={(selected) => {
                            // Create an array of selected choice labels
                            const selectedLabels = (
                              field?.multiple_choices || []
                            )
                              .filter((choice) =>
                                selected.includes(String(choice.id))
                              )
                              .map((choice) => choice.choice);
                            return selectedLabels.join(", ");
                          }}
                        >
                          {field?.multiple_choices?.map((choice: any) => (
                            <MenuItem key={choice.id} value={String(choice.id)}>
                              <Checkbox
                                disabled={isFieldDisabled(field?.is_editable)}

                                checked={dynamicFields[field.label]?.includes(
                                  String(choice.id)
                                )}
                              />
                              <ListItemText primary={choice.choice} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {field.field_type === "tel-phone" && (
                      <PhoneInput
                        style={{ background: isFieldDisabled(field?.is_editable) ? "#eff2f5" : "transparent" }}

                        disabled={isFieldDisabled(field?.is_editable)}

                        defaultCountry="IN"
                        value={dynamicFields[field.label] || "+91"}
                        // disabled={!field?.is_editable}

                        onChange={(value) =>
                          handleInputChange(field.label, value)
                        }
                        required={field.is_required}
                      />
                    )}
                    {errors[field.label] && (
                      <div className="mt-2" style={{ color: "red" }}>
                        {capitalizeFirstLetter(errors[field.label])}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
          </>
        )}
        <div className="col-xl-6">
          <label>Attachments</label>
          <div className="mt-2">
            <Button
              // variant="contained"
              component="label"
              className="text-primary"
              startIcon={<AttachFileIcon />}
            >
              Add Attachment
              <input type="file" hidden multiple onChange={handleFileChange} />
            </Button>
            <Tooltip
              title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
              arrow
            >
              <IconButton
                aria-label="info"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "11px",
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {files.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
            {files.map((file, index) => (
              <Chip
                key={index}
                label={file.file_name}
                onDelete={handleDeleteFile(file)}
              />
            ))}
          </Box>
        )}
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Notes
        </label>
      </div>
      <div className="row g-5 g-xl-8 mb-4">
        <div className="col-xl-6">
          <label>Internal Notes</label>
          <textarea
            className="form-control form-control-lg form-control-solid"
            rows={1}
            autoComplete="off"
            onChange={internalNotehandler}
            value={internalNoteValue[0]?.content}
          />
        </div>
        <div className="col-xl-6" style={{ position: "relative" }}>
          <label>External Notes</label>
          <div className="textarea-container">
            <textarea
              className="form-control form-control-lg form-control-solid"
              rows={1}
              autoComplete="off"
              value={externalNotetextareaValue
                .map((item) => item.content)
                .join("\n")}
              onChange={externalNotehandler}
            />

            <i
              data-toggle="tooltip"
              data-placement="top"
              title="Select Template"
              className="bi bi-pen"
              style={{
                position: "absolute",
                top: "40px",
                right: "30px",
                cursor: "pointer",
              }}
              onClick={openTemplateModal}
            ></i>
          </div>
        </div>
      </div>

      <div className="text-center mb-3">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Status
        </label>
      </div>
      <div className="row g-5 g-xl-8 mb-4">
        <div className="col-xl-6">
          <label className="required">Status</label>
          <select
            className="form-select form-select-lg form-control-solid"
            onChange={(e) => handleStatusChange(e.target.value)}
            value={selectedStatusId || ""}
          >
            <option value="" label="Select" />
            {statusOptions?.map((statusItem) => (
              <option
                key={statusItem.rid} // Make sure key corresponds to the unique rid
                value={statusItem.rid} // Use rid as the value
                label={statusItem.status} // Display the status text
              >
                {statusItem.status}
              </option>
            ))}
          </select>
          {/* {errors.status_type && (
            <div className="text-danger">{errors.status_type}</div>
          )} */}
        </div>
      </div>

      <div className="text-end mt-3 mb-4">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={submitHandler}
          disabled={isButtonDisabled} // Disable button based on state
        >
          {loading ? (
            <>
              Creating... <Loader />
            </>
          ) : (
            "Create"
          )}
        </button>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeTemplateModal}
        style={customModalStyles}
        contentLabel="Email templates"
      >
        <EmailTemplateModal
          closeModal={closeTemplateModal}
          setSelectedTemplateDescription={setSelectedTemplateDescription}
          storeTemplateData={storeTemplateData}
          setTemplateAttachment={setTemplateAttachment}
        />
      </ReactModal>
    </>
  );
};

export default TicketModal;
