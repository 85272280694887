import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const createTask = createApi({
  reducerPath: "createTask",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    createTask: builder.mutation({
      query: (body) => ({
        url: `v1/tickets/ticket-event/`,
        method: "POST",
        body,
      }),
    }),


    getTaskDetails: builder.query({
      query: (particularTaskId) => ({
        url: `v1/tickets/ticket-event/${particularTaskId}`,
        method: "GET",
      }),
    }),

    updateTaskDetails: builder.mutation({
      query: (particularTaskId) => ({
        url: `v1/tickets/ticket-event/${particularTaskId}`,
        method: "GET",
      }),
    }),


    getTaskList: builder.query({
      query: () => ({
        url: `v1/tickets/ticket-event/`,
        method: "GET",
      }),
    }),

  }),
});

export const { useCreateTaskMutation, useGetTaskDetailsQuery, useGetTaskListQuery, useUpdateTaskDetailsMutation } = createTask;
