import React, { useContext, useEffect, useRef, useState } from 'react'
import ChatContext from '../../context/ChatContext'
import { Dropdown } from '../../helpers/components/Dropdown'
import { ChatInner } from '../../components/conversations/ChatInner'
import SidePanel from '../../components/conversations/SidePanel'
import { KTSVG } from '../../helpers/components/KTSVG'
import { toast } from 'react-toastify'
import { createInitials } from '../../utils/functions/createInitials'
import {
  GET_CHAT_ROOM_META_BY_CHATROOM_ID,
  GET_SESSIONS_BY_CHAT_ROOM_ID,
  GET_USERS
} from '../../graphql/query'
import { useQuery } from '@apollo/client'
import { IChatbotState } from '../../utils/interfaces/IChatbotState'
import SkeletonChatRoom from './SkeletonChatRoom'
import { isEqual } from 'lodash'
import UserContext from '../../context/UserContext'
import CsvDownloader from 'react-csv-downloader'
import TeamPanel from './TeamPanel'
import AssignedChatDropdown from './AssignedChatDropdown'
import { useNavigate } from 'react-router-dom'
import ChatPanelComponent from './ChatPanelComponent'

import './style.css'
interface IProps {
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
  chatRoomID: number
  // agentID: number
  setSearchParams: any
  searchParams: any
  setIsData2: any
  setIsData3: any
  handleOpenChat: any
  handleCloseChat: any
  handleMinimizeChat: any
  chatPanels: any
  disabledIndexes: any
}

const ChatRoom: React.FC<IProps> = ({
  type,
  chatRoomID,
  // agentID,
  setSearchParams,
  searchParams,
  setIsData2,
  setIsData3,
  handleOpenChat,
  handleCloseChat,
  handleMinimizeChat,
  chatPanels,
  disabledIndexes
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [updateChatRoomsFlag, setUpdateChatRoomsFlag] = useState<boolean>(false)
  const [chatMenuDroppedDown, setChatMenuDroppedDown] = useState<boolean>(false)
  const [assignedMenuDroppedDown, setAssignedMenuDroppedDown] = useState<boolean>(false)
  const chatDropdownMenuRef1 = useRef<HTMLDivElement>(null)
  const assignedDropdownMenuRef = useRef<HTMLDivElement>(null)
  const chatRoomsArrayRef = useRef<any[]>([])
  const { currentUserRole, currentUser } = useContext(UserContext)
  const navigate = useNavigate()

  const {
    state,
    setState,
    isDashUserOnline,
    getUnassignedChatrooms,
    getAssignedChatrooms,
    assignChatroom,
    markChatRoomAsResolved,
    setTotalUnassignedChats,
    setTotalUnreadAssignedChats,
    msgMarkedAsReadResponse,
    setMsgMarkedAsReadResponse,
    getPrevMessages,
    markMessageAsRead,
    flagToTriggerMarkMessageAsRead,
    setFlagToTriggerMarkMessageAsRead,
    unreadMessagesArray,
    setUnreadMessagesArray,
    sendMessageToEndUser
  } = useContext(ChatContext)
  const [selectedUserId, setSelectedUserId] = useState<any>(0)
  const didComponentMountRef = useRef<boolean>(false)
  const convArrayRef = useRef<any[]>([])
  const updateConvRef = useRef<boolean>(false)

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatDropdownMenuRef1.current &&
        !chatDropdownMenuRef1.current.contains(event.target)
      ) {
        setChatMenuDroppedDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onSelectActiveUser = (e: any) => {
    setSelectedUserId(e.target.value)
  }

  const handleMarkMessgaeAsRead = () => {
    if (flagToTriggerMarkMessageAsRead === true && unreadMessagesArray.length > 0) {
      const messageIds = unreadMessagesArray.map((message: any) => message.messageId)
      markMessageAsRead(messageIds, chatRoomID)
      setFlagToTriggerMarkMessageAsRead(false)
      setUnreadMessagesArray([])
    }
  }

  const handleChatAssign = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectedUserId < 1) {
      console.log({ selectedUserId })
    } else {
      setChatMenuDroppedDown(false)
      setTimeout(() => {
        setLoading(true)
      })
      assignChatroom(chatRoomID, parseInt(selectedUserId.toString())).then((res: any) => {
        setTotalUnassignedChats((prevValue: number) => prevValue - 1)
        // if (!!res && res.messageResponse === 'SUCCESS') {
        //   toast.success('Chatroom Assigned Successfully')
        // } else {
        //   toast.warn('Unable to assign chatroom')
        // }
        if (!!res && res.messageResponse === 'SUCCESS') {
          toast.success('Chatroom Assigned Successfully')

          // Here we remove the conversation associated with the chatRoomID
          setState((prevState: IChatbotState) => {
            const updatedPanelBasedConversation = prevState.panelBasedConversation.filter(
              (panel) => panel.chatRoomId !== chatRoomID
            )

            return {
              ...prevState,
              panelBasedConversation: updatedPanelBasedConversation
            }
          })
        } else {
          toast.warn('Unable to assign chatroom')
        }
        getUnassignedChatrooms().then((resUnassigned: any) => {
          setLoading(false)
          if (!!resUnassigned) {
            const unassignedChatrooms = resUnassigned.unassigned_chatrooms
            const unassignedChatRoomsArray = unassignedChatrooms
              .filter((chatRoom: any) => !!chatRoom)
              .map((chatRoom: any) => {
                const initials =
                  !!chatRoom.chatRoomMeta &&
                    chatRoom.chatRoomMeta.length > 0 &&
                    chatRoom.chatRoomMeta.find((metaData: any) => metaData.name === 'Name')
                    ? createInitials(
                      chatRoom.chatRoomMeta.find((metaData: any) => metaData.name === 'Name')
                        .value
                    )
                    : !!chatRoom.id
                      ? createInitials(chatRoom.id)
                      : createInitials('Unnamed')
                return {
                  ...chatRoom,
                  initials
                }
              })
              .sort((a: any, b: any) => {
                // Sort by putting last chatRoomID in first
                return parseInt(b.id) - parseInt(a.id)
              })
            // console.log(unassignedChatRoomsArray)
            chatRoomsArrayRef.current = unassignedChatRoomsArray
            // console.log(chatRoomsArrayRef.current)
            setUpdateChatRoomsFlag(true)
            console.log('Set to update unassigned chatRooms')
          }
        })
        // setState((prevState: IChatbotState) => ({
        //   ...prevState,
        //   conversation: []
        // }))
        // setState((prevState: IChatbotState) => {
        //   const updatedPanelBasedConversation = prevState.panelBasedConversation.map((panel) => {
        //     if (panel.chatRoomId === chatRoomID) {
        //       return {
        //         ...panel,
        //         conversation: []
        //       }
        //     }
        //     return panel
        //   })

        //   return {
        //     ...prevState,
        //     panelBasedConversation: updatedPanelBasedConversation
        //   }
        // })
      })
    }
  }

  // Handle 'message-mark-as-read' response to display tick in the UI
  // useEffect(() => {
  //   if (!!msgMarkedAsReadResponse) {
  //     const response = msgMarkedAsReadResponse
  //     const chatRoomId = response.payloadForRedis.content.chatId
  //     if (!!response.payloadForRedis && chatRoomId === chatRoomID) {
  //       const { messageId } = response.payloadForRedis
  //       // Include newly received readByRecipient Data on the exisitng conversations array
  //       const newConvArray = Array.from(state.conversation).map((item: any) => {
  //         console.log(item.messageId)
  //         return item.messageId === messageId ? response.payloadForRedis : item
  //       })
  //       setState(
  //         (prevState: IChatbotState) => ({
  //           ...prevState,
  //           conversation: newConvArray,
  //           enableUserInput: true
  //         }),
  //         (updatedState: IChatbotState) => {
  //           console.log(updatedState.conversation)
  //         }
  //       )
  //     }
  //   }
  //   return () => {
  //     setMsgMarkedAsReadResponse(null)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [msgMarkedAsReadResponse])
  useEffect(() => {
    if (!!msgMarkedAsReadResponse) {
      const response = msgMarkedAsReadResponse
      const chatRoomId = response.payloadForRedis.content.chatId

      if (!!response.payloadForRedis && chatRoomId === chatRoomID) {
        const { messageId } = response.payloadForRedis

        // Update panelBasedConversation for the specific chatRoomID
        setState(
          (prevState: IChatbotState) => {
            const updatedPanelBasedConversation = prevState.panelBasedConversation.map((panel) => {
              if (panel.chatRoomId === chatRoomId) {
                const updatedConversation = panel.conversation.map((item: any) => {
                  return item.messageId === messageId ? response.payloadForRedis : item
                })
                return {
                  ...panel,
                  conversation: updatedConversation
                }
              }
              return panel
            })

            return {
              ...prevState,
              panelBasedConversation: updatedPanelBasedConversation,
              enableUserInput: true
            }
          },
          (updatedState: IChatbotState) => {
            console.log(updatedState.panelBasedConversation)
          }
        )
      }
    }

    return () => {
      setMsgMarkedAsReadResponse(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgMarkedAsReadResponse])

  // useEffect(() => {
  //   if (
  //     isDashUserOnline &&
  //     (type === 'Unassigned' || type === 'Assigned') &&
  //     !!chatRoomID &&
  //     chatRoomID > 0
  //   ) {
  //     getPrevMessages(chatRoomID)
  //       .then((res: any) => {
  //         if (!!res.messages && res.messages.length > 0) {
  //           const newConvArray = res.messages
  //           convArrayRef.current = newConvArray
  //           updateConvRef.current = true

  //           setState(
  //             (prevState: IChatbotState) => ({
  //               ...prevState,
  //               conversation: newConvArray
  //             }),
  //             (updatedState: IChatbotState) => {
  //               console.log('Conversation loaded for chatRoom ID: ' + chatRoomID)
  //             }
  //           )
  //           console.log(
  //             'Set to call useEffect to update conversations for chatRoomID: ' + chatRoomID
  //           )
  //         } else {
  //           setState((prevState: IChatbotState) => ({
  //             ...prevState,
  //             conversation: []
  //           }))
  //         }
  //       })
  //       .catch(() => {
  //         console.log('No Conversations')
  //       })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chatRoomID, isDashUserOnline])
  useEffect(() => {
    if (
      isDashUserOnline &&
      (type === 'Unassigned' || type === 'Assigned') &&
      !!chatRoomID &&
      chatRoomID > 0
    ) {
      getPrevMessages(chatRoomID)
        .then((res: any) => {
          if (!!res.messages && res.messages.length > 0) {
            const newConvArray = res.messages
            convArrayRef.current = newConvArray
            updateConvRef.current = true

            setState(
              (prevState: IChatbotState) => {
                const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
                  (panel) => {
                    if (panel.chatRoomId === chatRoomID) {
                      return {
                        ...panel,
                        conversation: newConvArray
                      }
                    }
                    return panel
                  }
                )

                return {
                  ...prevState,
                  panelBasedConversation: updatedPanelBasedConversation
                }
              },
              (updatedState: IChatbotState) => {
                console.log('Conversation loaded for chatRoom ID: ' + chatRoomID)
              }
            )
          } else {
            setState((prevState: IChatbotState) => {
              const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
                (panel) => {
                  if (panel.chatRoomId === chatRoomID) {
                    return {
                      ...panel,
                      conversation: []
                    }
                  }
                  return panel
                }
              )

              return {
                ...prevState,
                panelBasedConversation: updatedPanelBasedConversation
              }
            })
          }
        })
        .catch(() => {
          console.log('No Conversations')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomID, isDashUserOnline])

  useEffect(() => {
    if (updateChatRoomsFlag === true) {
      const chatRoomsArray = chatRoomsArrayRef.current
      // console.log(chatRoomsArray)
      console.log('useEffect called to update Chat Rooms array !')
      setState(
        (prevState: IChatbotState) => ({
          ...prevState,
          chatRooms: chatRoomsArray
        }),
        (updatedState: IChatbotState) => {
          // console.log(updatedState.chatRooms)
          if (updatedState.chatRooms.length === 0) {
            if (setSearchParams) {
              setSearchParams({ type: type as string, chatRoomId: '0' })
            }
          }
          if (didComponentMountRef.current === false) {
            // Check if the user was previously in a conversations page, to persist the state
            if (!localStorage.getItem('botgoDashChatRoomId')) {
              // Set Initial Chat ID to be that of the latest conversation
              // setChatRoomID(parseInt(chatRoomsArray[0].id))
            }
            didComponentMountRef.current = true
          }
        }
      )
      return () => {
        setUpdateChatRoomsFlag(false)
      }
    }
  })

  useEffect(() => {
    if (!!isDashUserOnline) {
      console.log('ChatRoomID changed to ' + chatRoomID)
      refetchChatRoomMetaData({
        input: {
          chatRoomId: chatRoomID
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomID, isDashUserOnline])

  useEffect(() => {
    const convArray = convArrayRef.current

    if (updateConvRef.current === true) {
      console.log('useEffect called to update conversation array!')

      setState((prevState: IChatbotState) => {
        const updatedPanelBasedConversation = prevState.panelBasedConversation.map((panel) => {
          if (panel.chatRoomId === chatRoomID) {
            return {
              ...panel,
              conversation: convArray
            }
          }
          return panel
        })

        return {
          ...prevState,
          panelBasedConversation: updatedPanelBasedConversation
        }
      })

      return () => {
        updateConvRef.current = false
      }
    }
  })

  const defaultActiveUserOption = {
    value: 0,
    label: 'Unassigned'
  }
  let activeUserOptions: Array<{ value: number; label: string }> = [defaultActiveUserOption]


  const {
    error: error2,
    data: data2,
    refetch: refetchSessionsByChatRoomId
  } = useQuery(GET_SESSIONS_BY_CHAT_ROOM_ID, {
    variables: {
      input: {
        chatRoomId: chatRoomID
      }
    },
    context: {
      headers: {
        'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
      }
    }
  })

  const {
    loading: loading3,
    error: error3,
    data: data3,
    refetch: refetchChatRoomMetaData
  } = useQuery(GET_CHAT_ROOM_META_BY_CHATROOM_ID, {
    variables: {
      input: {
        chatRoomId: chatRoomID
      }
    },
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
      }
    }
  })

  const {
    loading: loading4,
    error: error4,
    data: data4
  } = useQuery(GET_USERS, {
    skip: currentUserRole.agent || currentUserRole.operator,
    context: {
      headers: {
        'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
      }
    }
  })

  useEffect(() => {
    if (!!data2 && !!data3) {
      if (setIsData2 && setIsData3) {
        setIsData2(data2)
        setIsData3(data3)
      }
    }
  }, [data2, data3])

  if (error4) return <h1>{error4.message}</h1>
  if (error3) return <h1>{error3.message}</h1>
  if (error2) return <h1>{error2.message}</h1>
  if (loading || loading3) return <SkeletonChatRoom />

  if ((!!chatRoomID && type === 'Resolved') || type === 'Chat-Report') {
    refetchSessionsByChatRoomId({
      input: {
        chatRoomId: chatRoomID
      }
    })
  }

  if ((data2 && !!chatRoomID && chatRoomID > 0 && type === 'Resolved') || type === 'Chat-Report') {
    const newConvArray: any[] = [] //Need to explicitly define the Type of array objects
    const sessions = data2?.sessionsByChatRoomId
    if (!!sessions && sessions.length > 0) {
      sessions.forEach((session: any) => {
        if (!!session?.messageRequest && session?.messageRequest.length > 0) {
          session?.messageRequest.forEach((message: any) => {
            const time = message?.time
            const content = message?.content
            newConvArray.push({ content, time })
          })
        } else {
          newConvArray.push({ content: { message: 'No message in this Chat', side: 'center' } })
        }
      })
    } else {
      newConvArray.push({ content: { message: 'No conversation to show', side: 'center' } })
    }
    // Upto this line code will be executed on each re-render
    // ----------
    // Following lines will execute conditionally, if and only if fetched convArray is changed
    if (!isEqual(convArrayRef.current, newConvArray)) {
      convArrayRef.current = newConvArray
      updateConvRef.current = true
      console.log('Set to call useEffect to update conversations')
    }
  }

  if (loading4) {
    activeUserOptions = [
      {
        value: -1,
        label: 'loading users...'
      }
    ]
  }

  if (data4 && type === 'Unassigned') {
    const users = data4.users
    const activeUsers = users
      .filter((user: any) => user.status === 'Active')
      .map((user: any) => ({ value: user.id, label: user.name }))
    activeUserOptions = [defaultActiveUserOption, ...activeUsers]
  }

  const onAssignTo = (value: boolean) => {
    setChatMenuDroppedDown(value)
  }

  const formatDate = (isoString: string | number | Date) => {
    const date = new Date(isoString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(2)
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    return `${day}/${month}/${year} ${hours}:${minutes}`
  }

  // Function to generate CSV data
  const generateCsvData = () => {
    if (!data2 || !data2?.sessionsByChatRoomId || !data2?.sessionsByChatRoomId?.length) {
      return []
    }

    const session = data2?.sessionsByChatRoomId[0]
    const csvData = session?.messageRequest?.map((message: any, index: number) => {
      let name = ''
      if (message?.content?.side === 'lhs') {
        name = session?.botName
      } else if (message?.content?.side === 'rhs') {
        name = `CONV-${session?.chatRoomId}`
      } else if (message?.content?.side === 'dash-user') {
        name = session?.agentName
      }

      const formattedTime = formatDate(message.time)

      return {
        'S.No': index + 1,
        'Date and Time': formattedTime,
        Name: name,
        Message: message?.content?.message
      }
    })

    return csvData
  }

  const csvData = generateCsvData()
  const csvHeaders = [
    { id: 'S.No', displayName: 'S.No' },
    { id: 'Date and Time', displayName: 'Date and Time' },
    { id: 'Name', displayName: 'Name' },
    { id: 'Message', displayName: 'Message' }
  ]

  const hearderName = () => {
    if ((type === 'Unassigned' || type === 'Assigned') && chatRoomID > 0) {
      return (
        <div className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
          CONV -{' '}
          {state.chatRooms.find((chatRoom: any) => parseInt(chatRoom.id) === chatRoomID)
            ?.chatRoomMeta?.length > 0 &&
            state.chatRooms
              .find((chatRoom: any) => parseInt(chatRoom.id) === chatRoomID)
              ?.chatRoomMeta.find((metaData: any) => metaData.name === 'Name')
            ? state?.chatRooms
              .find((chatRoom: any) => parseInt(chatRoom.id) === chatRoomID)
              .chatRoomMeta.find((metaData: any) => metaData.name === 'Name').value
            : state.chatRooms.find((chatRoom: any) => parseInt(chatRoom.id) === chatRoomID)?.id
              ? state.chatRooms.find((chatRoom: any) => parseInt(chatRoom.id) === chatRoomID)?.id
              : ''}
        </div>
      )
    } else {
      return (
        <div className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
          CONV -{' '}
          {state.chatRooms?.find((chatRoom: any) => parseInt(chatRoom.crId) === chatRoomID)?.crmName
            ?.length > 0 &&
            state.chatRooms?.find((chatRoom: any) => parseInt(chatRoom.crId) === chatRoomID)?.crmName
            ? state.chatRooms?.find((chatRoom: any) => parseInt(chatRoom.crId) === chatRoomID)
              .crmValue
            : state.chatRooms?.find((chatRoom: any) => parseInt(chatRoom.crId) === chatRoomID)?.crId
              ? state.chatRooms?.find((chatRoom: any) => parseInt(chatRoom.crId) === chatRoomID).crId
              : chatRoomID}
        </div>
      )
    }
  }

  const handleBack = () => {
    navigate('/report/chatHistory-report')
  }

  const handlerAssignedUser = (e: any) => {
    setSelectedUserId(e.target.value)
  }

  console.log(activeUserOptions)
  console.log(selectedUserId)
  return (
    <>
      {/* {!!chatRoomID && chatRoomID > 0 && ( */}
      {type === 'Chat-Report' && (
        <div>
          <button className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleBack}>
            <i className='bi bi-arrow-left fs-1 fw-bolder'></i>
          </button>
        </div>
      )}

      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10 w-lg-800px w-xl-950px mb-md-2'>
        <div
          className='card border border-1'
          id='kt_chat_messenger'
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 8px 0px' }}
        >
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover' />
              <div className='d-flex justify-content-center flex-column me-3'>
                {hearderName()}
                <div className='mb-0 lh-1'>
                  {chatRoomID > 0 && (
                    <span
                      className={`badge badge-${'success'} badge-circle w-10px h-10px me-1`}
                    ></span>
                  )}

                  <span className='fs-7 fw-bold text-gray-400'>
                    {chatRoomID > 0 ? 'Active' : ''}
                  </span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'>
              {type === 'Assigned' && !!chatRoomID && chatRoomID > 0 && (
                <AssignedChatDropdown
                  assignedDropdownMenuRef={assignedDropdownMenuRef}
                  assignedMenuDroppedDown={assignedMenuDroppedDown}
                  setAssignedMenuDroppedDown={setAssignedMenuDroppedDown}
                  chatRoomID={chatRoomID}
                  setUpdateChatRoomsFlag={setUpdateChatRoomsFlag}
                  chatRoomsArrayRef={chatRoomsArrayRef}
                />
              )}
              {(type === 'Resolved' || type === 'Chat-Report') &&
                !!chatRoomID &&
                chatRoomID > 0 && (
                  <div>
                    <CsvDownloader
                      datas={csvData}
                      columns={csvHeaders}
                      filename={`Chatroom_${data2?.sessionsByChatRoomId[0]?.chatRoomId}`}
                      className='btn btn-light-primary me-2'
                    >
                      <i className='bi bi-download fs-4 me-2'></i>
                      Export Chat
                    </CsvDownloader>
                  </div>
                )}

              {type === 'Unassigned' && (
                <div className='position-relative me-2' ref={chatDropdownMenuRef1}>
                  <button
                    className={`btn btn-sm btn-icon btn-active-light-primary ${chatMenuDroppedDown ? 'show menu-dropdown' : ''
                      }`}
                    disabled={!chatRoomID || chatRoomID === 0}
                    onClick={() => setChatMenuDroppedDown((prev) => !prev)}
                  >
                    <i className='bi bi-three-dots fs-2'></i>
                  </button>
                  {chatMenuDroppedDown && (
                    <div
                      className='dropdown-menu show'
                      style={{
                        position: 'absolute',
                        left: '-250px', // Adjust width to align with button
                        top: '100%', // Show below the button
                        width: 250,
                        zIndex: 1050, // Ensure it appears above other elements
                      }}
                    >
                      <form className='card-body' onSubmit={handleChatAssign}>
                        <div className='mb-10'>
                          <label className='form-label fw-bold'>Assign To:</label>
                          <div>
                            <select
                              className='form-select form-select-solid'
                              role='button'
                              value={selectedUserId || 0}
                              onChange={(e) => handlerAssignedUser(e)}
                            >
                              <option value={0} disabled>
                                Select a user
                              </option>
                              {activeUserOptions?.map((option) => (
                                <option key={option?.value} value={option?.value}>
                                  {option?.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end'>
                          <button
                            type='reset'
                            className='btn btn-sm btn-white btn-active-light-primary me-2'
                            onClick={() => setChatMenuDroppedDown(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm btn-primary'
                            disabled={selectedUserId <= 0}
                          >
                            Apply
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <ChatInner
            chatRoomId={chatRoomID}
            // agentID={agentID}
            type={type}
            onAssign={onAssignTo}
            handleMarkMessgaeAsRead={handleMarkMessgaeAsRead}
          />
        </div>
      </div>
      {/* )} */}
      {(type === 'Resolved' || type === 'Chat-Report') && (
        <div className='flex-lg-row-auto ms-lg-7 ms-xl-10 w-lg-350px'>
          <SidePanel
            // sidePanelOpened={sidePanelOpened}
            currentUser={currentUser}
            sendMessageToEndUser={sendMessageToEndUser}
            data2={data2}
            chatRoomId={chatRoomID}
            chatRoomMetaData={data3}
            markChatroomAsResolved={markChatRoomAsResolved}
            getAssignedChatrooms={getAssignedChatrooms}
            setTotalUnreadAssignedChats={setTotalUnreadAssignedChats}
            setUpdateChatRoomsFlag={setUpdateChatRoomsFlag}
            chatRoomsArrayRef={chatRoomsArrayRef}
            type={type}
            setState={setState}
          // chatRoomMetaData={undefined}
          />
        </div>
      )}
      {type === 'Assigned' && (
        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10 w-lg-350px'>
          <TeamPanel handleOpenChat={handleOpenChat} disabledIndexes={disabledIndexes} />
        </div>
      )}

      {chatPanels.map((panel: any, index: any) => (
        <ChatPanelComponent
          user={panel.user}
          chatRoomId={chatRoomID}
          type={type}
          key={panel.id}
          id={panel.id}
          index={index}
          isMinimized={panel.isMinimized}
          onClose={handleCloseChat}
          onMinimize={handleMinimizeChat}
          handleMarkMessgaeAsRead={handleMarkMessgaeAsRead}
          convArrayRef={convArrayRef}
          updateConvRef={updateConvRef}
        />
      ))}
    </>
  )
}

export default ChatRoom
