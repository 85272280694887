import React, { useState, useRef, useEffect } from "react";
import { saveAs } from "file-saver"; // Ensure file-saver is installed
import * as XLSX from "xlsx"; // Ensure xlsx is installed
import LeadTrailActionMenu from "./LeadTrailActionMenu";

interface IProps {
  leadTrailData: {
    forward_count: number;
    last_forward_date: string | null;
    audit_trail: any[];
  };
}

const Trail: React.FC<IProps> = ({ leadTrailData }) => {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  console.log(leadTrailData, "leadTrailData");

  // Extract audit trail data safely
  const auditTrail = leadTrailData?.audit_trail ?? [];

  console.log(auditTrail, "LLLLLLLauditTrail");

  // Toggle menu
  const toggleMenu = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formatTime = (dateString: string | null) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const downloadCSV = () => {
    if (auditTrail.length === 0) return;

    const headers = [
      "Date",
      "Time",
      "Lead Activity",
      "Status",
      "Assignee",
      "Assigned By",
    ];

    const padRight = (text, length) => text.toString().padStart(length, " ");

    const csvContent = [
      headers.join(","),
      ...auditTrail.map((row) =>
        [
          padRight(formatDate(row.created), 0),
          padRight(formatTime(row.created), 0),
          padRight(row.lead_activity || "N/A", 0),
          padRight(row.new_status_type || "N/A", 0),
          padRight(row.new_assignee || "N/A", 0),
          padRight(row.assigned_by || "N/A", 0),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "audit_trail_data.csv");
  };

  //
  const downloadExcel = () => {
    if (auditTrail.length === 0) return;

    const ws = XLSX.utils.json_to_sheet(
      auditTrail.map((row) => ({
        Date: formatDate(row.created),
        Time: formatTime(row.created),
        LeadActivity: row.lead_activity || "N/A",
        Status: row.new_status_type || "N/A",
        Assignee: row.new_assignee || "N/A",
        AssignedBy: row.assigned_by || "N/A",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Audit Trail");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "audit_trail_data.xlsx");
  };

  // Handle clicks outside the dropdown menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenMenuIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="ms-auto mt-2 mb-2 mx-5">
          <button className="btn btn-primary me-2" onClick={downloadCSV}>
            <i className="bi bi-file-earmark-text"></i> Export CSV
          </button>
          <button className="btn btn-primary" onClick={downloadExcel}>
            <i className="bi bi-file-earmark-spreadsheet"></i> Export Excel
          </button>
        </div>

        <div className="table-responsive">
          <table className="table table-row-bordered table-row-gray-300 text-muted align-middle gs-5">
            <thead className="table-light fw-bolder">
              <tr className="bg-primary">
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Lead Activity</th>
                <th scope="col">Status</th>
                <th scope="col">Assignee</th>
                <th scope="col">Assigned By</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {auditTrail.map((data, index) => {
                const formattedDate = formatDate(data.created);
                const formattedTime = formatTime(data.created);

                // Get the forwarded date and time
                const forwardedDate = formatDate(
                  leadTrailData.last_forward_date
                );
                const forwardedTime = formatTime(
                  leadTrailData.last_forward_date
                );

                // Highlight if the forwarded date and time match
                const isHighlighted =
                  formattedDate === forwardedDate &&
                  formattedTime === forwardedTime;

                return (
                  <tr
                    key={index}
                    style={{
                      background: isHighlighted ? "#d8d8df" : "",
                      color: isHighlighted ? "black" : "",
                    }}
                  >
                    <th scope="row">{formattedDate}</th>
                    <td>{formattedTime}</td>
                    <td>{data.lead_activity || "N/A"}</td>
                    <td>{data.new_status_type || "N/A"}</td>
                    <td>{data.new_assignee || "N/A"}</td>
                    <td>{data.assigned_by || "N/A"}</td>
                    <td>
                      <LeadTrailActionMenu
                        index={index}
                        id={data.id}
                        leadTrailData={data}
                        toggleMenu={toggleMenu}
                        openMenuIndex={openMenuIndex}
                        dropdownRef={dropdownRef}
                        setOpenMenuIndex={setOpenMenuIndex}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Trail;
