import React, { useState, useEffect } from "react";
import {
    useDeletePauseCodeMutation,
    usePauseCodeDataQuery,
} from "../../../../Services/Telephony/Telephony";
import LoadingSpinner from "../../../LoadingSpinner";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import EditPauseCode from "./EditPauseCode";
import DeleteContactModal from "../../../contact/contact/DeleteContactModal";
import AddPauseCode from "./AddPauseCode";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import EmptyRows from "../../../reusableComponent/EmptyRows";

const PauseCode = () => {
    const [pauseCode, setPauseCode] = useState([]);

    const [pauseLoading, setPauseLoading] = useState(false);
    const [pauseId, setPauseId] = useState("");
    const [pauseEditModal, setPauseEditModal] = useState(false);
    const [pauseAddModal, setPauseAddModal] = useState(false);
    const [pauseDeleteModal, setPauseDeleteModal] = useState(false);
    const [openPauseAddModal, setOpenPauseAddModal] = useState(false);

    const {
        data: pauseCodeData,
        isLoading: pauseCodeDataLoading,
        status: pauseCodeStatus,
        refetch,
    } = usePauseCodeDataQuery({});
    const [deletePauseTrigger, { status: deleteStatus }] =
        useDeletePauseCodeMutation();

    const PauseCodeHeader = [
        { label: "Sr.No", key: "sno" },
        { label: "Pause code", key: "pause" },
    ];

    useEffect(() => {
        if (pauseCodeData) {
            setPauseCode(pauseCodeData);
        }
    }, [pauseCodeData]);

    useEffect(() => {
        if (pauseCodeStatus === "pending") {
            setPauseLoading(true);
        } else {
            setPauseLoading(false);
        }
    }, [pauseCodeStatus]);

    const EditModal = (id) => {
        setPauseId(id);
        setPauseEditModal(true);
    };

    const closeModal = () => {
        setPauseEditModal(false);
        setPauseAddModal(false);
        setPauseDeleteModal(false);
        setOpenPauseAddModal(false);
    };

    const deleteModal = (queue) => {
        setPauseId(queue);
        setPauseDeleteModal(true);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        overflow: "scroll",
        bgcolor: "background.paper",
        border: "1px solid transparent",
        boxShadow: 2,
        borderRadius: "5px",
        p: 4,
        paddingBottom: "0",
    };

    const importantItems = ["Manual Dial", "Not Ready"];

// Filter out duplicates of important items
const filteredPauseCode = pauseCode.filter(
    (item: any) => !importantItems.includes(item.name)
);

    return (
        <div style={{marginLeft:"3%"}}>
            <div className="row mb-5">
                <div className="col-10">
                    <span className="input-group-text" id="basic-addon1" style={{width:"103%"}}>
                        Pause code
                    </span>
                </div>

                <div className="col-2 d-flex justify-content-end">
                    <div className="card-toolbar d-flex gap-2">
                        <button
                            className="input-group-text form-control btn-sm fw-bold d-flex align-items-center min-w-150px"
                            style={{
                                justifyContent: "center",
                                fontSize: "16px",
                            }}
                            onClick={() => setOpenPauseAddModal(true)}
                        >
                            <i className="bi bi-plus-lg text-dark me-2"></i>
                            <span>Add</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div
                        className="card  my-1 p-4"
                        style={{ backgroundColor: "#E4E4E47F" }}
                    >
                        <div className="row align-items-center">
                            {PauseCodeHeader?.map((card, index) => (
                                <div
                                    className="col"
                                    style={{
                                        textAlign:
                                            card.key === "pause"
                                                ? "center"
                                                : "start",
                                    }}
                                    key={index}
                                >
                                    <label htmlFor="">
                                        <strong
                                            style={{
                                                display: "flex",
                                                gap: "2px",
                                            }}
                                        >
                                            {card?.label}
                                        </strong>
                                    </label>
                                </div>
                            ))}
                            <div className="col text-end">
                                <label
                                    htmlFor=""
                                    style={{ paddingRight: "25px" }}
                                >
                                    <strong>Action</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row" style={{ height: "55vh", overflow: "scroll" }}>
           
                {/* {pauseCode?.length ? <EmptyRows value={''}/> :  */}
                <div className="col" style={{ paddingRight: "0px" }}>
    {pauseCodeDataLoading || pauseLoading ? (
        <LoadingSpinner />
    ) : (
        <>
            {pauseCode?.length ? (
                <>
                    {/* Single "Not Ready" Card */}
                    <div className="card mt-2 my-1 p-4">
                        <div className="row align-items-start">
                            <div className="col text-start">
                                <label htmlFor="" style={{ paddingLeft: "10px" }}>1</label>
                            </div>
                            <div className="col text-start" style={{ paddingLeft: "21%" }}>
                                <label htmlFor="">Not Ready</label>
                            </div>
                            <div className="col text-center d-flex" style={{ gap: "10px", justifyContent: "end", paddingRight: "25px" }}>
                            </div>
                        </div>
                    </div>

                    {/* Single "Manual Dial" Card */}
                    <div className="card mt-2 my-1 p-4">
                        <div className="row align-items-start">
                            <div className="col text-start">
                                <label htmlFor="" style={{ paddingLeft: "10px" }}>2</label>
                            </div>
                            <div className="col text-start" style={{ paddingLeft: "21%" }}>
                                <label htmlFor="">Manual Dial</label>
                            </div>
                            <div className="col text-center d-flex" style={{ gap: "10px", justifyContent: "end", paddingRight: "25px" }}>
                            </div>
                        </div>
                    </div>

                    {/* Render the rest of the filtered `pauseCode` */}
                    {filteredPauseCode.map((user: any, index: number) => (
                        <div className="card mt-2 my-1 p-4" key={index}>
                            <div className="row align-items-start">
                                <div className="col text-start">
                                    <label htmlFor="" style={{ paddingLeft: "10px" }}>{index + 3}</label>
                                </div>

                                <div className="col text-start" style={{ paddingLeft: "21%" }}>
                                    <label htmlFor="">{capitalizeFirstLetter(user?.name)}</label>
                                </div>

                                <div className="col text-center d-flex" style={{ gap: "10px", justifyContent: "end", paddingRight: "25px" }}>
                                    <button className="text-hover-primary border-0 bg-white me-2" title="Edit" onClick={() => EditModal(user.id)}>
                                        <i className="bi bi-pencil text-dark fs-4"></i>
                                    </button>
                                    <button className="text-hover-danger border-0 bg-white me-2" title="Delete" onClick={() => deleteModal(user)}>
                                        <i className="bi bi-trash text-dark fs-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                ""
            )}
        </>
    )}
</div>
                {/* } */}
            </div>

            <Modal
                open={pauseEditModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit code
                    </Typography>

                    <Box>
                        <EditPauseCode
                            closeModal={closeModal}
                            refetch={refetch}
                            pauseId={pauseId}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={pauseDeleteModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        {/* <GridCloseIcon /> */}
                    </IconButton>
                    {/* <Typography
                                        id="modal-title"
                                        variant="h6"
                                        component="h2"
                                        sx={{ mb: 2 }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        Edit Extension

                                    </Typography> */}

                    <Box>
                        <DeleteContactModal
                            closeModal={closeModal}
                            setFieldsData={setPauseCode}
                            fieldsData={pauseCode}
                            storingFieldData={pauseId}
                            refetch={refetch}
                            trigger={deletePauseTrigger}
                        />
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openPauseAddModal}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, position: "absolute" }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "fixed",
                            top: 16,
                            right: 16,
                            zIndex: 1301,
                            background: "white",
                        }} // Ensure it's above the modal content
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add pause code
                    </Typography>

                    <Box>
                        <AddPauseCode
                            closeModal={closeModal}
                            pauseCode={pauseCode}
                            refetch={refetch}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default PauseCode;
