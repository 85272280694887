import React, { useState } from "react";
import { useCreateTelephonyServerMutation } from "../../../Services/Telephony/TelephonyFrontend";
import { toast } from "react-toastify";

interface IPROPS {
  closeModal: any;
  refetch: () => void;
}

const AddTelephonyServerConfig: React.FC<IPROPS> = ({ closeModal, refetch }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
    const [isSomethingAdded, setIsSomethingAdded] = useState(false)
  const [serverConfigData, setServerConfigData] = useState({
    ip_addr: "",
    sql_port: "",
    https_port: "",
    http_port: "",
    domain: "",
    db_user: "",
    db_name: "",
    db_password: "",
    recording_path: "",
  });

  const [addTelephonyServerConfigTrigger] = useCreateTelephonyServerMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setServerConfigData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsSomethingAdded(true)

    // Clear errors when user starts typing
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!serverConfigData.ip_addr.trim()) {
      newErrors.ip_addr = "IP Address is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    const transformedData = {
      ...serverConfigData,
      http_port: serverConfigData.http_port.trim() === "" ? 0 : serverConfigData.http_port,
      https_port: serverConfigData.https_port.trim() === "" ? 0 : serverConfigData.https_port,
      sql_port: serverConfigData.sql_port.trim() === "" ? 0 : serverConfigData.sql_port,

    };

    const response = await addTelephonyServerConfigTrigger(transformedData);

    try {
      if (response?.data) {
        toast.success("Extension Added Successfully");
        closeModal();
        refetch();
        // setTimeout(() => setIsButtonDisabled(false), 3000);
      } else if ("data" in response.error) {
        const errorData = response?.error?.data as Record<string, string[]>;
        Object.entries(errorData).forEach(([key, messages]) => {
          toast.error(messages[0]);
          setErrors((prev) => ({ ...prev, [key]: messages[0] }));
        });
        // setTimeout(() => setIsButtonDisabled(false), 3000);
      }
    } catch (error) {
      // setTimeout(() => setIsButtonDisabled(false), 3000);
    }
  };

  const labelMap: Record<string, string> = {
    ip_addr: "IP address",
    sql_port: "SQL port",
    https_port: "HTTPS port",
    http_port: "HTTP port",
    domain: "Domain",
    db_user: "Database user",
    db_name: "Database name",
    db_password: "Database password",
    recording_path: "Recording path",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div style={{ maxHeight: "50vh",
      overflow: "scroll",
      overflowX: "hidden",display: "flex", flexDirection:"column", gap: "20px"}}>
     {Object.keys(serverConfigData).map((key) => (
  <div key={key}>
    <label style={{ fontSize: "14px" }}>
      {labelMap[key] || key.replace(/_/g, " ").toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
    </label>
    <input
      className="form-control"
      name={key}
      value={serverConfigData[key]}
      onChange={handleChange}
    />
    {errors[key] && <small className="text-danger">{errors[key]}</small>}
  </div>
))}
      </div>
      <div style={{ width: "100%", display: "flex", gap: "10px", justifyContent: "end" }}>
        <button type="reset" className="btn btn-sm btn-white me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleSubmit} disabled={!isSomethingAdded}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddTelephonyServerConfig;
