import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
} from "@mui/material";
import { Pagination, PaginationItem } from "@mui/material";
import {
    useCallHistoryQuery,
    useEditAgentMappingUsersQuery,
} from "../../Services/Telephony/Telephony";
import LoadingSpinner from "../../components/LoadingSpinner";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { useNavigate } from "react-router-dom";

const Index = () => {

    // DON"T REMOVE BELOW COMMENTED CODE
    const userRole = localStorage.getItem("user_role");
    const getUserId = localStorage.getItem("User_Id");

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // Initialize with the current date in YYYY-MM-DD format
    const [loading, setLoading] = useState<boolean>(false);
    const [agentUsersData, setAgentUsersData] = useState([]);
    const [usersLoader, setUsersLoader] = useState(false);

    const formatDate1 = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    // Initialize current date (dateTo)
    const currentDate = new Date();
    const formattedCurrentDate = formatDate1(currentDate);

    // Calculate dateFrom (6 days prior)
    const dateFromValue = new Date();
    dateFromValue.setDate(currentDate.getDate() - 6);
    const formattedDateFrom = formatDate1(dateFromValue);

    const [dateFrom, setDateFrom] = useState<string>(formattedDateFrom);
    const [dateTo, setDateTo] = useState<string>(formattedCurrentDate);
    const [selectProcess, setSeectProcess] = useState<any>("All");
    const [agentId, setAgentId] = useState<any>("all");
    const [params, setParams] = useState<any>({ gte: dateFrom, lte: dateTo });
    const { data, refetch, isLoading, status } = useCallHistoryQuery(params, {
        skip: !params,
    });

    const {
        data: usersData,
        status: agentMappingUserStatus,
        refetch: refetchUserData,
    } = useEditAgentMappingUsersQuery({}, { skip: userRole === "agent" });

    // Sample Data

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        newPage: number
    ) => {
        setCurrentPage(newPage);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (userRole === "agent") {
            setAgentId(getUserId);
        }
    }, [getUserId, userRole]);

    useEffect(() => {
        if (agentMappingUserStatus === "pending") {
            setUsersLoader(true);
        } else {
            setUsersLoader(false);
        }
    }, [agentMappingUserStatus]);

    useEffect(() => {
        if (usersData) {
            setAgentUsersData(usersData);
        }

    }, [usersData, refetchUserData]);

    const handleDateFromChange = (event: any) => {
        setDateFrom(event.target.value);
    };

    const handleDateToChange = (event: any) => {
        setDateTo(event.target.value);
    };
    useEffect(() => {
        const queryParams = {
            gte: dateFrom,
            lte: dateTo,
            page: currentPage,
            page_size: 10,
        };

        if (selectProcess !== "All") {
            queryParams["call_type"] = selectProcess;
        }
        if (agentId !== "all") {
            queryParams["user_id"] = agentId;
        }

        setParams(queryParams);
    }, [dateFrom, dateTo, selectProcess, currentPage, agentId]);

    useEffect(() => {
        if (data?.count) {
            setTotalPages(Math.ceil(data?.count / 10));
        }
    }, [data]);
    useEffect(() => {
        // Reset currentPage to 1 whenever dateFrom, dateTo, or selectProcess changes
        setCurrentPage(1);
    }, [dateFrom, dateTo, selectProcess]);

    useEffect(() => {
        if (status === "pending") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [status]);

    const ITEM_HEIGHT = 60;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 150,
            },
        },
    };

    const handleChange = (e) => {
        setSeectProcess(e.target.value);
    };

    const agentSelectHandler = (e) => {
        setAgentId(e.target.value);
        setCurrentPage(1)
    };
    const handleBackButtonClick = () => {
        navigate(-1); // Navigate back to the previous page
    };
    return (
        <div className="custom-style">
            <div id="kt_app_content_container" className="app-container mx-5">
                <div className="card card-flush">

                    <div className="card-header align-items-center py-1 gap-2">

                        <h2 className="p-4" style={{ marginLeft: "-13px" }}><button
                            className="btn btn-link"
                            onClick={handleBackButtonClick}
                        >
                            <i className="bi bi-arrow-90deg-left" style={{
                                fontSize: "20px",
                                color: "black",
                                cursor: "pointer",
                                marginRight: "7px"
                            }}></i>
                        </button>
                            Call history</h2>
                    </div>
                    <div
                        className="row"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {/* Filter */}
                        <div
                            style={{
                                marginLeft: "25px",
                                width: "60%",
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                            }}
                        >
                            {/* From Date */}

                            <div className="w-100 mw-150px">
                                <TextField
                                    id="date-from"
                                    label="From"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: new Date()
                                                .toISOString()
                                                .split("T")[0], // Restrict to today or earlier
                                            style: {
                                                textTransform: "uppercase",
                                            }, // Optional: uppercase input text
                                        },
                                    }}
                                    fullWidth
                                    value={dateFrom}
                                    onChange={handleDateFromChange}
                                />
                            </div>

                            <div className="w-100 mw-150px">
                                <TextField
                                    id="date-to"
                                    label="To"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: new Date()
                                                .toISOString()
                                                .split("T")[0], // Restrict to today or earlier
                                            style: {
                                                textTransform: "uppercase",
                                            }, // Optional: uppercase input text
                                        },
                                    }}
                                    fullWidth
                                    value={dateTo}
                                    onChange={handleDateToChange}
                                />
                            </div>

                            {/* To Date */}

                            {/* Dropdown */}
                            <div
                                className="w-150 mw-150px"
                                style={{
                                    flex: "1 1 200px",
                                    marginRight: "10px",
                                }}
                            >
                                <FormControl fullWidth>
                                    <InputLabel id="Assignee-select-label">
                                        Select process
                                    </InputLabel>
                                    <Select
                                        labelId="Assignee-select-label"
                                        id="Assignee-select"
                                        label="Select process"
                                        MenuProps={MenuProps}
                                        value={selectProcess}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="all" value="All">
                                            All
                                        </MenuItem>

                                        <MenuItem value={"inbound"}>
                                            Inbound
                                        </MenuItem>
                                        <MenuItem value={"outbound"}>
                                            Outbound
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {/* Uncomment this after the approval */}
                            {userRole != "agent" && (
                                <div
                                    className="w-150 mw-150px"
                                    style={{
                                        flex: "1 1 200px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="Assignee-select-label">
                                            Select agent
                                        </InputLabel>
                                        <Select
                                            labelId="Assignee-select-label"
                                            id="Assignee-select"
                                            label="Select process"
                                            MenuProps={MenuProps}
                                            value={agentId}
                                            onChange={agentSelectHandler}
                                        >
                                            <MenuItem value="" disabled>
                                                {usersLoader ? (
                                                    <>
                                                        Searching...{" "}
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                    </>
                                                ) : (
                                                    "Select User"
                                                )}
                                            </MenuItem>
                                            <MenuItem key="all" value="all">
                                                All
                                            </MenuItem>

                                            {!usersLoader &&
                                                agentUsersData.map(
                                                    (user, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={user.id}
                                                        >
                                                            {user.username}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className="row gap-5 ms-3"
                        style={{ marginTop: "20px" }}
                    >
                        <div className="row">
                            <div className="col">
                                <div
                                    className="card mb-n5 my-1 p-4"
                                    style={{ backgroundColor: "#E4E4E47F" }}
                                >
                                    <div className="row align-items-center">
                                        {/* Customer Name */}
                                        <div className="col text-start">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Customer name
                                                </strong>
                                            </label>
                                        </div>

                                        {/* Campaign Name */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Campaign name
                                                </strong>
                                            </label>
                                        </div>

                                        {/* Start Time */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Start time
                                                </strong>
                                            </label>
                                        </div>

                                        {/* End Time */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    End time
                                                </strong>
                                            </label>
                                        </div>

                                        {/* Mobile No */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Mobile no
                                                </strong>
                                            </label>
                                        </div>

                                        {/* Duration */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Duration
                                                </strong>
                                            </label>
                                        </div>

                                        {/* Disposition */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Disposition
                                                </strong>
                                            </label>
                                        </div>
                                        {userRole != "agent" &&

                                            <div className="col text-center">
                                                <label htmlFor="">
                                                    <strong className="d-flex gap-2">
                                                        Agent name
                                                    </strong>
                                                </label>
                                            </div>
                                        }

                                        {/* Process */}
                                        <div className="col text-center">
                                            <label htmlFor="">
                                                <strong className="d-flex gap-2">
                                                    Process
                                                </strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <div>
                                        {data?.results?.length > 0 ? (
                                            <>
                                                {data?.results?.map(
                                                    (user, index) => (
                                                        <div
                                                            className="card mt-2 my-1 p-4"
                                                            key={index}
                                                        >
                                                            <div className="row align-items-start">
                                                                {/* Customer Name */}
                                                                <div className="col text-start">
                                                                    <label>
                                                                        {user
                                                                            ?.contacts[0]
                                                                            ?.name ||
                                                                            ""}
                                                                    </label>
                                                                </div>

                                                                {/* Campaign Name */}
                                                                <div className="col text-center">
                                                                    <label>
                                                                        {user.campaign_name ||
                                                                            ""}
                                                                    </label>
                                                                </div>

                                                                {/* Start Time */}
                                                                <div className="col text-center">
                                                                    <label>
                                                                        {user &&
                                                                            user.call_start_time_display
                                                                            ? formatDate(
                                                                                user.call_start_time_display
                                                                            )
                                                                            : ""}
                                                                    </label>
                                                                </div>

                                                                {/* End Time */}
                                                                <div className="col text-center">
                                                                    <label>
                                                                        {user &&
                                                                            user.call_end_time_display
                                                                            ? formatDate(
                                                                                user.call_end_time_display
                                                                            )
                                                                            : ""}
                                                                    </label>
                                                                </div>

                                                                {/* Mobile No */}
                                                                <div className="col text-center">
                                                                    <label>
                                                                        {user.customer_phone_no ||
                                                                            ""}
                                                                    </label>
                                                                </div>

                                                                {/* Duration */}
                                                                <div className="col text-center">
                                                                    <label>
                                                                        {user.conversation_duration ||
                                                                            ""}
                                                                    </label>
                                                                </div>

                                                                {/* Disposition */}
                                                                <div className="col text-center">
                                                                    <label>
                                                                        {capitalizeFirstLetter(user.dispose_remark) ||
                                                                            ""}
                                                                    </label>
                                                                </div>

                                                                {userRole != "agent" &&
                                                                    <div className="col text-center">
                                                                        <label>
                                                                            {capitalizeFirstLetter(user.agent_name) ||
                                                                                ""}
                                                                        </label>
                                                                    </div>
                                                                }

                                                                {/* Process */}
                                                                <div className="col text-center">
                                                                    <label data-toggle="tooltip" data-placement="top" title={user?.call_type === "outbound" ? "Outbound" : user?.call_type === "inbound" ? "Inbound" : ""}>
                                                                        {user?.call_type === "outbound" ? <i className="bi bi-telephone-outbound" style={{ fontSize: "20px" }}></i> : <i className="bi bi-telephone-inbound" style={{ fontSize: "20px" }}></i>}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <EmptyRows value={""} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        {data?.results?.length > 0 && (
                            <>
                                <Pagination
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                        color: "blue",
                                    }}
                                    count={totalPages} // Total number of pages
                                    page={currentPage} // Current page number
                                    onChange={handlePageChange} // Page change handler
                                    color="primary" // Adjust as needed
                                    renderItem={(item) => {
                                        // Custom rendering logic for Pagination items
                                        if (item.type === "page") {
                                            if (
                                                (currentPage === 1 &&
                                                    item.page <= 3) || // Show first 3 pages if on page 1
                                                (currentPage === totalPages &&
                                                    item.page >=
                                                    totalPages - 2) || // Show last 3 pages if on the last page
                                                (item.page >= currentPage - 1 &&
                                                    item.page <=
                                                    currentPage + 1) // Show current page and 1 sibling on each side
                                            ) {
                                                return (
                                                    <PaginationItem {...item} />
                                                );
                                            }
                                            return null;
                                        }
                                        return <PaginationItem {...item} />;
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
