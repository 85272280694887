import React, { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useUpdateCamapignMutation } from "../../../Services/campign/CampaignDetail";
import ReactModal from "react-modal";
import AddingLeadIntoContact from "./AddingLeadIntoContact";
import { CIRCLEONE_USER } from "../../../constants";
interface IProps {
  data: any;
  closeStatusModal: any;
  refetch: any;
  refetchAuditTrail: any;
  selectedStatus: any;
  handleStatusUpdate: any; // Add this prop to handle status update
  refetchAuditTrailData: any;
  selectedAllStatus: any;
  storingTicketId: any;
  creatingNewContactDetails: any;
}

const ChangeStatusCampaignModal = ({
  data,
  closeStatusModal,
  refetch,
  refetchAuditTrail,
  selectedStatus,
  handleStatusUpdate,
  refetchAuditTrailData,
  selectedAllStatus,
  storingTicketId,
  creatingNewContactDetails,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const params = useParams();
  const uuid = params.uuid;

  const [updateStatus] = useUpdateCamapignMutation();

  const checkForTenant = localStorage.getItem(CIRCLEONE_USER);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await updateStatus({
        id: storingTicketId ? storingTicketId : `${uuid}`,
        new_status_type: selectedStatus,
        assigned_by: localStorage.getItem("circleone-username"),
        assignee: localStorage.getItem("User_Id"),
        status:
          selectedAllStatus.status_type === "wip" ? selectedAllStatus.id : null, // WIP
        closed_status:
          selectedAllStatus.status_type === "closed"
            ? selectedAllStatus.id
            : null,
        dynamic_fields: {},
        static_fields: {},
      });

      if (response.data) {
        setIsLoading(false);
        toast.success("Status updated successfully!");

        if (
          (selectedAllStatus?.status === "Won" &&
            checkForTenant === "autodealer") ||
          (selectedAllStatus?.status === "Won" && checkForTenant === "meta")
        ) {
          setOpenConfirmationModal(true);
        } else {
          handleStatusUpdate();
          closeStatusModal();
          refetch();
          refetchAuditTrailData();
        }
        // creatingNewContactDetails()
        // refetchAuditTrail();
        // refetchAuditTrailData();
      } else if (response.error) {
        // Extract error details from the response
        const { status, data }: any = response.error;

        if (data?.detail) {
          // Show detailed error messages dynamically
          data.detail.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });
        } else {
          toast.error(
            `Error ${status}: Something went wrong. Please try again.`
          );
        }

        setIsLoading(false); // Reset loading state
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      console.error(error); // Log the error
    }
    // closeStatusModal();
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height: "170px",
    },
  };

  return (
    <div className="card-body">
      <span className="fs-5 fw-bold">
        Are you sure you want to change the status?
      </span>
      <div className="d-flex justify-content-end mt-10">
        <button
          type="reset"
          className="btn btn-sm btn-white me-3"
          onClick={closeStatusModal}
          disabled={isLoading}
        >
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Updating..." : "Update"}
        </button>
      </div>

      <ReactModal
        isOpen={openConfirmationModal}
        onRequestClose={closeStatusModal}
        style={customModalStyles}
        contentLabel="Change Status"
      >
        <AddingLeadIntoContact
          // data={campaignData}
          closeStatusModal={closeStatusModal}
          creatingNewContactDetails={creatingNewContactDetails}
          closeStatusModall={closeStatusModal}
          handleStatusUpdate={handleStatusUpdate}
          refetch={refetch}

          // refetch={refetch}
          // refetchAuditTrail={refetchAuditTrail}
          // selectedStatus={selectedStatus}
          // handleStatusUpdate={handleStatusUpdate}
          // // refetchAuditTrailData={
          // //   refetchAuditTrailData
          // // }
          // selectedAllStatus={selectedAllStatus}
          // storingTicketId={storingTicketId}
          // //  refetchAuditTrail={undefined}
          // refetchAuditTrailData={undefined}
          // creatingNewContactDetails={creatingNewContactDetails}
        />
      </ReactModal>
    </div>
  );
};

export default ChangeStatusCampaignModal;