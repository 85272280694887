import { useState, useContext, useRef, useEffect } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import IntentRenderer from "./intent-renderer";
import ChatContext from "../../context/ChatContext";
import Intent from "./intent";
import MsgLoader from "./templates/msgloader";
import axios from "axios";
import ReactModal from "react-modal";
import ChangeStatusModel from "./ChangeStatusModel";

interface IProps {
  id: number;
  onClose: (id: number) => void;
  isAgentData: any;
  handleMarkMessgaeAsRead: any;
  type: "Unassigned" | "Assigned" | "Resolved" | "Chat-Report";
}

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

const ChatPanelBody: React.FC<IProps> = ({
  isAgentData,
  handleMarkMessgaeAsRead,
  id,
  onClose,
  type,
}) => {
  const [chatUpdateFlag, toggleChatUpdateFlag] = useState<boolean>(false);
  const [chatInputValue, setChatInputValue] = useState<string>("");
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [uploadFileUrl, setUploadFileUrl] = useState<string>("");
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { state, bottomRef, onChange, onSubmit } = useContext(ChatContext);
  const maxLength = 5000000; // 5MB
  const userId = parseInt(isAgentData?.id);
  const currentPanel = state.panelBasedConversation.find(
    (panel: any) => panel.chatRoomId === userId || panel.agentId === userId
  );
  const chatRoomPanel = state.panelBasedConversation.find(
    (panel: any) => panel.chatRoomId === userId || panel.agentId === userId
  );

  const formatSizeInMB = (size: number) => {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "15px",
      border: "none",
      boxShadow: "0 2px 8px 0 #00000029",
    },
  };

  const openChangeStatusModal = () => {
    setIsChangeStatusModalOpen(true);
  };

  const afterChangeStatusModal = () => {
    // subtitle.style.color = '#f00'
  };

  const closeChangeStatusModal = () => {
    setIsChangeStatusModalOpen(false);
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    // const newChatInputValue = state.chatInput.value + emojiData.emoji
    // onChange({
    //   target: {
    //     name: state.chatInput.name,
    //     value: newChatInputValue
    //   }
    // })
    const newChatInputValue = chatInputValue + emojiData.emoji;
    setChatInputValue(newChatInputValue);
  };

  const handleDropdownItemClick = (item: string) => {
    // const newChatInputValue = state.chatInput.value + ' ' + item
    // onChange({
    //   target: {
    //     name: state.chatInput.name,
    //     value: newChatInputValue
    //   }
    // })
    const newChatInputValue = chatInputValue + " " + item;
    setChatInputValue(newChatInputValue);
    setShowDropdown(false);
  };

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (files[0].size <= maxLength) {
        try {
          // Upload the file
          const fileData = files[0];
          const data = {
            files: [
              {
                fileName: fileData.name,
                mimeType: fileData.type,
              },
            ],
          };
          const response = await axios.post(FILE_UPLOAD_URL as string, data);
          const uploadURL = response.data[0].uploadURL;

          await axios.put(uploadURL, fileData);
          const fileUrl = response.data[0].url;
          setUploadFileUrl(fileUrl);
          // Set the file name or URL in the chat input
          const newChatInputValue = chatInputValue + ` ${fileUrl}`;
          setChatInputValue(newChatInputValue);
          onChange({
            target: {
              name: state.chatInput.name,
              value: newChatInputValue,
            },
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        alert(
          `File ${files[0].name} exceeds the size limit ${formatSizeInMB(
            maxLength
          )}.`
        );
      }
    }
  };

  const uploadFileName = uploadFileUrl.split("-").pop();

  const sendMessage = (e: any) => {
    const trimmedValue = chatInputValue.trim();
    if (!!uploadFileUrl) {
      if (uploadFileUrl.length > 0) {
        toggleChatUpdateFlag((flag: boolean) => !flag);
        onSubmit(e, currentPanel, uploadFileUrl);
        toggleChatUpdateFlag((flag: boolean) => !flag);
        setUploadFileUrl("");
        setChatInputValue("");
        textareaRef.current?.focus();
      }
    } else {
      if (trimmedValue.length > 0) {
        toggleChatUpdateFlag((flag: boolean) => !flag);
        setTimeout(() => {
          onSubmit(e, currentPanel, trimmedValue);
          toggleChatUpdateFlag((flag: boolean) => !flag);
          setChatInputValue("");
          textareaRef.current?.focus();
        }, 500);
      }
    }
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      sendMessage(e);
    }
  };

  const cannedResponse = [
    "Sure thing! If you have any other questions, Ill be happy to help!",
    "Hi there! 👋 How can I help you today?",
    "Can I help you with anything else? ",
    "Thank you for your patience. ",
    "I`m still on it. Please bear with me for a moment. ",
  ];

  const renderFooter = () => {
    return (
      <div className="card-footer pt-4" id="kt_chat_messenger_footer">
        <textarea
          className="form-control form-control-flush mb-3"
          rows={1}
          placeholder="Type a message"
          name={state.chatInput.name}
          value={!!uploadFileUrl ? uploadFileName : chatInputValue}
          onChange={(e) => setChatInputValue(e.target.value)}
          onKeyDown={onEnterPress}
          ref={textareaRef}
          disabled={!isAgentData.id}
          onFocus={handleMarkMessgaeAsRead}
        ></textarea>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <button
              className="btn btn-active-light-primary me-1 p-2"
              style={{ border: "none", background: "transparent" }}
              type="button"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              data-bs-placement="top"
              title="Emoji Picker"
            >
              <i className="bi bi-emoji-smile fs-1"></i>
            </button>
            <button
              className="btn btn-active-light-primary p-2"
              type="button"
              onClick={handleFileUploadClick}
              data-bs-placement="top"
              title="File Transfer"
            >
              <i className="bi bi-paperclip fs-2"></i>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <button
              className="btn btn-active-light-primary p-2"
              type="button"
              onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown visibility
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Canned Responses"
            >
              <i className="bi bi-hash fs-1"></i>
            </button>
            {chatRoomPanel?.chatRoomId && (
              <button
                className="btn btn-active-light-primary p-2"
                onClick={openChangeStatusModal}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Change Status"
              >
                <i className="bi bi-card-checklist fs-2"></i>
              </button>
            )}
          </div>
          <ReactModal
            isOpen={isChangeStatusModalOpen}
            onAfterOpen={afterChangeStatusModal}
            onRequestClose={closeChangeStatusModal}
            style={customModalStyles}
            contentLabel="Add Meta Data"
          >
            <ChangeStatusModel
              chatroomId={chatRoomPanel?.chatRoomId}
              closeChangeStatusModal={closeChangeStatusModal}
              id={id}
              onClose={onClose}
            />
          </ReactModal>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={sendMessage}
            // disabled={chatUpdateFlag || !chatRoomId || !agentID }
            >
              Send
            </button>
          </div>
        </div>
        {showEmojiPicker && (
          <div className="emoji-picker">
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              style={{ width: "auto" }}
            />
          </div>
        )}
        {showDropdown && (
          <div
            className="dropdown-menu show card-body border border-1 p-4"
            style={{
              transform: "translate(-15%, -155%)",
              borderRadius: "5px",
              border: "none",
              boxShadow: "0 2px 8px 0 #00000029",
              width: "350px",
            }}
          >
            {cannedResponse.map((item: string) => (
              <p
                className=""
                key={item}
                onClick={() => handleDropdownItemClick(item)}
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  };
  useEffect(() => {
    console.log(currentPanel?.conversation); // Log conversation
    if (bottomRef?.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to bottom
    }
  }, [currentPanel?.conversation]); // Dependency array ensures it runs when conversation updates
  console.log(currentPanel?.conversation)
  return (
    <div className="card-body" id="kt_chat_messenger_body">
      <div className="scroll-y me-n5 pe-5" style={{ height: "390px" }}>
        <div className="d-flex justify-content-center mb-4">
          <div
            className="p-1 rounded bg-light text-dark fw-bold text-center"
            style={{ width: "fit-content" }}
          >
            {!!currentPanel?.conversation[0]?.time &&
              new Intl.DateTimeFormat("en-IN", {
                year: "numeric",
                month: "long",
                day: "numeric",
                timeZone: "Asia/Kolkata",
              }).format(new Date(currentPanel?.conversation[0]?.time))}
          </div>
        </div>
        {(currentPanel?.chatRoomId !== undefined ||
          currentPanel?.agentId !== undefined) && (
            <>
              <IntentRenderer
                chatRoomId={currentPanel?.chatRoomId}
                previousValue={state.previousValue}
                conversation={currentPanel?.conversation}
                type={type}
                flagMessageRead={state.flagMessageRead}
                uploadFileUrl={uploadFileUrl}
              />
              {state.msgLoading.active === true ? (
                <Intent
                  chatRoomId={currentPanel?.chatRoomId}
                  side="rhs"
                  key="loader-intent-key"
                  time=""
                  type={type}
                  item={() => { }}
                >
                  {MsgLoader({ side: "end-user" })}
                </Intent>
              ) : (
                ""
              )}
            </>
          )}
        <div ref={bottomRef} />
      </div>
      {renderFooter()}
    </div>
  );
};

export default ChatPanelBody;
