import {
  useAddAttachmentsMutation,
  useGetAuditTrailQuery,
} from "../../../Services/ticket/Audit-Trail";
import { formatDate } from "../../reusableComponent/DateFormat";
import { Button, Tooltip, IconButton, Chip, Box, Modal } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useState } from "react";
import { toast } from "react-toastify";

interface IProps {
  data: any;
  auditTrailData: any;
  refetchAuditTrail: any;
  refetch: any;
}

const Attachment: React.FC<IProps> = ({
  data,
  auditTrailData,
  refetchAuditTrail,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const { data: TrailData } = useGetAuditTrailQuery({
    id: auditTrailData?.[0]?.id,
    activity: auditTrailData?.[0]?.ticket_activity,
  });

  const attachments = [data];

  const [addAttachments] = useAddAttachmentsMutation();

  console.log(data, "data11111111");
  console.log(auditTrailData, "data1111111112222222");

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);

      // Call API to save attachments
      const updatedTicket = {
        ticket_id: data.id,
        attachments: fileDataArray,
      };

      setIsLoading(true);
      const response = await addAttachments(updatedTicket );

      if (response.data) {
        setIsLoading(false);
        toast.success("Attachment added successfully!");
        refetch();
        refetchAuditTrail();
      } else if (response.error) {
        setIsLoading(false);
        toast.error("Error: Something went wrong. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  return (
    <div className="table-responsive">
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Tooltip
          title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
          arrow
        >
          <IconButton aria-label="attach file">
            <label htmlFor="attachFileInput">
              <AttachFileIcon style={{ cursor: "pointer" }} />
              <input
                type="file"
                id="attachFileInput"
                className="d-none"
                multiple
                onChange={handleFileChange}
              />
            </label>
          </IconButton>
        </Tooltip>
      </div>
      <table className="table table-row-bordered text-center">
        <thead className="table-light fw-bolder">
          <tr className="bg-primary">
            <th scope="col">Date</th>
            <th scope="col">Name</th>
            <th scope="col">Attachment</th>
            <th scope="col">Download</th>
          </tr>
        </thead>
        <tbody>
          {attachments &&
            attachments.flatMap((attachment, index) =>
              attachment?.attachments.map((file, fileIndex) => (
                <tr key={`${index}-${fileIndex}`}>
                  <td>{formatDate(attachment?.created)}</td>
                  <td>{TrailData?.[0]?.new_assignee || "N/A"}</td>
                  <td className="text-primary">{file?.file_name}</td>
                  <td>
                    <i
                      className="bi bi-download fs-4 text-dark cursor-pointer"
                      onClick={() => window.open(file?.file, "_blank")}
                    ></i>
                  </td>
                </tr>
              ))
            )}
          {!attachments.length && (
            <tr>
              <td colSpan={4}>No attachments found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Attachment;
