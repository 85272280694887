import React, { useContext, useRef } from 'react'
import { CloseButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createInitials } from '../../utils/functions/createInitials'
import ChatContext from '../../context/ChatContext'
import { useNavigate } from 'react-router-dom'
import { on } from 'events'
import UserContext from '../../context/UserContext'

interface ChangeStatusModelProps {
  chatroomId: any
  closeChangeStatusModal: () => void
  id: number
  onClose: (id: number) => void
}

const ChangeStatusModel: React.FC<ChangeStatusModelProps> = ({
  chatroomId,
  closeChangeStatusModal,
  id,
  onClose
}) => {
  const chatRoomsArrayRef = useRef<any[]>([])
  const navigate = useNavigate()
  const {
    markChatRoomAsResolved,
    getAssignedChatrooms,
    setTotalUnreadAssignedChats,
    sendMessageToEndUser
  } = useContext(ChatContext)
  const { currentUser } = useContext(UserContext)

  const data1 = {
    content: {
      id: 27,
      message: 'Rating and Review',
      metadata: {
        payload: [
          {
            buttons: [
              {
                name: 'Submit review',
                action: {
                  type: 'null',
                  trigger: 26
                },
                message: 'Response Submitted '
              }
            ]
          }
        ],
        templateId: 30
      },
      chatId: chatroomId,
      side: 'dash-user'
    },
    side: 'dash-user',
    time: new Date().toUTCString(),
    sender: {
      type: 'dash-user',
      id: currentUser.id,
      name: currentUser.name
    }
  }

  const handleChatStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    const allStatus = {
      1: 'ACTIVE',
      2: 'RESOLVED',
      3: 'SPAM'
    }
    const currentStatus = (allStatus as any)[value]

    markChatRoomAsResolved(chatroomId, currentStatus)
      .then((res: any) => {
        if (!!res && res.messageResponse === 'SUCCESS') {
          toast.success('Successfully updated status')
          onClose(id)
          sendMessageToEndUser(data1)
          return getAssignedChatrooms()
        } else {
          toast.error('Unable to update status')
          return Promise.reject()
        }
      })
      .then((resAssigned: any) => {
        if (!!resAssigned) {
          const assignedChatrooms = resAssigned.assigned_chatrooms[0]
            ?.filter((chatRoom: any) => !!chatRoom)
            .map((chatRoom: any) => {
              let initials

              if (chatRoom.chatRoomMeta && chatRoom.chatRoomMeta.length > 0) {
                const metaName = chatRoom.chatRoomMeta.find(
                  (metaData: any) => metaData.name === 'Name'
                )
                if (metaName) {
                  initials = createInitials(metaName.value)
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id)
                } else {
                  initials = createInitials('Unnamed')
                }
              } else if (chatRoom.id) {
                initials = createInitials(chatRoom.id)
              } else {
                initials = createInitials('Unnamed')
              }
              return {
                ...chatRoom,
                initials
              }
            })
            .sort((a: any, b: any) => parseInt(b.id) - parseInt(a.id)) // Sort by ID descending

          setTotalUnreadAssignedChats(assignedChatrooms.length)
          chatRoomsArrayRef.current = assignedChatrooms

          if (currentStatus === 'RESOLVED' || currentStatus === 'SPAM') {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.delete('chatRoomId')
            navigate(`${window.location.pathname}?${searchParams.toString()}`)
          }
        }
      })
      .catch((error: any) => {
        console.error('Error updating chatroom status:', error)
      })
  }

  return (
    <div className='card' style={{ width: '400px', margin: '0 auto' }}>
      <div className='text-end'>
        <CloseButton onClick={closeChangeStatusModal} />
      </div>
      {/* Header */}
      <div className='card-header d-flex py-0' style={{ minHeight: '35px' }}>
        <h5 className='mb-0'>Chatroom ID: {chatroomId}</h5>
      </div>

      {/* Body */}
      <div className='card-body'>
        <h6 className='mb-3'>Change Status</h6>
        <select className='form-select' onChange={handleChatStatus} aria-label='Change Status'>
          <option value='1'>Active</option>
          <option value='2'>Resolved</option>
          <option value='3'>Mark as Spam</option>
        </select>
      </div>
    </div>
  )
}

export default ChangeStatusModel
