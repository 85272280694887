import React, { useContext, useEffect, useRef, useState } from "react";
import { useChatFilters } from "../../../utils/hooks/useChatFilters";
import { useSearchParams } from "react-router-dom";
import ChatContext from "../../../context/ChatContext";
import { createInitials } from "../../../utils/functions/createInitials";
import { IChatbotState } from "../../../utils/interfaces/IChatbotState";
import SkeletonCard from "./SkeletonCard";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  type: "Unassigned" | "Assigned" | "Resolved";
  // chatRoomID: number
}

const UnassignedLeftPanel: React.FC<IProps> = ({ type }) => {
  const {
    state,
    setState,
    isDashUserOnline,
    newUnassignedChat,
    getUnassignedChatrooms,
    getAssignedChatrooms,
    setTotalUnassignedChats,
    setTotalUnreadAssignedChats,
  } = useContext(ChatContext);
  const { operations } = useChatFilters();
  const tabTitles = ["Unassigned", "Assigned"];
  const [, setSearchParams] = useSearchParams();
  const [updateChatRoomsFlag, setUpdateChatRoomsFlag] =
    useState<boolean>(false);
  const [applicationIds] = useState<number[]>([1, 2]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const chatRoomsArrayRef = useRef<any[]>([]);
  const didComponentMountRef = useRef<boolean>(false);

  useEffect(() => {
    // Load Unassigned Chatrooms
    if (!!isDashUserOnline && newUnassignedChat) {
      setIsLoading(true);
      getUnassignedChatrooms().then((res: any) => {
        setIsLoading(false);
        if (!!res) {
          const unassignedChatrooms = res.unassigned_chatrooms;
          const unassignedChatRoomsArray = unassignedChatrooms
            .filter((chatRoom: any) => !!chatRoom)
            .map((chatRoom: any) => {
              let initials;

              if (chatRoom.chatRoomMeta && chatRoom.chatRoomMeta.length > 0) {
                const metaName = chatRoom.chatRoomMeta.find(
                  (metaData: any) => metaData.name === "Name"
                );
                if (metaName) {
                  initials = createInitials(metaName.value);
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id);
                } else {
                  initials = createInitials("");
                }
              } else if (chatRoom.id) {
                initials = createInitials(chatRoom.id);
              } else {
                initials = createInitials("");
              }

              return {
                ...chatRoom,
                initials,
              };
            })
            .sort((a: any, b: any) => {
              return parseInt(b.id) - parseInt(a.id);
            });
          setTotalUnassignedChats(unassignedChatRoomsArray.length);
          chatRoomsArrayRef.current = unassignedChatRoomsArray;
          setUpdateChatRoomsFlag(true);
          console.log("Set to call useEffect to load unassigned chatRooms");
        }
      });

      // getAssignedChatrooms().then((res: any) => {
      //   if (!!res && !!res.assigned_chatrooms && res.assigned_chatrooms.length > 0) {
      //     const assignedChatrooms = res.assigned_chatrooms
      //     let assignedChatRoomsArray = []
      //     if (assignedChatrooms[0] !== null) {
      //       assignedChatRoomsArray = assignedChatrooms[0].filter((chatRoom: any) => !!chatRoom)
      //     }
      //     setTotalUnreadAssignedChats(assignedChatRoomsArray.length)
      //     console.log('Updated initial number of assigned chats')
      //   }
      // })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUnassignedChat]);

  useEffect(() => {
    // Load Unassigned Chatrooms
    if (isDashUserOnline && type === "Unassigned") {
      setIsLoading(true);
      getUnassignedChatrooms().then((resUnassigned: any) => {
        setIsLoading(false);
        if (!!resUnassigned) {
          const unassignedChatrooms = resUnassigned.unassigned_chatrooms;
          const unassignedChatRoomsArray = unassignedChatrooms
            .filter((chatRoom: any) => !!chatRoom)
            .map((chatRoom: any) => {
              let initials;

              if (chatRoom.chatRoomMeta && chatRoom.chatRoomMeta.length > 0) {
                const metaName = chatRoom.chatRoomMeta.find(
                  (metaData: any) => metaData.name === "Name"
                );
                if (metaName) {
                  initials = createInitials(metaName.value);
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id);
                } else {
                  initials = createInitials("");
                }
              } else if (chatRoom.id) {
                initials = createInitials(chatRoom.id);
              } else {
                initials = createInitials("");
              }

              return {
                ...chatRoom,
                initials,
              };
            })
            .sort((a: any, b: any) => {
              // Sort by putting last chatRoomID in first
              return parseInt(b.id) - parseInt(a.id);
            });
          console.log(unassignedChatRoomsArray);
          setTotalUnassignedChats(unassignedChatRoomsArray.length);
          chatRoomsArrayRef.current = unassignedChatRoomsArray;
          // console.log(chatRoomsArrayRef.current)
          setUpdateChatRoomsFlag(true);
          console.log("Set to call useEffect to load unassigned chatRooms");
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isDashUserOnline]);

  useEffect(() => {
    if (updateChatRoomsFlag === true) {
      const chatRoomsArray = chatRoomsArrayRef.current;
      // console.log(chatRoomsArray)
      console.log("useEffect called to update Chat Rooms array !");
      setState(
        (prevState: IChatbotState) => ({
          ...prevState,
          chatRooms: chatRoomsArray,
        }),
        (updatedState: IChatbotState) => {
          // console.log(updatedState.chatRooms)
          if (updatedState.chatRooms.length === 0) {
            setSearchParams({ type: type as string });
          }
          if (didComponentMountRef.current === false) {
            didComponentMountRef.current = true;
          }
        }
      );
      return () => {
        setUpdateChatRoomsFlag(false);
      };
    }
  });

  useEffect(() => {
    if (state.flagToRefreshChatrooms === true) {
      getUnassignedChatrooms().then((res: any) => {
        setIsLoading(false);
        if (!!res) {
          const unassignedChatrooms = res.unassigned_chatrooms;
          const unassignedChatRoomsArray = unassignedChatrooms
            .filter((chatRoom: any) => !!chatRoom)
            .map((chatRoom: any) => {
              let initials;

              if (chatRoom.chatRoomMeta && chatRoom.chatRoomMeta.length > 0) {
                const metaName = chatRoom.chatRoomMeta.find(
                  (metaData: any) => metaData.name === "Name"
                );
                if (metaName) {
                  initials = createInitials(metaName.value);
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id);
                } else {
                  initials = createInitials("");
                }
              } else if (chatRoom.id) {
                initials = createInitials(chatRoom.id);
              } else {
                initials = createInitials("");
              }

              return {
                ...chatRoom,
                initials,
              };
            })
            .sort((a: any, b: any) => {
              // Sort by putting last chatRoomID in first
              return parseInt(b.id) - parseInt(a.id);
            });

          setState(
            (prevState: IChatbotState) => ({
              ...prevState,
              chatRooms: unassignedChatRoomsArray,
              flagToRefreshChatrooms: false,
            }),
            (updatedState: IChatbotState) => {
              // console.log(updatedState.chatRooms)
              if (updatedState.chatRooms.length === 0) {
                setSearchParams({ type: type as string });
              }
              if (didComponentMountRef.current === false) {
                didComponentMountRef.current = true;
              }
            }
          );
        }
      });
    }
  }, [state.flagToRefreshChatrooms]);

  useEffect(() => {
    // On receiving notification, push new unassigned chatrooms into the existing array
    if (
      !!isDashUserOnline &&
      !!newUnassignedChat &&
      Object.keys(newUnassignedChat).length > 0
    ) {
      if (type === "Unassigned") {
        const chatRoomId = newUnassignedChat.id;

        // Check if the chatroom with the same ID already exists
        const existingChatRoom = chatRoomsArrayRef.current.find(
          (chatRoom: any) => chatRoom.id === chatRoomId
        );

        if (!existingChatRoom) {
          let initials;

          if (
            newUnassignedChat.chatRoomMeta &&
            newUnassignedChat.chatRoomMeta.length > 0
          ) {
            const metaName = newUnassignedChat.chatRoomMeta.find(
              (metaData: any) => metaData.name === "Name"
            );
            if (metaName) {
              initials = createInitials(metaName.value);
            } else if (newUnassignedChat.id) {
              initials = createInitials(newUnassignedChat.id);
            } else {
              initials = createInitials("");
            }
          } else if (newUnassignedChat.id) {
            initials = createInitials(newUnassignedChat.id);
          } else {
            initials = createInitials("");
          }

          chatRoomsArrayRef.current.unshift({
            ...newUnassignedChat,
            initials,
          });

          setUpdateChatRoomsFlag(true);
          console.log("Added new unassigned chat");
          console.log(chatRoomsArrayRef.current);
        } else {
          console.log("Chatroom already exists:", existingChatRoom);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashUserOnline, newUnassignedChat, type]);

  useEffect(() => {
    operations.updateChatFilters("applicationIds", applicationIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationIds]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("chatRoomId")) {
      params.delete("chatRoomId");
      setSearchParams(Object.fromEntries(params.entries()));
    }
  }, []);

  const handleChatRoomClick = (chatRoom: any) => {
    setSearchParams({ type: type, chatRoomId: chatRoom.id });
    setState((prevState: IChatbotState) => {
      const existingPanel = prevState.panelBasedConversation.find(
        (panel) => panel.chatRoomId === chatRoom.id
      );

      if (!existingPanel) {
        return {
          ...prevState,
          panelBasedConversation: [
            ...prevState.panelBasedConversation,
            {
              chatRoomId: parseInt(chatRoom.id),
              conversation: [],
            },
          ],
        };
      }
      return prevState;
    });
  };

  if (!!isLoading) {
    return (
      <>
        <SkeletonCard />
      </>
    );
  }

  return (
    // <div className='flex-column flex-lg-row-auto w-100 w-350px mx-lg-0'>
    <div
      className="card card-flush"
      style={{ boxShadow: "0 2px 8px 0 #00000029" }}
    >
      <div className="card-header pt-7" id="kt_chat_contacts_header">
        <ul
          className="nav nav-stretch nav-line-tabs border-transparent flex-nowrap mb-5"
          style={{ margin: "auto" }}
        >
          {tabTitles.map((title, index) => (
            <li className="nav-item" key={uuidv4()}>
              <span
                className={`nav-link cursor-pointer ${
                  type === title ? "active fw-bolder" : ""
                }`}
                onClick={() => setSearchParams({ type: title })}
                role="tab"
              >
                {title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="card-body pt-5"
        style={{ minHeight: "150px" }}
        id="kt_chat_contacts_body"
      >
        <div className="scroll-y mh-700px">
          {state.chatRooms.length > 0 ? (
            state.chatRooms.map((chatRoom: any) => (
              <React.Fragment key={`unique-key-${uuidv4()}`}>
                <div className="d-flex flex-stack py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px symbol-circle">
                      {chatRoom.initials && (
                        <span
                          className={`symbol-label bg-light-${chatRoom.initials.state} text-${chatRoom.initials.state} fs-6 fw-bolder`}
                        >
                          {chatRoom.initials.label}
                        </span>
                      )}
                    </div>

                    <div className="ms-5">
                      <span
                        role="button"
                        onClick={() => handleChatRoomClick(chatRoom)}
                        className="fs-5 fw-bolder text-gray-900 text-hover-primary me-6"
                      >
                        {!!chatRoom.chatRoomMeta && (
                          <>
                            CONV -{" "}
                            {chatRoom.chatRoomMeta.length > 0 &&
                            chatRoom.chatRoomMeta.find(
                              (metaData: any) => metaData.name === "Name"
                            )
                              ? chatRoom.chatRoomMeta.find(
                                  (metaData: any) => metaData.name === "Name"
                                ).value
                              : chatRoom.id
                              ? chatRoom.id
                              : ""}
                          </>
                        )}
                      </span>
                      <span className="fw-bold text-muted ms-20">
                        {!!chatRoom?.chatName &&
                          new Intl.DateTimeFormat("en-IN", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                            timeZone: "Asia/Kolkata",
                          }).format(new Date(chatRoom?.chatName?.time))}
                      </span>
                      <div>
                        {!!chatRoom?.chatName && (
                          <>
                            <span className="fw-bold text-muted">
                              BOT-{chatRoom?.chatName?.applicationId}
                            </span>
                            <span className="ms-3 fw-bold text-muted">
                              CUST-{chatRoom?.chatName?.bgAccountId}
                            </span>
                            {chatRoom?.chatName?.agentId && (
                              <span className="ms-3 fw-bold text-muted">
                                AGENT-{chatRoom?.chatName?.agentId}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="separator separator-dashed d-none" />
              </React.Fragment>
            ))
          ) : (
            <>
              <p className="text-center my-10">No Chatrooms to show</p>
            </>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default UnassignedLeftPanel;
