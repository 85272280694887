import React, { useEffect } from "react";
import { useDeleteDespositionMutation } from "../../../../Services/general/General";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import { useDeleteDispositionMutation } from "../../../../Services/Telephony/Telephony";

interface IProps {
  closeDeleteModal: () => void;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  data: any[];
  currentNode: any;
  SetLiftingAddDate: (data: any[]) => void;
  refetch: () => void;
  selectedTeam: any;
}

const DeleteDispositionModal: React.FC<IProps> = ({
  closeDeleteModal,
  setData,
  data,
  SetLiftingAddDate,
  currentNode,
  refetch,
  selectedTeam,
}) => {
  const [trigger, { isLoading }] = useDeleteDispositionMutation();

  const deleteSubTopic = (nodes: any[], nodeId: string) => {
    return nodes
      .filter((node: any) => node.id !== nodeId)
      .map((node: any) => ({
        ...node,
        sub_disposition: deleteSubTopic(node.sub_disposition || [], nodeId),
      }));
  };
  

  const handleDelete = async () => {
    try {
      await trigger({ id: currentNode?.id });
      const updatedData = deleteSubTopic(data, currentNode?.id);
      setData(updatedData);
      closeDeleteModal();
      refetch();
      toast.success("Deleted Successfully")
    } catch (error) {
      console.error("Failed to delete disposition", error);
      toast.error("An error occured while deleting");
    }
  };

  useEffect(() => {
    if (data) {
      SetLiftingAddDate(data); // Use the original data with levels intact
    }
  }, [data]);

  return (
    <div className="card-body" style={{ padding: "0" }}>
      <>
        <p
          className="fs-5 fw-bold"
          style={{ width: "100%", textAlign: "center" }}
        >
          Are you sure you want to delete the following topic?
        </p>
        <p
          className="fs-4"
          style={{ fontWeight: "600", width: "100%", textAlign: "center" }}
        >
          {currentNode.topic}
        </p>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-sm btn-white me-2"
            onClick={closeDeleteModal}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={handleDelete}
          >
            <span className="indicator-label">
              {isLoading ? (
                <>
                  Deleting... <Loader />{" "}
                </>
              ) : (
                "Delete"
              )}
            </span>
          </button>
        </div>
      </>
    </div>
  );
};

export default DeleteDispositionModal;
