import React, { useEffect, useRef, useState } from "react";
import { CardContent, Checkbox, ListItemText, OutlinedInput } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { useGetAllUserListQuery, useGetCallDetailsReportQuery, useGetAllQueueQuery } from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const ContactDetailReport = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [reportType, setReportType] = useState("Inbound"); // Default to Inbound
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [selectedAssignee, setSelectedAssignee] = useState<any>([]);
  const [theUser, setTheUser] = useState<any>([]);
  const { data: userData, isLoading: userLoading } = useGetAllUserListQuery({});
  const { data: queueData, isLoading: queueLoading, refetch } = useGetAllQueueQuery({ skip: reportType === 'Outbound' });
  const [params, setParams] = useState<any>(null);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [allQueue, setAllQueue] = useState<any>([])
  const [selectedQueues, setSelectedQueues] = useState<any>([]);
  const [options, setOptions] = useState<any>([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [userOptions, setUserOptions] = useState<any[]>([]);
  // const [selectQueue, setSelectQueue] = useState<any>('')
  const [isAllAssigneeSelected, setIsAllAssigneeSelected] = useState(false)

  const {
    data: callsDetailsData,
    isLoading: callsDetailsLoading,
  } = useGetCallDetailsReportQuery(params, {
    skip: !params,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const columnsInbound: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr.No",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "account_name",
      headerName: "Account Name",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phone_number",
      headerName: "Customer Number",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "service_number",
      headerName: "Service Number",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "campaign",
      headerName: "Queue Name",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agent_role",
      headerName: "User Type",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agent_name",
      headerName: "User Name",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "xlite_no",
      headerName: "Xlite No",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "start_date",
    //   headerName: "Call Start Date",
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      field: "start_time",
      headerName: "Call Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ringing_duration",
      headerName: "Ringing Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "conversation_duration",
      headerName: "Conversation Duration",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hold_duration",
      headerName: "Hold Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "campaign_ivr_duration",
      headerName: "Campaign IVR Message",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ivr_duration",
      headerName: "IVR Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "call_duration",
      headerName: "Call Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "language",
      headerName: "Language Option Selected",
      minWidth: 180,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "transfer_duration",
    //   headerName: "Transfer Duration",
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   field: 'conference_duration',
    //   headerName: "Conference Duration",
    //   align: "center",
    //   headerAlign: "center",
    // },

    
   
   

    {
      field: "hangup_cause",
      headerName: "Hangup Cause",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hangup_sub_cause",
      headerName: "Hangup Sub Cause",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hangup_remark",
      headerName: " Hangup Remarks",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hangup_source",
      headerName: "Hangup Source",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "end_date",
    //   headerName: "Call End Date",
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "end_time",
      headerName: "End Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "disposition_time",
      headerName: "ACW Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "handletime",
      headerName: "Handle Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recording",
      headerName: "Recording",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        params.value !== "No data found" ? (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            Play
          </a>
        ) : (
          "No data found"
        )
      ),
    },
  ];

  const columnsOutbound: GridColDef[] = [
    { field: "sr_no", 
      headerName: "Sr.No",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
     },
    {
      field: "account_name",
      headerName: "Account Name",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phone_number",
      headerName: "Customer Number",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "service_number",
      headerName: "Service Number",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "campaign",
      headerName: "Campaign Name",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agent_role",
      headerName: "User Type",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agent_name",
      headerName: "User Name",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "xlite_no",
      headerName: "Xlite No",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agent_call_status",
      headerName: "Agent Call Status",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "start_date",
    //   headerName: "Call Start Date",
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      field: "start_time",
      headerName: "Call Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center"
    },
    // {
    //   field: "transfer_duration",
    //   headerName: "Transfer Duration",
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   field: 'conference_duration',
    //   headerName: "Conference Duration",
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "ringing_duration",
      headerName: "Dialing Duration",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "conversation_duration",
      headerName: "Conversation Duration",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hold_duration",
      headerName: "Hold Duration",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hangup_cause",
      headerName: "Hangup Cause",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hangup_sub_cause",
      headerName: "Hangup Sub Cause",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hangup_remark",
      headerName: "Hangup Remarks",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "hangup_source",
    //   headerName: "Hangup Source",
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   field: "end_date",
    //   headerName: "Call End Date",
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "end_time",
      headerName: "End Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "disposition_time",
      headerName: "ACW Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "handletime",
      headerName: "Handle Time",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recording",
      headerName: "Recording",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        params.value !== "No data found" ? (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            Play
          </a>
        ) : (
          "No data found"
        )
      ),
    },
  ];

  const handleReportTypeChange: any = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDataRows([])
    }, 500); // Small delay to simulate user interaction feedback
    setReportType(event.target.value as string);
    if (event.target.value === "Inbound") {
      refetch()
    }

    // Simulate download completion (due to CsvDownloader's lack of a completion callback)
  };

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };

  // const handleChange: any = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSelectedAssignee(event.target.value as string);
  // };
  // const handleQueueChange = (event: any) => {
  //   setSelectQueue(event.target.value);
  // };

useEffect(() => {
    if (queueData) {
      console.log(queueData,"queueData")
      // Transform queueData to match the expected format
      const transformedQueOptions = queueData.map(item => ({
        label: item.name, // Use 'name' for the display text
        value: item.id    // Use 'id' as the unique identifier
      }));

      setOptions(transformedQueOptions);
    }
  }, [queueData]);
  
  useEffect(() => {
    if (userData) {
      console.log(userData,"userData")
      // Transform queueData to match the expected format
      const transformedAssigneeOptions = userData.map(user => ({
        label: user.username, // Use 'name' for the display text
        value: user.id    // Use 'id' as the unique identifier
      }));

      setUserOptions(transformedAssigneeOptions);
    }
  }, [userData]);

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  // useEffect(() => {

  //   const transformedData = userData?.map((user) => ({
  //     id: user.id,
  //     username: user.username,
  //   }));
  //   if (Array.isArray(userData)) {
  //     setTheUser(transformedData);
  //   } else {
  //     setTheUser([]); // Fallback to an empty array if it's not an array
  //   }
  // }, [userData]);

  // useEffect(() => {
  //   const transformedData = queueData?.map((queue) => ({
  //     id: queue.id,
  //     name: queue.name,
  //   }));
  //   if (queueData && Array.isArray(queueData)) {
  //     setAllQueue(transformedData)
  //   }
  // }, [queueData]);

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (selectedAssignee.length === 0) {
      toast.error('Please select Assignee');
      return;
    }

    // if (!selectQueue && reportType === "Inbound") {
    //   toast.error('Please select Queue');
    //   return;
    // }
 if (selectedQueues.length === 0 && reportType === "Inbound") {
      toast.error('Please select a queue');
      return;
    }
    const selectedQueueLabels = selectedQueues.map((queueId) => {
      const queue = options.find((q) => q.value === queueId);
      console.log(queue,"queue")
      return queue ? queue.label : null;
    });
    const selectedAssigneeLabels = selectedAssignee.map((assigneeId) => {
      const assignee = userOptions.find((q) => q.value === assigneeId);
      console.log(assignee,"assignee")
      return assignee ? assignee.value : null;
    });
    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
      call_type: reportType === "Inbound" ? "inbound" : "outbound",
      queue: selectedQueueLabels,
      user_id:selectedAssigneeLabels
    };
console.log(queryParams,"queuename....")
    // if (selectedAssignee !== "All") {
    //   queryParams.user_id = selectedAssignee; // Add user_id when an assignee is selected
    // }
    // if (selectQueue !== 'All' && reportType === "Inbound") {
    //   queryParams.queue = selectQueue

    // }
    setParams(queryParams);
    console.log(queryParams,"setqueuename")

  };

  function formatDate(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  function formatTime(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  const formatDuration = (duration) => {
    const parts = duration.split(':').map(part => part.padStart(2, '0'));
    return parts.join(':');
  };
  useEffect(() => {
    if (Array.isArray(callsDetailsData)) {
      const transformedRows = callsDetailsData.map((item, index) => {
        const commonData = {
          id: index + 1,
          sr_no: index + 1, // Sr.No is a serial number
          account_name: item.contacts[0]?.account?.name || "-", // Assuming account_name doesn't exist in the data
          customer_name: item.contacts[0]?.name || "-", // Assuming customer_name doesn't exist in the data
          phone_number: item.customer_phone_no || "-",
          service_number: item.called_from_phone_no || "-",
          campaign: item.campaign_name || "-",
          agent_role: item.agent_role || "-",
          agent_name: item.agent_name || "-",
          xlite_no: item.extension_no || "-",
          start_time:item.call_start_time_display|| "-",
          // start_date: formatDate(item.call_start_time_display) || "No data found",

          // transfer_duration: "No data found",
          // Conference_duration: "No data found",

          duration: item.duration ? formatDuration(item.duration) : "-",
          ringing_duration: item.ringing_seconds || "-",
          conversation_duration: item.conversation_duration ? formatDuration(item.conversation_duration) : "",
          hold_duration: item.hold_duration_display ? formatDuration(item.hold_duration_display) : "",
          hangup_cause: item.dispose_remark || "-",
          hangup_sub_cause: item.sub_dispose_remark || "-",
          hangup_remark: item.remarks || "-",
          hangup_source: item.hangup_source || "-",
          end_time: item.call_end_time_display || "-",
          end_date: formatDate(item.call_end_time_display) || "-",
          disposition_time: item.acw_duration_display ? formatDuration(item.acw_duration_display) : "-",
          handle_time: item.call_duration || "-",
          recording: item.recording_link || "-",
          call_duration:item.call_duration  || "-",
          handletime: item.duration ? formatDuration(item.duration) : "-",
        };

        return reportType === "Inbound"
          ? {
            ...commonData,
            campaign_ivr_duration: item.campaign_ivr_msg || "-",
            ivr_duration: item.ivr_duration1 || "-",
            language:item.language || "-",


          }
          : {
            ...commonData,
            agent_call_status: item.agent_outbound_call_status || "-",
          };
      });

      setDataRows(transformedRows);
      setLocalLoading(false);
    } else {
      setDataRows([]); // Reset if data is not an array
      setLocalLoading(false);
    }

    setLocalLoading(false);
  }, [callsDetailsData, reportType]);





  const downloaderRef = useRef(null);


  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);
    setSelectedQueues([]);
    setSelectedAssignee([]);
    

  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };


  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };


  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  console.log(allQueue)
  const handleQueueChange = (event) => {
    const { target: { value } } = event;
    if (value.includes("all")) {
      setIsAllSelected(!isAllSelected);
      setSelectedQueues(isAllSelected ? [] : options.map(option => option.value));
    } else {
      setSelectedQueues(value);
      setIsAllSelected(value.length === options.length);
    }
  };

  const handleAssigneeChange = (event) => {
    const { target: { value } } = event;
    if (value.includes("all")) {
      setIsAllAssigneeSelected(!isAllAssigneeSelected);
      setSelectedAssignee(isAllAssigneeSelected ? [] : userOptions.map(option => option.value));
    } else {
      setSelectedAssignee(value);
      setIsAllAssigneeSelected(value.length === userOptions.length);
    }
  };

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* Back button */}
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/telephony/reports-list");
            }}
          /> */}
          <button
              className="btn btn-link mb-2"
              onClick={() => {
                navigate("/reports/telephony/reports-list");
              }}
              style={{marginRight:"-17px"}}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3"> Call Details</span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>


          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="select-type">Select Type</InputLabel>
              <Select
                labelId="select-type"
                id="select-type"
                label="Select Type"
                value={reportType}
                onChange={handleReportTypeChange}
              >
                <MenuItem value="Inbound">Inbound</MenuItem>
                <MenuItem value="Outbound">Outbound</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select labelId="Assignee-select-label" id="Assignee-select" label="Assignee" MenuProps={MenuProps} value={selectedAssignee}
                onChange={handleChange}>
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {theUser?.map((assignee: any) => (
                  <MenuItem key={assignee.id} value={assignee.id}>
                    {assignee.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
  <div className="w-100 mw-150px">
              <FormControl fullWidth>
                <InputLabel id="assignee-select-label">Assignee</InputLabel>
                <Select
                  labelId="assignee-select-label"
                  id="assignee-select"
                  multiple
                  value={selectedAssignee}
                  onChange={handleAssigneeChange}
                  input={<OutlinedInput label="Assignee" />}
                  renderValue={(selected) => {
                    if (isAllAssigneeSelected) {
                      return <div>All</div>;
                    }
                    return (
                      <div>
                        {selected
                          .map((assigneeId) => {
                            const assignee = userOptions.find((q) => q.value === assigneeId);
                            return assignee ? assignee.label : null;
                          })
                          .join(", ")}
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    onClick={() => setIsAllAssigneeSelected(!isAllAssigneeSelected)}
                  >
                    <Checkbox checked={isAllAssigneeSelected} indeterminate={selectedAssignee.length > 0 && !isAllAssigneeSelected} />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {userOptions.map((assignee) => (
                    <MenuItem key={assignee.value} value={assignee.value}>
                      <Checkbox checked={selectedAssignee.indexOf(assignee.value) > -1} />
                      <ListItemText primary={assignee.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          {reportType === "Inbound" && (

            // <div className="w-100 mw-150px">
            //   <FormControl fullWidth>
            //     <InputLabel id="Queue-select-label">Queue</InputLabel>
            //     <Select
            //       labelId="Queue-select-label"
            //       id="Queue-select"
            //       label="Queue"
            //       value={selectQueue}
            //       onChange={handleQueueChange}
            //       MenuProps={MenuProps}
            //     >
            //       {" "}
            //       <MenuItem key="all" value="All">
            //         All
            //       </MenuItem>
            //       {allQueue?.map((queue: any) => (

            //         <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
            //       ))}
            //     </Select>
            //   </FormControl>
            // </div>
            <div className="w-100 mw-150px">
              <FormControl fullWidth>
                <InputLabel id="queue-select-label">Select Queues</InputLabel>
                <Select
                  labelId="queue-select-label"
                  id="queue-select"
                  multiple
                  value={selectedQueues}
                  onChange={handleQueueChange}
                  input={<OutlinedInput label="Select Queues" />}
                  renderValue={(selected) => {
                    if (isAllSelected) {
                      return <div>All</div>;
                    }
                    return (
                      <div>
                        {selected
                          .map((queueId) => {
                            const queue = options.find((q) => q.value === queueId);
                            return queue ? queue.label : null;
                          })
                          .join(", ")}
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    onClick={() => setIsAllSelected(!isAllSelected)}
                  >
                    <Checkbox checked={isAllSelected} indeterminate={selectedQueues.length > 0 && !isAllSelected} />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {options.map((queue) => (
                    <MenuItem key={queue.value} value={queue.value}>
                      <Checkbox checked={selectedQueues.indexOf(queue.value) > -1} />
                      <ListItemText primary={queue.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}


          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>


            {/* CsvDownloader component outside the button */}
            <button className="btn btn-primary">
              <CsvDownloader
                ref={downloaderRef}
                filename={`${reportType}-call-details-report`}
                datas={dataRows}
                columns={(reportType === "Inbound" ? columnsInbound : columnsOutbound).map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}

              >Download</CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>



        <CardContent style={{ width: "100%" }}>
          {userLoading || callsDetailsLoading || localLoading || queueLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={
                reportType === "Inbound" ? columnsInbound : columnsOutbound
              }
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default ContactDetailReport;
