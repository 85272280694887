export const AUTH_TOKEN = 'auth-token'
export const CIRCLEONE_USER = 'circleone-user'
export const CIRCLEONE_USERNAME = "circleone-username"
export const CIRCLEONE_TEAM = 'circleone-team'
export const CIRCLEONE_LOGIN_TYPE = 'circleone-login-type'
export const USER_TOKEN = 'user-token'
export const USER_TEAM_ID = 'teamId'
export const EXTENSION = 'extension'
export const TELEPHONEURL = 'telephoneurl'
export const TELEPHONYLOGIN = 'telephonyLogin'
export const CALLBREAKID = 'call-break-id'
export const QUEUE = 'queues'
export const TELEPHONYLOGINID = 'telephony-login-id'
export const TELPEHONYLOGINUSERID = 'telephony-login-user_id'
export const CALL_BREAK_USER_ID = 'call-break-user-id'
export const CALL_BREAK_REMARK = 'call_break_remark'
export const CALL_BREAK_PAUSE_ID = 'call_break_Pause_id'
export const CALL_HISTORY_ID = 'call_history_id'
export const CALL = 'call'
export const TELEPHONY_LOGIN = 'telephony_login'
export const OUTGOING_CALL = 'telephony_outgoing'
export const INCOMING_CALL = 'telephony_incoming'
export const USER_LOGIN_PASSWORD = 'password'
