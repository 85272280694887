import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import {
  useDispositionMutation,
  useTeamsQuery,
  useWorkflowsMutation,
} from "../../../../Services/settingsAPIs/CommonAPI";

const AddSLAModal = ({ closeModal }) => {
  return (
    <>
      <div className="d-flex flex-column align-items-center w-300px w-lg-600px">
        <div className="row">
          <div className="col">
            <label className="fs-4" htmlFor="">
              Add level
            </label>
          </div>
        </div>
        <div className="row min-w-1000px">
          <div className="col">
            <div className="input-group-text mt-2 bg-white">
              <div className="row w-100 align-items-center">
                <div className="col-lg-4 col text-start">
                  <input
                    className="form-control border-0"
                    type="text"
                    value="Escalation level 01"
                  />
                </div>
                <div className="col-1">
                  <div className="d-flex justify-content-start align-items-center">
                    <label htmlFor="">00</label>
                    <div className="d-flex flex-column ms-2">
                      <i className="bi bi-caret-up-fill"></i>
                      <i className="bi bi-caret-down-fill"></i>
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <div className="d-flex justify-content-start align-items-center">
                    <label htmlFor="">00</label>
                    <div className="d-flex flex-column ms-2">
                      <i className="bi bi-caret-up-fill"></i>
                      <i className="bi bi-caret-down-fill"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col text-start">
                  <input
                    className="form-control border-0"
                    type="text"
                    placeholder="mail id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col text-end">
          <button className="btn btn-primary" onClick={closeModal}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

const WorkflowBasedSLA = () => {
  const customAddlevelModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const [triggerDispositionMutation] = useDispositionMutation();
  const [triggerGetWorkflowsMutation] = useWorkflowsMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<number>(1);
  const [theDispositions, setTheDispositions] = useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState("");
  const [theWorkflows, setTheWorkflows] = useState([]);
  const [selectedWF, setSelectedWF] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsQuery({});

  const [escalationsAtStages, setEscalationsAtStages] = useState<any>([
    {
      status: "New",
      escalations: [
        {
          level: 1,
          atElapsedTime: { hours: 1, minutes: 0 },
          escalationTo: "jay@bluewhirl.io",
        },
      ],
      showExpanded: false,
    },
    {
      status: "Demo",
      escalations: [
        {
          level: 1,
          atElapsedTime: { hours: 0, minutes: 30 },
          escalationTo: "coy@bluewhirl.io",
        },
        {
          level: 2,
          atElapsedTime: { hours: 0, minutes: 45 },
          escalationTo: "boy@bluewhirl.io",
        },
        {
          level: 3,
          atElapsedTime: { hours: 2, minutes: 0 },
          escalationTo: "joy@bluewhirl.io",
        },
      ],
      showExpanded: false,
    },
    {
      status: "Proposal",
      escalations: [
        {
          level: 1,
          atElapsedTime: { hours: 3, minutes: 0 },
          escalationTo: "riya@bluewhirl.io",
        },
      ],
      showExpanded: false,
    },
    {
      status: "Negotiation",
      escalations: [
        {
          level: 1,
          atElapsedTime: { hours: 4, minutes: 0 },
          escalationTo: "joy@bluewhirl.io",
        },
      ],
      showExpanded: false,
    },
  ]);

  const toggleExpansion = (stageIndex: number) => {
    setEscalationsAtStages((prev: any) => {
      const updated = prev.map((stage: any, index: number) =>
        index === stageIndex
          ? { ...stage, showExpanded: !stage.showExpanded }
          : stage
      );
      return updated;
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTeamDropdownChange = (evt: any) => {
    setSelectedTeam(evt.target.value);
    triggerDispositionMutation(evt.target.value)
      .then((res: any) => res.data && setTheDispositions(res.data))
      .catch((err: any) => alert(err));
  };
  const handleSelectDispositionChange = (event: any) => {
    setSelectedDisposition(event.target.value);
    triggerGetWorkflowsMutation({}).then(
      (res: any) => res.data && setTheWorkflows(res.data.results)
    );
  };
  const handleSelectWFChange = (event: any) => {
    setSelectedWF(event.target.value);
  };
  const handleSelectPriorityChange = (event: any) => {
    setSelectedPriority(event.target.value);
  };

  useEffect(() => {
    setTheTeams(teamsData);
  }, [teamsData]);
  return (
    <div>
      <div className="row mb-2">
        <div className="col d-lg-flex">
          <div className="d-flex flex-column text-start p-2">
            <label htmlFor="" className="form-label">
              Select team
            </label>
            <div>
              <select
                className="form-select select2-hidden-accessible py-4"
                onChange={handleTeamDropdownChange}
                value={selectedTeam}
              >
                <option value="">Select team</option>
                {theTeams &&
                  theTeams.map((team, index) => (
                    <option value={index + 1}>{team.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div
            className={`d-flex flex-column text-start p-2 ${
              selectedTeam === 0 ? "d-none" : ""
            }`}
          >
            <label htmlFor="" className="form-label">
              Select disposition
            </label>
            <div>
              <select
                className="form-select select2-hidden-accessible py-4"
                onChange={handleSelectDispositionChange}
                value={selectedDisposition}
              >
                <option value="">Select disposition</option>
                {theDispositions.map((theDisposition) => (
                  <option value={theDisposition.id}>
                    {theDisposition.topic}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className={`d-flex flex-column text-start p-2 ${
              selectedDisposition === "" ? "d-none" : ""
            }`}
          >
            <label htmlFor="" className="form-label">
              Select workflow
            </label>
            <div>
              <select
                className="form-select select2-hidden-accessible py-4"
                onChange={handleSelectWFChange}
                value={selectedWF}
              >
                <option value="">Select workflow</option>
                {theWorkflows.map((theworkflow: any) => (
                  <option value={theworkflow.id}>{theworkflow.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div
            className={`d-flex flex-column text-start p-2 ${
              selectedWF === "" ? "d-none" : ""
            }`}
          >
            <label htmlFor="" className="form-label">
              Priority
            </label>
            <div>
              <select
                className="form-select select2-hidden-accessible py-4"
                onChange={handleSelectPriorityChange}
                value={selectedPriority}
              >
                <option value="">Select Priority</option>
                <option value="low" selected>
                  Low
                </option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
              </select>
            </div>
          </div>
          <div
            className={`d-flex flex-column text-start p-2 ${
              selectedWF === "" ? "d-none" : ""
            }`}
          >
            <label htmlFor="" className="form-label">
              Resolution SLA
            </label>
            <div className="input-group-text bg-white d-flex justify-contents-between">
              <div className="d-flex justify-content-start align-items-center">
                <input
                  type="number"
                  className="form-control border-0 py-1"
                  placeholder="hours"
                  min={0}
                />
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <input
                  type="number"
                  className="form-control border-0 py-1"
                  placeholder="minutes"
                  min={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2 mt-4 overflow-auto">
        <div className="row">
          <div className="col">
            <div
              className="card my-1 min-h-40px min-w-600px justify-content-center"
              style={{ backgroundColor: "rgba(228, 228, 228, 0.498)" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-4 col text-start ps-10">
                  <label htmlFor="">
                    <strong>Status</strong>
                  </label>
                </div>
                <div className="col-lg-2 col text-center">
                  <label htmlFor="">
                    <strong>Escalation Level</strong>
                  </label>
                </div>
                <div className="col-1 col text-center">
                  <label htmlFor="">
                    <strong>Hours</strong>
                  </label>
                </div>
                <div className="col-1 col text-center">
                  <label htmlFor="">
                    <strong>Minutes</strong>
                  </label>
                </div>
                <div className="col-lg-2 col text-center">
                  <label htmlFor="">
                    <strong>Mail ID</strong>
                  </label>
                </div>
                <div className="col-lg-2 col text-end">
                  <label htmlFor="">
                    <strong></strong>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {escalationsAtStages.map((stage: any, stageIndex) => (
          <>
            <div className="row">
              <div className="col">
                <div className="input-group-text mt-2 bg-white min-w-600px">
                  <div className="row w-100 align-items-center">
                    {stage.escalations.map((escalation, escalationIndex) => (
                      <>
                        <div
                          className={`row ${
                            escalationIndex > 0 && !stage.showExpanded
                              ? "d-none"
                              : ""
                          }`}
                        >
                          <div className="col-4 text-start">
                            {escalationIndex === 0 && stage.status}
                          </div>
                          <div className="col-2 text-center">
                            <label htmlFor="">{escalation.level}</label>
                          </div>
                          <div className="col-1 text-center">
                            <div className="d-flex justify-content-center align-items-center">
                              <input
                                type="number"
                                className="form-control border-0 py-1"
                                min={0}
                              />
                            </div>
                          </div>
                          <div className="col-1 text-center">
                            <div className="d-flex justify-content-center align-items-center">
                              <input
                                type="number"
                                className="form-control border-0 py-1"
                                min={0}
                              />
                            </div>
                          </div>
                          <div className="col-2 text-center">
                            {escalation.escalationTo}
                          </div>
                          <div className="col-2 text-end">
                            <button className="btn btn-link py-0">
                              <i
                                className="text-dark bi bi-plus-lg"
                                onClick={openModal}
                              ></i>
                            </button>
                            <button className="btn btn-link py-0">
                              <i
                                className={`ms-2 text-dark ${
                                  escalationIndex === 0
                                    ? stage.showExpanded
                                      ? "bi bi-chevron-up"
                                      : "bi bi-chevron-down"
                                    : "bi bi-bookmark"
                                }`}
                                onClick={() => toggleExpansion(stageIndex)}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customAddlevelModalStyles}
        contentLabel="New Ticket"
      >
        <AddSLAModal closeModal={closeModal} />
      </ReactModal>
    </div>
  );
};

export default WorkflowBasedSLA;
