import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useChatFilters } from "../../../utils/hooks/useChatFilters";
import { useSearchParams } from "react-router-dom";
import ChatContext from "../../../context/ChatContext";
import { createInitials } from "../../../utils/functions/createInitials";
import { IChatbotState } from "../../../utils/interfaces/IChatbotState";
import SkeletonCard from "./SkeletonCard";
import UserContext from "../../../context/UserContext";
import { v4 as uuidv4 } from "uuid";
import { KTSVG } from "../../../helpers/components/KTSVG";
import SidePanel from "../SidePanel";
import GeoLocation from "./GeoLocation";
import { parse } from "path";
import TeamActionMenu from "../TeamActionMenu";

interface IProps {
  type: "Unassigned" | "Assigned" | "Resolved";
  chatRoomID: any;
  isData2: any;
  isData3: any;
  handleOpenChat: any;
  disabledIndexes: any;
}

const AssignedLeftPanel: React.FC<IProps> = ({
  type,
  chatRoomID,
  isData2,
  isData3,
  handleOpenChat,
  disabledIndexes,
}) => {
  const {
    state,
    setState,
    isDashUserOnline,
    newAssignedChat,
    getAssignedChatrooms,
    markChatRoomAsResolved,
    setTotalUnreadAssignedChats,
    sendMessageToEndUser,
  } = useContext(ChatContext);
  const { operations } = useChatFilters();
  const CurrentUserRole = localStorage.getItem("user_role");
  const currentUser = localStorage.getItem("circleone-username");
  const tabTitles = CurrentUserRole?.includes("admin")
    ? ["Unassigned", "Assigned"]
    : ["Assigned"];
  const [searchParams, setSearchParams] = useSearchParams();
  const chatroomID = searchParams.get("chatRoomId");
  const [updateChatRoomsFlag, setUpdateChatRoomsFlag] =
    useState<boolean>(false);
  const [applicationIds] = useState<number[]>([1, 2]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLocation, setIsLocation] = useState<any>();
  const chatRoomsArrayRef = useRef<any[]>([]);
  const didComponentMountRef = useRef<boolean>(false);
  const chatWindowDropdownMenuRef = useRef<any[]>([]);
  useEffect(() => {
    // Load Assigned Chatrooms
    if (isDashUserOnline && newAssignedChat) {
      setIsLoading(true);
      getAssignedChatrooms().then((res: any) => {
        setIsLoading(false);
        if (!!res) {
          console.warn(res);
          const assignedChatrooms = res.assigned_chatrooms;
          let assignedChatRoomsArray = [];
          if (assignedChatrooms[0] !== null) {
            assignedChatRoomsArray = assignedChatrooms[0]
              ?.filter((chatRoom: any) => !!chatRoom)
              ?.map((chatRoom: any) => {
                let initials: any = "Unnamed";

                if (
                  chatRoom?.chatRoomMeta &&
                  chatRoom?.chatRoomMeta?.length > 0
                ) {
                  const metaName = chatRoom?.chatRoomMeta?.find(
                    (metaData: any) => metaData?.name === "Name"
                  );
                  if (metaName) {
                    initials = createInitials(metaName?.value);
                  }
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom?.id);
                } else {
                  initials = createInitials("");
                }

                return {
                  ...chatRoom,
                  initials,
                };
              })
              .sort((a: any, b: any) => {
                // Sort by putting last chatRoomID in first
                return parseInt(b.id) - parseInt(a.id);
              });
          }
          // console.log(assignedChatRoomsArray)
          setTotalUnreadAssignedChats(assignedChatRoomsArray?.length);
          chatRoomsArrayRef.current = assignedChatRoomsArray;
          // console.log(chatRoomsArrayRef.current)
          setUpdateChatRoomsFlag(true);
          console.log("Set to call useEffect to load assigned chatRooms");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAssignedChat]);

  useEffect(() => {
    // Load Assigned Chatrooms
    if (isDashUserOnline && type === "Assigned") {
      setIsLoading(true);
      getAssignedChatrooms().then((res: any) => {
        setIsLoading(false);
        if (!!res) {
          console.warn(res);
          const assignedChatrooms = res.assigned_chatrooms;
          let assignedChatRoomsArray = [];
          if (assignedChatrooms[0] !== null) {
            assignedChatRoomsArray = assignedChatrooms[0]
              ?.filter((chatRoom: any) => !!chatRoom)
              .map((chatRoom: any) => {
                let initials;

                if (
                  chatRoom?.chatRoomMeta &&
                  chatRoom?.chatRoomMeta?.length > 0
                ) {
                  const metaName = chatRoom?.chatRoomMeta?.find(
                    (metaData: any) => metaData.name === "Name"
                  );
                  if (metaName) {
                    initials = createInitials(metaName.value);
                  } else if (chatRoom.id) {
                    initials = createInitials(chatRoom.id);
                  } else {
                    initials = createInitials("");
                  }
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id);
                } else {
                  initials = createInitials("");
                }

                return {
                  ...chatRoom,
                  initials,
                };
              })
              .sort((a: any, b: any) => {
                // Sort by putting last chatRoomID in first
                return parseInt(b.id) - parseInt(a.id);
              });
          }
          // console.log(assignedChatRoomsArray)
          setTotalUnreadAssignedChats(assignedChatRoomsArray?.length);
          chatRoomsArrayRef.current = assignedChatRoomsArray;
          setUpdateChatRoomsFlag(true);
          console.log("Set to call useEffect to load assigned chatRooms");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isDashUserOnline]);

  useEffect(() => {
    if (state.flagToRefreshChatrooms === true && type === "Assigned") {
      console.log(state.flagToRefreshChatrooms);
      getAssignedChatrooms().then((res: any) => {
        setIsLoading(false);
        if (!!res) {
          console.warn(res);
          const assignedChatrooms = res.assigned_chatrooms;
          const assignedChatRoomsArray = assignedChatrooms[0]
            // if (assignedChatrooms[0] !== null) {

            ?.filter((chatRoom: any) => !!chatRoom)
            .map((chatRoom: any) => {
              let initials;

              if (
                chatRoom?.chatRoomMeta &&
                chatRoom?.chatRoomMeta?.length > 0
              ) {
                const metaName = chatRoom?.chatRoomMeta?.find(
                  (metaData: any) => metaData.name === "Name"
                );
                if (metaName) {
                  initials = createInitials(metaName.value);
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id);
                } else {
                  initials = createInitials("");
                }
              } else if (chatRoom.id) {
                initials = createInitials(chatRoom.id);
              } else {
                initials = createInitials("");
              }

              return {
                ...chatRoom,
                initials,
              };
            })
            .sort((a: any, b: any) => {
              // Sort by putting last chatRoomID in first
              return parseInt(b.id) - parseInt(a.id);
            });
          setState(
            (prevState: IChatbotState) => ({
              ...prevState,
              chatRooms: assignedChatRoomsArray,
              flagToRefreshChatrooms: false,
            }),
            (updatedState: IChatbotState) => {
              // console.log(updatedState.chatRooms)
              if (updatedState.chatRooms.length === 0) {
                setSearchParams({ type: type as string });
              }
              if (didComponentMountRef.current === false) {
                didComponentMountRef.current = true;
              }
            }
          );
          // }
        }
      });
    }
  }, [state.flagToRefreshChatrooms, type]);

  useEffect(() => {
    // On receiving notification, push new Assigned chatrooms into the existing array
    if (
      !!isDashUserOnline &&
      !!newAssignedChat &&
      Object.keys(newAssignedChat).length > 0
    ) {
      if (type === "Assigned") {
        let initials;

        if (
          newAssignedChat.chatRoomMeta &&
          newAssignedChat.chatRoomMeta.length > 0
        ) {
          const metaName = newAssignedChat.chatRoomMeta.find(
            (metaData: any) => metaData.name === "Name"
          );
          if (metaName) {
            initials = createInitials(metaName.value);
          } else if (newAssignedChat.id) {
            initials = createInitials(newAssignedChat.id);
          } else {
            initials = createInitials("");
          }
        } else if (newAssignedChat.id) {
          initials = createInitials(newAssignedChat.id);
        } else {
          initials = createInitials("");
        }

        chatRoomsArrayRef?.current?.unshift({
          ...newAssignedChat,
          initials,
        });
        setUpdateChatRoomsFlag(true);
        // console.log('Added new assigned chat')
        // console.log(chatRoomsArrayRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashUserOnline, newAssignedChat, type]);

  useEffect(() => {
    if (updateChatRoomsFlag === true) {
      const chatRoomsArray = chatRoomsArrayRef.current;
      // console.log(chatRoomsArray)
      console.log("useEffect called to update Chat Rooms array !");
      setState(
        (prevState: IChatbotState) => ({
          ...prevState,
          chatRooms: chatRoomsArray,
        }),
        (updatedState: IChatbotState) => {
          // console.log(updatedState.chatRooms)
          if (updatedState?.chatRooms?.length === 0) {
            setSearchParams({ type: type as string });
          }
          if (didComponentMountRef.current === false) {
            didComponentMountRef.current = true;
          }
        }
      );
      return () => {
        setUpdateChatRoomsFlag(false);
      };
    }
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("chatRoomId")) {
      params.delete("chatRoomId");
      setSearchParams(Object.fromEntries(params.entries()));
    }
  }, []);

  // update chatFilters on changing applicationIds array
  useEffect(() => {
    operations?.updateChatFilters("applicationIds", applicationIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationIds]);

  const handleChatRoomClick = (chatRoom: any) => {
    // localStorage.setItem('chatRoomId', chatRoom.id)
    setSearchParams({ type: type, chatRoomId: chatRoom.id });
    setIsLocation(chatRoom.endUserLocation);
    setState((prevState: IChatbotState) => {
      console.log(
        "Existing panelBasedConversation:",
        prevState.panelBasedConversation
      );
      const existingPanel = prevState.panelBasedConversation.find(
        (panel) => panel.chatRoomId === chatRoom.id
      );
      console.log(existingPanel?.chatRoomId);
      if (!existingPanel) {
        return {
          ...prevState,
          panelBasedConversation: [
            // ...prevState.panelBasedConversation,
            {
              chatRoomId: parseInt(chatRoom.id),
              conversation: [],
            },
          ],
        };
      }
      return prevState;
    });
  };

  chatWindowDropdownMenuRef.current = state?.chatRooms?.map(
    (chatRoom: any, index: number) =>
      chatWindowDropdownMenuRef.current[index] ?? createRef()
  );
  // console.log(state.panelBasedConversation)

  if (!!isLoading) {
    return (
      <>
        <SkeletonCard />
      </>
    );
  }

  return (
    <>
      <SidePanel
        sendMessageToEndUser={sendMessageToEndUser}
        data2={isData2}
        currentUser={currentUser}
        chatRoomId={chatRoomID}
        chatRoomMetaData={isData3}
        markChatroomAsResolved={markChatRoomAsResolved}
        getAssignedChatrooms={getAssignedChatrooms}
        setTotalUnreadAssignedChats={setTotalUnreadAssignedChats}
        setUpdateChatRoomsFlag={setUpdateChatRoomsFlag}
        chatRoomsArrayRef={chatRoomsArrayRef}
        type={type}
        setState={setState}
      />
      <GeoLocation isLocation={isLocation} />
      <div
        className="card card-flush mb-4"
        style={{ boxShadow: "0 2px 8px 0 #00000029", height: "425px" }}
      >
        <div
          className="card-header px-8 py-2"
          id="kt_chat_contacts_header"
          style={{ minHeight: "0px" }}
        >
          <ul
            className="nav nav-stretch nav-line-tabs border-transparent flex-nowrap mb-5"
            style={{ margin: "auto" }}
          >
            {tabTitles?.map((title, index) => (
              <li className="nav-item" key={uuidv4()}>
                <span
                  className={`nav-link cursor-pointer ${
                    type === title ? "active fw-bolder" : ""
                  }`}
                  onClick={() => setSearchParams({ type: title })}
                  role="tab"
                >
                  {title}
                </span>
              </li>
            ))}
          </ul>

          {/* <form className='w-100 position-relative' autoComplete='off'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
            />
            <input
              type='text'
              className='form-control form-control-solid px-15'
              name='search'
              disabled
              placeholder='Search Chat...'
            />
          </form> */}
        </div>

        <div
          className="card-body pb-4 pt-0 ps-6 pe-0"
          style={{ overflowY: "scroll" }}
        >
          <div className="mh-700px">
            {state?.chatRooms?.length > 0 ? (
              state?.chatRooms?.map((chatRoom: any, index: number) => (
                <React.Fragment key={`unique-key-${uuidv4()}`}>
                  <div className="d-flex align-items-center py-4">
                    <div className="symbol symbol-45px symbol-circle">
                      {chatRoom.initials && (
                        <span
                          className={`symbol-label bg-light-${chatRoom?.initials?.state} text-${chatRoom?.initials?.state} fs-6 fw-bolder z-index-1`}
                        >
                          {chatRoom?.initials?.label}
                        </span>
                      )}
                    </div>

                    <div className="ms-5 d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <span
                          role="button"
                          onClick={() => handleChatRoomClick(chatRoom)}
                          className={`fs-5 fw-bolder ${
                            chatRoom?.id?.toString() === chatroomID
                              ? "text-primary"
                              : "text-gray-900"
                          } text-hover-primary mb-2`}
                        >
                          {!!chatRoom.chatRoomMeta && (
                            <>
                              CONV -{" "}
                              {chatRoom.chatRoomMeta.length > 0 &&
                              chatRoom.chatRoomMeta.find(
                                (metaData: any) => metaData.name === "Name"
                              )
                                ? chatRoom.chatRoomMeta.find(
                                    (metaData: any) => metaData.name === "Name"
                                  ).value
                                : chatRoom?.id || ""}
                            </>
                          )}
                        </span>

                        <span className="fw-bold text-muted ms-20">
                          {!!chatRoom?.chatName &&
                            new Intl.DateTimeFormat("en-IN", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                              timeZone: "Asia/Kolkata",
                            }).format(new Date(chatRoom.chatName.time))}
                        </span>
                      </div>
                      <div className="d-flex">
                        {!!chatRoom?.chatName && (
                          <>
                            <div className="fw-bold text-muted">
                              BOT-{chatRoom?.chatName?.applicationId}
                            </div>
                            <div className="ms-3 fw-bold text-muted">
                              CUST-{chatRoom?.chatName?.bgAccountId}
                            </div>
                            {chatRoom?.chatName?.agentId && (
                              <div className="ms-3 fw-bold text-muted">
                                AGENT-{chatRoom?.chatName?.agentId}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <div
                        id={`user-menu-${index}`}
                        ref={chatWindowDropdownMenuRef.current[index]}
                      >
                        <TeamActionMenu
                          index={index}
                          user={chatRoom}
                          id={chatRoomID}
                          chatWindowDropdownMenuRef={chatWindowDropdownMenuRef}
                          handleOpenChat={() => handleOpenChat(chatRoom)} // Pass user object
                          isDisabled={disabledIndexes.includes(chatRoom.id)}
                        />
                      </div>
                    </div>
                    <div className="separator separator-dashed d-none" />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <>
                <p className="text-center my-10">No Chatrooms to show</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
    // </div>
  );
};

export default AssignedLeftPanel;
