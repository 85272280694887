import React from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
// import { CREATE_CHATROOM_META } from '../../graphql/mutation'
// import { GET_CHAT_ROOM_META_BY_CHATROOM_ID } from '../../graphql/query'
import { toast } from 'react-toastify'

interface Props {
  chatRoomId: number
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  closeAddMeta: () => void
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
}

const addMetaSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Name is required'),
  value: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Value is required')
})

const AddMetaModal: React.FC<Props> = ({ chatRoomId, setIsLoading, closeAddMeta, type }) => {
  // const [createChatRoomMeta, { loading: loadingAddMeta }] = useMutation(CREATE_CHATROOM_META, {
  //   refetchQueries: [
  //     {
  //       query: GET_CHAT_ROOM_META_BY_CHATROOM_ID,
  //       variables: {
  //         input: {
  //           chatRoomId: chatRoomId
  //         }
  //       },
  //       context: {
  //         headers: {
  //           'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //         }
  //       }
  //     }
  //   ],
  //   context: {
  //     headers: {
  //       'access-control-allow-methods': type === 'Assigned' ? 'Human-hand-off' : 'Chat-history'
  //     }
  //   }
  // })
  const initialValues = {
    name: '',
    value: ''
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addMetaSchema,
    onSubmit: (values, { resetForm }) => {
      // setIsLoading(true)
      // createChatRoomMeta({
      //   variables: {
      //     input: {
      //       chatRoomId: chatRoomId,
      //       meta: [
      //         {
      //           name: values.name,
      //           value: values.value
      //         }
      //       ]
      //     }
      //   }
      // })
      //   .then((res: any) => {
      //     // setIsLoading(false)
      //     resetForm()
      //     closeAddMeta()
      //     console.log(res)
      //     // Need to rewrite the following logic
      //     if (parseInt(res.data.createChatRoomMeta[0].id) > 0) {
      //       toast.success('Succesfully Added Chat Data')
      //     } else {
      //       toast.error('Unable to Add Data')
      //     }
      //   })
      //   .catch((err) => {
      //     // setIsLoading(false)
      //     toast.error('Sorry! Unexpected Error.')
      //     console.error(err)
      //   })
    }
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
        <input
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.name && formik.errors.name },
            {
              'is-valid': formik.touched.name && !formik.errors.name
            }
          )}
          type='text'
          name='name'
          autoComplete='off'
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.name}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Value</label>
        <input
          {...formik.getFieldProps('value')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.value && formik.errors.value },
            {
              'is-valid': formik.touched.value && !formik.errors.value
            }
          )}
          type='text'
          name='value'
          autoComplete='off'
        />
        {formik.touched.value && formik.errors.value && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.value}</span>
          </div>
        )}
      </div>

      <div className='d-flex justify-content-end'>
        <button
          type='reset'
          className='btn btn-sm btn-white btn-active-light-primary me-2'
          onClick={closeAddMeta}
        >
          Cancel
        </button>

        <button
          type='submit'
          className='btn btn-sm btn-primary'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.name}
        >
          {/* {!loadingAddMeta && <span className='indicator-label'>Add</span>}
          {loadingAddMeta && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )} */}
        </button>
      </div>
    </form>
  )
}

export default AddMetaModal
