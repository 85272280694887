import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonChatRoom = () => {
  return (
    <>
      <SkeletonTheme baseColor='#EBEBEB' highlightColor='#f5F5F5'>
        <div className='card card-body w-100 w-350px p-0 ms-5' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover' />
              <div className='d-flex justify-content-center flex-column me-3'>
                <Skeleton className='skeleton ms-1' width='10rem' height={20} />

                <div className='mb-0 lh-5 d-flex mt-1'>
                  <span>
                    <Skeleton circle className='circle-skeleton ms-1' width={10} height={12} />
                  </span>
                  <span className='fs-7 fw-bold text-gray-400 mt-n1'>
                    <Skeleton className='skeleton ms-2 mt-3' width='7rem' height={12} />
                  </span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='me-2'>
                <Skeleton className='skeleton me-5 mt-3' width='2rem' height={12} />
              </div>
              <div className='me-n3'>
                <Skeleton className='skeleton me-5 mt-2' width='2rem' height={25} />
              </div>
            </div>
          </div>
          <div className='card-body' id='kt_chat_messenger_body'>
            <div className='scroll-y mh-550px me-n5'>
              <div className='skeleton-chat-header me-4'>
                <div className='skeleton-chat-header d-flex justify-content-end mt-n1'>
                  <Skeleton circle className='circle-skeleton ' width={33} height={33} />
                  <Skeleton className='skeleton ms-2 mt-4' width='8rem' height={12} />
                </div>
                <div className='skeleton-chat d-flex flex-column align-items-end'>
                  <Skeleton className='skeleton mt-2' width='17rem' height={60} />
                  <Skeleton className='skeleton' width='1rem' height={10} />
                </div>

                <div className='skeleton-chat-header d-flex justify-content-end mt-10'>
                  <Skeleton circle className='circle-skeleton ' width={33} height={33} />
                  <Skeleton className='skeleton ms-2 mt-4' width='8rem' height={12} />
                </div>
                <div className='skeleton-chat d-flex flex-column align-items-end'>
                  <Skeleton className='skeleton mt-2' width='12rem' height={55} />
                  <Skeleton className='skeleton' width='1rem' height={10} />
                </div>

                <div className='skeleton-chat-header d-flex mt-10'>
                  <Skeleton circle className='circle-skeleton ' width={33} height={33} />
                  <Skeleton className='skeleton ms-2 mt-4' width='8rem' height={12} />
                </div>
                <div className='skeleton-chat d-flex flex-column '>
                  <Skeleton className='skeleton mt-2' width='10rem' height={50} />
                  <Skeleton className='skeleton' width='1rem' height={10} />
                </div>

                <div className='skeleton-chat-header d-flex justify-content-end mt-10'>
                  <Skeleton circle className='circle-skeleton ' width={33} height={33} />
                  <Skeleton className='skeleton ms-2 mt-4' width='8rem' height={12} />
                </div>
                <div className='skeleton-chat d-flex flex-column align-items-end'>
                  <Skeleton className='skeleton mt-2' width='27rem' height={45} />
                  <Skeleton className='skeleton' width='1rem' height={10} />
                </div>
              </div>
            </div>
            <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center me-2'>
                  <Skeleton className='skeleton mt-4' width='3rem' height={30} />
                  <Skeleton className='skeleton ms-2 mt-4' width='3rem' height={30} />
                </div>
                <Skeleton className='skeleton ms-2 mt-4' width='7rem' height={37} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  )
}

export default SkeletonChatRoom
