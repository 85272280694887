import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { EXTENSION, TELEPHONEURL } from '../../constants'
import { useTelephony } from '../context/TelephonyContext'
import ButtonLoadingSkeleton from '../ButtonsLoadingSkeleton'

interface IPROPS {
    conferenceNumber:any
    onClick?: () => void;
    style?: React.CSSProperties;
    setThirdPartyCallDisconnected:any
    setIsConferenceCallActive:any
    setCheckConferenceCallNumberEntered:any
}

const ThirdPartCallDisconnect: React.FC<IPROPS> = ({setCheckConferenceCallNumberEntered,conferenceNumber, onClick, style, setThirdPartyCallDisconnected, setIsConferenceCallActive}) => {

    const {disconnectCallLoader, setDisconnectCallLoader} = useTelephony()
    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);

    const telephoneData = JSON.parse(gettingTelephonyData);
    const gettingExtension = localStorage.getItem(EXTENSION);
    const callHangup = async() => {
        setDisconnectCallLoader(true)

        const url = telephoneData?.https_port > 0 ? 
        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${conferenceNumber}7&Type=xfrhangup&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`

        : 
        `https://${telephoneData?.domain}/click2call/Transfer.php?Number=${gettingExtension}&TransferNumber=91${conferenceNumber}7&Type=xfrhangup&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`


        const endThirdPartyCallResponse = 
        await axios.post(
            url            )

        try {
            if (endThirdPartyCallResponse?.data) {
                setCheckConferenceCallNumberEntered(false)
                setThirdPartyCallDisconnected(true)
                setIsConferenceCallActive(false)

                toast.success("Conference call ended successfully")

            }
            
            setDisconnectCallLoader(false)
        } catch (error) {
            setDisconnectCallLoader(false)
            toast.error("An error occured")
            
        }


    }

  return (
    <div>
        {disconnectCallLoader ? <ButtonLoadingSkeleton /> : 
        <div onClick={onClick} style={style}>
        <i
        onClick={callHangup} 
        className="bi bi-telephone-x" style={{fontSize: "15px", color: "white", padding: "8px", background: "red", borderRadius: "50%" }} 
        data-toggle="tooltip"
        data-placement="top"
        title="End third party call"
        ></i>
          <div
        style={{
            position: 'absolute',
            top: '50%',
            left: '0',
            width: '100%',
            height: '2px',
            backgroundColor: 'white', 
            transform: 'translateY(-50%) rotate(125deg)',
            background: "black",
            zIndex: 1,
        }}
    ></div>
        </div>
}
    </div>
  )
}

export default ThirdPartCallDisconnect