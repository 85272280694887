import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const campaignListApi = createApi({
  reducerPath: "campaignList",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    campainPagination: builder.query({
      query: ({
        currentPage,
        selectCampaign,
        value,
        storeKey,
        selectedFilterAndValue1,
        selectedTeamId,

      }) => {
        const baseUrl = `/v1/common/lead/?page=${currentPage}&page_size=5&ordering=-created&campaign=${selectCampaign}&`;
        const teamParam =
          selectedTeamId === "My Team"
            ? ""
            : `&assignee__username__icontains=${selectedTeamId}`;
        const statusParam = getStatusParam(storeKey, value);
        const filterParams = getFilterParams(selectedFilterAndValue1);

        return `${baseUrl}${teamParam}${statusParam}${filterParams}`;

      },
    }),

    campignWorkflow: builder.query({
      query: ({ selectedWorkFlow, selectCampaign, selectedFilterAndValue1 }) => {
        const baseUrl = `/v1/common/bin-count/workflow/?campaign=${selectCampaign}&workflow_name=${selectedWorkFlow}`;

        const filterParams = getFilterParams(selectedFilterAndValue1);

        return `${baseUrl}${filterParams}`;
      },
    }),
    campaign: builder.query({
      query: ({ teamId, campaignType }) => {
        const campaignTypee = campaignType ? "outward" : "inward";
    
        return {
          url: `v1/common/campaigns/?team=${teamId}`,
          method: "GET",
        };
      },
    }),
    
    campaignChangeStatus: builder.query({
      query: (selectedWorkFlow) => ({
        url: `v1/common/campaign-name/?campaign_workflow=${selectedWorkFlow}&`,
        method: "GET",
      }),
    }),
    CampignsDynamicFields: builder.query({
      query: () => {
        return {
          url: `v1/common/dynamic-field-definitions/?model_name=campaign&`,
          method: "GET",
        };
      },
    }),
    SingleLead: builder.mutation({
      query: (body) => ({
        url: `v1/common/lead/`,
        method: "POST",
        body,
      }),
    }),
    bulkLeadUpload: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/common/lead/bulk-upload/`,
          method: "POST",
          body,
        };
      },
    }),
    bulkLeadAssign: builder.mutation({
      query: ({ body, selectedUserId }) => ({
        url: `v1/users/users/${selectedUserId}/assign_multiple_lead/`,
        method: "POST",
        body,
      }),
    }),
    deleteBulkLead: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/common/lead/delete/`,
          method: "DELETE",
          body,
        };
      },
    }),
    leadEventCount: builder.query({
      query: () => {
        return {
          url: `v1/common/lead-event-bin-count/`,
          method: "GET",
        };
      },
    }),


    leadEvent: builder.query({
      query: ({ selectedFilters, tabSelectedParam, currentPage }) => {
        console.log("leadEvent", selectedFilters, tabSelectedParam);

        // Create the query string for selectedFilters
        const selectedFiltersQuery = Object.entries(selectedFilters)
          .filter(([key, value]) => value) // Keep only non-empty values
          .map(
            ([key, value]: any) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          ) // Encode keys and values
          .join("&"); // Join with '&'

        // Create the query string for tabSelectedParam if it has length > 0
        const tabSelectedQuery =
          tabSelectedParam && Object.keys(tabSelectedParam).length > 0
            ? Object.entries(tabSelectedParam)
              .map(
                ([key, value]: any) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
              )
              .join("&")
            : "";

        // Combine both query strings
        const queryString = [selectedFiltersQuery, tabSelectedQuery]
          .filter((query) => query) // Remove empty strings
          .join("&"); // Join with '&'

        return {
          url: `v1/common/lead-event/?page=${currentPage}&page_size=10&${queryString}`, // Append the query string
          method: "GET",
        };
      },
    }),

    taskCollection: builder.query({
      query: ({ collection }) => {
        return {
          url: `v1/common/lead/${collection}/`,
          method: "GET",
        };
      },
    }),

    updateTask: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/common/lead-event/${id}/`,
        method: "PUT",
        body,
      }),
    }),

    campaignOrder: builder.query({
      query: ({ }) => {
        return {
          url: `v1/campaign/campaign-order/`,
          method: "GET",
        };
      },
    }),

    campaignLeadEvent: builder.query({
      query: (id) => ({
        url: `v1/common/lead-event/?lead=${id}&`,
        method: "GET"
      })

    }),
    whatsAppConfig: builder.mutation({
      query: (body) => ({
        url: `v1/common/lead/broadcast-leads/`,
        method: "POST",
        body,
      }),
    }),

    createWhatsAppTemplates: builder.mutation({
      query: (body) => {
        return {
          url: `v1/common/whatsapp-templates/`,
          method: "POST",
          body
        };
      },
    }),

    getWhatsAppTemplates: builder.query({
      query: ({rowsPerPage, page}) => {
        return {
          url: `v1/common/whatsapp-templates/?&page_size=${rowsPerPage}&page=${page}`,
          method: "GET",
        };
      },
    }),

    updateWhatsAppTemplates: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/common/whatsapp-templates/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    deleteTemplate: builder.mutation({
      query: ({ fieldsData }) => {
        return {
          url: `v1/common/whatsapp-templates/${fieldsData}/`,
          method: "DELETE",
          // body,
        };
      },
    }),



    getBroadcastTemplates: builder.query({
      query: (id) => {
        return {
          url: `v1/common/whatsapp-templates/?team=${id}`,
          method: "GET",
        };
      },
    }),


    getTemplatePredefinedList: builder.query({
      query: ({ }) => {
        return {
          url: `v1/common/whatsapp-meta-templates`,
          method: "GET",
        };
      },
    }),





  }),
});

export const {
  // WhatsappTemplate Start
  useCreateWhatsAppTemplatesMutation,
  useGetWhatsAppTemplatesQuery,
  useUpdateWhatsAppTemplatesMutation,
  useDeleteTemplateMutation,
  useGetTemplatePredefinedListQuery,


  // End
  useGetBroadcastTemplatesQuery,
  useWhatsAppConfigMutation,
  useCampaignLeadEventQuery,
  useCampainPaginationQuery,
  useCampignWorkflowQuery,
  useCampaignQuery,
  useCampaignChangeStatusQuery,
  useCampignsDynamicFieldsQuery,
  useSingleLeadMutation,
  useBulkLeadUploadMutation,
  useBulkLeadAssignMutation,
  useDeleteBulkLeadMutation,
  useLeadEventCountQuery,
  useLeadEventQuery,
  useTaskCollectionQuery,
  useUpdateTaskMutation,
  useCampaignOrderQuery
} = campaignListApi;

// Helper functions
function getStatusParam(storeKey: string, statusValue: string) {
  switch (storeKey) {
    case "All":
      return "";
    case "active":
    case "reopen":
      return `&status_type__contains=${storeKey}`;
    case "wip":
      return `&status__status__contains=${statusValue}`;
    case "closed":
      return `&closed_status__status__contains=${statusValue}`;
    default:
      return `&status__status__contains=${statusValue}`;
  }
}

function getFilterParams(selectedFilterAndValue1: object) {
  const filterParams = Object.entries(selectedFilterAndValue1)
    .filter(([key, value]) => value !== "")
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return filterParams ? `&${filterParams}` : "";
}
