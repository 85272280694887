import React, { useEffect, useState } from "react";
import {
  useTagsQuery,
  useTeamsQuery,
  useUserDynamicFieldsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import {
  useAccessAccountAndTeamQuery,
  useAddAccountMutation,
} from "../../../Services/customer/FrontendCustomer";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import { Loader } from "react-bootstrap-typeahead";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";



import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  IconButton,
  Tooltip,
  Box,
  Chip,
} from "@mui/material";



interface IProps {
  closeModal: () => void;
  refetchingData: any;
}
interface ErrorState {
  [key: string]: string;
}

const AccountModal: React.FC<IProps> = ({ closeModal, refetchingData }) => {
  const [tagsData, setTagsData] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [accountDynamicData, setAccountDynamicData] = useState<any>([]);
  const [dynamicFields, setDynamicFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamss, setTeamss] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [files, setFiles] = useState([]);
  

  const { data: tags } = useTagsQuery({});
  const { data: teams } = useTeamsQuery({});
  const [trigger, { isLoading }] = useAddAccountMutation();
  const [teamAndAccountData, setTeamAndAccountData] = useState([]);
  const { data: teamAndAccount } = useAccessAccountAndTeamQuery({});

  useEffect(() => {
    if (teamAndAccount) {
      setTeamAndAccountData(teamAndAccount);
    }
  }, [teamAndAccount]);

  const modelName = "account";

  const { data: accountDynamicField } = useUserDynamicFieldsQuery(modelName);

  useEffect(() => {
    if (tags) {
      setTagsData(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (accountDynamicField) {
      setAccountDynamicData(accountDynamicField);
    }
  }, [accountDynamicField]);

  useEffect(() => {
    if (teams) {
      setTeamss(teams);
    }
  }, [teams]);
  const tagSelectHandler = (tag) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        return [];
      } else {
        return [tag];
      }
    });
  };

  const handleInputChange = (label: any, value: any) => {
    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const validateForm = () => {
    const newErrors: ErrorState = {};

    accountDynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Unselect all
      setSelectedTeams([]);
    } else {
      // Select all
      const allTeamIds = teams.map((team) => team.id);
      setSelectedTeams(allTeamIds);
    }
    setSelectAll(!selectAll);
  };

  const toggleTeamSelection = (teamId: string) => {
    const isSelected = selectedTeams.includes(teamId);

    if (isSelected) {
      setSelectedTeams(selectedTeams?.filter((id) => id !== teamId));
    } else {
      setSelectedTeams([...selectedTeams, teamId]);
      setSelectAll(false);
    }
  };

  const renderValue = (selected: string[]) => {
    const selectedTeamNames = teams
      .filter((team) => selected.includes(team.id))
      .map((team) => team.name);
    return selectedTeamNames.join(", ");
  };

  const addAccountHandler = async (e) => {
    e.preventDefault();

    // Check required fields and email validation
    const isFormValid = validateForm();
    let isEmailValid = true;
    let emailError = "";

    const emailFieldLabel = "email";
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailValue = dynamicFields[emailFieldLabel]?.trim();

    // Check if the email is valid
    if (emailValue && !emailPattern.test(emailValue)) {
      isEmailValid = false;
      emailError = "Invalid email address";
    }

    if (!isFormValid || !isEmailValid) {
      setIsButtonDisabled(true);

      if (!isFormValid) {
        toast.error("Please fill all required fields.");
      }
      if (!isEmailValid) {
        toast.error(emailError);
        setErrors((prevErrors) => ({
          ...prevErrors,
          [emailFieldLabel]: emailError,
        }));
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      return; // Exit the function to prevent API call
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = accountDynamicData.reduce((acc, field) => {
      const label = field.label;
      const value = dynamicFields[label] || "";

      // Check if the value is a number (or can be converted to a number)
      if (!isNaN(value) && value !== "") {
        acc[label] = parseFloat(value); // Convert the value to a number
      } else {
        acc[label] = value; // Keep it as a string if it's not a number
      }

      return acc;
    }, {});

    const addAccountObj = {
      accessible_teams: selectedTeams,
      dynamic_fields: dynamicFieldData,
      name: businessName,
      tags: selectedTags,
      attachments: files,

    };

    try {
      // Trigger the API call
      const response = await trigger(addAccountObj);

      if ("data" in response) {
        toast.success("Added Successfully");
        refetchingData();
        closeModal();
      } else if ("error" in response) {
        const error = response.error as {
          data?: {
            error?: string[];
            name?: string[];
            dynamic_fields?: { [key: string]: string[] };
          };
        };
        const dynamicFieldErrors = response.error as {
          data?: { dynamic_fields?: { [key: string]: string[] } };
        };

        const dynamicFieldsErrors = dynamicFieldErrors?.data?.dynamic_fields;

        if (dynamicFieldsErrors) {
          Object.keys(dynamicFieldsErrors).forEach((field) => {
            const fieldErrors = dynamicFieldsErrors[field];
            if (fieldErrors.length > 0) {
              if (field === "email") {
                toast.error(fieldErrors[0]);
              } else {
                fieldErrors.forEach((error) => {
                  toast.error(error);
                });
              }
            }
          });
        }

        const errorMessage = error?.data?.error?.[0];
        const nameErrorMessages = error.data?.name;

        if (nameErrorMessages && nameErrorMessages.length > 0) {
          toast.error(nameErrorMessages[0]);
        }
        toast.error(errorMessage);
      }
    } catch (err) {
      console.error("Error occurred while adding account:", err);
      toast.error("An unexpected error occurred");
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return "";
  };

  // Function to get current date-time formatted as 'YYYY-MM-DDTHH:mm'
  const getCurrentDateTimeFormatted = () => {
    const now = new Date();

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0");
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

      const handleDeleteFile = (fileToDelete) => () => {
          setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
        };
  
       const handleFileChange = async (event) => {
          const newFiles = Array.from(event.target.files);
          const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const fileData = {
                  file_name: file.name,
                  file: e.target.result.split(",")[1], // Extracting Base64 part
                };
                resolve(fileData);
              };
              reader.onerror = (error) => reject(error);
              reader.readAsDataURL(file);
            });
          });
      
          try {
            const fileDataArray = await Promise.all(filePromises);
            setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
          } catch (error) {
            console.error("Error reading files:", error);
            toast.error("An error occurred while uploading files. Please try again.");
          }
        };

  return (
    <div>
      <div
        style={{
          height: "60vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div className="row g-5 g-xl-8">
          <div className="col-xl-6" style={{ width: "100%" }}>
            <label className="required">Business name</label>
            <input
              type="text"
              className={`form-control ${
                errors["businessName"] ? "is-invalid" : ""
              }`}
              onChange={(e) => {
                setBusinessName(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  businessName: "",
                }));
              }}
            />
            {errors["businessName"] && (
              <div style={{ color: "red" }}>{errors["businessName"]}</div>
            )}
          </div>
          <div>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="teams-select-label">Select teams</InputLabel>
              <Select
                labelId="teams-select-label"
                id="teams-select"
                multiple
                value={selectedTeams}
                onChange={(event) =>
                  setSelectedTeams(event.target.value as string[])
                }
                input={<OutlinedInput label="Select Teams" />}
                renderValue={renderValue}
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  <ListItemText primary="All" />
                </MenuItem>
                {teamss?.map((team: any) => (
                  <MenuItem
                    key={team.id}
                    value={team.id}
                    onClick={() => toggleTeamSelection(team)}
                  >
                    <Checkbox checked={selectedTeams.includes(team.id)} />
                    <ListItemText primary={team.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          {accountDynamicData?.map((el: any) => (
            <React.Fragment key={el?.label}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label className={el?.is_required ? "required" : ""}>
                  {capitalizeFirstLetter(
                    el?.field_type === "check-box" ? "" : el?.label
                  )}
                </label>
                {el?.field_type === "drop-down" ? (
                  <select
                    className="form-control"
                    value={dynamicFields[el?.label] || ""}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {el?.choices?.map((choice: any) => (
                      <option key={choice?.id} value={choice?.choice}>
                        {choice?.choice}
                      </option>
                    ))}
                  </select>
                ) : el?.field_type === "check-box" ? (
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      {capitalizeFirstLetter(el.label)}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={dynamicFields[el?.label] || []}
                      onChange={(e) =>
                        handleInputChange(el.label, e.target.value)
                      }
                      input={
                        <OutlinedInput
                          label={capitalizeFirstLetter(el.label)}
                        />
                      }
                      renderValue={(selected) => {
                        return selected.join(", ");
                      }}
                    >
                      {el?.multiple_choices?.map((choice) => (
                        <MenuItem key={choice?.id} value={choice?.choice}>
                          <Checkbox
                            checked={dynamicFields[el?.label]?.includes(
                              choice?.choice
                            )}
                          />
                          <ListItemText primary={choice?.choice} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : el?.field_type === "date-time" ? (
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={getDataTimeFormatted(dynamicFields[el?.label]) || ""}
                    max={getCurrentDateTimeFormatted()}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                  />
                ) : el?.field_type === "text-area" ? (
                  <textarea
                    className="form-control"
                    value={dynamicFields[el?.label] || ""}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                    placeholder="Enter text here"
                    rows={2} // Adjust the number of rows as needed
                  />
                ) : el?.field_type === "tel-phone" ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <PhoneInput
                      style={{ width: "100%" }}
                      defaultCountry="IN"
                      value={dynamicFields[el?.label] || "+91"}
                      onChange={(phone: string) =>
                        handleInputChange(el.label, phone)
                      }
                    />
                  </div>
                ) : el?.field_type === "date" ? (
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={getDataTimeFormatted(dynamicFields[el?.label]) || ""}
                    max={getCurrentDateTimeFormatted()}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                    placeholder=""
                  />
                ) : (
                  <input
                    type={
                      el?.field_type === "email"
                        ? "email"
                        : el?.field_type === "number"
                        ? "number"
                        : el?.field_type === "date"
                        ? "date"
                        : el?.field_type === "time"
                        ? "time"
                        : "text"
                    }
                    placeholder={
                      el?.field_type === "email"
                        ? "Enter your email"
                        : el?.field_type === "number"
                        ? "Number"
                        : el?.field_type === "date-time"
                        ? "Select date and time"
                        : ""
                    }
                    className="form-control"
                    value={dynamicFields[el?.label] || ""}
                    onChange={(e) =>
                      handleInputChange(el.label, e.target.value)
                    }
                    onBlur={(e) => {
                      if (el?.field_type === "email") {
                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        const emailValue = e.target.value.trim();

                        if (!emailPattern.test(emailValue)) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            [el?.label]: "Invalid email address",
                          }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            [el?.label]: "",
                          }));
                        }
                      }
                    }}
                  />
                )}
              </div>
              {errors[el.label] && (
                <div style={{ color: "red" }}>{errors[el.label]}</div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="row g-5 g-xl-8 mb-4">
          <h4>Tags</h4>
          <div
            style={{
              display: "flex",
              gap: "10px",
              margin: "0",
              overflow: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {tagsData?.map((tag) => (
              <p
                key={tag.id}
                onClick={() => tagSelectHandler(tag.id)}
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  background: selectedTags.includes(tag.id)
                    ? "#009ef7"
                    : "#cccccc",
                  color: selectedTags.includes(tag.id) ? "white" : "white",
                }}
              >
                {capitalizeFirstLetter(tag?.name)}
              </p>
            ))}
          </div>
        </div>
      </div>

      <div className="col-xl-6">
          <label>Attachments</label>
          <div className="mt-2">
            <Button
              // variant="contained"
              component="label"
              className="text-primary"
              startIcon={<AttachFileIcon />}
            >
              Add Attachment
              <input type="file" hidden multiple onChange={handleFileChange} />
            </Button>
            <Tooltip
              title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
              arrow
            >
              <IconButton
                aria-label="info"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "11px",
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {files.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
            {files.map((file, index) => (
              <Chip
                key={index}
                label={file.file_name}
                onDelete={handleDeleteFile(file)}
              />
            ))}
          </Box>
        )}
      <div className="d-flex justify-content-end" style={{ marginTop: "20px" }}>
        <button
          type="reset"
          className="btn btn-sm btn-white btn-active-light-danger me-2"
          onClick={closeModal}
        >
          Cancel
        </button>

        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={addAccountHandler}
          disabled={isButtonDisabled}
        >
          {isLoading ? (
            <>
              Submitting <Loader />
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default AccountModal;
