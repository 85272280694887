import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { CloseButton } from "react-bootstrap";
import * as Yup from "yup";
import { useGetUsersQuery } from "../../../../Services/user/Users";
import { useEffect, useState } from "react";
import {
  useSmtpMailConfigMutation,
  useUpdateSMTPMailConfigMutation,
} from "../../../../Services/settingsAPIs/EmailConfiguration";
import clsx from "clsx";
import { useUsersByTeamIdQuery } from "../../../../Services/ticket/users/UsersByTeamId";
import { Loader } from "react-bootstrap-typeahead";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface IProps {
  closeModal: any;
  refetch: any;
  teamsList: any;
  mailConfigForEdit: any;
}

const microsoftValidationSchema = Yup.object().shape({
  client_id: Yup.string().required("Client Id is required"),
  client_secret: Yup.string().required("Client Secret is required"),
  server_tenant_id: Yup.string().required("Server Tenant Id is required"),
});

const gmailValidationSchema = Yup.object().shape({
  username: Yup.string().required("Please provide an email id"),
  password: Yup.string().required("Please provide a password"),
  host_name: Yup.string().required("Please provide a host name"),
  port: Yup.string().required("Please provide a port"),
});

const ModalSMTPEmailConfig: React.FC<IProps> = ({
  closeModal,
  refetch,
  teamsList,
  mailConfigForEdit,
}) => {
  const [triggerSMTPmailConfigurationMutation] = useSmtpMailConfigMutation();
  const [triggerUpdateOnSMTPmailConfigurationMutation] =
    useUpdateSMTPMailConfigMutation();

  const [theUser, setTheUser] = useState([]);
  const [mailServer, setMailServer] = useState<string>("gmail");
  const [selectedTeamId, setSelectedTeamId] = useState<any>();
  const [selectedUserId, setSelectedUserId] = useState<any>();
  const [passwordShown, setPasswordShown] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [somethingAdded, setSomehingAdded] = useState(false)
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
    selectedTeamId,
    {
      skip: !selectedTeamId,
    }
  );
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (usersData) {
      setTheUser(usersData);
    }
  }, [usersData]);

  useEffect(() => {
    if (usersStatus === "pending") {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [usersStatus]);

  useEffect(() => {
    if (mailConfigForEdit.mailConfigEditEnabled) {
      if (mailConfigForEdit.mailConfigData.email_server === "microsoft") {
        setMailServer("microsoft")
      }
      else {
        setMailServer('gmail')
      }


    }

  }, [mailConfigForEdit])

  const handleMailServerChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    resetForm: any
  ) => {
    const selectedServer = e.target.value;
    setMailServer(selectedServer);
    resetForm({
      values: {
        username: "",
        password: "",
        host_name: "",
        port: "",
        client_id: "",
        client_secret: "",
        server_tenant_id: "",
      },
    });
  };

  const handleSubmit = (values) => {
    values.team = [selectedTeamId];
    values.user = [selectedUserId];
    values.email_server = mailServer;
    mailConfigForEdit.mailConfigEditEnabled
      ? triggerUpdateOnSMTPmailConfigurationMutation({
        configId: mailConfigForEdit.mailConfigData.id,
        body: values,
      })
        .then((res: any) => {
          if (res.data) {
            toast.success(
              "The email configuration has been successfully updated."
            );
            closeModal();
            refetch();
          } else toast.error("Some issue");
        })
        .catch((err) => console.log(err))
      : triggerSMTPmailConfigurationMutation(values)
        .then((res: any) => {
          if (res.data) {
            toast.success("New SMTP mail has been configured successfully");
            closeModal();
            refetch();
          } else toast.error("Some issue");
        })
        .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (mailConfigForEdit.mailConfigEditEnabled) {
      setSelectedTeamId(mailConfigForEdit.mailConfigData.team[0]);
      setSelectedUserId(mailConfigForEdit.mailConfigData.user[0]);
    }
  }, []);
  return (
    <Formik
      initialValues={{
        username: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.username
          : "",
        password: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.password
          : "",
        host_name: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.host_name
          : "",
        port: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.port
          : "",
        team: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.team
          : [],
        user: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.user
          : [],
        email_server: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.email_server
          : "",
        client_id: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.client_id
          : "",
        client_secret: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.client_secret
          : "",
        server_tenant_id: mailConfigForEdit.mailConfigEditEnabled
          ? mailConfigForEdit.mailConfigData.server_tenant_id
          : "",
      }}
      validationSchema={
        mailServer === "microsoft"
          ? microsoftValidationSchema
          : gmailValidationSchema
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched, resetForm }) => (
        <Form className="form w-100">
          <div className="text-end">
            <CloseButton onClick={closeModal} />
          </div>

          <div className="text-center mb-4">
            <label className="form-label text-dark card-title">
              SMTP <br />
              Email configuration
            </label>
          </div>
          <div className="w-100">
            <div className="mt-2">
              <label htmlFor="email_server">Email server</label>
              <select
                className="form-control form-control-lg form-control-solid"
                name="email_server"
                id="email_server"
                onChange={(e) => handleMailServerChange(e, resetForm)}
                value={mailServer}
              >
                <option value="gmail">Gmail</option>
                <option value="microsoft">Microsoft</option>
              </select>
            </div>

            {mailServer === "microsoft" ? (
              <>

                <>
                  <div className="mt-2">
                    <label htmlFor="username">Email</label>
                    <Field
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid": touched.username && errors.username,
                        }
                      )}
                      type="text"
                      name="username"
                      id="username"
                    />
                    {touched.username && errors.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )}
                  </div>
                  <div className="mt-2">
                    <label htmlFor="password">Password</label>
                    <div className="d-flex align-items-center position-relative">
                      <Field
                        className={clsx(
                          "form-control form-control-lg form-control-solid pe-5",
                          {
                            "is-invalid": touched.password && errors.password,
                          }
                        )}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                      />
                      <button
                        type="button"
                        className="btn position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                      {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                      </button>
                    </div>
                    {touched.password && errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </div>

                </>
                <div className="mt-2">
                  <label htmlFor="client_id">Client id</label>
                  <Field
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid": touched.client_id && errors.client_id,
                      }
                    )}
                    type="text"
                    name="client_id"
                    id="client_id"
                  />
                  {touched.client_id && errors.client_id && (
                    <div className="invalid-feedback">{errors.client_id}</div>
                  )}
                </div>
                <div className="mt-2">
                  <label htmlFor="client_secret">Client secret</label>
                  <Field
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid":
                          touched.client_secret && errors.client_secret,
                      }
                    )}
                    type="text"
                    name="client_secret"
                    id="client_secret"
                  />
                  {touched.client_secret && errors.client_secret && (
                    <div className="invalid-feedback">
                      {errors.client_secret}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <label htmlFor="server_tenant_id">Server tenant Id</label>
                  <Field
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid":
                          touched.server_tenant_id && errors.server_tenant_id,
                      }
                    )}
                    type="text"
                    name="server_tenant_id"
                    id="server_tenant_id"
                  />
                  {touched.server_tenant_id && errors.server_tenant_id && (
                    <div className="invalid-feedback">
                      {errors.server_tenant_id}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="mt-2">
                  <label htmlFor="username">Email</label>
                  <Field
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid": touched.username && errors.username,
                      }
                    )}
                    type="text"
                    name="username"
                    id="username"
                  />
                  {touched.username && errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="mt-2">
                  <label htmlFor="password">Password</label>
                  <div className="d-flex align-items-center position-relative">
                    <Field
                      className={clsx(
                        "form-control form-control-lg form-control-solid pe-5",
                        {
                          "is-invalid": touched.password && errors.password,
                        }
                      )}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                    />
                    <button
                      type="button"
                      className="btn position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                    </button>
                  </div>
                  {touched.password && errors.password && (
                    <div className="text-danger">{errors.password}</div>
                  )}
                </div>
              </>
            )}

            {/* Common fields */}
            <div className="mt-2">
              <label htmlFor="host_name">SMTP server address</label>
              <Field
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid": touched.host_name && errors.host_name,
                  }
                )}
                type="text"
                name="host_name"
                id="host_name"
              />
              {touched.host_name && errors.host_name && (
                <div className="text-danger">{errors.host_name}</div>
              )}
            </div>
            <div className="mt-2">
              <label htmlFor="port">Port</label>
              <Field
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid": touched.port && errors.port,
                  }
                )}
                type="text"
                name="port"
                id="port"
              />
              {touched.port && errors.port && (
                <div className="text-danger">{errors.port}</div>
              )}
            </div>
            <div className="mt-2">
              <label htmlFor="teamselection">Team</label>
              <select
                className="form-control form-control-lg form-control-solid"
                name="teamselection"
                id="teamselection"
                onChange={(evt) => {
                  setSelectedTeamId(Number(evt.target.value));
                }}
                value={selectedTeamId}
              >
                <option selected disabled value="">
                  {" "}
                  Choose Team{" "}
                </option>
                {teamsList?.map((el: any) => (
                  <option value={el?.id} key={el?.id}>
                    {el?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <label htmlFor="userselection" className="mx-1">User</label>
              {isUserLoading ? (
                <Loader />
              ) : (
                <select
                  className="form-control form-control-lg form-control-solid"
                  name="userselection"
                  id="userselection"
                  onChange={(evt) => {
                    setSelectedUserId(Number(evt.target.value));
                  }}
                  value={selectedUserId}
                >
                  <option selected disabled value="">
                    Select user
                  </option>
                  {usersData?.map((el: any) => (
                    <option value={el?.id} key={el?.id}>
                      {el?.username}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="mt-2 text-end">
              <button
                style={{ transition: "background-color 0.6s ease" }}
                className={`${Object.keys(errors).length
                  ? "btn-secondary"
                  : Object.keys(touched).length
                    ? "btn-primary"
                    : "btn-secondary"
                  } btn btn-primary`}
                type="submit"
              // disabled={isSubmitting}
              >
                <i className="text-start fs-2 bi bi-plus-lg me-2"></i>
                <span>Save</span>
                <i
                  className={`${Object.keys(errors).length
                    ? "mx-2 bi fs-2"
                    : Object.keys(touched).length
                      ? "mx-2 bi fs-2 bi-check-circle-fill"
                      : "mx-2 bi fs-2"
                    } `}
                ></i>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};


export default ModalSMTPEmailConfig;
