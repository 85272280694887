import React from 'react'

const TextTemplate: React.FC<any> = ({ message, side, readByRecipientId, uploadFileUrl }) => {
  // const regex = new RegExp(getFileNameWithExtension(uploadFileUrl).split('-').slice(1).join("-"), 'gi');
  // let filteredMsg= message.replace (regex,'')
  function getFileNameWithExtension(url: string) {
    const splitArr = url.split('-')
    const fileExtension = splitArr[splitArr.length - 1].toLowerCase()
    return fileExtension
  }
  function getFileExtension(url: string) {
    const splitArr = url.split('.')
    const fileExtension = splitArr[splitArr.length - 1].toLowerCase()
    return fileExtension
  }

  function displayFile(url: string) {
    const extension = getFileExtension(url)

    // let content;

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'svg':
        return (
          <div className='d-flex flex-row gap-2'>
            {/* <a className='w-75 h-100 m-2' href={message} download={getFileNameWithExtension(url)}>
              <i className='bi bi-download text-black-700'></i>
            </a> */}
            <img src={url} alt='url' className='w-100 h-100'></img>
          </div>
        )
      case 'pdf':
      case 'csv':
      case 'txt':
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'zip':
        return (
          <>
            <div className='d-flex flex-row' style={{ width: 'fit-content' }}>
              <i className='bi bi-paperclip pt-1 me-2 text-black fw-bold fs-5'></i>
              <p>{getFileNameWithExtension(url)}</p>
            </div>
            {/* <a className='w-75 h-100 m-2' href={message} download={getFileNameWithExtension(url)}>
              <i className='bi bi-download text-black-700'></i>
            </a> */}
          </>
        )

      default:
        return <p>Unsupported file type.</p>
    }
  }

  function isValidFileType(url: string) {
    const validExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'svg',
      'csv',
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'txt',
      'zip'
    ]

    return validExtensions.includes(getFileExtension(url))
  }

  return (
    <>
      {/* {uploadFileUrl!="" ? (
          <div className='w-75 h-100'> {displayFile(uploadFileUrl)}</div>
      ) : <div/>}
      
        {message!="" ?<p
          dangerouslySetInnerHTML={{ __html: filteredMsg }}
          className={`p-5 mb-0 rounded bg-light-${
            side === 'rhs' ? 'primary' : 'info'
          } text-dark fw-bold mw-lg-400px text-${side === 'center' ? 'center' : 'start'}`}
        />: <div/>}
      
      {side === 'lhs' && <i className='bi bi-check-all text-primary'></i>}
      {side === 'dash-user' && !!readByRecipientId && readByRecipientId > 0 && (
        <i className='bi bi-check-all'></i>
      )} */}
      {isValidFileType(message) ? (
        <p
          className='px-4 pt-4 pb-0 mb-0 rounded text-dark fw-bold mw-lg-400px text-start'
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 8px 0px' }}
        >
          {' '}
          {displayFile(message)}
        </p>
      ) : (
        <p
          dangerouslySetInnerHTML={{ __html: message }}
          className={`p-5 mb-0 rounded bg-light-${
            side === 'rhs' ? 'primary' : 'info'
          } text-dark fw-bold mw-lg-400px text-${side === 'center' ? 'center' : 'start'}`}
        />
      )}

      {side === 'lhs' && <i className='bi bi-check-all text-primary'></i>}
      {side === 'dash-user' && !!readByRecipientId && readByRecipientId > 0 && (
        <i className='bi bi-check-all'></i>
      )}
    </>
  )
}

export default TextTemplate
