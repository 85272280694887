import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import configFile from "../helpers/example-data/config.json";
import { IConfig } from "../utils/interfaces/IConfig";

const defaultValue: IConfig = configFile;

const ConfigContext: React.Context<IConfig> = createContext(defaultValue);

interface Props {
  children?: ReactNode;
}

export const ConfigProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [config, setConfig] = useState<IConfig>(defaultValue);

  useEffect(() => {
    //   async function fetchConfig() {
    //     let appId: string
    //     if (ENVIRONMENT === 'production') {
    //       appId = (window as any).botgo._globals.appId // **--> Remove 'any' type after botgo is defined inside 'window'
    //     } else {
    //       appId = "80ad2402226fab613e84ed7920801337"
    //     }
    //     const result = await fetch(API_URL + '/frontend/loadconfig/' + appId)
    //     result.json().then((res) => {
    //       if (res.config) {
    //         setConfig(res.config)
    //       }
    //     })
    //   }
    //   fetchConfig()
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigContext;
