import React, { useEffect, useState } from 'react';
import { useCrmBgAccountCheckQuery, useChatEnableAndDisableMutation } from "../../../Services/chat/Chat"
import { useLogoutMutation } from "../../../Services/Auth/Auth"
import { toast } from 'react-toastify';
import axios from 'axios';

const ChatSettings = () => {
    const [isChatEnabled, setIsChatEnabled] = useState(false);
    const { data: crmBgAccountCheck, refetch } = useCrmBgAccountCheckQuery({});
    const [trigger, { isLoading }] = useChatEnableAndDisableMutation();    // Call hooks at the top level
    const [triggerLogout] = useLogoutMutation();
    const apiPort = process.env.REACT_APP_API_PROTOCOL;
    const userName = localStorage.getItem('circleone-username')
    const password = localStorage.getItem('password')
    const authDetails: any = localStorage.getItem('user-token')

    const tenant = authDetails ? JSON.parse(authDetails).tenant : null
    const teamNamePrev = authDetails ? JSON.parse(authDetails).teams[0]?.name : null

    const triggerLoginMutationFn = async (payload: any) => {
        return axios.post(
            `${apiPort}://${process.env.REACT_APP_BASE_URL}/v1/auth/`,
            payload
        );
    };



    const handleToggle = async () => {
        console.log(!isChatEnabled);
        try {
            const response = await trigger(!isChatEnabled);
            if (response?.data) {
                SaveChanges(isChatEnabled)

            } else {
                toast.error("An error occurred");
            }
        } catch (error) {
            toast.error("An error occurred");
        }
    };



    useEffect(() => {
        if (crmBgAccountCheck) {
            setIsChatEnabled(crmBgAccountCheck?.bg_id_enabled)
        }
    }, [crmBgAccountCheck])

    const switchStyles: any = {
        position: 'relative',
        display: 'inline-block',
        width: '50px',
        height: '24px',
    };

    const inputStyles: any = {
        opacity: 0,
        width: 0,
        height: 0,
    };

    const sliderStyles: any = {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: isChatEnabled ? '#009ef7' : '#ccc',
        transition: '.4s',
        borderRadius: '34px',
    };

    const sliderBeforeStyles: any = {
        position: 'absolute',
        content: '""',
        height: '18px',
        width: '18px',
        left: isChatEnabled ? '26px' : '4px',
        bottom: '3px',
        backgroundColor: 'white',
        transition: '.4s',
        borderRadius: '50%',
    };
    const SaveChanges = async (isChatEnabled: any) => {
        const payload = {
            "username": userName,
            "password": password,
            "tenant": tenant,
            "team": Number(localStorage.getItem("teamId"))
        }
        try {
            const response = await triggerLogout({});
            if (response.data?.success) {
                const loginResponse = await triggerLoginMutationFn(payload);

                if (loginResponse && loginResponse.data) {
                    const { token } = loginResponse.data;
                    const CIRCLEONE_TEAM = loginResponse.data.teams[0]?.name
                    const teamId = loginResponse.data.teams[0].id

                    if (token) {
                        // Store the token directly
                        localStorage.setItem("user-token", JSON.stringify(loginResponse.data));
                        localStorage.setItem('auth-token', token);
                        localStorage.setItem('circleone-team', CIRCLEONE_TEAM)
                        localStorage.setItem('teamId', teamId)
                        sessionStorage.clear()
                        refetch();
                        setIsChatEnabled(!isChatEnabled);
                        toast.success(`Chat ${!isChatEnabled ? 'enabled' : 'disabled'} successfully`);
                        window.location.reload()

                        // toast.success("Changes saved successfully");
                    } else {
                        // toast.error("Login failed: Token not found");

                    }
                } else {
                    // toast.error("Login failed");

                }
            }
        } catch (error) {
            // toast.error("Team is not changed");

        }
    }

    return (
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div className="col">
                <span className="input-group-text mt-2" id="basic-addon1">
                    Chat settings
                </span>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <div className="card-body">
                    <div className="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                        <div>
                            <div className=" card-xl-stretch">
                                <div className="card-header align-items-center border-0">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="fw-bold mb-2 text-gray-900">
                                            Chat Features Overview
                                        </span>
                                        <span className="text-muted fw-semibold fs-7">
                                            Enable or disable chat features for your application.
                                        </span>
                                    </h3>
                                </div>

                                <div className="card-body pt-5">
                                    <div className="timeline-label">
                                        <div className="timeline-item">
                                            <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                01
                                            </div>
                                            <div className="timeline-badge z-index-0">
                                                <i className="fa fa-genderless text-success fs-1"></i>
                                            </div>
                                            <div className="timeline-content d-flex">
                                                <span className="fw-bold text-gray-800 ps-3">
                                                    Simple Chat Bot
                                                </span>
                                            </div>
                                        </div>

                                        <div className="timeline-item">
                                            <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                02
                                            </div>
                                            <div className="timeline-badge">
                                                <i className="fa fa-genderless text-danger fs-1"></i>
                                            </div>
                                            <div className="timeline-content d-flex">
                                                <span className='fw-bold text-gray-800 ps-3'>
                                                    Human Assistance Handoff
                                                </span>
                                            </div>
                                        </div>

                                        <div className="timeline-item z-index-0">
                                            <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                03
                                            </div>
                                            <div className="timeline-badge">
                                                <i className="fa fa-genderless text-primary fs-1"></i>
                                            </div>
                                            <div className="timeline-content d-flex">
                                                <span className='fw-bold text-gray-800 ps-3'>
                                                    Agent To Agent Chat
                                                </span>
                                            </div>
                                        </div>

                                        <div className="timeline-item">
                                            <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                04
                                            </div>
                                            <div className="timeline-badge z-index-0">
                                                <i className="fa fa-genderless text-danger fs-1"></i>
                                            </div>
                                            <div className="timeline-content d-flex">
                                                <span className='fw-bold text-gray-800 ps-3'>
                                                    Hybrid Bot With Human Assistance
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex pt-10 justify-content-end">
                            <label style={switchStyles}>
                                <input
                                    type="checkbox"
                                    checked={isChatEnabled}
                                    onChange={handleToggle}
                                    style={inputStyles}
                                />
                                <span style={sliderStyles}>
                                    <span style={sliderBeforeStyles}></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatSettings;