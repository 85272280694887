import React, { useEffect, useState } from "react";
import { CardContent, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllAssigneeBySelectedTeamIdQuery,
  useGetAllTeamsQuery,
  useGetAllWorkflowSelectedTeamIdQuery,
  useGetTicketLifeCycleReportQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";

const TicketLifeCycleReport = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Ticket Id",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created",
      headerName: "Last Updated",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status_type",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Agent",
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created_by.username",
      headerName: "Creator",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "priority",
      headerName: "Ticket Name",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalTransactionTime",
      headerName: "Total Transaction Time (HH:MM:SS)",
      flex: 1,
      minWidth: 300,
      align: "center",
      headerAlign: "center",
    },]);
  const { data, isLoading } = useGetAllTeamsQuery({});
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [localLoading, setLocalLoading] = useState(false);
  const { data: assigneeData, isLoading: assigneeLoading } =
    useGetAllAssigneeBySelectedTeamIdQuery(selectedTeamId, {
      skip: !selectedTeamId,
    });
  const { data: workflowData, isLoading: workflowLoading } =
    useGetAllWorkflowSelectedTeamIdQuery(selectedTeamId, {
      skip: !selectedTeamId,
    });

  const [optionsTeams, setOptionsTeams] = useState<any[]>([]);
  const [optionsAssignee, setOptionsAssignee] = useState<any[]>([]);
  const [optionsWorkflow, setOptionsWorkflow] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [params, setParams] = useState<any>(null);

  const {
    data: lifeCycleReport,
    isLoading: lifeCycleReportLoading,
  } = useGetTicketLifeCycleReportQuery(params, {
    skip: !params,
  });

  useEffect(() => {
    if (data && data.teams?.length > 0) {
      const transformedOptions = data.teams.map((team: any) => ({
        label: team.name,
        value: team.id,
      }));
      setOptionsTeams(transformedOptions);
    }
  }, [data]);

  useEffect(() => {
    if (assigneeData) {
      const transformedAssignees = assigneeData.map((assignee: any) => ({
        label: `${assignee.username}`,
        value: assignee.id,
      }));
      setOptionsAssignee(transformedAssignees);
    }
  }, [assigneeData]);

  useEffect(() => {
    if (workflowData && Array.isArray(workflowData.results)) {
      const transformedWorkflows = workflowData.results.map(
        (workflow: any) => ({
          label: workflow.name,
          value: workflow.id,
        })
      );
      setOptionsWorkflow(transformedWorkflows);
    }
  }, [workflowData]);

  const handleTeamChange = (event: any) => {
    setSelectedTeamId(event.target.value);
  };

  const handleAssigneeChange = (event: any) => {
    setSelectedAssignee(event.target.value);
  };

  const handleWorkflowChange = (event: any) => {
    setSelectedWorkflow(event.target.value);
  };

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (!selectedTeamId) {
      toast.error('Please select "Team"');
      return;
    }

    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };

    if (selectedWorkflow) {
      queryParams.action__disposition__get_ticket_name__name = selectedWorkflow;
    }
    if (selectedTeamId !== "All" && selectedTeamId) {
      queryParams.team = selectedTeamId;
    }
    if (selectedAssignee !== "All" && selectedAssignee) {
      queryParams.action__assignee = selectedAssignee;
    }

    setParams(queryParams);
  };

  const resetFilters = () => {
    setSelectedTeamId(null);
    setSelectedAssignee(null);
    setSelectedWorkflow(null);
    setDateFrom("");
    setDateTo("");
    setParams(null);
  };

  const formatRows = (data1: any[], columns: GridColDef[]) => {
    return data1?.map((item, index) => {
      const row: any = {
        id: index + 1
      };

      columns.forEach(column => {
        if (column.field === 'createdDate') {
          row[column.field] = item.created || "-";
        } else if (column.field === 'title') {
          row[column.field] = item.ticket_id || "-";
        } else if (column.field === 'status_type') {
          row[column.field] = item.status || "-";
        } else if (column.field === 'created') {
          row[column.field] = item.last_update || "-";
        } else if (column.field === 'description') {
          row[column.field] = item.agent?.username || "-";
        } else if (column.field === 'created_by.username') {
          row[column.field] = item.created_by?.username || "-";
        } else if (column.field === 'totalTransactionTime') {
          row[column.field] = item.total_transaction_time || "-";
        } else if (column.field === 'priority') {
          row[column.field] = item.workflow_name || "-";
        } else {
          // Handle transaction_time fields
          const transaction = item.transaction_time?.find((t: any) => t.status === column.field);
          row[column.field] = transaction?.transaction_time || "-";
        }
      });

      return row;
    });
  };

  useEffect(() => {
    setColumns([
      {
        field: "createdDate",
        headerName: "Created Date",
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "title",
        headerName: "Ticket Id",
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "created",
        headerName: "Last Updated",
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "status_type",
        headerName: "Status",
        flex: 1,
        minWidth: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "description",
        headerName: "Agent",
        flex: 1,
        minWidth: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "created_by.username",
        headerName: "Creator",
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "priority",
        headerName: "Ticket Name",
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "totalTransactionTime",
        headerName: "Total Transaction Time (HH:MM:SS)",
        flex: 1,
        minWidth: 300,
        align: "center",
        headerAlign: "center",
      },
    ]);

    if (lifeCycleReport && Array.isArray(lifeCycleReport) && lifeCycleReport.length > 0) {
      const statusColumns2 = lifeCycleReport[0]?.transaction_time
        ?.map(item => item.status)
        .filter((status, index, self) => status && self.indexOf(status) === index)
        .map(status => ({
          field: status === null ? "null" : status,
          headerName: `Transaction Time (HH:MM:SS) ${status}`,
          flex: 1,
          minWidth: 300,
          align: "center",
          headerAlign: "center",
        }));

      console.log("statusColumns2:", statusColumns2); // Debugging log

      if (Array.isArray(statusColumns2)) {
        setColumns(prevColumns => [...prevColumns, ...statusColumns2]);
      } else {
        console.warn("statusColumns2 is not an array:", statusColumns2);
      }
    }
  }, [lifeCycleReport]);



  const rows: any = formatRows(lifeCycleReport, columns || []);
  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/ticket/reports-list");
            }}
          /> */}
          <button
            className="btn btn-link mb-2"
            onClick={() => {
              navigate("/reports/ticket/reports-list");
            }}
            style={{ marginRight: "-17px" }}
          >
            <i className="bi bi-arrow-90deg-left" style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}></i>
          </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            Ticket Lifecycle
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          {/* Date Filters */}
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="team-select-label">Select Teams</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                value={selectedTeamId}
                onChange={handleTeamChange}
                label="Select Teams"
                MenuProps={MenuProps}
              >
                {optionsTeams.map((team) => (
                  <MenuItem key={team.value} value={team.value}>
                    {team.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="workflow-select-label">Workflow</InputLabel>
              <Select
                labelId="workflow-select-label"
                id="workflow-select"
                value={selectedWorkflow}
                onChange={handleWorkflowChange}
                label="Workflow"
                MenuProps={MenuProps}
              >
                {optionsWorkflow.map((workflow) => (
                  <MenuItem key={workflow.value} value={workflow.label}>
                    {workflow.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select
                labelId="Assignee-select-label"
                id="Assignee-select"
                value={selectedAssignee}
                onChange={handleAssigneeChange}
                label="Assignee"
                MenuProps={MenuProps}
              >
                {optionsAssignee.map(
                  (assignee) =>
                    assignee.label.length > 1 && (
                      <MenuItem key={assignee.value} value={assignee.value}>
                        {assignee.label}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>

            <button className="btn btn-primary">
              <CsvDownloader
                filename="Ticket Lifecycle"
                datas={rows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}
              >
                Download
              </CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        {/* DataGrid */}
        <CardContent style={{ width: "100%", minHeight: "400px" }}>
          {isLoading ||
            assigneeLoading ||
            workflowLoading ||
            lifeCycleReportLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                  whiteSpace: "nowrap", // Prevent wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  whiteSpace: "nowrap", // Prevent wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                }
              }}
            />

          )}
        </CardContent>
      </div>
    </div>
  );
};

export default TicketLifeCycleReport;
