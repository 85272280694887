import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import TicketModal from "../../components/ticket/TicketModal";
import "../../components/app/Fixes.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  useTelephonyTicketPaginationQuery,
  useTicketPaginationQuery,
  useTicketWorkflowQuery,
  useUserTicketQuery,
} from "../../Services/ticket/TicketList";
import LoadingSpinner from "../../components/LoadingSpinner";
import BulkAssigneeModal from "../../components/ticket/BulkAssigneeModal";
import { toast } from "react-toastify";
import TicketFilterModal from "../../components/ticket/TicketFilterModal";
import TicketStandardView from "./TicketStandardView";
import TicketListView from "./TicketListView";
import TicketCardView from "./TicketCardView";
import { useMeeQuery } from "../../Services/settingsAPIs/CommonAPI";
import { CALL, INCOMING_CALL } from "../../constants";
import { useNavigate } from "react-router-dom";
const TicketLists = () => {
  // Retrieve the stored option from local storage or set default
  const storedOption =
    sessionStorage.getItem("selectedOption") || "ticketListView";
  const [selectedOption, setSelectedOption] = useState(storedOption);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBulkAssigneeModalOpen, setIsBulkAssigneeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<any>(() => {
    const savedPage = sessionStorage.getItem("ticketCurrentPage");
    return savedPage ? Number(savedPage) : 1;
  });
  const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
  const [liftingKey, setLiftingKey] = useState([]);
  const [isFilterModalOpen, setFilterIsModalOpen] = useState(false);
  const { data: useData } = useMeeQuery({});

  // Retrieve team_id from local storage
  const teamIdFromLocalStorage = localStorage.getItem("teamId");
  const { data: Ticket } = useUserTicketQuery(teamIdFromLocalStorage);

  const [selectedWorkFlow, setselectedWorkFlow] = useState(
    sessionStorage.getItem("selectedWorkFlow") || ""
  );
  const [data, setData] = useState<any>();
  const [selectedTeamId, setSelectedTeamId] = useState(() => {
    const selectedTeamIdFromSession = sessionStorage.getItem("selectedTeamId");
    const userRole = localStorage.getItem("user_role");
    const userId = localStorage.getItem("User_Id");

    if (selectedTeamIdFromSession) {
      return selectedTeamIdFromSession;
    }

    if (userRole === "admin") {
      return "My Team";
    }

    if (userRole === "agent" || userRole === "manager") {
      return userId;
    }

    return null; // Fallback value if none of the conditions are met
  });

  const [isLoadingPageChange, setIsLoadingPageChange] =
    useState<boolean>(false);
  const [liftUiData, setLiftUiData] = useState(0);
  const [value, setValue] = useState(liftUiData ? "active" : "All");
  const [storeKey, setStoreKey] = useState(liftUiData ? "active" : "All");
  const [storingTicketData, setStoringTicketData] = useState("");

  const [filterType, setFilterType] = useState("blank");

  const [selectedFilterAndValue, setSelectedFilterAndValue] = useState<any>({
    action__contact__email__icontains: "",
    action__assignee__username__icontains: "",
    title__icontains: "",
    created: "",
    id__icontains: "",
    merchant_id: "",
  });
  const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>(
    () => {
      const sessionData = sessionStorage.getItem("selectedFilterAndValue");
      return sessionData
        ? JSON.parse(sessionData)
        : {
            action__contact__email__icontains: "",
            action__assignee__username__icontains: "",
            title__icontains: "",
            created: "",
            id__icontains: "",
            merchant_id: "",
          };
    }
  );

  const { data: ticketWorkflow, refetch: refetchWorkflow } =
    useTicketWorkflowQuery(
      { selectedWorkFlow, selectedFilterAndValue1 },
      { skip: !selectedWorkFlow }
    );

  const callActive = localStorage.getItem(INCOMING_CALL) === "true";
  const mobileNumber = localStorage.getItem("liftingMobileNumber");

  const { data: fetchedData, refetch } = useTicketPaginationQuery(
    {
      currentPage,
      value,
      selectedWorkFlow,
      selectedTeamId,
      selectedFilterAndValue1,
      liftingKey,
      storeKey,
    },
    { skip: !selectedWorkFlow || callActive }
  );

  const { data: fetchedTelephonyData, refetch: telephonyRefetch } =
    useTelephonyTicketPaginationQuery(
      {
        currentPage,
        value,
        selectedWorkFlow,
        selectedTeamId,
        selectedFilterAndValue1,
        liftingKey,
        storeKey,
        mobileNumber,
      },
      { skip: !callActive || !selectedWorkFlow }
    );

  useEffect(() => {
    sessionStorage.setItem("storeKey", storeKey);
  }, [storeKey]);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "850px", // Max width for larger screens
      height: "80%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const customModalStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "450px",
    },
  };

  const customFilterModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "50px", // Max width for larger screens
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openFilterModal = () => {
    setFilterIsModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterIsModalOpen(false);
  };

  const openBulkModal = () => {
    if (selectedTickets.length === 0) {
      return toast.error("Please select ticket.");
    } else {
      setIsBulkAssigneeModalOpen(true);
    }
  };

  const closeBulkModal = () => {
    setSelectedTickets([]);
    setIsBulkAssigneeModalOpen(false);
  };

  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    setSelectedTickets([]);
    sessionStorage.setItem("selectedOption", selected); // Store the selected option in local storage
  };

  const handleWorkflowChange = (event) => {
    setCurrentPage(1);
    setselectedWorkFlow(event.target.value);
    sessionStorage.setItem("selectedWorkFlow", event.target.value); // Store the selected workflow in local storage

  };

  useEffect(() => {
    const storedWorkFlow = sessionStorage.getItem("selectedWorkFlow");
    if (storedWorkFlow) {
      setselectedWorkFlow(storedWorkFlow);

    }
    console.log('Selected workflow state updated:', selectedWorkFlow);
  }, [selectedWorkFlow]);

  // useEffect(() => {
  //   console.log('Selected workflow state updated:', selectedWorkFlow);
  // }, [selectedWorkFlow]);

  const handleTeamChange = (e) => {
    const selectedTeamId = e.target.value;
    setSelectedTeamId(selectedTeamId);
    sessionStorage.setItem("selectedTeamId", selectedTeamId); // Store the selected team ID in local storage
    setIsLoadingPageChange(true);
  };

  useEffect(() => {
    const storedTeamId = localStorage.getItem("selectedTeamId");
    if (storedTeamId) {
      setSelectedTeamId(storedTeamId);
    }
  }, []);

  useEffect(() => {
    setIsLoadingPageChange(true);
    if (callActive) {
      if (fetchedTelephonyData?.results) {
        setData(fetchedTelephonyData?.results);
      }
    } else if (!callActive) {
      if (fetchedData?.results) {
        setData(fetchedData?.results);
      }
    }
    setIsLoadingPageChange(false);
  }, [fetchedData, callActive, fetchedTelephonyData]); // Ensure dependencies are correct

  useEffect(() => {
    if (callActive) {
      if (fetchedTelephonyData) {
        setIsLoadingPageChange(true);
        setData(fetchedTelephonyData);
        setIsLoadingPageChange(false);
      }
    } else if (!callActive) {
      if (fetchedData) {
        setIsLoadingPageChange(true);
        setData(fetchedData);
        setIsLoadingPageChange(false);
      }
    }
  }, [callActive, fetchedData, fetchedTelephonyData]);

  useEffect(() => {
    const setDispositionData = data?.results?.reduce(
      (acc: any, disposition: any) => {
        const dispositionId = disposition?.action?.disposition?.id;
        if (dispositionId) {
          acc[disposition?.id] = dispositionId;
        }
        return acc;
      },
      {}
    );
    setStoringTicketData(setDispositionData || "");
  }, [data]);

  const handleSubmit = (filterValues) => {
    setIsLoadingPageChange(true);
    setSelectedFilterAndValue1(filterValues);
    setIsLoadingPageChange(false);
  };

  // useEffect(() => {
  //   if (Ticket && Ticket?.results?.length > 0) {
  //     setselectedWorkFlow(Ticket?.results[0]?.id);
  //     sessionStorage.setItem("selectedWorkFlow", Ticket?.results[0]?.id);
  //   }
  // }, [Ticket]);
  useEffect(() => {
    if (Ticket && Ticket?.results?.length > 0) {
      const firstWorkflowId = Ticket?.results[0]?.id;
      if (!selectedWorkFlow) {
        setselectedWorkFlow(firstWorkflowId);
        sessionStorage.setItem("selectedWorkFlow", firstWorkflowId);
        console.log('Initial workflow set from Ticket results:', firstWorkflowId);
      } else {
        console.log('Workflow retained from sessionStorage:', selectedWorkFlow);
      }
    }
  }, [Ticket]);

  useEffect(() => {
    const savedPage = sessionStorage.getItem("ticketCurrentPage");
    if (savedPage !== currentPage.toString()) {
      sessionStorage.setItem("ticketCurrentPage", currentPage.toString());
    }
  }, [currentPage]);
  const navigate = useNavigate(); // Initialize useNavigate
  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const taskHandler = () => {
    navigate('/ticket/tasks')
  }

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header 1 */}
        <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
          <div className="d-flex">
            <button
              className="btn btn-link mb-2"
              onClick={handleBackButtonClick}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
            <h2 className="p-4">Ticket</h2>
          </div>
      {selectedOption === "ticketStandardView" && (
          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-150px">
              <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                <InputLabel id="view-select-label">View</InputLabel>
                <Select
                  labelId="view-select-label"
                  id="view-select"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                  label="View"
                >
                  <MenuItem value="ticketListView">List View</MenuItem>
                  <MenuItem value="ticketStandardView">Standard View</MenuItem>
                  <MenuItem value="ticketCardView">Card View</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="w-200 mw-200px" style={{ marginRight: "1px" }}>
              <FormControl style={{ width: "150px", zIndex: 0 }}>
                <InputLabel id="workflow-select-label">
                  Select Workflow
                </InputLabel>
                <Select
                  labelId="workflow-select-label"
                  id="workflow-select"
                  value={selectedWorkFlow}
                  onChange={handleWorkflowChange}
                  label="Select Workflow"
                >
                  {/* Assuming 'Ticket' has a 'results' array with workflow options */}
                  {Ticket &&
                    Ticket.results.map((workflow) => (
                      <MenuItem key={workflow.id} value={workflow.id}>
                        {workflow.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
)}

{selectedOption === "ticketListView" && (
          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-150px">
              <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                <InputLabel id="view-select-label">View</InputLabel>
                <Select
                  labelId="view-select-label"
                  id="view-select"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                  label="View"
                >
                  <MenuItem value="ticketListView">List View</MenuItem>
                  <MenuItem value="ticketStandardView">Standard View</MenuItem>
                  <MenuItem value="ticketCardView">Card View</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="w-200 mw-200px" style={{ marginRight: "20px" }}>
              <FormControl style={{ width: "150px", zIndex: 0 }}>
                <InputLabel id="workflow-select-label">
                  Select Workflow
                </InputLabel>
                <Select
                  labelId="workflow-select-label"
                  id="workflow-select"
                  value={selectedWorkFlow}
                  onChange={handleWorkflowChange}
                  label="Select Workflow"
                >
                  {/* Assuming 'Ticket' has a 'results' array with workflow options */}
                  {Ticket &&
                    Ticket.results.map((workflow) => (
                      <MenuItem key={workflow.id} value={workflow.id}>
                        {workflow.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
)}

{selectedOption === "ticketCardView" && (
          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-150px">
              <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                <InputLabel id="view-select-label">View</InputLabel>
                <Select
                  labelId="view-select-label"
                  id="view-select"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                  label="View"
                >
                  <MenuItem value="ticketListView">List View</MenuItem>
                  <MenuItem value="ticketStandardView">Standard View</MenuItem>
                  <MenuItem value="ticketCardView">Card View</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="w-200 mw-200px" style={{ marginRight: "20px" }}>
              <FormControl style={{ width: "150px", zIndex: 0 }}>
                <InputLabel id="workflow-select-label">
                  Select Workflow
                </InputLabel>
                <Select
                  labelId="workflow-select-label"
                  id="workflow-select"
                  value={selectedWorkFlow}
                  onChange={handleWorkflowChange}
                  label="Select Workflow"
                >
                  {/* Assuming 'Ticket' has a 'results' array with workflow options */}
                  {Ticket &&
                    Ticket.results.map((workflow) => (
                      <MenuItem key={workflow.id} value={workflow.id}>
                        {workflow.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
)}
        </div>
        {/* Card Header 2 */}
        <div className="card-header d-flex align-items-center justify-content-between py-1 px-20 mx-10 gap-2 gap-md-5">
          {/* Filter and Search Inputs on the Left Side */}
{selectedOption === "ticketCardView" && (
          <div className="d-flex align-items-center gap-2 me-auto" style={{marginLeft:"-8px"}}>
            <div className="w-100 mw-200px" style={{ marginRight: "20px" }}>
              <FormControl style={{ width: "150px", zIndex: 0 }}>
                <InputLabel
                  id="team-select-label"
                  style={{ fontSize: "12px", marginLeft: "-5px" }}
                >
                  {" "}
                  Select assignee
                </InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={selectedTeamId}
                  onChange={handleTeamChange}
                  label="Select Team"
                >
                  <MenuItem key="all" value="My Team">
                    My Team
                  </MenuItem>
                  <MenuItem key="all" value={localStorage.getItem("User_Id")}>
                    Self
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="w-100 mw-100px">
              <Button
                variant="outlined"
                className="text-hover-primary fw-bold"
                onClick={openFilterModal}
                sx={{
                  height: 50,
                  width: 140,
                  border: "1px solid #ccc",
                  gap: "5px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <i className="bi bi-filter text-dark fs-2" />
                <span
                  className="ms-auto me-2 text-dark"
                  style={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                  }}
                >
                  Search filter
                </span>
              </Button>
            </div>
          </div>
)}
  {selectedOption === "ticketListView" && (
          <div className="d-flex align-items-center gap-2 me-auto" style={{marginLeft:"10px"}}>
            <div className="w-100 mw-200px" style={{ marginRight: "20px" }}>
              <FormControl style={{ width: "150px", zIndex: 0 }}>
                <InputLabel
                  id="team-select-label"
                  style={{ fontSize: "12px", marginLeft: "-5px" }}
                >
                  {" "}
                  Select assignee
                </InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={selectedTeamId}
                  onChange={handleTeamChange}
                  label="Select Team"
                >
                  <MenuItem key="all" value="My Team">
                    My Team
                  </MenuItem>
                  <MenuItem key="all" value={localStorage.getItem("User_Id")}>
                    Self
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="w-100 mw-100px">
              <Button
                variant="outlined"
                className="text-hover-primary fw-bold"
                onClick={openFilterModal}
                sx={{
                  height: 50,
                  width: 140,
                  border: "1px solid #ccc",
                  gap: "5px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <i className="bi bi-filter text-dark fs-2" />
                <span
                  className="ms-auto me-2 text-dark"
                  style={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                  }}
                >
                  Search filter
                </span>
              </Button>
            </div>
          </div>
)}
 {selectedOption === "ticketStandardView" && (
          <div className="d-flex align-items-center gap-2 me-auto" style={{marginLeft:"-64px"}}>
            <div className="w-100 mw-200px" style={{ marginRight: "20px" }}>
              <FormControl style={{ width: "150px", zIndex: 0 }}>
                <InputLabel
                  id="team-select-label"
                  style={{ fontSize: "12px", marginLeft: "-5px" }}
                >
                  {" "}
                  Select assignee
                </InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={selectedTeamId}
                  onChange={handleTeamChange}
                  label="Select Team"
                >
                  <MenuItem key="all" value="My Team">
                    My Team
                  </MenuItem>
                  <MenuItem key="all" value={localStorage.getItem("User_Id")}>
                    Self
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="w-100 mw-100px">
              <Button
                variant="outlined"
                className="text-hover-primary fw-bold"
                onClick={openFilterModal}
                sx={{
                  height: 50,
                  width: 140,
                  border: "1px solid #ccc",
                  gap: "5px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <i className="bi bi-filter text-dark fs-2" />
                <span
                  className="ms-auto me-2 text-dark"
                  style={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                  }}
                >
                  Search filter
                </span>
              </Button>
            </div>
          </div>
)}
  
          <ReactModal
            isOpen={isFilterModalOpen}
            onRequestClose={closeFilterModal}
            style={customFilterModalStyles}
            contentLabel="Ticket Filter Modal"
          >
            <TicketFilterModal
              closeFilterModal={closeFilterModal}
              selectedFilterAndValue={selectedFilterAndValue}
              setSelectedFilterAndValue={setSelectedFilterAndValue}
              handleSubmit={handleSubmit}
              setIsLoadingPageChange={setIsLoadingPageChange}
            />
          </ReactModal>

          {/* Ticket Button on the Right Side */}
          
          <a
            className="btn btn-sm btn-icon btn-light btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            data-bs-placement="top"
            aria-label="Assignee"
            data-bs-original-title="Assignee"
            data-kt-initialized={1}
            title="Assignee"
            onClick={openBulkModal}
          >
            <i className="bi bi-person-fill fs-2" />
          </a>
          {/* <div>
            <button 
            className="form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold"
            onClick={taskHandler}
          >Task
          </button>
          </div> */}
          {selectedOption === "ticketCardView" && (
          <div className="w-100 mw-100px" style={{marginRight:"-49px"}}>
            <button
              className="form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold"
              onClick={openModal}
            >
              <i className="bi bi-plus-lg mx-1"></i>
              <span className="ms-auto me-2">Ticket</span>
            </button>
          </div>
)}
 {selectedOption === "ticketListView" && (
          <div className="w-100 mw-100px" style={{marginRight:"-52px"}}>
            <button
              className="form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold"
              onClick={openModal}
            >
              <i className="bi bi-plus-lg mx-1"></i>
              <span className="ms-auto me-2">Ticket</span>
            </button>
          </div>
)}
 {selectedOption === "ticketStandardView" && (
          <div className="w-100 mw-100px" style={{marginRight:"-67px"}}>
            <button
              className="form-control d-flex justify-content-between align-items-center text-hover-primary fw-bold"
              onClick={openModal}
            >
              <i className="bi bi-plus-lg mx-1"></i>
              <span className="ms-auto me-2">Ticket</span>
            </button>
          </div>
)}
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            style={customModalStyles}
            contentLabel="New Ticket"
          >
            <TicketModal
              closeModal={closeModal}
              setIsLoading={setIsLoading}
              refetch={callActive ? telephonyRefetch : refetch}
              refetchWorkflow={refetchWorkflow}
              selectedWorkflow={selectedWorkFlow}
            />
          </ReactModal>

          <ReactModal
            isOpen={isBulkAssigneeModalOpen}
            onRequestClose={closeBulkModal}
            style={customModalStyles1}
            contentLabel="Bulk Assignee"
          >
            <BulkAssigneeModal
              closeBulkModal={closeBulkModal}
              selectedTickets={selectedTickets}
              refetch={callActive ? telephonyRefetch : refetch}
            />
          </ReactModal>
        </div>

        {/* Card Body */}
        <div className="card-body d-flex flex-column">
          {isLoadingPageChange ? (
            <div className="d-flex justify-content-center align-items-center">
              <LoadingSpinner />
            </div>
          ) : data?.length === 0 ? (
            <div className="text-center">No data available.</div>
          ) : (
            <>
              {selectedOption === "ticketListView" && (
                <TicketListView
                  ticket={data}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  ticketWorkflow={ticketWorkflow}
                  value={value}
                  setValue={setValue}
                  setStoreKey={setStoreKey}
                  setLiftUiData={setLiftUiData}
                  refetch={callActive ? telephonyRefetch : refetch}
                  storingTicketData={storingTicketData}
                  useData={useData}
                />
              )}
              {selectedOption === "ticketStandardView" && (
                <TicketStandardView
                  ticket={data}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  useData={useData}
                  ticketWorkflow={ticketWorkflow}
                  setStoreKey={setStoreKey}
                  value={value}
                  setValue={setValue}
                />
              )}

              {selectedOption === "ticketCardView" && (
                <TicketCardView
                  ticket={data}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  ticketWorkflow={ticketWorkflow}
                  value={value}
                  setValue={setValue}
                  setStoreKey={setStoreKey}
                  setLiftUiData={setLiftUiData}
                  refetch={callActive ? telephonyRefetch : refetch}
                  storingTicketData={storingTicketData}
                  useData={useData}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketLists;
