import { Link, useLocation } from "react-router-dom";
import "./HeaderContent.css"; // Make sure to create this CSS file
import { useEffect, useState } from "react";
import {
  CIRCLEONE_TEAM,
  CIRCLEONE_USER,
  CIRCLEONE_USERNAME,
} from "../../constants";

const HeaderContents = () => {
  const [permissionList, setPermissionList] = useState<any>([]);
  const [modulePermission, setModulePermission] = useState<boolean>(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const permissionToShow = localStorage.getItem("user-token");

  const checkTenant = localStorage.getItem(CIRCLEONE_USER);
  const checkTeam = localStorage.getItem(CIRCLEONE_TEAM);
  const checkUsername = localStorage.getItem(CIRCLEONE_USERNAME);

  console.log(checkTenant, "checktenantntn");

  useEffect(() => {
    if (permissionToShow) {
      const parsedPermission = JSON.parse(permissionToShow); // Parse the JSON string
      const teamPermissions = parsedPermission?.teams[0];
      // Find the key that you need dynamically
      const dynamicKey =
        Object.keys(teamPermissions).find((key) =>
          key.includes("module_permissions")
        ) || Object.keys(teamPermissions)[0];
      const dynamicKey1 =
        Object.keys(teamPermissions).find(
          (key) => key === "module_permissions"
        ) || Object.keys(teamPermissions)[0];
      if (dynamicKey1 === "module_permissions") {
        setModulePermission(true);
      } else {
        setModulePermission(false);
      }
      const permissionListValue = teamPermissions[dynamicKey] || [];
      setPermissionList(permissionListValue);
    }
  }, [permissionToShow]);

  const hasPermission = (permission) =>
    Array.isArray(permissionList) && permissionList.includes(permission);

  const restricteduser =
    checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io";

  return (
    // Top heading

    <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0">
      {hasPermission("dashboard:enable") && (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion ${
            currentPath.includes("/dashboard") ? "active" : ""
          }`}
        >
          <div className="dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-house-door text-dark fs-2"></i> Dashboard
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              {/* {checkTenant === "csquare" ? <p style={{padding: "4px 14px", margin: "0", color: "grey", cursor: "default"}}>Email</p> : */}
{/*  */}
              {!restricteduser && (
                <li>
                  <Link
                    to="/dashboard/email-dashboard"
                    className={`dropdown-item ${
                      currentPath === "/dashboard/email-dashboard"
                        ? "active"
                        : ""
                    }`}
                  >
                    Email
                  </Link>
                </li>
              )}
              {!restricteduser && (
                <li>
                  <Link
                    to="/dashboard/ticket-dashboard"
                    className={`dropdown-item ${
                      currentPath === "/dashboard/ticket-dashboard"
                        ? "active"
                        : ""
                    }`}
                  >
                    Ticket
                  </Link>
                </li>
              )}
            {checkTenant === "victorinox"  ? <p style={{padding: "4px 15px", margin: "0", color: "grey", cursor: "default"}}>Leads</p> : 
              <li>
                <Link
                  to="/dashboard/campaign-dashboard"
                  className={`dropdown-item disabled ${currentPath === "/dashboard/campaign-dashboard"
                    ? "active"
                    : ""
                    }`}
                >
                  Leads
                </Link>
              </li>
}
              {checkTenant === "victorinox" ? <p style={{padding: "4px 14px", margin: "0", color: "grey", cursor: "default"}}>Telephony</p> :
              <li>
                <Link
                  to="/dashboard/rtm-dashboard"
                  className={`dropdown-item disabled ${currentPath === "/dashboard/rtm-dashboard" ? "active" : ""
                    }`}
                >
                  Telephony
                </Link>
              </li>
      }
            </ul>
          </div>
        </div>
      )}

      {restricteduser ? (
        ""
      ) : (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion ${
            currentPath.includes("/activity") ? "active" : ""
          }`}
        >
          <div className="dropdown">
            <button
              className="btn dropdown-toggle "
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-activity fs-2 text-dark"></i> Activity
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link
                  to="/activity/active"
                  className={`dropdown-item ${
                    currentPath === "/activity/active" ? "active" : ""
                  }`}
                >
                  Today
                </Link>
              </li>
              <li>
                <Link
                  to="/activity/recent"
                  className={`dropdown-item ${
                    currentPath === "/activity/recent" ? "active" : ""
                  }`}
                >
                  Recent
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}

      {hasPermission("users:enable") && (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion ${
            currentPath === "/users/user" ? "active" : ""
          }`}
        >
          <Link to="/users/user" className="btn d-flex align-items-center">
            <i className="bi bi-people text-dark fs-2"></i> Users
          </Link>
        </div>
      )}

      {hasPermission("customers:enable") && (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion ${
            currentPath === "/contact/customer/account" ? "active" : ""
          }`}
        >
          <Link
            to="/contact/customer/account"
            className="btn d-flex align-items-center"
          >
            <i className="bi bi-person text-dark fs-2"></i> Contact
          </Link>
        </div>
      )}
      {restricteduser ? (
        ""
      ) : (
        <>
          {hasPermission("email:enable") && (
            <div
              className={`menu-item here menu-here-bg menu-lg-down-accordion ${
                currentPath === "/email/inbox" ? "active" : ""
              }`}
            >
              {/* { checkTenant === "csquare" ? <div className="btn d-flex align-items-center" style={{cursor: "default", color: "grey"}}><span><i className="bi bi-menu-up fs-2 me-2 "></i></span><span> Email</span></div> :  */}

              <Link to="/email/inbox" className="btn d-flex align-items-center">
                <i className="bi bi-envelope-arrow-down text-dark fs-2 me-2"></i>
                Email
              </Link>
            </div>
          )}

          {hasPermission("ticket:enable") && (
            <div
              className={`menu-item here menu-here-bg menu-lg-down-accordion ${
                currentPath === "/ticket/lists" ? "active" : ""
              }`}
            >
              <Link
                to="/ticket/lists"
                className="btn d-flex align-items-center"
              >
                <i className="bi bi-ticket-detailed text-dark fs-2 me-2"></i>{" "}
                Ticket
              </Link>
            </div>
          )}
        </>
      )}
      {hasPermission("campaigns:enable") && (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion ${
            currentPath === "/campaign/list" ? "active" : ""
          }`}
        >
          {checkTenant === "victorinox" ? (
            <div
              className="btn d-flex align-items-center"
              style={{ cursor: "default", color: "grey" }}
            >
              <span>
                <i className="bi bi-menu-up fs-2 me-2 "></i>
              </span>
              <span> Leads</span>
            </div>
          ) : (
            <Link to="/campaign/list" className="btn d-flex align-items-center">
              <i className="bi bi-menu-up fs-2 me-2 text-dark"></i> Leads
            </Link>
          )}
        </div>
      )}

      {!restricteduser && (
        <>
            {hasPermission("chat:enable") && (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion disabled ${currentPath === "/chat/chatrooms" ? "active" : ""
            }`}
        >
          <Link to="/chat/chatrooms" className="btn d-flex align-items-center disabled">
            <i className="bi bi-chat  fs-2 me-2 text-dark"></i> Chat
          </Link>
      
        </div>
      )}
        </>
      )}

      {restricteduser ? (
        <>
          {hasPermission("reports:enable") && (
            <div
              className={`menu-item here menu-here-bg menu-lg-down-accordion ${
                currentPath.includes("/reports") ? "active" : ""
              }`}
            >
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-file-earmark-bar-graph text-dark fs-2"></i>
                  Reports
                </button>
                <ul className="dropdown-menu dropdown-menu-end p-2">
                  {/* {checkTenant === 'meta' ? <span style={{ display: "block", color: "grey", padding: "3px 12px", cursor: "default" }}>Email</span> :

                <li>
                  <Link
                    to="/reports/email/reports-list"
                    className={`dropdown-item ${currentPath === "/reports/email/reports-list"
                      ? "active"
                      : ""
                      } }`}
                  >
                    Email
                  </Link>
                </li>
              } */}
                  {/* <li>
                <Link
                  to="/reports/ticket/reports-list"
                  className={`dropdown-item ${currentPath === "/reports/ticket/reports-list"
                    ? "active"
                    : ""
                    }`}
                >
                  Ticket
                </Link>
              </li> */}

                  {/* {checkTenant === 'victorinox' ? <span style={{ display: "block", color: "grey", padding: "3px 12px", cursor: "default" }}>Telephony</span> :
                <li>
                  <Link
                    to="/reports/telephony/reports-list"
                    className={`dropdown-item ${currentPath === "/reports/telephony/reports-list"
                      ? "active"
                      : ""
                      }  `}
                  >
                    Telephony
                  </Link>
                </li>
              } */}

                  <li>
                    <Link
                      to="/reports/campaign/reports-list"
                      className={`dropdown-item ${
                        currentPath === "/reports/campaign/reports-list"
                          ? "active"
                          : ""
                      }  `}
                    >
                      Leads
                    </Link>
                  </li>

                  {/* {checkTenant === "victorinox" ? <span style={{display: "block", color: "grey", padding: "3px 14px", cursor: "default"}}>Chat</span> : 

                <li>
                  <Link
                    to="/reports/chat/reports-list"
                    className={`dropdown-item ${currentPath === "/reports/chat/reports-list" ? "active" : ""
                      } `}
                  >
                    Chat
                  </Link>
                </li>
              } */}
                </ul>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {hasPermission("reports:enable") && (
            <div
              className={`menu-item here menu-here-bg menu-lg-down-accordion ${
                currentPath.includes("/reports") ? "active" : ""
              }`}
            >
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-file-earmark-bar-graph text-dark fs-2"></i>
                  Reports
                </button>
                <ul className="dropdown-menu dropdown-menu-end p-2">

                <li className="disabled">
                <Link
                  to="/reports/email/reports-list"
                  className={`dropdown-item disabled ${currentPath === "/reports/email/reports-list" ? "active" : ""}`}
                >
                  Email
                </Link>
              </li>

                  <li>
                    <Link
                      to="/reports/ticket/reports-list"
                      className={`dropdown-item ${
                        currentPath === "/reports/ticket/reports-list"
                          ? "active"
                          : ""
                      }`}
                    >
                      Ticket
                    </Link>
                  </li>

                  {checkTenant === "victorinox" ? (
                    <span
                      style={{
                        display: "block",
                        color: "grey",
                        padding: "3px 12px",
                        cursor: "default",
                      }}
                    >
                      Telephony
                    </span>
                  ) : (
                    <li>
                      <Link
                        to="/reports/telephony/reports-list"
                        className={`dropdown-item ${
                          currentPath === "/reports/telephony/reports-list"
                            ? "active"
                            : ""
                        }  `}
                      >
                        Telephony
                      </Link>
                    </li>
                  )}

                  {checkTenant === "victorinox" ? (
                    <span
                      style={{
                        display: "block",
                        color: "grey",
                        padding: "3px 14px",
                        cursor: "default",
                      }}
                    >
                      Leads
                    </span>
                  ) : (
                    <li>
                      <Link
                        to="/reports/campaign/reports-list"
                        className={`dropdown-item ${
                          currentPath === "/reports/campaign/reports-list"
                            ? "active"
                            : ""
                        }  `}
                      >
                        Leads
                      </Link>
                    </li>
                  )}

                  {checkTenant === "victorinox" ? (
                    <span
                      style={{
                        display: "block",
                        color: "grey",
                        padding: "3px 14px",
                        cursor: "default",
                      }}
                    >
                      Chat
                    </span>
                  ) : (
                    <li>
                      <Link
                        to="/reports/chat/reports-list"
                        className={`dropdown-item ${
                          currentPath === "/reports/chat/reports-list"
                            ? "active"
                            : ""
                        } `}
                      >
                        Chat
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      {hasPermission("settings:enable") && (
        <div
          className={`menu-item here menu-here-bg menu-lg-down-accordion ${
            currentPath === "/settings/users" ? "active" : ""
          }`}
        >
          <Link to="settings/users" className="btn d-flex align-items-center">
            <i className="bi bi-sliders text-dark fs-2 me-2"></i> Settings
          </Link>
        </div>
      )}
    </div>
  );
};

export default HeaderContents;