import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import "./styles.scss";
import {
  useTicketAccessControlQuery,
  useTicketNewControlMutation,
  useTicketUpdateControlMutation,
} from "../../../../Services/ticketAccessControl/TicketAccessControl";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../LoadingSpinner";
import { Loader } from "react-bootstrap-typeahead";

const AccessControl = () => {
  const [teamId, setTeamId] = useState<string>("");
  const [teams, setTeams] = useState<any>([]);
  const [teamsDetails, setTeamsDetails] = useState<any>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [assignment, setAssignment] = useState<any>("");
  const [storedTeamId, setStoredTeamId] = useState<any>();
  const [selectedRadio, setSelectedRadio] = useState<string>("");
  const [storedTeamNameId, setStoredTeamNameId] = useState<any>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [valueSelected, setValueSelected] = useState<string>("");

  const [loader, setLoader] = useState(false);

  const { data: teamsData } = useTeamsQuery({});
  const { data: accessControl } = useTicketAccessControlQuery(teamId, {
    skip: !teamId,
  });

  const [triggerNewTicket] = useTicketNewControlMutation();
  const [trigger, { isLoading: ticketUpdateLoading }] =
    useTicketUpdateControlMutation();

  useEffect(() => {
    if (teamsData && teamsData.length > 0) {
      setTeams(teamsData);
    }
  }, [teamsData]);

  useEffect(() => {
    if (accessControl?.length > 0) {
      setTeamsDetails(accessControl);
    } else {
      setTeamsDetails([]);
    }
  }, [accessControl]);

  useEffect(() => {
    if (teamsDetails) {
      setStoredTeamId(teamsDetails[0]?.id);
      setStoredTeamNameId(teamsDetails[0]?.team);
    } else if (!teamsDetails) {
      setSelectedTeams([]);
    }
  }, [teamsDetails]);

  const handleChange = (event: SelectChangeEvent) => {
    setLoader(true);
    setTeamId(event.target.value as string);

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const handleAssignment = (event: SelectChangeEvent) => {
    setAssignment(event.target.value as string);
  };

  useEffect(() => {
    if (teamsDetails?.length > 0) {
      if (teamsDetails[0]?.round_robin === true) {
        setSelectedRadio("round_robin");
      } else if (teamsDetails[0]?.fifo === true) {
        setSelectedRadio("fifo");
      } else if (teamsDetails[0]?.default_routing === true) {
        setSelectedRadio("default_routing");
      }
    } else {
      setSelectedRadio("");
    }
  }, [teamsDetails]);

  const radioChangeHandler = (e: any) => {
    setValueSelected(e.target.value);
    setSelectedRadio(e.target.value);
  };

  useEffect(() => {
    if (teamsDetails) {
      const initialSelectedTeams =
        teamsDetails && storedTeamNameId?.map((team) => team?.id.toString());
      setSelectedTeams(initialSelectedTeams);
    } else {
      setSelectedTeams([]);
    }
  }, [storedTeamNameId, teamsDetails]);

  //  checkbox selection or de...
  const handleTeamSelect = (teamIds: string) => {
    setValueSelected(teamIds);
    setSelectedTeams((prevSelected = []) =>
      prevSelected.includes(teamIds)
        ? prevSelected.filter((id: any) => id !== teamIds)
        : [...prevSelected, teamIds]
    );
  };

  const saveHandler = async (e: any) => {
    e.preventDefault();
    const ticketAssignmentPayload = {
      default_routing: selectedRadio === "default_routing" ? true : false,
      fifo: selectedRadio === "fifo" ? true : false,
      round_robin: selectedRadio === "round_robin" ? true : false,
      team: selectedTeams,
      team_from: teamId,
      ticket_auto_team: false,
    };

    try {
      if (storedTeamId) {
        const response = await trigger({
          teamId: storedTeamId,
          body: ticketAssignmentPayload,
        });
        if ("data" in response) {
          toast.success("Edited Successfully");
          setValueSelected("");
        } else if ("error" in response) {
          setValueSelected("");
          const error = response.error as {
            data?: { team?: string[] };
          }; // Type assertion with possible structure

          const errorMessage = error?.data?.team?.[0]
            ? error.data.team[0]
            : "An error occurred";
          toast.error(errorMessage);
        }
      } else {
        const response = await triggerNewTicket(ticketAssignmentPayload);

        if (response?.data) {
          toast.success("Edited Successfully");
          setValueSelected("");
        } else if ("error" in response) {
          setValueSelected("");
          const error = response.error as {
            data?: { team?: string[] };
          }; // Type assertion with possible structure

          const errorMessage = error?.data?.team?.[0]
            ? error.data.team[0]
            : "An error occurred";

          toast.error(errorMessage);
        }
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  useEffect(() => {
    if (assignment === "auto") {
      setTeamId("");
    }
  }, [assignment]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "20px",marginLeft:"3%" }}
      className="ticket-access-control-cont"
    >
      <div style={{ display: "flex", gap: "20px" }}>
        <div className="w-250 mw-250px" style={{ marginRight: "20px" }}>
          <FormControl style={{ width: "250px", zIndex: 0 }}>
            <InputLabel id="team-select-label">Assignment type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={assignment}
              label="assignment"
              onChange={handleAssignment}
            >
              {/* <MenuItem value={"auto"}>Auto Assign</MenuItem> */}
              <MenuItem value={"manual"}>Manual Assign</MenuItem>
            </Select>
          </FormControl>
        </div>

        {assignment === "manual" && (
          <div className="w-250 mw-250px" style={{ marginRight: "20px" }}>
            <FormControl style={{ width: "250px", zIndex: 0 }}>
              <InputLabel id="team-select-label">Configure for</InputLabel>
              <Select
                labelId="imple-select-label"
                id="simple-select"
                value={teamId}
                label="Age"
                onChange={handleChange}
                MenuProps={MenuProps}
              >
                {teams?.map((teams, index) => (
                  <MenuItem value={teams?.id}>{teams?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </div>

      {/* Radio Button */}

      {teamId && (
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
            Team routing rules
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedRadio} // Set value to the selected radio button
              name="radio-buttons-group"
              onChange={radioChangeHandler}
            >
              <FormControlLabel
                value="round_robin"
                control={<Radio />}
                label="Round Robin"
              />
              <FormControlLabel
                value="default_routing"
                control={<Radio />}
                label="Team Assignment"
              />
              <FormControlLabel
                value="fifo"
                control={<Radio />}
                label="Active Logins (RR)"
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}

      {teamId && (
        <>
          {loader ? (
            <LoadingSpinner />
          ) : (
            <>
              <div>
                <h4>Assign to</h4>

                <Grid container spacing={2}>
                  {teams?.map((team, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <Card style={{ boxShadow: "none" }}>
                        <CardContent
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0",
                          }}
                        >
                          <Checkbox
                            checked={
                              selectedTeams &&
                              selectedTeams?.includes(team?.id.toString())
                            }
                            onChange={() =>
                              handleTeamSelect(team?.id.toString())
                            }
                            inputProps={{
                              "aria-label": team.name,
                            }}
                          />
                          <Typography variant="h6" component="div">
                            {team?.name}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>

              <div style={{ width: "100%", marginLeft: "-80px" }} className="d-flex justify-content-end mb-10 ">
                <button
                  className="btn btn-primary"
                  onClick={saveHandler}
                  disabled={!valueSelected || isButtonDisabled}
                >
                  {ticketUpdateLoading ? (
                    <>
                      Saving...
                      <Loader />{" "}
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AccessControl;
