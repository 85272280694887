import { IChatFilter } from "../interfaces/IChatFilter";
import { useStateWithCallback } from "./useStateWithCallback";

export const useChatFilters = () => {
  const [chatFilters, setChatFilters] = useStateWithCallback<IChatFilter>({});

  const updateChatFilters = (
    filterType: any,
    value:
      | IChatFilter["applicationIds"]
      | IChatFilter["name"]
      | IChatFilter["startDate"],
    // | IChatFilter['query'],
    callback?: (updatedState: IChatFilter) => void
  ) => {
    setChatFilters(
      (prev) => ({
        ...prev,
        [filterType]: value,
      }),
      callback
    );
  };

  return {
    models: { chatFilters },
    operations: { updateChatFilters },
  };
};
