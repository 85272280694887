import { useEffect, useState } from "react";
import {
  useTagsQuery,
  useTeamsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import {
  useGetEmailRulesQuery,
  useSetEmailRulesMutation,
} from "../../../../Services/settingsAPIs/EmailConfiguration";
import { toast } from "react-toastify";

const SLA = () => {
  const [theTeams, setTheTeams] = useState([]);
  const { data: teamsData } = useTeamsQuery({});
  const { data: emailRulesData, refetch } = useGetEmailRulesQuery({});
  const [selectedTeam, setSelectedTeam] = useState<number>();
  const [payload, setPayload] = useState<any>({
    email_first_response_sla_delay_in_ms: 0,
    email_first_response_sla_emails: null,
  });
  const [slaTimeHours, setSlaTimeHours] = useState<number>(0);
  const [slaTimeMinutes, setSlaTimeMinutes] = useState<number>(0);
  const [slaMailList, setSlaMailList] = useState<string>("");
  const [triggerSetEmailRules] = useSetEmailRulesMutation();

  const handleSelectTeamChange = (event: any) => {
    setSelectedTeam(Number(event.target.value));
  };
  const handleSLAhoursChange = (evt: any) => {
    setSlaTimeHours(Number(evt.target.value));
  };
  const handleSLAminutesChange = (evt: any) => {
    setSlaTimeMinutes(Number(evt.target.value));
  };
  const handleSLAmailingListChange = (evt: any) => {
    setSlaMailList(evt.target.value);
  };
  const handleSave = () => {
    triggerSetEmailRules(payload).then((res: any) => {
      if (res.data) {
        toast.success("Updated the first response SLA configurations");
      } else toast.error("something went wrong!");
      refetch();
    });
  };

  useEffect(() => {
    if (teamsData) setTheTeams(teamsData);
  }, [teamsData]);
  useEffect(() => {
    if (emailRulesData) {
      setSlaTimeHours(
        emailRulesData?.email_first_response_sla_delay_in_ms % 60000
      );
      setSlaTimeMinutes(
        emailRulesData?.email_first_response_sla_delay_in_ms / 60000
      );
      setSlaMailList(emailRulesData?.email_first_response_sla_emails.join(";"));
    }
  }, [emailRulesData]);
  useEffect(() => {
    setPayload({
      email_first_response_sla_delay_in_ms:
        slaTimeHours * 3600000 + slaTimeMinutes * 60000,
      email_first_response_sla_emails: slaMailList.split(";"),
    });
  }, [slaTimeHours, slaTimeMinutes, slaMailList]);

  return (
    <>
      <div className="card-toolbar flex-row-fluid justify-content-end gap-5 p-5" style={{marginLeft:"2%"}}>
        <div className="row">
          <div className="col">
            <span className="input-group-text mt-2" id="basic-addon1">
              First Response SLA
            </span>
          </div>
        </div>
        <div className="row align-items-center mt-2">
          <label>Select team</label>
          <div className="col-md-2">
            <select
              style={{ width: "fitContent" }}
              className="form-select select2-hidden-accessible"
              onChange={handleSelectTeamChange}
            >
              <option value={0}> Select team </option>
              {theTeams?.map((el: any) => (
                <option value={el?.id} key={el?.id}>
                  {el?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div
              className="card my-1 min-h-40px justify-content-center"
              style={{ backgroundColor: "rgba(228, 228, 228, 0.498)" }}
            >
              <div className="row align-items-center">
                <div className="col-3 text-center">
                  <strong>Hours</strong>
                </div>
                <div className="col-3 text-center">
                  <strong>Minutes</strong>
                </div>
                <div className="col-6">
                  <strong>Mail ID</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {selectedTeam && (
              <div className="input-group-text mt-2 bg-white">
                <div className="row w-100 align-items-center">
                  <div className="col-3 text-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        type="number"
                        className="form-control border-0 w-25"
                        value={slaTimeHours}
                        onChange={handleSLAhoursChange}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-3 text-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        type="number"
                        className="form-control border-0 w-25"
                        value={slaTimeMinutes}
                        onChange={handleSLAminutesChange}
                        min={0}
                      />
                    </div>
                  </div>

                  <div className="col-6 text-center">
                    <input
                      type="text"
                      className="form-control border-0"
                      onChange={handleSLAmailingListChange}
                      value={slaMailList}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary mt-2 ms-auto float-end"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SLA;
