import React, { useState, useRef, useEffect } from "react";
import { saveAs } from "file-saver"; // Ensure file-saver is installed
import * as XLSX from "xlsx"; // Ensure xlsx is installed
import TrailActionMenu from "./TrailActionMenu";

interface IProps {
  auditTrailData: any[];
  auditTrailForwardedData:any
}

const Trail: React.FC<IProps> = ({ auditTrailData, auditTrailForwardedData }) => {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);


  console.log(auditTrailForwardedData, "auditTrailForwardedData")
  // Toggle menu
  const toggleMenu = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  const downloadCSV = () => {
    if (auditTrailData.length === 0) return;

    const headers = [
      "Date",
      "Time",
      "Updates",
      "Status",
      "Assignee",
      "Assigned By",
    ];

    const csvContent = [
      headers.join(","),
      ...auditTrailData.map((row) =>
        [
          formatDate(row.created_date),
          formatTime(row.created),
          row.ticket_activity || "N/A",
          row.new_status_type || "N/A",
          row.new_assignee || "N/A",
          row.assigned_by || "N/A",
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "audit_trail_data.csv");
  };

  const downloadExcel = () => {
    if (auditTrailData.length === 0) return;

    const ws = XLSX.utils.json_to_sheet(
      auditTrailData.map((row) => ({
        Date: formatDate(row.created_date),
        Time: formatTime(row.created),
        Updates: row.ticket_activity || "N/A",
        Status: row.new_status_type || "N/A",
        Assignee: row.new_assignee || "N/A",
        AssignedBy: row.assigned_by || "N/A",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Audit Trail");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "audit_trail_data.xlsx");
  };

  const handleEdit = (id: any) => {
    console.log(`Edit clicked for ID: ${id}`);
    // Implement edit functionality here
  };

  const handleDelete = (id: any) => {
    console.log(`Delete clicked for ID: ${id}`);
    // Implement delete functionality here
  };

  // Handle clicks outside the dropdown menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenMenuIndex(null); // Close the menu
      }
    };

    // Add event listener for click outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="ms-auto mt-2 mb-2 mx-5">
          <button className="btn btn-primary me-2" onClick={downloadCSV}>
            <i className="bi bi-file-earmark-text"></i>
            Export CSV
          </button>
          <button className="btn btn-primary" onClick={downloadExcel}>
            <i className="bi bi-file-earmark-spreadsheet"></i>
            Export Excel
          </button>
        </div>

        <div className="table-responsive">
          <table className="table table-row-bordered table-row-gray-300 text-muted align-middle gs-5">
            <thead className="table-light fw-bolder">
              <tr className="bg-primary">
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Updates</th>
                <th scope="col">Status</th>
                <th scope="col">Assignee</th>
                <th scope="col">Assigned By</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
  {auditTrailData?.map((data, index) => {

    const formattedDate = formatDate(data.created_date); 
    const formattedTime = formatTime(data.created); 

    const forwardedDate = formatDate(auditTrailForwardedData.last_forward_date);
    const forwardedTime = formatTime(auditTrailForwardedData.last_forward_date);



    // Check if both date and time match
    const isHighlighted =
      formattedDate === forwardedDate && formattedTime === forwardedTime


    return (
      <tr
        key={index}
        style={{background: isHighlighted ? "#d8d8df" : "", color:isHighlighted ? "black" : ""}}
        // className={isHighlighted ? "table-success" : ""} // Highlight the row if it matches
      >
        <th scope="row">{formattedDate}</th>
        <td>{formattedTime}</td>
        <td>{data.ticket_activity || "N/A"}</td>
        <td>{data.new_status_type || "N/A"}</td>
        <td>{data.new_assignee || "N/A"}</td>
        <td>{data.assigned_by || "N/A"}</td>
        <td>
          <TrailActionMenu
            index={index}
            id={data.id}
            auditTrailData={data}
            toggleMenu={toggleMenu}
            openMenuIndex={openMenuIndex}
            dropdownRef={dropdownRef}
            setOpenMenuIndex={setOpenMenuIndex}
          />
        </td>
      </tr>
    );
  })}
</tbody>


          </table>
        </div>
      </div>
    </>
  );
};

export default Trail;
