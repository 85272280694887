import { useState } from "react";
import AccountFieldsPane from "../../components/settings/customer/accountFields/AccountFieldsPane";
import ContactFieldsPane from "../../components/settings/customer/contactFields/ContactFieldsPane";
import TagsPane from "../../components/settings/customer/TagsPane";
import ClientLoginPane from "../../components/settings/customer/ClientLoginPane";
import NestedDDConf from "../../components/settings/common/NestedDDConf";

const CustomerSettings = () => {
  const paneTitles = [
    "Account fields",
    "Contact fields",
    "Tags",
    // "Client login",
  ];
  const [openedPane, setOpenedPane] = useState("Account fields");
  return (
    <div>
      <div className="text-center">
        <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
          {paneTitles.map((title, index) => (
            <>
              <li className="nav-item pb-0" key={index}>
                <span
                  className={`nav-link cursor-pointer fs-4 fw-bold ${
                    openedPane === title ? "active fw-bolder" : ""
                  }`}
                  onClick={() => {
                    setOpenedPane(title);
                  }}
                  role="tab"
                >
                  {" "}
                  {title}
                </span>
              </li>
            </>
          ))}
        </ul>

        <div className="card-body" style={{ paddingTop: "0px" }}>
          {(() => {
            switch (openedPane) {
              case "Account fields":
                return <AccountFieldsPane />;
              case "Contact fields":
                return <ContactFieldsPane />;
              case "Tags":
                return <TagsPane />;
              case "Client login":
                return <ClientLoginPane />;
              case "confNestedDD":
                return (
                  <>
                    <NestedDDConf setMultiLevelConfiguration={undefined} />
                  </>
                );
              default:
                return <h4>select a tab to proceed</h4>;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default CustomerSettings;
