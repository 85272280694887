import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import { useUsersByTeamIdQuery } from "../../../Services/ticket/users/UsersByTeamId";
import { toast } from "react-toastify";
import { useUpdateUsernameMutation } from "../../../Services/ticket/TicketDetail";
import { useParams } from "react-router-dom";
import { useTicketControlQuery } from "../../../Services/ticketAccessControl/TicketAccessControl";
import { USER_TEAM_ID } from "../../../constants";
import { Loader } from "react-bootstrap-typeahead";

interface IProps {
  closeModal: () => void;
  refetch: any;
  refetchAuditTrailForwardedData:any
}

const ChangeUserModal: React.FC<IProps> = ({ closeModal, refetch ,refetchAuditTrailForwardedData}) => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [theTeams, setTheTeams] = useState([]);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { data: teamsDataa } = useTeamsQuery({});
  const [selectedUser, setSelectedUser] = useState("");
  const [theUser, setTheUser] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const teamId = localStorage.getItem(USER_TEAM_ID);

  const { data: teamsData } = useTicketControlQuery(teamId);

  const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
    selectedTeams,
    {
      skip: !selectedTeams,
    }
  );

  console.log(selectedTeams, "selecttetet")
  const params = useParams();
  const uuid = params.uuid;

  const [UpdateUsername] = useUpdateUsernameMutation();

  useEffect(() => {
    if (teamsData?.length > 0) {
      setTheTeams(teamsData[0]?.team);
    } else {
      setTheTeams(teamsDataa);
    }
  }, [teamsData, teamsDataa]);

  useEffect(() => {
    if (usersData) {
      setTheUser(usersData);
    }
  }, [usersData]);

  useEffect(() => {
    if (usersStatus === "pending") {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [usersStatus]);

  useEffect(() => {
    setIsCheckboxEnabled(selectedTeams.length > 0);
    if (selectedTeams.length === 0) {
      setIsCheckboxChecked(false);
    }
  }, [selectedTeams]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Call the createTicket mutation function with the form values
      const response = await UpdateUsername({
        id: `${uuid}`,
        assignee: selectedUser,
        team_assignee: selectedTeams,
      });

      if (response.data) {
        setIsLoading(false); // Reset loading state
        closeModal(); // Close modal
        toast.success("Assigned successfully!"); // Show success toast
        refetch(); // Refetch data
        refetchAuditTrailForwardedData()
      } else if (response.error) {
        // Extract error details from the response
        const { status, data }: any = response.error;

        if (data?.detail) {
          // Show detailed error messages dynamically
          data.detail.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });
        } else {
          toast.error(
            `Error ${status}: Something went wrong. Please try again.`
          );
        }

        setIsLoading(false); // Reset loading state
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      toast.error("Failed to assign. Please try again. "); // Show error toast
      console.error(error); // Log the error
    }
    closeModal();
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>

      <div className="row mt-3">
        <div className="col">
          <label className="form-label">Team</label>
          <select
            className="form-select"
            value={selectedTeams}
            onChange={(e: any) => {
              setSelectedTeams(e.target.value);
              setIsCheckboxChecked(false);
              setSelectedUser("");
              setUserSelected(true);
            }}
          >
            <option value="">Select Team</option>
            {theTeams.map((team: any) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              disabled={!isCheckboxEnabled}
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            />
            <label htmlFor="assignToUserCheckbox" className="form-check-label">
              Assign to user
            </label>
          </div>
        </div>
      </div>

      {isCheckboxChecked && (
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">User</label>
            {isUserLoading ? (
              <Loader />
            ) : (
              <select
                className="form-select"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                <option value="" disabled>
                  Select User
                </option>
                {theUser.map((user: any) => (
                  <option key={user.id} value={user.id}>
                    {user.username.split("@")[0]}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      )}
      <div className="row text-end ">
        <div className="col">
          <button className="btn btn-primary mt-5" onClick={handleSubmit}>
            {isLoading ? (
              <>
                Assigning... <Loader />
              </>
            ) : (
              "Assign"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChangeUserModal;
