import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import { MultiSelect } from "react-multi-select-component";

const OrderInvoiceDetailReport = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "leadStatus",
      headerName: "Lead Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "currentAssignee",
      headerName: "Current Assignee",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "internalNotes",
      headerName: "Internal Notes",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      createdDate: "2024-07-17",
      customerName: "John Doe",
      email: "john.doe@example.com",
      mobileNo: "1234567890",
      lastUpdated: "2024-07-17",
      leadStatus: "Active",
      currentAssignee: "Agent A",
      internalNotes: "Follow up needed",
    },
    {
      id: 2,
      createdDate: "2024-07-16",
      customerName: "Jane Smith",
      email: "jane.smith@example.com",
      mobileNo: "9876543210",
      lastUpdated: "2024-07-16",
      leadStatus: "Closed",
      currentAssignee: "Agent B",
      internalNotes: "Issue resolved",
    },
    // Add more rows as needed
  ];

  const options: any[] = [
    { label: "Insurance Team", value: "Insurance Team" },
    { label: "Collection", value: "Collection" },
  ];

  const optionsStatus: any[] = [
    { label: "Active", value: "active" },
    { label: "In Progress", value: "InProgress" },
    { label: "Closed", value: "closed" },
    { label: "Reopen", value: "reopen" },
  ];

  const [selectedTeams, setSelectedTeams] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* Back button */}
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/telephony/reports-list");
            }}
          /> */}
          <button
              className="btn btn-link mb-2"
              onClick={() => {
                navigate("/reports/telephony/reports-list");
              }}
              style={{marginRight:"-17px"}}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3">
            {" "}
            Order/Invoice Detail Report
          </span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          {/* Date Filters */}
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
            />
          </div>

          <div className="w-100 mw-150px">
            <MultiSelect
              options={options}
              value={selectedTeams}
              onChange={setSelectedTeams}
              labelledBy="Select"
            />
          </div>
          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="workflow-select-label">Workflow</InputLabel>
              <Select
                labelId="workflow-select-label"
                id="workflow-select"
                label="Workflow"
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                <MenuItem value="Work Flow">Work Flow</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <MultiSelect
                options={optionsStatus}
                value={selectedStatus}
                onChange={setSelectedStatus}
                labelledBy="Select"
              />
            </FormControl>
          </div>

          <div className="w-100 mw-150px">
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select
                labelId="Assignee-select-label"
                id="Assignee-select"
                label="Assignee"
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                <MenuItem value="Animesh Jain">Animesh Jain</MenuItem>
                <MenuItem value="Archana">Archana</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary">Add Email Id</button>
            <button className="btn btn-primary">Submit</button>
            <button className="btn btn-primary">Download</button>
            <button className="btn btn-link">
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        {/* DataGrid */}
        <CardContent style={{ width: "100%" }}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              loading={loading}
              disableColumnMenu
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                "& .css-16c50h-MuiInputBase-root-MuiTablePagination-select ,.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
                  {
                    marginBottom: "10px",
                  },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default OrderInvoiceDetailReport;
