import React from 'react'

interface IProps {
  side: string
}

const MsgLoader: React.FC<IProps> = ({ side }) => {
  return (
    <>
      <div className={`intent-message ${side}`}>
        <span className='dots-cont'>
          <span className='dot dot-1 botgo-font-color'></span>
          <span className='dot dot-2 botgo-font-color'></span>
          <span className='dot dot-3 botgo-font-color'></span>
        </span>
      </div>
    </>
  )
}

export default MsgLoader
