import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Divider,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  CIRCLEONE_USER,
  CIRCLEONE_TEAM,
  CIRCLEONE_USERNAME,
} from "../constants";

const SideBarWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dashboardOpen, setDashboardOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prev) => !prev);
  const toggleDashboard = () => setDashboardOpen((prev) => !prev);

  const [activityOpen, setActivityOpen] = useState(false);
  const toggleActivity = () => setActivityOpen((prev) => !prev);

  const [reportsOpen, setReportsOpen] = useState(false);
  const toggleReports = () => setReportsOpen((prev) => !prev);

  const [permissionList, setPermissionList] = useState<any>([]);
  const [modulePermission, setModulePermission] = useState<boolean>(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const permissionToShow = localStorage.getItem("user-token");

  const checkTenant = localStorage.getItem(CIRCLEONE_USER);
  const checkTeam = localStorage.getItem(CIRCLEONE_TEAM);
  const checkUsername = localStorage.getItem(CIRCLEONE_USERNAME);

  console.log(checkTenant, "checktenantntn");

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setIsOpen(false);
    }
  };
  // 

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (permissionToShow) {
      const parsedPermission = JSON.parse(permissionToShow); // Parse the JSON string
      const teamPermissions = parsedPermission?.teams[0];
      // Find the key that you need dynamically
      const dynamicKey =
        Object.keys(teamPermissions).find((key) =>
          key.includes("module_permissions")
        ) || Object.keys(teamPermissions)[0];
      const dynamicKey1 =
        Object.keys(teamPermissions).find(
          (key) => key === "module_permissions"
        ) || Object.keys(teamPermissions)[0];
      if (dynamicKey1 === "module_permissions") {
        setModulePermission(true);
      } else {
        setModulePermission(false);
      }
      const permissionListValue = teamPermissions[dynamicKey] || [];
      setPermissionList(permissionListValue);
    }
  }, [permissionToShow]);

  const hasPermission = (permission) =>
    Array.isArray(permissionList) && permissionList.includes(permission);

  const restricteduser =
    checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io";

  return (
    <div className="d-lg-none">
      <IconButton
        onClick={() => setIsOpen(!isOpen)}
        color="inherit"
        className="text-hover-primary"
      >
        <MenuIcon sx={{ fontSize: 25 }} />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={toggleMenu}>
        <Box
          sx={{
            width: 250,
            height: "100vh", // Ensures full viewport height
            background: "#1e1e2d",
            color: "#EAEAEA",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "4px 0px 12px rgba(0, 0, 0, 0.2)",
            overflow: "scroll", // Prevents any unintended scrolling inside the Drawer
            scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          }}
        >
          <IconButton
            onClick={() => setIsOpen(false)}
            sx={{ position: "absolute", right: 0, top: 50, color: "#EAEAEA" }}
          >
            <KeyboardDoubleArrowRightIcon
              style={{ fontSize: "2rem" }}
              className="text-hover-primary"
            />
          </IconButton>

          <Box>
            <Box sx={{ textAlign: "center", p: 3 }}>
              <Link to="/dashboard/email-dashboard">
                <img
                  alt="Logo"
                  src="/media/logos/CircleOne-Logo-With-Change.png"
                  style={{ maxHeight: "70px" }}
                />
              </Link>
            </Box>
            <Divider sx={{ backgroundColor: "#EAEAEA", margin: "0 20px" }} />

            <List>
              {hasPermission("dashboard:enable") && (
                <>
                  <ListItem
                    button
                    onClick={() => setDashboardOpen(!dashboardOpen)}
                    className={dashboardOpen ? "active" : ""}
                    sx={{
                      backgroundColor: dashboardOpen ? "#333352" : "inherit",
                      "&:hover": { backgroundColor: "#333352" },
                    }}
                  >
                    <ListItemIcon sx={{ color: "#EAEAEA" }}>
                      <i className="bi bi-house-door text-white fs-2"></i>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            color: "#EAEAEA",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Dashboard
                        </Typography>
                      }
                    />
                    {dashboardOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {!restricteduser && (
                        <ListItem
                          button
                          component={Link}
                          to="/dashboard/email-dashboard"
                          selected={
                            currentPath === "/dashboard/email-dashboard"
                          }
                          sx={{
                            backgroundColor:
                              currentPath === "/dashboard/email-dashboard"
                                ? "#444467"
                                : "inherit",
                            padding: "12px 20px",
                            pl: 9,
                          }}
                        >
                          <ListItemText primary="Email Dashboard" />
                        </ListItem>
                      )}
                      {!restricteduser && (
                        <ListItem
                          button
                          component={Link}
                          to="/dashboard/ticket-dashboard"
                          selected={
                            currentPath === "/dashboard/ticket-dashboard"
                          }
                          sx={{
                            backgroundColor:
                              currentPath === "/dashboard/ticket-dashboard"
                                ? "#444467"
                                : "inherit",
                            padding: "12px 20px",
                            pl: 9,
                          }}
                        >
                          <ListItemText primary="Ticket Dashboard" />
                        </ListItem>
                      )}

                      {checkTenant === "victorinox" ? (
                        <p
                          style={{
                            padding: "4px 15px",
                            margin: "0",
                            color: "grey",
                            cursor: "default",
                          }}
                        >
                          Leads
                        </p>
                      ) : (
                        <ListItem
                          button
                          component={Link}
                          to="/dashboard/campaign-dashboard"
                          selected={
                            currentPath === "/dashboard/campaign-dashboard"
                          }
                          sx={{
                            backgroundColor:
                              currentPath === "/dashboard/campaign-dashboard"
                                ? "#444467"
                                : "inherit",
                            padding: "12px 20px",
                            pl: 9,
                          }}
                        >
                          <ListItemText primary="Outboung Lead Dashboard" />
                        </ListItem>
                      )}

                      {!restricteduser && (
                        <>
                          {checkTenant === "victorinox" ? (
                            <p
                              style={{
                                padding: "4px 14px",
                                margin: "0",
                                color: "grey",
                                cursor: "default",
                              }}
                            >
                              Telephony
                            </p>
                          ) : (
                            <ListItem
                              button
                              component={Link}
                              to="/dashboard/rtm-dashboard"
                              selected={
                                currentPath === "/dashboard/rtm-dashboard"
                              }
                              sx={{
                                backgroundColor:
                                  currentPath === "/dashboard/rtm-dashboard"
                                    ? "#444467"
                                    : "inherit",
                                padding: "12px 20px",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Telephony Dashboard" />
                            </ListItem>
                          )}
                        </>
                      )}
                    </List>
                  </Collapse>
                </>
              )}

              {/* Activity Section */}
              {restricteduser ? (
                ""
              ) : (
                <>
                  <ListItem
                    button
                    onClick={toggleActivity}
                    // sx={{ padding: "12px 20px", margin: "6px 16px" }}
                  >
                    <ListItemIcon sx={{ color: "#EAEAEA" }}>
                      <i className="bi bi-activity fs-2 text-white"></i>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            color: "#EAEAEA",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Activity
                        </Typography>
                      }
                    />
                    {activityOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={activityOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component={Link}
                        to="/activity/active"
                        selected={currentPath === "/activity/active"}
                        sx={{
                          backgroundColor:
                            currentPath === "/activity/active"
                              ? "#444467"
                              : "inherit",
                          padding: "12px 20px",
                          pl: 9,
                        }}
                      >
                        <ListItemText primary="Active" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/activity/recent"
                        selected={currentPath === "/activity/recent"}
                        sx={{
                          backgroundColor:
                            currentPath === "/activity/recent"
                              ? "#444467"
                              : "inherit",
                          padding: "12px 20px",
                          pl: 9,
                        }}
                      >
                        <ListItemText primary="Recent" />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}

              {/* Users Section */}
              {hasPermission("users:enable") && (
                <ListItem
                  button
                  component={Link}
                  to="/users/user"
                  selected={currentPath === "/users/user"}
                  sx={{
                    backgroundColor:
                      currentPath === "/users/user" ? "#333352" : "inherit",
                    "&:hover": { backgroundColor: "#333352" },
                  }}
                >
                  <ListItemIcon sx={{ color: "#EAEAEA" }}>
                    <i className="bi bi-people text-white fs-2"></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: "#EAEAEA",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Users
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {/* Contact */}
              {hasPermission("customers:enable") && (
                <ListItem
                  button
                  component={Link}
                  to="/contact/customer/account"
                  selected={currentPath === "/contact/customer/account"}
                  sx={{
                    backgroundColor:
                      currentPath === "/contact/customer/account"
                        ? "#333352"
                        : "inherit",
                    "&:hover": { backgroundColor: "#333352" },
                  }}
                >
                  <ListItemIcon sx={{ color: "#EAEAEA" }}>
                    <i className="bi bi-person text-white fs-2"></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: "#EAEAEA",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Contact
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {/* Email */}

              {hasPermission("email:enable") && (
                <ListItem
                  button
                  component={Link}
                  to="/email/inbox"
                  selected={currentPath === "/email/inbox"}
                  sx={{
                    backgroundColor:
                      currentPath === "/email/inbox" ? "#333352" : "inherit",
                    "&:hover": { backgroundColor: "#333352" },
                  }}
                >
                  <ListItemIcon sx={{ color: "#EAEAEA" }}>
                    <i className="bi bi-envelope-arrow-down text-white fs-2"></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: "#EAEAEA",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Email
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {/* Ticket */}
              {hasPermission("ticket:enable") && (
                <ListItem
                  button
                  component={Link}
                  to="/ticket/lists"
                  selected={currentPath === "/ticket/lists"}
                  sx={{
                    backgroundColor:
                      currentPath === "/ticket/lists" ? "#333352" : "inherit",
                    "&:hover": { backgroundColor: "#333352" },
                  }}
                >
                  <ListItemIcon sx={{ color: "#EAEAEA" }}>
                    <i className="bi bi-ticket-detailed text-white fs-2 me-2"></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: "#EAEAEA",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Ticket
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {/* Campaign */}
              {hasPermission("campaigns:enable") && (
                <>
                  {checkTenant === "victorinox" ? (
                    <ListItem
                      button={false}
                      sx={{
                        color: "grey",
                        padding: "12px 40px",
                        cursor: "default",
                        pl: 9,
                      }}
                    >
                      <ListItemText primary="Leads" />
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      component={Link}
                      to="/campaign/list"
                      selected={currentPath === "/campaign/list"}
                      sx={{
                        backgroundColor:
                          currentPath === "/campaign/list"
                            ? "#333352"
                            : "inherit",
                        "&:hover": { backgroundColor: "#333352" },
                      }}
                    >
                      <ListItemIcon sx={{ color: "#EAEAEA" }}>
                        <i className="bi bi-menu-up text-white fs-2 me-2 "></i>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color: "#EAEAEA",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Leads
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </>
              )}

              {/* Chat */}
              {!restricteduser && (
                <>
                  {hasPermission("chat:enable") && (
                    <ListItem
                      button
                      component={Link}
                      to="/chat/chatrooms?type=Assigned"
                      selected={currentPath === "/chat/chatrooms?type=Assigned"}
                      sx={{
                        backgroundColor:
                          currentPath === "/chat/chatrooms?type=Assigned"
                            ? "#333352"
                            : "inherit",
                        "&:hover": { backgroundColor: "#333352" },
                      }}
                    >
                      <ListItemIcon sx={{ color: "#EAEAEA" }}>
                        <i className="bi bi-chat  fs-2 me-2 text-white"></i>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color: "#EAEAEA",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Chat
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </>
              )}

              {/* Reports */}
              {restricteduser ? (
                <>
                  {hasPermission("reports:enable") && (
                    <>
                      <ListItem button onClick={toggleReports}>
                        <ListItemIcon sx={{ color: "#EAEAEA" }}>
                          <i className="bi bi-chat fs-2 me-2 text-white"></i>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                color: "#EAEAEA",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Reports
                            </Typography>
                          }
                        />
                        {reportsOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            component={Link}
                            to="/reports/campaign/reports-list"
                            selected={
                              currentPath === "/reports/campaign/reports-list"
                            }
                            sx={{
                              backgroundColor:
                                currentPath === "/reports/campaign/reports-list"
                                  ? "#444467"
                                  : "inherit",
                              padding: "12px 20px",
                              pl: 9,
                            }}
                          >
                            <ListItemText primary="Leads" />
                          </ListItem>
                        </List>
                      </Collapse>
                    </>
                  )}
                </>
              ) : (
                <>
                  {hasPermission("reports:enable") && (
                    <>
                      <ListItem button onClick={toggleReports}>
                        <ListItemIcon sx={{ color: "#EAEAEA" }}>
                          <i className="bi bi-file-earmark-bar-graph text-white fs-2"></i>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                color: "#EAEAEA",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Reports
                            </Typography>
                          }
                        />
                        {reportsOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {checkTenant === "meta" ? (
                            <ListItem
                              button={false}
                              sx={{
                                color: "grey",
                                padding: "12px 40px",
                                cursor: "default",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Email" />
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              component={Link}
                              to="/reports/email/reports-list"
                              selected={
                                currentPath === "/reports/email/reports-list"
                              }
                              sx={{
                                backgroundColor:
                                  currentPath === "/reports/email/reports-list"
                                    ? "#444467"
                                    : "inherit",
                                padding: "12px 20px",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Email" />
                            </ListItem>
                          )}

                          <ListItem
                            button
                            component={Link}
                            to="/reports/ticket/reports-list"
                            selected={
                              currentPath === "/reports/ticket/reports-list"
                            }
                            sx={{
                              backgroundColor:
                                currentPath === "/reports/ticket/reports-list"
                                  ? "#444467"
                                  : "inherit",
                              padding: "12px 20px",
                              pl: 9,
                            }}
                          >
                            <ListItemText primary="Ticket" />
                          </ListItem>

                          {checkTenant === "victorinox" ? (
                            <ListItem
                              button={false}
                              sx={{
                                color: "grey",
                                padding: "12px 40px",
                                cursor: "default",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Telephony" />
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              component={Link}
                              to="/reports/telephony/reports-list"
                              selected={
                                currentPath ===
                                "/reports/telephony/reports-list"
                              }
                              sx={{
                                backgroundColor:
                                  currentPath ===
                                  "/reports/telephony/reports-list"
                                    ? "#444467"
                                    : "inherit",
                                padding: "12px 20px",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Telephony" />
                            </ListItem>
                          )}

                          {checkTenant === "victorinox" ? (
                            <ListItem
                              button={false}
                              sx={{
                                color: "grey",
                                padding: "12px 40px",
                                cursor: "default",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Leads" />
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              component={Link}
                              to="/reports/campaign/reports-list"
                              selected={
                                currentPath === "/reports/campaign/reports-list"
                              }
                              sx={{
                                backgroundColor:
                                  currentPath ===
                                  "/reports/campaign/reports-list"
                                    ? "#444467"
                                    : "inherit",
                                padding: "12px 20px",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Leads" />
                            </ListItem>
                          )}

                          {checkTenant === "victorinox" ? (
                            <ListItem
                              button={false}
                              sx={{
                                color: "grey",
                                padding: "12px 40px",
                                cursor: "default",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Chat" />
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              component={Link}
                              to="/reports/chat/reports-list"
                              selected={
                                currentPath === "/reports/chat/reports-list"
                              }
                              sx={{
                                backgroundColor:
                                  currentPath === "/reports/chat/reports-list"
                                    ? "#444467"
                                    : "inherit",
                                padding: "12px 20px",
                                pl: 9,
                              }}
                            >
                              <ListItemText primary="Chat" />
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </>
                  )}
                </>
              )}

              {/* Settings */}

              {hasPermission("settings:enable") && (
                <ListItem
                  button
                  component={Link}
                  to="/settings/users"
                  selected={currentPath === "/settings/users"}
                  sx={{
                    backgroundColor:
                      currentPath === "/settings" ? "#333352" : "inherit",
                    "&:hover": { backgroundColor: "#333352" },
                  }}
                >
                  <ListItemIcon sx={{ color: "#EAEAEA" }}>
                    <i className="bi bi-sliders text-white fs-2 me-2"></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: "#EAEAEA",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Settings
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Box>

          <Box className="text-center p-3">
            <Link
              to="/logout"
              className="btn btn-dark d-flex align-items-center justify-content-center gap-2"
              style={{
                borderRadius: "6px",
                backgroundColor: "#494b74",
                color: "#fff",
                fontWeight: "500",
                textDecoration: "none",
              }}
            >
              <LogoutIcon /> Sign Out
            </Link>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default SideBarWrapper;
