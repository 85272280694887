import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
import { useCreateWhatsAppTemplatesMutation } from "../../../../Services/campign/campignList";
import { toast } from "react-toastify";
import axios from "axios";
import { FILE_UPLOAD_URL } from "../../../../env";

const TemplateDetailsModal = ({ show, onClose, template, closeModal, refetch }) => {
    const [templateName, setTemplateName] = useState<any>('')
    const [selectedFile, setSelectedFile] = useState<any>("");
    const [editedTemplate, setEditedTemplate] = useState<any>(template?.payload);
    const [highlight, setHighlight] = useState<any>()
    const [uploadedImages, setUploadedImages] = useState<{ [key: number]: string }>({});
    const [
        trigger,
        { isLoading: createTempateLoading, status: createTemplateStatus },
    ] = useCreateWhatsAppTemplatesMutation();

    const handleInputChange = (index, key, value, changes) => {
        if (changes === 'button') {

            setHighlight(`${index}-${key}`); // Track specific button
        } else if (changes === "header") {
            setHighlight(index)
        } else {
            setHighlight({ index, paramIndex: key }); // Store both component and param index
        }
        const updatedComponents = JSON.parse(JSON.stringify(editedTemplate.components)); // Deep Copy

        if (updatedComponents[index].type === "HEADER" && updatedComponents[index].format === "IMAGE") {
            updatedComponents[index].example.header_handle[0] = value;
        } else if (updatedComponents[index].type === "BODY") {
            if (updatedComponents[index].example?.body_text_named_params) {
                updatedComponents[index].example.body_text_named_params[key].example = value;
            }
        } else if (updatedComponents[index].type === "BUTTONS") {
            if (updatedComponents[index].buttons[key]?.example) {
                updatedComponents[index].buttons[key].example[0] = value;
            }
        }

        setEditedTemplate({ ...editedTemplate, components: updatedComponents });
    };


    const createTemplateHandler = async () => {
        if (!templateName.trim()) {
            toast.error("Template name is required.");
            return;
        }
        const templateData = {
            "payload": {},
            "raw_payload": editedTemplate,
            "team": Number(localStorage.getItem("teamId")),
            "template_name": template?.id,
            "name": templateName

        };

        const response = await trigger(templateData);

        try {
            if (response?.data) {
                onClose();
                closeModal()
                toast.success("Template created successfully");
                refetch()
            } else if (response?.error && "data" in response.error) {
                const errorData = response.error.data as {
                    category?: string[];
                    content?: string[];
                };

                let errorMessage = "";
                if (errorData?.category && errorData.category.length > 0) {
                    errorMessage += "Category cannot be blank. ";
                }
                if (errorData?.content && errorData.content.length > 0) {
                    errorMessage += "Content cannot be blank.";
                }

                toast.error(errorMessage || "An unknown error occurred.");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {

        if (editedTemplate) {
            setSelectedFile(editedTemplate?.components[0]?.example?.header_handle[0])
        }

    }, [editedTemplate])
    const handleFileUpload = (index: any, key: any, changes: any, e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0]; // Get selected file

        if (file) {
            const imageUrl = URL.createObjectURL(file); // Create a temporary URL for preview

            setUploadedImages((prev) => ({
                ...prev,
                [index]: imageUrl, // Store preview URL using index as key
            }));
            imageUpload(index, key, changes, [file])

        }
    };


    async function imageUpload(index, key, changes, uploadedFiles: any) {
        if (uploadedFiles !== undefined) {
            // console.log(uploadedFiles);

            uploadedFiles.map(async (value: any, index: any) => {

                let files: any = [];
                files.push({
                    fileName: value.name,
                    mimeType: value.type,
                });

                const data = { files };
                try {
                    let response = await axios.post(FILE_UPLOAD_URL as string, data, {
                        headers: {

                            'Content-Type': 'application/json',
                        }
                    });
                    const uploadURL = response.data[0].uploadURL;
                    setSelectedFile(response.data[0].url)

                    if (changes === "header") {
                        setHighlight(index)
                    }
                    const updatedComponents = JSON.parse(JSON.stringify(editedTemplate.components)); // Deep Copy
                    if (updatedComponents[index].type === "HEADER" && updatedComponents[index].format === "IMAGE") {
                        updatedComponents[index].example.header_handle[0] = response.data[0].url;
                    }

                    setEditedTemplate({ ...editedTemplate, components: updatedComponents });
                    // Send file to uploadURL after the url is created in s3
                    const finalresponse = await axios.put(uploadURL, uploadedFiles[0], {
                        headers: {
                            'Content-Type': value.type,
                        }
                    });
                    console.log(finalresponse)
                } catch (err) {
                    console.error(err);
                }
            });
        }
    }


    return (
        <div className="modal-container">
            <div className="modal-header d-flex justify-content-center position-relative">
                <h4 className="m-0 position-absolute start-50 translate-middle-x"></h4>
                <CloseButton onClick={onClose} className="position-absolute end-0" />
            </div>
            <div className="modal-body d-flex">
                <div className="row">
                    {/* Left Side: Template Preview */}
                    <div className="col-6">
                        <div className="template-preview border-end pe-3">
                            <div className="container">
                                <div className="card p-3">
                                    {editedTemplate.components.map((comp, index) => (
                                        <div
                                            key={index}
                                            className={`mb-3 p-2 ${highlight === index ? "border border-danger" : ""}`}

                                            style={{ cursor: "pointer" }} // Makes it clear it's clickable
                                        >
                                            {comp.type === "HEADER" && comp.format === "IMAGE" && (
                                                <img
                                                    src={uploadedImages[index] || comp?.example?.header_handle?.[0]}
                                                    alt="Header"
                                                    className="w-100"
                                                    style={{ height: "200px", objectFit: "cover" }}
                                                />
                                            )}

                                            {comp.type === "BODY" && (
                                                <p className="mt-2 text-dark p-2">
                                                    {comp.text.split(/({{[^{}]+}})/g).map((part, i) => {
                                                        const match = part.match(/{{(\w+)}}/); // Extract placeholder name
                                                        if (match) {
                                                            const paramName = match[1]; // Extracted parameter name
                                                            const paramIndex = comp.example.body_text_named_params.findIndex(
                                                                (p) => p.param_name === paramName
                                                            );
                                                            const param = comp.example.body_text_named_params[paramIndex];

                                                            return (
                                                                <span
                                                                    key={i}
                                                                    className={highlight?.index === index && highlight?.paramIndex === paramIndex
                                                                        ? "bg-warning text-dark p-1 rounded" // Highlight only changed text
                                                                        : ""}>
                                                                    {param ? param.example : paramName}
                                                                </span>
                                                            );
                                                        }
                                                        return part; // Return normal text
                                                    })}
                                                </p>
                                            )}


                                            {comp.type === "BUTTONS" && (
                                                <div className="d-flex flex-column gap-2">
                                                    {comp.buttons.map((button, btnIndex) => (
                                                        <Button
                                                            key={btnIndex}
                                                            variant="light"
                                                            className={`text-primary border rounded-3 text-start text-center 
                                                                ${highlight === `${index}-${btnIndex}` ? "border border-danger" : ""}`}
                                                        >
                                                            {button.text.includes("test drive") && (
                                                                <i className="bi bi-box-arrow-up-right me-2  text-primary text-bold"></i>
                                                            )}
                                                            {button.type.includes("QUICK_REPLY") && (

                                                                <i className="bi bi-box-arrow-up-right me-2  text-primary text-bold "></i>
                                                            )}
                                                            {button.type.includes("URL") && (
                                                                <i className="bi bi-send me-2  text-primary text-bold "></i>
                                                            )}
                                                            {button.text}
                                                        </Button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Side: Editable Fields */}
                    <div className="col-6">
                        <div className="template-edit ps-3">
                            <h5 className="text-dark">Edit template</h5>
                            <div className="mb-3">
                                <label className="required">Template name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}

                                />
                            </div>
                            {editedTemplate.components.map((comp, index) => (
                                <div key={index}>
                                    {comp.type === "HEADER" && comp.format === "IMAGE" && (
                                        <div className="mb-3">
                                            <label>Header image</label>
                                            <div className="input-group">
                                                {/* Read-only input field to display selected file name */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="No file chosen"
                                                    value={selectedFile}
                                                    readOnly
                                                />
                                                {/* Hidden file input */}
                                                <input
                                                    type="file"
                                                    className="d-none"
                                                    accept="image/*"
                                                    id={`fileInput-${index}`}
                                                    onChange={(e) => handleFileUpload(index, 0, "header", e)}
                                                />
                                                {/* Button to trigger file selection */}
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={() => document.getElementById(`fileInput-${index}`).click()}
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {comp.type === "BODY" &&
                                        comp.example?.body_text_named_params?.map((param, paramIndex) => (
                                            <div key={paramIndex} className="mb-3">
                                                <label>
                                                    {param.param_name
                                                        .split("_")
                                                        .map((word, i) =>
                                                            i === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
                                                        )
                                                        .join(" ")}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editedTemplate.components[index].example.body_text_named_params[paramIndex].example}
                                                    onChange={(e) => handleInputChange(index, paramIndex, e.target.value, "body")}
                                                />
                                            </div>
                                        ))}

                                    {comp.type === "BUTTONS" &&
                                        comp.buttons.map(
                                            (button, btnIndex) =>
                                                button.example && (
                                                    <div key={btnIndex} className="mb-3">
                                                        <label>Button {btnIndex + 1}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={button.example[0]}
                                                            onChange={(e) => handleInputChange(index, btnIndex, e.target.value, "button")}
                                                        />
                                                    </div>
                                                )
                                        )}
                                </div>
                            ))}

                            <div className="d-flex justify-content-end mt-5">
                                <button
                                    className="btn btn-primary"
                                    onClick={createTemplateHandler}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplateDetailsModal;
