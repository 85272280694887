import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
    AUTH_TOKEN,
    CALL,
    CALL_HISTORY_ID,
    CALLBREAKID,
    EXTENSION,
    INCOMING_CALL,
    OUTGOING_CALL,
    QUEUE,
    TELEPHONEURL,
    TELEPHONY_LOGIN,
    TELPEHONYLOGINUSERID,
    USER_TEAM_ID,
} from "../../constants";
import {
    useCallHistoryIdMutation,
    useExtensionStateMutation,
    useTelephonyDispositionQuery,
} from "../../Services/Telephony/TelephonyFrontend";
import { Box, Modal, Typography } from "@mui/material";

import { useTelephony } from "../context/TelephonyContext";
import { toast } from "react-toastify";
import TelephonyDisposition from "./TelephonyDisposition";
import "./styles.scss";

import ButtonLoadingSkeleton from "../ButtonsLoadingSkeleton";

interface IPROPS {
    setIsCallDisconnected: any;
    isCallDisconnected: any;
    setCall: any;
    call: any;
    onClick?: () => void;
    style?: React.CSSProperties; // Add this line
    setIsCallPaused: any;
    setInbound: any;
    setIsConferenceCallActive:any
    checkingExtensionState: any;
    setCheckConferenceCallNumberEntered:any;
    pauseHandler:any

}

const CallDisconnect: React.FC<IPROPS> = ({
    setCall,
    onClick,
    style,
    setIsCallPaused,
    setInbound,
    checkingExtensionState,
    setIsConferenceCallActive,
    setCheckConferenceCallNumberEntered,
    pauseHandler
}) => {
    const {
        setCallDisconnected,
        disconnectCallLoader,
        setDisconnectCallLoader,
    } = useTelephony();

    const [storedDisposition, setStoredDisposition] = useState([]);
    const [openTelephonyDispositionModal, setOpenTelephonyDispositionModal] =
        useState(false);


          const outgoing = localStorage.getItem(OUTGOING_CALL)

           const incomingCallHistoryId = localStorage.getItem("IncomingCallId")
            const CallHistoryId = localStorage.getItem(CALL_HISTORY_ID)

    // const gettingCallHistoryId = outgoing ? CallHistoryId : incoming ? incomingCallHistoryId : null;
    const gettingCallHistoryId = outgoing === "true" ?  CallHistoryId : incomingCallHistoryId;




    const gettingExtension = localStorage.getItem(EXTENSION);
    // const gettingCallHistoryId = localStorage.getItem(CALL_HISTORY_ID);
    const getTEAMID = localStorage.getItem(USER_TEAM_ID);
    const gettingTelephonyData = localStorage.getItem(TELEPHONEURL);

    const gettingIncomingCallId = localStorage.getItem(TELPEHONYLOGINUSERID);



    
    const telephoneData = JSON.parse(gettingTelephonyData);

    const callStatus = localStorage.getItem(CALL) === "true";

    const [extensionStateTrigger] = useExtensionStateMutation();
    const [callHistoryIdTrigger] = useCallHistoryIdMutation();
    const { data } = useTelephonyDispositionQuery(getTEAMID, {
        skip: callStatus,
    });

    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const IncomingCall = localStorage.getItem(INCOMING_CALL);

    const previousState = useRef<string | null>(null); // To track the previous state

    useEffect(() => {
      if (previousState.current === "busy" && checkingExtensionState === "available") {
        callDisconnectHandler();
      }
  
      // Update the previous state
      previousState.current = checkingExtensionState;
    }, [checkingExtensionState]);

    console.log(checkingExtensionState, "checkingnggng")
    const callDisconnectHandler = async () => {
        // setDisconnectedCall(true);
        setDisconnectCallLoader(true);

        toast.warning("Please wait while we are disconnecting a call");

        const url = telephoneData?.https_port > 0 ?
        `https://${telephoneData?.domain}:${telephoneData?.https_port}/click2call/hangup.php?AgentExten=${gettingExtension}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3` :
        `https://${telephoneData?.domain}/click2call/hangup.php?AgentExten=${gettingExtension}&SecKey=2c41c5e95e052e9d03f423e9f578b9b3`


        try {
            await axios.post(
                url
            );

            const extensionObj = {
                extension: +gettingExtension,
                state: "acw",
            };

            await extensionStateTrigger(extensionObj);

            await axios.post(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/users/call-breaks/`,
                { break_remark: "ACW", user_id: +gettingIncomingCallId },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            const callHistoryIdObj = {
                action: "end-call",
            };

            const callHistoryResponse = await callHistoryIdTrigger({
                body: callHistoryIdObj,
                // id: gettingCallHistoryId || gettingIncomingCallId,
                id: gettingCallHistoryId
            });

            if (callHistoryResponse.error) {
                throw new Error("Failed to disconnect the call");
            }
            // setCallDisconnected(true);

            const dispositionCall = await axios.get(
                `${apiPort}://${fetchingTenant}.${
                    process.env.REACT_APP_BASE_URL
                }/v1/telephony/disposition/?team=${getTEAMID}&type=${
                    IncomingCall === "true" ? "IB" : "OB"
                }&`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            AUTH_TOKEN
                        )}`,
                    },
                }
            );

            if (dispositionCall?.data) {
                setOpenTelephonyDispositionModal(true);
                setStoredDisposition(dispositionCall?.data?.results);

            } else {
                throw new Error("Failed to fetch disposition data");
            }

            toast.success("Call disconnected");
            setDisconnectCallLoader(false);
            // setCallDisconnected(true);
            localStorage.removeItem("telephony-timer");
        } catch (error) {
            setDisconnectCallLoader(false);
            toast.error(error.message || "Unable to disconnect the call");
        }
    };

    const modalStyles = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
    };

    const closeDispositionModall = () => {
        setOpenTelephonyDispositionModal(false);
    };

    return (
        <div>
            {disconnectCallLoader ? (
                <ButtonLoadingSkeleton />
            ) : (
                <div
                    onClick={onClick}
                    style={style}
                    className="disconnect_cont"
                >
                    <i
                        style={{
                            fontSize: "15px",
                            color: "white",
                            padding: "8px",
                            background: "red",
                            borderRadius: "50%",
                            transform: "rotate(180deg)",
                            display: "inlineBlock",
                        }}
                        className="bi bi-telephone"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Disconnect"
                        onClick={callDisconnectHandler}
                    ></i>
                </div>
            )}

            <Modal
                open={openTelephonyDispositionModal}
                aria-labelledby="telephony-disposition-modal-title"
                aria-describedby="telephony-disposition-modal-description"
            >
                <Box sx={modalStyles}>
                    <Typography
                        id="telephony-disposition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        Dispose the call
                    </Typography>
                    <TelephonyDisposition
                        setIsCallPaused={setIsCallPaused}
                        Disposition={storedDisposition}
                        setCall={setCall}
                        // isLoading={isLoading}
                        closeModal={closeDispositionModall}
                        ticketId={gettingCallHistoryId}
                        setInbound={setInbound}
                        setIsConferenceCallActive={setIsConferenceCallActive}
                        setCheckConferenceCallNumberEntered={setCheckConferenceCallNumberEntered}
                            pauseHandler={pauseHandler}
                        

                        // value={""}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default CallDisconnect;
