import React, { useEffect, useState } from "react";
import {
  useContactDynamicFieldQuery,
  useContactsInfoDetailsQuery,
  useDeleteContactsAttachmentMutation,
  useUpdateContactInfoDetailsMutation,
} from "../../../Services/customer/FrontendCustomer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import {
  useTagsQuery,
  useTeamsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import { Box, IconButton, Typography, Modal,Tooltip,
  Chip,
  Button, } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ContactCustomerHistory from "./ContactCustomerHistory";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isFieldDisabled } from "../../reusableComponent/FieldDisabled";
import { useTelephony } from "../../context/TelephonyContext";
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachmentList from "../../user/AttachmentList";


interface UserDetails {
  id: number | null;
  name: string;
  email: string;
  phone_number: any;
  account: { name?: string };
  whatsapp_number:string;
  twitter_id: any;
  facebook_id:any
}
interface Tag {
  id: string;
  name: string;
}

interface Team {
  id: string;
  name: string;
}

interface ErrorState {
  [key: string]: string;
}
interface RestItems {
  accessible_teams: Array<any>;
  tags: Array<any>;
  name: any;
  account: { name?: string };
  email: any;
  phone_number: any;
  twitter_id: any;
  facebook_id:any;
  attachments: []
}

const initialRestItems: RestItems = {
  accessible_teams: [],
  tags: [],
  name: "",
  account: { name: "" },
  email: "",
  phone_number: "",
  twitter_id: "",
  facebook_id:"",
  attachments: []
};


const ContactInfo = () => {

  const { id } = useParams();
 const {storingContactData} = useTelephony()

  const location = useLocation();
  const userId = location.state as UserDetails;
  type NavigationState = {
    contactData?: {
      name: string;
      email: string;
      mobile: string;
    };
    showAccountButton?: boolean;
  };
  // const navigate = useNavigate();  
  const { contactData, showAccountButton } = location.state as NavigationState || {}; // Use the defined type  
  const [dynamicFields, setDynamicFields] = useState({});
  const [initialDynamicFields, setInitialDynamicFields] = useState({});
  const [attachments, setAttachments] = useState([]);
              const [files, setFiles] = useState([])


              console.log(files, "filessss")

  const [restItems, setRestItems] = useState<RestItems>(initialRestItems);

  console.log(restItems, "restItemsmm")
  const [getUserData, setGetUserData] = useState<UserDetails>({
    id: null,
    name: "",
    email: "",
    phone_number: "",
    account: {},
    whatsapp_number:"",
    twitter_id: "",
    facebook_id: ""
  });

  const [accountName, setAccountName] = useState("");
  const [name, setName] = useState(userId?.name);
  const [email, setEmail] = useState(userId?.email);
  const [mobile, setMobile] = useState(userId?.phone_number);
  const [whatsapp, setWhatsapp] = useState<any>(userId?.whatsapp_number);
  const [twitter, setTwitter] = useState(userId?.twitter_id);
  const [facebook, setFacebook] = useState(userId?.facebook_id);
  const [tagsData, setTagsData] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [isSomethingAdded, setIsSomethingAdded] = useState(false);

  const [teamAdded, setTeamAdded] = useState([]);

  const [isContactCustomerHistoryVisible, setIsContactCustomerHistoryVisible] =
    useState(false);

  const [requiredFields, setRequiredFields] = useState<Set<string>>(new Set());

  const isAdmin = localStorage.getItem("user_role");

  const { data, refetch } = useContactsInfoDetailsQuery(id);
  const { data: tags } = useTagsQuery({});
  const { data: teams } = useTeamsQuery({});
  const [trigger] = useUpdateContactInfoDetailsMutation();

  const { data: dynamicData } = useContactDynamicFieldQuery({});
  const [deleteAttachTrigger] = useDeleteContactsAttachmentMutation()


  useEffect(() => {
    if (data?.dynamic_fields) {
      setDynamicFields(data?.dynamic_fields);
      setInitialDynamicFields(data?.dynamic_fields);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setRestItems(data as RestItems);
    }
  }, [data]);

  useEffect(() => {
    if (userId) {
      setGetUserData(userId);
      setEmail(userId?.email);
      setName(userId?.name);
      setMobile(userId?.phone_number);
      setAccountName(userId?.account?.name);
      setWhatsapp(userId?.whatsapp_number)
    }
  }, [userId]);

  useEffect(() => {
    if (tags) {
      setTagsData(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (teams) {
      setSelectedTeams(teams);
    }
  }, [teams]);

  useEffect(() => {
    if (dynamicData) {
      // Create a set of required field labels
      const requiredFieldLabels = dynamicData
        .filter((field) => field.is_required)
        .map((field) => field.label);
      setRequiredFields(new Set(requiredFieldLabels));
    }
  }, [dynamicData]);

  const handleInputChange = (key: string, value: any) => {
    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    setIsSomethingAdded(true);
    setErrorMessages((prevState) => ({
      ...prevState,
      [key]: "", // Clear error message
    }));
  };

  const tagSelectHandler = (tags: any) => {
    setIsSomethingAdded(true);

    setRestItems((prevState) => {
      const tagExists = prevState.tags.some((tag) => tag.id === tags.id);

      if (tagExists) {
        // If the selected tag already exists, remove it (clear the selection)
        return {
          ...prevState,
          tags: [],
        };
      } else {
        const newTag: Tag = { id: tags.id, name: tags?.name }; // Adjust based on your actual tag data
        return {
          ...prevState,
          tags: [newTag], // Keep only the new tag
        };
      }
    });
  };

  const handleTeamClick = (teamDetails: Team) => {
    setIsSomethingAdded(true);

    setRestItems((prevState) => {
      const teamExists = prevState.accessible_teams.some(
        (team) => team.id === teamDetails.id
      );

      if (teamExists) {
        return {
          ...prevState,
          accessible_teams: prevState.accessible_teams.filter(
            (team) => team.id !== teamDetails.id
          ),
        };
      } else {
        return {
          ...prevState,
          accessible_teams: [...prevState.accessible_teams, teamDetails],
        };
      }
    });
  };

  const isEditContactDetailsEnabled =
    localStorage.getItem("isEditContactDetailsEnable") === "true"
      ? true
      : false;

  const initialTeamIds = restItems.accessible_teams.map((team) => team.id);
  const mergedTeams = Array.from(new Set([...initialTeamIds, ...teamAdded]));

  const initialTagIds = restItems.tags.map((tags) => tags.id);
  const mergedTags = Array.from(new Set([...initialTagIds, ...selectedTags]));

  const validateForm = () => {
    const newErrors: ErrorState = {};

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (
        field?.is_required &&
        field.field_type === "drop-down" &&
        !fieldValue
      ) {
        newErrors[field.label] = `${field.label} is required`;
      }
      if (
        field?.is_required &&
        field.field_type === "check-box" &&
        !fieldValue
      ) {
        newErrors[field.label] = `${field.label} is required`;
      }

      // Example: Add phone number validation logic here
      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        // If you have specific phone validation, you could add it here
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  console.log(userId?.whatsapp_number, "pppppp")

  const saveButtonHandler = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();
    let isEmailValid = true;
    let emailError = "";

    const emailFieldLabel = "email";
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailValue = dynamicFields[emailFieldLabel]?.trim();

    if (emailValue && !emailPattern.test(emailValue)) {
      isEmailValid = false;
      emailError = "Invalid email address";
    }

    const errors = {};

    if (!isFormValid || !isEmailValid) {
      // Show specific error based on validation
      toast.error(emailError || "Please fill all required fields.");
      return;
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    let editedFields = Object.keys(dynamicFields).reduce((acc, key) => {
      const field = dynamicData.find((field) => field.label === key);

      const isFieldEditableForRole = isAdmin === "admin" || field?.is_editable;

      const initialFieldValue = initialDynamicFields[key];
      const currentFieldValue = dynamicFields[key];

      if (isFieldEditableForRole && currentFieldValue !== initialFieldValue) {
        if (field?.field_type === "date") {
          const formattedDate = new Date(currentFieldValue)
            .toISOString()
            .replace("Z", "+00:00");
          acc[key] = formattedDate;
        } else {
          acc[key] = currentFieldValue;
        }
      }

      return acc;
    }, {});

    const contactInfoObj = {
      accessible_teams: mergedTeams,
      dynamic_fields: editedFields,
      email: email,
      facebook_id: facebook,
      name: name,
      phone_number: mobile,
      tags: mergedTags,
      twitter_id: twitter,
      whatsapp_number: whatsapp,
      attachments: attachments?.filter((att) => !att.id),

    };

    try {
      const response = await trigger({ body: contactInfoObj, id: id });
      if (response?.data) {
        refetch();

        toast.success("Edited Successfully");
        setIsSomethingAdded(false);
        setFiles([])

        // contactInfoObj.dynamic_fields = {}
      } else if (response && "error" in response) {
        const errorData = response.error as any;
        toast.error(errorData.data?.error[0] || "An error occurred.");
      }
    } catch (error) {
      toast.error("Unable to edit");
    }
  };

  const capitalizeFirstLetter = (string: any) => {
    return (
      string &&
      string?.charAt &&
      string?.charAt(0)?.toUpperCase() + string.slice(1)
    );
  };

  const handleDropdownChange = (key, value) => {
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return "";
  };

  // Function to get current date-time formatted as 'YYYY-MM-DDTHH:mm'
  const getCurrentDateTimeFormatted = () => {
    const now = new Date();

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0");
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const openContactCustomerHistoryModal = () => {
    // setStoringCustomerHistoryIf(id);
    setIsContactCustomerHistoryVisible(true);
  };

  const closeContactCustomerHistoryModal = () => {
    setIsContactCustomerHistoryVisible(false);
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "65%",
    overflow: "hidden",
    bgcolor: "background.paper",
    border: "1px solid white",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const handleWhatsappChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only numbers or empty input
    // if (/^\d*$/.test(value)) {
      setWhatsapp(value);
      setIsSomethingAdded(true);
    // }
  };
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
};

 useEffect(() => {
            if (data) {
                setAttachments(data?.attachments);
            }
        }, [data]);

        console.log(attachments, "attachmemntntn")

     const handleDelete = async(id) => {
      const attId = {
        attachment_id: id
      }
      const response = await deleteAttachTrigger(attId)

      console.log(response, "responseee")

      if (response?.data) {
        setAttachments((prevAttachments) =>
          prevAttachments.filter((attachment) => attachment.id !== id)
      );
      }
      else {
        toast.error("Unable to delete an attachment")
      }

          
        };
    
        const handleDeleteFile = (fileToDelete) => () => {
          console.log(fileToDelete, "fielToDeletet")
            setAttachments((prevFiles) =>
                prevFiles.filter((file) => file !== fileToDelete)
            );
        };
    
        const handleFileChange = async (event) => {
            const newFiles = Array.from(event.target.files);
            console.log(newFiles, "newFilesss")
            const filePromises = newFiles?.map((file: any) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e: any) => {
                        const fileData = {
                            file_name: file.name,
                            file: e.target.result.split(",")[1], // Extracting Base64 part
                        };
                        resolve(fileData);
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                });
            });
    
            try {
                const fileDataArray = await Promise.all(filePromises);
                setAttachments((prevFiles) => [...prevFiles, ...fileDataArray]);
                setFiles((prevFiles) => [...prevFiles, ...fileDataArray])
    
            } catch (error) {
                console.error("Error reading files:", error);
                toast.error(
                    "An error occurred while uploading files. Please try again."
                );
            }
        };
//  const handleBackButtonClick = () => {
//     navigate("/contact/customer/contact", { state: { showAccountButton } }); // Pass the toggle state back
//   };
  return (
    <div className="cont" style={{ padding: "20px 10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="">
                    <button style={{marginLeft:'20px'}}
                        className="btn btn-link mb-2"
                        onClick={handleBackButtonClick}
                    >
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Back"
                            className="bi bi-arrow-90deg-left"
                            style={{
                                fontSize: "20px",
                                color: "black",
                                cursor: "pointer",
                            }}
                        ></i>
                    </button>
                </div>
        <div style={{ width: "95%", textAlign: "center" }}>
          <h3>Edit contact</h3>
        </div>
        <span
          style={{
            flex: "1",
            gap: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="text-hover-primary border-0 me-4"
            style={{ background: "transparent" }}
            onClick={openContactCustomerHistoryModal}
          >
            <i
              data-placement="top"
              title="Customer History"
              className="bi bi-file-earmark-spreadsheet text-dark fs-4"
              style={{
                cursor: "pointer",
              }}
            ></i>
          </button>
        </span>
      </div>

      <div className="contact_info-cont" style={{ paddingTop: "0" }}>
        <div className="account">
          <label>Account Name</label>
          <input
            disabled={!isEditContactDetailsEnabled}
            value={accountName ? accountName : restItems?.account?.name}
            className="form-control"
          />
        </div>
        <div className="account">
          <label className={requiredFields.has("name") ? "required" : ""}>
            Name
          </label>
          <input
            disabled={!isEditContactDetailsEnabled}
            className={`form-control ${requiredFields.has("name") ? "is-invalid" : ""
              }`}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              setIsSomethingAdded(true)
            
            }}
          />
        </div>
        <div className="account">
          <label className={requiredFields.has("email") ? "required" : ""}>
            Email
          </label>
          <input
            disabled={!isEditContactDetailsEnabled}
            className={`form-control ${requiredFields.has("email") ? "is-invalid" : ""
              }`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              setIsSomethingAdded(true)
            
            }}
          />
        </div>
        <div className="account">
          <label
            className={requiredFields.has("phone_number") ? "required" : ""}
          >
            Mobile No
          </label>
          <input
            disabled={!isEditContactDetailsEnabled}
            className={`form-control ${requiredFields.has("phone_number") ? "is-invalid" : ""
              }`}
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value)
              setIsSomethingAdded(true)
            
            }}
          />
        </div>
        <div className="account">
          <label>WhatsApp No</label>
          <input
        disabled={!isEditContactDetailsEnabled}
        className="form-control"
        type="text"
        onChange={handleWhatsappChange}
        value={whatsapp}
      />
        </div>
        <div className="account">
          <label>Twitter Id</label>
          <input
            disabled={!isEditContactDetailsEnabled}
            className="form-control"
            onChange={(e) => {
              setTwitter(e.target.value)
              setIsSomethingAdded(true)
            
            }}
            value={twitter}
          />
        </div>
        <div className="account">
          <label>Facebook Id</label>
          <input
            disabled={!isEditContactDetailsEnabled}
            className="form-control"
            onChange={(e) => {
              setFacebook(e.target.value)
              setIsSomethingAdded(true)
            
            }}
            value={facebook}
          />
        </div>
      </div>

      <div>
        <div
          style={{
            display: "grid",
            gap: "50px",
            padding: "0 20px",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
        >
          {dynamicData?.length > 0 &&
            dynamicData.map((field: any) => {
              const { id, field_type, label, choices } = field || {};
              const fieldKey = label || "";

              // Skip rendering if required properties are missing
              if (!id || !field_type || !label) return null;

              if (field_type === "drop-down") {
                return (
                  <div key={id} className="dynamic-field">
                    <label
                      className={
                        requiredFields && requiredFields.has(fieldKey)
                          ? "required"
                          : ""
                      }
                    >
                      {capitalizeFirstLetter(fieldKey)}
                    </label>
                    <select
                      disabled={
                        !isEditContactDetailsEnabled ||
                        isFieldDisabled(field?.is_editable)
                      }
                      // disabled
                      className="form-control"
                      value={dynamicFields[fieldKey] || ""}
                      onChange={(e) =>
                        handleDropdownChange(fieldKey, e.target.value)
                      }
                    >
                      <option value="">Select an option</option>
                      {choices?.map((choice: any) => (
                        <option key={choice?.id} value={choice?.choice}>
                          {choice?.choice}
                        </option>
                      ))}
                    </select>
                    {errors[fieldKey] && (
                      <div style={{ color: "red" }}>{errors[fieldKey]}</div>
                    )}
                  </div>
                );
              } else if (field_type === "tel-phone") {
                return (
                  <div key={id} className="dynamic-field">
                    <label
                      className={
                        requiredFields && requiredFields.has(fieldKey)
                          ? "required"
                          : ""
                      }
                    >
                      {capitalizeFirstLetter(fieldKey)}
                    </label>
                    <div
                      className="telephone-field"
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <PhoneInput
                        style={{ width: "100%" }}
                          defaultCountry="IN"
                        disabled={
                          !isEditContactDetailsEnabled ||
                          isFieldDisabled(field?.is_editable)
                        }
                        value={dynamicFields[fieldKey] || "+91"}
                        onChange={(phone: string) =>
                          handleInputChange(fieldKey, phone)
                        }
                      />
                    </div>
                    {errors[fieldKey] && (
                      <div style={{ color: "red" }}>{errors[fieldKey]}</div>
                    )}
                  </div>
                );
              } else if (field_type === "date-time") {
                return (
                  <div>
                    <label
                      className={requiredFields.has(fieldKey) ? "required" : ""}
                    >
                      {capitalizeFirstLetter(fieldKey)}
                    </label>

                    <input
                      disabled={
                        !isEditContactDetailsEnabled ||
                        isFieldDisabled(field?.is_editable)
                      }
                      className="form-control"
                      max={getCurrentDateTimeFormatted()} // Restrict to current date-time
                      value={getDataTimeFormatted(dynamicFields[fieldKey])}
                      type={field_type === "date-time" ? "datetime-local" : ""}
                      placeholder=""
                      onChange={(e) =>
                        handleInputChange(fieldKey, e.target.value)
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <div key={id} className="dynamic-field">
                    <label
                      className={
                        requiredFields && requiredFields.has(fieldKey)
                          ? "required"
                          : ""
                      }
                    >
                      {capitalizeFirstLetter(fieldKey)}
                    </label>
                    <input
                      className="form-control"
                      disabled={
                        !isEditContactDetailsEnabled ||
                        isFieldDisabled(field?.is_editable)
                      }
                      type={
                        // field_type === "date-time"
                        //     ? "datetime-local"
                        //     :
                        field?.is_masked
                          ? "text"
                          : field_type === "date"
                            ? "datetime-local"
                            : field_type === "email"
                              ? "email"
                              : field_type === "number"
                                ? "number"
                                : "text"
                      }
                      placeholder={
                        field_type === "email"
                          ? "Enter your email"
                          : field_type === "number"
                            ? "Number"
                            : ""
                      }
                      value={dynamicFields[fieldKey] || ""}
                      onChange={(e) =>
                        handleInputChange(fieldKey, e.target.value)
                      }
                    />
                    {errors[fieldKey] && (
                      <div style={{ color: "red" }}>{errors[fieldKey]}</div>
                    )}
                  </div>
                );
              }
            })}
        </div>
      </div>

      <div className="tags_teams-combo">
        {tagsData?.length > 0 && (
          <div className="tags_cont" style={{ padding: "0 20px" }}>
            <h4 className="heading">Tags</h4>
            <div className="tags_name" style={{ flexWrap: "wrap" }}>
              {tagsData?.length > 0 &&
                tagsData?.map((tags) => (
                  <p
                    onClick={() => tagSelectHandler(tags)}
                    key={tags?.id}
                    style={{
                      borderRadius: "50px",
                      padding: "7px 15px",
                      whiteSpace: "nowrap",
                      background:
                        restItems?.tags?.find((t) => t?.id === tags.id) ||
                          selectedTags.includes(tags?.id)
                          ? "rgb(141 146 149)"
                          : "white",

                      border:
                        restItems?.tags.find((t) => t?.id === tags?.id) ||
                          selectedTags.includes(teams.id)
                          ? "none"
                          : "1px solid rgb(211 211 211)",
                      color:
                        restItems?.tags?.find((t) => t?.id === tags.id) ||
                          selectedTags.includes(tags?.id)
                          ? "white"
                          : "black",
                      cursor: "pointer",
                    }}
                  >
                    {capitalizeFirstLetter(tags?.name)}
                  </p>
                ))}
            </div>
          </div>
        )}
        {selectedTeams?.length > 0 ? (
          <div className="teams_cont" style={{ padding: "0 20px" }}>
            <h4 className="heading">Teams</h4>
            <div className="teams_name" style={{ flexWrap: "wrap" }}>
              {selectedTeams?.map((teams) => (
                <p
                  onClick={() => handleTeamClick(teams)}
                  key={teams?.id}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50px",
                    padding: "7px 15px",
                    whiteSpace: "nowrap",
                    background:
                      restItems?.accessible_teams?.find(
                        (t) => t?.id === teams?.id
                      ) || teamAdded.includes(teams.id)
                        ? "rgb(141 146 149)"
                        : "white",
                    border:
                      restItems?.accessible_teams?.find(
                        (t) => t?.id === teams?.id
                      ) || teamAdded.includes(teams.id)
                        ? "none"
                        : "1px solid rgb(211 211 211)",
                    color:
                      restItems?.accessible_teams?.find(
                        (t) => t?.id === teams?.id
                      ) || teamAdded.includes(teams?.id)
                        ? "white"
                        : "black",
                  }}
                >
                  {capitalizeFirstLetter(teams?.name)}
                </p>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <AttachmentList attachments={attachments} onDelete={handleDelete}/>

            <div className="col-xl-6" style={{padding: "0 10px", }}>
                    
                        <div className="mt-2">
                            <Button
                            
                                component="label"
                                className="text-primary"
                                startIcon={<AttachFileIcon />}
                            >
                                Add Attachment
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </Button>
                            <Tooltip
                                title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
                                arrow
                            >
                                <IconButton
                                    aria-label="info"
                                    style={{
                                        verticalAlign: "middle",
                                        marginLeft: "11px",
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div> */}

                    {files.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                mt: 2,
                            }}
                        >
                            {files?.map((file, index) => (
                                <Chip
                                    key={index}
                                    label={file.file_name}
                                    onDelete={handleDeleteFile(file)}
                                />
                            ))}
                        </Box>
                    )}
      <div className="save_button">
        <button
          className="btn btn-primary"
          onClick={saveButtonHandler}
          disabled={!isSomethingAdded}
        >
          Save
        </button>
      </div>

      <Modal
        open={isContactCustomerHistoryVisible}
        onClose={closeContactCustomerHistoryModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style2, position: "absolute" }}>
          <IconButton
            aria-label="close"
            onClick={closeContactCustomerHistoryModal}
            sx={{
              position: "fixed",
              top: 16,
              right: 16,
              zIndex: 1301,
            }}
          >
            <GridCloseIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Customer History
          </Typography>

          <Box>
            <ContactCustomerHistory storingCustomerHistoryId={id} />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactInfo;
