import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import LoadingSpinner from "../../../LoadingSpinner";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableAccountField from "../DragAccountFields";
import {
  useBulkSortingMutation,
  useUserDynamicFieldsQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import DeleteModal from "../../../reusableComponent/DeleteModal";
import EditModal from "../../../reusableComponent/EditModal";
import AddFieldModal from "../AddFieldModal";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";

interface IPROPS {
  closeModal: any;
  refetch: any;
  contactFieldData: any;
}

interface LiftingDropData {
  choice?: string;
  fieldId?: string;
}

const NewOptionFieldModal = ({ closeModal }) => {
  return (
    <>
      <div className="d-flex flex-column align-items-end">
        <input type="text" placeholder="value" className="form-control" />
        <button className="btn btn-secondary mt-3 w-50" onClick={closeModal}>
          Add
        </button>
      </div>
    </>
  );
};

const ContactFieldsPane = () => {
  const [isAddAccountFieldsModalOpen, setIsAddAccountFieldsModalOpen] =
    useState(false);
  const [isAddNewOptionFieldModalOpen, setIsAddNewOptionFieldModalOpen] =
    useState(false);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
  const [isContactFieldModalOpen, setIsContactFieldModalOpen] = useState(false);
  const [storingContactData, setStoringContactData] = useState(null);

  const [contactFields, setContactFields] = useState([]);
  const [contactFieldData, setContactFieldData] = useState([]);
  const [liftingDropData, setLiftingDropData] = useState<LiftingDropData>({});

  const modelName = "contact";
  const { data, refetch, isLoading } = useUserDynamicFieldsQuery(modelName);

  const [isModified, setIsModified] = useState(false);
  const [triggerBulkSorting] = useBulkSortingMutation();

  useEffect(() => {
    if (data) {
      setContactFields(data);
    }
  }, [data]);

  const [expandForMoreSpecifications, setExpandForMoreSpecifications] =
    useState({ expansionOn: -1, expanded: false });

  const [editDropdownListItem, setEditDropdownListItem] = useState({
    enabled: false,
    forChoiceId: -1,
    currentVal: liftingDropData?.choice ? liftingDropData?.choice : "",
    fieldId: "",
  });

  useEffect(() => {
    if (data) {
      setContactFieldData(data);
    }
  }, [data]);

  const openAddAccountFieldModal = () => {
    setIsAddAccountFieldsModalOpen(true);
  };
  const closeAddAccountFieldModal = () => {
    setIsAddAccountFieldsModalOpen(false);
  };

  const openAddNewOptionField = () => {
    setIsAddNewOptionFieldModalOpen(true);
  };
  const closeNewOptionFieldModal = () => {
    setIsAddNewOptionFieldModalOpen(false);
  };
  const customModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "500px",
    },
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...contactFields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setContactFields(updatedFields);
    setIsModified(true); // Set isModified to true when the fields are modified
  };

  const closeModal = () => {
    setIsAddAccountFieldsModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteButtonClicked(false);
  };
  const closeEditFieldModal = () => {
    setIsContactFieldModalOpen(false);
  };

  const deleteDataHandler = async (field: any) => {
    setIsDeleteButtonClicked(true);
    setStoringContactData(field);
  };

  const editFieldsHandler = (fields: any) => {
    setIsContactFieldModalOpen(true);
    setStoringContactData(fields);
  };

  const handleBulkSorting = async () => {
    try {
      const payload = contactFields.map((field, index) => ({
        id: field.id,
        order_value: index + 1,
      }));
      await triggerBulkSorting({ body: payload });
      toast.success("Sequencing updated successfully");
      setIsModified(false); // Reset isModified to false after successful  sorting
    } catch (error) {
      toast.error("Error occurred during sequencing ");
    }
  };

  const deleteCustomModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      width: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const customModalStyles2: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      width: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{marginLeft:"3%"}}>
      {/* <div className="row" style={{ width: "101%" }}>
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
            Contact fields
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12" style={{ marginLeft: "5px" }}>
          <span
            className="input-group-text mt-2 bg-white border-0"
            id="basic-addon1"
          >
            <div className="ms-auto d-flex" style={{ width: "120px" }}>
              <button
                style={{ display: "flex", justifyContent: "center" }}
                className="input-group-text form-control form-control align-items-center text-hover-primary fw-bold"
                onClick={openAddAccountFieldModal}
              >
                <i className="bi bi-plus-lg me-2"></i>
                <span>Option</span>
              </button>
            </div>
          </span>
        </div>
        <ReactModal
          isOpen={isAddAccountFieldsModalOpen}
          onRequestClose={closeAddAccountFieldModal}
          style={customModalStyles}
          contentLabel="Add a new Account Field"
        ></ReactModal>
        <ReactModal
          isOpen={isAddNewOptionFieldModalOpen}
          onRequestClose={closeNewOptionFieldModal}
          style={customModalStyles}
          contentLabel="Add a new Account Field"
        >
          <NewOptionFieldModal closeModal={closeNewOptionFieldModal} />
        </ReactModal>
      </div> */}
 <div className="d-flex align-items-center" style={{ width: "100%", marginLeft: "-5px" ,paddingBottom:"1%"}}>
  <div style={{ flex: "1" }}>
    <span className="input-group-text mt-2" id="basic-addon1">
    Contact fields
    </span>
  </div>
  <div style={{paddingLeft:"21px"}}>
    <button
      style={{
        display: "flex",
        justifyContent: "center",
        width: "120px",
      }}
      className="input-group-text form-control align-items-center text-hover-primary fw-bold"
      onClick={openAddAccountFieldModal}
    >
      <i className="bi bi-plus-lg me-2"></i>
      <span>Option</span>
    </button>
  </div>
</div>
<ReactModal
          isOpen={isAddAccountFieldsModalOpen}
          onRequestClose={closeAddAccountFieldModal}
          style={customModalStyles}
          contentLabel="Add a new Account Field"
        ></ReactModal>
        <ReactModal
          isOpen={isAddNewOptionFieldModalOpen}
          onRequestClose={closeNewOptionFieldModal}
          style={customModalStyles}
          contentLabel="Add a new Account Field"
        >
          <NewOptionFieldModal closeModal={closeNewOptionFieldModal} />
        </ReactModal>
        
      <div className="row" style={{ maxHeight: "60vh" }}>
        {/* <div className="col" style={{ marginRight: "0px", marginLeft: "0" }}> */}
        {/* <div className="row"> */}
        <div className="row" style={{ paddingRight: "0" }}>
          <div
            className="col px-2"
            style={{ paddingRight: "5px", paddingLeft: "5px" }}
          >
            <div
              className="card px-2 my-1 min-w-600px"
              style={{
                backgroundColor: "#E4E4E47F",
              }}
            >
              <div className="row align-items-center py-4">
                <div className="col-4 text-start ps-lg-10 ps-4">
                  <strong>Label</strong>
                </div>
                <div
                  className="col text-start"
                  style={{ textAlign: "justify" }}
                >
                  <strong>Field Type</strong>
                </div>
                <div className="col text-center">
                  <strong>Mandatory</strong>
                </div>
                <div className="col text-center">
                  <strong>Unique</strong>
                </div>
                <div className="col text-center">
                  <strong>Masking</strong>
                </div>
                <div className="col text-center">
                  <strong>Editable</strong>
                </div>
                <div className="col text-end me-2">
                  <strong style={{ paddingRight: "20px" }}>Actions</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ maxHeight: "53vh", overflow: "scroll" }}>
          <div
            className="card min-w-600px mt-1"
            draggable="true"
            style={{ cursor: "grab", padding: "7px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "34%",
                  paddingRight: "0px",
                }}
              >
                <label>Name</label>
                <div></div>
              </div>
              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form form-label">Text</label>
                </div>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex"></div>
            </div>
          </div>

          <div
            className="card min-w-600px mt-1"
            draggable="true"
            style={{ cursor: "grab", padding: "7px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "34%",
                  paddingRight: "0px",
                }}
              >
                <label>Email</label>
                <div></div>
              </div>
              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form form-label">Email</label>
                </div>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex"></div>
            </div>
          </div>

          <div
            className="card min-w-600px mt-1"
            draggable="true"
            style={{ cursor: "grab", padding: "7px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "34%",
                  paddingRight: "0px",
                }}
              >
                <label>Mobile No.</label>
                <div></div>
              </div>
              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form form-label">Telephone</label>
                </div>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex"></div>
            </div>
          </div>
          <div
            className="card min-w-600px mt-1"
            draggable="true"
            style={{ cursor: "grab", padding: "7px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "34%",
                  paddingRight: "0px",
                }}
              >
                <label>Whatsapp No.</label>
                <div></div>
              </div>
              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form form-label">Telephone</label>
                </div>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex"></div>
            </div>
          </div>
          <div
            className="card min-w-600px mt-1"
            draggable="true"
            style={{ cursor: "grab", padding: "7px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "34%",
                  paddingRight: "0px",
                }}
              >
                <label>Twitter Id</label>
                <div></div>
              </div>
              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form form-label">Text</label>
                </div>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex"></div>
            </div>
          </div>

          <div
            className="card min-w-600px mt-1"
            draggable="true"
            style={{ cursor: "grab", padding: "7px" }}
          >
            <div className="row align-items-center">
              <div
                className="col-4 text-start ps-lg-10 ps-4"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "34%",
                  paddingRight: "0px",
                }}
              >
                <label>Facebook Id</label>
                <div></div>
              </div>
              <div
                className="col-2 d-flex align-items-end dropdown"
                style={{
                  textAlign: "justify",
                  justifyContent: "start",
                  width: "10%",
                }}
              >
                <div className="w-100 mw-150px">
                  <label className="form form-label">Text</label>
                </div>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-x"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-center">
                <i
                  className="me-4 fs-2 bi bi-check2"
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col text-end d-flex"></div>
            </div>
          </div>
          {/* </div> */}

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <DndProvider backend={HTML5Backend}>
              <div className="row">
                <div className="col" style={{ flex: 1, overflowY: "auto" }}>
                  {contactFields.map((field: any, index: any) => (
                    <DraggableAccountField
                      key={index}
                      field={field}
                      index={index}
                      moveField={moveField}
                      expandForMoreSpecifications={expandForMoreSpecifications}
                      setExpandForMoreSpecifications={
                        setExpandForMoreSpecifications
                      }
                      setEditDropdownListItem={setEditDropdownListItem}
                      editDropdownListItem={editDropdownListItem}
                      refetch={refetch}
                      editFieldsHandler={editFieldsHandler}
                      deleteDataHandler={deleteDataHandler}
                      setLiftingDropData={setLiftingDropData}
                    />
                  ))}
                </div>
              </div>
            </DndProvider>
          )}
        </div>
        <div className="text-end mt-4" style={{ alignSelf: "flex-end" }}>
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={handleBulkSorting}
            disabled={!isModified || isLoading} // Disable the button if there are no modifications or if it's loading
          >
            {isLoading ? (
              <>
                Saving... <Loader />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>

      <ReactModal
        isOpen={isAddAccountFieldsModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Add a new Tag"
      >
        <AddFieldModal
          closeModal={closeModal}
          refetch={refetch}
          modalName="contact"
        />
      </ReactModal>

      <ReactModal
        isOpen={isContactFieldModalOpen}
        onRequestClose={closeEditFieldModal}
        style={customModalStyles2}
      >
        <EditModal
          closeModal={closeEditFieldModal}
          setFieldsData={setContactFieldData}
          fieldsData={contactFieldData}
          storingFieldData={storingContactData}
          refetch={refetch}
        />
      </ReactModal>

      <ReactModal
        isOpen={isDeleteButtonClicked}
        onRequestClose={closeDeleteModal}
        style={deleteCustomModalStyles}
      >
        <DeleteModal
          closeModal={closeDeleteModal}
          setFieldsData={setContactFieldData}
          fieldsData={contactFieldData}
          storingFieldData={storingContactData}
          refetch={refetch}
        />
      </ReactModal>
    </div>
  );
};

export default ContactFieldsPane;
