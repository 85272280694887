import React, { useContext } from 'react'
// import UserContext from '../../context/UserContext'
import ChatContext from '../../context/ChatContext'
import { toast } from 'react-toastify'
import { createInitials } from '../../utils/functions/createInitials'

interface IProps {
  closeTransferConfirmModal: () => void
  userId: number
  userName: string
  chatRoomID: number
  setUpdateChatRoomsFlag: any
  chatRoomsArrayRef: any
}

const TransferChatConfirmModal: React.FC<IProps> = ({
  closeTransferConfirmModal,
  userId,
  userName,
  chatRoomID,
  setUpdateChatRoomsFlag,
  chatRoomsArrayRef
}) => {
  // const { currentUser } = useContext(UserContext)
  const { transferChat, getAssignedChatrooms, setTotalUnreadAssignedChats } =
    useContext(ChatContext)

  const handleTransfer = async () => {
    // const currentUserId = parseInt(currentUser.id)
        const currentUserId = 879
    await transferChat(chatRoomID, currentUserId, userId).then((res: any) => {
      closeTransferConfirmModal()
      if (!!res && res.messageResponse === 'SUCCESS') {
        toast.success('Successfully updated status')
        getAssignedChatrooms().then((resAssigned: any) => {
          //   setIsLoading(false)
          if (!!resAssigned) {
            console.warn(resAssigned)
            const assignedChatrooms = resAssigned.assigned_chatrooms
            let assignedChatRoomsArray = []
            if (assignedChatrooms[0] !== null) {
              assignedChatRoomsArray = assignedChatrooms[0]
                .filter((chatRoom: any) => !!chatRoom)
                .map((chatRoom: any) => {
                  let initials

                  if (chatRoom.chatRoomMeta && chatRoom.chatRoomMeta.length > 0) {
                    const metaName = chatRoom.chatRoomMeta.find(
                      (metaData: any) => metaData.name === 'Name'
                    )
                    if (metaName) {
                      initials = createInitials(metaName.value)
                    } else if (chatRoom.id) {
                      initials = createInitials(chatRoom.id)
                    } else {
                      initials = createInitials('Unnamed')
                    }
                  } else if (chatRoom.id) {
                    initials = createInitials(chatRoom.id)
                  } else {
                    initials = createInitials('Unnamed')
                  }
                  return {
                    ...chatRoom,
                    initials
                  }
                })
                .sort((a: any, b: any) => {
                  // Sort by putting last chatRoomID in first
                  return parseInt(b.id) - parseInt(a.id)
                })
            }
            console.log(assignedChatRoomsArray)
            setTotalUnreadAssignedChats(assignedChatRoomsArray.length)
            chatRoomsArrayRef.current = assignedChatRoomsArray
            setUpdateChatRoomsFlag(true)
            console.log('Set to call useEffect to load assigned chatRooms')

            // Remove chatRoomId from the URL if status is RESOLVED or SPAM
            // if (currentStatus === 'RESOLVED' || currentStatus === 'SPAM') {
            //   const searchParams = new URLSearchParams(window.location.search)
            //   searchParams.delete('chatRoomId')
            //   navigate(`${window.location.pathname}?${searchParams.toString()}`)
            //   // For React Router v6, use: navigate(`${window.location.pathname}?${searchParams.toString()}`);
            // }
          }
        })
      } else {
        toast.error('Unable to update status')
      }
    })
  }

  return (
    <div className='card h-110px w-400px'>
      <span className='fs-5 fw-bold'>
        Are you sure you want to transfer the chat to
        <span className='fs-5 fw-bold pb-6 text-primary'> {userName}</span> ?
      </span>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-secondary me-2' onClick={closeTransferConfirmModal}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleTransfer}>
          Transfer
        </button>
      </div>
    </div>
  )
}

export default TransferChatConfirmModal
