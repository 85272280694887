import React, { useState, useEffect } from "react";

import ReactModal from "react-modal";
import { useGetTemplatePredefinedListQuery } from "../../../../Services/campign/campignList";

import DeleteWhatAppModal from "../WhatsAppConfiguration/DeleteWhatAppModal";
import { ref } from "yup";
import LoadingSpinner from "../../../LoadingSpinner";
import { Pagination, PaginationItem } from "@mui/material";
const MetaTemplate = () => {
    const [openTemplateModal, setOpenTemplateModal] = useState<any>('');
    const [templateData, setTemplateData] = useState([])
    const [editFieldData, setEditFieldData] = useState([])
    const [fieldId, setFieldId] = useState()
    const [totalCount, setTotalCount] = useState<any>(0);
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [page, setPage] = useState(1)

    const [templateDataLoading, setTemplateDataLoading] = useState(false)
    const { data: templates, refetch, isLoading: templateLoading, status: templateStatus } = useGetTemplatePredefinedListQuery({})


    console.log(templates, "tesmllalla")
    const closeModal = () => {
        setOpenTemplateModal('');
    };

    useEffect(() => {
        if (templateStatus === "pending") {
            setTemplateDataLoading(true)
        }
        else {
            setTemplateDataLoading(false)
        }

    }, [templateStatus])

    console.log(templates, "tesmllalla")

    const defaultCompactFormModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            maxWidth: "850px", // Max width for larger screens
            // height: "30%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
            background: "#ffffff",
        },
    };


    useEffect(() => {
        if (templates) {
            setTemplateData(templates?.results)
            setTotalCount(templates?.count || 0);

        }

    }, [templates])

    const EditModal = (modal, item) => {
        setOpenTemplateModal(modal)
        setEditFieldData(item)

    }

    const deleteModal = (modal, item) => {
        setOpenTemplateModal(modal)
        setFieldId(item)
    }

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };


    return (
        <div style={{marginLeft:"3%"}}>
        <div className="col " style={{marginBottom: "30px",}}>
            <span className="input-group-text mt-2" id="basic-addon1">
              Meta template
            </span>
        </div>

            <div>
                {/* Title Added */}


                <div className="row" style={{ tableLayout: "fixed", borderCollapse: "collapse", paddingRight: "0px" }}>
                    <div className="col px-2" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                        <div className="card px-2 my-1 min-w-600px" style={{ backgroundColor: "#E4E4E47F" }}>
                            {/* Header */}
                            <div className="row align-items-center py-4">
                                <div className="col text-start ps-5">
                                    <strong>Template name</strong>
                                </div>

                                <div className="col text-center">
                                    <strong style={{marginLeft:"-6%"}}>Category</strong>
                                </div>
                                <div className="col text-center">
                                    <strong>Status</strong>
                                </div>
                                <div className="col text-center">
                                    <strong>Language</strong>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                {templateLoading || templateDataLoading ? <LoadingSpinner /> : (
                    <div className="row" style={{ tableLayout: "fixed", borderCollapse: "collapse", paddingRight: "0px" }}>
                        <div className="col px-2" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                            {templateData && templateData.map((item, index) => (
                                <div className="card px-2 my-1 min-w-600px" draggable="true" style={{ cursor: "grab", padding: "7px 0px" }} key={index}>
                                    {/* Header */}
                                    <div className="row align-items-center py-4">
                                        <div className="col text-start ps-5">
                                            <label>{item.template_name}</label>
                                        </div>

                                        <div className="col text-center">
                                            <label>{item.category}</label>
                                        </div>
                                        <div className="col text-center">
                                            <label>{item.status}</label>
                                        </div>
                                        <div className="col text-center">
                                            <label>{item.language}</label>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>


                    </div>
                )}
            </div>
        </div>
    );
};
export default MetaTemplate
