import React, { useRef, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const FirstResponse = () => {
  const chartContainerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);

      // Force ApexCharts to resize properly in fullscreen
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = async () => {
    try {
      if (!document.fullscreenElement && chartContainerRef.current) {
        await chartContainerRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error("Fullscreen mode failed", error);
    }
  };

  const options: any = {
    chart: {
      type: "bar",
      height: isFullscreen ? "100%" : 600,
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            {
              icon: isFullscreen
                ? '<i class="bi bi-fullscreen-exit"></i>'
                : '<i class="bi bi-arrows-fullscreen"></i>',
              title: isFullscreen ? "Exit Fullscreen" : "Fullscreen",
              class: "custom-fullscreen-icon mx-2",
              click: toggleFullscreen,
            },
          ],
        },
        export: {
          svg: { filename: "inbox_email" },
          png: { filename: "inbox_email" },
          csv: { filename: "inbox_email" },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "right",
      offsetX: 0,
      offsetY: 50,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
        stacked: true,
      },
    },
  };

  const series = [
    {
      name: "Within SLA",
      data: [90, 80, 70, 78, 83, 76, 72, 91],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
    },
    {
      name: "SLA breached",
      data: [20, 35, 40, 45, 59, 60, 65, 60],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
    },
  ];

  return (
    <div className="card card-flush overflow-hidden h-md-100">
      <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
        <div className="px-9 mb-5">
          <div className="d-flex mb-2">
            <span className="fs-2 fw-bold text-dark me-2">First response</span>
          </div>
          <div
            ref={chartContainerRef}
            style={{
              width: "100%",
              height: isFullscreen ? "100vh" : "600px",
              backgroundColor: "white",
              padding: isFullscreen ? "20px" : "0",
            }}
          >
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={isFullscreen ? "100%" : 600} // Adjust height
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstResponse;
