import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useActiveQuery } from "../../Services/activity/Active";
import { useState, useEffect } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  getBadgeClasses,
  getIconClasses,
} from "../../components/reusableComponent/IconAndBadgeClasses";
import ReactModal from "react-modal";
import TaskFilterModal from "./TaskFilterModal";
import { assign } from "lodash";

const customFilterModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%", // Default to full width
    maxWidth: "50px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const TodaysTask = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize] = useState<number>(10); // Fixed page size
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [isFilterOn, setIsFilterOn] = useState<boolean>(false);

  console.log(isFilterOn, "isFilterOn");

  const [isLoadingPageChange, setIsLoadingPageChange] =
    useState<boolean>(false);
  const [isActiveFilterModalOpen, setIsActiveFilterModalOpen] = useState(false);

  const [selectedActiveFilters, setSelectedActiveFilters] = useState<any>({
    id: "",
    email_id: "",
    assignee: "",
    created: "",
    channel: "",
    disposition_name: "",
  });

  const [selectedActiveFilters1, setSelectedActiveFilters1] = useState<any>(
    () => {
      const sessionData = sessionStorage.getItem("selectedActiveFilters");
      return sessionData
        ? JSON.parse(sessionData)
        : {
          id: "",
          email_id: "",
          assignee: "",
          created: "",
          channel: "",
          disposition_name: "",
        };
    }
  );

  const { data: ActiveData } = useActiveQuery({
    currentPage,
    selectedActiveFilters1,
    isFilterOn,
  });

  const rows =
    ActiveData?.results?.map((ticket: any) => ({
      id: ticket?.id,
      created: new Date(ticket?.created).toISOString().slice(0, 10),
      disposition_name: ticket?.disposition_name,
      current_assignee: ticket?.current_assignee || "Unassigned",
      email_id: ticket?.email_id,
      channel: ticket?.channel,
      task_source: ticket?.task_source,
    })) || [];

  // Update the total pages whenever ActiveData changes
  const totalPages = Math.ceil(ActiveData?.count / pageSize);

  useEffect(() => {
    if (ActiveData) {
      setLoading(false);
    }
  }, [ActiveData]);

  useEffect(() => {
    if (
      selectedActiveFilters1 &&
      Object.values(selectedActiveFilters1).some((value) => value !== "")
    ) {
      setIsFilterOn(true);
    } else {
      setIsFilterOn(false);
    }
  }, [selectedActiveFilters1]);

  const handleSubmit = (filterValues) => {
    setIsLoadingPageChange(true);
    setSelectedActiveFilters1(filterValues);
    setIsLoadingPageChange(false);
  };

  const openActiveFilterModal = () => {
    setIsActiveFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsActiveFilterModalOpen(false);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDateFromChange = (event: any) => {
    const newDateFrom = event.target.value;
    setDateFrom(newDateFrom);

    if (newDateFrom && dateTo && new Date(newDateFrom) > new Date(dateTo)) {
      toast.error("The 'From' date cannot be later than the 'To' date.");
    }
  };

  const handleDateToChange = (event: any) => {
    const newDateTo = event.target.value;
    setDateTo(newDateTo);

    if (newDateTo && dateFrom && new Date(newDateTo) < new Date(dateFrom)) {
      toast.error("The 'To' date cannot be earlier than the 'From' date.");
    }
  };

  const generatePaginationNumbers = () => {
    const numbers = [];
    const maxVisibleNumbers = 5;

    if (totalPages <= maxVisibleNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {
      const middleIndex = Math.ceil(maxVisibleNumbers / 2);
      let startPage = currentPage - middleIndex + 1;
      let endPage = currentPage + middleIndex - 1;

      if (startPage < 1) {
        endPage = maxVisibleNumbers;
        startPage = 1;
      } else if (endPage > totalPages) {
        startPage = totalPages - maxVisibleNumbers + 1;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const currentDate = new Date().toISOString().slice(0, 10); // Current date in YYYY-MM-DD format

  return (
    <>
      <div
        className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
        style={{ marginLeft: "30px" }}
      >
        <div className="d-flex align-items-center">
          <h2 style={{marginLeft:"-28px"}}>Today</h2>
        </div>
        <div className="d-flex align-items-center gap-2 ms-auto">
          <div className="w-100 mw-100px ms-auto mx-12">
            <Button
              variant="outlined"
              className="text-hover-primary fw-bold"
              onClick={openActiveFilterModal}
              sx={{
                height: 50,
                width: 140,
                border: "1px solid #ccc",
                gap: "5px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <i className="bi bi-filter text-dark fs-2" />
              <span
                className="ms-auto me-2 text-dark"
                style={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                Search filter
              </span>
            </Button>
          </div>

          <ReactModal
            isOpen={isActiveFilterModalOpen}
            onRequestClose={closeFilterModal}
            style={customFilterModalStyles}
            contentLabel="Active Filter Modal"
          >
            <TaskFilterModal
              closeFilterModal={closeFilterModal}
              selectedFilterAndValue={selectedActiveFilters}
              setSelectedFilterAndValue={setSelectedActiveFilters}
              handleSubmit={handleSubmit}
              setIsLoadingPageChange={setIsLoadingPageChange}
            />
          </ReactModal>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Ref id
              </TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Created date
              </TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Disposition
              </TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Current assignee
              </TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Email id
              </TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Channel
              </TableCell>
              <TableCell style={{ fontWeight: "600", fontSize: "14px" }}>
                Task source
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: "center" }}>
                  <LoadingSpinner />
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <div className="text-center">
                    <span className="fs-6 ">No data available</span>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  component={Link}
                  to={
                    row.task_source === "email"
                      ? `/email/inbox/${row.id}`
                      : row.task_source === "ticket"
                        ? `/ticket/information/${row.id}`
                        : null
                  }
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <TableCell></TableCell>
                  <TableCell>
                    {row.task_source === "email" ? " " : row.id}
                  </TableCell>
                  <TableCell>{formatDate(row.created)}</TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(row.disposition_name)}
                  </TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(row.current_assignee)}
                  </TableCell>
                  <TableCell>{row.email_id}</TableCell>
                  <TableCell title={row.channel} style={{paddingLeft:"28px"}}>
                    <div
                      className={`${getBadgeClasses(row.channel)}`}
                      style={{
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <i className={getIconClasses(row.channel)}></i>
                    </div>
                  </TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(row.task_source)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && !isFilterOn && (
        <div className="row mx-4 pt-2 mb-2 mt-3">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <span>
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <div className="dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li
                  className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button
                    type="button"
                    className="btn primary page-link"
                    onClick={prevPage}
                  >
                    <i className="previous"></i>
                  </button>
                </li>

                {generatePaginationNumbers().map((number) => (
                  <li
                    className={`paginate_button page-item ${currentPage === number ? "active" : ""
                      }`}
                    key={number}
                  >
                    <button
                      type="button"
                      className="btn primary page-link"
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))}

                <li
                  className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    type="button"
                    className="btn primary page-link"
                    onClick={nextPage}
                  >
                    <i className="next"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TodaysTask;
