import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Modal,
    Box,
    Button,
    Select,
    MenuItem,
    Checkbox,
    FormControl,
    InputLabel,
    Tooltip,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import { useGetBroadcastTemplatesQuery, useWhatsAppConfigMutation } from "../../Services/campign/campignList";
import { toast } from "react-toastify";
import { USER_TEAM_ID } from "../../constants";

interface IProps {
    closeBulkModal: () => void;
    selectedCampaign: any;
    refetch: any;
    campaignSelected: any
}

const BroadcastModal: React.FC<IProps> = ({
    closeBulkModal,
    selectedCampaign,
    refetch,
    campaignSelected
}) => {
    const [addUser, setAddUser] = useState(false);
    const [channel, setChannel] = useState<string>(""); // For channel selection
    const [isLoading, setIsLoading] = useState(false);
    const [getAllTemplates, setGetAllTemplate] = useState([])
    const [templateSelected, setTemplatedSelected] = useState(0)

    const [haveDetails, setHaveDetails] = useState(false)
    const [haveLeads, setHaveLeads] = useState(false)

    const getTeamId = localStorage.getItem(USER_TEAM_ID)


    const [whatsAppConfigTrigger, { status }] = useWhatsAppConfigMutation()
    const { data: broadCastMessageData, status: broadCastMessageStatus } = useGetBroadcastTemplatesQuery(getTeamId)

    useEffect(() => {
        if (status === "pending") {
            setIsLoading(true)
        }
        else {
            setIsLoading(false)
        }

    }, [status])

    useEffect(() => {
        if (broadCastMessageData) {
            setGetAllTemplate(broadCastMessageData?.results)

        }
    }, [broadCastMessageData])


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 120,
            },
        },
    };

    console.log(selectedCampaign.length > 0, "selectetetette")
    const assignHandler = async () => {
        setIsLoading(true);
        try {
            // const payload = {
            //     selectedCampaign,
            //     channel,
            //     broadcastToAll: selectedCampaign.length === 0, // Broadcast to all leads if no campaign selected
            // };

            const whatsAppConfigObj = {
                lead_ids: selectedCampaign ? selectedCampaign : [],
                campaign: campaignSelected,
                whatsapp_template: templateSelected
            }


            const response = await whatsAppConfigTrigger(whatsAppConfigObj)


            if (response?.data) {
                toast.success(selectedCampaign.length > 0 ? "Whatsapp message sent successfully" : "Whatsapp broadcast sent successfully")

            }
            else if (response?.error) {
                toast.error("Failed to send message")

            }

            refetch();
            closeBulkModal();

        } catch (error) {
            console.error("Error in broadcasting:", error);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        if (selectedCampaign.length > 1) {
            setHaveLeads(false)
        }
        else {
            setHaveLeads(true)
        }

    }, [selectedCampaign.length])


    const templateSelectHandler = (event) => {
        const value = event.target.value;
        setTemplatedSelected(value);
        // templateSelectHandler(value); // Call the handler
    };

    useEffect(() => {
        if (templateSelected !== 0 && channel.length > 0) {
            if (selectedCampaign.length > 0) {
                setHaveDetails(true)
            } else if (addUser) {
                setHaveDetails(true)
            } else {
                setHaveDetails(false)
            }
        }
        else {
            setHaveDetails(false)
        }
    }, [templateSelected, channel, addUser])


    return (
        <Modal
            open={true}
            onClose={closeBulkModal}
            aria-labelledby="broadcast-modal-title"
            aria-describedby="broadcast-modal-description"
        >
            <Box
                sx={{
                    padding: "1rem",
                    backgroundColor: "white",
                    margin: "auto",
                    width: "400px",
                    height: "350px",
                    borderRadius: "8px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    boxShadow: 24,
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={closeBulkModal}
                        style={{ minWidth: "auto", padding: "0.5rem" }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                <div>

                    <div className="text-center mb-4"><label className="form-label fs-4 fw-bolder text-dark card-title">Broadcast campaign</label></div>

                    <div className="mt-6" style={{ marginBottom: "15px" }}>
                        <InputLabel>Channel</InputLabel>
                        <FormControl variant="standard" className="full-width w-100 mt-4">
                            <Select
                                value={channel}
                                onChange={(e) => {
                                    setChannel(e.target.value)


                                }}
                                displayEmpty
                                style={{ paddingLeft: "1rem" }}
                            >
                                <MenuItem value="" disabled>
                                    Select Channel
                                </MenuItem>
                                <MenuItem value="whatsapp">WhatsApp</MenuItem>

                                {/* Add more channels if needed */}
                            </Select>
                        </FormControl>
                    </div>

                    <FormControl fullWidth>
                        {/* <InputLabel id="template-select-label">Templates</InputLabel> */}
                        <label>Select template</label>
                        <Select
                            labelId="template-select-label"
                            value={templateSelected}
                            onChange={templateSelectHandler}
                            displayEmpty
                            style={{ maxWidth: "100%" }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300, // Limit dropdown height
                                        overflowY: "auto",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                {/* <em>Select Template</em> */}
                            </MenuItem>
                            {getAllTemplates?.map((el, index) => (
                                <MenuItem key={index} value={el?.id}>
                                    <Tooltip title={el?.content} placement="top">
                                        <span
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                display: "block",
                                                maxWidth: "300px", // Adjust as needed
                                            }}
                                        >
                                            {el?.name || "NA"}
                                        </span>
                                    </Tooltip>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {selectedCampaign.length === 0 && ( // Hide "Select All" checkbox if campaigns are selected
                        <div className="mt-4">
                            <Checkbox
                                className="example-margin"
                                checked={addUser}
                                onChange={() => {
                                    setAddUser(!addUser)
                                    // setHaveLeads(true)
                                }}
                            />
                            Broadcast to all leads
                        </div>
                    )}
                </div>
                <div
                    style={{
                        position: "absolute",
                        bottom: ".6rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "90%",
                    }}
                >
                    <Button
                        className="btn btn-primary w-100"
                        onClick={assignHandler}
                        disabled={!haveDetails}
                    // disabled={!channel || (selectedCampaign.length > 0 && !addUser)}
                    >
                        {isLoading ? (
                            <>
                                Broadcasting... <Loader />
                            </>
                        ) : (
                            "Broadcast"
                        )}
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default BroadcastModal;
