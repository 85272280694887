import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useCreateTaskMutation } from "../../../Services/ticket/CreateTask";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTeamsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useGetSearchByEmailQuery } from "../../../Services/email/EmailTemplate";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Loader } from "react-bootstrap-typeahead";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import { useUsersByTeamIdQuery } from "../../../Services/ticket/users/UsersByTeamId";
import axios from "axios";
import SkeletonLoader from "../../SkeletonLoader";
import Skeleton from "react-loading-skeleton";






interface IProps {
    closeModal: any;
    ticket: any;
    createTask: any;
    refetchTask: any;
}

const CreateTaskModal: React.FC<IProps> = ({
    closeModal,
    ticket,
    createTask,
    refetchTask,
}) => {

      const [createTaskInfo, setCreateTaskInfo] = useState<{
        subject: string;
        attachments: { file_name: string; file: string }[];
        date: string;
        time: string;
        eventDate: string;
        EventDescription: string;
        Lead: string;
        reminderTime: string;
        StartTime: string;
        status: string;
        guests: any
      }>({
        subject: "",
        date: "",
        time: "",
        eventDate: "",
        EventDescription: "",
        Lead: "",
        reminderTime: "",
        StartTime: "",
        status: "",
        attachments: [],
        guests: []
      });

        const [haveValues, setHaveValues] = useState("");
        const [errorMessage, setErrorMessage] = useState(false);

        const [checkBoxSelected, setCheckboxSelected] = useState(false);
        const [taskFormLoader, setTaskFormLoader] = useState(false);
        const [selectedUser, setSelectedUser] = useState("");
          const [selectedContact, setSelectedContact] = useState<any>();
        
        const [users, setUsers] = useState([])

          console.log(selectedContact, "dscscdscdcsc")

  const fetchingTenant = window.location.hostname.split(".")[0];
  const apiPort = process.env.REACT_APP_API_PROTOCOL;


    const [inputValue, setInputValue] = useState("");
    const [queryParam, setQueryParam] = useState();
    const [emailOptions, setEmailOptions] = useState<any[]>([]);
    const [selectedContacts, setSelectedContacts] = useState<any[]>([]); 
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<any[]>([]); 


    const selectedTeam  = localStorage.getItem('teamId')
    const getUserID = localStorage.getItem("User_Id")



    const { data, error } = useGetSearchByEmailQuery(queryParam, {
        skip: !queryParam,
    });

     const { data: usersData, status: usersStatus } = useUsersByTeamIdQuery(
        selectedTeam,
        {
          skip: !selectedTeam,
        }
      );


    //   useEffect(() => {
    //     if (usersData?.length) {
    //         setUsers(usersData)
    //     }
    //   }, [usersData])

      useEffect(() => {
        if (usersData) {
          setUsers(usersData);
      
          // Find the user whose ID matches getUserID
          const matchedUser = usersData.find(user => String(user.id) === getUserID);
      
          // If a match is found, set it as the selected user
          if (matchedUser) {
            setSelectedUser(matchedUser.id);
          }
        }
      }, [usersData, getUserID]);


    const extractEmailOptions = (data: any) => {
        if (!data) return [];
        return data.map((item) => ({ label: item.email }));
    };

    useEffect(() => {
        if (data) {
            setEmailOptions(extractEmailOptions(data));
        }
    }, [data]);

    const handleInputChange = (event, newInputValue, setInputValue) => {
        if (event?.type === "click" || event?.type === "keydown") {
            setInputValue("");
            const emailOptionExists = emailOptions.some(
                (option) => option.label === newInputValue
            );
        }
        if (event?.type === "change") {
            const queryParams: any = {
                email: newInputValue,
            };

            setInputValue(newInputValue);
            setQueryParam(queryParams);
        }
    };

    const handleAddEmail = (email, setEmails, emails) => {
        if (email && isValidEmail(email)) {
            if (!emails.includes(email.trim())) {
                setEmails([...emails, email.trim()]);
                setInputValue("");
            }
        }
    };



    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };


    const handleSelectChange = (e) => {
        setSelectedUser(e.target.value);
      };

      const convertTo24Hour = (time: string): string => {
        const match = time.match(/(\d+):(\d+) (\w+)/);
        if (!match) return time; // Return as is if no match
    
        let [_, hour, minute, period] = match;
        let hr = parseInt(hour, 10);
        if (period === "PM" && hr !== 12) hr += 12;
        if (period === "AM" && hr === 12) hr = 0;
    
        return `${hr.toString().padStart(2, "0")}:${minute}`;
      };
    
      const convertTo12Hour = (time: string): string => {
        if (!time) return "";
        let [hour, minute] = time.split(":");
        let hr = parseInt(hour, 10);
        const period = hr >= 12 ? "PM" : "AM";
        hr = hr % 12 || 12; // Convert 0 to 12 AM
        return `${hr}:${minute} ${period}`;
      };

      const generateTimeSlots = () => {
        const times: string[] = [];
        for (let hour = 0; hour < 24; hour++) {
          for (let min of ["00", "30"]) {
            const ampm = hour < 12 ? "AM" : "PM";
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 0 to 12 for 12-hour format
            times.push(`${formattedHour}:${min} ${ampm}`);
          }
        }
        return times;
      };
    
      // Generate time slots
      const timeSlots = generateTimeSlots();

      const reminderTimeArry = [
        { label: "5 mins prior to task", id: "00:05:00" },
        { label: "15 mins prior to task", id: "00:15:00" },
        { label: "30 mins prior to task", id: "00:30:00" },
        { label: "60 mins prior to task", id: "01:00:00" },
      ];
    
      const StatusArry = [
        { label: "Active", id: "active" },
        { label: "Re-Scheduled", id: "rescheduled" },
        { label: "Cancelled", id: "cancelled" },
        { label: "Done", id: "done" },
      ];

      const deleteImageHandler = (index: number) => {
        setCreateTaskInfo((prevState) => {
          const updatedAttachments = prevState.attachments.filter(
            (_, i) => i !== index
          );
    
          // Update `haveValues` with the new list of file names
          const updatedFileNames = updatedAttachments
            .map((file) => file.file_name)
            .join(", ");
          setHaveValues(updatedFileNames);
    
          return {
            ...prevState,
            attachments: updatedAttachments,
          };
        });
      };

        const handleFileChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          const newFiles = Array.from(event.target.files as FileList); // Explicitly cast to FileList
      
          const filePromises = newFiles.map((file) => {
            return new Promise<{ file_name: string; file: string }>(
              (resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                  if (e.target?.result) {
                    const fileData = {
                      file_name: file.name, // No error because file is of type File
                      file: (e.target.result as string).split(",")[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                  } else {
                    reject(new Error("File reading error"));
                  }
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
              }
            );
          });
      
          try {
            const fileDataArray = await Promise.all(filePromises);
            setCreateTaskInfo((prevState) => ({
              ...prevState,
              attachments: [...prevState.attachments, ...fileDataArray],
            }));
            const fileNames = fileDataArray.map((file) => file.file_name).join(", ");
            setHaveValues(fileNames);
          } catch (error) {
            console.error(error);
            toast.error("An error occurred while uploading files. Please try again.");
          }
        };

        const stripHtmlTags = (html: string) => {
            return html.replace(/<\/?[^>]+(>|$)/g, ""); // Removes all HTML tags
          };

          const saveHandler = async (e) => {
            e.preventDefault();
        
            if (
              !createTaskInfo.subject ||
              !createTaskInfo.date ||
              !createTaskInfo.time ||
              !createTaskInfo.EventDescription ||
              !createTaskInfo?.status
            ) {
              toast.error("Fields are required");
              setErrorMessage(true);
              return;
            }

        
            const createTaskObj = {
              attachments: createTaskInfo?.attachments,
              created_by: selectedUser,
              event_date: createTaskInfo?.date,
              event_description: stripHtmlTags(createTaskInfo?.EventDescription),
              ticket: ticket?.id,
              reminder_time: createTaskInfo?.reminderTime,
              start_time: createTaskInfo?.time,
              status: createTaskInfo?.status,
              team: "",
              template_attachments: [],
              title: createTaskInfo?.subject,
              assignee: ticket?.assignee?.id,
              assigned_by: ticket?.assignee?.username,
              status_type: ticket?.status_type,
              dynamic_fields: {},
              static_fields: {},
              guests: selectedContacts
            };
        
            const response = await createTask(createTaskObj);
        
            try {
              if (response?.data) {
                toast.success("Added successfully");
                closeModal();
                refetchTask();
              } else if ("data" in response?.error) {
                const errorData = response?.error.data as {
                  status: string[];
                  message: string[];
                };
                const errorMessage = errorData.message[0] || errorData.status[0];
                toast.error(errorMessage);
              }
            } catch (error) {}
          };
        
          const searchContactByMailID = (skey: string) => {
            setLoading(true); // Set loading to true before the request
          
            axios
              .get(
                `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/search-by-email/`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
                  },
                  params: {
                    email: skey,
                  },
                }
              )
              .then((res: any) => {
                if (res.status === 200) setSuggestions(res.data);
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setLoading(false); // Set loading to false after request completes
              });
          };

          const handleAddGuest = (newValue: string) => {
            if (newValue && !selectedContacts.includes(newValue)) {
              setSelectedContacts((prev) => [...prev, newValue]); // Add email to array
            }
          };

          const handleRemoveGuest = (emailToRemove: string) => {
            setSelectedContacts((prev) => prev.filter((email) => email !== emailToRemove));
          };

    return (
         <div>
             <div style={{ display: "flex", width: "100%" }}>
               <span
                 style={{
                   width: "60%",
                   display: "flex",
                   justifyContent: "end",
                   fontSize: "17px",
                 }}
               >
                 Create task
               </span>
               <span
                 style={{
                   flex: "1",
                   display: "flex",
                   justifyContent: "end",
                 }}
               >
                 <i
                   className="bi bi-x"
                   style={{ fontSize: "20px", cursor: "pointer" }}
                   onClick={closeModal}
                 ></i>
               </span>
             </div>
             <div
               style={{
                 marginTop: "20px",
                 display: "flex",
                 gap: "10px",
                 flexDirection: "column",
               }}
               >
               <div style={{marginBottom: "5px"}}>
                 <label style={{ fontSize: "15px" }} className="required">
                   Subject
                 </label>
                 <input
                   value={createTaskInfo.subject}
                   className="form-control"
                   onChange={(e) => {
                     setCreateTaskInfo((prevState) => ({
                       ...prevState,
                       subject: e.target.value,
                     }));
                     setHaveValues(e.target.value);
                   }}
                 />
                 {errorMessage && !createTaskInfo.subject ? (
                   <p style={{ color: "red", margin: "0" }}>Field is required</p>
                 ) : (
                   ""
                 )}
               </div>
               <div style={{marginBottom: "5px"}}>
                 <label style={{ fontSize: "15px" }}>Task owner</label>
                 <select
                   className="form-control"
                   value={selectedUser} // Bind the state to the value of the select
                   onChange={handleSelectChange} // Update state on change
                 >
                   {/* Map over all users in the campaign data */}
                   {users?.map((user, index) =>
                       <option
                         key={user?.id}
                         value={user?.id || ""}
                       >
                         {user?.username || ""}
                       </option>
                   )}
                 </select>
               </div>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                 <div>
                   <label style={{ fontSize: "15px" }} className="required">
                     Date
                   </label>
                   <DatePicker
                     format="DD/MM/YYYY" // UI format
                     value={
                       createTaskInfo.date
                         ? dayjs(createTaskInfo.date, "YYYY-MM-DD")
                         : null
                     } // Convert stored date to UI format
                     onChange={(newValue) => {
                       setCreateTaskInfo((prevState) => ({
                         ...prevState,
                         date: newValue ? newValue.format("YYYY-MM-DD") : "", // Store in API format
                       }));
                     }}
                     slotProps={{
                       textField: {
                         fullWidth: true,
                         error: errorMessage && !createTaskInfo.date,
                         helperText:
                           errorMessage && !createTaskInfo.date
                             ? "Field is required"
                             : "",
                       },
                     }}
                   />
                   {errorMessage && !createTaskInfo.date && (
                     <p style={{ color: "red", margin: "0" }}>Field is required</p>
                   )}
                 </div>
                 <div style={{ marginTop: "10px" }}>
                   <label style={{ fontSize: "15px" }} className="required">
                     Time
                   </label>
                   <Autocomplete
                     freeSolo
                     options={timeSlots}
                     value={
                       createTaskInfo.time ? convertTo12Hour(createTaskInfo.time) : ""
                     } // Convert 24h to 12h for UI
                     onChange={(_, newValue) => {
                       setCreateTaskInfo((prevState) => ({
                         ...prevState,
                         time: newValue ? convertTo24Hour(newValue) : "", // Store as 24h format
                       }));
                     }}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         fullWidth
                         onChange={(e) => {
                           setCreateTaskInfo((prevState) => ({
                             ...prevState,
                             time: convertTo24Hour(e.target.value), // Convert typed input to 24h
                           }));
                         }}
                       />
                     )}
                   />
       
                   {errorMessage && !createTaskInfo.time && (
                     <p style={{ color: "red" }}>Field is required</p>
                   )}
                 </div>
               </LocalizationProvider>
               <div style={{ display: "flex", gap: "5px" }}>
                 <input
                   type="checkbox"
                   onChange={() => setCheckboxSelected(!checkBoxSelected)}
                 />
                 <label>Remind at</label>
               </div>
               {checkBoxSelected && (
                 <div
                   style={{
                     display: "flex",
                     gap: "10px",
                     flexDirection: "column",
                   }}
                 >
                   <div>
                     <label style={{ fontSize: "15px" }}>Select reminder minute</label>
                     <select
                       className="form-control"
                       value={createTaskInfo?.reminderTime}
                       onChange={(e) => {
                         setCreateTaskInfo((prevState) => ({
                           ...prevState,
                           reminderTime: e.target.value,
                         }));
                         setHaveValues(e.target.value);
                       }}
                     >
                       {reminderTimeArry?.map((time, index) => (
                         <option value={time.id} key={time.id}>
                           {time?.label}
                         </option>
                       ))}
                     </select>
                   </div>
                 </div>
               )}
               {/* <div>
                 <label style={{ fontSize: "15px" }} className="required">
                   Select status
                 </label>
                 <select
                   className="form-control"
                   value={createTaskInfo?.status}
                   onChange={(e) => {
                     setCreateTaskInfo((prevState) => ({
                       ...prevState,
                       status: e.target.value,
                     }));
                     setHaveValues(e.target.value);
                   }}
                 >
                   <option selected disabled value="">
                     Select status
                   </option>
                   {StatusArry?.map((status, index) => (
                     <option value={status?.id} key={status?.id}>
                       {status?.label}
                     </option>
                   ))}
                 </select>
                 {errorMessage && !createTaskInfo.status ? (
                   <p style={{ color: "red", margin: "0" }}>Field is required</p>
                 ) : (
                   ""
                 )}
               </div> */}

               <div>
      <label style={{ fontSize: '15px' }}>Guests</label>
      <Autocomplete
        freeSolo
        options={suggestions.map((option) => option.email)}
        onInputChange={(event, newInputValue) => {
          searchContactByMailID(newInputValue);
        }}
        onChange={(event, newValue) => {
          handleAddGuest(newValue as string);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className="form-control form-control-lg form-control-solid"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && (
                    <InputAdornment position="end">
                      <CircularProgress size={24} />
                    </InputAdornment>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      
      {/* Display added guests */}

      <div style={{ marginTop: '10px' }}>
        {selectedContacts.length > 0 && (
          <span style={{display: "flex", gap: "10px"}}>
            {selectedContacts?.map((email, index) => (
              <span key={index} style={{border:" 1px solid grey", width:"fit-content", borderRadius: "20px", padding: "2px 5px"}}>
                {email}
                <i className="bi bi-x"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => handleRemoveGuest(email)}
                >
                </i>
              </span>
            ))}
          </span>
        )}
      </div>

    </div>


               <div>
                <label style={{fontSize: "15px"}}>Description</label>
                 {/* <div> */}
                 <ReactQuill
                   theme="snow"
                   value={createTaskInfo?.EventDescription}
                   onChange={(e) => {
                     setCreateTaskInfo((prevState) => ({
                       ...prevState,
                       EventDescription: e,
                     }));
                     setHaveValues(e);
                   }}
                   // modules={modules}
                   // formats={formats}
                   // style={{ height: '150px' }}
                 />
                 {/* </div> */}
                 {errorMessage && !createTaskInfo?.EventDescription ? (
                   <p style={{ color: "red", margin: "0" }}>Field is required</p>
                 ) : (
                   ""
                 )}
               </div>
             </div>
             <div
               style={{
                 display: "flex",
                 flexDirection: "column",
                 gap: "5px",
                 marginTop: "10px",
               }}
             >
               {createTaskInfo?.attachments.map((fileName, index) => (
                 <div
                   style={{
                     display: "flex",
                     gap: "10px",
                     background: "#e3e0e0",
                     borderRadius: "5px",
                     padding: "5px",
                   }}
                 >
                   <span>
                     <i
                       className="bi bi-x-circle"
                       onClick={() => deleteImageHandler(index)}
                       style={{ color: "black" }}
                     ></i>
                   </span>
                   <span>{fileName?.file_name}</span>
                 </div>
               ))}
             </div>
       
             <div
               style={{
                //  display: "flex",
                //  justifyContent: "space-between",
                 marginTop: "20px",
                //  alignItems: "center  ",
               }}
             >
               {/* <div style={{ display: "flex", gap: "20px" }}>
                 <span
                   data-toggle="tooltip"
                   data-placement="top"
                   title="Campaign templates"
                 >
                   <i
                     className="fas fa-mail-bulk fs-3"
                     style={{ fontSize: "20px" }}
                   ></i>
                 </span>
                 <span
                   data-toggle="tooltip"
                   data-placement="top"
                   title="Add attachments"
                 >
                   <input
                     type="file"
                     style={{
                       opacity: 0,
                       position: "absolute",
                       width: "50px",
                       border: "1px solid black",
                     }}
                     // value={createTaskInfo?.attachments}
                     multiple
                     onChange={handleFileChange}
                   />
                   <i
                     className="bi bi-paperclip fs-2"
                     style={{ fontSize: "20px" }}
                   ></i>
                 </span>
               </div> */}
               <div style={{display: "flex", justifyContent: "end", width: "100%"}}>
                 <button
                   className="btn btn-primary"
                   onClick={saveHandler}
                   disabled={!haveValues}
                 >
                   {taskFormLoader ? (
                     <>
                       Saving...
                       <Loader />
                     </>
                   ) : (
                     "Save"
                   )}
                 </button>
               </div>
             </div>
           </div>
    );
};

export default CreateTaskModal;
