import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    OutlinedInput,
    Checkbox,
    ListItemText,
} from "@mui/material";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import EmptyRows from '../../../../components/reusableComponent/EmptyRows';

interface IProps {
    data: any;
}

const WhatsappReadLeads: React.FC<IProps> = ({data}) => {

    console.log(data, "datattaa")
    const navigate = useNavigate();
    const leadsByCampaign = data?.lead_stats?.whatsapp_read_leads?.leads_by_campaign;
    console.log(leadsByCampaign, "leadsbycampignn")
    const templates = leadsByCampaign ? Object.keys(leadsByCampaign[Object.keys(leadsByCampaign)[0]]?.whatsapp_leads_by_template || {}) : [];
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState<any>([]);
    const [chartData, setChartData] = useState<any>({
        options: {
            chart: {
                type: "line", toolbar: { show: false },
                events: {
                    click: (event: any, chartContext: any, config: any) => {
                        const firstKey = Object.keys(leadsByCampaign)[0];
                        const whatsappLeadsByTemplate = leadsByCampaign[firstKey]?.whatsapp_leads_by_template;
                        const names = config.config.series.map(item => item.name);

                        const filteredTemplates = Object.keys(whatsappLeadsByTemplate)
                            .filter(key => names.includes(key))
                            .reduce((obj, key) => {
                                obj[key] = whatsappLeadsByTemplate[key];
                                return obj;
                            }, {});

                        const result = Object.keys(filteredTemplates).flatMap(templateName => {
                            const dates = Object.keys(filteredTemplates[templateName]);
                            const selectedDate = dates[config.dataPointIndex];
                            const details = filteredTemplates[templateName][selectedDate];

                            if (details) {
                                return [{ templateName, date: selectedDate, details }];
                            }
                            return [];
                        });
                        openModal(result);
                    }
                }


            },
            xaxis: {
                categories: [], labels: { rotate: -45, style: { fontSize: "12px" } }, title: {
                    text: "Date", style: {
                        fontSize: "13px", // Adjust title font size
                        fontWeight: "normal"
                    },
                }
            },
            stroke: { curve: "smooth", width: 3 },
            markers: { size: 5 },
            legend: { position: "top" },
            tooltip: { enabled: true },
            yaxis: {
                labels: { formatter: (val) => Math.floor(val) },
                min: 0,
                forceNiceScale: true,
                title: {
                    text: "Lead Count", style: {
                        fontSize: "13px", // Adjust title font size
                        fontWeight: "normal"
                    },
                },
            },
        },
        series: [],
    });

    const [selectedTemplates, setSelectedTemplates] = useState<string[]>(templates);
    const [isAllSelected, setIsAllSelected] = useState(true);

    const openModal = (result) => {
        setModalData(result)

        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);

    };
    const handleTemplateChange = (event: any) => {
        const value = event.target.value;
        if (value.includes("all")) {
            const allSelected = !isAllSelected;
            setIsAllSelected(allSelected);
            setSelectedTemplates(allSelected ? templates : []);
        } else {
            const isNoneSelected = value.length === 0;
            setIsAllSelected(value.length === templates.length);
            setSelectedTemplates(isNoneSelected ? [] : value);
        }
    };

    useEffect(() => {
        if (leadsByCampaign) {
            const firstKey = Object.keys(leadsByCampaign)[0];
            const whatsappLeadsByTemplate = leadsByCampaign[firstKey]?.whatsapp_leads_by_template;

            if (whatsappLeadsByTemplate) {
                const dates = new Set<string>();
                Object.values(whatsappLeadsByTemplate).forEach((template: any) => {
                    Object.keys(template).forEach((date) => dates.add(date));
                });

                const sortedDates = Array.from(dates)
                    .sort()
                    .map((date) => date.split('-').reverse().join('-'));

                const filteredTemplates =
                    selectedTemplates.length > 0 ? selectedTemplates : [];

                const seriesData = filteredTemplates.length
                    ? filteredTemplates.map((templateName) => ({
                        name: templateName,
                        data: Array.from(dates)
                            .sort()
                            .map((date) => whatsappLeadsByTemplate[templateName]?.[date]?.count || 0),
                    }))
                    : [];

                setChartData((prev: any) => ({
                    ...prev,
                    options: {
                        ...prev.options,
                        xaxis: { ...prev.options.xaxis, categories: sortedDates },
                    },
                    series: seriesData,
                }));
            } else {
                setChartData((prev: any) => ({
                    ...prev,
                    series: [],
                }));
            }
        }
    }, [leadsByCampaign, selectedTemplates, isAllSelected]);


    const ITEM_HEIGHT = 60;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 200,
            },
        },
    };

    const noDataPresent = !leadsByCampaign || Object.keys(leadsByCampaign).length === 0;


    const allLeads = modalData?.flatMap(template => template.details.leads_details) || [];
    const allTemplateName = modalData?.flatMap(template => template.templateName) || [];

    return (
        <div className="card" style={{ height: "100%" }}>
            <div className="card-body pb-0 d-flex flex-column" style={{ minHeight: "420px" }}>
                <div className="d-flex justify-content-between align-items-center ">
                    <h5 className="card-title align-items-start flex-column">
                        <span className="fs-2 fw-bold text-gray-800 me-2 lh-1 ls-n2">Read messages</span>
                    </h5>
                    <div className="w-100 mw-200px">
                        <FormControl fullWidth>
                            <InputLabel id="template-select-label">Select template</InputLabel>
                            <Select
                                labelId="template-select-label"
                                id="template-select"
                                multiple
                                value={selectedTemplates}
                                onChange={handleTemplateChange}
                                MenuProps={MenuProps}
                                input={<OutlinedInput label="Select Templates" />}
                                renderValue={(selected) => (selected.length === 0 ? "None" : isAllSelected ? "All" : selected.join(", "))}
                            >
                                <MenuItem value="all">
                                    <Checkbox checked={isAllSelected} indeterminate={selectedTemplates.length > 0 && !isAllSelected} />
                                    <ListItemText primary="All" />
                                </MenuItem>
                                {templates.map((template) => (
                                    <MenuItem key={template} value={template} title={template}>
                                        <Checkbox checked={selectedTemplates.indexOf(template) > -1} />
                                        <ListItemText
                                            primary={
                                                <span
                                                    className="text-truncate d-inline-block"
                                                    style={{ maxWidth: "120px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={template}
                                                >
                                                    {template}
                                                </span>
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                </div>
                {noDataPresent ? (
                    <p className="text-center" style={{ flex: 1 }}>
                        <div className="row">
                            <div className="mixed-chart" style={{ height: "420px" }}>
                                <EmptyRows value="LeadDashboard" />
                            </div>
                        </div>
                    </p>
                ) : (
                    <div className="app" style={{ flex: 1 }}>
                        <div className="row">
                            <div className="mixed-chart">
                                <Chart options={chartData.options} series={chartData.series} type="line" height="420px" />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Modal
                open={modalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // This will center the modal both horizontally and vertically
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: 2, // Optional: Adds rounded corners to the modal
                        width: '40vw', // Optional: Controls the modal's width, can be set to a specific value like '400px'
                        maxHeight: '80vh', // Optional: Ensures the modal content is scrollable if content overflows
                        overflowY: 'auto', // Enables scrolling if content overflows vertically

                    }}
                >
                    {/* Close Icon in Top-Right Corner */}
                    <IconButton
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'gray', // You can adjust this color as needed
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 id="modal-modal-title">{`Read messages`}</h2>
                    <p id="modal-modal-description">
                        {allTemplateName.length > 0 ? allTemplateName.join(', ') : 'No templates available'}
                    </p>


                    {/* Table for account details */}
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Lead id</th>
                                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>First name</th>
                                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Email</th>
                                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Phone number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allLeads.length > 0 ? (
                                allLeads.map((lead, index) => (
                                    <tr
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => navigate(`/campaign/lead/${lead.id}`)}
                                    >
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{lead.id}</td>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{lead.first_name}</td>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{lead.email}</td>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{lead.phone_number}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td style={{ textAlign: 'center', padding: '8px' }}>No leads available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Box>
            </Modal>
        </div>
    );
}

export default WhatsappReadLeads