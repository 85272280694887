import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const frontEndCustomerApi = createApi({
  reducerPath: "frontEndCustomerApi",
  baseQuery: dynamicBaseQuery,
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    addAccount: builder.mutation({
      query: (body) => ({
        url: "v1/customers/accounts/",
        method: "POST",
        body,
      }),
    }),

    getAccount: builder.query({
      query: () => ({
        url: "v1/customers/accounts/",
        method: "GET",
      }),
    }),

    addContact: builder.mutation({
      query: (body) => ({
        url: "v1/customers/contacts/",
        method: "POST",
        body,
      }),
    }),

    userInfoDetails: builder.query({
      query: (id) => ({
        url: `v1/customers/accounts/${id}/`,
        method: "GET",
      }),
    }),
    contactsInfoDetails: builder.query({
      query: (id) => ({
        url: `v1/customers/contacts/${id}/`,
        method: "GET",
      }),
    }),

    updateContactsInfoDetails: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/customers/contacts/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    deleteContactsInfoDetails: builder.mutation({
      query: (id) => ({
        url: `v1/customers/contacts/${id.id}/`,
        method: "DELETE",
      }),
    }),

    deleteContactsAttachment: builder.mutation({
      query: (body) => ({
        url: `v1/customers/contacts/delete-attachment/`,
        method: "DELETE",
        body
      }),
    }),

    deleteAccountsAttachment: builder.mutation({
      query: (body) => ({
        url: `v1/customers/accounts/delete-attachment/`,
        method: "DELETE",
        body
      }),
    }),
    updateContactInfoDetails: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/customers/contacts/${id}/`,
          method: "PATCH",
          body,
        };
      },
    }),
    updateAccountInfoDetails: builder.mutation({
      query: ({ body, id }) => ({
        url: `v1/customers/accounts/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    getAccountInfo: builder.query({
      query: (id) => ({
        url: `v1/customers/contacts/?account=${id}&page=1&is_lead_contact=false&`,
        method: "GET",
      }),
    }),
    deleteAccountInfo: builder.mutation({
      query: (id) => {
        return {
          url: `v1/customers/accounts/${id?.id}/`,
          method: "DELETE",
        };
      },
    }),

    searchQuery: builder.query({
      query: (id) => ({
        url: `v1/customers/account-search/${id}`,
        method: "GET",
      }),
    }),

    searchContactQuery: builder.query({
      query: ({ search, page, id }) => ({
        url: `v1/customers/contact-search/${search}?page=${page}&page_size=20&ordering=${id}`,
        method: "GET",
      }),
    }),

    accessAccountAndTeam: builder.query({
      query: (id) => ({
        url: "v1/customers/accounts/?is_lead_account=false&",
        method: "GET",
      }),
    }),

    searchByAccount: builder.query({
      query: ({ id, page, accountId }) => ({
        url: `v1/customers/contacts/?account=${id}&page=${page}&is_lead_contact=false&ordering=${accountId}`,
        method: "GET",
      }),
    }),

    allAccounts: builder.query({
      query: (id) => ({
        url: `v1/customers/accounts/?is_lead_account=false&`,
        method: "GET",
      }),
    }),

    contactDynamicField: builder.query({
      query: (id) => ({
        url: `v1/common/dynamic-field-definitions/?model_name=contact&`,
        method: "GET",
      }),
    }),

    accountDynamicField: builder.query({
      query: (id) => ({
        url: `v1/common/dynamic-field-definitions/?model_name=account&`,
        method: "GET",
      }),
    }),

    searchByEmail: builder.query({
      query: (id) => ({
        url: `v1/customers/contacts/search-by-email/?email=${id}&`,
        method: "GET",
      }),
    }),

    contactHistory: builder.query({
      query: (id) => ({
        url: `v1/customers/actions/?contact__account=${id}&`,
        method: "GET",
      }),
    }),

    oldestcontactHistory: builder.query({
      query: (id) => ({
        url: `v1/customers/actions/?ordering=created&`,
        method: "GET",
      }),
    }),

    contactBulkInvite: builder.mutation({
      query: ({ body }) => ({
        url: `v1/customers/contacts/bulk-upload/`,
        method: "POST",
        body,
      }),
    }),

    accountquery: builder.query({
      query: ({ page, sort, liftingAccountFilterData }) => {
        let url = `v1/customers/accounts/?page=${page}&is_lead_contact=false`;

        if (sort) {
          url += `&ordering=${sort}`;
        }
        if (liftingAccountFilterData.filtering?.createdDate) {
          url += `&created__date=${liftingAccountFilterData.filtering?.createdDate}`;
        }
        if (liftingAccountFilterData.filtering?.name) {
          url += `&name__icontains=${liftingAccountFilterData?.filtering?.name}`;
        }
        if (liftingAccountFilterData.filtering?.tags) {
          url += `&tags__icontains=${liftingAccountFilterData?.filtering?.tags}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
    }),


    accountDownloadquery: builder.query({
      query: ({
        page,
        sort,
        liftingAccountFilterData,
        accountDownloadButtonClicked,
      }) => {
        let url = `v1/customers/accounts/?is_lead_contact=false`;

        if (!accountDownloadButtonClicked && page) {
          url += `&page=${page}`;
        }
        if (sort) {
          url += `&ordering=${sort}`;
        }
        if (liftingAccountFilterData.filtering?.createdDate) {
          url += `&created__date=${liftingAccountFilterData.filtering?.createdDate}`;
        }
        if (liftingAccountFilterData.filtering?.name) {
          url += `&name__icontains=${liftingAccountFilterData?.filtering?.name}`;
        }
        if (liftingAccountFilterData.filtering?.tags) {
          url += `&tags__icontains=${liftingAccountFilterData?.filtering?.tags}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    contactCustomerHistory: builder.query({
      query: (id) => ({
        url: `v1/customers/actions/?contact=${id}&`,
        method: "GET",
      }),
    }),

    contactquery: builder.query({
      query: ({ page, sort, liftingContactFilterData }) => {
        let url = `v1/customers/contacts/?page=${page}&is_lead_contact=false`;

        if (sort) {
          url += `&ordering=${sort}`;
        }
        if (liftingContactFilterData.account) {
          url += `&account=${liftingContactFilterData.account}`;
        }
        if (liftingContactFilterData.filtering?.createdDate) {
          url += `&created__date=${liftingContactFilterData.filtering?.createdDate}`;
        }
        if (liftingContactFilterData.filtering?.email) {
          url += `&email_id=${liftingContactFilterData?.filtering?.email}`;
        }
        if (liftingContactFilterData.filtering?.name) {
          url += `&name__icontains=${liftingContactFilterData?.filtering?.name}`;
        }
        if (liftingContactFilterData.filtering?.tags) {
          url += `&tags__name=${liftingContactFilterData?.filtering?.tags}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    contactqueryDownload: builder.query({
      query: ({
        page,
        sort,
        liftingContactFilterData,
        downloadButtonClicked,
      }) => {
        let url = `v1/customers/contacts/?is_lead_contact=false`;

        if (!downloadButtonClicked && page) {
          url += `&page=${page}`;
        }

        if (sort) {
          url += `&ordering=${sort}`;
        }

        if (liftingContactFilterData.account) {
          url += `&account=${liftingContactFilterData.account}`;
        }

        if (liftingContactFilterData.filtering?.createdDate) {
          url += `&created__date=${liftingContactFilterData.filtering?.createdDate}`;
        }

        if (liftingContactFilterData.filtering?.email) {
          url += `&email_id=${liftingContactFilterData?.filtering?.email}`;
        }

        if (liftingContactFilterData.filtering?.name) {
          url += `&name__icontains=${liftingContactFilterData?.filtering?.name}`;
        }

        if (liftingContactFilterData.filtering?.tags) {
          url += `&tags__name=${liftingContactFilterData?.filtering?.tags}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useDeleteAccountsAttachmentMutation,
  useDeleteContactsAttachmentMutation,
  useAccountDownloadqueryQuery,
  useContactqueryDownloadQuery,
  useContactqueryQuery,
  useAccountqueryQuery,
  useOldestcontactHistoryQuery,
  useUpdateContactsInfoDetailsMutation,
  useContactHistoryQuery,
  useSearchByEmailQuery,
  useGetAccountQuery,
  useAccountDynamicFieldQuery,
  useContactDynamicFieldQuery,
  useSearchContactQueryQuery,
  useAllAccountsQuery,
  useSearchByAccountQuery,
  useAccessAccountAndTeamQuery,
  useSearchQueryQuery,
  useDeleteAccountInfoMutation,
  useAddAccountMutation,
  useAddContactMutation,
  useUserInfoDetailsQuery,
  useContactsInfoDetailsQuery,
  useContactBulkInviteMutation,
  useUpdateContactInfoDetailsMutation,
  useDeleteContactsInfoDetailsMutation,
  useUpdateAccountInfoDetailsMutation,
  useGetAccountInfoQuery,
  useContactCustomerHistoryQuery,
} = frontEndCustomerApi;
