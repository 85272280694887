import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  PaginationItem,
} from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import { format } from "date-fns";
import "./clientStyles.scss";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { useNavigate } from "react-router-dom";
import {
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material";
import {
  useTicketPagination2Query,
  useTicketPaginationQuery,
  useUserTicketQuery,
} from "../../Services/ticket/TicketList";
import { useWorkflowQuery } from "../../Services/dashboard/dashboard";

interface IProps {
  ticket: any;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLiftingTicketData: any;
  setLiftingRefetchWorkFlow: any;
  setRefetchingData: any;
}

const ClientListView: React.FC<IProps> = ({
  ticket,
  currentPage,
  setCurrentPage,
  setLiftingTicketData,
  setLiftingRefetchWorkFlow,
  setRefetchingData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaderData, setLoaderData] = useState<boolean>(false);
  const sessionKeyPrefix = "branch";
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [data, setData] = useState<any>();

  const teamIdFromLocalStorage = localStorage.getItem("teamId");
  const { data: Ticket } = useUserTicketQuery(teamIdFromLocalStorage);

  // const [selectedWorkFlow, setselectedWorkFlow] = useState("");
  const [selectedWorkFlow, setselectedWorkFlow] = useState(
      sessionStorage.getItem("selectedWorkFlow1") || ""
    );
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const [stateChanged, setStateChanged] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<any>(0);

  const [selectedTeamId, setSelectedTeamId] = useState<any>(() => {
    const selectedTeamIdFromSession = sessionStorage.getItem(`${sessionKeyPrefix}selectedTeamId`);
    const userRole = localStorage.getItem("user_role");
    const userId = localStorage.getItem("User_Id");
  
    // If there's a selected team ID in sessionStorage, use it
    if (selectedTeamIdFromSession) {
      return selectedTeamIdFromSession;
    }
  
    // If the user role is admin, set to "My Team"
    if (userRole === "admin") {
      return "My Team";
    }
  
    // If the user role is agent or manager, set to the user ID from localStorage
    if (userRole === "agent" || userRole === "manager") {
      return userId;
    }
  
    // Fallback value if no conditions match
    return "My Team"; 
  });
  
  const [filterType, setFilterType] = useState("blank");
  const [selectedFilterAndValue, setSelectedFilterAndValue] = useState<any>({
    action__contact__email__icontains: "",
    action__assignee__username__icontains: "",
    title__icontains: "",
    created: "",
    id__icontains: "",
    merchant_id: "",
  });

  const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>({
    action__contact__email__icontains: "",
    action__assignee__username__icontains: "",
    title__icontains: "",
    created: "",
    id__icontains: "",
    merchant_id: "",
  });

  const { data: ticketWorkflow, refetch: refetchWorkflow } = useWorkflowQuery(
    selectedWorkFlow,
    {
      skip: !selectedWorkFlow,
    }
  );


  const {
    data: fetchedData,
    refetch,
    isLoading,
    status
  } = useTicketPagination2Query(
    {
      currentPage,
      selectedWorkFlow,
      selectedTeamId,
      selectedFilterAndValue1,
    },
    { skip: !selectedWorkFlow}
  );

  // useEffect(() => {
  //   if () {
      
  //   }

  // }, [])

 useEffect(() => {
  if (status === "pending") {
    setLoadingState(true)
  }
  else if (status === "fulfilled") {

    setLoadingState(false)

    setTotalCount(ticket?.count || 0);


  }
  else {
    setLoadingState(false)
  }

 }, [status, ticket?.count])

  // useEffect(() => {
  //   if (Ticket && Ticket?.results?.length > 0) {
  //     setselectedWorkFlow(Ticket?.results[0]?.id);
  //   }
  // }, [Ticket]);
 useEffect(() => {
    if (Ticket && Ticket?.results?.length > 0) {
      const firstWorkflowId = Ticket?.results[0]?.id;
      if (!selectedWorkFlow) {
        setselectedWorkFlow(firstWorkflowId);
        sessionStorage.setItem("selectedWorkFlow1", firstWorkflowId);
        console.log('Initial workflow set from Ticket results:', firstWorkflowId);
      } else {
        console.log('Workflow retained from sessionStorage:', selectedWorkFlow);
      }
    }
  }, [Ticket]);
  const handleTeamChange = (e: any) => {
    setStateChanged(true)
    setSelectedTeamId(e.target.value);
    sessionStorage.setItem(`${sessionKeyPrefix}selectedTeamId`, e.target.value);
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setStateChanged(true)
    setFilterType(selectedFilter);
    if (selectedFilter === "blank") {
      setSelectedFilterAndValue1({});
      refetch()
    } else {
      setSelectedFilterAndValue({
        action__contact__email__icontains: "",
        action__assignee__username__icontains: "",
        title_icontains: "",
        created: "",
        id__icontains: "",
        merchant_id: "",
        [getFilterField(selectedFilter)]: "", 
      });
    }
  };


  const handleDateChange = (event) => {
    setStateChanged(true)
    setSelectedFilterAndValue({
      ...selectedFilterAndValue,
      created: event.target.value,
    });
  };

  const getFilterField = (filterType) => {
    switch (filterType) {
      case "byCustomerName":
        return "action__contact__email__icontains";
      case "byAssignee":
        return "action__assignee__username__icontains";
      case "byTitle":
        return "title__icontains";
      case "byDate":
        return "created";
      case "byTicketId":
        return "id__icontains";
      case "byMerchantId":
        return "merchant_id";
      default:
        return "";
    }
  };


  const rowsPerPage = 5;


  const handleSearchChange = (event) => {
    if (filterType === "blank") {
      setSelectedFilterAndValue({});
     
    } else {
      setStateChanged(true)
      setSelectedFilterAndValue((prev) => ({
        ...prev,
        [getFilterField(filterType)]: event.target.value,

      }));
    }
  };

  // setTotalCount(data?.count || 0);
  

  const handleWorkflowChange = (event) => {
    setselectedWorkFlow(event.target.value);
    sessionStorage.setItem("selectedWorkFlow1", event.target.value); // Store the selected workflow in local storage
    console.log('Workflow changed:', event.target.value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    setCurrentPage(1);
  }, [setCurrentPage]);

  // useEffect(() => {

  //   if (ticket?.count) {
  //     setTotalPages(Math.ceil(ticket?.count / pageSize));
  //   }

  // }, [currentPage, ticket, pageSize]);

  const rows =
    ticket?.results?.map((ticket: any) => ({
      id: ticket?.id,
      title: ticket?.title,
      created: format(new Date(ticket?.created), "yyyy-MM-dd HH:mm"),
      status_type: ticket?.status_type,
      description: ticket?.description,
      assignee: ticket?.action?.assignee?.username,
      raisedBy: ticket?.created_by?.username,
      priority: ticket?.priority,
      category: ticket?.action?.disposition?.workflow?.name,
      dueDate: ticket?.dueDate,
    })) || [];

    const handlePageChange = (
      event: React.ChangeEvent<unknown>,
      value: number
  ) => {
      setCurrentPage(value);
  };


  const startEntry = (currentPage - 1) * pageSize + 1;
  const endEntry = Math.min(currentPage * pageSize, ticket?.count || 0);

  const handleRowClick = (id: string) => {
    navigate(`/client-login/${id}`);
  };


  useEffect(() => {
 
    if (fetchedData) {
      setData(fetchedData);
      // setLoaderData(false);
    }
  }, [fetchedData]);

  useEffect(() => {
    setLiftingTicketData(data);
  }, [data, setLiftingTicketData]);

  const handleSubmit = () => {

    setCurrentPage(1)
    setSelectedFilterAndValue1(selectedFilterAndValue);
  };

  useEffect(() => {
    setRefetchingData(() => refetch);
  }, [refetch, setRefetchingData]);


  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="table-headerr">
        <div className="filter-result"></div>
      </div>
      <div className="client-filter-cont">
        <div className="w-200 mw-200px">
          <FormControl style={{ width: "150px", zIndex: 0 }}>
            <InputLabel id="team-select-label" style={{ fontSize: "12px" }}>
              Select Assignee
            </InputLabel>
            <Select
              labelId="team-select-label"
              id="team-select"
              value={selectedTeamId}
              onChange={handleTeamChange}
              label="Select Teams"
            >
              <MenuItem key="all" value="My Team">
                My Team
              </MenuItem>
              <MenuItem key="all" value={localStorage.getItem("User_Id")}>
                Self
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-100 mw-150px">
          <FormControl style={{ width: "150px" }}>
            <InputLabel id="filter-select-label" style={{ fontSize: "13px" }}>
              Filter
            </InputLabel>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              value={filterType}
              label="Filter By"
              onChange={handleFilterChange}
            >
              <MenuItem value={"blank"}>Select a filter</MenuItem>
              <MenuItem value={"byCustomerName"}>Customer Email</MenuItem>
              <MenuItem value={"byAssignee"}>Assignee</MenuItem>
              <MenuItem value={"byTitle"}>Title</MenuItem>
              <MenuItem value={"byDate"}>Date</MenuItem>
              <MenuItem value={"byTicketId"}>Ticket ID</MenuItem>
              <MenuItem value={"byMerchantId"}>Merchant ID</MenuItem>
            </Select>
          </FormControl>
        </div>
        {filterType === "byDate" ? (
          <>
            <div className="w-100 mw-150px">
              <TextField
                id="date"
                label="Date"
                type="date"
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </>
        ) : (
          <div className="w-100 mw-200px" style={{ padding: "5px" }}>
            <TextField
              placeholder="Search..."
              onChange={handleSearchChange}
              value={selectedFilterAndValue[getFilterField(filterType)] || ""}
              style={{ padding: "2px" }}
            />
          </div>
        )}

        <div className="w-200 mw-200px" style={{ marginRight: "20px" }}>
          <FormControl style={{ width: "150px", zIndex: 0 }}>
            <InputLabel id="workflow-select-label">Select Workflow</InputLabel>
            <Select
              labelId="workflow-select-label"
              id="workflow-select"
              value={selectedWorkFlow}
              onChange={handleWorkflowChange}
              label="Select Workflow"
            >
              {/* Assuming 'Ticket' has a 'results' array with workflow options */}
              {Ticket &&
                Ticket?.results?.map((workflow) => (
                  <MenuItem key={workflow.id} value={workflow.id}>
                    {workflow.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <button
            style={{ padding: "0" }}
            className="btn btn-secondary d-flex justify-content-between align-items-center text-hover-dark fw-bold"
            onClick={handleSubmit}
            disabled={!stateChanged || filterType === "blank"}
          >
            {/* <i className="bi bi-search me-2"></i> */}
            <span className="ms-auto" style={{ padding: "8px 16px" }}>
              Search
            </span>
          </button>
        </div>
      </div>
      <div className="card card-flush">
        <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Ref ID
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Created at
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Assignee
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Raised by
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Priority
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Workflow
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingState || isLoading ? (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center" }}>
                      <LoadingSpinner />
                    </TableCell>
                  </TableRow>
                ) : rows?.length > 0 ? (
                  rows.map((row: any) => (
                    <TableRow
                      key={row.id}
                      style={{ width: "100%" }}
                      onClick={() => handleRowClick(row.id)}
                      className="cursor-pointer"
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{capitalizeFirstLetter(row.title)}</TableCell>
                      <TableCell>{row.created}</TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(row.status_type)}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: "150px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip
                          title={capitalizeFirstLetter(row?.description) || ""}
                          arrow
                        >
                          <span>{capitalizeFirstLetter(row?.description)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(row?.assignee)}
                      </TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(row?.raisedBy)}
                      </TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(row?.priority)}
                      </TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.dueDate}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center" }}>
                      <EmptyRows value={""} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  padding: "0 15px",
                }}
              >
                <div>
                  Showing {startEntry} - {endEntry} of {ticket?.count || 0}{" "}
                  entries
                </div>
                <Pagination
          sx={{
            display: "flex",
            justifyContent: "end",
            color: "blue",
          }}
          count={Math.ceil(totalCount / rowsPerPage)}
          page={currentPage}
          color="primary"
          onChange={handlePageChange}
          renderItem={(item) => {
            if (item.type === "page") {
              if (
                (currentPage === 1 && item.page <= 3) ||
                (currentPage === Math.ceil(totalCount / rowsPerPage) &&
                  item.page >= Math.ceil(totalCount / rowsPerPage) - 2) ||
                (item.page >= currentPage - 1 && item.page <= currentPage + 1)
              ) {
                return <PaginationItem {...item} />;
              }
              return null;
            }
            return <PaginationItem {...item} />;
          }}
        />
              </div>
            )}
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default ClientListView;
