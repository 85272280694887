import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactModal from 'react-modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useGetSearchByEmailQuery, useGetLookupByEmailQuery, useThreadsMutation, useGetTemplateQuery } from '../../Services/email/EmailTemplate';
import EmailTemplateModal from '../../components/email/EmailTemplateModal';
import ContactDetailsFill from '../../components/email/composeEmailModal/ContactDetailsFill';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner';
import AddNewTicketContactModal from '../../components/ticket/AddNewTicketContactModal';

const Compose = () => {
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showCCField, setShowCCField] = useState(false);
    const [showBccField, setShowBccField] = useState(false);
    const [emails, setEmails] = useState<string[]>([]);
    const [cc, setCc] = useState<string[]>([]);
    const [bcc, setBcc] = useState<string[]>([]);
    const [subject, setSubject] = useState<any>('')
    const [focused, setFocused] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalOpenContactDetailsFill, setIsModalOpenContactDetailsFill] = useState<boolean>(false);
    const [selectedTemplateDescription, setSelectedTemplateDescription] = useState<any>('');
    const [storeTemplateData, setStoreTemplateData] = useState<any>([]);
    const [editorHtml, setEditorHtml] = useState<any>();
    const [inputValue, setInputValue] = useState('');
    const [ccInputValue, setCcInputValue] = useState('');
    const [bccInputValue, setBccInputValue] = useState('');
    const [queryParam, setQueryParam] = useState();
    const [emailOptions, setEmailOptions] = useState<any[]>([]);
    const [lookupEmail, setLookupEmail] = useState('');
    const [emailNotPresent, setEmailNotPresent] = useState<any>('')
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [customLoading, setCustomLoading] = useState<any>(false)
    const [templateAttachment, setTemplateAttachment] = useState<any>()
    const [isAddNewContactModalOpen, setAddNewContactModalOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState<any>();
    const [storeNewEmailId, setStoreNewEmailId] = useState("");

    const { data, error, isLoading } = useGetSearchByEmailQuery(queryParam, {
        skip: !queryParam,
    });
    const { data: lookupEmailData, error: errorLookupEmail, isLoading: isLoadingLookupEmail } = useGetLookupByEmailQuery(lookupEmail, {
        skip: !lookupEmail,
    });
    const { data: getTemplate, error: getTemplateError, isLoading: getTemplateIsLoading } = useGetTemplateQuery({});
    const [thredsEmail] = useThreadsMutation()


    const handleFileChange = async (event) => {
        const newFiles = Array.from(event.target.files);
        const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileData = {
                        file_name: file.name,
                        file: e.target.result.split(',')[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        try {
            const fileDataArray = await Promise.all(filePromises);
            setFiles((prevFiles) => {
                return [...prevFiles, ...fileDataArray];
            });
        } catch (error) {
            toast.error("An error occurred while uploading files. Please try again.");
        }
    };


    // Extract email options from the data

    const openContactModal = () => {
        setAddNewContactModalOpen(true);
    };

    const closeContactModal = () => {
        setAddNewContactModalOpen(false);
    };


    const extractEmailOptions = (data) => {
        if (!data) return [];
        return data.map(item => ({ label: item.email }));
    };

    useEffect(() => {
        if (data) {
            setEmailOptions(extractEmailOptions(data));
        }
    }, [data]);

    useEffect(() => {
        if (getTemplate) {
            setStoreTemplateData(getTemplate)
        }
    }, [getTemplate])

    useEffect(() => {
        if (selectedTemplateDescription && selectedTemplateDescription.content) {
            setSubject(selectedTemplateDescription.subject || '');
            setEditorHtml(selectedTemplateDescription.content);
        }
    }, [selectedTemplateDescription]);



    const handleInputChange = (event, newInputValue, setInputValue) => {
        if (event?.type === 'click' || event?.type === 'keydown') {

            setInputValue('');
            const emailOptionExists = emailOptions.some(option => option.label === newInputValue);

            if (!emailOptionExists) {
                setEmailNotPresent(false)
                openContactModal()
                setStoreNewEmailId(event.target.value)
            }
        }
        if (event?.type === 'change') {
            const queryParams: any = {
                email: newInputValue
            };

            setInputValue(newInputValue);
            setQueryParam(queryParams);
        }
    };

    const handleAddEmail = (email, setEmails, emails) => {
        if (email && isValidEmail(email)) {
            if (!emails.includes(email.trim())) {
                setEmails([...emails, email.trim()]);
                setInputValue('');
            }
        }
    };

    const handleDeleteEmail = (emailToDelete, setEmails, emails) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: "600px"
        }
    };
    const customModalStyles1 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: "600px"
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const afterOpenModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleEditorChange = (content) => {
        setEditorHtml(content);
        setShowSaveButton(true); // Enable save button when content is updated
    };

    const openModalContactDetailsFill = () => {
        setIsModalOpenContactDetailsFill(true);
    };

    const afterOpenModalContactDetailsFill = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModalContactDetailsFill = () => {
        setIsModalOpenContactDetailsFill(false);
    };


    useEffect(() => {
        // Disable save button when the component is initially rendered
        setShowSaveButton(false);
    }, []);

    const toggleCCField = () => {
        setShowCCField(!showCCField); // Toggle CC field visibility
    };

    const toggleBccField = () => {
        setShowBccField(!showBccField); // Toggle BCC field visibility
    };
    useEffect(() => {
        if (isSubmitting) {
            if (lookupEmailData) {
                const queryParam = {
                    contact: lookupEmailData?.id,
                    original_subject: subject,
                    html_content: editorHtml,
                    to: emails,
                    bcc: bcc,
                    cc: cc,
                    attachments: files,
                    disposition: null,
                    status: null,
                    template_attachments: Array.isArray(templateAttachment) ? templateAttachment.map((attachment) => attachment.id) : [],
                };
                thredsEmail(queryParam)
                    .then(response => {
                        if (response) {
                            toast.success("Email sent successfully!");
                            // Reset form fields on successful email send
                            setCustomLoading(false);
                            setBcc([]);
                            setEmails([]);
                            setCc([]);
                            setEditorHtml('');
                            setSubject('');
                            setFiles([]);
                            setTemplateAttachment([]);
                            setIsSubmitting(false);

                        } else {
                            toast.error("Failed to send email. Please try again later.");
                            setCustomLoading(false);
                        }
                    })
                    .catch(error => {
                        console.error('Email send error:', error);
                        toast.error("An error occurred while sending the email. Please try again.");
                        setIsSubmitting(false);
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            } else {
                // Ensure we stop the submission process if preconditions are not met
                setIsSubmitting(false);
            }
        }
    }, [lookupEmailData]);

    const handleSubmit = () => {
        // Check if the subject field is empty
        if (subject.trim().length === 0) {
            return toast.error("Subject cannot be empty. Please provide a subject for the email.");
        }

        // Check if the email content is missing
        if (!editorHtml || editorHtml.trim().length === 0) {
            return toast.error("Email message cannot be empty. Please write the content of the email.");
        }

        // Ensure that there is at least one recipient
        if (emails.length === 0) {
            return toast.error("No recipients found. Please add at least one recipient to send the email.");
        }

        // Proceed if there are recipients and lookupEmailData is available
        const lastEmail = emails[emails.length - 1];
        setLookupEmail(lastEmail);
        setIsSubmitting(true);
        // if (typeof lookupEmailData === 'object' && lookupEmailData !== null && Object.keys(lookupEmailData).length === 0) {
        //     setCustomLoading(true);
        //     return setIsModalOpenContactDetailsFill(true);
        // }


    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'color',
        'align',
        'script',
        'background',
        'blockquote',
        'code-block',
        'header',
        'indent',
        'list',
        'script'
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }, { font: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'blockquote', 'code-block'],
            [{ color: [] }, { background: [] }, { align: [] }],
            ['clean']
        ]
    };


    const handleDeleteFile = (fileToDelete) => () => {
        setFiles((prevFiles) => prevFiles.filter(file => file !== fileToDelete));
    };

    const handleDownloadFile = (fileId) => {
        // Implement the download logic here
        // For example, using an API call to get the file or redirect to the file URL
        window.open(`${fileId}`, '_blank');
    };
    const handleDeleteAttachmentFile = (fileToDeleteId) => async () => {
        // Directly update the local state after the API call
        setTemplateAttachment((prev) => {
            // Filter out the attachment with the matching fileToDeleteId
            const updatedAttachments = prev.filter(
                (file) => String(file.id) !== String(fileToDeleteId)
            );

            return updatedAttachments;
        });
    };

    return (
        <div id="kt_app_content_container" className="app-container">
            <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between py-3">
                            <h2 className="card-title m-0">New Message</h2>
                        </div>
                        <div className="card-body p-0">
                            {isSubmitting ? <><LoadingSpinner /></> : <>

                                <div id="kt_inbox_compose_form">
                                    <div className="d-block">
                                        <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                            <div className="text-dark fw-bold w-50px">To:</div>
                                            <div style={{ width: '100%' }}>
                                                <Autocomplete
                                                    freeSolo
                                                    options={emailOptions}
                                                    getOptionLabel={(option: any) => option.label}
                                                    onChange={(event, value) => {
                                                        if (value && isValidEmail(value.label)) {
                                                            handleAddEmail(value.label, setEmails, emails);
                                                            setInputValue(''); // Clear the input
                                                        }
                                                    }}
                                                    inputValue={inputValue}
                                                    onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, setInputValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{ border: 'none' }} // Remove border
                                                            fullWidth
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleAddEmail(inputValue, setEmails, emails);
                                                                    setInputValue(''); // Clear the input
                                                                    e.preventDefault(); // Prevent form submission or default behavior
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <Stack direction="row" spacing={1} alignItems="center" flexWrap='wrap'>
                                                                        {emails.map((email, index) => (
                                                                            <Chip
                                                                                key={index}
                                                                                label={email}
                                                                                onDelete={() => handleDeleteEmail(email, setEmails, emails)}
                                                                                className="mat-chip-custom"
                                                                                style={{ marginLeft: 5 }}
                                                                            />
                                                                        ))}
                                                                    </Stack>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="w-75px text-end">
                                                <span className="text-muted fs-bold cursor-pointer text-hover-primary me-2" onClick={toggleCCField}>Cc</span>
                                                <span className="text-muted fs-bold cursor-pointer text-hover-primary" onClick={toggleBccField}>Bcc</span>
                                            </div>
                                        </div>
                                        {showCCField && (
                                            <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                                <div className="text-dark fw-bold w-50px">Cc:</div>
                                                <div style={{ width: '100%' }}>
                                                    <Autocomplete
                                                        freeSolo
                                                        options={emailOptions}
                                                        getOptionLabel={(option: any) => option.label}
                                                        onChange={(event, value) => {
                                                            if (value && isValidEmail(value.label)) {
                                                                handleAddEmail(value.label, setCc, cc);
                                                                setCcInputValue(''); // Clear the input
                                                            }
                                                        }}
                                                        inputValue={ccInputValue}
                                                        onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, setCcInputValue)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                fullWidth
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleAddEmail(ccInputValue, setCc, cc);
                                                                        setCcInputValue(''); // Clear the input
                                                                        e.preventDefault(); // Prevent form submission or default behavior
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        <Stack direction="row" spacing={1} alignItems="center" flexWrap='wrap'>
                                                                            {cc.map((email, index) => (
                                                                                <Chip
                                                                                    key={index}
                                                                                    label={email}
                                                                                    onDelete={() => handleDeleteEmail(email, setCc, cc)}
                                                                                    className="mat-chip-custom"
                                                                                    style={{ marginLeft: 5 }}
                                                                                />
                                                                            ))}
                                                                        </Stack>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="ms-auto w-75px text-end">
                                                    <span className="fs-bold cursor-pointer" onClick={toggleCCField}>Hide Cc</span>
                                                </div>
                                            </div>
                                        )}
                                        {showBccField && (
                                            <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                                <div className="text-dark fw-bold w-50px">Bcc:</div>
                                                <div style={{ width: '100%' }}>
                                                    <Autocomplete
                                                        freeSolo
                                                        options={emailOptions}
                                                        getOptionLabel={(option: any) => option.label}
                                                        onChange={(event, value) => {
                                                            if (value && isValidEmail(value.label)) {
                                                                handleAddEmail(value.label, setBcc, bcc);
                                                                setBccInputValue(''); // Clear the input
                                                            }
                                                        }}
                                                        inputValue={bccInputValue}
                                                        onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, setBccInputValue)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                fullWidth
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleAddEmail(bccInputValue, setBcc, bcc);
                                                                        setBccInputValue(''); // Clear the input
                                                                        e.preventDefault(); // Prevent form submission or default behavior
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        <Stack direction="row" spacing={1} alignItems="center" flexWrap='wrap'>
                                                                            {bcc.map((email, index) => (
                                                                                <Chip
                                                                                    key={index}
                                                                                    label={email}
                                                                                    onDelete={() => handleDeleteEmail(email, setBcc, bcc)}
                                                                                    className="mat-chip-custom"
                                                                                    style={{ marginLeft: 5 }}
                                                                                />
                                                                            ))}
                                                                        </Stack>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="ms-auto w-75px text-end">
                                                    <span className="fs-bold cursor-pointer" onClick={toggleBccField}>Hide Bcc</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* begin::Subject */}
                                    <div className="border-bottom">
                                        <input className="form-control form-control-transparent border-0 px-8 min-h-45px" name="compose_subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                    {/* end::Subject */}
                                    {/* begin::Message */}
                                    <div className='card-body p-5'>
                                        <ReactQuill
                                            theme='snow'
                                            value={editorHtml}
                                            onChange={handleEditorChange}
                                            modules={modules}
                                            formats={formats}
                                            style={{ height: '200px' }}

                                        />
                                    </div>
                                    <div className='mt-5 p-5'>
                                        {files.length > 0 && (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                                                {files.map((file, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={file.file_name}
                                                        onDelete={handleDeleteFile(file)}
                                                    />
                                                ))}
                                            </Box>

                                        )}


                                        {templateAttachment?.length > 0 && (

                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                                                {templateAttachment?.map((file, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={file.file_name}
                                                        onClick={() => handleDownloadFile(file.file)}
                                                        onDelete={handleDeleteAttachmentFile(file.id)}
                                                        sx={{ cursor: 'pointer' }} // Adds a pointer cursor on hover
                                                    />
                                                ))}
                                            </Box>

                                        )}
                                    </div>
                                    {/* end::Message */}
                                    {/* begin::Footer */}
                                    <div className="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top mt-5">
                                        <div className="d-flex align-items-center me-3">
                                            <div className="btn-group me-4">
                                                <button className="btn btn-primary fs-bold px-6" data-kt-inbox-form="send" onClick={handleSubmit}>
                                                    <span className="indicator-label">Send</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                                </button>
                                            </div>
                                            <label htmlFor="attachFileInput" className="btn btn-active-light-primary btn-icon" title="You can add attachments for the following 
                                        file types: xls,pdf,csv,doc,jpg,png upto maximum size of 15MB
            ">
                                                <i className='bi bi-paperclip fs-2'></i>
                                                <input type="file" id="attachFileInput" className="d-none" multiple
                                                    onChange={handleFileChange} />
                                            </label>
                                            <div className="btn btn-active-light-primary btn-icon fs-bold px-6" onClick={openModal}>
                                                <label title='Template'>
                                                    <i className='fas fa-mail-bulk fs-3'></i>
                                                </label>
                                            </div>
                                            <ReactModal
                                                isOpen={isModalOpen}
                                                onAfterOpen={afterOpenModal}
                                                onRequestClose={closeModal}
                                                style={customModalStyles}
                                                contentLabel='Email templates'
                                            >
                                                <EmailTemplateModal closeModal={closeModal} setSelectedTemplateDescription={setSelectedTemplateDescription} storeTemplateData={storeTemplateData} setTemplateAttachment={setTemplateAttachment} />
                                            </ReactModal>


                                            <ReactModal
                                                isOpen={isAddNewContactModalOpen}
                                                onRequestClose={() => setAddNewContactModalOpen(false)}
                                                style={customModalStyles}
                                                contentLabel="New Contact"
                                            >
                                                <AddNewTicketContactModal
                                                    closeModal={() => setAddNewContactModalOpen(false)}
                                                    emailNotPresent={emailNotPresent}
                                                    setSelectedContact={setSelectedContact}
                                                    storeNewEmailId={storeNewEmailId}
                                                    setEmailNotPresent={setEmailNotPresent}
                                                />
                                            </ReactModal>
                                        </div>
                                    </div>
                                    {/* end::Footer */}
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Compose;
