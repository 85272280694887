import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import NewScheduleModal from "./NewScheduleModal";
import { useTicketScheduleMutation, useTicketSchedulesMutation } from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import { defaultDeleteDialogModalStyles, defaultScrollableModalStyles } from "../../../common/CustomModalStyles";
import DeleteScheduleModal from "./DeleteScheduleModal";
import { getIconClasses } from "../../../reusableComponent/IconAndBadgeClasses";

const Scheduler = () => {
  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsQuery({});
  const [isNewScheduleModalOpen, setIsNewScheduleModalOpen] = useState(false);
  const [isDeleteScheduleModalOpen, setIsDeleteScheduleModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<number>(0);
  const [theTeams, setTheTeams] = useState([]);
  const [theSchedules, setTheSchedules] = useState([]);
  const [scheduleForEdit, setScheduleForEdit] = useState<any>({ scheduleEditEnabled: false, scheduleData: null });
  const [scheduleForDeletion, setScheduleForDeletion] = useState<number>();
  const [autoTicketSchedulerToggler, setAutoTicketSchedulerToggler] = useState(false);
  const [triggerGetTicketSchedules] = useTicketSchedulesMutation();
  const [triggerGetATicketSchedule] = useTicketScheduleMutation();

  const openNewScheduleModal = () => { setIsNewScheduleModalOpen(true); };
  const closeNewScheduleModal = () => { setIsNewScheduleModalOpen(false); setScheduleForEdit({ ...scheduleForEdit, scheduleEditEnabled: false }); fetchListOfSchedules(); };
  const openDeleteScheduleModal = () => { setIsDeleteScheduleModalOpen(true); };
  const closeDeleteScheduleModal = () => { setIsDeleteScheduleModalOpen(false); fetchListOfSchedules(); };

  const handleAutoTicketSchedulerTogglerChange = () => { setAutoTicketSchedulerToggler(!autoTicketSchedulerToggler); };

  const handleFirstDropdownChange = (event) => { setSelectedTeam(event.target.value); };

  const tableHeaders = [
    {
      label: "Title",
      key: "title",
    },
    {
      label: "Contact",
      key: "Contact",
    },
    {
      label: "Channel",
      key: "Channel",
    },
    {
      label: "Frequency",
      key: "Frequency",
    },
    {
      label: "Scheduler time",
      key: "Scheduler time",
    },
  ];

 

  function stripFields(jsonObj, fieldsToRemove) {
    for (let field of fieldsToRemove) {
      delete jsonObj[field];
    }
    return jsonObj;
  }

  async function fetchOneSchedule(scheduleId: number) {
    triggerGetATicketSchedule(scheduleId)
      .then((res: any) => {
        if (res.data) {
          const strippedEditData = stripFields({ ...res.data }, [ "day_of_week", "day_of_month"]);
          setScheduleForEdit({ scheduleEditEnabled: true, scheduleData: strippedEditData })
          openNewScheduleModal();
        }
      })
      .catch((err) => console.log(err));
  }

  async function deleteSchedule(scheduleId: number) {
    setScheduleForDeletion(scheduleId);
    openDeleteScheduleModal();
  }

  async function fetchListOfSchedules() { triggerGetTicketSchedules(selectedTeam).then((res: any) => setTheSchedules(res.data)).catch(err => console.log(err)) }

  useEffect(() => { setTheTeams(teamsData); }, [teamsData]);
  useEffect(() => { fetchListOfSchedules(); }, [selectedTeam]);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "850px", // Max width for larger screens
      height: "80%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  useEffect(() => {

    if (theSchedules.length !== 0) {
      setAutoTicketSchedulerToggler(true);

    } else {
      setAutoTicketSchedulerToggler(false)
    }

  }, [theSchedules])

  return (
    <div className="row gap-5 container-fluid" style={{marginLeft:"2%"}}>

      <div className="row align-items-center">
        <div className="col-md-2" style={{marginLeft:"1%"}}>
          <label>Select team</label>
          <select
            className="form-select select2-hidden-accessible"
            onChange={handleFirstDropdownChange}
            value={selectedTeam}
          >
            <option value="">Select team</option>
            {theTeams && theTeams.map((team, index) => (<option value={team.id}>{team.name}</option>))}
          </select>
        </div>

        {selectedTeam !== 0 && (
          <>
            <div className="col-md-7 d-flex align-items-center gap-5 mt-4 mt-lg-0">
              <label className="me-3">Auto ticket scheduler</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={autoTicketSchedulerToggler}
                  onChange={handleAutoTicketSchedulerTogglerChange}
                />
              </div>
            </div>
            <div className={`col-md-3 d-flex align-items-center justify-content-end ${autoTicketSchedulerToggler ? '' : 'd-none'} mt-4 mt-lg-0`} style={{marginLeft:"74%"}}>
              <div>
                <button
                  className="form-control form-control fw-bold"
                  onClick={openNewScheduleModal}
                >
                  <i className="bi bi-plus fs-2 me-2"></i>
                  <span>
                  New schedule
                  </span>
                 
                </button>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 mx-auto">
                  <ReactModal
                    isOpen={isNewScheduleModalOpen}
                    onRequestClose={closeNewScheduleModal}
                    style={customModalStyles}
                    contentLabel="New Ticket"
                  >
                    <NewScheduleModal closeNewScheduleModal={closeNewScheduleModal} scheduleForEdit={scheduleForEdit} selectedTeam={selectedTeam} />
                  </ReactModal>
                </div>
              </div>
            </div>
          </>
        )}
      </div>


      {selectedTeam !== 0 && (
        <>
          <div className="row">
            <div className="col">
              <div className="min-w-600px">
                {/* The Table Header */}
                <div
                  className="card mb-n5 my-1 p-4 mx-4"
                  style={{ backgroundColor: "#E4E4E47F" }}
                >
                  <div className="row align-items-center">
                    {tableHeaders.map((header, index) => (
                      <div className="col-2 text-start" key={index}>
                        <strong >{header.label}</strong>
                      </div>
                    ))}
                    <div className="col-1 text-center">
                      <strong>Action</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="min-w-600px">
                {/* Render cards */}
                {theSchedules && theSchedules.map((theSchedule, index) => (
                  <div className="card mt-2 my-1 p-4 mx-4" key={index}>
                    <div className="row align-items-center">
                      <div className="col-2 text-start">
                        <label htmlFor="">{theSchedule.title}</label>
                      </div>
                      <div className="col-2 text-start">
                        <label htmlFor="">{theSchedule.contact[0].name}</label>
                      </div>
                      <div className="col-2 text-start">
                       
                          <i className={getIconClasses(theSchedule.ticket_channel)}></i>
                     
                      </div>
                      <div className="col-2 text-start">
                        <label htmlFor="">{theSchedule.schedule_timer}</label>
                      </div>
                      <div className="col-2 text-start">
                        <label htmlFor="">{theSchedule.daily_time}</label>
                      </div>
                      <div className="col-1 text-center ">
                        <button className=" text-hover-primary border-0 bg-white" onClick={() => fetchOneSchedule(theSchedule.id)}>
                          <i className="bi bi-pencil text-dark fs-4"></i>
                        </button>
                        <button className="text-hover-danger border-0 bg-white ms-4" onClick={() => deleteSchedule(theSchedule.id)}>
                          <i className="bi bi-trash text-dark fs-4"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-6 mx-auto">
                      <ReactModal
                        isOpen={isDeleteScheduleModalOpen}
                        onRequestClose={closeDeleteScheduleModal}
                        style={defaultDeleteDialogModalStyles}
                        contentLabel="Delete Schedule"
                      >
                        <DeleteScheduleModal closeDeleteScheduleModal={closeDeleteScheduleModal} scheduleForDeletion={scheduleForDeletion} />
                      </ReactModal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Scheduler;
