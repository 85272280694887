import React, { useEffect, useState } from "react";
import {
  useEmaildispositionQuery,
  useTeamsQuery,
  useWorkflowsQQuery,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { useTicketMapToDispositionMutation } from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import { Checkbox, FormControlLabel } from "@mui/material";

const TicketMapping = () => {
  const { data: teamsData } = useTeamsQuery({});
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<Number>();
  const { data: theDispositions, refetch: refetchTheDispositions } =
    useEmaildispositionQuery(selectedTeam, { skip: !selectedTeam });
  const { data: theWorkflowsFetched } = useWorkflowsQQuery(selectedTeam, {
    skip: !selectedTeam,
  });
  const [selectAll, setSelectAll] = useState(false);
  const [theworkflows, setTheWorkflows] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<number>();
  const [triggerTicketMapToDisposition] = useTicketMapToDispositionMutation();
  const [cardsData, setCardData] = useState(null);
  const [isAnyCardSelected, setIsAnyCardSelected] = useState(false);

  const [expandedSteps, setExpandedSteps] = useState<{
    [key: string]: boolean;
  }>({});
  const handleFirstDropdownChange = (event: any) => {
    setSelectedTeam(Number.parseInt(event.target.value));
  };

  function extractLabelsAndSteps(
    data: any,
    steps = [],
    topLevelParentTopic?: string
  ) {
    let result = [];
    data.forEach((item) => {
      if (item.sub_topics.length === 0) {
        // Leaf node found, add to result
        result.push({
          label: steps.length === 0 ? topLevelParentTopic : steps[0], // Use the topLevelParentTopic or the first step as the label
          parentTopic: topLevelParentTopic, // Set parentTopic to topLevelParentTopic
          id: item.id,
          isSetForMapping: false,
          ismappedTo: item.ticket_name,
          steps: [...steps, item.topic],
          subTopics: [], // Initialize the subTopics array
        });
      } else {
        // Recursive call for each sub-topic
        let newSteps = [...steps, item.topic];
        result.push(
          ...extractLabelsAndSteps(
            item.sub_topics,
            newSteps,
            topLevelParentTopic
          )
        ); // Pass topLevelParentTopic down
      }
    });
    return result;
  }

  const updateFetchedDispositionsImmutablyV2 = (
    dispositions: any,
    userTweaked: any,
    assignment: any
  ) => {
    console.dir(dispositions);
    console.dir(userTweaked);

    // Create a map of id to isSetForMapping from userTweaked for quick lookup
    const idToIsSetForMapping = {};
    userTweaked.forEach((item) => {
      idToIsSetForMapping[item.id] = item.isSetForMapping;
    });

    // Function to recursively update dispositions array
    const updateDispositionsRecursive = (dispositions) => {
      return dispositions.map((disposition) => {
        // Check if disposition's id exists in idToIsSetForMapping and isSetForMapping is true
        if (
          idToIsSetForMapping.hasOwnProperty(disposition.id) &&
          idToIsSetForMapping[disposition.id]
        ) {
          // Create a copy of the disposition object and update disposition_name
          const updatedDisposition = {
            ...disposition,
            ticket_name: assignment,
          };

          // Recursively update sub_topics if they exist
          if (
            updatedDisposition.sub_topics &&
            updatedDisposition.sub_topics.length > 0
          ) {
            updatedDisposition.sub_topics = updateDispositionsRecursive(
              updatedDisposition.sub_topics
            );
          }

          return updatedDisposition;
        } else {
          // If no update needed, return the original disposition object
          const originalDisposition = { ...disposition };

          // Recursively update sub_topics if they exist
          if (
            originalDisposition.sub_topics &&
            originalDisposition.sub_topics.length > 0
          ) {
            originalDisposition.sub_topics = updateDispositionsRecursive(
              originalDisposition.sub_topics
            );
          }

          return originalDisposition;
        }
      });
    };

    // Start updating dispositions array recursively
    const recursivelyUpdatedDispositions =
      updateDispositionsRecursive(dispositions);

    return recursivelyUpdatedDispositions;
  };

  const handleSave = () => {
    const payloadForUpdate = theDispositions;
    const immutablyUpdated = updateFetchedDispositionsImmutablyV2(
      payloadForUpdate,
      cardsData,
      workflowSelected
    );
    if (!workflowSelected) {
      toast.error("Please select a workflow to map the disposition to");
      return;
    }
    triggerTicketMapToDisposition({
      body: immutablyUpdated,
      teamId: selectedTeam,
    }).then((res: any) => {
      if (res.data) {
        toast.success(`Selected dispositions mapped successfully`);
        setSelectAll(false); // Add this line to reset the selectAll state
        setCardData(null);
        refetchTheDispositions();
      }
    });
  };

  useEffect(() => {
    setTheTeams(teamsData);
  }, [teamsData]);
  useEffect(() => {
    theWorkflowsFetched && setTheWorkflows(theWorkflowsFetched.results);
  }, [theWorkflowsFetched]);
  useEffect(() => {
    theDispositions && setCardData(extractLabelsAndSteps(theDispositions));
  }, [theDispositions]);

  useEffect(() => {
    const isAnySelected = cardsData?.some((card) => card.isSetForMapping);
    setIsAnyCardSelected(isAnySelected);
  }, [cardsData]);

  const handleCheckboxChange = (id: any) => {
    setCardData((prev: any) =>
      prev.map(
        (item: any) =>
          item.id === id
            ? { ...item, isSetForMapping: !item.isSetForMapping } // Toggle isSetForMapping for the matching item
            : item // Keep the rest of the items unchanged
      )
    );
  };
  const toggleSteps = (stepKey: string) => {
    setExpandedSteps((prev) => ({
      ...prev,
      [stepKey]: !prev[stepKey],
    }));
  };

  // Function to recursively extract the last ticket_name from sub_topics
  const getLastTicketName = (disposition) => {
    if (disposition.sub_topics.length === 0) {
      return disposition.ticket_name; // Base case: No more subtopics, return ticket_name
    }

    // Traverse subtopics recursively and get the last ticket_name found
    const lastSubTopic =
      disposition.sub_topics[disposition.sub_topics.length - 1];
    const ticketNameInSubTopic = getLastTicketName(lastSubTopic);

    // If the subtopic has a valid ticket_name, return it, otherwise return the parent ticket_name
    return ticketNameInSubTopic || disposition.ticket_name;
  };

  // Function to update cardsData with ismappedTo using ticket_name from theDispositions
  const updateCardsDataWithTicketNames = (cardsData, theDispositions) => {
    return cardsData?.map((card) => {
      // Find corresponding disposition by id
      const disposition = theDispositions.find((d) => d.id === card.id);

      if (disposition) {
        // Get the last ticket_name from disposition (or sub_topics)
        const lastTicketName = getLastTicketName(disposition);

        // Update the ismappedTo field with the ticket_name
        card.ismappedTo = lastTicketName;
      } else {
        // If no disposition found for this card, set ismappedTo to null
        card.ismappedTo = null;
      }

      return card; // Return the updated card
    });
  };

  useEffect(() => {
    theDispositions && setCardData(extractLabelsAndSteps(theDispositions));
  }, [theDispositions]);

  const renderTreeNode = (
    card: any,
    steps: string[] = [],
    currentIndex: number = 0
  ) => {
    if (currentIndex >= steps.length) return null; // Prevent going out of bounds

    const currentStep = steps[currentIndex];
    const stepKey = `${card.id}-${currentStep}`; // Create a unique key for each step

    return (
      <div key={stepKey} className="ms-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => toggleSteps(stepKey)} // Use the unique step key
          >
            <i
              className={`bi ${
                steps.length
                  ? "bi-chevron-" + (expandedSteps[stepKey] ? "down" : "right")
                  : "bi-dash"
              } me-2`}
            ></i>
            <span>{capitalizeFirstLetter(currentStep)}</span>
          </div>
        </div>
        {expandedSteps[stepKey] && currentIndex < steps.length - 1 && (
          <div className="ms-4 border-start ps-2">
            {/* Call renderTreeNode for the next index */}
            {renderTreeNode(card, steps, currentIndex + 1)}
          </div>
        )}
      </div>
    );
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCardData((prev: any) =>
      prev.map((item: any) => ({
        ...item,
        isSetForMapping: !selectAll,
        ismappedTo:
          !selectAll && !item.ismappedTo ? workflowSelected : item.ismappedTo,
      }))
    );
  };

  return (
    <div className="card-toolbar flex-row-fluid justify-content-end gap-5 mx-5" style={{paddingLeft:"3%"}}>
      <div className="row">
        <div className="col-2 mw-200px">
          <label> Select team</label>
          <select
            className="form-select select2-hidden-accessible"
            onChange={handleFirstDropdownChange}
          >
            <option value="" selected disabled>
              Select team
            </option>
            {theTeams &&
              theTeams.map((team, index) => (
                <option key={index} value={team.id}>
                  {team.name}
                </option>
              ))}
          </select>
        </div>

        {selectedTeam && isAnyCardSelected && (
          <div className="col-2 mw-200px">
            {/*begin::Select2*/}
            <label> Select workflow</label>
            <select
              className="form-select"
              onChange={(e) =>
                setWorkflowSelected(Number.parseInt(e.target.value))
              }
            >
              <option value="" selected disabled>
                Select a workflow
              </option>
              {theworkflows &&
                theworkflows.map((theworkflow: any) => (
                  <option value={theworkflow.id}>{theworkflow.name}</option>
                ))}
            </select>
            {/*end::Select2*/}
          </div>
        )}
      </div>
      {selectedTeam && (
        <div className="row w-100">
          <div className="input-group-text bg-secondary mt-5 mx-2">
            <div className="col-3 text-start">
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                inputProps={{ "aria-label": "select all" }}
              />
              <strong>Disposition</strong>
            </div>
            <div className="col-6 text-start">
              <strong>Disposition hierarchy</strong>
            </div>
            <div className="col-3 text-start">
              <strong>Mapped to workflow</strong>
            </div>
          </div>
        </div>
      )}
      <div className="row mt-5">
        {/* {cardsData?.map((card: any, index: number) => */}
        {cardsData &&
          cardsData.map(
            (card: any, index: any) =>
              selectedTeam && (
                <div key={index} className="col-12 mt-2">
                  <div
                    className={`card p-4 ${
                      card.label === "label" ? "bg-secondary" : ""
                    }`}
                    style={{ minHeight: "50px" }}
                  >
                    <div className="row align-items-center">
                      <div className="col-3 text-start d-flex align-items-center gap-5">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={card.isSetForMapping}
                              onChange={() => handleCheckboxChange(card.id)}
                            />
                          }
                          label={card.label} // Use the label property
                          sx={{ typography: "h6" }} // Use h6 typography to make the label bold
                        />
                      </div>

                      <div className="col-6 d-flex flex-row align-items-center gap-5">
                        {renderTreeNode(card, card.steps)}
                        {/* Pass the steps array here */}
                      </div>

                      {card.ismappedTo && (
                        <div className="col-3 text-start">
                          {
                            theworkflows.find((wf) => wf.id === card.ismappedTo)
                              ?.name
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
      </div>

      {!cardsData?.length && (
        <>
          <div className="container d-flex justify-content-center align-items-center">
            <p className="d-flex flex-column align-items-center">
              <span className="fs-2">No data to show</span>
              <span>
                <i className="bi bi-box2-fill fs-2x"></i>
              </span>
            </p>
          </div>
        </>
      )}

      <div className="row mt-4">
        {/* Save button */}
        {selectedTeam && isAnyCardSelected && (
          <div className="text-end mt-5 mb-3">
            <button className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketMapping;
