import React, { useState, useEffect } from "react";
import TemplateModal from "./TemplateModal";
import ReactModal from "react-modal";
import { useGetTemplatePredefinedListQuery, useGetWhatsAppTemplatesQuery } from "../../../../Services/campign/campignList";
import DeleteTemplateModal from "./DeleteTemplateModal";
import EditTemplateModal from "./EditTemplateModal";
import DeleteWhatAppModal from "../WhatsAppConfiguration/DeleteWhatAppModal";
import { ref } from "yup";
import LoadingSpinner from "../../../LoadingSpinner";
import { Pagination, PaginationItem } from "@mui/material";
import TemplateDetailsUpdate from "./TemplateDetailsUpdate";

const Template = () => {
    const [openTemplateModal, setOpenTemplateModal] = useState<any>('');
    const [templateData, setTemplateData] = useState([])
    const [editFieldData, setEditFieldData] = useState([])
    const [fieldId, setFieldId] = useState()
    const [totalCount, setTotalCount] = useState<any>(0);
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [page, setPage] = useState(1)

    const [templateDataLoading, setTemplateDataLoading] = useState(false)
    const { data: templates, refetch, isLoading: templateLoading, status: templateStatus } = useGetWhatsAppTemplatesQuery({ rowsPerPage, page })
    const { data: templatesPredefined, isLoading: templatesPredefinedLoading, status: templatesPredefinedStatus } = useGetTemplatePredefinedListQuery({})
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const template = [
        {
            "name": "autobot_template",
            "parameter_format": "NAMED",
            "components": [
                {
                    "type": "HEADER",
                    "format": "IMAGE",
                    "example": {
                        "header_handle": [
                            "https://scontent.whatsapp.net/v/t61.29466-34/473408871_2420228338331149_2829277199817615337_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=cxEJPxhuCi8Q7kNvgFXziHh&_nc_oc=Adiy3cFOiyN0FfJggBItrMR_yhkUb5SegB39insxUxJSM_O84c_42U_BhfEWSaCmXP8&_nc_zt=3&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=nipF78vXRIbPlgp9xLzzMQ&oh=01_Q5AaIZrmNkJhaCft3o1ktwX7Lfn6l-mvBgKhbQXNUrZyYLvC&oe=68009BBE"
                        ]
                    }
                },
                {
                    "type": "BODY",
                    "text": "Looking for a new ride? Check out our latest {{car_model}} with {{offer_details}}! 🎉  \n\n📅 Book a test drive today.",
                    "example": {
                        "body_text_named_params": [
                            {
                                "param_name": "car_model",
                                "example": "Swift"
                            },
                            {
                                "param_name": "offer_details",
                                "example": "25% discount"
                            }
                        ]
                    }
                },
                {
                    "type": "BUTTONS",
                    "buttons": [
                        {
                            "type": "QUICK_REPLY",
                            "text": "I'm Interested"
                        },
                        {
                            "type": "QUICK_REPLY",
                            "text": "Stop promotions"
                        },
                        {
                            "type": "URL",
                            "text": "Visit website",
                            "url": "https://botgo.io/{{1}}",
                            "example": [
                                "https://botgo.io/"
                            ]
                        }
                    ]
                }
            ],
            "language": "en",
            "status": "APPROVED",
            "category": "MARKETING",
            "sub_category": "CUSTOM",
            "id": "2420228334997816"
        }
    ]
    const closeModal = () => {
        setOpenTemplateModal('');
    };

    useEffect(() => {
        if (templateStatus === "pending") {
            setTemplateDataLoading(true)
        }
        else {
            setTemplateDataLoading(false)
        }

    }, [templateStatus])


    const defaultCompactFormModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            maxWidth: "850px", // Max width for larger screens
            // height: "30%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
            background: "#ffffff",
        },
    };


    useEffect(() => {
        if (templates) {
            setTemplateData(templates?.results)
            setTotalCount(templates?.count || 0);

            console.log(templates)
        }

    }, [templates])

    const EditModal = (modal, item) => {
        setOpenTemplateModal(modal)
        setEditFieldData(item)

    }

    const deleteModal = (modal, item) => {
        setOpenTemplateModal(modal)
        setFieldId(item)
    }

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };


    const handleTemplateEdit = (event, newValue) => {
        setSelectedTemplate(newValue || "");
        const foundTemplate = templateData.find((t) => t.id === newValue);
        setSelectedTemplateDetails(foundTemplate || null);
        if (foundTemplate) setIsModalOpen(true);
    };
    return (
        <div style={{ marginLeft: "3%" }}>
           
            <div style={{ marginBottom: "30px", display: "flex", width: "100%", gap: "20px" }}>
                <div className="col">
                    <span className="input-group-text" id="basic-addon1">
                        Template
                    </span>
                </div>

                <div className="card-toolbar d-flex gap-2">
                    <button
                        className="input-group-text form-control btn-sm fw-bold d-flex align-items-center"
                        style={{ justifyContent: "center", fontSize: "15px" }}
                        disabled={templateDataLoading}
                        onClick={() => setOpenTemplateModal('add')}
                    >
                        <i className="bi bi-plus-lg text-dark me-2"></i>
                        <span>Add</span>
                    </button>
                </div>

            </div>

            <div>
                {/* Title Added */}
                {/* <h3 className="text-start ps-2 mb-3">Template List</h3> */}

                <div className="row" style={{ tableLayout: "fixed", borderCollapse: "collapse", paddingRight: "0px" }}>
                    <div className="col px-2" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                        <div className="card px-2 my-1 min-w-600px" style={{ backgroundColor: "#E4E4E47F" }}>
                            {/* Header */}
                            <div className="row align-items-center py-4">
                                <div className="col text-start ps-5">
                                    <strong>Sr.No</strong>
                                </div>
                                {/* New Extra Field */}
                                <div className="col text-start">
                                    <strong>Title</strong>
                                </div>
                                <div className="col text-start">
                                    <strong>Template name</strong>
                                </div>
                                <div className="col text-center">
                                    <strong style={{marginLeft:"-16%" }}>Category</strong>
                                </div>
                                <div className="col text-end me-2">
                                    <strong style={{ paddingRight: "10px" }}>Actions</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {templateLoading || templateDataLoading ? <LoadingSpinner /> : (
                    <div className="row" style={{ tableLayout: "fixed", borderCollapse: "collapse", paddingRight: "0px" }}>
                        <div className="col px-2" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                            {templateData && templateData.map((item, index) => (
                                <div className="card px-2 my-1 min-w-600px" draggable="true" style={{ cursor: "grab", padding: "7px 0px" }} key={index}>
                                    <div className="row align-items-center">
                                        <div className="col text-start ps-5">
                                            <label>{index + 1}</label>
                                        </div>
                                        <div className="col text-start">
                                            <label>{item?.name || "N/A"}</label>
                                        </div>
                                        <div className="col text-start">
                                            <label title={item?.raw_payload?.name} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>
                                                {item?.raw_payload?.name}
                                            </label>
                                        </div>
                                        <div className="col text-center">
                                            <label title={item?.category} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {item?.raw_payload?.category}
                                            </label>
                                        </div>

                                        {/* Actions */}
                                        <div className="col text-end me-6">
                                            <button className="text-hover-primary border-0 bg-white me-2" title="Edit" onClick={() => handleTemplateEdit(index, item.id)}>
                                                <i className="bi bi-pencil fs-4"></i>
                                            </button>
                                            <button className="text-hover-danger border-0 bg-white" title="Delete" onClick={() => deleteModal('delete', item.id)}>
                                                <i className="bi bi-trash fs-4"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px" }}>
                            <p>
                                <span>Page</span>
                                <span> {page} of {Math.ceil(totalCount / rowsPerPage)}</span>
                            </p>
                            <Pagination
                                sx={{ display: "flex", justifyContent: "end", color: "blue" }}
                                count={Math.ceil(totalCount / rowsPerPage)}
                                page={page}
                                color="primary"
                                onChange={handlePageChange}
                                renderItem={(item) => {
                                    if (item.type === "page") {
                                        if (
                                            (page === 1 && item.page <= 3) ||
                                            (page === Math.ceil(totalCount / rowsPerPage) && item.page >= Math.ceil(totalCount / rowsPerPage) - 2) ||
                                            (item.page >= page - 1 && item.page <= page + 1)
                                        ) {
                                            return <PaginationItem {...item} />;
                                        }
                                        return null;
                                    }
                                    return <PaginationItem {...item} />;
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>


            <ReactModal
                isOpen={openTemplateModal === "add"}
                onRequestClose={closeModal}
                style={defaultCompactFormModalStyles}
            >
                <TemplateModal closeModal={closeModal} refetch={refetch} templateData={templatesPredefined?.results} />
            </ReactModal>

            {/* <ReactModal
                isOpen={openTemplateModal === "edit"}
                onRequestClose={closeModal}
                style={defaultCompactFormModalStyles}
            >
                <EditTemplateModal closeModal={closeModal} editTemplateData={editFieldData} refetch={refetch} templateData={templateData} />
            </ReactModal> */}
            <ReactModal
                isOpen={openTemplateModal === "delete"}
                onRequestClose={closeModal}
                style={defaultCompactFormModalStyles}
            >
                <DeleteTemplateModal
                    closeModal={closeModal}
                    fieldsData={fieldId}
                    refetch={refetch}
                />
            </ReactModal>



            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        maxWidth: "1050px",
                        height: "70%",
                        overflowY: "auto",
                    },
                }}
                contentLabel="Template Details"
            >
                <TemplateDetailsUpdate
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    template={selectedTemplateDetails}
                    refetch={refetch}
                />
            </ReactModal>
        </div>
    );
};

export default Template;
