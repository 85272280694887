import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import AddTagBasedSLAModal from "./AddTagBasedSLAModal";
import { ReactMultiEmail } from "react-multi-email";
import {
  useGetTicketTagSLAEscalationLevelsQuery,
  useUpdateTicketTagsMutation,
  useUpdateEscallationLevelMutation,
  useDeleteEscallationLevelMutation,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import DeleteLevelModal from "./tagBasedSLA/DeleteLevelModal";

const customAddlevelModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    maxWidth: "850px", // Max width for larger screens
    margin: "auto",
    Overflow: "hidden",
    OverflowY: "auto",
  },
};

interface Tag {
  id: number;
  tag_name: string;
  duration_in_ms: any;
  tag_id: any;
  tag_sla_mail: string[];
  team_id: number;
  level: { [key: string]: { email: any[]; duration_in_ms: any } };
  showExpanded?: any;
}

interface TimeValues {
  [key: string]: { hours: number; minutes: number };
}

const TagBasedSLA = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [theTags, setTheTags] = useState<Tag[]>([]);
  const [emailLists, setEmailLists] = useState({});
  const [levelEmailList, setLevelEmailList] = useState<any>({});
  const [timeValues, setTimeValues] = useState({});

  const [selectedLevelName, setSelectedLevelName] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentEscalationLevel, setCurrentEscalationLevel] = useState(1);
  const [updatedTimeValues, setUpdatedTimeValues] = useState({});
  const [updatedLevelTimeValues, setUpdatedLevelTimeValues] = useState({});
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [id, setId] = useState<any>(null);
  const [triggerTicketTag] = useUpdateTicketTagsMutation();
  const [triggerEscallationLevel] = useUpdateEscallationLevelMutation();
  const [triggerDeleteEscallationLevel] = useDeleteEscallationLevelMutation();

  const { data: ticketTagSLAEscalationLevels, refetch } =
    useGetTicketTagSLAEscalationLevelsQuery({});

  const openModal = (tagIndex: number) => {
    const selected = theTags[tagIndex];
    setSelectedTag(selected); // Store selected tag

    let availableLevels: any = new Set<number>();
    let minLevel = 2; // Escalation starts from 2

    if (selected?.level && Object.keys(selected?.level)?.length > 0) {
      const levels = Object.keys(selected.level)
        .map((level) => parseInt(level.match(/\d+/)?.[0] || "1", 10)) // Extract numbers from level names
        .filter(Number.isFinite); // Ensure they are valid numbers

      // Find the smallest missing level number
      const maxLevel = levels.length > 0 ? Math.max(...levels) + 1 : minLevel;
      for (let i = minLevel; i <= maxLevel; i++) {
        availableLevels.add(i);
      }
      levels.forEach((lvl) => availableLevels.delete(lvl));
    } else {
      availableLevels.add(minLevel);
    }

    // Set next escalation level to the lowest missing number
    setCurrentEscalationLevel(Math.min(...availableLevels));
    setIsModalOpen(true);
  };

  console.log(selectedTag, "selectedTag");

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openDeleteModal = (tagIndex: number, levelName: string) => {
    const selected = theTags[tagIndex];
    setSelectedTag(selected); // Store selected tag
    setSelectedLevelName(levelName);
    setIsDeleteModalOpen(true);
  };

  console.log(selectedTag, "selectedTag");
  console.log(selectedLevelName, "selectedLevelName");

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (Array.isArray(ticketTagSLAEscalationLevels)) {
      console.log("Fetched SLA Config:", ticketTagSLAEscalationLevels);

      const formattedTags = ticketTagSLAEscalationLevels.map((tag) => ({
        ...tag,
        showExpanded: false, // Ensure each tag has a showExpanded property
      }));

      setTheTags(formattedTags);

      const initialTimeValues: TimeValues = {};
      formattedTags.forEach((tag, tagIndex) => {
        initialTimeValues[tagIndex] = {
          hours: Math.floor((tag.duration_in_ms || 0) / 3600000),
          minutes: Math.floor(((tag.duration_in_ms || 0) % 3600000) / 60000),
        };

        Object.entries(tag.level || {}).forEach(
          ([levelName, levelData]: [string, any]) => {
            initialTimeValues[`${tagIndex}-${levelName}`] = {
              hours: Math.floor(levelData.duration_in_ms / 3600000),
              minutes: Math.floor((levelData.duration_in_ms % 3600000) / 60000),
            };
          }
        );
      });
      setTimeValues(initialTimeValues);
    }
  }, [ticketTagSLAEscalationLevels]);

  const toggleExpansion = (index: number) => {
    setTheTags((prev) =>
      prev.map((tag, i) =>
        i === index ? { ...tag, showExpanded: !tag.showExpanded } : tag
      )
    );
  };

  const handleTimeChangeLevel1 = (tagIndex, unit, action) => {
    setTimeValues((prev) => {
      const currentValue = prev[tagIndex] || { hours: 0, minutes: 0 };

      const updatedValue = {
        ...currentValue,
        [unit]:
          action === "increment"
            ? currentValue[unit] + 1
            : Math.max(currentValue[unit] - 1, 0),
      };

      setUpdatedTimeValues((prevUpdates) => ({
        ...prevUpdates,
        [tagIndex]: updatedValue,
      }));

      return { ...prev, [tagIndex]: updatedValue };
    });
  };

  const handleTimeChangeExpanded = (tagIndex, levelName, unit, action) => {
    const key = `${tagIndex}-${levelName}`;

    setTimeValues((prev) => {
      const currentValue = prev[key] || { hours: 0, minutes: 0 };
      const updatedValue = {
        ...currentValue,
        [unit]:
          action === "increment"
            ? currentValue[unit] + 1
            : Math.max(currentValue[unit] - 1, 0),
      };

      setUpdatedLevelTimeValues((prevUpdates) => ({
        ...prevUpdates,
        [key]: updatedValue,
      }));

      return { ...prev, [key]: updatedValue };
    });
  };

  // Update usage for Level 1 time change
  const handleTimeChange = (tagIndex, levelName, unit, action) => {
    if (levelName) {
      handleTimeChangeExpanded(tagIndex, levelName, unit, action);
    } else {
      handleTimeChangeLevel1(tagIndex, unit, action);
    }
  };

  const handleSave = () => {
    // Extract Escalation Level 1 data
    const firstLevelKey = Object.keys(updatedTimeValues).find(
      (key) => !key.includes("-")
    );
    const firstLevelDuration = firstLevelKey
      ? updatedTimeValues[firstLevelKey].hours * 3600000 +
        updatedTimeValues[firstLevelKey].minutes * 60000
      : id.duration_in_ms;

    const firstLevelEmails =
      Object.values(emailLists).find((_, index) => index === 0) ||
      id.tag_sla_mail ||
      [];

    const payload = {
      tag_sla_mail: firstLevelEmails,
      duration_in_ms: firstLevelDuration,
    };

    try {
      triggerTicketTag({
        body: payload,
        id: id.tag_id,
      });
      toast.success("Tag saved successfully");
      refetch();
      closeModal();
    } catch (error) {
      console.error("Error saving escalation:", error);
      toast.error("Failed to save escalation. Please try again.");
    }
  };

  const handleEscalationLevelUpdate = (tagIndex, levelName) => {
    const levelKey = levelName;
    const levelEmails: any =
      Object.values(levelEmailList).find((_, index) => index === 0) || [];

    // Extract time values for the specific level
    const key = `${tagIndex}-${levelName}`;
    const previousTimeValues = timeValues[key] || { hours: 0, minutes: 0 };
    const updatedTime = updatedLevelTimeValues[key] || {};

    const durationInMs =
      (updatedTime.hours !== undefined
        ? updatedTime.hours
        : previousTimeValues.hours) *
        3600000 +
      (updatedTime.minutes !== undefined
        ? updatedTime.minutes
        : previousTimeValues.minutes) *
        60000;

    const previousEmails = theTags[tagIndex]?.level?.[levelName]?.email || [];
    const finalEmails = levelEmails.length > 0 ? levelEmails : previousEmails;

    const payload = {
      level: {
        [levelKey]: {
          email: finalEmails,
          duration_in_ms: durationInMs,
        },
      },
    };

    try {
      triggerEscallationLevel({
        body: payload,
        id: id.tag_id,
        escalationLevel: levelKey,
      });
      toast.success("Escalation level updated successfully");
      refetch();
      closeModal();
    } catch (error) {
      console.error("Error saving escalation:", error);
      toast.error("Failed to update escalation. Please try again.");
    }
  };

  console.log(currentEscalationLevel, "currentEscalationLevel");

  console.log(levelEmailList, "levelEmailList");

  console.log(emailLists, "emailLists");

  console.log(updatedTimeValues, "updatedTimeValues");

  console.log(id, "updatedTimeValuesIDDDDD");

  return (
    <div style={{marginLeft:"0%"}}>
      <div className="row">
        <div className="col">
          <div
            className="card my-1 min-h-40px justify-content-center"
            style={{ backgroundColor: "rgba(228, 228, 228, 0.498)" }}
          >
            <div className="row align-items-center">
              <div className="col-2 text-start ps-10">
                <strong>Tag</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Escalation Level</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Hours</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Minutes</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Mail ID</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Actions</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      {theTags.map((tagInfo: any, tagIndex: any) => (
        <div key={tagIndex}>
          <div className="row">
            <div className="col">
              <div className="input-group-text mt-2 bg-white min-w-600px">
                <div className="row w-100 align-items-center text-center">
                  <div className="col-2 text-start ps-10">
                    {tagInfo.tag_name}
                  </div>
                  <div className="col-2">Escalation Level 1</div>
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    {timeValues[tagIndex]?.hours || 0}
                    <div className="d-flex flex-column ms-2">
                      <i
                        className="bi bi-caret-up-fill cursor-pointer"
                        onClick={() => {
                          handleTimeChange(tagIndex, "", "hours", "increment");
                          setId(tagInfo);
                        }}
                      ></i>
                      <i
                        className="bi bi-caret-down-fill cursor-pointer"
                        onClick={() => {
                          handleTimeChange(tagIndex, "", "hours", "decrement");
                          setId(tagInfo);
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    {timeValues[tagIndex]?.minutes || 0}
                    <div className="d-flex flex-column ms-2">
                      <i
                        className="bi bi-caret-up-fill cursor-pointer"
                        onClick={() => {
                          handleTimeChange(
                            tagIndex,
                            "",
                            "minutes",
                            "increment"
                          );
                          setId(tagInfo);
                        }}
                      ></i>
                      <i
                        className="bi bi-caret-down-fill cursor-pointer"
                        onClick={() => {
                          handleTimeChange(
                            tagIndex,
                            "",
                            "minutes",
                            "decrement"
                          );
                          setId(tagInfo);
                        }}
                      ></i>
                    </div>
                  </div>

                  <div className="col-3">
                    <ReactMultiEmail
                      placeholder="Input your Email Address"
                      emails={
                        emailLists[tagIndex] || tagInfo.tag_sla_mail || []
                      }
                      onChange={(updatedEmails) => {
                        setEmailLists((prev) => ({
                          ...prev,
                          [tagIndex]: updatedEmails,
                        }));
                        setId(tagInfo);
                      }}
                      getLabel={(email, index, removeEmail) => (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-1 d-flex justify-content-center">
                    <button
                      className="btn btn-link py-0"
                      onClick={() => openModal(tagIndex)}
                    >
                      <i className="text-dark bi bi-plus-lg"></i>
                    </button>
                    <button
                      className="btn btn-link py-0"
                      onClick={() => toggleExpansion(tagIndex)}
                    >
                      <i
                        className={`ms-2 text-dark ${
                          tagInfo.showExpanded
                            ? "bi bi-chevron-up"
                            : "bi bi-chevron-down"
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tagInfo.showExpanded &&
            Object.entries(tagInfo.level || {}).map(
              ([levelName, levelData]: [string, any], levelIndex) => (
                <div className="row" key={levelIndex}>
                  <div className="col">
                    <div className="input-group-text mt-2 bg-light min-w-600px">
                      <div className="row w-100 align-items-center">
                        <div className="col-2 text-start ps-10"></div>
                        <div className="col-2 text-center">{levelName}</div>
                        <div className="col-2 d-flex align-items-center justify-content-center">
                          {timeValues[`${tagIndex}-${levelName}`]?.hours || 0}
                          <div className="d-flex flex-column ms-2">
                            <i
                              className="bi bi-caret-up-fill cursor-pointer"
                              onClick={() => {
                                handleTimeChange(
                                  tagIndex,
                                  levelName,
                                  "hours",
                                  "increment"
                                );
                                setId(tagInfo);
                              }}
                            ></i>
                            <i
                              className="bi bi-caret-down-fill cursor-pointer"
                              onClick={() => {
                                handleTimeChange(
                                  tagIndex,
                                  levelName,
                                  "hours",
                                  "decrement"
                                );
                                setId(tagInfo);
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-center">
                          {timeValues[`${tagIndex}-${levelName}`]?.minutes || 0}
                          <div className="d-flex flex-column ms-2">
                            <i
                              className="bi bi-caret-up-fill cursor-pointer"
                              onClick={() => {
                                handleTimeChange(
                                  tagIndex,
                                  levelName,
                                  "minutes",
                                  "increment"
                                );
                                setId(tagInfo);
                              }}
                            ></i>
                            <i
                              className="bi bi-caret-down-fill cursor-pointer"
                              onClick={() => {
                                handleTimeChange(
                                  tagIndex,
                                  levelName,
                                  "minutes",
                                  "decrement"
                                );
                                setId(tagInfo);
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="col-3 text-center">
                          <ReactMultiEmail
                            placeholder="Input your Email Address"
                            emails={
                              levelEmailList[`${tagIndex}-${levelName}`] ||
                              levelData.email ||
                              []
                            }
                            onChange={(updatedEmails) => {
                              setLevelEmailList((prev) => ({
                                ...prev,
                                [`${tagIndex}-${levelName}`]: updatedEmails,
                              }));
                              setId(tagInfo);
                            }}
                            getLabel={(email, index, removeEmail) => (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index)}
                                >
                                  ×
                                </span>
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-1 text-center">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <button
                              type="button"
                              className="btn btn-link text-danger p-0"
                              title="Delete Escalation Level"
                              onClick={() =>
                                openDeleteModal(tagIndex, levelName)
                              }
                            >
                              <i className="bi bi-trash text-danger fs-5"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-link text-primary p-0"
                              title="Update Escalation Level"
                              onClick={() =>
                                handleEscalationLevelUpdate(tagIndex, levelName)
                              }
                            >
                              <i className="bi bi-pencil-square text-primary fs-5"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      ))}
      <div className="row mt-4">
        <div className="col d-flex justify-content-end">
          <button
            className="btn btn-primary ms-4 min-w-100px"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        style={customAddlevelModalStyles}
        onRequestClose={closeModal}
        contentLabel="New Ticket"
      >
        <AddTagBasedSLAModal
          closeModal={closeModal}
          currentEscalationLevel={currentEscalationLevel}
          refetch={refetch}
          selectedTag={selectedTag}
        />
      </ReactModal>

      <ReactModal
        isOpen={isDeleteModalOpen}
        style={customModalStyles}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete level"
      >
        <DeleteLevelModal
          closeDeleteModal={closeDeleteModal}
          levelName={selectedLevelName}
          tagId={selectedTag?.tag_id}
          refetch={refetch}
        />
      </ReactModal>
    </div>
  );
};

export default TagBasedSLA;
