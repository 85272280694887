import { Dropdown } from "../../helpers/components/Dropdown";
import { useRef, useState, createRef, useEffect } from "react";
import LeadFilterMenu from "../dashboard/campaign/charts/LeadFilterMenu";
import CampaignListView from "../../components/campaign/CampaignListView";
import CampaignCardView from "../../components/campaign/CampaignCardView";
import CampaignListCardView from "../../components/campaign/CampainListCardView";
import {
  useCampignWorkflowQuery,
  useCampainPaginationQuery,
} from "../../Services/campign/campignList";
import { useMeeQuery } from "../../Services/settingsAPIs/CommonAPI";
import { useTeamsQuery } from "../../Services/settingsAPIs/CommonAPI";
import {
  useCampaignQuery,
  useCampaignOrderQuery,
} from "../../Services/campign/campignList";
import ReactModal from "react-modal";
import CampaignLeadModel from "./CampaignLeadModel";
import { toast } from "react-toastify";
import BulkAssigneeLeadModel from "./BulkAssigneeLeadModel";
import DeleteBulkLeadModel from "./DeleteBulkLeadModel";
import './styles.scss'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CampaignFilterModel from "./CampaignFilterModel";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import BroadcastModal from "./BroadcastModal";
import { CIRCLEONE_TEAM, CIRCLEONE_USERNAME } from "../../constants";



const CampaignList = () => {
  const filterDropdownMenuRef = useRef<HTMLDivElement>(null);
  const [filterMenuDroppedDown, setFilterMenuDroppedDown] =
    useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [headerOption, setHeaderOption] = useState<any>([
    { id: "1", name: "Lead Id" },
    // { id: "2", name: "Outstanding Amount" },
    { id: "2", name: "Assignee" },
    { id: "3", name: "Priority" },
  ]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>([]);
  const handleMultiSelectChange = (selectedIds: string[]) => {
    if (selectedIds.length > 5) {
      toast.error("You can only select up to 5 headers."); // Display error message using toast
    } else {
      const updatedOptions = headerOption.filter((option) =>
        selectedIds.includes(option.id)
      );
      setSelectedOptions(updatedOptions); // Store objects with id and name
    }
  };
  const handleSelectAll = () => {
    if (selectedOptions.length > 0) {
      // If any field is selected, deselect all
      setSelectedOptions([]);
    } else {
      // Select up to 5 options
      const limitedOptions = headerOption.slice(0, 5);
      setSelectedOptions(limitedOptions);
    }
  };

  const taskRes = [
    {
      taskname: "folow up on payment",
      duedate: "12/1/23",
      frequency: "monthly",
      owner: "Archana",
      status: "pending",
    },
    {
      taskname: "folow up on payment",
      duedate: "12/1/23",
      frequency: "monthly",
      owner: "Archana",
      status: "pending",
    },
    {
      taskname: "folow up on payment",
      duedate: "12/1/23",
      frequency: "monthly",
      owner: "Archana",
      status: "pending",
    },
    {
      taskname: "folow up on payment",
      duedate: "12/1/23",
      frequency: "monthly",
      owner: "Archana",
      status: "pending",
    },
  ];

  const listOfLeads = [
    {
      isSelected: false,
      leadId: "00227",
      fname: "Prajakta",
      lname: "joshi",
      company: "Bluewhirl",
      mailid: "prajakta@bluewhirl.io",
      mobileno: "8953519254",
      status: "All", // Add status for the lead
    },
    {
      isSelected: false,
      leadId: "00228",
      fname: "Archana",
      lname: "Jagtap",
      company: "Bluewhirl",
      mailid: "archana@bluewhirl.io",
      mobileno: "6897519254",
      status: "Open", // Add status for the lead
    },
    {
      isSelected: false,
      leadId: "00229",
      fname: "Abhinev",
      lname: "Qasba",
      company: "Botgo",
      mailid: "abhinev@botgo.io",
      mobileno: "9990966200",
      status: "WIP", // Add status for the lead
    },
    {
      isSelected: false,
      leadId: "00230",
      fname: "Kiran",
      lname: "Singh",
      company: "Botgo",
      mailid: "kiran@botgo.io",
      mobileno: "9990099638",
      status: "Ready", // Add status for the lead
    },
    {
      isSelected: false,
      leadId: "00231",
      fname: "Shivam",
      lname: "Shakya",
      company: "Botgo",
      mailid: "shivam@botgo.io",
      mobileno: "8888262122",
      status: "Closed", // Add status for the lead
    },
  ];
  const customModalStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxHeight: "80%",
      maxWidth: "850px", // Max width for larger screens
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const customEditModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "28%", // Default to full width
      maxWidth: "1150px", // Max width for larger screens
      // height: "61%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };
  const customResetPassModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "450px",
      maxWidth: "850px", // Max width for larger screens
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };
  const customFilterModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "50px", // Max width for larger screens
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };

  const storedOption =
    sessionStorage.getItem("selectedOptionCampign") || "campaignListView";
  const [outwardLeadButton, setOutwardLeadButton] = useState<boolean>(true);

  const [selectedOption, setSelectedOption] = useState(storedOption);
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState<any>(() => {
    const savedPage = sessionStorage.getItem("campaignCurrentPage");
    return savedPage ? Number(savedPage) : 1;
  });

  const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
  const [liftingKey, setLiftingKey] = useState([]);
  const [isFilterModalOpen, setFilterIsModalOpen] = useState(false);
  const { data: useData } = useMeeQuery({});
  const [liftUiData, setLiftUiData] = useState(0);

  const [value, setValue] = useState(liftUiData ? "active" : "All");
  const [storeKey, setStoreKey] = useState(liftUiData ? "active" : "All");
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("user_role");

  const handleNavigate = () => {
    navigate("/campaign/eventlist");
  };
  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    sessionStorage.setItem("selectedOptionCampign", selected); // Campaign view draopdown session data
  };

  const [isLoadingPageChange, setIsLoadingPageChange] =
    useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // const { data: teamsData } = useTeamsQuery({});
  // const [selectedTeam, setSelectedTeam] = useState<number>();
  // const [theTeams, setTheTeams] = useState([]);
  const teamIdFromLocalStorage = localStorage.getItem("teamId");
  const teamNameFromLocalStorage = localStorage.getItem("circleone-team");
  const { data: camapignData, refetch: refetchCampaigns } = useCampaignQuery({

    teamId: teamIdFromLocalStorage, campaignType: outwardLeadButton
  }
  );
  const [selectedTeamId, setSelectedTeamId] = useState(() => {
    const selectedTeamIdFromSession = sessionStorage.getItem("selectedTeamId");
    const userRole = localStorage.getItem("user_role");
    const userId = localStorage.getItem("circleone-username");


    if (selectedTeamIdFromSession) {
      return selectedTeamIdFromSession;
    }

    if (userRole === "admin") {
      return "My Team";
    }

    if (userRole === "agent" || userRole === "manager") {
      return userId;
    }

    return null; // Fallback value if none of the conditions are met
  });
  const [selectedCampain, setSelectedCampain] = useState<number>();
  const [theCampaign, setTheCampaign] = useState([]);
  const [campaignId, setCampaignId] = useState<number>(); // Add state for campaignId
  const [workflowName, setWorkflowName] = useState<string>(""); // Add state for workflowName
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [isBulkAssigneeModalOpen, setIsBulkAssigneeModalOpen] = useState(false);
  const [isBulkBrodardcastModalOpen, setIsBulkBroardcastModalOpen] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);

  const checkTeam = localStorage.getItem(CIRCLEONE_TEAM)
  const checkUsername = localStorage.getItem(CIRCLEONE_USERNAME)


  const [selectedCampaignFilterAndValue, setSelectedFilterAndValue] =
    useState<any>({
      email__icontains: "",
      assignee__username__icontains: "",
      account_name__icontains: "",
      created: "",
      first_name__icontains: "",
      last_name__icontains: "",
      phone_number__icontains: "",
    });
  const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>(
    () => {
      const sessionData = sessionStorage.getItem(
        "selectedCampaignFilterAndValue"
      );
      return sessionData
        ? JSON.parse(sessionData)
        : {
          email__icontains: "",
          assignee__username__icontains: "",
          account_name__icontains: "",
          created: "",
          first_name__icontains: "",
          last_name__icontains: "",
          phone_number__icontains: "",
        };
    }
  );
  useEffect(() => {
    sessionStorage.setItem(
      "selectedCampaignFilterAndValue",
      JSON.stringify(selectedFilterAndValue1)
    );
  }, [selectedFilterAndValue1]); //set Filters  sessiondata

  useEffect(() => {
    const savedPage = sessionStorage.getItem("campaignCurrentPage");
    if (savedPage !== currentPage.toString()) {
      sessionStorage.setItem("campaignCurrentPage", currentPage.toString());
    }
  }, [currentPage]);

  const { data: CampaignWorkflow, refetch: refetchWorkflow } =
    useCampignWorkflowQuery(
      {
        selectedWorkFlow: workflowName,
        selectCampaign: campaignId,
        selectedFilterAndValue1,
      },
      { skip: !campaignId || !workflowName }
    );

  const { data: fetchedData, refetch } = useCampainPaginationQuery(
    {
      currentPage,
      selectCampaign: campaignId,
      value,
      liftingKey,
      storeKey,
      selectedFilterAndValue1,
      selectedTeamId,
    },
    { skip: !campaignId }
  );


  useEffect(() => {
    setIsLoadingPageChange(true);
    if (fetchedData?.results) {
      setData(fetchedData?.results);
    }
    setIsLoadingPageChange(false);
  }, [fetchedData, currentPage]);

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
    }
  }, [fetchedData]);
  useEffect(() => {
    if (camapignData && teamIdFromLocalStorage) {
      const filteredCampaigns =
        camapignData.results?.filter(
          (campaign) => campaign?.team?.id === Number(teamIdFromLocalStorage)
        ) || [];

      setTheCampaign(filteredCampaigns);


      if (filteredCampaigns.length > 0) {
        const sessionCampaignId = sessionStorage.getItem("selectedCampaignId"); // Get CampaignId session data
        let defaultCampaign = filteredCampaigns[0];

        if (sessionCampaignId) {
          const storedCampaign = filteredCampaigns.find(
            (campaign) => campaign.id === Number(sessionCampaignId)
          );

          if (storedCampaign) {
            defaultCampaign = storedCampaign;
          }
        }
        setSelectedCampaign(defaultCampaign);
        setSelectedCampain(defaultCampaign.id);
        setCampaignId(defaultCampaign.id);
        setWorkflowName(defaultCampaign.campaign_name);
        setIsButtonDisabled(!defaultCampaign.campaign_name);

        sessionStorage.setItem(
          "selectedCampaignId",
          defaultCampaign.id.toString()
        ); // Set default CampaignId session data
      } else {
        // Display toaster notification for no assignee data
        toast.warning("No campaigns assigned.");
      }
    }
  }, [camapignData, teamIdFromLocalStorage]);



  useEffect(() => {
    if (campaignId && workflowName) {
      refetchWorkflow();
    }
  }, [campaignId, workflowName, refetchWorkflow]);
  useEffect(() => {
    if (
      data &&
      Array.isArray(data.results) &&
      data.results.length > 0 &&
      data.results[0]?.dynamic_fields
    ) {
      // Extract keys from dynamic_fields
      const dynamicKeys = Object.keys(data.results[0]?.dynamic_fields);

      // Map dynamic keys into the required format
      const dynamicOptions = dynamicKeys.map((key, index) => ({
        id: `${headerOption.length + index + 1}`, // Ensure unique IDs
        name: key,
      }));

      // Update the headerOption state while preserving the initial fixed options
      setHeaderOption((prev) => [...prev, ...dynamicOptions]);
    }
  }, [data]);

  const handleSelectCampainChange = async (event: any) => {
    setCurrentPage(1);

    const selectedCampaignId = Number(event.target.value);
    setSelectedCampain(selectedCampaignId);
    setCampaignId(selectedCampaignId);
    sessionStorage.setItem("selectedCampaignId", selectedCampaignId.toString());
    const selectedCampaign = theCampaign.find(
      (campaign) => campaign.id === selectedCampaignId
    );
    if (selectedCampaign?.campaign_name) {
      setWorkflowName(selectedCampaign.campaign_name);
      setIsButtonDisabled(false);
    } else {
      // Display error toaster 
      toast.error("Workflow not mapped to  this campaign.");
      setIsButtonDisabled(true);
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%", // Default to full width
      maxWidth: "850px", // Max width for larger screens
      height: "50%",
      margin: "auto",
      Overflow: "hidden",
      OverflowY: "auto",
    },
  };
  const openBulkModal = () => {
    if (selectedTickets.length === 0) {
      return toast.error("Please select Lead .");
    } else {
      setIsBulkAssigneeModalOpen(true);
    }
  };
  const closeBulkModal = () => {
    setSelectedTickets([]);
    setIsBulkAssigneeModalOpen(false);
  };

  const openBulkBroadcastModal = () => {
    setIsBulkBroardcastModalOpen(true);

  };
  const closeBulkBroadcastModal = () => {
    setSelectedTickets([]);
    setIsBulkBroardcastModalOpen(false);
  };

  const openBulkDeleteModal = () => {
    if (selectedTickets.length === 0) {
      return toast.error("Please select Lead .");
    } else {
      setIsBulkDeleteModalOpen(true);
    }
  };
  const closeBulkDelateModal = () => {
    setSelectedTickets([]);
    setIsBulkDeleteModalOpen(false);
  };

  const openFilterModal = () => {
    setFilterIsModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterIsModalOpen(false);
  };

  const handleSubmit = (filterValues) => {
    setIsLoadingPageChange(true);
    setSelectedFilterAndValue1(filterValues);
    setIsLoadingPageChange(false);
  };
  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description?.slice(0, maxLength) + "...";
    }
    return description;
  };
  // useEffect(() => {
  //   if (CampaignWorkflow) {
  //     setData(CampaignWorkflow);
  //   }
  // }, [CampaignWorkflow]);

  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const handleTeamChange = (e) => {
    const selectedTeamId = e.target.value;
    setSelectedTeamId(selectedTeamId);
    sessionStorage.setItem("selectedTeamId", selectedTeamId); // Store the selected team ID in local storage
    setIsLoadingPageChange(true);
  };

  useEffect(() => {
    const storedTeamId = localStorage.getItem("selectedTeamId");
    if (storedTeamId) {
      setSelectedTeamId(storedTeamId);
    }
  }, []);

  const checkTenant = localStorage.getItem("circleone-user")

  localStorage.setItem("workflowName", workflowName)
  if (campaignId !== undefined) {
    localStorage.setItem("campaignId", String(campaignId));
  }


  const handleSwitchToggle = () => {
    setOutwardLeadButton(!outwardLeadButton);
    // Update the route based on the toggle
    // if (!outwardLeadButton) {
    //   navigate("inward");
    // } else {
    //   navigate("outward");
    // }
  };

  // useEffect(() => {
  //   if (location.pathname.includes("/account")) {
  //     setOutwardLeadButton(true);
  //   } else if (location.pathname.includes("/contact")) {
  //     setOutwardLeadButton(false);
  //   }
  // }, [location.pathname]);


  // useEffect(() => {
  //   navigate("outward");
  // }, []);


  const checkForToggleButton = outwardLeadButton ? "outward" : "inward"

  const navigateHandler = () => {
    navigate('/dashboard/campaign-dashboard', { state: checkForToggleButton })
  }
  const tabTitles = ['Outbound lead', 'Inbound lead'];

  const restrictedUser = checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io"
  return (
    <div className="custom-style">
      <div id="kt_app_content_container" className="app-container mx-5">
        <div className="card card-flush min-h-700px">
          {/*begin::Card header*/}
          <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
            {/*begin::Card title*/}
            {/* {selectedOption === "campaignListView" && (
              <div className="card-title">
                <div className="d-flex">
                  <button
                    className="btn btn-link"
                    onClick={handleBackButtonClick}
                  >
                    <i className="bi bi-arrow-90deg-left" style={{
                      fontSize: "20px",
                      color: "black",
                      cursor: "pointer",
                    }}></i>
                  </button>

                </div>
                <div style={{
                  display: "flex", gap: "20px",
                  marginLeft: "20px"
                }} className="p-3 ">

                  <div className='d-flex'>
                    <ul
                      className='nav nav-stretch nav-line-tabs border-transparent flex-nowrap mb-1'
                      style={{ display: 'flex', gap: '10px', whiteSpace: 'nowrap' }}
                    >
                      {tabTitles.map((title) => (
                        <li className='nav-item' key={title} style={{ flexShrink: 0 }}>
                          <span
                            className={`nav-link cursor-pointer ${outwardLeadButton === (title === 'Outbound lead') ? 'active fw-bolder' : ''
                              }`}
                            onClick={() => handleSwitchToggle()}
                            role='tab'
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'inline-block'
                            }}
                          >
                            {title}
                          </span>
                        </li>
                      ))}
                    </ul>

                  </div>
                  <div style={{ width: "10%" }} className="mt-3 cursor-pointer"
                    onClick={navigateHandler}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={`Navigate to ${outwardLeadButton ? "outbound" : "inbound"} lead dashboard`}

                  >
                    <i className="bi bi-graph-up-arrow" style={{ fontSize: "20px" }}></i>
                  </div>
                </div>
              </div>
            )} */}

            {/*begin::Card title*/}
            {selectedOption === "campaignListView" && (
              <div className="card-title">
                <div className="d-flex">
                  <button
                    className="btn btn-link"
                    onClick={handleBackButtonClick}
                  >
                    <i className="bi bi-arrow-90deg-left" style={{
                      fontSize: "20px",
                      color: "black",
                      cursor: "pointer",
                    }}></i>
                  </button>
                  <h2 className="p-4">Campaign</h2>

                </div>
                </div>
            )}
            {selectedOption === "campaignListCardView" && (
              <div className="card-title" style={{ marginLeft: "63px" }}>
                <div className="d-flex">
                  <button
                    className="btn btn-link "
                    onClick={handleBackButtonClick}
                  >
                    <i className="bi bi-arrow-90deg-left" style={{
                      fontSize: "20px",
                      color: "black",
                      cursor: "pointer",
                    }}></i>
                  </button>
                  <h2 className="p-4">Campaign</h2>
                </div>
              </div>
            )}
            {selectedOption === "campaignCardView" && (
              <div className="card-title" style={{ marginLeft: "3%" }}>
                <div className="d-flex">
                  <button
                    className="btn btn-link "
                    onClick={handleBackButtonClick}
                  >
                    <i className="bi bi-arrow-90deg-left" style={{
                      fontSize: "20px",
                      color: "black",
                      cursor: "pointer",
                    }}></i>
                  </button>
                  <h2 className="p-4">Campaign</h2>
                </div>
              </div>
            )}

            {/*end::Card title*/}

            {/*begin::Card toolbar*/}
            {selectedOption === "campaignListCardView" && (

              <div
                className="card-toolbar flex-row-fluid justify-content-end gap-5"
                style={{ marginRight: "1%" }}
              >
                {/* <div className="w-100 mw-150px">

                <select
                  className="form-select select2-hidden-accessible"
                  value={teamNameFromLocalStorage || 0}  // Default to the teamId from localStorage
                  disabled
                >
                  <option value={teamNameFromLocalStorage || 0}>
                    {teamNameFromLocalStorage || "Select Team"}
                  </option>
                </select>

              </div> */}

              {!restrictedUser && 
                <div className="w-100 mw-150px">
                  <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                    <InputLabel id="view-select-label">View</InputLabel>
                    <Select
                      labelId="view-select-label"
                      id="view-select"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                      label="View"

                    >
                        {/* {checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io"  ? 
                         <MenuItem value="campaignListView">Standard view</MenuItem>
                        : 
                        <> */}
                      <MenuItem value="campaignListCardView">List view</MenuItem>
                      <MenuItem value="campaignListView">Standard view</MenuItem>
                      <MenuItem value="campaignCardView">Card view</MenuItem>
                        {/* </>
                        } */}
                    </Select>
                  </FormControl>
                </div>
            }
                <div className="w-100 mw-150px">
                  <FormControl style={{ width: "150px", zIndex: 0 }}>
                    <InputLabel id="campaign-select-label">
                      Select campaign
                    </InputLabel>
                    <Select
                      labelId="campaign-select-label"
                      id="campaign-select"
                      onChange={handleSelectCampainChange}
                      value={selectedCampain || 0}
                      label="Select Campaign"
                    >
                      {theCampaign?.map((el: any) => (
                        <MenuItem value={el?.id} key={el?.id}>
                          {el?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
            {selectedOption === "campaignListView" && (

              <div
                className="card-toolbar flex-row-fluid justify-content-end gap-5"
                style={{ marginRight: "2px" }}
              >
                {/* <div className="w-100 mw-150px">

    <select
      className="form-select select2-hidden-accessible"
      value={teamNameFromLocalStorage || 0}  // Default to the teamId from localStorage
      disabled
    >
      <option value={teamNameFromLocalStorage || 0}>
        {teamNameFromLocalStorage || "Select Team"}
      </option>
    </select>

  </div> */}
                <div className="w-100 mw-150px">
                  <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                    <InputLabel id="view-select-label">View</InputLabel>
                    <Select
                      labelId="view-select-label"
                      id="view-select"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                      label="View"
                    >
                        {/* {checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io"  ? 
                         <MenuItem value="campaignListView">Standard view</MenuItem>
                        : 
                        <> */}
                        {!restrictedUser && 
                      <MenuItem value="campaignListCardView">List view</MenuItem>}
                      <MenuItem value="campaignListView">Standard view</MenuItem>
                      {!restrictedUser && 
                      <MenuItem value="campaignCardView">Card view</MenuItem>
                      }
                        {/* </>
} */}
                    </Select>
                  </FormControl>
                </div>
                <div className="w-100 mw-150px">
                  <FormControl style={{ width: "150px", zIndex: 0 }}>
                    <InputLabel id="campaign-select-label">
                      Select campaign
                    </InputLabel>
                    <Select
                      labelId="campaign-select-label"
                      id="campaign-select"
                      onChange={handleSelectCampainChange}
                      value={selectedCampain || 0}
                      label="Select Campaign"
                    >
                      {theCampaign?.map((el: any) => (
                        <MenuItem value={el?.id} key={el?.id}>
                          {el?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

            {selectedOption === "campaignCardView" && (

              <div
                className="card-toolbar flex-row-fluid justify-content-end gap-5"
                style={{ marginRight: "2%" }}
              >
                {/* <div className="w-100 mw-150px">

    <select
      className="form-select select2-hidden-accessible"
      value={teamNameFromLocalStorage || 0}  // Default to the teamId from localStorage
      disabled
    >
      <option value={teamNameFromLocalStorage || 0}>
        {teamNameFromLocalStorage || "Select Team"}
      </option>
    </select>

  </div> */}
  {!restrictedUser && 
                <div className="w-100 mw-150px">
                  <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                    <InputLabel id="view-select-label">View</InputLabel>
                    <Select
                      labelId="view-select-label"
                      id="view-select"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                      label="View"
                    >


{/* {checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io"  ? 
                         <MenuItem value="campaignListView">Standard view</MenuItem>
                        : 
                        <> */}

                      <MenuItem value="campaignListCardView">List view</MenuItem>
                      <MenuItem value="campaignListView">Standard view</MenuItem>
                      <MenuItem value="campaignCardView">Card view</MenuItem>
                        {/* </>
} */}
                    </Select>
                  </FormControl>
                </div>
            }
                <div className="w-100 mw-150px">
                  <FormControl style={{ width: "150px", zIndex: 0 }}>
                    <InputLabel id="campaign-select-label">
                      Select campaign
                    </InputLabel>
                    <Select
                      labelId="campaign-select-label"
                      id="campaign-select"
                      onChange={handleSelectCampainChange}
                      value={selectedCampain || 0}
                      label="Select Campaign"
                    >
                      {theCampaign?.map((el: any) => (
                        <MenuItem value={el?.id} key={el?.id}>
                          {el?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}

          {/*begin::Card header*/}
          <div className="card-header d-flex align-items-center justify-content-between py-1 px-20 mx-10 gap-2 gap-md-5">
            {/*begin::Card toolbar*/}
            <div
              className="d-flex align-items-center gap-2 me-auto"

            >
              {/* Select Headers and Search Filter at the start */}




              {checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io" ?
                ""
                :
                <>
                  {selectedOption === "campaignListView" && (
                    <div className="w-100 mw-200px" style={{ marginLeft: "-35px" }}>
                      <FormControl fullWidth style={{ width: "150px", zIndex: 0 }}>
                        <InputLabel id="multi-select-label">
                          Select headers
                        </InputLabel>
                        <Select
                          labelId="multi-select-label"
                          id="multi-select"
                          multiple
                          value={selectedOptions.map((opt: any) => opt.id)} // Map to only IDs for Select value
                          onChange={(event) => {
                            const { value }: any = event.target;
                            if (value.includes("all")) {
                              handleSelectAll();
                            } else {
                              handleMultiSelectChange(value);
                            }
                          }}
                          renderValue={(selected) =>
                            selected.length === headerOption.length
                              ? "All"
                              : selected
                                .map(
                                  (id) =>
                                    headerOption.find((opt) => opt.id === id)
                                      ?.name
                                )
                                .join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          <MenuItem
                            value="all"
                            onClick={() => handleSelectAll()}
                            style={{ fontWeight: "bold" }}
                          >
                            <Checkbox
                              checked={
                                selectedOptions.length === headerOption.length
                              }
                              indeterminate={
                                selectedOptions.length > 0 &&
                                selectedOptions.length < headerOption.length
                              }
                            />
                            Select all
                          </MenuItem>

                          {headerOption.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <Checkbox
                                checked={selectedOptions.some(
                                  (opt: any) => opt.id === option.id
                                )}
                              />
                              {/* Wrap only the label in Tooltip */}
                              <Tooltip title={option.name} arrow>
                                <span>{truncateDescription(option.name, 11)}</span>
                              </Tooltip>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {selectedOption === "campaignListView" && (
                    <div className="d-flex align-items-center gap-2 me-auto">

                      <div className="w-100 mw-200px" style={{ marginLeft: "3px" }}>
                        <FormControl style={{ width: "150px", zIndex: 0 }}>
                          <InputLabel
                            id="team-select-label"
                            style={{ fontSize: "12px", marginLeft: "-5px" }}
                          >
                            {" "}
                            Select assignee
                          </InputLabel>
                          <Select
                            labelId="team-select-label"
                            id="team-select"
                            value={selectedTeamId}
                            onChange={handleTeamChange}
                            label="Select Team"
                          >
                            {localStorage.getItem("user_role") !== "agent" && (

                              <MenuItem key="all" value="My Team">
                                My Team
                              </MenuItem>
                            )}
                            <MenuItem key="all" value={localStorage.getItem("circleone-username")}>
                              Self
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="w-100 mw-100px">
                        <Button
                          variant="outlined"
                          className="text-hover-primary fw-bold"
                          onClick={openFilterModal}
                          sx={{
                            height: 50,
                            width: 140,
                            border: "1px solid #ccc",
                            gap: "5px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <i className="bi bi-filter text-dark fs-2" />
                          <span
                            className="ms-auto me-2 text-dark"
                            style={{
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                            }}
                          >
                            Search filter
                          </span>
                        </Button>

                        <ReactModal
                          isOpen={isFilterModalOpen}
                          onRequestClose={closeFilterModal}
                          style={customFilterModalStyles}
                          contentLabel="Campaign Filter Modal"
                        >
                          <CampaignFilterModel
                            closeFilterModal={closeFilterModal}
                            selectedFilterAndValue={selectedCampaignFilterAndValue}
                            setSelectedFilterAndValue={setSelectedFilterAndValue}
                            handleSubmit={handleSubmit}
                            setIsLoadingPageChange={setIsLoadingPageChange}
                          />
                        </ReactModal>
                      </div>
                    </div>

                  )}
                  {selectedOption === "campaignCardView" && (
                    <div className="d-flex align-items-center gap-2 me-auto">

                      <div className="w-100 mw-200px" style={{ marginLeft: "-11px" }}>
                        <FormControl style={{ width: "150px", zIndex: 0 }}>
                          <InputLabel
                            id="team-select-label"
                            style={{ fontSize: "12px", marginLeft: "-5px" }}
                          >
                            {" "}
                            Select assignee
                          </InputLabel>
                          <Select
                            labelId="team-select-label"
                            id="team-select"
                            value={selectedTeamId}
                            onChange={handleTeamChange}
                            label="Select Team"
                          >
                            {localStorage.getItem("user_role") !== "agent" && (

                              <MenuItem key="all" value="My Team">
                                My Team
                              </MenuItem>
                            )}
                            <MenuItem key="all" value={localStorage.getItem("circleone-username")}>
                              Self
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="w-100 mw-100px" style={{ marginLeft: "5%" }}>
                        <Button
                          variant="outlined"
                          className="text-hover-primary fw-bold"
                          onClick={openFilterModal}
                          sx={{
                            height: 50,
                            width: 140,
                            border: "1px solid #ccc",
                            gap: "5px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <i className="bi bi-filter text-dark fs-2" />
                          <span
                            className="ms-auto me-2 text-dark"
                            style={{
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                            }}
                          >
                            Search filter
                          </span>
                        </Button>

                        <ReactModal
                          isOpen={isFilterModalOpen}
                          onRequestClose={closeFilterModal}
                          style={customFilterModalStyles}
                          contentLabel="Campaign Filter Modal"
                        >
                          <CampaignFilterModel
                            closeFilterModal={closeFilterModal}
                            selectedFilterAndValue={selectedCampaignFilterAndValue}
                            setSelectedFilterAndValue={setSelectedFilterAndValue}
                            handleSubmit={handleSubmit}
                            setIsLoadingPageChange={setIsLoadingPageChange}
                          />
                        </ReactModal>
                      </div>
                    </div>
                  )}



                  {selectedOption === "campaignListCardView" && (
                    <div className="d-flex align-items-center gap-2 me-auto">

                      <div className="w-100 mw-200px" style={{ marginLeft: "6px" }}>
                        <FormControl style={{ width: "150px", zIndex: 0 }}>
                          <InputLabel
                            id="team-select-label"
                            style={{ fontSize: "12px", marginLeft: "-5px" }}
                          >
                            {" "}
                            Select assignee
                          </InputLabel>
                          <Select
                            labelId="team-select-label"
                            id="team-select"
                            value={selectedTeamId}
                            onChange={handleTeamChange}
                            label="Select Team"
                          >
                            {localStorage.getItem("user_role") !== "agent" && (

                              <MenuItem key="all" value="My Team">
                                My Team
                              </MenuItem>
                            )}
                            <MenuItem key="all" value={localStorage.getItem("circleone-username")}>
                              Self
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="w-100 mw-100px" style={{ marginLeft: "10px" }}>
                        <Button
                          variant="outlined"
                          className="text-hover-primary fw-bold"
                          onClick={openFilterModal}
                          sx={{
                            height: 50,
                            width: 140,
                            border: "1px solid #ccc",
                            gap: "5px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <i className="bi bi-filter text-dark fs-2" />
                          <span
                            className="ms-auto me-2 text-dark"
                            style={{
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                            }}
                          >
                            Search filter
                          </span>
                        </Button>


                        <ReactModal
                          isOpen={isFilterModalOpen}
                          onRequestClose={closeFilterModal}
                          style={customFilterModalStyles}
                          contentLabel="Campaign Filter Modal"
                        >
                          <CampaignFilterModel
                            closeFilterModal={closeFilterModal}
                            selectedFilterAndValue={selectedCampaignFilterAndValue}
                            setSelectedFilterAndValue={setSelectedFilterAndValue}
                            handleSubmit={handleSubmit}
                            setIsLoadingPageChange={setIsLoadingPageChange}
                          />
                        </ReactModal>
                      </div>
                    </div>
                  )}
                </>
              }
            </div>






            {/* <h5 
  onClick={navigateHandler} 
  style={{ margin: "0", cursor: "pointer", color: "blue" }}
  className="hover-underline"
>
  {outwardLeadButton ? "Outward" : "Inward"} lead dashboard
</h5> */}

            {/* <h5 onClick={navigateHandler} style={{margin: "0", cursor:" pointer", color: "blue"}}>  {outwardLeadButton ? "Outward" : "Inward"} lead dashboard</h5> */}

            {/* Other buttons and actions at the end */}
            {selectedOption === "campaignCardView" && (

              <div
                className="d-flex align-items-center gap-2"
                style={{ marginRight: "-36px" }}
              >
                {/* Lead Button */}
                <div>
                  <button
                    className="form-control form-control align-items-center text-hover-primary fw-bold min-w-150px"
                    onClick={openModal}
                    disabled={isButtonDisabled}
                  >
                    <i className="text-dark bi bi-plus-lg me-2"></i>
                    <span className="ms-auto">Lead</span>
                  </button>
                  <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={!isBulk ? customModalStyles : customEditModalStyles}
                    contentLabel="Lead"
                  >
                    <CampaignLeadModel
                      closeModal={closeModal}
                      isBulk={isBulk}
                      setIsBulk={setIsBulk}
                      refetchWorkflow={refetchWorkflow}
                      refetch={refetch}
                      campaignId={campaignId}
                      value={value}
                      setValue={setValue}
                      setStoreKey={setStoreKey}
                      selectedCampaign={selectedCampaign}
                    />
                  </ReactModal>
                </div>

                {/* Tasks Button */}

                {checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io" ?
                  ""
                  :
                  <>
                    <div>
                      <button
                        className="form-control form-control align-items-center text-hover-primary fw-bold min-w-150px"
                        onClick={handleNavigate}
                      >
                        <i className="bi bi-list-task text-dark me-2"></i>
                        <span className="ms-auto">Tasks</span>
                      </button>
                    </div>

                    {/* Assignee Button */}
                    <a
                      className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      data-bs-placement="top"
                      aria-label="Assignee"
                      data-bs-original-title="Assignee"
                      data-kt-initialized={1}
                      title="Assignee"
                      onClick={openBulkModal}
                    >
                      <i className="bi bi-person-fill fs-2" />
                    </a>
                  </>
                }
                <ReactModal
                  isOpen={isBulkAssigneeModalOpen}
                  onRequestClose={closeBulkModal}
                  style={customModalStyles1}
                  contentLabel="Bulk Assignee"
                >
                  <BulkAssigneeLeadModel
                    closeBulkModal={closeBulkModal}
                    selectedCampaign={selectedTickets}
                    refetch={refetch}
                  />
                </ReactModal>
                <a href="#" className="btn btn-link">
                  <i
                    className="text-dark fs-2 bi bi-trash"
                    onClick={openBulkDeleteModal}
                  ></i>
                </a>

                <ReactModal
                  isOpen={isBulkDeleteModalOpen}
                  onRequestClose={closeBulkDelateModal}
                  style={customResetPassModalStyles}
                  contentLabel="Delete Campaign"
                >
                  <DeleteBulkLeadModel
                    selectedCampaign={selectedTickets}
                    closeDeleteCampaignModal={closeBulkDelateModal}
                    refetch={refetch}
                  />
                </ReactModal>

                {checkTenant === "meta" &&


                  <a
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    data-bs-placement="top"
                    aria-label="Broadcast"
                    data-bs-original-title="Broadcast"
                    data-kt-initialized={1}
                    title="Broadcast"
                    onClick={openBulkBroadcastModal}
                  >
                    <i className="bi bi-broadcast fs-2" />
                  </a>

                }



                <ReactModal
                  isOpen={isBulkBrodardcastModalOpen}
                  onRequestClose={closeBulkBroadcastModal}
                  style={customModalStyles1}
                  contentLabel="Bulk Broadcast"
                >
                  <BroadcastModal
                    closeBulkModal={closeBulkBroadcastModal}
                    selectedCampaign={selectedTickets}
                    refetch={refetch}
                    campaignSelected={selectedCampain}
                  />
                </ReactModal>


              </div>
            )}


            {selectedOption === "campaignListView" && (

              <div
                className="d-flex align-items-center gap-2"
                style={{ marginRight: "-64px" }}
              >
                {/* Lead Button */}
                <div>
                  <button
                    className="form-control form-control align-items-center text-hover-primary fw-bold min-w-150px"
                    onClick={openModal}
                    disabled={isButtonDisabled}
                  >
                    <i className="text-dark bi bi-plus-lg me-2"></i>
                    <span className="ms-auto">Lead</span>
                  </button>
                  <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={!isBulk ? customModalStyles : customEditModalStyles}
                    contentLabel="Lead"
                  >
                    <CampaignLeadModel
                      closeModal={closeModal}
                      isBulk={isBulk}
                      setIsBulk={setIsBulk}
                      refetchWorkflow={refetchWorkflow}
                      refetch={refetch}
                      campaignId={campaignId}
                      value={value}
                      setValue={setValue}
                      setStoreKey={setStoreKey}
                      selectedCampaign={selectedCampaign}
                    />
                  </ReactModal>
                </div>

                {/* Tasks Button */}
                {checkTeam === "Botgo" && checkUsername === "vivek@gtbotgo.io" ?
                  ""
                  :
                  <>
                    <div>
                      <button
                        className="form-control form-control align-items-center text-hover-primary fw-bold min-w-150px"
                        onClick={handleNavigate}
                      >
                        <i className="bi bi-list-task text-dark me-2"></i>
                        <span className="ms-auto">Tasks</span>
                      </button>
                    </div>

                    {/* Assignee Button */}
                    <a
                      className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      data-bs-placement="top"
                      aria-label="Assignee"
                      data-bs-original-title="Assignee"
                      data-kt-initialized={1}
                      title="Assignee"
                      onClick={openBulkModal}
                    >
                      <i className="bi bi-person-fill fs-2" />
                    </a>
                  </>
                }
                <ReactModal
                  isOpen={isBulkAssigneeModalOpen}
                  onRequestClose={closeBulkModal}
                  style={customModalStyles1}
                  contentLabel="Bulk Assignee"
                >
                  <BulkAssigneeLeadModel
                    closeBulkModal={closeBulkModal}
                    selectedCampaign={selectedTickets}
                    refetch={refetch}
                  />
                </ReactModal>
                <a href="#" className="btn btn-link">
                  <i
                    className="text-dark fs-2 bi bi-trash"
                    onClick={openBulkDeleteModal}
                  ></i>
                </a>

                <ReactModal
                  isOpen={isBulkDeleteModalOpen}
                  onRequestClose={closeBulkDelateModal}
                  style={customResetPassModalStyles}
                  contentLabel="Delete Campaign"
                >
                  <DeleteBulkLeadModel
                    selectedCampaign={selectedTickets}
                    closeDeleteCampaignModal={closeBulkDelateModal}
                    refetch={refetch}
                  />
                </ReactModal>

                {checkTenant === "meta" &&


                  <a
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    data-bs-placement="top"
                    aria-label="Broadcast"
                    data-bs-original-title="Broadcast"
                    data-kt-initialized={1}
                    title="Broadcast"
                    onClick={openBulkBroadcastModal}
                  >
                    <i className="bi bi-broadcast fs-2" />
                  </a>

                }



                <ReactModal
                  isOpen={isBulkBrodardcastModalOpen}
                  onRequestClose={closeBulkBroadcastModal}
                  style={customModalStyles1}
                  contentLabel="Bulk Broadcast"
                >
                  <BroadcastModal
                    closeBulkModal={closeBulkBroadcastModal}
                    selectedCampaign={selectedTickets}
                    refetch={refetch}
                    campaignSelected={selectedCampain}
                  />
                </ReactModal>


              </div>
            )}

            {selectedOption === "campaignListCardView" && (

              <div
                className="d-flex align-items-center gap-2"
                style={{ marginRight: "-54px" }}
              >
                {/* Lead Button */}
                <div>
                  <button
                    className="form-control form-control align-items-center text-hover-primary fw-bold min-w-150px"
                    onClick={openModal}
                    disabled={isButtonDisabled}
                  >
                    <i className="text-dark bi bi-plus-lg me-2"></i>
                    <span className="ms-auto">Lead</span>
                  </button>
                  <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={!isBulk ? customModalStyles : customEditModalStyles}
                    contentLabel="Lead"
                  >
                    <CampaignLeadModel
                      closeModal={closeModal}
                      isBulk={isBulk}
                      setIsBulk={setIsBulk}
                      refetchWorkflow={refetchWorkflow}
                      refetch={refetch}
                      campaignId={campaignId}
                      value={value}
                      setValue={setValue}
                      setStoreKey={setStoreKey}
                      selectedCampaign={selectedCampaign}
                    />
                  </ReactModal>
                </div>

                {/* Tasks Button */}
                <div>
                  <button
                    className="form-control form-control align-items-center text-hover-primary fw-bold min-w-150px"
                    onClick={handleNavigate}
                  >
                    <i className="bi bi-list-task text-dark me-2"></i>
                    <span className="ms-auto">Tasks</span>
                  </button>
                </div>

                {/* Assignee Button */}
                <a
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="top"
                  aria-label="Assignee"
                  data-bs-original-title="Assignee"
                  data-kt-initialized={1}
                  title="Assignee"
                  onClick={openBulkModal}
                >
                  <i className="bi bi-person-fill fs-2" />
                </a>
                <ReactModal
                  isOpen={isBulkAssigneeModalOpen}
                  onRequestClose={closeBulkModal}
                  style={customModalStyles1}
                  contentLabel="Bulk Assignee"
                >
                  <BulkAssigneeLeadModel
                    closeBulkModal={closeBulkModal}
                    selectedCampaign={selectedTickets}
                    refetch={refetch}
                  />
                </ReactModal>
                <a href="#" className="btn btn-link">
                  <i
                    className="text-dark fs-2 bi bi-trash"
                    onClick={openBulkDeleteModal}
                  ></i>
                </a>

                <ReactModal
                  isOpen={isBulkDeleteModalOpen}
                  onRequestClose={closeBulkDelateModal}
                  style={customResetPassModalStyles}
                  contentLabel="Delete Campaign"
                >
                  <DeleteBulkLeadModel
                    selectedCampaign={selectedTickets}
                    closeDeleteCampaignModal={closeBulkDelateModal}
                    refetch={refetch}
                  />
                </ReactModal>

                {checkTenant === "meta" &&


                  <a
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    data-bs-placement="top"
                    aria-label="Broadcast"
                    data-bs-original-title="Broadcast"
                    data-kt-initialized={1}
                    title="Broadcast"
                    onClick={openBulkBroadcastModal}
                  >
                    <i className="bi bi-broadcast fs-2" />
                  </a>

                }



                <ReactModal
                  isOpen={isBulkBrodardcastModalOpen}
                  onRequestClose={closeBulkBroadcastModal}
                  style={customModalStyles1}
                  contentLabel="Bulk Broadcast"
                >
                  <BroadcastModal
                    closeBulkModal={closeBulkBroadcastModal}
                    selectedCampaign={selectedTickets}
                    refetch={refetch}
                    campaignSelected={selectedCampain}
                  />
                </ReactModal>


              </div>
            )}
            {/*end::Card toolbar*/}
          </div>

          {/*end::Card header*/}
          {/*begin::Hover dropdown*/}
          {/* <div className="card-body pt-5">
            <Dropdown
              menuRef={filterDropdownMenuRef}
              droppedDown={filterMenuDroppedDown}
              setDroppedDown={setFilterMenuDroppedDown}
              width={250}
              hideOnBlur={true}
            >
              <LeadFilterMenu />
            </Dropdown>
          </div> */}

          {/*end::Hover dropdown*/}
          <div className="card-body d-flex flex-column">
            {isLoadingPageChange ? (
              <div className="d-flex justify-content-center align-items-center">
                <LoadingSpinner />
              </div>
            ) : data?.length === 0 ? (
              <div className="text-center">No data available.</div>
            ) : (
              <>
                {/*begin::Card body*/}
                {selectedOption === "campaignListCardView" && (
                  <CampaignListCardView
                    listOfLeads={listOfLeads}
                    taskRes={taskRes}
                    CampaignWorkflow={CampaignWorkflow}
                    value={value}
                    setValue={setValue}
                    setStoreKey={setStoreKey}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    useData={useData}
                    campign={data}
                    setSelectedTickets={setSelectedTickets}
                    selectedTickets={selectedTickets}
                    setLiftUiData={setLiftUiData}
                    refetch={refetch}
                    selectedWorkFlow={workflowName}
                    selectCampaign={campaignId}
                    selectedOptions={selectedOptions}
                    checkTenant={checkTenant}

                  />
                )}
                {selectedOption === "campaignListView" && (
                  <CampaignListView
                    listOfLeads={listOfLeads}
                    taskRes={taskRes}
                    CampaignWorkflow={CampaignWorkflow}
                    value={value}
                    setValue={setValue}
                    setStoreKey={setStoreKey}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    useData={useData}
                    campign={data}
                    setSelectedTickets={setSelectedTickets}
                    selectedTickets={selectedTickets}
                    selectedOptions={selectedOptions}
                    checkTenant={checkTenant}
                  />
                )}

                {selectedOption === "campaignCardView" && (
                  <CampaignCardView
                    listOfLeads={listOfLeads}
                    taskRes={taskRes}
                    CampaignWorkflow={CampaignWorkflow}
                    value={value}
                    setValue={setValue}
                    setStoreKey={setStoreKey}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    useData={useData}
                    campign={data}
                    setSelectedTickets={setSelectedTickets}
                    selectedTickets={selectedTickets}
                    selectedOptions={selectedOptions}
                    checkTenant={checkTenant}

                  />
                )}
              </>
            )}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Products*/}
      </div>
    </div>
  );
};
// I want when 
export default CampaignList;