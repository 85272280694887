import React, { useState, useEffect } from "react";
import { useTelephony } from "../context/TelephonyContext";
import { INCOMING_CALL, OUTGOING_CALL, TELEPHONY_LOGIN } from "../../constants";

interface IPROPS {
    callPaused: any;
    call: any;
    isCallPause: any;
}


const TelephonyTimer: React.FC<IPROPS> = ({ callPaused, call, isCallPause }) => {
    const { callDisconnected } = useTelephony();

    const [timeInSeconds, setTimeInSeconds] = useState<number>(() => {
        const savedTime = localStorage.getItem("telephony-timer");
        return savedTime ? parseInt(savedTime, 10) : 0;
    });


    const incomingCallActive = localStorage.getItem(INCOMING_CALL) === "true"
    const outgoingCallActive = localStorage.getItem(OUTGOING_CALL) === "true"


    
    // Format seconds into HH:MM:SS
    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
        )}:${String(secs).padStart(2, "0")}`;
    };

    useEffect(() => {
        if (callDisconnected) {
            setTimeInSeconds(0);
            localStorage.removeItem("telephony-timer");
        }
    }, [callDisconnected]);



    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
    
        if (incomingCallActive || outgoingCallActive) {
            timer = setInterval(() => {
                setTimeInSeconds((prev) => {
                    const updatedTime = prev + 1;
                    // localStorage.setItem("telephony-timer", updatedTime.toString());
                    return updatedTime;
                });
            }, 1000);
        }
    
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [incomingCallActive, outgoingCallActive]); // Add dependencies to control re-running the effect
    

    const gettingTelephonyLoginData = localStorage.getItem(TELEPHONY_LOGIN)


    return (
        <div style={{ display: "flex" }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <i
                     data-toggle="tooltip"
                     data-placement="top"
                     title={gettingTelephonyLoginData === null ? "logout" : call ? "On Call" : !callPaused ? "On Pause" : "Ready"}
                    className="bi bi-circle-fill me-4"
                    style={{
                        fontSize: "20px",
                        color:gettingTelephonyLoginData === null ? "red" : call ? "orange" : !callPaused ? "yellow" : "#7acf7a",
                    }}
                ></i>
            </div>
            <div style={{ textAlign: "center", height: "100%" }}>
                <span style={{ padding: "10px" }} className="form-control">
                    {formatTime(timeInSeconds)}
                </span>
            </div>
        </div>
    );
};

export default TelephonyTimer;
