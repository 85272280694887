import React, { useState } from "react";
import { useBulkUploadMutation } from "../../Services/user/Users";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { useBulkLeadUploadMutation } from "../../Services/campign/campignList";
import { Box, LinearProgress } from "@mui/material";
import { dynamicBaseQuery } from "../../Services/badRequestHandler/BadRequestHandler";

interface IProps {
  closeModal: any;
  refetch: any;
  dynamicData: any;
  campaignId: any;
  refetchWorkflow: any;
}

const BulkLeadModel: React.FC<IProps> = ({
  closeModal,
  refetch,
  dynamicData,
  campaignId,
  refetchWorkflow,
}) => {
  const [selectedCsvFiles, setSelectedCsvFiles] = useState<File[]>([]);
  const [fileToDownload, setFileToDownload] = useState<File | null>(null);
  const [failDetails, setFailDetails] = useState<any[]>([]); // Store fail details here
  const [triggerBulkUpload] = useBulkLeadUploadMutation({});
  const [isAssigneeCheck, setIsAssigneeCheck] = useState(false);
  const [progress, setProgress] = React.useState(null);
  const [showingLoader, setShowingLoader] = useState(false)




  const handleCsvFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     if (event.target.files) {
       const files = Array.from(event.target.files);
       setSelectedCsvFiles(files);
       setFileToDownload(files[0]);
     }
   };
 
   const handleRemoveCsvFile = (index: number) => {
     const newFiles = Array.from(selectedCsvFiles);
     newFiles.splice(index, 1);
     setSelectedCsvFiles(newFiles);
     if (index === 0 && newFiles.length > 0) {
       setFileToDownload(newFiles[0]); // Update the file to be downloaded if the first file was removed
     } else if (index === 0) {
       setFileToDownload(null); // Clear file to download if no files are left
     }
   };

 
   const handleCsvDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Test Worksheet");

    // Define header row
    const header = [
        "campaign_account_name",
        "campaign_first_name",
        "campaign_last_name",
        "campaign_email",
        "campaign_phone_number"
    ];


   

    if (dynamicData && dynamicData.length) {
        dynamicData.forEach((field, index) => {
            const csvKey = field.label.replace(/ /g, "_"); // Unique column names
            header.push(`campaign_${csvKey}`);
        });
    }


    if (isAssigneeCheck) {
      header.push("campaign_assignee");
  }
    // Add header row before any cell modifications
    ws.addRow(header);

    // Ensure 100 rows exist to avoid "A Cell needs a Row" error
    for (let i = 2; i <= 100; i++) {
        ws.addRow([]);
    }

    // Set column widths
    ws.columns.forEach((col) => {
        col.width = 18;
    });

    // Apply styles to header row
    const headerRow = ws.getRow(1);
    headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFADD8E6" },
    };

    // Style required fields in red
    const requiredHeaders = [
      // "campaign_account_name", 
      // "campaign_first_name", 
      // "campaign_last_name", 
      // "campaign_email", 
      "campaign_phone_number"
    ];
    requiredHeaders.forEach((headerName) => {
        const colIndex = header.indexOf(headerName) + 1;
        if (colIndex) {
            ws.getCell(1, colIndex).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" }, // Red color for required headers
            };
        }
    });

    // Highlight required dynamic fields
    dynamicData.forEach((field, index) => {
      const columnIndex = header.length - dynamicData.length + index + 1; // Adjust for the current dynamic field
      const columnLetter = String.fromCharCode(64 + columnIndex);
  
      if (field.field_type === "check-box") {
        const checkboxOptions = field.multiple_choices
          .map((choice) => choice.choice)
          .join(",");
  
        // Add dropdown validation for checkboxes
        for (let i = 2; i <= 100; i++) {
          ws.getCell(`${columnLetter}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${checkboxOptions}"`],
          };
        }
      } else if (field.field_type === "drop-down") {
        const fieldOptions = field?.choices.map((option: any) => option.choice);
  
        // Add dropdown validation for drop-downs
        for (let i = 2; i <= 100; i++) {
          ws.getCell(`${columnLetter}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${fieldOptions}"`],
          };
        }
      }

    });

    if (dynamicData && dynamicData.length) {
      dynamicData.forEach((field, index) => {
        if (field.is_required) {
          const columnIndex = header.length - dynamicData.length + index + 1; // Adjust for the current dynamic field
          const columnLetter = String.fromCharCode(64 + columnIndex);
          ws.getCell(`${columnLetter}1`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Red color for required dynamic fields
          };
        }
      });
    }

    // Generate Excel file
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));

    // Create download link
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "campaign_template.xlsx";
    document.body.appendChild(link);
    link.click();

    // Cleanup
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
};

   const handleDownloadFailDetails = async () => {
     const workbook = new ExcelJS.Workbook();
     const ws = workbook.addWorksheet("Failed Uploads");
   
     // Prepare header for static fields
     let header = [
      "campaign_account_name",
      "campaign_first_name",
      "campaign_last_name",
      "campaign_email",
      "campaign_phone_number",
    ];
   
     // Dynamically add dynamic field headers
     if (dynamicData && dynamicData.length) {
       dynamicData.forEach((field) => {
         const csvKey = field.label.replace(/ /g, "_"); // Replace spaces with underscores
         header.push(`campaign_${csvKey}`); // Dynamic field label in header
       });
     }
   
     // Errors column is added at the last
     header.push("Errors");
   
     // Add headers to the worksheet
     ws.addRow(header);
   
     // Set column widths
     ws.columns.map((col) => (col.width = 18));
   
     // Iterate through failDetails to populate rows
     failDetails.forEach((fail) => {
       const { account_name,first_name, last_name,email, phone_number, dynamic_fields, error } = fail;
       
   
       // Prepare dynamic fields data in the same structure as CSV
       const dynamicFieldValues = dynamicData.map((field) => {
         const fieldKey = field.label.replace(/ /g, "_");
         return dynamic_fields[fieldKey] || ""; // Return 'N/A' if value is missing
       });
   
       // Convert errors into string for easier display in Excel
       const errorString = Array.isArray(error)
         ? error.join(", ")
         : JSON.stringify(error);
   
       // Apply styles to all cells
       ws.eachRow((row) => {
         row.eachCell((cell) => {
           cell.font = {
             name: "Inter",
             size: 8,
           };
           cell.alignment = {
             horizontal: "center",
           };
         });
       });
   
       // Add the row with dynamic fields and the error at the end
       ws.addRow([
         account_name,
         first_name,
         last_name,
         email,
         phone_number,

         ...dynamicFieldValues, // Spread dynamic field values here
         errorString, // Errors at the end
       ]);
     });
   
     // Apply specific red fill style to headers
     const redHeaders = [
      // "campaign_account_name",
      // "campaign_first_name",
      // "campaign_last_name",
      // "campaign_email",
      "campaign_phone_number",
    ];
     redHeaders.forEach((headerName) => {
       const colIndex = header.indexOf(headerName) + 1; // Find column index for each red header
       ws.getCell(1, colIndex).fill = {
         type: "pattern",
         pattern: "solid",
         fgColor: { argb: "FFFF0000" }, // Red color for specific headers
       };
     });
   
     // Apply red fill to required dynamic field headers
     if (dynamicData && dynamicData.length) {
       dynamicData.forEach((field, index) => {
         if (field.is_required) {
           const columnIndex = header.indexOf(`campaign_${field.label.replace(/ /g, "_")}`) + 1;
           ws.getCell(1, columnIndex).fill = {
             type: "pattern",
             pattern: "solid",
             fgColor: { argb: "FFFF0000" }, // Red color for required dynamic fields
           };
         }
       });
     }
   
     // Create the Excel file and initiate the download
     const excelBlob = await workbook.xlsx.writeBuffer();
     const excelUrl = URL.createObjectURL(
       new Blob([excelBlob], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
       })
     );
   
     const link = document.createElement("a");
     link.href = excelUrl;
     link.download = "failed_uploads.xlsx";
     document.body.appendChild(link);
     link.click();
   
     // Clean up temporary URLs and DOM elements
     URL.revokeObjectURL(excelUrl);
     document.body.removeChild(link);
     closeModal();
   };
   
   const cleanCsvData = (csvContent: string, percentage: number) => {
     const rows = csvContent.split("\n").map((row) => row.split(","));
     const cleanedRows = rows.filter((row) => row.some((cell) => cell.trim() !== ""));
     const [headers, ...dataRows] = cleanedRows;
   
     const totalRows = Math.ceil((dataRows.length * percentage) / 100);
     const limitedRows = dataRows.slice(0, totalRows);
   
     const cleanedData = limitedRows.map((row) =>
       headers.reduce((acc, header, index) => {
         acc[header.trim()] = row[index]?.trim() || "";
         return acc;
       }, {})
     );
   
     return cleanedData;
   };
   

   const createCsvContent = (data: any[]) => {
     if (data.length === 0) return "";
     const headers = Object.keys(data[0]);
     const rows = data.map((row) => headers.map((header) => row[header]).join(","));
     return [headers.join(","), ...rows].join("\n");
   };
 
   React.useEffect(() => {
     const timer = setInterval(() => {
       setProgress((oldProgress) => {
         if (oldProgress === 100) {
           return 0;
         }
         return oldProgress;
       });
     }, 500);
   
     return () => {
       clearInterval(timer);
     };
   }, []);


   const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
     event.preventDefault();
   
     if (selectedCsvFiles.length > 0) {
     setShowingLoader(true)
 
       const file = selectedCsvFiles[0];
       const fileExtension = file.name.split(".").pop()?.toLowerCase();
   
       const reader = new FileReader();
   
       reader.onload = async (e) => {
         let csvContent: string | undefined;
   
         if (e.target?.result) {
           if (fileExtension === "csv") {
             csvContent = e.target.result as string;
           } else if (fileExtension === "xlsx") {
             const binaryStr = e.target.result as string;
             const workbook = XLSX.read(binaryStr, { type: "binary" });
             const firstSheetName = workbook.SheetNames[0];
             const worksheet = workbook.Sheets[firstSheetName];
             csvContent = XLSX.utils.sheet_to_csv(worksheet);
           }
   
           if (csvContent) {
             const cleanedData = cleanCsvData(csvContent, 100);
   
             const partSize = Math.ceil(cleanedData.length / 5);
             const dividedData = [];
             for (let i = 0; i < cleanedData.length; i += partSize) {
               dividedData.push(cleanedData.slice(i, i + partSize));
             }
   
             try {
               let combinedFailDetails: any[] = [];
               const totalParts = dividedData.length;
               let allSuccess = false;
   
               for (let i = 0; i < totalParts; i++) {
                 const part = dividedData[i];
                 const csvContentPart = createCsvContent(part);
   
                 const requestObj = {
                  campaign: campaignId,
                   file_name: `Part_${i + 1}_${file.name}`,
                   csv_file: `data:text/csv;base64,${btoa(csvContentPart)}`,
                 };
   
                 const response = await triggerBulkUpload({ body: requestObj });
  
                 if (
                   !response?.data?.fail_count &&
                   !response?.data?.success_count
                 ) {
                   console.warn("No meaningful response; exiting loop.");
                   allSuccess = false;
                   break;
                 }



           
   
                 if (response) {
                   combinedFailDetails = [
                     ...combinedFailDetails,
                     ...(response?.data?.fail_details || []),
                   ];

                  
                   if (combinedFailDetails?.length > 0) {
                    allSuccess = false

                   }
                   else if (response?.data?.success_count) {
                    allSuccess = true

                   }
 
                 } 
                 
              
   
                 // Update progress dynamically
                 setProgress(((i + 1) / totalParts) * 100);
               }
   
               setFailDetails(combinedFailDetails);
               setSelectedCsvFiles([]);
               refetch();
   
               // Display toast based on overall success
               if (!allSuccess) {
                toast.warning("Some date failed to upload")

               } else if (allSuccess) {
                 toast.success("File uploaded successfully");
               }
               else {
                toast.error("No data found")
               }
               setShowingLoader(false)
             } catch (error) {
               setShowingLoader(false)
               toast.error("Upload failed. Please try again.");
             }
           }
         }
       };
   
       if (fileExtension === "csv") {
         reader.readAsText(file);
       } else if (fileExtension === "xlsx") {
         reader.readAsBinaryString(file);
       } else {
         toast.error(
           "Unsupported file format. Please upload a CSV or XLSX file."
         );
       }
     }
   };




console.log(progress, "progresssss")
  const checkboxStyle: any = {
    width: "20px",
    height: "20px",
    border: "1px solid #009ef7",
    backgroundColor: isAssigneeCheck ? "#009ef7" : "transparent",
    position: "relative",
    cursor: "pointer",
    display: "inline-block",
    appearance: "none", // Removes default styling
    WebkitAppearance: "none", // For WebKit browsers
  };

  const checkmarkStyle: any = {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(45deg)",
    width: "5px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    display: isAssigneeCheck ? "block" : "none", // Only show when checked
  };


  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-4">
        <div className="d-flex justify-content-evenly mt-2 mb-8 mx-1">
          {/* CSV Upload Button */}
          <label
            htmlFor="upload-csv-button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-2 px-3 py-2 rounded mw-150px"
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-file-earmark-spreadsheet text-dark fs-4 me-2"></i>
            CSV Upload
          </label>
          <input
            type="file"
            id="upload-csv-button"
            accept=".csv, .xlsx"
            style={{ display: "none" }}
            onChange={handleCsvFileChange}
          />

          {/* CSV Download Button */}
          <button
            type="button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-2 px-3 py-2 rounded mw-150px"
            onClick={handleCsvDownload}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-download text-dark fs-4 me-2"></i>
            CSV Download
          </button>
        </div>
        <div className="d-flex justify-content-start mt-2 mb-8 mx-4" style={{paddingLeft:"26px"}}>
          <div
            style={checkboxStyle}
            onClick={() => setIsAssigneeCheck(!isAssigneeCheck)} // Handle toggle
          >
            <div style={checkmarkStyle}></div>
          </div>
          <label htmlFor="show-fail-details" className="ms-2">Assign to user</label>
        </div>

        {showingLoader ? 

<Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
    : ""
        }
        <div className="mt-3 mb-3" style={{paddingLeft:"38px"}}>
          <label className="fs-6 fw-bolder mb-2">Important Instructions:</label>
          <br />
          <label className="text-danger">
            &bull; Please upload a valid CSV or XLSX file format only.
          </label>
          <br />
          <label className="text-danger">
            &bull; Headers highlighted in red are mandatory fields.
          </label>
        </div>

        <div>

        {failDetails?.length > 0 && (
          <div style={{width: "100%", display: "flex", justifyContent: "center"}}>

          <button
            type="button"
            className="form-control d-flex align-items-center justify-content-center bg-secondary fw-bold mx-6 px-3 py-2 rounded mw-150px"
            onClick={handleDownloadFailDetails}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-download text-dark fs-4 me-2"></i>
            Error Data
          </button>
          </div>

        )}
        </div>


        {/* Display selected CSV files */}
        {selectedCsvFiles?.length > 0 && (
          <div className="mb-4" style={{paddingLeft:"35px"}}>
            <h5>Selected CSV file:</h5>
            <ul className="list-group mt-3">
              {selectedCsvFiles.map((file, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>{file.name}</span>
                  <button
                    type="button"
                    className="btn btn-link text-danger p-0"
                    onClick={() => handleRemoveCsvFile(index)}
                  >
                    <i className="bi bi-trash text-danger fs-5"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mb-4 pt-2" style={{marginTop:"12%"}}>
        <button
          type="button"
          className="btn btn-sm btn-white  me-2"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-sm btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
};

export default BulkLeadModel;