import React, { useState } from "react";
import {Autocomplete, TextField, Checkbox, FormControlLabel, MenuItem, Select, Box, Chip,  InputAdornment, CircularProgress } from "@mui/material";
import { CloseButton, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // React Quill CSS
import { toast } from "react-toastify";
import { useUpdateTaskDetailsMutation } from "../../Services/ticket/CreateTask";
import axios from "axios";

interface IProps {
    closeModal: () => void;
    selectedRow: any;
    refetch: any;
}

const ViewTaskModal: React.FC<IProps> = ({ closeModal, selectedRow, refetch }) => {

    console.log(selectedRow, "selectedRoww")
    const [showReminderDropdown, setShowReminderDropdown] = useState(false);
    const [status, setStatus] = useState(selectedRow?.status); // To handle status dropdown value
    const [editorValue, setEditorValue] = useState(selectedRow?.event_description); // To handle React Quill editor value
    const [title, setTitle] = useState<any>(selectedRow?.title)
    const [date, setDate] = useState<any>(selectedRow?.event_date)
    const [time, setTime] = useState<any>(selectedRow?.start_time)
    const [remainder, setRemainder] = useState<any>(selectedRow?.reminder_time)
    const [guests, setGuests] = useState(selectedRow?.guests[0])
    const [files, setFiles] = useState([]);
        const [loading, setLoading] = useState(false);
            const [selectedContacts, setSelectedContacts] = useState<any[]>(selectedRow?.guests); 
                const [suggestions, setSuggestions] = useState<any[]>([]); 
    
    const collection = selectedRow?.id

    console.log(guests, "gueststs")

    // const { data: taskCollection, isLoading: taskCollectionLoading, error: taskCollectionError } = useTaskCollectionQuery({ collection });
    const [updateTask] = useUpdateTaskDetailsMutation();



    const options = [
        { value: '00:05:00', text: '5 mins Prior to Task' },
        { value: '00:15:00', text: '15 mins Prior to Task' },
        { value: '00:30:00', text: '30 mins Prior to Task' },
        { value: '01:00:00', text: '60 mins Prior to Task' },
    ];

    const statusOptions = [
        { value: 'active', text: 'Active' },
        { value: 'rescheduled', text: 'Re-Scheduled' },
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'done', text: 'Done' },
    ];

    const fetchingTenant = window.location.hostname.split(".")[0];
    const apiPort = process.env.REACT_APP_API_PROTOCOL;

    const handleDeleteFile = (fileToDelete) => () => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };
    const handleFileChange = async (event) => {
        const newFiles = Array.from(event.target.files);
        const filePromises = newFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileData = {
                        file_name: file.name,
                        file: e.target.result.split(",")[1], // Extracting Base64 part
                    };
                    resolve(fileData);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        });

        try {
            const fileDataArray = await Promise.all(filePromises);
            setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
        } catch (error) {
            console.error("Error reading files:", error);
            toast.error("An error occurred while uploading files. Please try again.");
        }
    };


    const taskUpdateHandler = async () => {
   

        // attachments: createTaskInfo?.attachments,
        // created_by: selectedUser,
        // ticket: ticket?.id,
        // team: "",
        // template_attachments: [],
        // assignee: ticket?.assignee?.id,
        // assigned_by: ticket?.assignee?.username,
        // status_type: ticket?.status_type,
        // dynamic_fields: {},
        // static_fields: {},
        const id = selectedRow?.id
        try {
            const payload =
            {
                "title": title,
                "event_date": date,
                "start_time": time,
                "reminder_time": remainder,
                "status": status,
                "event_description": editorValue,
                "created_by": collection,
                // "created_by": taskCollection?.created_by.id,
                "attachments": files,
                "guests": selectedContacts
            }
            await updateTask({ body: payload, id });

            toast.success(`Task updated successfully`);
            refetch();
            closeModal();

        } catch (err) {
            toast.error("Failed to assign. Please try again.");
            console.error("Error assigning user:", err);
        } finally {

        }
    };

      const searchContactByMailID = (skey: string) => {
                setLoading(true); // Set loading to true before the request
              
                axios
                  .get(
                    `${apiPort}://${fetchingTenant}.${process.env.REACT_APP_BASE_URL}/v1/customers/contacts/search-by-email/`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
                      },
                      params: {
                        email: skey,
                      },
                    }
                  )
                  .then((res: any) => {
                    if (res.status === 200) setSuggestions(res.data);
                  })
                  .catch((err) => console.log(err))
                  .finally(() => {
                    setLoading(false); // Set loading to false after request completes
                  });
              };

    const handleAddGuest = (newValue: string) => {
        if (newValue && !selectedContacts.includes(newValue)) {
          setSelectedContacts((prev) => [...prev, newValue]); // Add email to array
        }
      };

      const handleRemoveGuest = (emailToRemove: string) => {
        setSelectedContacts((prev) => prev.filter((email) => email !== emailToRemove));
      };
    return (
        <div style={{display: "flex", gap: "20px", flexDirection: "column"}}>
            <div className="p-4">
                {/* Modal Header */}
                <div className="d-flex justify-content-end">
                    <div>
                        <CloseButton onClick={closeModal} />
                    </div>
                    {/* Update Button */}

                </div>
                <div className="text-center mb-4">
                    <h5 className="fs-4 fw-bold text-dark">View Task</h5>
                </div>

                {/* Task Details */}
                <div className="">
                    <table className="table table-striped table-bordered">
                        <tbody>
                            {/* <tr>
                                <td className="fw-bold">Account name</td>
                                <th colSpan={3}>
                                    {taskCollection?.account_name}
                                    <a
                                        href={`/campaign/lead/${taskCollection?.id}`}
                                        className="float-end text-decoration-none text-primary"
                                    >
                                        View lead details
                                    </a>
                                </th>
                            </tr> */}
                        

                        
                        </tbody>
                    </table>



                    {/* Subject Field */}
                    <div className="w-100 mb-4">
                        <label className="required">Title</label>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={title}
                            className="form-control form-control-lg form-control-solid"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    {/* Date and Time Fields */}
                    <div className="d-flex gap-3 mb-4">
                        <div className="flex-fill">
                            <label className="required">Date</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="date"
                                autoComplete="off"
                                InputLabelProps={{ shrink: true }}
                                className="form-control form-control-lg form-control-solid"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </div>

                        <div className="flex-fill">
                            <label className="required">Time</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="time"
                                autoComplete="off"
                                InputLabelProps={{ shrink: true }}
                                className="form-control form-control-lg form-control-solid"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Reminder Checkbox */}
                    <div className="w-100 mb-4">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={showReminderDropdown}
                                    onChange={(e) => setShowReminderDropdown(e.target.checked)} // Toggle dropdown visibility
                                />
                            }
                            label="Reminder At"
                            className="d-flex align-items-center"
                        />
                    </div>

                    {/* Reminder Time Dropdown */}
                    {showReminderDropdown && (
                        <div className="w-100 mb-4">
                            <label className="required">Reminder time</label>
                            <Select
                                fullWidth
                                value={remainder}
                                onChange={(e) => setRemainder(e.target.value)}
                                variant="outlined"
                                displayEmpty
                                className="form-control form-control-lg form-control-solid"
                                sx={{
                                    height: 56,
                                    "& .MuiSelect-select": {
                                        padding: "16.5px 14px",
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select reminder minute
                                </MenuItem>
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {/* Status Dropdown */}
                    {/* <div className="w-100 mb-4">
                        <label className="required">Status</label>
                        <Select
                            fullWidth
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            variant="outlined"
                            displayEmpty
                            className="form-control form-control-lg form-control-solid"
                            sx={{
                                height: 56,
                                "& .MuiSelect-select": {
                                    padding: "16.5px 14px",
                                },
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select task status
                            </MenuItem>
                            {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </div> */}

                    <div>
                        <label>Guests</label>
                          <Autocomplete
                                freeSolo
                                options={suggestions.map((option) => option.email)}
                                onInputChange={(event, newInputValue) => {
                                  searchContactByMailID(newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                  handleAddGuest(newValue as string);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="form-control form-control-lg form-control-solid"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {loading && (
                                            <InputAdornment position="end">
                                              <CircularProgress size={24} />
                                            </InputAdornment>
                                          )}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />

<div style={{ marginTop: '10px', marginBottom:"10px" }}>
        {selectedContacts.length > 0 && (
          <span style={{display: "flex", gap: "10px"}}>
            {selectedContacts.map((email, index) => (
              <span key={index} style={{border:" 1px solid grey", width:"fit-content", borderRadius: "20px", padding: "2px 5px"}}>
                {email}
                <i className="bi bi-x"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => handleRemoveGuest(email)}
                >
                </i>
              </span>
            ))}
          </span>
        )}
      </div>

                    </div>

                    {/* React Quill Editor */}
                    <div className="w-100 mb-4">
                        <label className="required" style={{fontSize:"15px"}}>Task description</label>
                        <ReactQuill
                            value={editorValue}
                            onChange={setEditorValue}
                            placeholder="Write task details here..."
                            theme="snow"
                            className=""
                            style={{ minHeight: "150px", height: "150px" }} // Increase the height of the editor
                        />
                    </div>

                    {/* Attachment Icon */}
                    {/* <div className="w-100 d-flex justify-content-end">
                    <IconButton color="primary" aria-label="attach file">
                        <AttachFileIcon onChange={handleFileChange} />
                    </IconButton>
                </div> */}
                </div>

            </div>


            {/* <div className="d-flex justify-content-between align-items-center mt-5">

                <div className="d-flex">
                    <label
                        htmlFor="attachFileInput"
                        className={`btn btn-active-light-primary btn-icon `}
                        title="You can add attachments for the following file types: xls,pdf,csv,doc,jpg,png up to a maximum size of 15MB"
                    >
                        <i className="bi bi-paperclip align-self-center text-dark fs-2 mx-2 "></i>
                        <input
                            type="file"
                            id="attachFileInput"
                            className="d-none"
                            multiple
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
            </div> */}
            {files.length > 0 && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                    {files.map((file, index) => (
                        <Chip
                            key={index}
                            label={file.file_name}
                            onDelete={handleDeleteFile(file)}
                        />
                    ))}
                </Box>
            )}

            <div className="text-end mt-3 mb-4">
                <button type="submit" className="btn btn-lg btn-primary" onClick={taskUpdateHandler}>Update</button>
            </div>
        </div>
    );
};

export default ViewTaskModal;
