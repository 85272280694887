import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../badRequestHandler/BadRequestHandler";

export const Telephony = createApi({
    reducerPath: "telephony",
    baseQuery: dynamicBaseQuery,
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => ({
        serverConfig: builder.query({
            query: () => ({
                url: "v1/telephony/telephony-server-config/",
                method: "GET",
            }),
        }),

        severData: builder.query({
            query: (id) => ({
                url: `v1/telephony/telephony-server-config/${id}/`,
                method: "GET",
            }),
        }),

        updateSeverData: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/telephony/telephony-server-config/${id}/`,
                method: "PUT",
                body,
            }),
        }),

        extensionData: builder.query({
            query: (id) => ({
                url: `v1/telephony/extensions/?ordering=-created&`,
                method: "GET",
            }),
        }),

        editExtensionData: builder.query({
            query: (id) => ({
                url: `v1/telephony/extensions/${id}/`,
                method: "GET",
            }),
        }),

        updateEditExtensionData: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/telephony/extensions/${id}/`,
                method: "PUT",
                body,
            }),
        }),

        deleteEditExtensionData: builder.mutation({
            query: (id) => ({
                url: `v1/telephony/extensions/${id?.id}/`,
                method: "DELETE",
            }),
        }),

        addExtension: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/extensions/`,
                method: "POST",
                body,
            }),
        }),

        // QUEUE

        queueData: builder.query({
            query: (id) => ({
                url: `v1/telephony/queues/`,
                method: "GET",
            }),
        }),

        editQueueData: builder.query({
            query: (id) => ({
                url: `v1/telephony/queues/${id}`,
                method: "GET",
            }),
        }),

        editQueue: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/telephony/queues/${id}`,
                method: "PUT",
                body,
            }),
        }),

        deleteQueueData: builder.mutation({
            query: (id) => ({
                url: `v1/telephony/queues/${id?.id}/`,
                method: "DELETE",
            }),
        }),

        addQueueData: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/queues/`,
                method: "POST",
                body,
            }),
        }),

        // PAUSE CODE

        pauseCodeData: builder.query({
            query: (id) => ({
                url: `v1/telephony/pause-codes`,
                method: "GET",
            }),
        }),

        addPauseCode: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/pause-codes/`,
                method: "POST",
                body,
            }),
        }),

        editPauseCode: builder.query({
            query: (id) => ({
                url: `v1/telephony/pause-codes/${id}`,
                method: "GET",
            }),
        }),

        editPause: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/telephony/pause-codes/${id}`,
                method: "PUT",
                body,
            }),
        }),

        deletePauseCode: builder.mutation({
            query: (id) => ({
                url: `v1/telephony/pause-codes/${id?.id}/`,
                method: "DELETE",
            }),
        }),

        // AGENT MAPPING

        agentMappingData: builder.query({
            query: (id) => ({
                url: `v1/users/user-telephony-extension/`,
                method: "GET",
            }),
        }),

        addAgentMappingData: builder.query({
            query: () => ({
                url: `v1/users/user-telephony-extension/?ordering=-created&filters=extensions`,
                method: "GET",
            }),
        }),

        downloadAgentMappingData: builder.query({
            query: (id) => ({
                url: `v1/users/user-telephony-extension/`,
                method: "GET",
            }),
        }),

        deleteAgentMappingCode: builder.mutation({
            query: (id) => ({
                url: `v1/users/user-telephony-extension/${id?.id}/`,
                method: "DELETE",
            }),
        }),

        agentMappingQueue: builder.query({
            query: (id) => ({
                url: `v1/telephony/queues/`,
                method: "GET",
            }),
        }),

        editAgentMappingField: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/users/user-telephony-extension/${id}`,
                method: "PUT",
                body,
            }),
        }),

        editAgentMapping: builder.query({
            query: (id) => ({
                url: `v1/users/user-telephony-extension/${id}`,
                method: "GET",
            }),
        }),

        editAgentMappingUsers: builder.query({
            query: (id) => ({
                url: `v1/users/users/?`,
                method: "GET",
            }),
        }),

        editAgentMappingExtensions: builder.query({
            query: (id) => ({
                url: `v1/telephony/extensions/?filters=extensions`,
                method: "GET",
            }),
        }),

        updateAgentMappingExtensions: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/users/user-telephony-extension/${id}/`,
                method: "PUT",
                body,
            }),
        }),

        addAgentMappingExtensions: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/users/user-telephony-extension/`,
                method: "POST",
                body,
            }),
        }),

        // DISPOSITION

        getAllTeamsData: builder.query({
            query: (id) => ({
                url: `v1/users/users/${id}`,
                method: "GET",
            }),
        }),

        getAllDispositionData: builder.query({
            query: ({ id, process }) => {
                return {
                    url: !process
                        ? `v1/telephony/disposition/?team=${id}&`
                        : `v1/telephony/disposition/?team=${id}&type=${process}&`,
                    method: "GET",
                };
            },
        }),

        createTeleDisposition: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/disposition/`,
                method: "POST",
                body,
            }),
        }),

        getParticularDispositionData: builder.query({
            query: (id) => {
                return {
                    url: `v1/telephony/disposition/${id}`,
                    method: "GET",
                };
            },
        }),

        addSubTeleDisposition: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/telephony/disposition/${id}/`,
                method: "PUT",
                body,
            }),
        }),

        deleteDisposition: builder.mutation({
            query: (id) => ({
                url: `v1/telephony/disposition/${id?.id}/`,
                method: "DELETE",
            }),
        }),

        // DID

        getAllDIDData: builder.query({
            query: (id) => ({
                url: `v1/telephony/telephony-pri/`,
                method: "GET",
            }),
        }),

        particularFieldDIDData: builder.query({
            query: (id) => ({
                url: `v1/telephony/telephony-pri/${id}/`,
                method: "GET",
            }),
        }),

        editParticularFieldDIDData: builder.mutation({
            query: ({ body, id }) => ({
                url: `v1/telephony/telephony-pri/${id}/`,
                method: "PUT",
                body,
            }),
        }),

        addNewDIDData: builder.mutation({
            query: (body) => ({
                url: `v1/telephony/telephony-pri/`,
                method: "POST",
                body,
            }),
        }),

        deleteDIDData: builder.mutation({
            query: (id) => ({
                url: `v1/telephony/telephony-pri/${id.id}/`,
                method: "DELETE",
            }),
        }),

        callHistory: builder.query({
            query: ({ gte, lte ,call_type,page,page_size, user_id}) => ({
                url: `v1/users/call-history/`,
                method: "GET",
                params: {
                    gte,
                    lte,
                    call_type,
                    page,
                    page_size, 
                    user_id


                },
            }),
        }),


    }),
});

export const {
    useServerConfigQuery,
    useSeverDataQuery,
    useUpdateSeverDataMutation,
    useExtensionDataQuery,
    useEditExtensionDataQuery,
    useUpdateEditExtensionDataMutation,
    useDeleteEditExtensionDataMutation,
    useAddExtensionMutation,
    // QUEUE
    useQueueDataQuery,
    useEditQueueDataQuery,
    useEditQueueMutation,
    useDeleteQueueDataMutation,
    useAddQueueDataMutation,
    // PAUSE CODE
    usePauseCodeDataQuery,
    useAddPauseCodeMutation,
    useEditPauseCodeQuery,
    useEditPauseMutation,
    useDeletePauseCodeMutation,
    // AGENT MAPPING
    useAddAgentMappingDataQuery,
    useAgentMappingDataQuery,
    useDownloadAgentMappingDataQuery,
    useDeleteAgentMappingCodeMutation,
    useAgentMappingQueueQuery,
    useEditAgentMappingFieldMutation,
    useEditAgentMappingQuery,
    useEditAgentMappingUsersQuery,
    useEditAgentMappingExtensionsQuery,
    useUpdateAgentMappingExtensionsMutation,
    useAddAgentMappingExtensionsMutation,
    // DISPOSITION
    useGetAllTeamsDataQuery,
    useGetAllDispositionDataQuery,
    useCreateTeleDispositionMutation,
    useAddSubTeleDispositionMutation,
    useGetParticularDispositionDataQuery,
    useDeleteDispositionMutation,

    // DID
    useGetAllDIDDataQuery,
    useParticularFieldDIDDataQuery,
    useEditParticularFieldDIDDataMutation,
    useAddNewDIDDataMutation,
    useDeleteDIDDataMutation,

    //  Call history
    useCallHistoryQuery

} = Telephony;
