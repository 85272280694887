import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { WS_URL } from '../env'
import { useStateWithCallback } from '../utils/hooks/useStateWithCallback'
import { IChatbotState } from '../utils/interfaces/IChatbotState'
import { toast } from 'react-toastify'
import { AUTH_TOKEN } from '../constants'
import worker_script from '../utils/worker-script'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_ALL_APPLICATION_IDS } from '../graphql/query'
import UserContext from './UserContext'

const initialState: IChatbotState = {
  appId: '80ad2402226fab613e84ed7920801337', //window.botgo._globals.appId
  token: '',
  transcript: '',
  conversation: [],
  panelBasedConversation: [],
  chatRooms: [],
  chatInput: { value: '', error: '', name: 'chatInput' },
  enableUserInput: false,
  inputOptions: null,
  previousValue: [],
  activeIntentId: null,
  nextIntentId: null,
  enableHuman: false,
  selectValue: [],
  initiallyShow: false,
  msgLoading: { active: false, timer: 2000 },
  reconnectChat: false,
  flagToRefreshChatrooms: false,
  flagMessageRead: false
}

const defaultChatContext = {
  state: initialState
}

const ChatContext = createContext<any>(defaultChatContext)

interface IProps {
  children?: ReactNode
}

export const ChatProvider: React.FC<PropsWithChildren<IProps>> = ({ children }) => {
  // 👇🏼 Define All States
  const [state, setState] = useStateWithCallback<IChatbotState>(initialState)
  const [socket, setSocket] = useStateWithCallback<any>(null)
  const [isSocketOpen, setIsSocketOpen] = useStateWithCallback<boolean>(false)
  const [isDashUserOnline, setIsDashUserOnline] = useStateWithCallback<boolean>(false)
  const [initialCallToPing, setInitialcallToPing] = useStateWithCallback<boolean>(false)
  const [flagToReconnectWS, setFlagToReconnectWS] = useStateWithCallback<boolean>(false)
  const [msgMarkedAsReadResponse, setMsgMarkedAsReadResponse] = useStateWithCallback<any>(null)
  const [numOfTimesWebsocketDisconnected, setNumOfTimesWebsocketDisconnected] =
    useStateWithCallback<number>(0)
  const [isSocketReconnected, setIsSocketReconnected] = useStateWithCallback<any>(false)
  const [isTyping, setIsTyping] = useStateWithCallback<boolean>(false)
  const [allowCallingTyping, setAllowCallingTyping] = useStateWithCallback<boolean>(true)
  const [flagToTriggerMarkMessageAsRead, setFlagToTriggerMarkMessageAsRead] =
    useStateWithCallback<boolean>(false)
  const [unreadMessagesArray, setUnreadMessagesArray] = useStateWithCallback<Array<any>>([])
  const [allApplicationIds, setAllApllicationsIds] = useState<any>('')

  // const [firstSocketCall, setFirstSocketCall] = useStateWithCallback<boolean>(true)
  // const [flagToGetMsg, setFlagToGetMsg] = useStateWithCallback<boolean>(false)
  const { currentUser } = useContext(UserContext)
  const { data } = useQuery(GET_ALL_APPLICATION_IDS, {
    variables: {
      input: {
        appIDString: null
      }
    },
    context: {
      headers: {
        'access-control-allow-methods': 'AdminPermission'
      }
    }
  })
  const didMountRef = useRef<boolean>(true)
  const timerWorker = useRef<Worker | null>(null)
  const reconnectToSocketTimeIntervalRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const initWSResolveHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const initWSRejecthandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  // const waitForOpenSocketResolveHandlerRef = useRef<() => void>(() => {
  //   return
  // })
  const makeUserOnlineResolveHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const makeUserOnlineRejecthandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const getUnassignedChatroomsResolveHandlerRef = useRef<(arg0: unknown) => void>(
    (value: unknown) => {
      return
    }
  )
  const getUnassignedChatroomsRejectHandlerRef = useRef<(arg0: unknown) => void>(
    (value: unknown) => {
      return
    }
  )
  const getAssignedChatroomsResolveHandlerRef = useRef<(arg0: unknown) => void>(
    (value: unknown) => {
      return
    }
  )
  const getAssignedChatroomsRejectHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const assignChatroomResolveHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const assignChatroomRejectHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const resolveChatResolveHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const resolveChatRejectHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const getPrevMsgsResolveHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const getPrevMsgsRejectHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const PendingResolvehandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const PendingRejecthandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const TypingResolvehandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const TypingRejecthandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const UpdatUserActiveStatushandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const UpdatUserActiveStatusRejecthandlerRef = useRef<(arg0: unknown) => void>(
    (value: unknown) => {
      return
    }
  )
  const TransferChatResolvehandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const TransferChatRejecthandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const getAgentPrevMsgsResolveHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  const getAgentPrevMsgsRejectHandlerRef = useRef<(arg0: unknown) => void>((value: unknown) => {
    return
  })
  // const timeOutIdRef = useRef<NodeJS.Timer | undefined>(undefined);
  const WebSocket_URL = process.env.REACT_APP_BOTGO_WEBSOCKET_URL || ''
  const [newUnassignedChat, setNewUnassignedChat] = useStateWithCallback<object>({})
  const [newAssignedChat, setNewAssignedChat] = useStateWithCallback<object>({})
  const [totalUnassignedChats, setTotalUnassignedChats] = useStateWithCallback<number>(0)
  const [totalUnreadAssignedChats, setTotalUnreadAssignedChats] = useStateWithCallback<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get('type')
  // Socket Implementation
  const initWS = () => {
    const promise = new Promise((resolve, reject) => {
      initWSResolveHandlerRef.current = resolve
      initWSRejecthandlerRef.current = reject
    })
    const ws = new WebSocket(WebSocket_URL)
    ws.onopen = onConnOpen
    ws.onmessage = onMessage
    ws.onclose = onConnClose
    setSocket(ws)
    return promise
  }

  // const terminateWS = () => {
  //   socket.close()
  // }

  useEffect(() => {
    if (!!data) {
      // Filter the objects with a number in their id and then map to extract the id values.
      setAllApllicationsIds('')
      const filteredIds = data.allApplicationId
        .filter((item: any) => /\d/.test(item.id))
        .map((item: any) => parseInt(item.id, 10))

      setAllApllicationsIds(filteredIds)
    }
  }, [data])

  const onConnOpen = () => {
    initWSResolveHandlerRef.current(true)
    if (numOfTimesWebsocketDisconnected > 0) {
      console.warn('Attempt No: ' + numOfTimesWebsocketDisconnected)
      setNumOfTimesWebsocketDisconnected(0)
    }
  }

  const onConnClose = () => {
    setIsSocketOpen(false)
    console.log('Websocket closed!')
    setState((prevState) => ({
      ...prevState,
      enableUserInput: false,
      reconnectChat: true
    }))
    timerWorker.current?.postMessage({ turn: 'off' })
    console.log('Clear timer in web-worker')
    setNumOfTimesWebsocketDisconnected((prev) => prev + 1)
  }

  const onMessage = async (data: any) => {
    if (data) {
      const response = JSON.parse(data.data)
      console.log(response)

      // Handle 'dash-user-online' Response
      if (response.request === 'dash-user-login' && response.messageResponse === 'SUCCESS') {
        makeUserOnlineResolveHandlerRef.current(response)
      } else if (response.request === 'dash-user-login' && response.messageResponse !== 'SUCCESS') {
        makeUserOnlineRejecthandlerRef.current(null)
      }

      // Handle 'Get-Unassigned-Chatrooms' Response
      if (
        response.request === 'get-unassigned-chatrooms' &&
        response.messageResponse === 'SUCCESS'
      ) {
        getUnassignedChatroomsResolveHandlerRef.current(response)
      }
      // else {
      //   getUnassignedChatroomsRejectHandlerRef.current({null})
      // }

      // Handle 'Assign-Chatroom' Response
      if (response.request === 'assign-chatroom') {
        assignChatroomResolveHandlerRef.current(response)
      }
      // else {
      //   assignChatroomRejectHandlerRef.current({null})
      // }

      if (response.request === 'update_user_online' && response.messageResponse === 'SUCCESS') {
        UpdatUserActiveStatushandlerRef.current(response)
      }

      if (response.request === 'transfer_chatroom' && response.messageResponse === 'SUCCESS') {
        TransferChatResolvehandlerRef.current(response)
      }

      // Handle 'Get-Assigned-Chatrooms' Response
      if (response.request === 'get-assigned-chatrooms' && response.messageResponse === 'SUCCESS') {
        getAssignedChatroomsResolveHandlerRef.current(response)
      }
      // else {
      //   getAssignedChatroomsRejectHandlerRef.current({null})
      // }

      // Handle 'Get-Previous-Messages' Response
      if (response.request === 'get-previous-messages' && response.messageResponse === 'SUCCESS') {
        getPrevMsgsResolveHandlerRef.current(response)
      }

      // Handle 'Get-Agent-Previous-Messages' Response
      if (
        response.request === 'get-agent-previous-messages' &&
        response.messageResponse === 'SUCCESS'
      ) {
        getAgentPrevMsgsResolveHandlerRef.current(response)
      }

      //Handle 'get-pending-message' Response
      if (response.request === 'get-pending-messages' && response.messageResponse === 'SUCCESS') {
        PendingResolvehandlerRef.current(response)
      }

      // if (response.request === 'get-current-intent' && response.messageResponse === 'SUCCESS') {
      //   if (response.currentIntent) {
      //     intentResolveHandlerRef.current(response.currentIntent)
      //   } else {
      //     intentRejecthandlerRef.current({ message: "Sorry ! Error in getting intent! We sincerely regret the inconvenience." })
      //   }
      // }

      // Handle 'initiate-human-handoff' response to display notification fro new unassigned chatroom
      if (response.request === 'initiate-human-handoff' && response.messageResponse === 'SUCCESS') {
        if (!!response.chatRoom) {
          setTotalUnassignedChats((prevValue) => prevValue + 1)
          setNewUnassignedChat(response.chatRoom)
          toast.info(`1 new unassigned chat`)
        }
      }

      // Handle 'new-chatroom-assigned' response to display notification for any new assigned chatroom
      if (response.request === 'new-chatroom-assigned' && response.messageResponse === 'SUCCESS') {
        if (!!response.chatRoom) {
          setTotalUnreadAssignedChats((prevValue) => prevValue + 1)
          setNewAssignedChat(response.chatRoom)
          toast.info(`1 new chat assigned to you`)
        }
      }

      if (response.request === 'chatroom-timed_out') {
        const resChatRoomId = response.chatRoomId
        if (!!resChatRoomId) {
          setState((prevState: IChatbotState) => {
            const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
              (panel: any) => {
                if (panel.chatRoomId === resChatRoomId) {
                  console.log(panel.chatRoomId)
                  console.log(resChatRoomId)
                  return {
                    ...panel,
                    conversation: []
                  }
                }
                return panel
              }
            )

            return {
              ...prevState,
              panelBasedConversation: updatedPanelBasedConversation,
              flagToRefreshChatrooms: true
            }
          })
          toast.success(`CONV-${resChatRoomId} has timed out`)
        }
      }

      // Handle 'typing' response
      let msgLoadingTimeout: any
      if (response.request === 'typing' && response.messageResponse === 'SUCCESS') {
        TypingResolvehandlerRef.current(response)
        if (
          response.request === 'typing' &&
          response.messageResponse === 'SUCCESS' &&
          response.payloadForRedis
        ) {
          setState((prevState) => ({
            ...prevState,
            msgLoading: {
              ...prevState.msgLoading,
              active: true
            }
          }))
          scrollToBottom()
          // Set a timeout to turn off msgLoading after 3 seconds
          clearTimeout(msgLoadingTimeout)
          msgLoadingTimeout = setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              msgLoading: {
                ...prevState.msgLoading,
                active: false
              }
            }))
          }, 3000)
        }
      }

      // Handle 'new-message' response to push new message in the convArray
      // if (response.request === 'new-message' && response.messageResponse === 'SUCCESS') {
      //   const chatRoomIdFromLocalStorageStr = localStorage.getItem('chatRoomId')
      //   if (chatRoomIdFromLocalStorageStr !== null) {
      //     const chatRoomIdFromLocalStorage = parseInt(chatRoomIdFromLocalStorageStr, 10)
      //     if (
      //       !isNaN(chatRoomIdFromLocalStorage) &&
      //       response.chatRoomId === chatRoomIdFromLocalStorage
      //     ) {
      //       const message = response.messagePayload
      //       setState(
      //         (prevState: IChatbotState) => ({
      //           ...prevState,
      //           conversation: [...prevState.conversation, message],
      //           enableUserInput: true
      //         }),
      //         () => {
      //           scrollToBottom()
      //         }
      //       )
      //       setUnreadMessagesArray((prevValue) => [...prevValue, message])
      //       setFlagToTriggerMarkMessageAsRead(true)
      //       // Clear the msgLoading timeout immediately when a new message is received
      //       clearTimeout(msgLoadingTimeout)
      //       // Set msgLoading to false
      //       setState((prevState) => ({
      //         ...prevState,
      //         msgLoading: {
      //           ...prevState.msgLoading,
      //           active: false
      //         }
      //       }))
      //     } else {
      //       toast.success(`1 new message from CONV-${response.chatRoomId}`)
      //     }
      //   }
      // }
      if (response.request === 'new-message' && response.messageResponse === 'SUCCESS') {
        const message = response.messagePayload
        setState(
          (prevState: IChatbotState) => {
            const updatedPanelBasedConversation = prevState.panelBasedConversation.map(
              (panel: any) => {
                if (panel.chatRoomId === response.chatRoomId) {
                  return {
                    ...panel,
                    conversation: [...panel.conversation, message]
                  }
                }
                return panel
              }
            )

            return {
              ...prevState,
              panelBasedConversation: updatedPanelBasedConversation,
              enableUserInput: true
            }
          },
          () => {
            scrollToBottom()
          }
        )

        setUnreadMessagesArray((prevValue) => [...prevValue, message])
        setFlagToTriggerMarkMessageAsRead(true)

        // Clear the msgLoading timeout immediately when a new message is received
        clearTimeout(msgLoadingTimeout)

        // Set msgLoading to false
        setState((prevState) => ({
          ...prevState,
          msgLoading: {
            ...prevState.msgLoading,
            active: false
          }
        }))
      }

      // Handle 'new-message-dash' response to push new message in the convArray
      // if (response.request === 'new-message-dash' && response.messageResponse === 'SUCCESS') {
      //   const agentIdFromLocalStorageStr = localStorage.getItem('agentId')
      //   if (agentIdFromLocalStorageStr !== null) {
      //     const agentIdFromLocalStorage = parseInt(agentIdFromLocalStorageStr, 10)
      //     if (
      //       !isNaN(agentIdFromLocalStorage) &&
      //       response.senderUserId === agentIdFromLocalStorage
      //     ) {
      //       const message = response.messagePayload
      //       setState(
      //         (prevState: IChatbotState) => ({
      //           ...prevState,
      //           conversation: [...prevState.conversation, message],
      //           enableUserInput: true
      //         }),
      //         () => {
      //           scrollToBottom()
      //         }
      //       )
      //       setUnreadMessagesArray((prevValue) => [...prevValue, message])
      //       setFlagToTriggerMarkMessageAsRead(true)
      //       // Clear the msgLoading timeout immediately when a new message is received
      //       clearTimeout(msgLoadingTimeout)
      //       // Set msgLoading to false
      //       setState((prevState) => ({
      //         ...prevState,
      //         msgLoading: {
      //           ...prevState.msgLoading,
      //           active: false
      //         }
      //       }))
      //     } else {
      //       const senderId = parseInt(searchParams.get('agentId') || '0', 10)
      //       if (senderId === response.senderUserId) {
      //         toast.success(`1 new message from ${response.messagePayload.content.name}`)
      //       }
      //     }
      //   }
      // }
      if (response.request === 'new-message-dash' && response.messageResponse === 'SUCCESS') {
        // const agentIdFromLocalStorageStr = localStorage.getItem('agentId');
        // if (agentIdFromLocalStorageStr !== null) {
        //   const agentIdFromLocalStorage = parseInt(agentIdFromLocalStorageStr, 10);
        // if (
        //   !isNaN(agentIdFromLocalStorage) &&
        //   response.senderUserId === agentIdFromLocalStorage
        // ) {
        const message = response.messagePayload
        console.log(response ,"shakya")

        // Update the conversation for the specific senderUserId in panelBasedConversation
        setState(
          (prevState: IChatbotState) => {
            const updatedPanelBasedConversation = prevState.panelBasedConversation.map((panel) => {
              // Assuming that the panel Id corresponds to the senderUserId or chatRoomId
              if (panel.agentId === response.senderUserId) {
                return {
                  ...panel,
                  conversation: [...panel.conversation, message]
                }
              }
              return panel
            })

            return {
              ...prevState,
              panelBasedConversation: updatedPanelBasedConversation,
              enableUserInput: true
            }
          },
          () => {
            scrollToBottom()
          }
        )

        setUnreadMessagesArray((prevValue) => [...prevValue, message])
        setFlagToTriggerMarkMessageAsRead(true)

        // Clear the msgLoading timeout immediately when a new message is received
        clearTimeout(msgLoadingTimeout)

        // Set msgLoading to false
        setState((prevState) => ({
          ...prevState,
          msgLoading: {
            ...prevState.msgLoading,
            active: false
          }
        }))
   
        // } else {
        // const senderId = parseInt(searchParams.get('agentId') || '0', 10);
        // if (senderId === response.senderUserId) {
        toast.success(`1 new message from ${response.messagePayload.content.name ? response.messagePayload.content.name :response.sender }`)
        // }
        // }
        // }
      }

      // if (response.request === 'message' && response.messageResponse === 'FAIL') {
      //   toast.warning('Sorry unable to connect with this user ')
      // }

      // Handle 'mark-message-as-delivered' (TBM --> 'message-marked-as-read') response to mark message as read
      if (
        response.request === 'mark-message-as-delivered' &&
        response.messageResponse === 'SUCCESS'
      ) {
        setMsgMarkedAsReadResponse(response)
      }

      // Handle 'mark-chatroom-as-resolved' response
      if (response.request === 'mark-chatroom-as-resolved') {
        resolveChatResolveHandlerRef.current(response)
      }
    } else {
      console.log('No response received from Server')
    }
  }

  const makeUserOnline = async () => {
    const promise = new Promise((resolve, reject) => {
      makeUserOnlineResolveHandlerRef.current = resolve
      makeUserOnlineRejecthandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'dash-user-login',
          dashAccessToken: localStorage.getItem('auth-token'),
          teamId: Number(localStorage.getItem('botgo-team-id'))
        })
      )
    } else {
      console.error(`Unable to make dashboard user online !!`)
    }
    return promise
  }

  const getUnassignedChatrooms = async () => {
    const promise = new Promise((resolve, reject) => {
      getUnassignedChatroomsResolveHandlerRef.current = resolve
      getUnassignedChatroomsRejectHandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'get-unassigned-chatrooms',
          applicationIds: allApplicationIds
        })
      )
    } else {
      console.error(`Unable to get unassigned chatrooms !!`)
    }
    return promise
  }

  const assignChatroom = async (chatRoomId: number, userId: number) => {
    const promise = new Promise((resolve, reject) => {
      assignChatroomResolveHandlerRef.current = resolve
      assignChatroomRejectHandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      console.log(
        JSON.stringify({
          MessageGroupId: 'assign-chatroom',
          userId,
          chatRoomId
        })
      )
      socket.send(
        JSON.stringify({
          MessageGroupId: 'assign-chatroom',
          chatRoomId,
          userId
        })
      )
    } else {
      console.error(`Unable to assign chatroom !!`)
    }
    return promise
  }

  const getAssignedChatrooms = async () => {
    const promise = new Promise((resolve, reject) => {
      getAssignedChatroomsResolveHandlerRef.current = resolve
      getAssignedChatroomsRejectHandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'get-assigned-chatrooms',
          userIds: [parseInt(currentUser.id)]
        })
      )
    } else {
      console.error(`Unable to get assigned chatrooms !!`)
    }
    return promise
  }

  const getPrevMessages = (chatRoomId: number) => {
    const promise = new Promise((resolve, reject) => {
      getPrevMsgsResolveHandlerRef.current = resolve
      getPrevMsgsRejectHandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'get-previous-messages',
          chatRoomId
        })
      )
    } else {
      console.log(`Error in getting previous messages !!`)
    }
    return promise
  }

  const getAgentPrevMessages = (chatId: number) => {
    console.log(chatRoomID)
    const promise = new Promise((resolve, reject) => {
      getAgentPrevMsgsResolveHandlerRef.current = resolve
      getAgentPrevMsgsRejectHandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'get-agent-previous-messages',
          chatId
        })
      )
    } else {
      console.log(`Error in getting previous messages !!`)
    }
    return promise
  }
  // const getPendingMessages = (chatRoomID: any) => {
  //   const promise = new Promise((resolve, reject) => {
  //     PendingResolvehandlerRef.current = resolve
  //     PendingRejecthandlerRef.current = reject
  //   })
  //   const messageId = state.conversation.length - 1
  //   let last = state?.conversation?.pop()
  //   const sessionId = last?.sessionId
  //   if (!!socket && isSocketOpen) {
  //     socket.send(
  //       JSON.stringify({
  //         MessageGroupId: 'get-pending-messages',
  //         accessToken: localStorage.getItem('botgojwt'),
  //         messageId: messageId,
  //         sessionId: sessionId
  //       })
  //     )
  //   } else {
  //     console.log(`Error in getting pending messages !!`)
  //   }
  //   return promise
  // }
  const getPendingMessages = (chatRoomID: any) => {
    const promise = new Promise((resolve, reject) => {
      PendingResolvehandlerRef.current = resolve
      PendingRejecthandlerRef.current = reject
    })

    // Find the relevant panel based on chatRoomID
    const panel = state.panelBasedConversation.find((panel) => panel.chatRoomId === chatRoomID)
    if (!panel) {
      return promise // Return immediately if the panel does not exist
    }

    const messageId = panel.conversation.length - 1 // Get the last message's index
    let last = panel.conversation.pop() // Get the last message from the panel's conversation
    const sessionId = last?.sessionId

    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'get-pending-messages',
          accessToken: localStorage.getItem('botgojwt'),
          messageId: messageId,
          sessionId: sessionId
        })
      )
    } else {
      console.log(`Error in getting pending messages !!`)
    }

    return promise
  }

  const sendMessageToEndUser = (data: object) => {
    // const promise = new Promise((resolve, reject) => {
    //   sendMessageResolveHandlerRef.current = resolve
    //   sendMessageRejectHandlerRef.current = reject
    // })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'message',
          data
        })
      )
    } else {
      console.error(`Unable to send message to the end-user !!`)
    }
    // return promise
  }

  const sendMessageToDashUser = (data: any) => {
    if (!!socket && isSocketOpen) {
      const message = {
        MessageGroupId: 'dash-to-dash-message',
        recieverId: data.recieverId,
        senderId: data.senderId,
        side: data.side,
        time: data.time,
        data: {
          content: data.content,
          sender: data.sender,
          receiver: data.receiver,
          meta: data.meta
        }
      }
      socket.send(JSON.stringify(message))
    } else {
      console.error(`Unable to send message to the dash-user !!`)
    }
  }

  const markChatRoomAsResolved = (chatRoomId: number, status: string, remark?: string) => {
    const promise = new Promise((resolve, reject) => {
      resolveChatResolveHandlerRef.current = resolve
      resolveChatRejectHandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'mark-chatroom-as-resolved',
          chatRoomId,
          status,
          remark
        })
      )
    } else {
      console.error(`Unable to change chat status !!`)
    }
    return promise
  }

  const typing = async (chatRoomId: number) => {
    const promise = new Promise((resolve, reject) => {
      TypingResolvehandlerRef.current = resolve
      TypingRejecthandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'Typing',
          dashAccessToken: localStorage.getItem('auth-token'),
          chatRoomId,
          sender: {
            type: 'dash-user'
          }
        })
      )
    } else {
      console.error(`Unable to send typing notification to the end-user !!`)
    }
    return promise
  }

  const updateUserActiveStatus = async (status: string, userId: number) => {
    const promise = new Promise((resolve, reject) => {
      UpdatUserActiveStatushandlerRef.current = resolve
      UpdatUserActiveStatusRejecthandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'update-user-active-status',
          status,
          userId,
          accessToken: localStorage.getItem('auth-token')
        })
      )
    } else {
      console.error(`Unable to change the user active status !!`)
    }
    return promise
  }

  const transferChat = async (chatRoomId: number, currentUserId: number, newUserId: number) => {
    const promise = new Promise((resolve, reject) => {
      TransferChatResolvehandlerRef.current = resolve
      TransferChatRejecthandlerRef.current = reject
    })
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'transfer_chatroom',
          dashAccessToken: localStorage.getItem('auth-token'),
          chatRoomId,
          currentUserId,
          newUserId
        })
      )
    } else {
      console.error(`Unable to transfer the chatroom !!`)
    }
    return promise
  }

  const markMessageAsRead = (messageIds: number[], chatRoomId: number) => {
    if (!!socket && isSocketOpen) {
      socket.send(
        JSON.stringify({
          MessageGroupId: 'mark-message-as-read',
          dashAccessToken: localStorage.getItem('auth-token'),
          chatRoomId,
          status: 'SEEN',
          messageId: messageIds,
          reciever: {
            type: 'dash-user'
          }
        })
      )
    } else {
      console.error(`Unable to send notification to the end-user !!`)
    }
  }

  // Ensuring the socket connection persists as long as the user is online
  // setInterval(() => {
  //   if (!!socket && isSocketOpen === true) {
  //     socket.send(JSON.stringify({
  //       MessageGroupId: "",
  //     }))
  //     console.log("Sent Blank Call to WebSocket")
  //     console.log(new Date().toLocaleTimeString())
  //   }
  // }, 9 * 60 * 1000)

  // 👇🏼 Define all utility Functions

  const formDataRef = useRef<any>(null)

  const bottomRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSelect = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      chatInput: {
        ...prevState.chatInput,
        value: e
      },
      selectValue: e
    }))
  }

  const handleFileInputChange = (e: any) => {
    const name = e.target.name
    const files = e.target.files

    let status = true

    if (state.inputOptions.multiple) {
      if (state.inputOptions.maxCount) {
        if (files.length > state.inputOptions.maxCount) {
          status = false
          alert(`Too many files! . Make sure number of files under ${state.inputOptions.maxCount}`)
        }
      }
      let totalsize = 0
      for (let i = 0; i < files.length; i++) {
        totalsize = totalsize + files[i].size
      }
      if (totalsize > state.inputOptions.maxLength) {
        status = false
        alert(
          `File too large. Make sure file size in under ${state.inputOptions.maxLength / 1000000
          } Mb`
        )
      }
    } else if (state.inputOptions.maxLength) {
      if (files[0].size > state.inputOptions.maxLength) {
        status = false
        alert(
          `File too large. Make sure file size in under ${state.inputOptions.maxLength / 1000000
          } Mb`
        )
      }
    }

    if (status) {
      setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name as keyof IChatbotState],
          files,
          value: prevState.inputOptions.message,
          error: ''
        }
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name as keyof IChatbotState],
          error: 'error'
        }
      }))
    }
  }

  const onChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name as keyof IChatbotState],
        value
      }
    }))
  }

  const onSubmit = async (e: any, user?: any, message?: string) => {
    // e.preventDefault()
    const {
      chatInput,
      previousValue,
      activeIntentId,
      inputOptions
      // enableHuman,
    } = state
    // let value = chatInput.value
    let value: any = message ? message : chatInput.value ? chatInput.value : e
    let status = true
    let errorMessage = ''
    let fileLocations = [{ filename: '', url: '' }]
    let fileUpload = false

    const data = {
      content: {
        side: 'dash-user',
        message: value,
        chatId: user?.chatRoomId
      },
      side: 'dash-user',
      time: new Date().toUTCString(),
      sender: {
        type: 'dash-user',
        id: currentUser.id,
        name: currentUser.name
      }
    }

    const dashData = {
      recieverId: user?.agentId,
      senderId: parseInt(currentUser.id),
      side: 'dash-user-rhs',
      time: new Date().toUTCString(),
      content: {
        message: value
      },
      sender: {
        type: 'dash-user-rhs',
        name: currentUser.name
      },
      receiver: {
        type: 'dash-user-lhs',
        name: user?.agentName
      },
      meta: {
        isRead: 'false',
        isDelivered: 'false'
      }
    }

    if (user?.agentId) {
      sendMessageToDashUser(dashData)
    } else {
      sendMessageToEndUser(data)
    }

    if (inputOptions) {
      if (!inputOptions.optional) {
        //test input text min length
        if (inputOptions.minLength) {
          if (value.length < inputOptions.minLength) {
            status = false
            errorMessage = !!inputOptions.errorMessage ? inputOptions.errorMessage : ''
          }
        }

        //test input text max length
        if (inputOptions.maxLength) {
          if (value.length > inputOptions.maxLength) {
            status = false
            errorMessage = !!inputOptions.errorMessage ? inputOptions.errorMessage : ''
          }
        }

        if (inputOptions.regex) {
          if (inputOptions.regex.length > 0) {
            const regex = new RegExp(inputOptions.regex)
            if (!regex.test(value)) {
              status = false
              errorMessage = !!inputOptions.errorMessage ? inputOptions.errorMessage : ''
            }
          }
        }
      }

      //handle file upload
      // if (inputOptions.type === "file") {
      //   fileUpload = true
      //   const formData = new FormData()
      //   for (var file of chatInput.files) {
      //     formData.append('upload', file)
      //   }
      //   //need to add check if there is file
      //   try {
      //     let response = await fetch(API_URL + "/files", {
      //       method: "POST",
      //       headers: { 'Authorization': 'Bearer ' + localStorage.getItem('botgojwt') },
      //       body: formData,
      //     });
      //     let res = await response.json()
      //     let text = ''
      //     for (var result of res.filesArray) {
      //       text = text + '<b>' + result.originalname + '</b><br>'
      //     }
      //     value = text + "Uploaded Successfully!!"
      //     fileLocations = res.filesArray.map((itm: any) => {
      //       return { "filename": itm.originalname, "url": itm.location }
      //     })
      //   } catch (err) {
      //     status = false
      //     errorMessage = "Some error occurred!!"
      //   }
      // }
    }

    if (status) {
      if (activeIntentId !== null) {
        let idx = previousValue.findIndex((item) => item.id === activeIntentId)
        let temp: any
        if (fileUpload) {
          temp = value
          value = fileLocations
          if (idx > -1) previousValue[idx] = { id: activeIntentId, value }
          else previousValue.push({ id: activeIntentId, value })
          value = temp
        } else {
          if (idx > -1) previousValue[idx] = { id: activeIntentId, value }
          else previousValue.push({ id: activeIntentId, value })
        }
      }

      //handle chat with human
      // if (enableHuman) {
      //   // // send text to admin
      //   // setState((prevState) => ({
      //   //   ...prevState,
      //   //   chatInput: {
      //   //     ...chatInput,
      //   //     value: "",
      //   //     error: ""
      //   //   },
      //   //   previousValue
      //   // }), (updatedState) => {
      //   //   socket.emit("messageFromUser", value)
      //   //   const newPreviousValue = updatedState.previousValue
      //   //   trigger(null, value, undefined, newPreviousValue)
      //   // }
      //   // )
      // } else {
      setState(
        (prevState) => ({
          ...prevState,
          chatInput: {
            ...chatInput,
            value: '',
            error: ''
          },
          previousValue
        }),
        (updatedState) => {
          const newPreviousValue = updatedState.previousValue
          if (Array.isArray(value)) {
            let val = value.map((item) => item.label).join(', ')
            trigger(null, val, true, newPreviousValue, user)
            return
          }
          trigger(null, value, true, newPreviousValue, user)
        }
      )
      // }
    } else {
      setState((prevState) => ({
        ...prevState,
        chatInput: {
          ...chatInput,
          error: errorMessage.length > 0 ? errorMessage : 'error',
          value: ''
        }
      }))
    }
  }

  const onButtonClicked = (nextIntentId: number, value: any, id: any, formdata: any) => {
    const previousValueArray = state.previousValue.slice(0)
    let idx = previousValueArray.findIndex((item) => item.id === id)

    if (!!formdata) {
      if (idx > -1) previousValueArray[idx] = { id, formdata }
      else previousValueArray.push({ id, formdata })
    } else {
      if (idx > -1) previousValueArray[idx] = { id, value }
      else previousValueArray.push({ id, value })
    }

    setState(
      (prevState) => ({
        ...prevState,
        previousValue: previousValueArray
      }),
      (updatedState) => {
        const newPreviousValue = updatedState.previousValue
        trigger(nextIntentId, value, undefined, newPreviousValue, null)
      }
    )
  }

  const trigger = async (
    id: any,
    userInputValue?: string,
    fromAdmin?: boolean,
    newPreviousValue?: any[],
    user?: any
  ) => {
    let nextIntentIdVar = id
    let activeIntentIdVar: any = null
    let enableUserInputVar: boolean = false
    let inputOptionsVar: any = null
    let enableHumanVar: any = false
    // let objArray: any[] = []
    // let tObj: any = null

    if (userInputValue) {
      pushIntent(
        [
          {
            side: fromAdmin ? 'dash-user' : 'bot',
            message: userInputValue
          }
        ],
        enableUserInputVar,
        activeIntentIdVar,
        nextIntentIdVar,
        inputOptionsVar,
        enableHumanVar,
        user
      )
    }

    // if (id !== null) {
    //   let prefs: any = {}
    //   const updatePrefs = (item: string) => {
    //     let prevValue = state.previousValue.find(
    //       (pre) => pre.id === tObj.metadata.user_prefs[item]
    //     )

    //     if (prevValue) {
    //       prefs[item] = prevValue.value
    //     }
    //   }
    //   const findFormId = (pre: any) => pre.id === tObj.metadata.formId

    //   do {
    //     if (!!newPreviousValue) {
    //       tObj = await getIntentFromJson(nextIntentIdVar, newPreviousValue)
    //     } else {
    //       tObj = await getIntentFromJson(nextIntentIdVar)
    //     }
    //     tObj.side = "lhs"
    //     enableUserInputVar = tObj.userInput
    //     activeIntentIdVar = tObj.id

    //     if (tObj.human) {
    //       enableHumanVar = tObj.human
    //       if (tObj.metadata.user_prefs && state.previousValue.length > 0) {
    //         let k = Object.keys(tObj.metadata.user_prefs)
    //         k.forEach(updatePrefs)
    //       }

    //       if (tObj.metadata.formId) {
    //         prefs = {
    //           ...state.previousValue.find(findFormId).formdata,
    //         }
    //       }
    //       socket.emit("joinUser", { prefs })
    //     }

    //     if (tObj.inputOptions) {
    //       inputOptionsVar = tObj.inputOptions
    //     }

    //     if (tObj.trigger) {
    //       nextIntentIdVar = tObj.trigger
    //     }

    //     objArray.push(tObj);

    //     if (tObj.end)
    //       objArray.push({
    //         side: "center",
    //         message: "Chat ended. Thank You!",
    //       })
    //   }
    //   while (tObj.userInput === false && tObj.trigger)
    // } else {
    // enableUserInputVar = true
    // inputOptionsVar = state.inputOptions
    // enableHumanVar = state.enableHuman
    // }

    // setTimeout(() => {
    //   pushIntent(
    //     objArray,
    //     enableUserInputVar,
    //     activeIntentIdVar,
    //     nextIntentIdVar,
    //     inputOptionsVar,
    //     enableHumanVar
    //   )
    // }, 2000)
  }
  const pushIntent = (
    intent: any,
    enableUserInput: any,
    activeIntentId: any,
    nextIntentId: any,
    inputOptions: any,
    enableHuman: any,
    user: any // Assuming you pass chatRoomID as an additional parameter
  ) => {
    if (intent.length === 0) {
      scrollToBottom()
      return
    }

    // Find the relevant panel based on chatRoomID
    const panelIndex = state.panelBasedConversation.findIndex(
      (panel) =>
        panel.chatRoomId === parseInt(user?.chatRoomId) || panel.agentId === parseInt(user?.agentId)
    )

    if (panelIndex === -1) {
      console.error(`Panel not found for chatRoomID: ${user?.chatRoomId || user?.agentId}`)
      return // Exit if the panel does not exist
    }

    const updatedPanel = {
      ...state.panelBasedConversation[panelIndex],
      conversation: [
        ...state.panelBasedConversation[panelIndex].conversation,
        {
          content: intent[0],
          side: intent[0].side,
          time: new Date().toUTCString(),
          messageId: state.panelBasedConversation[panelIndex].conversation.length
        }
      ]
    }

    const updatedPanels = [
      ...state.panelBasedConversation.slice(0, panelIndex),
      updatedPanel,
      ...state.panelBasedConversation.slice(panelIndex + 1)
    ]

    setState(
      (prevState) => ({
        ...prevState,
        panelBasedConversation: updatedPanels,
        enableUserInput,
        nextIntentId,
        inputOptions,
        activeIntentId,
        enableHuman
      }),
      (updatedState) => {
        if (intent[0].sendTranscript) {
          fetch('https://botgo.io/api/sendTranscript', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: new URLSearchParams({
              conversation: JSON.stringify(updatedState.panelBasedConversation)
            })
          })
        }
      }
    )

    let interval = state.msgLoading.timer
    if (intent.length === 1) {
      interval = 0
    }
    setTimeout(
      () =>
        pushIntent(
          intent.slice(1),
          enableUserInput,
          activeIntentId,
          nextIntentId,
          inputOptions,
          enableHuman,
          user
        ),
      interval
    )
  }

  // const pushIntent = (
  //   intent: any,
  //   enableUserInput: any,
  //   activeIntentId: any,
  //   nextIntentId: any,
  //   inputOptions: any,
  //   enableHuman: any
  // ) => {
  //   if (intent.length === 0) {
  //     scrollToBottom()
  //     return
  //   }

  //   setState(
  //     (prevState) => ({
  //       ...prevState,
  //       conversation: [
  //         ...prevState.conversation,
  //         {
  //           content: intent[0],
  //           side: intent[0].side,
  //           time: new Date().toUTCString(),
  //           messageId: prevState.conversation.length
  //         }
  //       ],
  //       enableUserInput,
  //       nextIntentId,
  //       inputOptions,
  //       activeIntentId,
  //       enableHuman
  //     }),
  //     (updatedState) => {
  //       if (intent[0].sendTranscript) {
  //         fetch('https://botgo.io/api/sendTranscript', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  //             // 'Authorization': 'Bearer '+this.state.token
  //           },
  //           body: new URLSearchParams({
  //             conversation: JSON.stringify(updatedState.conversation)
  //           })
  //         })
  //       }
  //     }
  //   )

  //   let interval = state.msgLoading.timer
  //   if (intent.length === 1) {
  //     interval = 0
  //   }
  //   setTimeout(
  //     () =>
  //       pushIntent(
  //         intent.slice(1),
  //         enableUserInput,
  //         activeIntentId,
  //         nextIntentId,
  //         inputOptions,
  //         enableHuman
  //       ),
  //     interval
  //   )
  // }

  // const triggerFirst = async () => {
  //   // const data = await fetch(`https://geolocation-db.com/json/`);
  //   // const jwt = await fetch(API_URL+'/frontend/verifyAppId/'+window.botgo._globals.appId);
  //   var ciph = (CryptoJS.AES.encrypt(state.appId, 'rk12seejetyi3c').toString() as any).replaceAll('/', 'Por21Ld')
  //   const jwt = await fetch(API_URL + '/frontend/verifyAppId/' + ciph)
  //   jwt.json().then((res) => {
  //     if (!res.accessToken) {
  //       trigger(0)
  //     } else {
  //       // setToken(res.accessToken)
  //       // localStorage.setItem('chatId', `${Date.now() + Math.floor(Math.random() * 9999)}`)
  //       // trigger(res.startIntent)
  //       let token = res.accessToken
  //       setState((prevState) => ({ ...prevState, token }), (updatedState) => {
  //         // data.json().then(function(res){
  //         localStorage.setItem('botgojwt', `${updatedState.token}`)
  //         localStorage.setItem('chatId', `${Date.now() + Math.floor(Math.random() * 9999)}`)
  //         // });
  //         trigger(res.startIntent)
  //       })
  //     }
  //   })
  // }

  // const getIntentFromJson = async (id: string, newPreviousValue?: any[]) => {
  //   // setMsgLoading({ ...msgLoading, active: true })
  //   setState((prevState) => ({
  //     ...prevState,
  //     msgLoading: {
  //       ...prevState.msgLoading,
  //       active: true
  //     }
  //   }))
  //   const params = new URLSearchParams()
  //   params.append('id', id)
  //   params.append('public', `${!!newPreviousValue ? JSON.stringify(newPreviousValue) : JSON.stringify(state.previousValue)}`)
  //   params.append('chat', `${localStorage.getItem('chatId')}`)
  //   params.append('appId', state.appId)

  //   let currIntent = await fetch(API_URL + '/frontend/payload/handler', {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //       'Authorization': 'Bearer ' + localStorage.getItem('botgojwt')
  //     },
  //     body: params
  //     // body: new URLSearchParams({
  //     //   id: id,
  //     //   public: JSON.stringify(state.previousValue),
  //     //   chat: localStorage ? localStorage.getItem('chatId') : '',
  //     //   appId: state.appId
  //     // }) as URLSearchParams
  //   })
  //   return currIntent.json()
  // }

  // const reset = (e: any) => {
  //   if (!state.msgLoading.active) {
  //     // setConversation([])
  //     // setPreviousValue([])
  //     // triggerFirst()
  //     setState((prevValue) => ({
  //       ...prevValue,
  //       conversation: [],
  //       previousValue: []
  //     }), () => {
  //       triggerFirst()
  //     })
  //   }
  // }

  // const renderInput = (
  //   inputOptions: IChatbotState['inputOptions'],
  //   enableUserInput: IChatbotState['enableUserInput'],
  //   chatInput: IChatbotState['chatInput']
  // ) => {
  //   if (inputOptions.type === "textarea") {
  //     return (
  //       <textarea
  //         className={`form-control chat-input overflow-hidden ${chatInput.error.length > 0 ? "is-invalid-custom" : ""
  //           }`}
  //         disabled={!enableUserInput}
  //         placeholder={
  //           chatInput.error.length > 0
  //             ? chatInput.error
  //             : inputOptions.placeHolder
  //         }
  //         name={chatInput.name}
  //         value={chatInput.value}
  //         onKeyDown={(e) => {
  //           const key = e.key
  //           if (key === 'Enter' && !e.shiftKey) {
  //             e.preventDefault();
  //             onSubmit(e);
  //           }
  //         }}
  //         onChange={onChange}
  //       />
  //     )
  //   } else if (inputOptions.type === "file") {
  //     return (
  //       <input
  //         disabled={!enableUserInput}
  //         placeholder={
  //           chatInput.error.length > 0
  //             ? chatInput.error
  //             : inputOptions.placeHolder
  //         }
  //         multiple={inputOptions.multiple}
  //         name={chatInput.name}
  //         onChange={handleFileInputChange}
  //         className={`form-control chat-input ${chatInput.error.length > 0 ? "is-invalid-custom" : ""
  //           }`}
  //         type={inputOptions.type}
  //       />
  //     )
  //   } else if (inputOptions.type === "auto-suggest") {
  //     return (
  //       <Typeahead
  //         onChange={handleSelect}
  //         flip={true}
  //         onKeyDown={(e: any) => {
  //           // Submit the form when the user hits enter.
  //           if (e.key === 'Enter') {
  //             if (timerid) {
  //               clearTimeout(timerid)
  //             }
  //             timerid = setTimeout(() => {
  //               onSubmit(e);
  //             }, 300);
  //           }
  //         }}
  //         // name={chatInput.name}
  //         selected={state.selectValue}
  //         // menuPlacement="top"
  //         multiple={inputOptions.multiple}
  //         options={inputOptions.options}
  //         className={`form-control chat-input ${chatInput.error.length > 0 ? "is-invalid-custom" : ""
  //           }`}
  //       // style={{config.theme.inputStyle} as CSSProperties}
  //       />
  //     )
  //   } else {
  //     return (
  //       <input
  //         disabled={!enableUserInput}
  //         placeholder={
  //           chatInput.error.length > 0
  //             ? chatInput.error
  //             : inputOptions.placeHolder
  //         }
  //         name={chatInput.name}
  //         value={chatInput.value}
  //         onChange={onChange}
  //         className={`form-control chat-input ${chatInput.error.length > 0 ? "is-invalid-custom" : ""
  //           }`}
  //         type={inputOptions.type}
  //       />
  //     )
  //   }
  // }
  // useEffect(() => {
  //   // Check if any message is in queue and waiting for websocket to be open and if init is called on being reconnected
  //   if (
  //     !!socket&&
  //     !!isSocketOpen &&
  //     socket.readyState === 1 &&
  //     !!isReconnected
  //   ) {
  //     clearTimeout(timeOutIdRef.current);
  //     waitForOpenSocketResolveHandlerRef.current();
  //     setIsReconnected(false);
  //   }
  // });

  const reconnect = () => {
    if (!!state.reconnectChat) {
      setState((prevState: any) => ({
        ...prevState,
        initiallyShow: true,
        reconnectChat: false
      }))
      setFlagToReconnectWS(true)
    }
  }
  // 👇🏼 Define all side-effects applicable
  // useEffect(() => {
  //   let typingTimeout: any

  //   const handleKeyDown = () => {
  //     clearTimeout(typingTimeout) // Clear the previous timeout
  //     setIsTyping(true)

  //     // Set a new timeout to turn off typing after 5 seconds
  //     typingTimeout = setTimeout(() => {
  //       setIsTyping(false)
  //     }, 5000)

  //     if (!!allowCallingTyping && !!isTyping && type === 'Assigned') {
  //       // Only call the typing API if isTyping is true and allowingCallingTyping is true
  //       typing(chatRoomID).then((res: any) => {
  //         if (res.request === 'Typing' && res.messageResponse === 'SUCCESS') {
  //           setState(
  //             (prevState) => ({
  //               ...prevState,
  //               conversation: [...prevState.conversation],
  //               enableUserInput: true
  //             }),
  //             (updatedState) => {
  //               scrollToBottom()
  //             }
  //           )
  //         }
  //       })

  //       // After making the API call, set allowingCallingTyping to false
  //       setAllowCallingTyping(false)

  //       // Set a new timeout to re-enable calling the typing API after 5 seconds
  //       setTimeout(() => {
  //         setAllowCallingTyping(true)
  //       }, 5000)
  //     }
  //   }

  //   // Attach the keydown event listener
  //   window.addEventListener('keydown', handleKeyDown)

  //   return () => {
  //     // Clean up the event listener when the component unmounts
  //     window.removeEventListener('keydown', handleKeyDown)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allowCallingTyping, isTyping])
  useEffect(() => {
    let typingTimeout: any

    const handleKeyDown = () => {
      clearTimeout(typingTimeout) // Clear the previous timeout
      setIsTyping(true)
      typingTimeout = setTimeout(() => {
        setIsTyping(false)
      }, 5000)

      if (!!allowCallingTyping && !!isTyping && type === 'Assigned') {
        typing(chatRoomID).then((res: any) => {
          if (res.request === 'Typing' && res.messageResponse === 'SUCCESS') {
            const panelIndex = state.panelBasedConversation.findIndex(
              (panel) => panel.chatRoomId === chatRoomID
            )
            if (panelIndex !== -1) {
              setState(
                (prevState) => ({
                  ...prevState,
                  panelBasedConversation: [
                    ...prevState.panelBasedConversation.slice(0, panelIndex),
                    {
                      ...prevState.panelBasedConversation[panelIndex],
                      conversation: [...prevState.panelBasedConversation[panelIndex].conversation]
                    },
                    ...prevState.panelBasedConversation.slice(panelIndex + 1)
                  ],
                  enableUserInput: true
                }),
                (updatedState) => {
                  scrollToBottom()
                }
              )
            }
          }
        })
        setAllowCallingTyping(false)
        setTimeout(() => {
          setAllowCallingTyping(true)
        }, 5000)
      }
    }

    // Attach the keydown event listener
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('keydown', handleKeyDown)
      clearTimeout(typingTimeout) // Clear the timeout if the component unmounts
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCallingTyping, isTyping])

  useEffect(() => {
    if (!!didMountRef.current) {
      initWS().then((res) => {
        if (!!res) {
          setIsSocketOpen(true)
          console.log('Websocket connected!')
        }
      })
    }
    return () => {
      didMountRef.current = false //prevents initial websocket call for all consecutive re-renders
    }
  })

  useEffect(() => {
    timerWorker.current = new Worker(worker_script)
    return () => {
      timerWorker.current?.terminate()
    }
  }, [])

  useEffect(() => {
    if (initialCallToPing === true) {
      timerWorker.current?.postMessage({ turn: 'on' })
      console.log('Set Timer in Webworker')
      // Receive messages received from web-worker thread
      timerWorker.current!.onmessage = ({ data: { time } }) => {
        if (!!socket && isSocketOpen === true) {
          socket.send(
            JSON.stringify({
              MessageGroupId: 'ping'
            })
          )
        }
      }
    }
    return () => {
      setInitialcallToPing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCallToPing])

  const chatRoomID = searchParams.get('chatRoomId')
    ? parseInt(searchParams.get('chatRoomId') as string)
    : 0

  useEffect(() => {
    if (isSocketOpen === true) {
      clearInterval(reconnectToSocketTimeIntervalRef.current)
      makeUserOnline()
        .then((res) => {
          if (!!res) {
            setIsDashUserOnline(true)
            console.log('Made the user Online')
          }
        })
        .catch((err) => {
          console.error(err)
          // localStorage.removeItem(AUTH_TOKEN)
          // setTimeout(() => {
          //   document.location.reload()
          // }, 1000)
        })
      setInitialcallToPing(true)
    } else if (initialCallToPing === true) {
      setInitialcallToPing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketOpen])

  // useEffect(() => {
  //   if (!!isSocketReconnected && type === 'Assigned') {
  //     getPendingMessages(chatRoomID).then((res: any) => {
  //       if (res.request === 'get-pending-messages' && res.messageResponse === 'SUCCESS') {
  //         if (res.pendingMessages.length > 0) {
  //           setState(
  //             (prevState: any) => ({
  //               ...prevState,
  //               conversation: [...prevState.conversation, ...res.pendingMessages]
  //             }),
  //             (updatedState) => {
  //               // Reset the socket reconnection flag
  //               setIsSocketReconnected(false)
  //               scrollToBottom()
  //             }
  //           )
  //         } else {
  //           // Reset the socket reconnection flag
  //           setIsSocketReconnected(false)
  //         }
  //       }
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSocketReconnected])
  useEffect(() => {
    if (!!isSocketReconnected && type === 'Assigned') {
      getPendingMessages(chatRoomID).then((res: any) => {
        if (res.request === 'get-pending-messages' && res.messageResponse === 'SUCCESS') {
          if (res.pendingMessages.length > 0) {
            const panelIndex = state.panelBasedConversation.findIndex(
              (panel) => panel.chatRoomId === chatRoomID
            )
            if (panelIndex !== -1) {
              setState(
                (prevState) => ({
                  ...prevState,
                  panelBasedConversation: [
                    ...prevState.panelBasedConversation.slice(0, panelIndex),
                    {
                      ...prevState.panelBasedConversation[panelIndex],
                      conversation: [
                        ...prevState.panelBasedConversation[panelIndex].conversation,
                        ...res.pendingMessages
                      ]
                    },
                    ...prevState.panelBasedConversation.slice(panelIndex + 1)
                  ]
                }),
                (updatedState) => {
                  setIsSocketReconnected(false)
                  scrollToBottom()
                }
              )
            } else {
              setIsSocketReconnected(false)
            }
          } else {
            setIsSocketReconnected(false)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketReconnected])

  useEffect(() => {
    // Reattempt to connect to websocket when socket connection is closed
    if (numOfTimesWebsocketDisconnected > 0) {
      if (numOfTimesWebsocketDisconnected < 3) {
        setTimeout(() => {
          reconnect()
        }, 2 * 1000)
      } else if (numOfTimesWebsocketDisconnected < 10) {
        setTimeout(() => {
          reconnect()
        }, 3 * 1000)
      } else if (numOfTimesWebsocketDisconnected < 25) {
        setTimeout(() => {
          reconnect()
        }, 5 * 1000)
      } else {
        console.error('Oops! Botgo WebSocket Server is not responding!')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numOfTimesWebsocketDisconnected])

  useEffect(() => {
    if (!!flagToReconnectWS) {
      initWS().then((res) => {
        if (!!res) {
          setIsSocketOpen(true)
          setIsSocketReconnected(true)
          console.log('Websocket Reconnected!')
        }
      })
    }
    return () => {
      setFlagToReconnectWS(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagToReconnectWS])

  // useEffect(() => {
  //   let formDataObj = formDataRef.current
  //   const convArray = state.conversation
  //   convArray.forEach((conv: any) => {
  //     if (!!conv.content?.fulfillment?.parameters?.formData) {
  //       const data = conv.content.fulfillment.parameters.formData
  //       formDataRef.current = { ...formDataObj, ...data }
  //     }
  //   })
  // }, [state.conversation])
  useEffect(() => {
    const convArray = state.panelBasedConversation.reduce((acc: any[], panel: any) => {
      return acc.concat(panel.conversation)
    }, [])

    let formDataObj = formDataRef.current

    convArray.forEach((conv: any) => {
      if (!!conv.content?.fulfillment?.parameters?.formData) {
        const data = conv.content.fulfillment.parameters.formData
        formDataRef.current = { ...formDataObj, ...data }
      }
    })
  }, [state.panelBasedConversation])

  // useEffect(() => {
  //   //trigger first
  //   if (state.enableHuman) {
  //     setSocket(io(API_URL, { transports: ["websocket"], reconnection: false }))
  //   }
  // }, [state.enableHuman])

  // useEffect(() => {
  //   if (!!socket) {
  //     socket.on("messageFromAdmin", (value: string) => {
  //       trigger(null, value, true)
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket])

  return (
    <ChatContext.Provider
      value={{
        state,
        formDataRef,
        bottomRef,
        setState,
        scrollToBottom,
        handleSelect,
        handleFileInputChange,
        onChange,
        onSubmit,
        onButtonClicked,
        trigger,
        pushIntent,
        isDashUserOnline,
        newUnassignedChat,
        setNewUnassignedChat,
        newAssignedChat,
        setNewAssignedChat,
        getUnassignedChatrooms,
        getAssignedChatrooms,
        assignChatroom,
        markChatRoomAsResolved,
        totalUnassignedChats,
        setTotalUnassignedChats,
        totalUnreadAssignedChats,
        setTotalUnreadAssignedChats,
        getPrevMessages,
        getAgentPrevMessages,
        msgMarkedAsReadResponse,
        setMsgMarkedAsReadResponse,
        flagToTriggerMarkMessageAsRead,
        setFlagToTriggerMarkMessageAsRead,
        unreadMessagesArray,
        setUnreadMessagesArray,
        markMessageAsRead,
        updateUserActiveStatus,
        transferChat,
        sendMessageToEndUser,
        // terminateWS,
        reconnect
        // triggerFirst,
        // getIntentFromJson,
        // renderInput
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export default ChatContext
