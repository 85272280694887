import React, { createRef, useContext, useRef, useState } from 'react'
import { KTSVG } from '../../helpers/components/KTSVG'
import { GET_ALL_TEAMS_AND_USERS, GET_TEAMS } from '../../graphql/query'
import { useQuery } from '@apollo/client'
import UserContext from '../../context/UserContext'
import TeamActionMenu from './TeamActionMenu'
import { createInitials } from '../../utils/functions/createInitials'
import { Dropdown } from 'react-bootstrap'

interface IProps {
  disabledIndexes: number[]
  handleOpenChat: (userId: number) => void
}

const TeamPanel: React.FC<IProps> = ({ disabledIndexes, handleOpenChat }) => {
  const { currentUser } = useContext(UserContext)
  const chatWindowDropdownMenuRef = useRef<any[]>([])

  const [teamName, setTeamName] = useState<string>('')
  const [memberName, setMemberName] = useState<string>('')

  const {
    data: teamData,
    error: teamError,
    loading: teamLoading
  } = useQuery(GET_TEAMS, {
    context: {
      headers: {
        'access-control-allow-methods': 'Human-hand-off'
      }
    }
  })

  const { data, error, loading, refetch } = useQuery(GET_ALL_TEAMS_AND_USERS, {
    variables: {
      input: {
        teamName,
        memberName
      }
    },
    context: {
      headers: {
        'access-control-allow-methods': 'Human-hand-off'
      }
    }
  })

  // Handle changes to the team filter
  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTeamName(event.target.value)
    refetch({ input: { teamName: event.target.value, memberName } })
  }

  const handleMemberSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMemberName(event.target.value)
    refetch({ input: { teamName, memberName: event.target.value } })
  }

  chatWindowDropdownMenuRef.current =
    data?.getAllTeamsAndUsers?.data?.flatMap(
      (team: any) =>
        team?.users?.map(
          (user: any, index: number) => chatWindowDropdownMenuRef.current[index] ?? createRef()
        ) || []
    ) || []

  if (loading || teamLoading) return <div>Loading...</div>
  if (error || teamError) return <div>Error loading data</div>

  return (
    <div className='card p-2' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 8px 0px' }}>
      <div className='flex-column'>
        <div>
          <div id='kt_chat_contacts_header'>
            <form className='w-100 position-relative'>
              <i className='ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-1 translate-middle-y'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <div className='search-bar d-flex'>
                {/* Dropdown for team selection */}
                <div>
                  <select
                    className='form-select form-select-solid'
                    aria-label='Select Team'
                    value={teamName}
                    onChange={handleTeamChange} // Filter on team selection
                    style={{ borderRadius: '0rem' }}
                  >
                    <option value=''>All Teams</option>
                    {teamData?.getTeams.map((team: any) => (
                      <option key={team.id} value={team.name}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <form className='w-100 position-relative' autoComplete='off'>
                    {/* Search bar for member name */}
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid px-15'
                      name='search'
                      placeholder='Search Member'
                      value={memberName}
                      onChange={handleMemberSearch}
                      style={{ borderLeft: '1px solid #e1e3e4', borderRadius: '0rem' }}
                      autoFocus
                    />
                  </form>
                </div>
              </div>
              <div className='d-flex justify-content-between pt-4 mx-4'>
                <div className='d-flex align-items-center'>
                  <label className='fs-4 fw-bold'>My Team</label>
                </div>
              </div>
            </form>
          </div>
          <div id='kt_chat_contacts_body'>
            <div className='card-body scroll-y p-0'>
              <div className='mh-700px'>
                {data.getAllTeamsAndUsers?.data?.map((team: any) => (
                  <div key={team.id}>
                    <table className='table table-row-gray-300 mb-0'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-primary '>
                          <th className='px-4'>{team.name}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='py-0'>
                            <div className='h-auto scroll-y ps-3' id='kt_chat_contacts_body'>
                              {team.users.length > 0 ? (
                                team.users.map((user: any, index: number) => {
                                  const currentUserId = parseInt(currentUser.id)
                                  if (currentUserId === user.id) {
                                    return null // Skip current user
                                  }
                                  const { label, state } = createInitials(user.name)
                                  return (
                                    <div
                                      key={user.id}
                                      className='d-flex flex-stack py-4 px-4 border border-2 mb-1'
                                    >
                                      <div className='d-flex align-items-center'>
                                        <div className='symbol ps-1 symbol-40px symbol-circle me-3'>
                                          <span
                                            className={`symbol-label bg-light-${state} text-${state} fs-6 fw-bolder`}
                                          >
                                            {label}
                                          </span>
                                        </div>
                                        <div className='ms-1 d-flex flex-column'>
                                          <div className='d-flex flex-lg-column flex-xl-row justify-content-between'>
                                            <div
                                              className='fs-7 fw-bold text-gray-900 text-hover-primary mb-1'
                                              role='button'
                                            >
                                              {user.name || 'N/A'}
                                            </div>
                                            <div className='text-muted fw-bold' style={{marginLeft: '5px'}}>
                                              ID-{user.id || 'N/A'}
                                            </div>
                                          </div>
                                          <div
                                            className='fw-semibold fw-bold text-muted fs-8'
                                            style={{ wordBreak: 'break-word' }}
                                          >
                                            {user.email || 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='d-flex align-items-center'>
                                        {/* Dropdown for actions */}
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            as='div' // This replaces the default button with a div
                                            id={`dropdown-${user.id}`}
                                            className='custom-dropdown-toggle'
                                          >
                                            <i className='bi bi-three-dots-vertical fs-2'></i>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleOpenChat(user)}>
                                              <i className='bi bi-chat-left-text'></i>
                                              <span className='px-2'>Open Window</span>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  )
                                })
                              ) : (
                                <div className='d-flex flex-stack py-4 px-4 border border-2 mb-1'>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol ps-1 symbol-40px symbol-circle me-3'>
                                      <span
                                        className={`symbol-label bg-light-primary text-primary fs-6 fw-bolder`}
                                      >
                                        {'N'}
                                      </span>
                                    </div>
                                    <div className='ms-1 d-flex flex-column'>
                                      <div className='d-flex flex-lg-column flex-xl-row justify-content-between'>
                                        <div
                                          className='fs-7 fw-bold text-gray-900 text-hover-primary mb-1'
                                          role='button'
                                        >
                                          No agent present
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className='separator separator-dashed d-none'></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamPanel
