import React, { useState, useEffect, useContext } from 'react'
import ChatContext from '../../context/ChatContext'

const ChatHeaderComponents = () => {
  const { updateUserActiveStatus } = useContext(ChatContext)
  // const { currentUser } = useContext(UserContext)

  const currentUser = localStorage.getItem('User_Id')

  const [isPaused, setIsPaused] = useState(() => {
    const savedState = localStorage.getItem('isPaused')
    return savedState === 'true'
  })

  useEffect(() => {
    localStorage.setItem('isPaused', isPaused.toString())
  }, [isPaused])

  const handleClick = async () => {
    const newStatus = isPaused ? 'online' : 'offline'
    const userId = parseInt(currentUser)
    await updateUserActiveStatus(newStatus, userId).then(() => {
      setIsPaused(!isPaused)
    })
  }

  const buttonText = isPaused ? 'Resume' : 'Pause'
  const iconClass = isPaused ? 'bi-play-circle' : 'bi-pause-circle'

  // Apply conditional classes for text, icon, and border colors
  const colorClass = isPaused ? 'text-danger border-danger' : 'text-success border-success'

  console.log(currentUser,"currentUser");
  

  return (
    <div>
      <div className='w-200px'>
        <button
          className={`form-control d-flex justify-content-between align-items-center fw-bold h-50px border-3 ${colorClass}`}
          onClick={handleClick}
        >
          <span className='fw-bolder fs-4'>{buttonText}</span>
          <i className={`bi ${iconClass} ${colorClass} fs-1 fw-bolder`}></i>
        </button>
      </div>
    </div>
  )
}

export default ChatHeaderComponents
