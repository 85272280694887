import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { AUTH_TOKEN } from '../constants'
import { toast } from 'react-toastify'

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path }) => {
            // toast.error(`${message}`)
        })
    }
})

const authMiddleware = new ApolloLink((operation, forward) => {
    let token = localStorage.getItem('auth-token')

    // Check if token exists
    if (token) {
        // Parse token payload to get expiration time
        const tokenPayload = JSON.parse(atob(token.split('.')[1])) // assuming token is in JWT format
        const tokenExpiration = tokenPayload.exp * 1000 // Convert expiration time to milliseconds
        // Check if token is expired
        if (Date.now() >= tokenExpiration) {
            // Token expired, remove it from localStorage
            localStorage.removeItem('auth-token')

            document.location.reload()
        }
    }

    if (token) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            }
        }))
    }
    return forward(operation)
})

const link = from([authMiddleware, errorLink, new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL })])

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link
})

export default client