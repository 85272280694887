import React, { useEffect, useState } from "react";
import {
  useTagsQuery,
  useTeamsQuery,
  useUserDynamicFieldsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import {
  useAccessAccountAndTeamQuery,
  useAddContactMutation,
} from "../../../Services/customer/FrontendCustomer";
import { toast } from "react-toastify";
import { GridCloseIcon } from "@mui/x-data-grid";
import TeamsModal from "./TeamsModal";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  IconButton,
  Tooltip,
  Box,
  Chip,
} from "@mui/material";

import { Loader } from "react-bootstrap-typeahead";
import BulkContact from "./BulkContact";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { set } from "lodash";

interface IProps {
  closeModal: () => void;
  refetchingData: any;
}
interface Team {
  id: string;
  name: string;
}

interface ErrorState {
  [key: string]: string;
}

const ContactModal: React.FC<IProps> = ({ closeModal, refetchingData }) => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamsDataa, setTeamsDataa] = useState([]);
  const [accessibleTeams, setAccessibleTeams] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [accountSelected, setAccountSelected] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [facebookId, setFacebookId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dynamicData, setDynamicData] = useState([]);
  const [dynamicFields, setDynamicFields] = useState({});
  const [errors, setErrors] = useState<ErrorState>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showAccountButton, setShowAccountButton] = useState(true);
  const [files, setFiles] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false)
  const [openTeams, setOpenTeams] = useState(false);
  const { id } = useParams();
  const [teamAndAccountData, setTeamAndAccountData] = useState([]);
  const { data: teamAndAccount, isLoading: isLoadingAccounts, status: accountStatus } = useAccessAccountAndTeamQuery({});
  const { data: teamssData } = useTeamsQuery({});

  const accountSelectHandler = (event) => {
    const selectedAccountId = event.target.value;
    setAccountSelected(selectedAccountId);

    setSelectedTeams([]);

    const selectedAccount = teamAndAccountData.find(
      (acc) => acc.id === parseInt(selectedAccountId)
    );
    setAccessibleTeams(selectedAccount ? selectedAccount.accessible_teams : []);
  };

  const { data: tags, isLoading: tagsLoading } = useTagsQuery({});
  // const { data: accountData } = useClientEditInfoQuery({});
  const [trigger, { isLoading }] = useAddContactMutation();

  const modelName = "contact";
  const { data: contactDynamicData, isLoading: dynamicLoading } =
    useUserDynamicFieldsQuery(modelName);

  // useEffect(() => {
  //   if (accountData) {
  //     setAccounts(accountData);
  //   }
  // }, [accountData]);

  useEffect(() => {
    if (tags) {
      setTagsData(tags);
    }
  }, [tags]);


  useEffect(() => {
    if (teamssData) {
      setTeamsDataa(teamssData);
    }
  }, [teamssData]);


  useEffect(() => {
    if (teamAndAccount) {
      setTeamAndAccountData(teamAndAccount);
    }
  }, [teamAndAccount]);

  useEffect(() => {
    if (accountStatus === "pending") {
      setAccountLoading(true);
    } else {
      setAccountLoading(false);
    }
  }, [accountStatus]);

  useEffect(() => {
    if (contactDynamicData) {
      setDynamicData(contactDynamicData);
    }
  }, [contactDynamicData]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTeams([]); // Unselect all
    } else {
      const allTeamIds = accessibleTeams.length
        ? accessibleTeams.map((team) => team.id)
        : teamsDataa.map((team) => team.id);
      setSelectedTeams(allTeamIds); // Select all teams based on accessibleTeams or teamsDataa
    }
    setSelectAll(!selectAll);
  };

  const toggleTeamSelection = (teamId: string) => {
    const isSelected = selectedTeams.includes(teamId);

    if (isSelected) {
      setSelectedTeams(selectedTeams?.filter((id) => id !== teamId));
    } else {
      setSelectedTeams([...selectedTeams, teamId]);
      setSelectAll(false);
    }
  };

  // To deselct the all checkbox
  useEffect(() => {
    setSelectAll(false);
  }, [accountSelected]);

  const tagSelectHandler = (tag: any) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        // If the selected tag is already present, remove it (clear the selection)
        return [];
      } else {
        // If the selected tag is not present, store it as the new value
        return [tag];
      }
    });
  };

  const handleInputChange = (label: any, value: any) => {
    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const validateForm = () => {
    const newErrors: ErrorState = {};

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }
      if (!email) {
        newErrors.email = "Email is required";
      }

      if (!name) {
        newErrors.name = "Name is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      setIsButtonDisabled(true);

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);

      return;
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = dynamicData.reduce((acc, field) => {
      acc[field.label] = dynamicFields[field.label] || "";
      return acc;
    }, {});

    const contactObj = {
      accessible_teams: selectedTeams,
      account: accountSelected,
      dynamic_fields: dynamicFieldData,
      email: email,
      facebook_id: facebookId,
      phone_number: mobileNumber,
      tags: selectedTags,
      twitter_id: twitterId,
      whatsapp_number: whatsappNumber,
      name: name,
      attachments: files,
    };

    try {
      const response = await trigger(contactObj);


      if (response?.data) {
        refetchingData();
        toast.success("Added successfully");
        closeModal();
      } else if (response?.error) {
        const error = response.error as {
          data?: { email?: string[]; name?: string[]; error?: string[] };
        };
        const errorEmail = error?.data?.email?.[0];
        const errorName = error?.data?.name?.[0];
        const errorField = error?.data?.error?.[0]
        const errorMessage = errorEmail || errorName || errorField || "An error occured";

        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };

  const closeTeamsModal = () => {
    setOpenTeams(false);
  };

  const handleSwitchToggle = () => {
    setShowAccountButton(!showAccountButton);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 120,
      },
    },
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return "";
  };

  // Function to get current date-time formatted as 'YYYY-MM-DDTHH:mm'
  const getCurrentDateTimeFormatted = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0");
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const result = accountSelected ? accessibleTeams : teamsDataa;


  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
    } catch (error) {
      console.error("Error reading files:", error);
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  return (
    <div className="custom-style">
      {/* Toggling dynamically */}

      {!id && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <label
            className="form-check-label mx-2"
            style={{
              fontSize: "16px",
              color: showAccountButton ? "#009ef7" : "",
            }}
          >
            Single
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={handleSwitchToggle}
              checked={!showAccountButton}
            />
          </div>
          <label
            className="card-text"
            style={{
              fontSize: "16px",
              color: !showAccountButton ? "#009ef7" : "",
            }}
          >
            Bulk
          </label>
        </div>
      )}
      {!showAccountButton && (
        <BulkContact
          dynamicData={dynamicData}
          closeModal={closeModal}
          refetch={refetchingData}
          teamsData={accessibleTeams}
          teamAndAccountData={teamAndAccountData}
          tagsData={tags}
        />
      )}

      {showAccountButton && (
        <>
          <div
            style={{
              maxHeight: "60vh",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="row g-5 g-xl-8 mb-4">
              <div className="col-xl-6" style={{ width: "100%" }}>
                <label>Select account</label>
                {accountLoading ? (
                  <div className="mt-2">
                    <Loader />
                  </div>
                ) : (
                  <select
                    className="form-select select2-hidden-accessible"
                    onChange={accountSelectHandler}
                    value={accountSelected}
                  >
                    <option selected disabled value="">
                      Select account
                    </option>
                    {teamAndAccountData?.map((acc) => (
                      <option value={acc.id} key={acc.id}>
                        {acc?.name}
                      </option>
                    ))}
                  </select>)}
              </div>
            </div>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="teams-select-label">Select teams</InputLabel>
              <Select
                labelId="teams-select-label"
                id="teams-select"
                multiple
                value={selectedTeams}
                MenuProps={MenuProps}
                onChange={(event) =>
                  setSelectedTeams(event.target.value as string[])
                }
                input={<OutlinedInput label="Select teams" />}
                renderValue={(selected) =>
                  result
                    .filter((team) => selected.includes(team.id))
                    .map((team) => team.name) // Display team names based on selected IDs
                    .join(", ")
                }
              >
                <MenuItem>
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  <ListItemText primary="All" />
                </MenuItem>
                {result?.map((team) => (
                  <MenuItem
                    key={team.id}
                    value={team.id}
                    onClick={() => toggleTeamSelection(team)}
                  >
                    <Checkbox checked={selectedTeams.includes(team.id)} />
                    <ListItemText primary={team.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="row g-5 g-xl-8 mb-4">
              <div
                className="col-xl-6"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <label className="required">Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      name: "",
                    }));
                  }}
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}{" "}
                {/* Display error message if exists */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label className="required">Email</label>
              <input
                className="form-control"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "",
                  }));
                }}
              />
            </div>
            {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}

            <div className="row g-5 g-xl-8 mb-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <label>Mobile No.</label>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: '40px'
                  }}
                >
                  <PhoneInput
                    style={{ width: "100%", height: '40px' }}
                    defaultCountry="IN"
                    value={mobileNumber || "+91"}
                    onChange={(value) => setMobileNumber(value)}
                  />
                </div>
              </div>
            </div>

            <div className="row g-5 g-xl-8 mb-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <label>Whatsapp no.</label>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <PhoneInput
                    style={{ width: "100%" }}
                    defaultCountry="IN"
                    value={whatsappNumber || "91"}
                    onChange={(value) => setWhatsappNumber(value)}
                  />
                </div>
              </div>
            </div>



            <div className="row g-5 g-xl-8 mb-4">
              <div
                className="col-xl-6"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <label>Facebook id </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setFacebookId(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="row g-5 g-xl-8 mb-4">
              <div
                className="col-xl-6"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <label>Twitter id </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setTwitterId(e.target.value);
                  }}
                />
              </div>
            </div>


            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              {dynamicData?.map((el) => (
                <React.Fragment key={el?.label}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      className={
                        el.field_type === "check-box"
                          ? ""
                          : el?.is_required
                            ? "required"
                            : ""
                      }
                    >
                      {capitalizeFirstLetter(
                        el?.field_type === "check-box" ? "" : el?.label
                      )}
                    </label>
                    {el?.field_type === "drop-down" ? (
                      <select
                        className="form-control"
                        value={dynamicFields[el?.label] || ""}
                        onChange={(e) =>
                          handleInputChange(el.label, e.target.value)
                        }
                      >
                        <option value="" selected>
                          Select an option
                        </option>
                        {el?.choices?.map((choice: any) => (
                          <option key={choice?.id} value={choice?.choice}>
                            {choice?.choice}
                          </option>
                        ))}
                      </select>
                    ) : el?.field_type === "tel-phone" ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <PhoneInput
                          style={{ width: "100%" }}
                          defaultCountry="IN"
                          value={dynamicFields[el?.label] || "+91"}
                          onChange={(phone: string) =>
                            handleInputChange(el.label, phone)
                          }
                        />
                      </div>
                    ) : el?.field_type === "check-box" ? (
                      <FormControl sx={{ width: "100%" }}>
                        <label className={el?.is_required ? "required" : ""}>
                          {" "}
                          {capitalizeFirstLetter(el.label)}
                        </label>

                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={dynamicFields[el?.label] || []}
                          onChange={(e) =>
                            handleInputChange(el.label, e.target.value)
                          }
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            // Ensure selected is an array
                            if (Array.isArray(selected)) {
                              return selected.join(", ");
                            }
                            return "";
                          }}
                        >
                          {el?.multiple_choices?.map((choice: any) => (
                            <MenuItem key={choice?.id} value={choice?.choice}>
                              <Checkbox
                                checked={dynamicFields[el?.label]?.includes(
                                  choice?.choice
                                )}
                              />
                              <ListItemText primary={choice?.choice} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : el?.field_type === "date-time" ? (
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={
                          getDataTimeFormatted(dynamicFields[el?.label]) || ""
                        }
                        max={getCurrentDateTimeFormatted()}
                        onChange={(e) =>
                          handleInputChange(el.label, e.target.value)
                        }
                        placeholder=""
                      />
                    ) : el?.field_type === "text-area" ? (
                      <textarea
                        className="form-control"
                        value={dynamicFields[el?.label] || ""}
                        onChange={(e) =>
                          handleInputChange(el.label, e.target.value)
                        }
                        placeholder="Enter text here"
                        rows={2} // Adjust the number of rows as needed
                      />
                    ) : (
                      <input
                        type={
                          el?.field_type === "email"
                            ? "email"
                            : el?.field_type === "number"
                              ? "number"
                              : el?.field_type === "date"
                                ? "datetime-local"
                                : el?.field_type === "time"
                                  ? "time"
                                  : "text"
                        }
                        placeholder={
                          el?.field_type === "email"
                            ? "Enter your email"
                            : el?.field_type === "number"
                              ? "Number"
                              : ""
                        }
                        className="form-control"
                        value={dynamicFields[el?.label] || ""}
                        onChange={(e) =>
                          handleInputChange(el.label, e.target.value)
                        }
                      />
                    )}
                  </div>
                  {errors[el.label] && (
                    <div style={{ color: "red" }}>
                      {capitalizeFirstLetter(errors[el.label])}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            <div className="row g-5 g-xl-8 mb-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ margin: "0" }}>Tags</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    whiteSpace: "nowrap",
                    overflow: "scroll",
                  }}
                >
                  {tagsData?.map((tag) => (
                    <p
                      key={tag.id}
                      onClick={() => tagSelectHandler(tag.id)}
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        background: selectedTags.includes(tag.id)
                          ? "#009ef7"
                          : "#cccccc",
                        color: selectedTags.includes(tag.id)
                          ? "white"
                          : "white",
                      }}
                    >
                      {capitalizeFirstLetter(tag?.name)}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <label>Attachments</label>
            <div className="mt-2">
              <Button
                // variant="contained"
                component="label"
                className="text-primary"
                startIcon={<AttachFileIcon />}
              >
                Add attachment
                <input type="file" hidden multiple onChange={handleFileChange} />
              </Button>
              <Tooltip
                title="You can add attachments for the following file types: xls, pdf, csv, doc, jpg, png up to a maximum size of 15MB"
                arrow
              >
                <IconButton
                  aria-label="info"
                  style={{
                    verticalAlign: "middle",
                    marginLeft: "11px",
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          {files.length > 0 && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
              {files.map((file, index) => (
                <Chip
                  key={index}
                  label={file.file_name}
                  onDelete={handleDeleteFile(file)}
                />
              ))}
            </Box>
          )}

          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "20px" }}
          >
            <button
              type="reset"
              className="btn btn-sm btn-white btn-active-light-danger me-2"
              onClick={closeModal}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={submitHandler}
              disabled={isButtonDisabled}
            >
              {isLoading ? (
                <>
                  Submitting... <Loader />
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactModal;