import React, { useState } from 'react'
import ReactModal from 'react-modal'
import DeleteMetaModal from './DeleteMetaModal'
import EditMetaModal from './EditMetaModal'

interface IProps {
  item: { id: string; name: string; value: string }
  chatRoomId: number
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
}

const SidePanelItem: React.FC<IProps> = ({ item, chatRoomId, setIsLoading, type }) => {
  const [isEditMetaOpen, setIsEditMetaOpen] = useState<boolean>(false)
  const [isDeleteMetaOpen, setIsDeleteMetaOpen] = useState<boolean>(false)
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-30%, -60%)'
    }
  }

  const openEditMeta = () => {
    setIsEditMetaOpen(true)
  }

  const afterOpenEditMeta = () => {
    // subtitle.style.color = '#f00'
  }

  const closeEditMeta = () => {
    setIsEditMetaOpen(false)
  }

  const openDeleteMeta = () => {
    setIsDeleteMetaOpen(true)
  }

  const afterOpenDeleteMeta = () => {
    // subtitle.style.color = '#f00'
  }

  const closeDeleteMeta = () => {
    setIsDeleteMetaOpen(false)
  }

  return (
    <div
      className='card flex-row px-9'
      style={{
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        borderRadius: '6px',
        minHeight: '60px'
      }}
    >
      <div className='fs-6 my-4 col-8 d-flex align-items-center'>
        <span className='text-gray-700 fw-bold mb-0 text-start'>{item.name}:</span>
        <span className='text-start'>&nbsp;&nbsp;{item.value}</span>
      </div>
      <div className='w-25 col-4 d-flex align-items-center'>
        <div className='btn btn-sm btn-color-primary' onClick={openEditMeta}>
          <i className='bi bi-pencil-square'></i>
        </div>
        <div className='btn btn-sm btn-color-danger' onClick={openDeleteMeta}>
          <i className='bi bi-trash'></i>
        </div>
        <ReactModal
          isOpen={isEditMetaOpen}
          onAfterOpen={afterOpenEditMeta}
          onRequestClose={closeEditMeta}
          style={customModalStyles}
          contentLabel='Edit Meta Data'
        >
          <EditMetaModal
            type={type}
            data={item}
            chatRoomId={chatRoomId}
            setIsLoading={setIsLoading}
            closeEditMeta={closeEditMeta}
          />
        </ReactModal>
        <ReactModal
          isOpen={isDeleteMetaOpen}
          onAfterOpen={afterOpenDeleteMeta}
          onRequestClose={closeDeleteMeta}
          style={customModalStyles}
          contentLabel='Delete Meta Data'
        >
          <DeleteMetaModal
            type={type}
            data={item}
            chatRoomId={chatRoomId}
            setIsLoading={setIsLoading}
            closeDeleteMeta={closeDeleteMeta}
          />
        </ReactModal>
      </div>
    </div>
  )
}

export default SidePanelItem
