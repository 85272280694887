import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import {
  useCustomerUpdateMutation,
  useTagsQuery,
  useTeamsQuery,
} from "../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { useContactDynamicFieldQuery } from "../../../Services/customer/FrontendCustomer";
import { PhoneInput } from "react-international-phone";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Loader } from "react-bootstrap-typeahead";
import { set } from "lodash";
import { isFieldDisabled } from "../../reusableComponent/FieldDisabled";


interface IProps {
  data: any;
  closeModal: any;
  refetch: any;
}

interface ErrorState {
  [key: string]: string;
}

const CustomerInfoEditModal: React.FC<IProps> = ({
  data,
  closeModal,
  refetch,
}) => {
  const { data: tagsData } = useTagsQuery({});
  const { data: teamsData } = useTeamsQuery({});

  const [theTeams, setTheTeams] = useState([]);
  const [theTags, setTheTags] = useState<any>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
  const [trigger] = useCustomerUpdateMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [name, setName] = useState<string>(data.name);
  const [number, setNumber] = useState<string>(data.phone_number || "");
  const [whatsappNumber, setWhatsappNumber] = useState(data.whatsapp_number || "");

  const [twitterId, setTwitterId] = useState(data.twitter_id);
  const [facebookId, setFacebookId] = useState(data.facebook_id);

  const [dynamicData, setDynamicData] = useState<any[]>([]);
  const [dynamicFields, setDynamicFields] = useState({});
  const { data: DynamicFieldsData } = useContactDynamicFieldQuery({});
  const [errors, setErrors] = useState({});

  const [initialDynamicFields, setInitialDynamicFields] = useState({});

  const isAdmin = localStorage.getItem("user_role");

  useEffect(() => {
    if (teamsData) {
      setTheTeams(teamsData);
      setSelectedTeamIds(
        data.accessible_teams?.map((team: any) => team.id) || []
      );
    }
  }, [teamsData, data.accessible_teams]);

  useEffect(() => {
    if (tagsData) {
      setTheTags(tagsData);
      setSelectedTagIds(data.tags?.map((tag: any) => tag.id) || []);
    }
  }, [tagsData, data.tags]);

  useEffect(() => {
    if (data?.dynamic_fields) {
      setDynamicFields(data?.dynamic_fields);
    }
  }, [data?.dynamic_fields]);

  useEffect(() => {
    if (DynamicFieldsData) {
      setDynamicData(DynamicFieldsData);
    }
  }, [DynamicFieldsData]);

  useEffect(() => {
    if (data?.dynamic_fields) {
      setDynamicFields(data?.dynamic_fields);
      setInitialDynamicFields(data.dynamic_fields);
    }
  }, [data?.dynamic_fields]);

  const toggleTagSelection = (tag: any) => {
    if (selectedTagIds.includes(tag.id)) {
      // If the selected tag is already present, remove it (clear the selection)
      setSelectedTagIds([]);
    } else {
      // If the selected tag is not present, store it as the new value (replace any existing tags)
      setSelectedTagIds([tag.id]);
    }
  };

  const toggleTeamSelection = (team: any) => {
    if (selectedTeamIds.includes(team.id)) {
      setSelectedTeamIds(selectedTeamIds.filter((id) => id !== team.id));
    } else {
      setSelectedTeamIds([...selectedTeamIds, team.id]);
    }
  };
  const handleInputChange = (label: any, value: any) => {
    setErrors({});
    setDynamicFields((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setErrors({});
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  const validateForm = () => {
    const newErrors: ErrorState = {};

    dynamicData.forEach((field: any) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }
      if (
        field?.is_required &&
        field.field_type === "check-box" &&
        !fieldValue
      ) {
        newErrors[field.label] = `${field.label} is required`;
      }

      if (field.is_required && field.field_type === "tel-phone" && fieldValue) {
        const isValidPhone =
          /^(?:\+?\d{1,3})?[ -]?\(?\d{1,4}?\)?[ -]?\d{1,4}[ -]?\d{1,4}(?: x\d{1,4})?$/.test(
            fieldValue
          );
        if (!isValidPhone) {
          newErrors[
            field.label
          ] = `${field.label} must be a valid phone number`;
        }
      }

      if (field?.field_type === "email" && field.is_required) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(dynamicFields[field.label])) {
          newErrors[field.label] = "Invalid email format";
        }
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");

      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);

      return;
    }

    setIsButtonDisabled(false);

    const dynamicFieldData = Object.keys(dynamicFields).reduce((acc, key) => {
      const field = dynamicData.find((field) => field.label === key);

      const isFieldEditableForRole = isAdmin === "admin" || field?.is_editable;
      const initialFieldValue = initialDynamicFields[key];
      let currentFieldValue = dynamicFields[key];

      if (isFieldEditableForRole && currentFieldValue !== initialFieldValue) {
        if (field?.field_type === "date") {
          const formattedDate = new Date(currentFieldValue)
            .toISOString()
            .replace("Z", "+00:00");
          acc[key] = formattedDate;
        } else {
          acc[key] = currentFieldValue;
        }
      }

      return acc;
    }, {});
    try {
      const response = await trigger({
        id: data?.id,
        name: name,
        phone_number: number,
        dynamic_fields: dynamicFieldData,
        tags: selectedTagIds,
        accessible_teams: selectedTeamIds,
        facebook_id: facebookId,
        twitter_id: twitterId,
        whatsapp_number: whatsappNumber,
      });

      if (response.data) {
        setIsLoading(false);
        closeModal();
        toast.success("Customer information updated successfully!");
        refetch();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to update. Please try again.");
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };

  const getDataTimeFormatted = (date) => {
    if (date && date.length > 0) {
      const dateObj = new Date(date);

      // Manually format the date to ensure no timezone conversion issues
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      const hours = String(dateObj.getUTCHours()).padStart(2, "0");
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="text-end">
        <CloseButton onClick={closeModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Customer Updates
        </label>
      </div>

      <div className="w-100 mb-4">
        <label>Name</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="w-100 mb-4">
        <label>Email</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="email"
          autoComplete="off"
          value={data.email}
          disabled
        />
      </div>


      <div className="w-100 mb-4">
        <label>Mobile No.</label>
        <div style={{ display: "flex", width: "100%" }}>
          <PhoneInput
            style={{ width: "100%" }}
            defaultCountry="IN"
            value={number || "+91"}
            onChange={(value) => setNumber(value)}
          />
        </div>
      </div>

      <div className="w-100 mb-4">
        <label>Whatsapp No.</label>
        <div style={{ display: "flex", width: "100%" }}>
          <PhoneInput
            style={{ width: "100%" }}
            defaultCountry="IN"
            value={whatsappNumber || "+91"}
            onChange={(value) => setWhatsappNumber(value)}
          />
        </div>
      </div>

      <div className="w-100 mb-4">
        <label>Twitter Id</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={twitterId}
          onChange={(e) => setTwitterId(e.target.value)}
        />
      </div>

      {dynamicData?.map((el: any) => (
        <React.Fragment key={el?.label}>
          <div className="mb-3">
            <InputLabel htmlFor={`dynamic_fields.${el.label}`}>
              {el.label}
              {el.is_required && <span className="required"></span>}
            </InputLabel>
            {el?.field_type === "drop-down" ? (
              <select
                className="form-control"
                disabled={isFieldDisabled(el?.is_editable)}
                value={dynamicFields[el?.label] || ""}
                onChange={(e) => handleInputChange(el.label, e.target.value)}
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {el?.choices?.map((choice: any) => (
                  <option key={choice?.id} value={choice?.choice}>
                    {choice?.choice}
                  </option>
                ))}
              </select>
            ) : el?.field_type === "check-box" ? (
              <FormControl fullWidth>
                <Select
                  labelId={`checkbox-label-${el.label}`}
                  id={`checkbox-${el.label}`}
                  disabled={isFieldDisabled(el?.is_editable)}
                  multiple
                  value={dynamicFields[el?.label] || []} // Ensure the value is always an array
                  onChange={(e) => handleInputChange(el.label, e.target.value)}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                >
                  {el?.multiple_choices?.map((choice) => (
                    <MenuItem key={choice?.id} value={choice?.choice}>
                      <Checkbox
                        checked={
                          dynamicFields[el?.label]?.includes(choice?.choice) ||
                          false
                        }
                      />
                      <ListItemText primary={choice?.choice} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : el?.field_type === "date-time" ? (
              <TextField
                type="datetime-local"
                className="form-control"
                disabled={isFieldDisabled(el?.is_editable)}
                value={dynamicFields[el?.label] || ""}
                onChange={(e) => handleInputChange(el.label, e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            ) : el?.field_type === "tel-phone" ? (
              <div style={{ display: "flex", width: "100%" }}>
                <PhoneInput
                  style={{ width: "100%" }}
                  defaultCountry="IN"
                  value={dynamicFields[el?.label] || "+91"} // Ensure the value is a string
                  onChange={(phone: string) =>
                    handleInputChange(el.label, phone)
                  }
                  disabled={isFieldDisabled(el?.is_editable)}
                />
              </div>
            ) : el?.field_type === "date" ? (
              <TextField
                type="date"
                className="form-control"
                disabled={isFieldDisabled(el?.is_editable)}
                value={dynamicFields[el?.label] || ""}
                onChange={(e) => handleInputChange(el.label, e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              <input
                type={
                  el?.field_type === "date-time"
                    ? "datetime-local"
                    : el?.field_type === "email"
                      ? "email"
                      : el?.field_type === "number"
                        ? "number"
                        : el?.field_type === "date"
                          ? "date"
                          : el?.field_type === "time"
                            ? "time"
                            : "text"
                }
                placeholder={
                  el?.field_type === "email"
                    ? "Enter your email"
                    : el?.field_type === "number"
                      ? "Number"
                      : el?.field_type === "date-time"
                        ? "Select date and time"
                        : ""
                }
                className="form-control"
                value={dynamicFields[el?.label] || ""} // Ensure the value is a string
                onChange={(e) => handleInputChange(el.label, e.target.value)}
                disabled={isFieldDisabled(el?.is_editable)}
                onBlur={(e) => {
                  if (el?.field_type === "email") {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const emailValue = e.target.value.trim();

                    if (!emailPattern.test(emailValue)) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [el?.label]: "Invalid email address",
                      }));
                    } else {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [el?.label]: "",
                      }));
                    }
                  }
                }}
              />
            )}
          </div>
          {errors[el.label] && (
            <div style={{ color: "red" }}>{errors[el.label]}</div>
          )}
        </React.Fragment>
      ))}

      <div className="w-100 mb-4">
        <label>Tags:</label>
        <Grid container spacing={1}>
          {theTags.map((tag: any) => (
            <Grid item key={tag.id}>
              <Chip
                label={tag.name}
                color={selectedTagIds.includes(tag.id) ? "primary" : "default"}
                onClick={() => toggleTagSelection(tag)}
                style={{ margin: "5px" }}
              />
            </Grid>
          ))}
        </Grid>
      </div>

      <div className="w-100 mb-4">
        <label>Teams:</label>
        <Grid container spacing={1}>
          {theTeams.map((team: any) => (
            <Grid item key={team.id}>
              <Chip
                label={team.name}
                color={
                  selectedTeamIds.includes(team.id) ? "primary" : "default"
                }
                onClick={() => toggleTeamSelection(team)}
                style={{ margin: "5px" }}
              />
            </Grid>
          ))}
        </Grid>
      </div>

      <div className="text-end mt-3 mb-4">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={submitHandler}
          disabled={isButtonDisabled} // Disable button based on state
        >
          {isLoading ? (
            <>
              Submitting... <Loader />
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </>
  );
};

export default CustomerInfoEditModal;
