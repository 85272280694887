import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  useTeamsQuery,
  useUpdateDynamicFieldDefinitionsMutation,
} from "../../../../Services/settingsAPIs/CommonAPI";
import { toast } from "react-toastify";
import { toSentenceCase } from "../../../../utils/functions/toSentenceCase";
import {
  useAddChoiceToDynamicFieldDDMutation,
  useAddOptionToDynamicFieldCheckboxMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { CloseButton } from "react-bootstrap";

const AddNewCampaignFieldModal = ({
  closeModal,
  workflowSelected,
  refetch,
}) => {
  const [theTeams, setTheTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const fieldTypes = [
    "text",
    "email",
    "number",
    "tel-phone",
    "text-area",
    "check-box",
    "date-time",
    "date",
    "time",
    "drop-down",
    "multi-level",
  ];
  // const [constraintType, setConstraintType] = useState({ constraint: '', status: false });
  const { data: teamsData } = useTeamsQuery({
    // skip: !(constraintType.constraint === 'mandatory' && constraintType.status),
  });
  const [lengthQueryFieldEnabled, setLengthQueryFieldEnabled] = useState(false);
  const [fieldTypeSelected, setFieldTypeSelected] = useState("");
  const [triggerUpdateDynamicFieldsMutation] =
    useUpdateDynamicFieldDefinitionsMutation();
  const [triggerAddOptionToCheckboxField] =
    useAddOptionToDynamicFieldCheckboxMutation();
  const [triggerAddChoiceToDDfield] = useAddChoiceToDynamicFieldDDMutation();

  const handleToCheckCustomOptions = (evt) => {
    setFieldTypeSelected(evt.target.value);
    setLengthQueryFieldEnabled(["text", "number"].includes(evt.target.value));
  };

  const validationSchemaFormOption = Yup.object().shape({
    label: Yup.string().required("Please provide a field name"),
    field_type: Yup.string().required("Please provide a field type"),
    options: Yup.array()
      .of(Yup.string().required("Option cannot be empty"))
      .min(1, "At least one option is required")
      .when("field_type", {
        is: (field_type: string) =>
          ["check-box", "drop-down"].includes(field_type),
        then: Yup.array()
          .of(Yup.string().required("Option cannot be empty"))
          .min(1, "At least one option is required"),
        otherwise: Yup.array().notRequired(),
      }),
  });

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Please provide a field name"),
    field_type: Yup.string().required("Please provide a field type"),
  });
  const handleSubmit = (values, actions) => {
    values.campaign_name = workflowSelected;
    values.team = selectedTeams.map((item) => item.value);
    values.label = toSentenceCase(values.label);

    setTimeout(() => {
      actions.setSubmitting(false);
    }, 500);

    if (
      (values.field_type === "check-box" &&
        values.options.some((option) => !option)) ||
      (values.field_type === "drop-down" &&
        values.options.some((option) => !option))
    ) {
      alert("All options must be filled.");
    } else {
      triggerUpdateDynamicFieldsMutation(values)
        .then((res) => {
          if (res.data) {
            if (values.field_type === "check-box") {
              values.options.forEach((option) => {
                triggerAddOptionToCheckboxField({
                  choice: option,
                  field: res.data.id,
                });
              });
            } else if (values.field_type === "drop-down") {
              values.options.forEach((option) => {
                triggerAddChoiceToDDfield({
                  choice: option,
                  field: res.data.id,
                });
              });
            }
            toast.success("Field added successfully");
            closeModal();
            refetch();
          } else if (res.error) {
            if (
              typeof res.error === "object" &&
              res.error !== null &&
              "data" in res.error
            ) {
              const errorData = res.error as {
                data: { non_field_errors?: string[] };
              };
              const errorMessage =
                errorData?.data?.non_field_errors?.[0] ?? "An error occurred";
              toast.error(errorMessage);
            } else {
              toast.error("An unexpected error occurred.");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (teamsData) {
      setTheTeams(teamsData);
    }
  }, [teamsData]);

  const [constraintType, setConstraintType] = useState({
    required: false,
    unique: false,
    masked: false,
    editable: false,
  });

  const contraints = ["required", "unique", "masked", "editable"];

  return (
    <div>
      <Formik
        initialValues={{
          field_type: "",
          label: "",
          is_required: constraintType?.required,
          is_unique: constraintType?.unique,
          is_editable: true,
          is_masked: constraintType?.masked,
          model_name: "campaign",
          campaign_name: -1,
          field_length: 0,
          team: [],
          options: [""],
          ticket_name: "",
        }}
        validationSchema={
          fieldTypeSelected === "check-box" ||
          fieldTypeSelected === "drop-down" ||
          fieldTypeSelected === "radio"
            ? validationSchemaFormOption
            : validationSchema
        }
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors, touched, setFieldValue }) => (
          <Form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework form-floating">
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                style={{
                  width: "90%",
                  justifyContent: "center",
                }}
              >
                <div>Label</div>
                <Field
                  className="form-control mx-4"
                  style={{ maxWidth: "40%" }}
                  type="text"
                  name="label"
                  id="label"
                />
                {touched.label && errors.label && (
                  <div className="text-danger">{errors.label}</div>
                )}
                <button
                  style={{
                    transition: "background-color 0.6s ease",
                  }}
                  className={`${
                    Object.keys(errors).length
                      ? "btn-secondary min-w-150px"
                      : Object.keys(touched).length
                      ? "btn-primary min-w-150px"
                      : "btn-secondary min-w-150px"
                  } btn d-flex align-items-center`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <i className="text-start fs-2 bi bi-plus-lg me-2"></i>
                  <span>Add</span>
                  <i
                    className={`${
                      Object.keys(errors).length
                        ? "mx-2 bi fs-2"
                        : Object.keys(touched).length
                        ? "mx-2 bi fs-2 bi-check-circle-fill"
                        : "mx-2 bi fs-2"
                    } `}
                  ></i>
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "end",
                }}
              >
                <CloseButton onClick={closeModal} />
              </div>
            </div>

            <div>
              <label htmlFor="" className="form-label">
                Choose Type
              </label>
              <div className="row row-cols-6 mt-1">
                {fieldTypes?.map((field, index) => (
                  <div className="col" key={index}>
                    <Field
                      className="mt-2 form-check-input"
                      type="radio"
                      name="field_type"
                      value={field}
                      id={`thefield${index}`}
                      onInput={handleToCheckCustomOptions}
                    />
                    <label
                      className="mx-1 mt-2 form-label"
                      htmlFor={`thefield${index}`}
                    >
                      {toSentenceCase(field)}
                    </label>
                  </div>
                ))}
                <ErrorMessage
                  name="field_type"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-6">
              {contraints?.map((item) => (
                <React.Fragment key={item}>
                  <input
                    style={{
                      cursor: "pointer",
                    }}
                    className="ms-4 form-check-input"
                    type="checkbox"
                    disabled={
                      item === "masked" &&
                      (fieldTypeSelected === "drop-down" ||
                        fieldTypeSelected === "check-box" ||
                        fieldTypeSelected === "mu lti-level")
                    }
                    onChange={() => {
                      if (
                        item === "masked" &&
                        (fieldTypeSelected === "drop-down" ||
                          fieldTypeSelected === "check-box" ||
                          fieldTypeSelected === "multi-level")
                      ) {
                        setFieldValue(`is_masked`, false);
                      } else {
                        setFieldValue(
                          `is_${item.toLowerCase()}`,
                          !values[`is_${item.toLowerCase()}`]
                        );
                      }
                    }}
                    checked={
                      item === "masked"
                        ? fieldTypeSelected !== "drop-down" &&
                          fieldTypeSelected !== "check-box" &&
                          fieldTypeSelected !== "multi-level" &&
                          values.is_masked
                        : values[`is_${item.toLowerCase()}`]
                    }
                  />
                  <label
                    className="mx-1 form-label"
                    style={{
                      color:
                        item === "masked" &&
                        (fieldTypeSelected === "drop-down" ||
                          fieldTypeSelected === "check-box" ||
                          fieldTypeSelected === "multi-level")
                          ? "grey"
                          : "",
                    }}
                  >
                    Mark as {item}
                  </label>
                </React.Fragment>
              ))}
            </div>

            {/* {lengthQueryFieldEnabled && (
                            <div>
                                <label htmlFor="label" className="form-label mt-6">Length of the field</label>
                                <Field className="form-control form-control-lg" type="number" id="field_length" name="field_length" placeholder="Enter a length for this field" />
                            </div>
                        )} */}

            {/* Dynamic Options based on Field Type */}
            <div className="row">
              <div className="row">
                {(values.field_type === "check-box" ||
                  values.field_type === "drop-down" ||
                  values.field_type === "radio") && (
                  <div className="col">
                    <FieldArray name="options">
                      {({ push, remove }) => (
                        <>
                          <label htmlFor="options" className="form-label">
                            {values.field_type === "check-box"
                              ? "Checkbox Options"
                              : values.field_type === "drop-down"
                              ? "Dropdown Options"
                              : "Radio Options"}
                          </label>

                          {values.options.length > 0 &&
                            values.options.map((option, index) => (
                              <div
                                className="d-flex align-items-center mb-2"
                                key={index}
                              >
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.options &&
                                    errors.options[index] &&
                                    touched.options &&
                                    touched.options[index]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name={`options.${index}`}
                                  placeholder={`Enter a ${values.field_type} item`}
                                />
                                <ErrorMessage
                                  name={`options.${index}`}
                                  component="div"
                                  className="text-danger"
                                />

                                <button
                                  type="button"
                                  className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete option"
                                  aria-label="Delete"
                                  onClick={() =>
                                    values.options.length > 1 && remove(index)
                                  }
                                >
                                  <i className="bi text-dark bi-trash-fill fs-2 mx-4" />
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Add option"
                                  aria-label="Add"
                                  onClick={() => push("")}
                                >
                                  <i className="bi primary bi-plus fs-2 mx-4" />
                                </button>
                              </div>
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewCampaignFieldModal;
