import React, { useState } from 'react';
import WhatsAppConfiguration from '../../components/settings/configuration/WhatsAppConfiguration/WhatsAppConfiguration';
import Template from '../../components/settings/configuration/Template/Template';
import MetaTemplate from '../../components/settings/configuration/MetaTemplate/MetaTemplate';

const Configuration = () => {
    const [activeTabIndex, setActiveTabIndex] = useState<any>(0);
    const tabTitles = [
        "WhatsApp",
        "WhatsApp template",
         "Meta template"

    ];

    const mappingObj = {
        0: <WhatsAppConfiguration />,
        1: <Template />,
        2:<MetaTemplate/>

    }

    return (
        <div className="text-start">
            <div>
                <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
                    {tabTitles?.map((title, index) => (
                        <li className="nav-item" key={Math.random()}>
                            <span
                                style={{ textAlign: "center" }}
                                className={`nav-link cursor-pointer fs-4 fw-bold ${activeTabIndex === index ? "active fw-bolder" : ""
                                    }`}
                                onClick={() => {
                                    setActiveTabIndex(index);
                                }}
                                role="tab"
                            >
                                {title}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            {mappingObj[activeTabIndex]}
        </div>
    );
};

export default Configuration
