import React, { useState } from "react";
import { useDeleteEscallationLevelMutation } from "../../../../../Services/settingsAPIs/CommonAPI";

interface IProps {
  closeDeleteModal: () => void;
  levelName: string;
  tagId: any;
  refetch: () => void;
}

const DeleteLevel: React.FC<IProps> = ({
  closeDeleteModal,
  levelName,
  tagId,
  refetch,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [triggerDeleteEscallationLevel] = useDeleteEscallationLevelMutation();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await triggerDeleteEscallationLevel({
        id: tagId,
        escalationLevel: levelName,
      }).unwrap();
      refetch(); // Refresh data after deletion
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting escalation level:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="card-body">
      <p className="fs-5 fw-bold">
        Are you sure you want to delete the escalation level{" "}
        <strong>{levelName}</strong>?
      </p>

      <div className="d-flex justify-content-end mt-10">
        <button
          type="button"
          className="btn btn-sm btn-white btn-active-light-primary me-2"
          onClick={closeDeleteModal}
          disabled={isDeleting}
        >
          Cancel
        </button>

        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          {isDeleting ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            <span className="indicator-label">Delete</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteLevel;
