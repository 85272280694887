import UnassignedLeftPanel from "./UnassignedLeftPanel";
import AssignedLeftPanel from "./AssignedLeftPanel";
import SidePanel from "../SidePanel";
import { SetStateAction, useContext } from "react";
import ChatContext from "../../../context/ChatContext";
import ResolvedLeftPanel from "./ResolvedLeftPanel";

interface IProps {
  type: "Unassigned" | "Assigned" | "Resolved" | "Chat-Report";
  chatRoomID: any;
  isData2: any;
  isData3: any;
  handleOpenChat: any;
  disabledIndexes: any;
}

const ChatLeftPanel: React.FC<IProps> = ({
  type,
  chatRoomID,
  isData2,
  isData3,
  handleOpenChat,
  disabledIndexes,
}) => {
  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mx-lg-0 mb-md-2">
      {type === "Unassigned" && <UnassignedLeftPanel type={type} />}
      {type === "Assigned" && (
        <AssignedLeftPanel
          type={type}
          chatRoomID={chatRoomID}
          isData2={isData2}
          isData3={isData3}
          handleOpenChat={handleOpenChat}
          disabledIndexes={disabledIndexes}
        />
      )}
      {type === "Resolved" && <ResolvedLeftPanel type={type} />}
    </div>
  );
};

export default ChatLeftPanel;
