import React, { useState } from "react";
import { CloseButton } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import ReactModal from "react-modal";
import TemplateDetailsModal from "./TemplateDetailsModal"; // Import the new modal

interface IPROPS {
    closeModal: any;
    refetch: any;
    templateData: any;
}

const TemplateModal: React.FC<IPROPS> = ({ closeModal, refetch, templateData }) => {
    const [templateInput, setTemplateInput] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleTemplateChange = (event, newValue) => {
        setSelectedTemplate(newValue || "");
        const foundTemplate = templateData.find((t) => t.template_name === newValue);
        setSelectedTemplateDetails(foundTemplate || null);
        if (foundTemplate) setIsModalOpen(true);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", width: "100%" }}>
                <span style={{ width: "60%", textAlign: "right", fontSize: "16px" }}>Add template</span>
                <span style={{ flex: "1", textAlign: "right" }}>
                    <CloseButton onClick={closeModal} />
                </span>
            </div>

            <div>
                <label>Please select any template</label>
                <Autocomplete
                    freeSolo
                    options={templateData.map((option) => option.template_name)}
                    inputValue={templateInput}
                    onInputChange={(event, newInput) => setTemplateInput(newInput)}
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    renderInput={(params) => <TextField {...params} placeholder="Select template" />}
                    sx={{ width: "100%", "& .MuiAutocomplete-endAdornment": { display: "none" } }}
                />
            </div>

            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        maxWidth: "1050px",
                        height: "70%",
                        overflowY: "auto",
                    },
                }}
                contentLabel="Template Details"
            >
                <TemplateDetailsModal
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    template={selectedTemplateDetails}
                    closeModal={closeModal}
                    refetch={refetch}

                />
            </ReactModal>
        </div>
    );
};

export default TemplateModal;
