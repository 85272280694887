import React, { useEffect, useRef, useState } from "react";
import { CardContent } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";
import {
  useGetAllUserListQuery,
  useGetBreakReportQuery,
  useGetAllTeamsListQuery,
} from "../../../../Services/reports/tickets/ReportTickets";
import { toast } from "react-toastify";
import CsvDownloader from "react-csv-downloader";
import { set } from "lodash";

const BreakReport = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [reportType, setReportType] = useState("Inbound"); // Default to Inbound
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [selectedAssignee, setSelectedAssignee] = useState<string>("");
  const [theUser, setTheUser] = useState<any>([]);
  const [allTeam, setAllTeam] = useState<any>([]);
  const { data: userData, isLoading: userLoading } = useGetAllUserListQuery({});
  const { data: teamsData, isLoading: teamsLoading } = useGetAllTeamsListQuery(
    {}
  );
  const [selectTeam, setSelectTeam] = useState<any>("");
  const [params, setParams] = useState<any>(null);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [localLoading, setLocalLoading] = useState(false);

  const { data: BreakReportData, isLoading: breakReportLoading } =
    useGetBreakReportQuery(params, {
      skip: !params,
    });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const columns: GridColDef[] = [
    {
      field: "srNo",
      headerName: "Sr.No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "department",
      headerName: "Department Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agent_name",
      headerName: "User Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "start_date",
      headerName: "Start Date Time ",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    // {
    //   field: "start_time",
    //   headerName: "Pause/Aux Code Start Time",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "end_date",
      headerName: "End Date Time",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "end_time",
    //   headerName: "Pause/Aux Code End Time",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      field: "aux_code",
      headerName: "AUX CODE",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleDateFromChange = (event: any) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: any) => {
    setDateTo(event.target.value);
  };

  const handleChange: any = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAssignee(event.target.value as string);
  };
  const handleTeamChange = (event: any) => {
    setSelectTeam(event.target.value);
  };

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll(".dropdown-heading");
    dropdownHeadings.forEach((heading: any) => {
      heading.style.height = "50px";
    });
  }, []);

  useEffect(() => {
    const transformedData = userData?.map((user) => ({
      id: user.id,
      username: user.username,
    }));
    if (Array.isArray(userData)) {
      setTheUser(transformedData);
    } else {
      setTheUser([]); // Fallback to an empty array if it's not an array
    }
  }, [userData]);

  useEffect(() => {
    const transformedData1 = teamsData?.map((user) => ({
      id: user.id,
      name: user.name,
    }));
    if (Array.isArray(transformedData1)) {
      setAllTeam(transformedData1);
    } else {
      setAllTeam([]); // Fallback to an empty array if it's not an array
    }
  }, [teamsData]);

  const handleSubmit = () => {
    if (!dateFrom) {
      toast.error('Please select "From Date"');
      return;
    }
    if (!dateTo) {
      toast.error('Please select "To Date"');
      return;
    }
    if (!selectedAssignee) {
      toast.error("Please select Assignee");
      return;
    }
    if (!selectTeam) {
      toast.error("Please select Team");
      return;
    }

    const queryParams: any = {
      gte: dateFrom,
      lte: dateTo,
    };

    if (selectedAssignee !== "All") {
      queryParams.user_id = selectedAssignee; // Add user_id when an assignee is selected
    }
    if (selectTeam !== "All") {
      queryParams.team_id = selectTeam;
    }
    setParams(queryParams);
  };

  function formatDate(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  function formatTime(datetime) {
    if (!datetime) return "No data found";
    const dateObj = new Date(datetime);
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  const formatDuration = (duration) => {
    const parts = duration.split(':').map(part => part.padStart(2, '0'));
    return parts.join(':');
  };

  useEffect(() => {
    if (Array.isArray(BreakReportData)) {
      const transformedRows = BreakReportData.map((item, index) => ({
        id: index + 1, // Unique identifier for the row
        srNo: index + 1,
        department: item.team_name || "-", // Mapping department name
        agent_name: item.agent_name || "-", // Mapping user name
        start_date:item.break_start_time_display || "-", // Mapping start time
        // start_time:
        //   formatTime(item.break_start_time_display) || "No data found", // Mapping start time
        end_date:item.break_end_time_display || "-", // Mapping end time
        // end_time: formatTime(item.break_end_time_display) || "No data found", // Mapping end time
        aux_code: item.break_remark || "-", // Mapping aux code
        duration: item.break_duration ? formatDuration(item.break_duration) : "-", // Mapping duration
      }));

      setDataRows(transformedRows);
      setLocalLoading(false);
    } else {
      setDataRows([]); // Reset if data is not an array
      setLocalLoading(false);
    }
    console.log(BreakReportData);
    setLocalLoading(false);
  }, [BreakReportData]);

  const downloaderRef = useRef(null);

  const handleDownloadClick = () => {
    if (downloaderRef.current) {
      downloaderRef.current.handleDownload();
    }
  };
  const resetFilters = () => {
    setDateFrom("");
    setDateTo("");
    setParams(null);
  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  const handlePaginationChange = (newPaginationModel: any) => {
    setLocalLoading(true);
    setPaginationModel(newPaginationModel);
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (params) {
      setLocalLoading(true);
    }
  }, [params]);

  return (
    <div id="kt_app_content_container" className="app-container mx-5">
      <div className="card card-flush">
        {/* Card Header */}
        <div
          className="card-header align-items-center py-1 gap-2 gap-md-5"
          style={{ justifyContent: "flex-start" }}
        >
          {/* Back button */}
          {/* <img
            src="https://insurance.circleonecrm.com/assets/arrow_back.svg"
            alt="Back"
            style={{ marginBottom: "-2px", cursor: "pointer" }}
            className="ng-star-inserted"
            onClick={() => {
              navigate("/reports/telephony/reports-list");
            }}
          /> */}
          <button
              className="btn btn-link mb-2"
              onClick={() => {
                navigate("/reports/telephony/reports-list");
              }}
              style={{marginRight:"-17px"}}
            >
             <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                    }}></i>
            </button>
          <span className="fs-3 text-gray-800 fw-bold ms-3">Agent Breaks</span>
        </div>

        {/* Toolbar */}
        <div
          className="card-toolbar flex-row-fluid justify-content-start gap-5 d-flex mb-10"
          style={{ marginLeft: "30px" }}
        >
          <div className="w-100 mw-150px">
            <TextField
              id="date-from"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateFrom}
              onChange={handleDateFromChange}
            />
          </div>
          <div className="w-100 mw-150px">
            <TextField
              id="date-to"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" }, // Optional: uppercase input text
                },
              }}
              fullWidth
              value={dateTo}
              onChange={handleDateToChange}
            />
          </div>

          {/* Adjusted Assignee Select Fields */}
          <div
            className="w-150 mw-150px"
            style={{ flex: "1 1 200px", marginRight: "10px" }}
          >
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Assignee</InputLabel>
              <Select
                labelId="Assignee-select-label"
                id="Assignee-select"
                label="Assignee"
                MenuProps={MenuProps}
                value={selectedAssignee}
                onChange={handleChange}
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {theUser?.map((assignee: any) => (
                  <MenuItem key={assignee.id} value={assignee.id}>
                    {assignee.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-150 mw-150px" style={{ flex: "1 1 200px" }}>
            <FormControl fullWidth>
              <InputLabel id="Assignee-select-label">Select Team</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                label="Select Team"
                MenuProps={MenuProps}
                value={selectTeam}
                onChange={handleTeamChange}
              >
                <MenuItem key="all" value="All">
                  All
                </MenuItem>
                {allTeam?.map((team: any) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="ms-auto d-flex gap-3" style={{ marginRight: "20px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>

            {/* CsvDownloader component outside the button */}
            <button className="btn btn-primary">
              <CsvDownloader
                ref={downloaderRef}
                filename={`break-report`}
                datas={dataRows}
                columns={columns.map((col) => ({
                  id: col.field,
                  displayName: col.headerName,
                }))}
                separator=","
                wrapColumnChar={`"`}
              >
                Download
              </CsvDownloader>
            </button>

            <button className="btn btn-link" onClick={resetFilters}>
              <i className="bi bi-funnel fs-2 text-dark"></i>
            </button>
          </div>
        </div>

        <CardContent style={{ width: "100%" }}>
          {userLoading || breakReportLoading || localLoading || teamsLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={dataRows}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={localLoading}
              disableColumnMenu
              pageSizeOptions={[5, 10, 15, 20]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightgray",
                  "&:last-child": {
                    justifyContent: "flex-end",
                  },
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "lightgray",
                },
                // Target the pagination select and actions by stable component classes
                "& .MuiTablePagination-select, .MuiTablePagination-actions": {
                  marginBottom: "10px",
                },
                // Target the DataGrid overlay wrapper with its stable class
                "& .MuiDataGrid-overlayWrapper": {
                  height: "50px",
                },
              }}
            />
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default BreakReport;
