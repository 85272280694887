import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { toSentenceCase } from "../../../../utils/functions/toSentenceCase";
import {
    useAddOptionToDynamicFieldCheckboxMutation,
    useAddChoiceToDynamicFieldDDMutation,
} from "../../../../Services/settingsAPIs/TicketSettingsAPI";
import { useUserDynamicFiedMutation } from "../../../../Services/settingsAPIs/CommonAPI";
import { CloseButton } from "react-bootstrap";

// Define Yup schema for validation

interface IProps {
    closeModal: any;
    refetch: any;
}

const NewFieldModal: React.FC<IProps> = ({ closeModal, refetch }) => {
    const fieldTypes = [
        "text",
        "email",
        "number",
        "tel-phone",
        "text-area",
        "check-box",
        "date-time",
        "date",
        "time",
        "drop-down",
        'Multi-level'
    ];
    // const [constraintType, setConstraintType] = useState({ constraint: '', status: false });

    const [constraintType, setConstraintType] = useState({
        required: false,
        unique: false,
        masked: false,
        editable: false,
    });

    const [lengthQueryFieldEnabled, setLengthQueryFieldEnabled] =
        useState(false);
    const [fieldTypeSelected, setFieldTypeSelected] = useState("");

    const [triggerUpdateDynamicFieldsMutation] = useUserDynamicFiedMutation();
    const [triggerAddOptionToCheckboxField] =
        useAddOptionToDynamicFieldCheckboxMutation();
    const [triggerAddChoiceToDDfield] = useAddChoiceToDynamicFieldDDMutation();

    const handleToCheckCustomOptions = (evt: any) => {
        setFieldTypeSelected(evt.target.value);
        setLengthQueryFieldEnabled(
            ["text", "number"].includes(evt.target.value)
        );
    };

    const validationSchemaFormOption = Yup.object().shape({
        label: Yup.string().required("Please provide a field name"),
        field_type: Yup.string().required("Please provide a field type"),
        options: Yup.array()
            .of(Yup.string().required("Option cannot be empty"))
            .min(1, "At least one option is required")
            .when("field_type", {
                is: (field_type: string) =>
                    ["check-box", "drop-down"].includes(field_type),
                then: Yup.array()
                    .of(Yup.string().required("Option cannot be empty"))
                    .min(1, "At least one option is required"),
                otherwise: Yup.array().notRequired(),
            }),
    });

    const validationSchema = Yup.object().shape({
        label: Yup.string().required("Please provide a field name"),
        field_type: Yup.string().required("Please provide a field type"),
    });

    const handleSubmit = (values: any, actions: any) => {
        values.label = toSentenceCase(values.label);

        setTimeout(() => {
            actions.setSubmitting(false);
        }, 500);

        if (values.field_type === "check-box" && values.options.length === 0) {
            toast.error("At least one checkbox option is mandatory");
        } else if (
            values.field_type === "drop-down" &&
            values.options.length === 0
        ) {
            toast.error("At least one dropdown item is mandatory");
        } else if (
            values.field_type === "radio" &&
            values.options.length === 0
        ) {
            toast.error("At least one radio option is mandatory");
        } else {
            triggerUpdateDynamicFieldsMutation(values)
                .then((res: any) => {
                    if (res.data) {
                        if (values.field_type === "check-box") {
                            values.options.forEach((option: string) => {
                                triggerAddOptionToCheckboxField({
                                    choice: option,
                                    field: res.data.id,
                                });
                            });
                            toast.success("Added a field to the workflow");
                        } else if (values.field_type === "drop-down") {
                            values.options.forEach((option: string) => {
                                triggerAddChoiceToDDfield({
                                    choice: option,
                                    field: res.data.id,
                                });
                            });
                            toast.success("Added a field to the workflow");
                        } else if (values.field_type === "radio") {
                            values.options.forEach((option: string) => {
                                triggerAddChoiceToDDfield({
                                    choice: option,
                                    field: res.data.id,
                                });
                            });
                            toast.success("Added a field to the workflow");
                        } else {
                            toast.success("Added a field to the workflow");
                        }
                        closeModal();
                        refetch();
                    } else if (res?.error) {
                        toast.error(
                            res?.error?.data?.non_field_errors[0] ||
                                "An error occured"
                        );
                    }
                })
                .catch((err) => {
     
                    toast.error("An error occured");
                });
        }
    };

    useEffect(() => {
        if (["check-box", "drop-down", "radio"].includes(fieldTypeSelected)) {
            setLengthQueryFieldEnabled(false);
        } else {
            setLengthQueryFieldEnabled(
                ["text", "number"].includes(fieldTypeSelected)
            );
        }
    }, [fieldTypeSelected]);

    const contraints = ["required", "unique", "masked", "editable"];

    return (
        <div>
            <Formik
                initialValues={{
                    field_type: "",
                    label: "",
                    is_required: constraintType?.required, // Linking to constraintType
                    is_unique: constraintType?.unique,
                    is_editable: true,
                    is_masked: constraintType?.masked,
                    model_name: "userprofile",
                    ticket_name: null,
                    options:
                        fieldTypeSelected === "check-box" ||
                        fieldTypeSelected === "drop-down" ||
                        fieldTypeSelected === "radio"
                            ? [""]
                            : [],
                }}
                validationSchema={
                    fieldTypeSelected === "check-box" ||
                    fieldTypeSelected === "drop-down" ||
                    fieldTypeSelected === "radio"
                        ? validationSchemaFormOption
                        : validationSchema
                }
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue }) => (
                    <Form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework overflow-y-auto">
                
                <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center"    style={{
                                    width: "90%",
                                    justifyContent: "center",
                                }}>
                                <div>Label</div>
                                <Field
                                    className="form-control mx-4"
                                    style={{ maxWidth: "40%" }}
                                    type="text"
                                    name="label"
                                    id="label"
                                />
                                {touched.label && errors.label && (
                                    <div className="text-danger">
                                        {errors.label}
                                    </div>
                                )}
                                <button
                                    style={{
                                        transition:
                                            "background-color 0.6s ease",
                                    }}
                                    className={`${
                                        Object.keys(errors).length
                                            ? "btn-secondary min-w-150px"
                                            : Object.keys(touched).length
                                            ? "btn-primary min-w-150px"
                                            : "btn-secondary min-w-150px"
                                    } btn d-flex align-items-center`}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <i className="text-start fs-2 bi bi-plus-lg me-2"></i>
                                    <span>Add</span>
                                    <i
                                        className={`${
                                            Object.keys(errors).length
                                                ? "mx-2 bi fs-2"
                                                : Object.keys(touched).length
                                                ? "mx-2 bi fs-2 bi-check-circle-fill"
                                                : "mx-2 bi fs-2"
                                        } `}
                                    ></i>
                                </button>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flex: "1",
                                    justifyContent: "end",
                                }}
                            >
                                <CloseButton onClick={closeModal} />
                            </div>
                        </div>

                        <label htmlFor="" className="form-label">
                            Choose Type
                        </label>
                        <div className="row row-cols-6 mt-1">
                            {fieldTypes?.map((field, index) => (
                                <div className="col" key={index}>
                                    <Field
                                        className="mt-2 form-check-input"
                                        type="radio"
                                        name="field_type"
                                        value={field}
                                        id={`thefield${index}`}
                                        onInput={handleToCheckCustomOptions}
                                    />
                                    <label
                                        className="mx-1 mt-2 form-label"
                                        htmlFor={`thefield${index}`}
                                    >
                                        {toSentenceCase(field)}
                                    </label>
                                </div>
                            ))}
                            {touched.field_type && errors.field_type && (
                                <div className="text-danger">
                                    {errors?.field_type}
                                </div>
                            )}
                        </div>

                        {/* CHECKBOX */}

                        <div className="d-flex justify-content-center mt-6">
                            {contraints?.map((item) => (
                                <React.Fragment key={item}>
                                    <input
                                     style={{
                                        cursor:
                                           "pointer",
                                    }}
                                        className="ms-4 form-check-input"
                                        type="checkbox"
                                        disabled={
                                            item === "masked" &&
                                            (fieldTypeSelected ===
                                                "drop-down" ||
                                                fieldTypeSelected ===
                                                    "check-box" ||
                                                fieldTypeSelected ===
                                                    "multi-level")
                                        }
                                        onChange={() => {
                                            if (
                                                item === "masked" &&
                                                (fieldTypeSelected ===
                                                    "drop-down" ||
                                                    fieldTypeSelected ===
                                                        "check-box" ||
                                                    fieldTypeSelected ===
                                                        "multi-level")
                                            ) {
                                                setFieldValue(
                                                    `is_masked`,
                                                    false
                                                );
                                            } else {
                                                setFieldValue(
                                                    `is_${item.toLowerCase()}`,
                                                    !values[
                                                        `is_${item.toLowerCase()}`
                                                    ]
                                                );
                                            }
                                        }}
                                        checked={
                                            item === "masked"
                                                ? fieldTypeSelected !==
                                                      "drop-down" &&
                                                  fieldTypeSelected !==
                                                      "check-box" &&
                                                  fieldTypeSelected !==
                                                      "multi-level" &&
                                                  values.is_masked
                                                : values[
                                                      `is_${item.toLowerCase()}`
                                                  ]
                                        }
                                    />
                                    <label
                                        className="mx-1 form-label"
                                        style={{
                                            color:
                                            
                                            item === "masked" && 
                                            (fieldTypeSelected === "drop-down" ||
                                            fieldTypeSelected === "check-box" ||
                                            fieldTypeSelected === "multi-level")
                                                ? "grey" 
                                                : "", 
                                        }}
                                    >
                                        Mark as {item}
                                    </label>

                                    {/* I want only "masked color shall get changes"*/}
                                </React.Fragment>
                            ))}
                        </div>
                        {/* <div className="d-flex justify-content-center mt-6">
              <input className="ms-4 form-check-input" type="checkbox" name="constraints" id="mandatory" value="mandatory" onChange={(evt) => setConstraintType({ constraint: 'mandatory', status: evt.target.checked })} />
              <label className="mx-1 form-label" htmlFor="mandatory">Mark as mandatory</label>
              <input className="ms-4 form-check-input" type="checkbox" name="constraints" id="unique"  value="unique" onChange={(evt) => setConstraintType({ constraint: 'unique', status: evt.target.checked })} />
              <label className="mx-1 form-label" htmlFor="unique">Mark as unique</label>
              <input className="ms-4 form-check-input" type="checkbox" name="constraints" id="masked"  value="masked" onChange={(evt) => {
                setFieldValue('is_masked', evt.target.checked);
                setConstraintType({ constraint: 'masked', status: evt.target.checked })
                }} 
                checked={values.is_masked}
                />
              <label className="mx-1 form-label" htmlFor="masked">Mark as masked</label>
              <input
        className="ms-4 form-check-input"
        type="checkbox"
        name="constraints"
        id="editable"
        value="editable"
        checked={values.is_editable}
        onChange={(evt) => {
          setFieldValue('editable', evt.target.checked);

          setConstraintType({ constraint: 'editable', status: evt.target.checked });
        }}
      />
      <label className="mx-1 form-label" htmlFor="editable">Mark as editable</label>
            </div> */}

            

                        <div className="row">
                            <div className="row">
                                {(fieldTypeSelected === "check-box" ||
                                    fieldTypeSelected === "drop-down" ||
                                    fieldTypeSelected === "radio") && (
                                    <div className="col">
                                        <FieldArray name="options">
                                            {({ push, remove }) => (
                                                <>
                                                    <label
                                                        htmlFor="options"
                                                        className="form-label"
                                                    >
                                                        {fieldTypeSelected ===
                                                        "check-box"
                                                            ? "Checkbox Options"
                                                            : fieldTypeSelected ===
                                                              "drop-down"
                                                            ? "Dropdown Options"
                                                            : "Radio Options"}
                                                    </label>

                                                    {values.options.length ===
                                                        0 && (
                                                        <div className="d-flex align-items-center mb-2">
                                                            <Field
                                                                type="text"
                                                                className="form-control me-2"
                                                                name={`options.0`}
                                                                placeholder={`Enter a ${fieldTypeSelected} item`}
                                                                defaultValue=""
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="top"
                                                                title="Cannot delete the last option"
                                                                aria-label="Delete"
                                                                onClick={() =>
                                                                    remove(0)
                                                                }
                                                                disabled={
                                                                    values
                                                                        .options
                                                                        .length <=
                                                                    1
                                                                }
                                                            >
                                                                <i className="bi bi-trash fs-4" />
                                                            </button>
                                                        </div>
                                                    )}

                                                    {values.options.length >
                                                        0 &&
                                                        values.options.map(
                                                            (
                                                                option: string,
                                                                index: number
                                                            ) => (
                                                                <div
                                                                    className="d-flex align-items-center mb-2"
                                                                    key={index}
                                                                >
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control "
                                                                        name={`options.${index}`}
                                                                        placeholder={`Enter a ${fieldTypeSelected} item`}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        title="Delete option"
                                                                        aria-label="Delete"
                                                                        onClick={() =>
                                                                            values
                                                                                .options
                                                                                .length >
                                                                                1 &&
                                                                            remove(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="bi text-dark bi-trash-fill fs-2 mx-4" />
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        title="Add option"
                                                                        aria-label="Add"
                                                                        onClick={() =>
                                                                            push(
                                                                                ""
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className=" bi primary  bi-plus fs-2 mx-4" />
                                                                    </button>
                                                                </div>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </FieldArray>

                                        {values.options.length === 0 && (
                                            <span className="text-danger">
                                                At least one option is required.
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default NewFieldModal;
