import { useState, useEffect } from 'react'

interface LocationConfig {
  service: string
  configs: Record<string, string>
}

interface LocationShareProps {
  onLocationSelect: (locationLink: string) => void
  onClose: () => void
  data: any
}

const LocationShare: React.FC<LocationShareProps> = ({ onLocationSelect, onClose, data }) => {
  const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null)
  const [currentAddress, setCurrentAddress] = useState<string>('')
  const [mapConfig, setMapConfig] = useState<LocationConfig | null>(null)

  useEffect(() => {
    if (data?.getallMaps) {
      const configs = data.getallMaps.reduce((acc: Record<string, string>, curr: any) => {
        const configName = curr.configName.replace(/"/g, '')
        const value = curr.value.replace(/"/g, '')
        return { ...acc, [configName]: value }
      }, {})

      setMapConfig({
        service: data?.getallMaps[0]?.services,
        configs
      })
    }
  }, [data])

  const generateMapLink = (lat: number, lng: number, service: string): string => {
    if (service === 'Google Maps') {
      return `https://www.google.com/maps?q=${lat},${lng}`
    }
    // else if (service === 'MapMyIndia') {
    //   return `https://mappls.com/place/@${lat},${lng},15z`
    // }
    return ''
  }

  const getAddressFromCoordinates = async (
    lat: number,
    lng: number,
    service: string,
    apiKey: string
  ) => {
    try {
      if (service === 'Google Maps') {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
        )
        const data = await response.json()
        if (data.results && data.results[0]) {
          setCurrentAddress(data.results[0].formatted_address)
        }
      }
      // else if (service === 'MapMyIndia') {
      //   // Mappls reverse geocoding API
      //   const response = await fetch(
      //     `https://apis.mappls.com/advancedmaps/v1/${apiKey}/rev_geocode?lat=${lat}&lng=${lng}`
      //   )
      //   const data = await response.json()
      //   if (data.results && data.results[0]) {
      //     setCurrentAddress(data.results[0].formatted_address)
      //   }
      // }
    } catch (error) {
      console.error('Error getting address:', error)
      setCurrentAddress('Address not available')
    }
  }

  const initializeMap = async () => {
    if (!mapConfig) return

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = parseFloat(position.coords.latitude.toFixed(6))
          const lng = parseFloat(position.coords.longitude.toFixed(6))
          setCurrentLocation({ lat, lng })

          // Get address after setting location
          await getAddressFromCoordinates(lat, lng, mapConfig.service, mapConfig.configs['API Key'])
        },
        (error) => {
          console.error('Error getting location:', error)
        }
      )
    }
  }

  useEffect(() => {
    initializeMap()
  }, [mapConfig])

  const handleShareLocation = () => {
    if (currentLocation && mapConfig) {
      const locationLink = generateMapLink(
        currentLocation.lat,
        currentLocation.lng,
        mapConfig.service
      )
      onLocationSelect(locationLink)
      onClose()
    }
  }

  if (!mapConfig)
    return (
      <div className='card shadow-sm border'>
        <div className='card-header bg-light'>
          <div className='d-flex align-items-center justify-content-between'>
            <h5 className='mb-0'>No Configuration</h5>
            <button className='btn btn-sm btn-icon' onClick={onClose}>
              <i className='bi bi-x fs-2'></i>
            </button>
          </div>
        </div>
        <div className='card-body text-center my-4'>
          <p>No map configuration found. Please contact support.</p>
        </div>
      </div>
    )

  const apiKey = mapConfig.configs['API Key']

  return (
    <div className='card shadow-sm border rounded-3'>
      <div className='card-header bg-light d-flex justify-content-between align-items-center'>
        <h5 className='mb-0 text-primary'>Share Your Location</h5>
        <button className='btn btn-sm btn-icon btn-light' onClick={onClose}>
          <i className='bi bi-x fs-5'></i>
        </button>
      </div>

      <div className='card-body'>
        <div className='mb-4'>
          <h6 className='text-muted'>Map Service</h6>
          <div className='d-flex align-items-center gap-2'>
            <img
              src={
                mapConfig.service === 'Google Maps'
                  ? 'https://botgobucket.s3.ap-south-1.amazonaws.com/botgo/google-map-icon.webp'
                  : ''
              }
              alt={mapConfig.service}
              className='fs-5'
              style={{ width: '24px', height: '24px' }} // Adjust size as needed
            />
            <span className='fw-semibold'>{mapConfig.service}</span>
          </div>
        </div>

        {currentLocation ? (
          <>
            <div className='mb-3'>
              <h6 className='text-muted'>Your Current Location</h6>
              <div className='p-3 bg-light rounded'>
                <div className='d-flex align-items-start gap-2'>
                  <i className='bi bi-geo-alt text-primary fs-4 mt-1'></i>
                  <div>
                    {currentAddress ? (
                      <p className='mb-0 fw-semibold'>{currentAddress}</p>
                    ) : (
                      <div className='d-flex align-items-center gap-2'>
                        <div
                          className='spinner-border spinner-border-sm text-primary'
                          role='status'
                        ></div>
                        <span>Getting address...</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <iframe
                width='100%'
                height='200'
                src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${currentLocation.lat},${currentLocation.lng}`}
                style={{ border: 'none', borderRadius: '8px' }}
                title='Current Location'
              ></iframe>
            </div>
          </>
        ) : (
          <div className='text-center'>
            <div className='spinner-border spinner-border-sm text-primary' role='status'></div>
            <p className='mt-3 mb-0 text-muted'>Fetching your location...</p>
          </div>
        )}
      </div>

      <div className='card-footer bg-light text-end'>
        <button
          className='btn btn-primary px-4'
          onClick={handleShareLocation}
          disabled={!currentLocation}
        >
          <i className='bi bi-send me-2'></i> Share Location
        </button>
      </div>
    </div>
  )
}

export default LocationShare
