import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ModalEmailConfig from "./ModalEmailConfig";
import { useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import {
  useEmailConfigDeleteMutation,
  useSmtpConfigDeleteMutation,
  useSmtpMailConfigsQuery,
  useTeamConfigQuery,
} from "../../../../Services/settingsAPIs/EmailConfiguration";
import { toast } from "react-toastify";
import EmailEditModal from "./EmailEditModal";
import {
  defaultCompactFormModalStyles,
  defaultModalStyles,
} from "../../../common/CustomModalStyles";
import CommonDeleteModal, {
  DeleteMessageBody,
} from "../../common/CommonDeleteModal";
import ModalSMTPEmailConfig from "./ModalSMTPEmailConfig";
import LoadingSpinner from "../../../LoadingSpinner";

const EmailConfiguration = () => {
  const { data: fetchedTeams, refetch: refetchTeams } = useTeamsQuery({});
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [emailConfigTypeToggler, setEmailConfigTypeToggler] = useState(false);
  const [teamsList, setTeamsList] = useState<any>([]);
  const [teamSelected, setTeamSelected] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [editModalData, setEditModalData] = useState(null);
  const [storingId, setStoringId] = useState(null);
  const [emailConfigForDeletion, setEmailConfigForDeletion] =
    useState<number>();
  const [mailConfigurationsOnDisplay, setMailConfigurationsOnDisplay] =
    useState([]);
  const [triggerEmailConfigDeleteMutation] = useEmailConfigDeleteMutation();
  const [triggerSMTPConfigDeleteMutation] = useSmtpConfigDeleteMutation();
  const [mailConfigForEdit, setMailConfigForEdit] = useState<any>({
    mailConfigEditEnabled: false,
    mailConfigData: null,
  });
  const [toggleChanged, setToggleChanged] = useState(false);
  const user_Role = localStorage.getItem("user_role");

  const {
    data: emailIMAPmailConfigData,
    refetch: refetchIMAPmailConfigData,
    isLoading: isLoadingIMAPmailconfig,
  } = useTeamConfigQuery(selectedTeam, {
    skip: emailConfigTypeToggler || !selectedTeam,
  });

  const {
    data: emailSMTPmailConfigData,
    refetch: refetchSMTPmailConfigData,
    isLoading: isLoadingSMTPmailconfig,
  } = useSmtpMailConfigsQuery(selectedTeam, {
    skip: !emailConfigTypeToggler || !selectedTeam,
  });

  const [
    isDeleteEmailConfigurationModalOpen,
    setIsDeleteEmailConfigurationModalOpen,
  ] = useState<boolean>(false);

  const openDeleteEmailConfigurationModal = () => {
    emailConfigTypeToggler
      ? setIsDeleteEmailConfigurationModalOpen(true)
      : setIsDeleteEmailConfigurationModalOpen(true);
  };
  const closeDeleteEmailConfigurationModal = () => {
    emailConfigTypeToggler
      ? refetchSMTPmailConfigData()
      : refetchIMAPmailConfigData();
    setIsDeleteEmailConfigurationModalOpen(false);
  };

  const handleSelectTeamChange = (event: any) => {
    setSelectedTeam(event.target.value);
    setTeamSelected(true);
  };

  const handleEmailConfigTypeTogglerChange = () => {
    setEmailConfigTypeToggler(!emailConfigTypeToggler);
    setMailConfigurationsOnDisplay([]); // Reset mail configurations on display
    setSelectedTeam(null); // Reset selected team
    setTeamSelected(false); // Reset team selected flag
    setTeamsList([]); // Reset teams list
    setMailConfigForEdit({
      mailConfigEditEnabled: false,
      mailConfigData: null,
    }); // Reset mail config for edit
    setIsNewIMAPemailConfigModalOpen(false); // Reset new IMAP email config modal
    setIsNewSMTPemailConfigModalOpen(false); // Reset new SMTP email config modal
    setIsDeleteEmailConfigurationModalOpen(false); // Reset delete email configuration modal
    setToggleChanged(true);
    refetchTeams();
  };

  useEffect(() => {
    if (fetchedTeams) {
      setTeamsList(fetchedTeams);
    }
    if (toggleChanged) {
      setToggleChanged(false);
    }
  }, [fetchedTeams, toggleChanged]);

  useEffect(() => {
    if (
      emailConfigTypeToggler &&
      !isLoadingSMTPmailconfig &&
      emailSMTPmailConfigData?.length > 0
    ) {
      setMailConfigurationsOnDisplay(emailSMTPmailConfigData);
    } else if (
      !emailConfigTypeToggler &&
      !isLoadingIMAPmailconfig &&
      emailIMAPmailConfigData?.length > 0
    ) {
      setMailConfigurationsOnDisplay(emailIMAPmailConfigData);
    } else {
      setMailConfigurationsOnDisplay([]);
    }
  }, [
    emailConfigTypeToggler,
    selectedTeam,
    isLoadingSMTPmailconfig,
    isLoadingIMAPmailconfig,
    emailSMTPmailConfigData,
    emailIMAPmailConfigData,
  ]);

  const [isNewIMAPemailConfigModalOpen, setIsNewIMAPemailConfigModalOpen] =
    useState(false);
  const [isNewSMTPemailConfigModalOpen, setIsNewSMTPemailConfigModalOpen] =
    useState(false);

  const openNewIMAPemailConfigModal = () => {
    if (teamSelected) {
      setIsNewIMAPemailConfigModalOpen(true);
    } else {
      toast.error("Select a team first");
    }
  };
  const openNewSMTPemailConfigModal = () => {
    if (teamSelected) {
      setIsNewSMTPemailConfigModalOpen(true);
    } else {
      toast.error("Select a team first");
    }
  };
  const closeNewIMAPemailConfigModal = () => {
    setMailConfigForEdit({
      ...mailConfigForEdit,
      mailConfigEditEnabled: false,
    });
    setIsNewIMAPemailConfigModalOpen(false);
  };
  const closeNewSMTPemailConfigModal = () => {
    setMailConfigForEdit({
      ...mailConfigForEdit,
      mailConfigEditEnabled: false,
    });
    setIsNewSMTPemailConfigModalOpen(false);
  };

  const editModalHandler = (el: any) => {
    if (emailConfigTypeToggler) {
      setMailConfigForEdit({
        mailConfigEditEnabled: true,
        mailConfigData: {
          id: el.id,
          username: el.username,
          password: el.password,
          host_name: el.host_name,
          port: el.port,
          team: el.team,
          user: el.user,
          email_server: el.email_server,
          client_id: el.client_id,
          client_secret: el.client_secret,
          server_tenant_id: el.server_tenant_id,
        },
      });
      openNewSMTPemailConfigModal();
    } else {
      setMailConfigForEdit({
        mailConfigEditEnabled: true,
        mailConfigData: {
          id: el.id,
          user: el.user,
          round_robin: el.round_robin,
          FIFO: el.FIFO,
          client_id: el.client_id,
          client_secret: el.client_secret,
          server_tenant_id: el.server_tenant_id,
          username: el.username,
          password: el.password,
          hostname: el.host_name,
          port: el.port,
          server: el.server,
          ssl: el.SSL,
          emailServer: el.email_server,
          team: el.team,
          disposition: el.disposition,
          workflow: el.workflow,
        },
      });
      openNewIMAPemailConfigModal();
    }
  };

  const deleteModalHandler = (el: any) => {
    setEmailConfigForDeletion(el.id);
    openDeleteEmailConfigurationModal();
  };
  const closeEditModal = () => {
    setEditModal(false);
  };

  useEffect(() => {
    if (fetchedTeams) {
      setTeamsList(fetchedTeams);
    }
  }, [fetchedTeams]);
  useEffect(() => {
    if (
      emailConfigTypeToggler &&
      !isLoadingSMTPmailconfig &&
      emailSMTPmailConfigData?.length > 0
    ) {
      setMailConfigurationsOnDisplay(emailSMTPmailConfigData);
    } else if (
      !emailConfigTypeToggler &&
      !isLoadingIMAPmailconfig &&
      emailIMAPmailConfigData?.length > 0
    ) {
      setMailConfigurationsOnDisplay(emailIMAPmailConfigData);
    } else {
      setMailConfigurationsOnDisplay([]);
    }
  }, [
    emailConfigTypeToggler,
    selectedTeam,
    isLoadingSMTPmailconfig,
    isLoadingIMAPmailconfig,
  ]);

  return (
    <div className="custom-style" style={{marginLeft:"3%"}}>
      <div className="col">
        <span className="input-group-text mt-2" id="basic-addon1">
          Email configuration
        </span>
      </div>
      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "16px" }}>IMAP</span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={emailConfigTypeToggler}
                onChange={handleEmailConfigTypeTogglerChange}
              />
            </div>
            <span style={{ fontSize: "16px" }}>SMTP</span>
          </div>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <select
              style={{ width: "fitContent" }}
              className="form-select select2-hidden-accessible"
              onChange={handleSelectTeamChange}
              value={selectedTeam || ""}
            >
              <option value="" selected disabled>
                Choose team
              </option>
              {teamsList?.map((el: any) => (
                <option value={el?.id} key={el?.id}>
                  {el?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          {user_Role === "admin" && (
            <button
              className="input-group-text align-items-center text-hover-primary fw-bold  float-end "
              onClick={() =>
                emailConfigTypeToggler
                  ? openNewSMTPemailConfigModal()
                  : openNewIMAPemailConfigModal()
              }
            >
              <i className="bi bi-plus-lg me-2"></i>

              Add
            </button>
          )}
        </div>
      </div>

      <div className="col">
        <span className="input-group-text mt-2" id="basic-addon1">
          Mail id
        </span>
      </div>

      {selectedTeam && (
        <>
          {isLoadingIMAPmailconfig || isLoadingSMTPmailconfig ? (
            <div className="d-flex justify-content-center mt-5">
              <LoadingSpinner />
            </div>
          ) : mailConfigurationsOnDisplay.length > 0 ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#009ef7",
                  color: "white",
                  alignItems: "center",
                }}
              >
                <span style={{ padding: "10px", fontSize: "16px" }}>Name</span>
                <span style={{ padding: "10px 20px", fontSize: "16px" }}>
                  Action
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {mailConfigurationsOnDisplay?.map((el: any) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <p style={{ margin: "0" }}>{el?.username}</p>
                    {user_Role === "admin" && (
                      <p
                        style={{
                          margin: "0",
                          display: "flex",
                          gap: "30px",
                        }}
                      >
                        <i
                          className="bi bi-pencil fs-4 text-dark text-hover-primary cursor-pointer"
                          onClick={() => editModalHandler(el)}
                          aria-label="Edit Item"
                        />

                        <i
                          className="bi bi-trash fs-4 text-dark text-hover-danger cursor-pointer"
                          onClick={() => deleteModalHandler(el)}
                          aria-label="Delete Item"
                        />
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <span>No mail configurations found.</span>
            </div>
          )}
        </>
      )}

      <ReactModal
        isOpen={editModal}
        onRequestClose={closeEditModal}
        style={defaultCompactFormModalStyles}
      >
        <EmailEditModal
          closeModal={closeEditModal}
          editModalData={editModalData}
          editModal={editModal}
          emailConfigId={storingId}
        />
      </ReactModal>

      <ReactModal
        isOpen={isNewIMAPemailConfigModalOpen}
        onRequestClose={closeNewIMAPemailConfigModal}
        style={defaultCompactFormModalStyles}
        contentLabel="New IMAP Email Configuration"
      >
        <ModalEmailConfig
          closeModal={closeNewIMAPemailConfigModal}
          refetch={refetchIMAPmailConfigData}
          mailConfigForEdit={mailConfigForEdit}
        />
      </ReactModal>
      <ReactModal
        isOpen={isNewSMTPemailConfigModalOpen}
        onRequestClose={closeNewSMTPemailConfigModal}
        style={defaultCompactFormModalStyles}
        contentLabel="New SMTP Email Configuration"
      >
        <ModalSMTPEmailConfig
          closeModal={closeNewSMTPemailConfigModal}
          refetch={refetchSMTPmailConfigData}
          teamsList={teamsList}
          mailConfigForEdit={mailConfigForEdit}
        />
      </ReactModal>

      <ReactModal
        isOpen={isDeleteEmailConfigurationModalOpen}
        onRequestClose={closeDeleteEmailConfigurationModal}
        style={defaultModalStyles}
        contentLabel="Delete a selected email configuration"
      >
        <CommonDeleteModal
          closeModal={closeDeleteEmailConfigurationModal}
          deleteAPItrigger={
            emailConfigTypeToggler
              ? triggerSMTPConfigDeleteMutation
              : triggerEmailConfigDeleteMutation
          }
          deletionIdentifier={emailConfigForDeletion}
          messageBody={
            <DeleteMessageBody
              bodyParams={{
                descriptionPromptEnabled: false,
                deletionOf: `${emailConfigTypeToggler ? "SMTP" : "Email"
                  } configuration`,
              }}
            />
          }
        />
      </ReactModal>
    </div>
  );
};

export default EmailConfiguration;
