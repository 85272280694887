import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useTagsQuery } from "../../../Services/settingsAPIs/CommonAPI";
import { useAddTagsMutation } from "../../../Services/customer/Customer";
import { toast } from "react-toastify";
import EditTags from "./EditTags";
import EmptyRows from "../../reusableComponent/EmptyRows";
import DeleteTags from "../../reusableComponent/DeleteTags";
import { capitalizeFirstLetter } from "../../reusableComponent/CapitalLetter";

const AddTagModal = ({ closeModal, refetch }) => {
  const [tagToBeAdded, setTagToBeAdded] = useState("");
  const [trigger] = useAddTagsMutation();

  const addTagToTheList = async (e) => {
    e.preventDefault();
    const obj = {
      name: tagToBeAdded,
    };
    const response = await trigger(obj);
    if (response.data) {
      toast.success("Added successfully");
      refetch();
    }

    closeModal();
  };

  return (
    <>
      <form action="">
        <div className="d-flex align-items-center">
          <label className="form-label me-4" htmlFor="">
            Tag
          </label>
          <input
            onChange={(evt) => setTagToBeAdded(evt.target.value)}
            className="form-control me-4"
            type="text"
            placeholder={`Enter text`}
            value={tagToBeAdded}
          />
          <button
            type="submit"
            className="btn btn-primary"
            onClick={addTagToTheList}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

const TagsPane = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [storedData, setStoredData] = useState(null);
  const [deleteTagsModalOpen, setDeleteTagsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const { data, refetch } = useTagsQuery({});

  useEffect(() => {
    if (data) {
      setTagsData(data);
    }
  }, [data]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const tagEditHandler = (tags) => {
    setStoredData(tags);
    setEditModal(true);
  };
  const closeTagEditHandler = () => setEditModal(false);
  const tagDeleteHandler = (tags) => {
    setStoredData(tags);
    setDeleteTagsModalOpen(true);
  };
  const closeDeleteTagsHandler = () => setDeleteTagsModalOpen(false);

  const customModalStyles1: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
      width: "40%",
    },
  };

  const customModalStyles: ReactModal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
    },
  };

  const filteredTags = tagsData.filter((tag) =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{marginLeft:"2%"}}>
      <div className="row" style={{width:"111%"}}>
        <div className="col-10">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fs-2 bi bi-search"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Update search query
            />
          </div>
        </div>
        <div className="col-2" style={{paddingLeft:"2%"}}>
          <button
            className="input-group-text align-items-center text-hover-primary fw-bold"
            onClick={openModal} style={{width:"36%"}}
          >
            <i className="bi bi-plus-lg me-2"></i>
            <span className="ms-auto" style={{paddingRight:"10px"}}>Tag</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <span className="input-group-text mt-2" id="basic-addon1">
            Customer tags
            <span className="ms-auto mx-8">Action</span>
          </span>
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Add a new Tag"
      >
        <AddTagModal closeModal={closeModal} refetch={refetch} />
      </ReactModal>

      {filteredTags.length > 0 ? (
        filteredTags.map((team, index) => (
          <div className="row" key={team.id}>
            <div className="col">
              <div className="input-group-text mt-2 bg-white">
                <div
                  className="row w-100 align-items-center"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-4 text-start">
                    {capitalizeFirstLetter(team.name)}
                  </div>
                  <div className="col-2 text-end">
                    {/* Edit Button */}
                    <button
                      className="text-hover-primary border-0 bg-white me-2"
                      title="Edit"
                      onClick={() => tagEditHandler(team)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-pencil fs-4 text-dark"></i>
                    </button>

                    {/* Delete Button */}
                    <button
                      className="text-hover-danger border-0 bg-white me-2"
                      title="Delete"
                      onClick={() => tagDeleteHandler(team)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-trash fs-4 text-dark"></i>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <EmptyRows value={""} />
      )}

      <ReactModal
        isOpen={editModal}
        onRequestClose={closeTagEditHandler}
        style={customModalStyles1}
      >
        <EditTags
          closeModal={closeTagEditHandler}
          refetch={refetch}
          storedData={storedData}
        />
      </ReactModal>

      <ReactModal
        isOpen={deleteTagsModalOpen}
        onRequestClose={closeDeleteTagsHandler}
        style={customModalStyles}
      >
        <DeleteTags
          closeModal={closeDeleteTagsHandler}
          setFieldsData={setTagsData}
          fieldsData={tagsData}
          storingFieldData={storedData}
          refetch={refetch}
        />
      </ReactModal>
    </div>
  );
};

export default TagsPane;
