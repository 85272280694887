import { useState } from "react";
import ChatSetting from "../../components/settings/chat/index";

const ChatSettings = () => {
    const paneTitles = [
        "Chat",
    ];
    const [openedPane, setOpenedPane] = useState("Chat");
    return (
        <div>
            <div className="text-center">
                <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex flex-nowrap mb-5">
                    {paneTitles.map((title, index) => (
                        <>
                            <li className="nav-item pb-0" key={index}>
                                <span
                                    className={`nav-link cursor-pointer fs-4 fw-bold ${openedPane === title ? "active fw-bolder" : ""
                                        }`}
                                    onClick={() => {
                                        setOpenedPane(title);
                                    }}
                                    role="tab"
                                >
                                    {" "}
                                    {title}
                                </span>
                            </li>
                        </>
                    ))}
                </ul>

                <div className="" style={{ paddingTop: "0px" }}>
                    {(() => {
                        switch (openedPane) {
                            case "Chat":
                                return <ChatSetting />;
                            default:
                                return <h4>select a tab to proceed</h4>;
                        }
                    })()}
                </div>
            </div>
        </div>
    );
};

export default ChatSettings;