import React, { PropsWithChildren, ReactNode, createContext, useEffect, useRef } from 'react'
import { useStateWithCallback } from '../utils/hooks/useStateWithCallback'
import { useQuery } from '@apollo/client'
import { GET_USER_BY_ID } from '../graphql/query'

const UserContext = createContext<any>({})

interface IProps {
  children?: ReactNode
}

export const UserProvider: React.FC<PropsWithChildren<IProps>> = ({ children }) => {
  let user: any = {}
  const [currentUser, setCurrentUser] = useStateWithCallback<any>({})
  const [currentUserRole, setCurrentUserRole] = useStateWithCallback<{ [key: string]: boolean }>({
    admin: false,
    agent: false,
    operator: false,
    superAdmin: false,
    salesManager: false,
    salesAssociate: false,
    franchiseAdmin: false,
    otherRole: false
  })
  const [isLoading, setIsLoading] = useStateWithCallback<boolean>(false)
  const { loading, error, data } = useQuery(GET_USER_BY_ID)
  const isUserFetchedRef = useRef<boolean>(false)

  useEffect(() => {
    if (isUserFetchedRef.current === true) {
      isUserFetchedRef.current = false
      setCurrentUser(
        (prevUser: any) => ({ ...user }),
        (updatedUser: any) => {
          // Logic to properly define currentUserRole based on roleId
          if (updatedUser?.UserRole[0].roleId === 1) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                admin: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else if (updatedUser?.UserRole[0].roleId === 2) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                superAdmin: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else if (updatedUser?.UserRole[0].roleId === 5) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                agent: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else if (updatedUser?.UserRole[0].roleId === 3) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                operator: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else if (updatedUser?.UserRole[0].roleId === 4) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                salesManager: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else if (updatedUser?.UserRole[0].roleId === 8) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                salesAssociate: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else if (updatedUser?.UserRole[0].roleId === 9) {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                franchiseAdmin: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          } else {
            setCurrentUserRole(
              (prev: any) => ({
                ...prev,
                salesAssociate: true
              }),
              (updatedUserRole: any) => {
                setIsLoading(false)
              }
            )
          }
        }
      )
    }
  }, [data])

  if (data) {
    console.log(data)
  }

  if (!loading && !error && data) {
    isUserFetchedRef.current = true
    user = data.getUserById
  }

  return (
    <UserContext.Provider
      value={{
        data,
        currentUser,
        currentUserRole,
        setCurrentUser,
        setCurrentUserRole,
        loadingUser: loading || isLoading,
        errorLoadingUser: error
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
