import React from 'react'

interface IPROPS {
    close:any
    socketData:any
}

const NotificationModal: React.FC<IPROPS> = ({close, socketData}) => {


  return (
    <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
        <div style={{display: "flex", width: "100%"}}><span style={{width: "60%", }}>Reminder</span><span onClick={close}>X</span></div>
        <div><span>Lead</span><span>{socketData?.lead}</span></div>
        <div><span>Title</span><span>{socketData?.title}</span></div>
        <div><span>Description</span>
        <span>{socketData?.description.replace(/<[^>]*>/g, "")}</span>
        </div>
        <div><span>Due date</span><span>{socketData?.event_date}</span></div>
        <div><span>Time due</span><span>{socketData?.start_time}</span></div>
        <div></div>
    </div>
  )
}

export default NotificationModal