import React, { useContext, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../helpers/components/KTSVG";
import { useChatFilters } from "../../../utils/hooks/useChatFilters";
import { useSearchParams } from "react-router-dom";
import { Dropdown } from "../../../helpers/components/Dropdown";
import ChatContext from "../../../context/ChatContext";
import { createInitials } from "../../../utils/functions/createInitials";
import { IChatbotState } from "../../../utils/interfaces/IChatbotState";
import { useQuery } from "@apollo/client";
// import { GET_CHAT_ROOMS, GET_ALL_APPLICATION_IDS } from '../../../graphql/query'
import { MultiSelect } from "react-multi-select-component";
import SkeletonChatHistory from "./SkeletonChatHistory";

import { v4 as uuidv4 } from "uuid";

interface IProps {
  type: "Unassigned" | "Assigned" | "Resolved";
}

const ResolvedLeftPanel: React.FC<IProps> = ({ type }) => {
  const {
    state,
    setState,
    isDashUserOnline,
    getUnassignedChatrooms,
    getAssignedChatrooms,
    setTotalUnassignedChats,
    setTotalUnreadAssignedChats,
  } = useContext(ChatContext);
  const { operations, models } = useChatFilters();
  const [, setSearchParams] = useSearchParams();
  const filterDropdownMenuRef = useRef<HTMLDivElement>(null);
  const [filterMenuDroppedDown, setFilterMenuDroppedDown] =
    useState<boolean>(false);
  const [updateChatRoomsFlag, setUpdateChatRoomsFlag] =
    useState<boolean>(false);
  const [applicationIds] = useState<number[]>([1, 2]);
  const [isLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const chatRoomsArrayRef = useRef<any[]>([]);
  const didComponentMountRef = useRef<boolean>(false);
  const chatRoomsPerPage = 10;
  const initialAllApplicationIdsSelected: any = [];
  const [applicationIdSelected, setApplicationIdSelected] = useState<any>(
    initialAllApplicationIdsSelected
  );

  const [options] = useState<any>([]);
  const [isFilterSet, setIsFilterSet] = useState<boolean>(false);

  // Initial update of the total no. of unassigned and assigned chatrooms
  useEffect(() => {
    if (isDashUserOnline === true && type === "Resolved") {
      getUnassignedChatrooms().then((res: any) => {
        if (
          !!res &&
          !!res.unassigned_chatrooms &&
          res.unassigned_chatrooms.length > 0
        ) {
          const unassignedChatrooms = res.unassigned_chatrooms;
          const unassignedChatRoomsArray = unassignedChatrooms.filter(
            (chatRoom: any) => !!chatRoom
          );
          setTotalUnassignedChats(unassignedChatRoomsArray.length);
          console.log("Updated initial number of unassigned chats");
        }
      });
      getAssignedChatrooms().then((res: any) => {
        if (
          !!res &&
          !!res.assigned_chatrooms &&
          res.assigned_chatrooms.length > 0
        ) {
          const assignedChatrooms = res.assigned_chatrooms;
          let assignedChatRoomsArray = [];
          if (assignedChatrooms[0] !== null) {
            assignedChatRoomsArray = assignedChatrooms[0].filter(
              (chatRoom: any) => !!chatRoom
            );
          }
          setTotalUnreadAssignedChats(assignedChatRoomsArray.length);
          console.log("Updated initial number of assigned chats");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashUserOnline]);

  useEffect(() => {
    if (updateChatRoomsFlag === true) {
      const chatRoomsArray = chatRoomsArrayRef.current;
      // console.log(chatRoomsArray)
      console.log("useEffect called to update Chat Rooms array !");
      setState(
        (prevState: IChatbotState) => ({
          ...prevState,
          chatRooms: chatRoomsArray,
        }),
        (updatedState: IChatbotState) => {
          if (didComponentMountRef.current === false) {
            didComponentMountRef.current = true;
          }
        }
      );
      return () => {
        setUpdateChatRoomsFlag(false);
      };
    }
  });

  // update chatFilters on changing applicationIds array
  useEffect(() => {
    operations.updateChatFilters("applicationIds", applicationIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationIds]);

  useEffect(() => {
    if (isFilterSet) {
      // refetchChatRooms({
      //   input: {
      //     ...models.chatFilters,
      //     query: null,
      //     limit: chatRoomsPerPage,
      //     offset: (currentPage - 1) * chatRoomsPerPage
      //   }
      // })
    } else {
      // refetchChatRooms({
      //   input: {
      //     limit: chatRoomsPerPage,
      //     offset: (currentPage - 1) * chatRoomsPerPage
      //   }
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const {
  //   loading: loading1,
  //   error: error1,
  //   data: data1,
  //   refetch: refetchChatRooms
  //   // networkStatus,
  // } = useQuery(GET_CHAT_ROOMS, {
  //   variables: {
  //     input: {}
  //   },
  //   context: {
  //     headers: {
  //       'access-control-allow-methods': 'Chat-history'
  //     }
  //   }
  //   // notifyOnNetworkStatusChange: true
  // })

  // const {
  //   loading: loading2,
  //   error: error2,
  //   data: data2
  // } = useQuery(GET_ALL_APPLICATION_IDS, {
  //   variables: {
  //     input: {
  //       appIDString: null
  //     }
  //   },
  //   context: {
  //     headers: {
  //       'access-control-allow-methods': 'Chat-history'
  //     }
  //   }
  // })

  // useEffect(() => {
  //   if (!!data1) {
  //     if (type === 'Resolved') {
  //       const chatRooms = data1?.chatRooms?.chatRooms
  //       const newChatRoomsArray = chatRooms
  //         ?.map((chatRoom: any) => {
  //           let initials
  //           // !!chatRoom.chatRoomMeta &&
  //           // chatRoom.chatRoomMeta.length > 0 &&
  //           // chatRoom.chatRoomMeta.find((metaData: any) => metaData.name === 'Name')
  //           //   ? createInitials(
  //           //       chatRoom.chatRoomMeta.find((metaData: any) => metaData.name === 'Name').value
  //           //     )
  //           //   : !!chatRoom.id
  //           //   ? createInitials(chatRoom.id)
  //           //   : createInitials('Unnamed')
  //           // !!chatRoom.crmName && chatRoom.crmName.length > 0
  //           //   ? createInitials(chatRoom.crmValue)
  //           //   : !!chatRoom.crId
  //           //   ? createInitials(chatRoom.crId)
  //           //   : createInitials('Unnamed')
  //           if (!!chatRoom.crmName) {
  //             initials = createInitials(chatRoom.crmValue)
  //           } else if (!!chatRoom.crId) {
  //             initials = createInitials(chatRoom.crId)
  //           } else {
  //             initials = createInitials('Unnamed')
  //           }

  //           return {
  //             ...chatRoom,
  //             initials
  //           }
  //         })
  //         .sort((a: any, b: any) => {
  //           // Sort by putting last chatRoomID in first
  //           return parseInt(b.id) - parseInt(a.id)
  //         })
  //       // Upto this line code will be executed on eachtime 'Resloved' tab is selected
  //       // ----------
  //       // Following lines will execute conditionally, if and only if fetched chatRoomsArray is modified or updated
  //       // if (newChatRoomsArray.length > 0) {
  //       chatRoomsArrayRef.current = newChatRoomsArray
  //       setUpdateChatRoomsFlag(true)
  //       console.log('Set to call useEffect to update chatRooms')
  //       // }
  //     }
  //   }
  // }, [data1, type])

  // useEffect(() => {
  //   if (!!data1) {
  //     if (type === 'Resolved') {
  //       const chatRooms = data1?.chatRooms?.chatRooms ? data1?.chatRooms?.chatRooms : []

  //       // Create a new data structure with merged crmData for the same crId
  //       const chatRoomsByCrId = chatRooms?.reduce((acc: any, chatRoom: any) => {
  //         const { crId, crmId, crmName, crmValue, ...otherProps } = chatRoom

  //         // If the chatRoom with crId already exists, add to its crmData array
  //         if (acc[crId]) {
  //           if (crmId) {
  //             acc[crId].crmData.push({ crmId, crmName, crmValue })
  //           }
  //         } else {
  //           // Otherwise, create a new chatRoom entry with crmData
  //           acc[crId] = {
  //             ...otherProps,
  //             crId,
  //             crmData: crmId ? [{ crmId, crmName, crmValue }] : []
  //           }
  //         }

  //         return acc
  //       }, {})

  //       // Convert the object back to an array and sort by crId (or any other logic)
  //       const newChatRoomsArray = Object.values(chatRoomsByCrId)
  //         .map((chatRoom: any) => {
  //           let initials
  //           if (chatRoom.crmName) {
  //             initials = createInitials(chatRoom.crmValue)
  //           } else if (chatRoom.crId) {
  //             initials = createInitials(chatRoom.crId)
  //           } else {
  //             initials = createInitials('')
  //           }

  //           return {
  //             ...chatRoom,
  //             initials
  //           }
  //         })
  //         .sort((a: any, b: any) => {
  //           // Sort by putting last chatRoomID in first
  //           return parseInt(b.crId) - parseInt(a.crId)
  //         })

  //       chatRoomsArrayRef.current = newChatRoomsArray
  //       setUpdateChatRoomsFlag(true)
  //       console.log('Set to call useEffect to update chatRooms')
  //     }
  //   }
  // }, [data1, type])

  // if (!!data2 && data2?.allApplicationId?.length > 0) {
  //   data2?.allApplicationId?.forEach((allApplicationId: any, index: any) => {
  //     initialAllApplicationIdsSelected.push({
  //       label: allApplicationId.appName,
  //       value: allApplicationId.id
  //     })
  //   })
  // }

  // useEffect(() => {
  //   if (data2?.allApplicationId?.length > 0) {
  //     if (applicationIdSelected.length === 0) {
  //       setApplicationIdSelected([
  //         {
  //           label: data2?.allApplicationId[0]?.appName,
  //           value: data2?.allApplicationId[0]?.id
  //         }
  //       ])
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [applicationIdSelected, data2])

  // useEffect(() => {
  //   setIsFilterSet(true)
  //   let selectedapplications: number[] = []
  //   if (!!data2) {
  //     if (type === 'Resolved') {
  //       applicationIdSelected.forEach((theapplicationId: any) =>
  //         selectedapplications.push(Number(theapplicationId.value))
  //       )
  //       if (!!data2 && data2?.allApplicationId?.length > 0) {
  //         data2?.allApplicationId.forEach((allApplicationId: any, index: any) => {
  //           let present = options.some(
  //             (code: { label: string }) => code.label === allApplicationId.appName
  //           )
  //           if (!present) {
  //             options.push({ label: allApplicationId.appName, value: allApplicationId.id })
  //           }
  //         })
  //       }

  //       operations.updateChatFilters(
  //         'applicationIds',
  //         selectedapplications,
  //         (updatedChatfilters) => {
  //           if (!!updatedChatfilters.applicationIds) {
  //             refetchChatRooms({
  //               input: {
  //                 applicationIds: updatedChatfilters.applicationIds,
  //                 limit: chatRoomsPerPage,
  //                 offset: (currentPage - 1) * chatRoomsPerPage
  //               }
  //             })
  //           } else {
  //             setIsFilterSet(false)
  //             refetchChatRooms({
  //               input: {
  //                 applicationIds: [],
  //                 limit: chatRoomsPerPage,
  //                 offset: (currentPage - 1) * chatRoomsPerPage
  //               }
  //             })
  //           }
  //         }
  //       )
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data2, applicationIdSelected])
  // console.log(data1)

  // Pagination
  // const totalChatrooms = data1?.chatRooms?.count
  // const totalPages = Math.ceil(totalChatrooms / chatRoomsPerPage)

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // const nextPage = () => {
  //   if (totalPages > 0 && currentPage < totalPages) {
  //     setCurrentPage((prevPage) => prevPage + 1)
  //   }
  // }

  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage((prevPage) => prevPage - 1)
  //   }
  // }

  // Generate snake handle pagination numbers
  // const paginationNumbers = () => {
  //   const numbers = []
  //   const maxVisibleNumbers = 4 // Maximum visible pagination numbers

  //   if (totalPages <= maxVisibleNumbers) {
  //     // If total pages are less than or equal to max visible numbers, show all
  //     for (let i = 1; i <= totalPages; i++) {
  //       numbers.push(i)
  //     }
  //   } else {
  //     const middleIndex = Math.ceil(maxVisibleNumbers / 2) // Middle index of the pagination numbers
  //     let startPage = currentPage - middleIndex + 1
  //     let endPage = currentPage + middleIndex - 1

  //     if (startPage < 1) {
  //       // If start page is less than 1, adjust start and end page accordingly
  //       endPage = startPage + maxVisibleNumbers - 1
  //       startPage = 1
  //     } else if (endPage > totalPages) {
  //       // If end page is greater than total pages, adjust start and end page accordingly
  //       startPage = endPage - maxVisibleNumbers + 1
  //       endPage = totalPages
  //     }

  //     for (let i = startPage; i <= endPage; i++) {
  //       numbers.push(i)
  //     }
  //   }

  //   return numbers
  // }

  // if (isLoading || loading1 || loading2) return <SkeletonChatHistory />
  // if (error1) return <h1>{error1.message}</h1>
  // if (error2) return <h1>{error2.message}</h1>
  if (!!isLoading) {
    return (
      <>
        <SkeletonChatHistory />
      </>
    );
  }

  const formatEndDate = (endDateStr: string | number | Date) => {
    if (!endDateStr) return null;

    const endDate = new Date(endDateStr);
    const today = new Date();

    // Function to check if the date is today
    const isToday = (someDate: Date) => {
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    };

    if (isToday(endDate)) {
      return endDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      const day = String(endDate.getDate()).padStart(2, "0");
      const month = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = String(endDate.getFullYear()).slice(-2); // Get the last two digits of the year
      return `${day}/${month}/${year}`;
    }
  };

  const handleFilter = (e: React.FormEvent<HTMLFormElement>) => {
    // Handle filter / search graphql API call
    setIsFilterSet(true);
    e.preventDefault();
    // refetchChatRooms({
    //   input: {
    //     ...models.chatFilters,
    //     limit: chatRoomsPerPage,
    //     offset: (currentPage - 1) * chatRoomsPerPage
    //   }
    // })
    if (filterMenuDroppedDown === true) {
      setFilterMenuDroppedDown(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilterSet(true);
    operations.updateChatFilters(
      "query",
      e.target.value,
      (updatedChatfilters: any) => {
        if (!!updatedChatfilters.query) {
          // refetchChatRooms({
          //   input: {
          //     ...models.chatFilters,
          //     query: parseInt(updatedChatfilters.query),
          //     limit: chatRoomsPerPage,
          //     offset: (currentPage - 1) * chatRoomsPerPage
          //   }
          // })
        } else {
          setIsFilterSet(true);
          // refetchChatRooms({
          //   input: {
          //     ...models.chatFilters,
          //     query: null,
          //     limit: chatRoomsPerPage,
          //     offset: (currentPage - 1) * chatRoomsPerPage
          //   }
          // })
        }
      }
    );
  };

  const handleChatRoomClick = (chatRoom: any) => {
    console.log(chatRoom);
    setSearchParams({ type: type, chatRoomId: chatRoom.crId });
    setState((prevState: IChatbotState) => {
      const existingPanel = prevState.panelBasedConversation.find(
        (panel) => panel.chatRoomId === chatRoom.crId
      );

      if (!existingPanel) {
        return {
          ...prevState,
          panelBasedConversation: [
            ...prevState.panelBasedConversation,
            {
              chatRoomId: parseInt(chatRoom.crId),
              conversation: [],
            },
          ],
        };
      }
      return prevState;
    });
  };

  const getDisplayName = (chatRoom: any) => {
    if (!!chatRoom?.crId) {
      return chatRoom?.crId;
    }

    return "Unnamed";
  };

  const getAgentName = (chatRoom: any) => {
    if (chatRoom?.chatName?.agentId !== null) {
      return chatRoom?.chatName?.agentId;
    } else if (chatRoom?.assignedAgentId) {
      return chatRoom?.assignedAgentId;
    } else {
      return;
    }
  };

  const agentClassname = (chatRoom: any) => {
    if (chatRoom?.chatName?.agentId !== null || chatRoom?.assignedAgentId) {
      return "text-info";
    } else {
      return "text-gray-900";
    }
  };

  const isPrevDisabled = currentPage === 1 ? "disabled" : "";
  // const isNextDisabled = currentPage === totalPages ? 'disabled' : ''

  return (
    // <div className='flex-column flex-lg-row-auto w-100 w-350px mx-lg-0'>
    <div
      className="card card-flush"
      style={{ boxShadow: "0 2px 8px 0 #00000029" }}
    >
      <div
        className="card-header pt-7 flex-nowrap flex-column"
        id="kt_chat_contacts_header"
      >
        <div className="d-flex">
          <div className="col">
            <span className="fw-bolder text-dark align-items-center fs-4 fs-md-6">
              Filter Chatroom By Application ID:
            </span>
            <div className="mb-4 mt-2">
              <MultiSelect
                options={options}
                value={applicationIdSelected}
                onChange={setApplicationIdSelected}
                labelledBy="Select"
                className="w-300px"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-evenly">
          <form className="w-100 position-relative" autoComplete="off">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
            />
            <input
              type="text"
              className="form-control form-control-solid px-15"
              name="search"
              placeholder="Search Chat..."
              onChange={handleSearch}
              value={models.chatFilters.query}
            />
          </form>
          <div className="d-flex mx-2" ref={filterDropdownMenuRef}>
            <div
              className="btn btn-sm btn-flex btn-light pb-4"
              onClick={() => setFilterMenuDroppedDown(!filterMenuDroppedDown)}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen031.svg"
                className="svg-icon-5 svg-icon-gray-500 me-1"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card-body pt-5" id="kt_chat_contacts_body">
        <Dropdown
          menuRef={filterDropdownMenuRef}
          droppedDown={filterMenuDroppedDown}
          setDroppedDown={setFilterMenuDroppedDown}
          width={250}
          hideOnBlur={false}
        >
          <form className="card-body px-10" onSubmit={handleFilter}>
            <div className="fw-bold align-items-center fs-4">Select Date</div>{" "}
            <br />
            <div className="mb-5 w-100">
              <label className="form-label" htmlFor="startDate">
                From
              </label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                value={
                  models.chatFilters.startDate
                    ? models.chatFilters.startDate.toString()
                    : ""
                }
                min={new Date("2022-07-15").toISOString().split("T")[0]}
                max={
                  models.chatFilters.endDate
                    ? models.chatFilters.endDate.toString()
                    : ""
                }
                className="form-control"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    operations.updateChatFilters("startDate", e.target.value);
                  } else {
                    operations.updateChatFilters("startDate", undefined);
                  }
                }}
              />
            </div>
            <div className="mb-10 w-100">
              <label className="form-label" htmlFor="endDate">
                To
              </label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                value={
                  models.chatFilters.endDate
                    ? models.chatFilters.endDate.toString()
                    : ""
                }
                min={
                  models.chatFilters.startDate
                    ? models.chatFilters.startDate.toString()
                    : ""
                }
                max={new Date().toISOString().split("T")[0]}
                className="form-control"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    operations.updateChatFilters("endDate", e.target.value);
                  } else {
                    operations.updateChatFilters("endDate", undefined);
                  }
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-sm btn-white btn-active-light-primary me-2"
                onClick={() => setFilterMenuDroppedDown(false)}
              >
                Cancel
              </button>

              <button type="submit" className="btn btn-sm btn-primary">
                Apply
              </button>
            </div>
          </form>
        </Dropdown>
        <div className="scroll-y mh-550px">
          {state?.chatRooms?.length > 0 ? (
            state?.chatRooms?.map((chatRoom: any) => (
              <React.Fragment key={`unique-key-${uuidv4()}`}>
                <div className="d-flex flex-stack py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px symbol-circle">
                      {chatRoom.initials && (
                        <span
                          className={`symbol-label bg-light-${chatRoom.initials.state} text-${chatRoom.initials.state} fs-6 fw-bolder`}
                        >
                          {chatRoom.initials.label}
                        </span>
                      )}
                    </div>
                    <div className="ms-5">
                      <span
                        role="button"
                        // onClick={() => setSearchParams({ type: type, chatRoomId: chatRoom.crId })}
                        onClick={() => handleChatRoomClick(chatRoom)}
                        className={`fs-5 fw-bolder text-hover-primary mb-2 ${agentClassname(
                          chatRoom
                        )}`}
                      >
                        CONV-{getDisplayName(chatRoom)}
                      </span>
                      <span
                        className="fw-bold text-muted"
                        style={{ marginLeft: "4.7rem" }}
                      >
                        {!!chatRoom?.chatName &&
                          formatEndDate(chatRoom?.endDate)}
                      </span>
                      <div>
                        {!!chatRoom?.chatName && (
                          <div className="d-flex flex-column">
                            <div className="fw-bold text-muted">
                              BOT-{chatRoom?.botName || "N/A"}
                            </div>
                            {/* <p className='ms-3 fw-bold text-muted'>
                              CUST-{chatRoom?.chatName?.bgAccountId}
                            </p> */}
                            {(chatRoom?.chatName?.agentId !== null ||
                              chatRoom?.assignedAgentId) && (
                              <div className="fw-bold text-muted">
                                AGENT-{getAgentName(chatRoom)}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed d-none" />
              </React.Fragment>
            ))
          ) : (
            <p className="text-center my-10">No Chatrooms to show</p>
          )}
        </div>

        <div className="card-footer p-3">
          <div className="row">
            <div className="d-flex align-items-center justify-content-center my-3">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="kt_table_users_paginate"
              >
                <ul className="pagination">
                  <li
                    className={`paginate_button me-0 page-item ${isPrevDisabled}`}
                  >
                    <button
                      type="button"
                      aria-controls="kt_table_users"
                      data-dt-idx="0"
                      className="btn primary page-link"
                      // onClick={prevPage}
                    >
                      <i className="previous"></i>
                    </button>
                  </li>
                  {/* {paginationNumbers().map((pageNumber: any) => {
                    const isActive = currentPage === pageNumber ? 'active' : ''
                    return (
                      <li key={pageNumber} className={`paginate_button page-item ${isActive}`}>
                        <button
                          type='button'
                          aria-controls='kt_table_users'
                          data-dt-idx={pageNumber}
                          className='btn primary btn-sm page-link z-index-0'
                          onClick={() => paginate(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    )
                  })} */}
                  <li
                    className={`paginate_button page-item 
                    `}
                  >
                    <button
                      type="button"
                      aria-controls="kt_table_users"
                      data-dt-idx="0"
                      className="btn primary page-link"
                      // onClick={nextPage}
                    >
                      <i className="next"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ResolvedLeftPanel;
