import { useState } from "react";
import PriorityBasedSLA from "./PriorityBasedSLA";
import WorkflowBasedSLA from "./WorkflowBasedSLA";
import TagBasedSLA from "./TagBasedSLA";

const SLA = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabTitles = [
    "SL by customer tags",
    "SL by disposition priority",
    "Workflow-based SL",
  ];

  return (
    <div className="text-start" style={{ height: "100%",marginLeft:"4%" }}>
      <div>
        <ul className="nav nav-stretch nav-line-tabs text-primary border-transparent d-flex mb-5">
          {tabTitles.map((title, index) => (
            <li className="nav-item" key={index}>
              <span
                className={`nav-link cursor-pointer fw-bold ${
                  activeTabIndex === index ? "active fw-bolder" : ""
                }`}
                onClick={() => setActiveTabIndex(index)}
                role="tab"
              >
                {title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {activeTabIndex === 0 && <TagBasedSLA />}
      {activeTabIndex === 1 && <PriorityBasedSLA />}
      {activeTabIndex === 2 && <WorkflowBasedSLA />}
    </div>
  );
};

export default SLA;
