import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../../badRequestHandler/BadRequestHandler";

export const getReportTicketsApi = createApi({
  reducerPath: "getReportTickets",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getAllTeams: builder.query({
      query: () => ({
        url: "v1/users/me/",
        method: "GET",
      }),
    }),

    getAllAssigneeBySelectedTeamId: builder.query({
      query: (teams__id) => ({
        url: `v1/users/users/?teams__id=${teams__id}&`,
        method: "GET",
      }),
    }),

    getAllWorkflowSelectedTeamId: builder.query({
      query: (teams__id) => ({
        url: `v1/tickets/ticket-name/?team_id=${teams__id}&`,
        method: "GET",
      }),
    }),

    getTicketLifeCycleReport: builder.query({
      query: ({
        gte,
        lte,
        team,
        action__assignee,
        action__disposition__get_ticket_name__name,
      }) => ({
        url: `v1/reports/ticket-lifecycle-report/`,
        method: "GET",
        params: {
          gte,
          lte,
          team,
          action__assignee,
          action__disposition__get_ticket_name__name,
        },
      }),
    }),

    getIssueTypeWiseTATReport: builder.query({
      query: ({ gte, lte, team_ids, team_id }) => ({
        url: `v1/reports/sla-reports/`,
        method: "GET",
        params: {
          gte,
          lte,
          report_type: "issue_level",
          result_format: "list",
          team_ids,
          team_id,
        },
      }),
    }),

    getSubIssueTypeWiseTATReport: builder.query({
      query: ({ gte, lte, team_ids, team_id }) => ({
        url: `v1/reports/sla-reports/`,
        method: "GET",
        params: {
          gte,
          lte,
          report_type: "sub_issue_level",
          result_format: "list",
          team_ids,
          team_id
        },
      }),
    }),

    getDepartmentWiseTATReport: builder.query({
      query: ({ gte, lte, team_ids, team_id }) => ({
        url: `v1/reports/sla-reports/`,
        method: "GET",
        params: {
          gte,
          lte,
          report_type: "team_level",
          result_format: "list",
          team_ids,
          team_id
        },
      }),
    }),

    getAuditTicktetWiseReport: builder.query({
      query: ({ gte, lte, team_ids, team_id }) => ({
        url: `v1/reports/sla-reports/`,
        method: "GET",
        params: {
          gte,
          lte,
          report_type: "audit",
          result_format: "list",
          team_ids,
          team_id
        },
      }),
    }),

    getLatestStatusReport: builder.query({
      query: ({ gte, lte, team_id, status_type }) => ({
        url: `v1/reports/sla-reports/`,
        method: "GET",
        params: {
          gte,
          lte,
          report_type: "latest_status_of_tickets",
          result_format: "list",
          team_id,
          status_type,
        },
      }),
    }),


    //  Telephoney report

    getAllUserList: builder.query({
      query: ({ }) => ({
        url: `v1/users/users/?`,
        method: "GET",
      }),

    }),


    getLoginAndLogoutReport: builder.query({
      query: ({ gte, lte, user_id }) => ({
        url: `v1/users/telephony-login/`,
        method: "GET",
        params: {
          gte,
          lte,
          user_id,
        },
      }),
    }),

    getAgentPerformanceReport: builder.query({
      query: ({ gte, lte, user_id, call_type }) => ({
        url: `v1/reports/telephony-agent-performance/`,
        method: "GET",
        params: {
          gte,
          lte,
          user_id,
          call_type
        },
      }),
    }),

    getAllQueue: builder.query({
      query: ({ }) => ({
        url: `v1/telephony/queues/`,
        method: "GET",
      }),

    }),



    getCallDetailsReport: builder.query({
      query: ({ gte, lte, user_id, call_type, queue }) => ({
        url: `v1/users/call-history/`,
        method: "GET",
        params: {
          gte,
          lte,
          user_id,
          call_type,
          queue
        },
      }),
    }),

    // getIntervelWiseInboundCallsReport: builder.query({
    //   query: ({ gte,lte, interval, queuename }) => ({
    //     url: `v1/reports/telephony-inbound-call-report/?gte=2024-11-20%2000:00:00&interval=15&queuename=hindi&lte=2024-11-20%2023:59:59`,
    //     method: "GET",
    //   }),
    // }),
    getIntervelWiseInboundCallsReport: builder.query({
      query: ({ gte, lte, interval, queuename }) => {

        // Construct the API request
        return {
          url: `v1/reports/telephony-inbound-call-report/?gte=${gte}%2000:00:00&interval=${interval}&queuename=${queuename}&lte=${lte}%2023:59:59`,
          method: "GET",
        };
      },
    }),
    getAllTeamsList: builder.query({
      query: ({ }) => ({
        url: `v1/users/teams/`,
        method: "GET",
      }),

    }),


    getBreakReport: builder.query({
      query: ({ gte, lte, user_id, team_id }) => ({
        url: `v1/users/call-breaks/`,
        method: "GET",
        params: {
          gte,
          lte,
          user_id,
          team_id
        },
      }),
    }),


    getAbandonedCallReport: builder.query({
      query: ({ gte, lte, queuename }) => {
        const url = queuename
          ? `v1/reports/telephony-abandoned-report/?gte=${gte}%2000:00:00&lte=${lte}%2023:59:59&queuename=${queuename}&`
          : `v1/reports/telephony-abandoned-report/?gte=${gte}%2000:00:00&lte=${lte}%2023:59:59&`;

        return {
          url,
          method: "GET"
        };
      },
    }),

    getAllCampaign: builder.query({
      query: ({ }) => ({
        url: `v1/common/campaigns/?`,
        method: "GET",
      }),

    }),

    getCampaignLeadReport: builder.query({
      query: ({ gte, lte, campaign }) => ({
        url: `v1/common/lead/leadfields/?`,
        method: "GET",
        params: {
          gte,
          lte,
          campaign
        },
      }),
    }),
    

    getLeadFields: builder.query({
      query: ({ gte, lte, campaign ,recepients}) => ({
        url: `v1/common/lead/leadfields?`,
        method: "GET",
        params: {
          gte,
          lte,
          campaign,
          recepients
        },
      }),
    }),


    getCampaignByTeamId: builder.query({
      query: ({ selectTeam }) => ({
        url: `v1/common/campaigns/?team=${selectTeam}&`,
        method: "GET",

      }),
    }),

    getWorkflowByTeamIdAndCampaignId: builder.query({
      query: ({ selectTeam, selectedCampaign }) => {
        const baseUrl = `v1/common/campaign-name/?team=${selectTeam}`;
        const campaignFilter = selectedCampaign !== "All" ? `&campaign=${selectedCampaign}` : '';

        return {
          url: `${baseUrl}${campaignFilter}&`,
          method: "GET",
        };
      },
    }),

    getStatusByTeamIdAndCampaignIdWorkflowId: builder.query({
      query: ({ selectTeam, selectedCampaign, selectedWorkflow }) => ({
        url: `v1/common/campaign-name/?team=${selectTeam}&campaign=${selectedCampaign}&name=${selectedWorkflow}&`,
        method: "GET",

      }),
    }),


    getCampaignDispositionReport: builder.query({
      query: ({ gte, lte, campaign__team, status_type, campaign, assignee, campaign__campaign_name, id__icontains }) => ({
        url: `v1/reports/campaign-disposition-report/`,
        method: "GET",
        params: {
          gte,
          lte,
          campaign__team,
          status_type,
          campaign,
          assignee,
          campaign__campaign_name,
          id__icontains

        },
      }),
    }),


  }),
});

export const {
  useGetAllTeamsQuery,
  useGetAllAssigneeBySelectedTeamIdQuery,
  useGetAllWorkflowSelectedTeamIdQuery,
  useGetTicketLifeCycleReportQuery,
  useGetIssueTypeWiseTATReportQuery,
  useGetSubIssueTypeWiseTATReportQuery,
  useGetDepartmentWiseTATReportQuery,
  useGetAuditTicktetWiseReportQuery,
  useGetLatestStatusReportQuery,
  useGetLoginAndLogoutReportQuery,
  useGetAllUserListQuery,
  useGetAgentPerformanceReportQuery,
  useGetAllQueueQuery,
  useGetCallDetailsReportQuery,
  useGetIntervelWiseInboundCallsReportQuery,
  useGetAllTeamsListQuery,
  useGetBreakReportQuery,
  useGetAbandonedCallReportQuery,
  useGetAllCampaignQuery,
  useGetCampaignLeadReportQuery,
  useGetCampaignByTeamIdQuery,
  useGetWorkflowByTeamIdAndCampaignIdQuery,
  useGetStatusByTeamIdAndCampaignIdWorkflowIdQuery,
  useGetCampaignDispositionReportQuery,
  useGetLeadFieldsQuery
} = getReportTicketsApi;
