import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { Link } from "react-router-dom";
import EmptyRows from "../../components/reusableComponent/EmptyRows";
import LoadingSpinner from "../../components/LoadingSpinner";
import { capitalizeFirstLetter } from "../../components/reusableComponent/CapitalLetter";
import { formatDate } from "../../components/reusableComponent/DateFormat";
import { useGetTaskDetailsQuery, useGetTaskListQuery } from "../../Services/ticket/CreateTask";
import ViewTaskModal from "./ViewTaskModal";

const TicketTask = () => {
        const [row, setRows] = useState([]);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [particularTaskId, setParticularTaskId] = useState('')   
        const [selectedRow, setSelectedRow] = useState({})     
        const {data: ticketTaskData, isLoading: ticketDataLoading, refetch} = useGetTaskListQuery({})
        // const {data: taskDetailData, isLoading: ticketDetailLoading} =  useGetTaskDetailsQuery(particularTaskId, {skip: !particularTaskId})

        useEffect(() => {
            if (ticketTaskData) {
                setRows(ticketTaskData?.results)
            }
        }, [ticketTaskData])

        const regex = /(<([^>]+)>)/gi;

        const customModalStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "75%", // Default to full width
                maxWidth: "750px", // Max width for larger screens
                height: "75%",
                margin: "auto",
                Overflow: "hidden",
                OverflowY: "auto",
            },
        };

        const closeModal = () => {
            setIsModalOpen(false);
        };

        const currentPage = 1
        const totalPages = 1

        const handleRowClick = (data:any) => {
            setParticularTaskId(data?.id)
            setIsModalOpen(true)
            setSelectedRow(data)
        }
    
  return (
    <div id="kt_app_content_container" className="app-container mx-5">
    <div className="card card-flush min-h-700px">
        {/*begin::Card header*/}
        <div className="card-header align-items-center py-1 my-1 gap-2 gap-md-5">
            {/*begin::Card title*/}

            <div className="card-title">
                <h2 className="p-4">Tasks</h2>
            </div>


            {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}

        {/*begin::Card header*/}
        {/* <div className="card-header align-items-center py-1 gap-2 gap-md-5"> */}
            {/*begin::Card toolbar*/}
            {/* <div className="card-toolbar flex-row-fluid justify-content-end gap-5"> */}

                {/* <div>
                    <Link to="/campaign/list" className="form-control form-control align-items-center text-hover-primary fw-bold ">
                        <i className="text-dark bi bi-list me-2"></i>
                        <span className="ms-auto">Leads</span>
                    </Link>
                </div> */}

                {/* <div
                    className="w-100 mw-150px"
                    style={{ marginRight: "20px", marginLeft: "8px" }}
                >
                    <Button
                        variant="outlined"
                        className="text-hover-primary fw-bold"
                        sx={{
                            height: 40,
                            width: 140,
                            border: "1px solid #ccc",
                            gap: "5px",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <i className="bi bi-filter text-dark fs-2" />
                        <span
                            className="ms-auto me-2 text-dark"
                            style={{
                                textTransform: "none", 
                                whiteSpace: "nowrap",
                                fontSize: "14px",
                            }}
                        >
                            Search filter
                        </span>
                    </Button>
                </div> */}

                {/* <ReactModal
                    isOpen={isFilterModalOpen}
                    onRequestClose={closeFilterModal}
                    style={customFilterModalStyles}
                    contentLabel="Ticket Filter Modal"
                >
                    <CampaignFilter
                        closeFilterModal={closeFilterModal}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                        handleSubmit={handleSubmit}
                        setSelectedFilters1={setSelectedFilters}

                    />
                </ReactModal> */}


                {/*end::Add product*/}
            {/* </div> */}
            {/*end::Card toolbar*/}
        {/* </div> */}
        {/*end::Card header*/}
        {/*begin::Hover dropdown*/}
        <div className="card-body pt-5">

      

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textAlign: "center", // Center-align header text
                                }}
                            >
                                Title
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textAlign: "center", // Center-align header text
                                }}
                            >
                                Created on
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textAlign: "center", // Center-align header text
                                }}
                            >
                                Due date
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textAlign: "center", // Center-align header text
                                }}
                            >
                                Time due
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textAlign: "center", // Center-align header text
                                }}
                            >
                                Task description
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textAlign: "center", // Center-align header text
                                }}
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { ticketDataLoading ? (
                            <TableRow>
                                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                                    <LoadingSpinner />
                                </TableCell>
                            </TableRow>
                        ) : row?.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                                    <EmptyRows value={""} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            row?.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ "&:last-child td, &:last-child th ": { border: 0 } }}
                                    onClick={() => handleRowClick(row)}
                                    className="cursor-pointer"
                                >
                                    <TableCell style={{ textAlign: "center" }}>
                                        {capitalizeFirstLetter(row.title)}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        {formatDate(row.created)}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        {formatDate(row.event_date)}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        {capitalizeFirstLetter(row?.start_time)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: "center",
                                            maxWidth: "150px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Tooltip
                                            title={capitalizeFirstLetter(row?.event_description) || ""}
                                            arrow>

                                            <span>{capitalizeFirstLetter(row?.event_description.replace(regex, ""))}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        {capitalizeFirstLetter(row.status)}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>


        </div>

            <div className="row mx-4 pt-2 mb-2 mt-3">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    <span>
                        {/* Page {currentPage} of {totalPages} */}
                    </span>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="kt_table_users_paginate"
                    >
                        <ul className="pagination">
                            <li
                                className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                                    }`}
                            >
                                <button
                                    type="button"
                                    className="btn primary page-link"
                                    // onClick={prevPage}
                                >
                                    <i className="previous"></i>
                                </button>
                            </li>

                            {/* {generatePaginationNumbers().map((pageNumber) => (
                                <li
                                    key={pageNumber}
                                    className={`paginate_button page-item ${currentPage === pageNumber ? "active" : ""
                                        }`}
                                >
                                    <button
                                        type="button"
                                        className="btn primary page-link"
                                        onClick={() => paginate(pageNumber)}
                                    >
                                        {pageNumber}
                                    </button>
                                </li>
                            ))} */}

                            <li
                                className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                                    }`}
                            >
                                <button
                                    type="button"
                                    className="btn primary page-link"
                                    // onClick={nextPage}
                                >
                                    <i className="next"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
    </div>



    <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="View Task"
    >
        <ViewTaskModal
            closeModal={closeModal}
            selectedRow={selectedRow}
            refetch={refetch}

        />
    </ReactModal>

</div>
  )
}

export default TicketTask