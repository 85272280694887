import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useTeamsPageQuery, useTeamsQuery } from "../../../../Services/settingsAPIs/CommonAPI";
import LoadingSpinner from "../../../LoadingSpinner";
import DeleteTeamModal from "./DeleteTeamModal";
import AddTeamModal from "./AddTeamModal";
import EditTeamModal from "./EditTeamModal";
import { defaultScrollableModalStyles } from "../../../common/CustomModalStyles";
import { capitalizeFirstLetter } from "../../../reusableComponent/CapitalLetter";
import { Pagination, PaginationItem } from "@mui/material";
import './styles.scss'
import { CIRCLEONE_TEAM, CIRCLEONE_USER } from "../../../../constants";

const TeamPane = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addTeamModalOpen, setAddTeamModalOpen] = useState(false)
    const [storeValue, setStoreValue] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState<any>(0);
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState<boolean>(false)

    // const {data, isLoading, refetch } = useTeamsQuery({});
    const { data: teamsData, isLoading, refetch, status } = useTeamsPageQuery({ rowsPerPage, page, search });

    const [storingFieldData, setStoringFieldData] = useState(null);
    const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState<boolean>(false);

    useEffect(() => {
        if (status === "pending") {
            setLoading(true)

        }
        else {
            setLoading(false)

        }

    }, [status])

    const openModal = (team: any) => {
        setIsModalOpen(true);
        setStoreValue(team)
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModalAddTeam = (team: any) => {
        setAddTeamModalOpen(true);
        setStoreValue(team)
    };
    const closeModalAddTeam = () => {
        setAddTeamModalOpen(false);
    };
    const closeDeleteModal = () => {
        setIsDeleteButtonClicked(false);
    };

    const [theTeams, setTheTeams] = useState([]);

    const deleteCustomModalStyles: ReactModal.Styles = {
        content: {
            top: "50%",
            left: "50%",
            width: "30%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    useEffect(() => {

        if (teamsData) {

            setTheTeams(teamsData);
            setTotalCount(teamsData?.count || 0);

        }
    }, [teamsData]);

    const deleteTeamHandler = (field: any) => {
        setStoringFieldData(field);
        setIsDeleteButtonClicked(true);
    }

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };



    return (
        <div className="teams_cont">
            <div className="row">
                <div className="col-10" >
                    <div className="input-group mb-3" style={{width:"111%"}}>
                        <span className="input-group-text" id="basic-addon1">
                            <i className="fs-2 bi bi-search"></i>
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-2" style={{paddingLeft:"9%"}}>
                    <button
                className="input-group-text align-items-center text-hover-primary fw-bold"
                onClick={openModalAddTeam} style={{width:"92%"}}
                    >
                        <i className="bi bi-plus-lg me-2"></i>
                        <span className="ms-auto"  style={{marginRight:"9%"}} >Team</span>
                    </button>
                </div>
            </div>

            <>
                <div className="row">
                    <div className="col">
                        <span className="input-group-text mt-2 d-flex justify-content-between">
                            <span>Team</span>
                            <span>Action</span>
                        </span>
                    </div>
                </div>

                {isLoading || loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {teamsData?.results?.map((team: any, index: number) => (
                            <div className="row" key={index}>
                                <div className="col-12">
                                    <span
                                        className="input-group-text bg-white mt-2 d-flex justify-content-between"
                                        id="basic-addon1"
                                    >
                                        <span>{capitalizeFirstLetter(team?.name)}</span>
                                        <span>
                                            <button
                                                className="text-hover-primary border-0 bg-white me-2"
                                                title="Edit"
                                                onClick={() => openModal(team)}
                                            >
                                                <i className="bi bi-pencil text-dark fs-4"></i>
                                            </button>
                                            <button
                                                className="text-hover-danger border-0 bg-white me-2"
                                                title="Delete"
                                                onClick={() => deleteTeamHandler(team)}
                                            >
                                                <i className="bi bi-trash text-dark fs-4"></i>
                                            </button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </>
                )}

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        marginTop: "10px",
                    }}
                >
                    <p>
                        <span >Page</span>
                        <span> {page} of {Math.ceil(totalCount / rowsPerPage)}</span>
                    </p>
                    <Pagination
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            color: "blue",
                        }}
                        count={Math.ceil(totalCount / rowsPerPage)}
                        page={page}
                        color="primary"
                        onChange={handlePageChange}
                        renderItem={(item) => {
                            if (item.type === "page") {
                                if (
                                    (page === 1 && item.page <= 3) ||
                                    (page ===
                                        Math.ceil(totalCount / rowsPerPage) &&
                                        item.page >=
                                        Math.ceil(totalCount / rowsPerPage) - 2) ||
                                    (item.page >= page - 1 && item.page <= page + 1)
                                ) {
                                    return <PaginationItem {...item} />;
                                }
                                return null;
                            }
                            return <PaginationItem {...item} />;
                        }}
                    />
                </div>
            </>


            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={defaultScrollableModalStyles}
            >
                <EditTeamModal storeValue={storeValue} setStoreValue={setStoreValue} refetch={refetch} closeModal={closeModal} />
            </ReactModal>

            <ReactModal
                isOpen={addTeamModalOpen}
                onRequestClose={closeModalAddTeam}
                style={defaultScrollableModalStyles}
            >
                <AddTeamModal closeModal={closeModalAddTeam} refetch={refetch} />
            </ReactModal>


            <ReactModal
                isOpen={isDeleteButtonClicked}
                onRequestClose={closeDeleteModal}
                style={deleteCustomModalStyles}
            >
                <DeleteTeamModal closeModal={closeDeleteModal} storingFieldData={storingFieldData} theTeams={theTeams} setTheTeams={setTheTeams} refetch={refetch} />
            </ReactModal>





        </div>
    );
};

export default TeamPane;