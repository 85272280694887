import ProgressBar from "../ticket/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import TicketLeftSideMenuCard from "../../components/ticket/TicketLeftSideMenuCard";
import TicketRightSideMenu from "../../components/ticket/TicketRightSideMenu";
import TicketPaneOpen from "../../components/ticket/TicketPaneOpen";
import TicketListInfoCard from "../../components/ticket/TicketListInfoCard";
import { useDetailTicketQuery } from "../../Services/ticket/TicketDetail";
import { useEffect, useState,useRef } from "react";
import ReactModal from "react-modal";
import CreateTaskModal from "../../components/ticket/ticketListInfo/CreateTaskModal";
import { useCreateTaskMutation } from "../../Services/ticket/CreateTask";
import { useTaskQuery } from "../../Services/ticket/Task";
import { useAuditTrailQuery, useGetForwardedAuditTrailCountQuery } from "../../Services/ticket/Audit-Trail";
import ClientLoginHeader from "./ClientLoginHeader";
import { toAbsoluteUrl } from "../../helpers";
import { useLayout } from "../../layout/core";
import CreateChildTicket from "../../components/ticket/CreateChildTicket";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useTicketPagination2Query } from "../../Services/ticket/TicketList";
import { toast } from "react-toastify";

const ViewTicket = () => {
    const params = useParams();
    const uuid = params.uuid;
    const [currentTicketId, setCurrentTicketId] = useState(uuid);

    const { data, refetch, isFetching, status } = useDetailTicketQuery(currentTicketId);
    const [createTask] = useCreateTaskMutation({});
    const [isChildTicketModalOpen, setIsChildTicketModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const {
        data: taskData,
        refetch: refetchTask,
        isLoading,
    } = useTaskQuery(uuid);

    const { data: auditTrailData, refetch: refetchAuditTrailData } =
        useAuditTrailQuery(uuid);
    const { data: auditTrailForwardedData, refetch: refetchAuditTrailForwardedData } =
        useGetForwardedAuditTrailCountQuery(uuid);


    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [getAllStatus, setGetAllStatus] = useState<any>([]);
    const { config, classes, attributes } = useLayout();
    const [paneOpen, setPaneOpen] = useState<String>("LeadInformation");
    const navigate = useNavigate(); // Initialize useNavigate
    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "550px",
        },
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const afterOpenModal = () => {
        // subtitle.style.color = '#f00'
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const customModalStyles1 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Default to full width
            maxWidth: "850px", // Max width for larger screens
            height: "80%",
            margin: "auto",
            Overflow: "hidden",
            OverflowY: "auto",
        },
    };



    useEffect(() => {
        if (status === "pending") {
            setLoading(true);

        } else {
            setLoading(false);
        }

    }, [status]);


    const openChildTicketModal = () => {
        setIsChildTicketModalOpen(true);
    }
    const afterOpenChildTicketModal = () => {
        // subtitle.style.color = '#f00'
    }
    const closeChildTicketModal = () => {
        setIsChildTicketModalOpen(false);
    }
    const handleBackButtonClick = () => {
        navigate(-1); // Navigate back to the previous page
    };
    const [currentPage, setCurrentPage] = useState<any>(() => {
        const savedPage = sessionStorage.getItem("ticketCurrentPage");
        return savedPage ? Number(savedPage) : 1;
    });
    // const [selectedWorkFlow, setselectedWorkFlow] = useState('')
    const [selectedWorkFlow, setselectedWorkFlow] = useState(
        sessionStorage.getItem("selectedWorkFlow1") || ""
    );
    const [direction, setDirection] = useState(null);
    const [isTicketLoading, setIsTicketLoading] = useState(false);
    useEffect(() => {
        console.log("Initial selectedWorkFlow:", selectedWorkFlow);
        if (!selectedWorkFlow) {
            setselectedWorkFlow('defaultWorkflowId'); // Ensure a default value is set
        }
      }, [selectedWorkFlow]);
    const [selectedFilterAndValue1, setSelectedFilterAndValue1] = useState<any>(
        () => {
            const sessionData = sessionStorage.getItem(
                "selectedFilterAndValue"
            );
            return sessionData
                ? JSON.parse(sessionData)
                : {
                      action__contact__email__icontains: "",
                      action__assignee__username__icontains: "",
                      title__icontains: "",
                      created: "",
                      id__icontains: "",
                      merchant_id: "",
                  };
        }
    );
    const [selectedTeamId, setSelectedTeamId] = useState(() => {
        const selectedTeamIdFromSession =
            sessionStorage.getItem("selectedTeamId");
        const userRole = localStorage.getItem("user_role");
        const userId = localStorage.getItem("User_Id");

        if (selectedTeamIdFromSession) {
            return selectedTeamIdFromSession;
        }

        if (userRole === "admin") {
            return "My Team";
        }

        if (userRole === "agent" || userRole === "manager") {
            return userId;
        }

        return null; // Fallback value if none of the conditions are met
    });
 const {
    data: fetchedData,
    refetch:refetchTicket,
    // isLoading,
    // status
  } = useTicketPagination2Query(
    {
      currentPage,
      selectedWorkFlow,
      selectedTeamId,
      selectedFilterAndValue1,
    },
    { skip: !selectedWorkFlow}
  );
console.log(data,"data")
useEffect(() => {
    console.log("Fetching data with parameters: ", {
      currentPage,
      selectedWorkFlow,
      selectedTeamId,
      selectedFilterAndValue1,
    });
    // refetchTicket().catch(error => {
    //   console.error("Error fetching tickets:", error);
    // });
  }, [refetchTicket, currentPage, selectedWorkFlow, selectedTeamId, selectedFilterAndValue1]);
  useEffect(() => {
    if (fetchedData) {
      console.log("Fetched Data: ", fetchedData);
    }
  }, [fetchedData]);
  
 const handleButtonClick = async (event, direction) => {
        event.preventDefault();
        setDirection(direction);
        setIsTicketLoading(true);
        const recordsPerPage = 5;
        const tickets = fetchedData?.results;
        console.log(fetchedData,"vclictickets")
        const totalRecords = fetchedData?.count || 0;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        const currentIndex = tickets?.findIndex(
            (ticket) => ticket.id === currentTicketId
        );
        console.log(currentIndex,"currentIndex")

        if (direction === "next") {
            if (currentIndex < tickets?.length - 1) {
                const nextTicketId = tickets[currentIndex + 1].id;
                setCurrentTicketId(nextTicketId);
                console.log(nextTicketId,"nextTicketId")

                navigate(`/client-login/${nextTicketId}`, {
                    replace: true,
                });
                setConfirmationModalOpen(false)
            } else if (currentPage < totalPages) {
                setCurrentPage((prevPage) => {
                    const newPage = prevPage + 1;
                    sessionStorage.setItem("ticketCurrentPage", newPage);
                    return newPage;
                });
                setConfirmationModalOpen(false)

            } else {
                toast.warning("No more tickets available.");
                setConfirmationModalOpen(false)

            }
        } else if (direction === "previous") {
            if (currentIndex > 0) {
                const previousTicketId = tickets[currentIndex - 1]?.id;
                setCurrentTicketId(previousTicketId);
                navigate(`/client-login/${previousTicketId}`, {
                    replace: true,
                });
            } else if (currentPage > 1) {
                setCurrentPage((prevPage) => {
                    const newPage: any = prevPage - 1;
                    sessionStorage.setItem("ticketCurrentPage", newPage);
                    return newPage;
                });
            } else {
                toast.warning("No previous tickets available.");
            }
        }
        setIsTicketLoading(false);
    };

    useEffect(() => {
        const refetchData = async () => {
            setIsTicketLoading(true);
            await refetchTicket();
            if (direction === "next") {
                setCurrentTicketId(fetchedData?.results[0]?.id);
                navigate(`/client-login/${fetchedData?.results[0]?.id}`, {
                    replace: true,
                });
            } else if (direction === "previous") {
                const lastTicketId =
                    fetchedData?.results[fetchedData.results.length - 1]?.id;
                setCurrentTicketId(lastTicketId);
                navigate(`/client-login/${lastTicketId}`, {
                    replace: true,
                });
            }
            setIsTicketLoading(false);
        };

        if (direction) {
            refetchData();
        }
    }, [currentPage, fetchedData]);
    const leadInformationRef = useRef<HTMLDivElement>(null); // Create a reference to the component

    const scrollToTop = () => {
        if (leadInformationRef.current) {
            leadInformationRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
      const refetchData = async () => {
          setIsTicketLoading(true);
          await refetchTicket(); // Assuming this function refetches the data

          if (direction === "next" || direction === "previous") {
              setPaneOpen("LeadInformation"); // Set the active tab
              scrollToTop();
          }

          setIsTicketLoading(false);

          setDirection(null);
      };

      if (direction) {
          setPaneOpen("LeadInformation");
          refetchData();
      }
  }, [direction]); 
  leadInformationRef.current?.scrollIntoView();
    return (
        <>
            <div
                // id="kt_header"
                className="header align-items-stretch justify-content-between d-none d-lg-flex"
                style={{ left: 0 }}
                {...attributes.headerMenu}
            >
                {/* begin::Wrapper */}
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                    {/* begin::Navbar */}
                    <div className="app-header-menu app-header-mobile-drawer align-items-stretch ms-2">
                        <Link to="/client-login">
                            <img
                                alt="Logo"
                                src={toAbsoluteUrl(
                                    "/media/logos/CircleOne-Logo-With-Change.png"
                                )}
                                className="h-60px"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <ClientLoginHeader />

            <div id="kt_app_content_container" className="app-container mx-5 " ref={leadInformationRef}  style={{ marginTop: "75px" }}>
                {/*begin::Products*/}
                <div className="card card-flush h-100">

                    {/*start:: The 2nd stack */}
                    <div className="card-header align-items-center py-1 gap-2 gap-md-5 mt-4">
                        <div className="d-flex">
                            <button className="btn btn-link mb-2" onClick={handleBackButtonClick}>
                            <i className="bi bi-arrow-90deg-left"     style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                        marginLeft:"-8px"
                                    }}></i>
                            </button>
                        </div>
                            <h2 className="p-5" style={{marginInlineEnd:"auto",marginLeft:"-27px"}}>Ticket Information</h2>

                        {/* Create Ticket Button */}
                        <div className="card w-100 mw-125px">
                            <button className="btn btn-link" onClick={openModal} disabled>
                                <i className="bi bi-plus"></i>
                                Create Task
                            </button>
                        </div>
                        <ReactModal
                            isOpen={isModalOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customModalStyles}
                            contentLabel="Create Task"
                        >
                            <CreateTaskModal
                                closeModal={closeModal}
                                ticket={data}
                                createTask={createTask}
                                refetchTask={refetchTask}
                            />
                        </ReactModal>
                    </div>
                    <div
                    className="card-header align-items-center py-1 gap-2 gap-md-5"
                    style={{
                        width: "99%",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <span
                        className="btn btn-link"
                        data-bs-toggle="tooltip"
                        title="Previous Ticket"
                        onClick={(event) =>
                            handleButtonClick(event, "previous")
                        }
                    >
                        <i
                            className="bi bi-arrow-left-square"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>
                    </span>

                    <span
                        className="btn btn-link"
                        data-bs-toggle="tooltip"
                        title="Next Ticket"
                        onClick={(event) => handleButtonClick(event, "next")}
                        style={{ marginRight: "16%" }}
                    >
                        <i
                            className="bi bi-arrow-right-square"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>
                    </span>
                </div>
                    {/*end:: The 2nd stack */}

                    {/*start:: The 3rd stack */}
                    {isLoading ? <LoadingSpinner /> :
                        (
                            <>

                                <div className="container-fluid mt-5">

                                    <div className="row" style={{marginTop:"-1%"}}>
                                        <div className="col-xl-2 col-lg-4 col-md-12 mb-4" style={{paddingLeft:"2rem"}}>
                                            <TicketLeftSideMenuCard tickets={data} />
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-12 mb-4">
                                            <TicketListInfoCard
                                                tickets={data}
                                                refetch={refetch}
                                                refetchAuditTrailData={refetchAuditTrailData}
                                                setGetAllStatus={setGetAllStatus}
                                                refetchAuditTrailForwardedData={refetchAuditTrailForwardedData}
                                                openChildTicketModal={openChildTicketModal}
                                            />
                                            {/* end::Item */}

                                            <div className="mt-5">

                                                <div className="mt-2 ">
                                                    <TicketPaneOpen
                                                        tickets={data}
                                                        refetch={refetch}
                                                        taskData={taskData}
                                                        refetchTask={refetchTask}
                                                        isLoading={isLoading}
                                                        refetchAuditTrailForwardedData={refetchAuditTrailForwardedData}
                                                        paneOpen={paneOpen}
                                                        setPaneOpen={setPaneOpen}
                                                        setConfirmationModalOpen={setConfirmationModalOpen}
                                                        confirmationModalOpen={confirmationModalOpen}
                                                        handleButtonClick={handleButtonClick}
                                                    auditTrailForwardedData={auditTrailForwardedData}


                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-4 col-md-12 mb-4" style={{marginLeft:"-9px"}}>
                                            <TicketRightSideMenu auditTrailForwardedData={auditTrailForwardedData} setPaneOpen={setPaneOpen} />
                                        </div>
                                    </div>
                                </div>

                            </>
                        )

                    }
                    {/*ends:: The 4th stack */}
                </div>


                <ReactModal
                    isOpen={isChildTicketModalOpen}
                    onRequestClose={closeChildTicketModal}
                    style={customModalStyles1}
                    contentLabel="New Ticket"
                >
                    <CreateChildTicket
                        closeModal={closeChildTicketModal}
                        refetch={refetch}
                        uuid={uuid}

                    />
                </ReactModal>
            </div>
        </>
    );
};

export default ViewTicket
