import React, { useEffect, useState, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import { isFieldDisabled } from "../../reusableComponent/FieldDisabled";
import { useUpdateCamapignDynamicFieldsMutation } from "../../../Services/campign/CampaignDetail";
import { useCampignsDynamicFieldsQuery } from "../../../Services/campign/campignList";
import { useTelephony } from "../../context/TelephonyContext";
import { TELEPHONY_LOGIN } from "../../../constants";
import {
  maskEmail,
  maskPhoneNumber,
} from "../../reusableComponent/MaskedFields";
import ConfirmationModal from "./ConfirmationModal";
import ReactModal from "react-modal";
import { useUserDynamicFieldsQuery } from "../../../Services/settingsAPIs/CommonAPI";

interface IProps {
  ticket: any;
  refetch: any;
  uuid: any;
  handleButtonClick: any;
  setConfirmationModalOpen: any;
  confirmationModalOpen: any;
  setLiftingObj: any;
  buttonClicked: any;
  scrollToTop: any;
  setIsStatusWonSelected: any;
  isStatusWonSelected: any;
  // refetch1: any;
  refetchLeadTrail: any;
}

const LeadInformationTab: React.FC<IProps> = ({
  ticket,
  refetch,
  uuid,
  handleButtonClick,
  setConfirmationModalOpen,
  confirmationModalOpen,
  setLiftingObj,
  buttonClicked,
  scrollToTop,
  setIsStatusWonSelected,
  isStatusWonSelected,
  // refetch1,
  refetchLeadTrail,
}) => {
  const { data: ticketDynamicFields } = useCampignsDynamicFieldsQuery({});
  const [dynamicFields, setDynamicFields] = useState<any>({});
  const [dynamicFieldData, setDynamicFieldData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [accountName, setAccountName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailMasked, setIsEmailMasked] = useState(true);
  const [isPhoneMasked, setIsPhoneMasked] = useState(true);
  const [matchedDynamicFields, setMatchedDynamicFields] = useState<
    Record<string, any>
  >({});

  const modelName = "contact";
  const { data: contactDynamicData } = useUserDynamicFieldsQuery(modelName);

  console.log(
    ticketDynamicFields?.map((el) => el.label),
    "ticketDynaicicic"
  );
  console.log(contactDynamicData, "contactDynamicData");

  useEffect(() => {
    if (!ticket?.dynamic_fields || !contactDynamicData) return;

    const updatedFields: Record<string, any> = {};

    contactDynamicData.forEach((el) => {
      const key = el?.label;
      if (ticket?.dynamic_fields?.hasOwnProperty(key)) {
        updatedFields[key] = ticket?.dynamic_fields[key]; // Store matched value
      }
    });

    setMatchedDynamicFields(updatedFields);
  }, [ticket?.dynamic_fields, contactDynamicData]);

  console.log(matchedDynamicFields, "matchedDynamicFields");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [updateDynamicFields] = useUpdateCamapignDynamicFieldsMutation();
  const [errors, setErrors] = useState<any>({});
  const { setLifitinMobileNumber } = useTelephony();

  const checkUserRole = localStorage.getItem("user_role");
  const checkTenantName = localStorage.getItem("circleone-user");

  const handleInputChange = (key: string, value: any) => {
    // Check if value is in "date-time" format (e.g., ISO 8601 string or valid date)
    const field = dynamicFieldData.find((field) => field.label === key);
    if (
      !isNaN(new Date(value).getTime()) &&
      field?.field_type === "date-time"
    ) {
      // If the value is a valid date, process the date value
      const localDate = new Date(value);
      // If you want to store the date in UTC, use toISOString()
      const utcDate = new Date(localDate.toISOString());
      setDynamicFields((prevFields) => ({
        ...prevFields,
        [key]: utcDate.toISOString(), // Store as ISO string in UTC
      }));
    } else {
      // If the value is not a valid date-time, simply update the state with the value
      setDynamicFields((prevFields) => ({
        ...prevFields,
        [key]: value,
      }));
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ value: any }>,
    key: string
  ) => {
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [key]: event.target.value,
    }));
  };

  useEffect(() => {
    if (ticket) {
      setAccountName(ticket.account_name || "");
      setFirstName(ticket.first_name || "");
      setLastName(ticket.last_name || "");
      setEmail(ticket.email || "");
      setPhoneNumber(ticket.phone_number || "");
      setDynamicFields(ticket.dynamic_fields);
    }
  }, [ticket]);

  console.log(dynamicFields, "dyniamcmmcmc");
  // const handleSave = async () => {
  //   if (isLoading) return; // Prevent saving if already loading
  //   setIsLoading(true);
  //   try {
  //     await updateDynamicFields({
  //       variables: {
  //         dynamic_fields: dynamicFields,
  //       },
  //       uuid,
  //     });
  //     toast.success("Dynamic fields updated successfully!");
  //     refetch();
  //     refetch1();
  //   } catch (error) {
  //     toast.error("Error updating dynamic fields:", error);
  //   } finally {
  //     setIsLoading(false); // Reset loading state
  //   }
  // };
  const validatePhoneNumber = (number) => {
    if (number.length !== 10 || isNaN(number)) {
      return "Mobile number must be 10 digits";
    }
    return "";
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Invalid email address";
    }
    return "";
  };
  const validateForm = () => {
    const newErrors = {};

    // Validate dynamic fields
    dynamicFieldData.forEach((field) => {
      const fieldValue = dynamicFields[field.label];

      if (field.is_required && !fieldValue) {
        newErrors[field.label] = `${field.label} is required`;
      }
    });

    // Validate phone number
    // if (!phoneNumber) {
    //     newErrors["phone_number"] = "Mobile Number is required";
    // } else {
    //     const phoneError = validatePhoneNumber(phoneNumber);
    //     if (phoneError) {
    //         newErrors["phone_number"] = phoneError;
    //     }
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // useEffect(() => {
  //     editedFields()

  // }, [isStatusWonSelected])

  const editedFields = useMemo(() => {
    if (!ticket?.dynamic_fields || !dynamicFields) return {};

    return Object.keys(dynamicFields).reduce((acc, key) => {
      const originalValue = ticket.dynamic_fields[key] || ""; // Default to empty string if missing
      const currentValue = dynamicFields[key] || ""; // Default to empty string if missing

      // Always include new keys from dynamicFields
      if (originalValue !== currentValue || !(key in ticket.dynamic_fields)) {
        acc[key] = currentValue;
      }

      return acc;
    }, {});
  }, [ticket?.dynamic_fields, dynamicFields]);

  // useEffect(() => {
  //     if (Object.keys(editedFields).length > 0) {
  //         localStorage.setItem("dynamicData", JSON.stringify(editedFields));
  //     }
  // }, [editedFields]);  // Store in localStorage only when editedFields changes

  const getTeamId = localStorage.getItem("teamId");

  useEffect(() => {
    // Retrieve stored dynamic fields and parse them
    // const storedDynamicFields = localStorage.getItem("dynamicData");
    // const parsedDynamicFields = storedDynamicFields ? JSON.parse(storedDynamicFields) : {};

    // const getTeamId = localStorage.getItem("teamId");

    const updatedLeadInfoObj = {
      account: "",
      name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      dynamic_fields: matchedDynamicFields, // Use parsed data
      tags: [],
      accessible_teams: [Number(getTeamId)],
    };

    setLiftingObj(updatedLeadInfoObj);
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    matchedDynamicFields,
    getTeamId,
    setLiftingObj,
  ]);
  // Include editedFields in dependency array

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }
    const phoneNumberError = validatePhoneNumber(phoneNumber);
    if (phoneNumberError) {
      setErrors({ phone_number: phoneNumberError });
      toast.error(phoneNumberError);
      return;
    }
    setIsLoading(true);

    const editedFields = Object.keys(dynamicFields).reduce((acc, key) => {
      const originalValue = ticket.dynamic_fields[key];
      const currentValue = dynamicFields[key];
      const field = dynamicFieldData.find((field) => field.label === key);
      // Check if the field has been changed
      if (originalValue !== currentValue) {
        if (field?.field_type === "date") {
          const formattedDate = new Date(currentValue)
            .toISOString()
            .replace("Z", "+00:00");
          acc[key] = formattedDate;
        } else {
          console.log(currentValue);
          acc[key] = currentValue;
        }
      }

      return acc;
    }, {});
    const updatedTicket = {
      account_name: accountName,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      dynamic_fields: editedFields || {},
      assigned_by: localStorage.getItem('circleone-username'),
      assignee: localStorage.getItem('User_Id'),
      status_type: ticket?.status_type
    };

    try {
      const response = await updateDynamicFields({
        variables: updatedTicket,
        uuid,
      });

      if (response.data) {
        // Successful API call
        setIsLoading(false); // Reset loading state
        toast.success("Information updated successfully!");
        refetch();
        refetchLeadTrail();
        // Mask the email only after success
        setIsEmailMasked(true);
        setIsPhoneMasked(true);
        setConfirmationModalOpen(true);
        scrollToTop();
      } else if (response.error) {
        // Handle error response
        const { status, data }: any = response.error;
        if (data?.email) {
          toast.error(data.email[0]); // Display email error message
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: data.email[0],
          }));
        } else if (data?.detail) {
          data.detail.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });
        } else {
          toast.error(
            `Error ${status}: Something went wrong. Please try again.`
          );
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      toast.error("Failed to assign. Please try again."); // Show error toast
      console.error(error); // Log the error
    }
  };

  // I want when any

  useEffect(() => {
    if (ticket?.campaign?.id) {
      const filterDataById = ticketDynamicFields?.filter(
        (name) => name?.campaign_name === ticket?.campaign?.id
      );
      setDynamicFieldData(filterDataById);
    }
  }, [ticketDynamicFields, ticket?.campaign?.id]);

  const showInitialCheckbox = (dynamicFieldsIds, checkboxFiledsOption) => {
    let data = ["ss"];
    return data;
  };

  const gettingTelephonyStatus = localStorage.getItem(TELEPHONY_LOGIN);

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "450px",
        },
    };
    const getFormattedDate = (date: string) => {
        if (!date) return "";
    
        const dt = new Date(date); // Parses the UTC date
        const year = dt.getFullYear();
        const month = String(dt.getMonth() + 1).padStart(2, "0");
        const day = String(dt.getDate()).padStart(2, "0");
        const hours = String(dt.getHours()).padStart(2, "0");
        const minutes = String(dt.getMinutes()).padStart(2, "0");
    
        // Format for datetime-local in UTC, ignoring timezone conversions
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };
    
const name = "lead"



  return (
    <div className="card card-body">
      <form>
        {/* <div className="row g-5 g-xl-8 mb-4"> */}
        <div className="row row-cols-3">
          <div className="col-xl-6 mb-3">
            <InputLabel>Account Name</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
          <div className="col-xl-6 mb-3">
            <InputLabel>First Name</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-xl-6 mb-3">
            <InputLabel>Last Name</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="col-xl-6 mb-3">
            <InputLabel>Email</InputLabel>
            <TextField
              type="email"
              variant="outlined"
              fullWidth
              // disabled={checkUserRole === "agent"}
              value={checkUserRole === "agent" ? maskEmail(email) : email}
              onChange={(e) => {
                if (checkUserRole !== "agent") {
                  setEmail(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="row row-cols-3">
          <div className="col-xl-6 mb-3">
            <InputLabel className="required">Mobile No</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={
                checkUserRole === "agent"
                  ? maskPhoneNumber(phoneNumber)
                  : phoneNumber
              }
              // disabled={checkUserRole === "agent"}
              onChange={(e) => {
                if (checkUserRole !== "agent") {
                  setPhoneNumber(e.target.value);
                }
              }}
              onClick={() =>
                setLifitinMobileNumber(
                  checkUserRole === "agent"
                    ? maskPhoneNumber(phoneNumber)
                    : phoneNumber
                )
              }
              data-toggle="tooltip"
              data-placement="top"
              title={
                gettingTelephonyStatus === "true"
                  ? "Click to call"
                  : "Login telephony to call"
              }
            />

            {errors.phone_number && (
              <div className="mt-2" style={{ color: "red" }}>
                {errors.phone_number}
              </div>
            )}
          </div>

          {dynamicFieldData?.map((field: any) => (
            <div key={field.label} className="col-xl-6 mb-3">
              <InputLabel htmlFor={`dynamic_fields.${field.label}`}>
                {field.label}
                {field.is_required && <span className="required"></span>}
              </InputLabel>
              {field.field_type === "text" && (
                <TextField
                  id={`dynamic_fields.${field.label}`}
                  variant="outlined"
                  disabled={isFieldDisabled(field?.is_editable)}
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "email" && (
                <TextField
                  type="email"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "number" && (
                <TextField
                  type="number"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "date" && (
                <TextField
                  type="datetime-local"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: "uppercase" }, // Optional: uppercase input text
                    },
                  }}
                  required={field.is_required}
                />
              )}
              {field.field_type === "time" && (
                <TextField
                  type="time"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required={field.is_required}
                />
              )}
              {field.field_type === "text-area" && (
                <TextField
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={dynamicFields[field.label] || ""}
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  required={field.is_required}
                />
              )}
              {field.field_type === "drop-down" && (
                <FormControl fullWidth>
                  <Select
                    labelId={`select-label-${field.label}`}
                    disabled={isFieldDisabled(field?.is_editable)}
                    id={`select-${field.label}`}
                    value={dynamicFields[field.label] || ""}
                    onChange={(e) =>
                      handleInputChange(field.label, e.target.value)
                    }
                    required={field.is_required}
                  >
                    {field?.choices?.map((option: any) => (
                      <MenuItem key={option.id} value={option.choice}>
                        {option.choice}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {field.field_type === "check-box" && (
                <FormControl fullWidth>
                  <Select
                    labelId={`checkbox-label-${field.label}`}
                    disabled={isFieldDisabled(field?.is_editable)}
                    id={`checkbox-${field.label}`}
                    multiple
                    value={dynamicFields[field.label] || []}
                    onChange={(e: any) => handleCheckboxChange(e, field.label)}
                    renderValue={(selected) => {
                      // Create an array of selected choice labels
                      const selectedLabels = (field?.multiple_choices || [])
                        .filter((choice: any) =>
                          selected.includes(String(choice.id))
                        )
                        .map((choice) => choice.choice);
                      return selectedLabels.join(", ");
                    }}
                  >
                    {field?.multiple_choices?.map((choice: any) => (
                      <MenuItem key={choice.id} value={String(choice.id)}>
                        <Checkbox
                          checked={dynamicFields[field.label]?.includes(
                            String(choice.id)
                          )}
                        />
                        <ListItemText primary={choice.choice} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {field.field_type === "tel-phone" && (
                <div style={{ display: "flex", width: "100%" }}>
                  <PhoneInput
                    style={{ width: "100%" }}
                    disabled={isFieldDisabled(field?.is_editable)}
                    defaultCountry="IN"
                    value={dynamicFields[field.label] || "+91"}
                    onChange={(value) => handleInputChange(field.label, value)}
                  />
                </div>
              )}
              {field.field_type === "date-time" && (
                <TextField
                  type="datetime-local"
                  id={`dynamic_fields.${field.label}`}
                  disabled={isFieldDisabled(field?.is_editable)}
                  variant="outlined"
                  fullWidth
                  value={
                    getFormattedDate(dynamicFields[field.label]) ||
                    dynamicFields[field.label] ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(field.label, e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: "uppercase" }, // Optional: uppercase input text
                    },
                  }}
                  required={field.is_required}
                />
              )}
            </div>
          ))}
        </div>
        <div className="row mt-5">
          <div className="d-flex">
            <Button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={handleSubmit}
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Saving..." : "Save"} {/* Loading indicator */}
            </Button>
          </div>
        </div>
      </form>

      <ReactModal
        isOpen={confirmationModalOpen}
        onRequestClose={closeConfirmationModal}
        style={customModalStyles}
        contentLabel="Change Status"
      >
        <ConfirmationModal
          handleButtonClick={handleButtonClick}
          closeModal={closeConfirmationModal}
          name={name}
        />
      </ReactModal>
    </div>
  );
};

export default LeadInformationTab;
