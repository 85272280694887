import React, { useState } from "react";
import { useUpdateUsernameMutation } from "../../Services/ticket/TicketDetail";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Box, Button, Chip, IconButton, Tooltip } from "@mui/material";
import { useInternalNotesMutation } from "../../Services/Messages/Messages";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";

interface IProps {
  data: any;
  closeStatusModal: any;
  refetch: any;
  refetchAuditTrail: any;
  selectedStatus: any;
  handleStatusUpdate: any; // Add this prop to handle status update
  refetchAuditTrailData: any;
  selectedAllStatus: any;
  storingTicketId: any;
  refetchAuditTrailForwardedData: any
}

const ChangeStatusModal = ({
  data,
  closeStatusModal,
  refetch,
  refetchAuditTrail,
  selectedStatus,
  handleStatusUpdate,
  refetchAuditTrailData,
  selectedAllStatus,
  storingTicketId,
  refetchAuditTrailForwardedData
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState<string>("");
  const [initialsShow, setInitialShow] = useState<boolean>(false);
  const [savingLoading, setSavingLoading] = useState<boolean>(false);
  const params = useParams();
  const uuid = params.uuid;

  const [updateStatus] = useUpdateUsernameMutation();
  const [trigger] = useInternalNotesMutation();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await updateStatus({
        id: storingTicketId ? storingTicketId : `${uuid}`,
        status_type: selectedStatus,
        status:
          selectedAllStatus.status_type === "wip" ? selectedAllStatus.id : null, // WIP
        closed_status:
          selectedAllStatus.status_type === "closed"
            ? selectedAllStatus.id
            : null, // closed
      });

      if (response.data) {
        setIsLoading(false); // Reset loading state
        toast.success("Status updated successfully!");
        handleStatusUpdate(); // Call the function to handle status update
        closeStatusModal();
        refetch();
        refetchAuditTrail();
        refetchAuditTrailData();
        refetchAuditTrailForwardedData()
      } else if (response.error) {
        // Extract error details from the response
        const { status, data }: any = response.error;

        if (data?.detail) {
          // Show detailed error messages dynamically
          data.detail.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });
        } else if (data?.status_type) {
          data?.status_type.forEach((message) => {
            toast.error(` ${message}`); // Display each error message
          });

        } else {
          console.log(data)
          toast.error(
            `Error ${status}: Something went wrong. Please try again.`
          );
        }

        setIsLoading(false); // Reset loading state
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      console.error(error); // Log the error
    }
    closeStatusModal();
  };

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    const filePromises = newFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileData = {
            file_name: file.name,
            file: e.target.result.split(",")[1], // Extracting Base64 part
          };
          resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      setFiles((prevFiles) => {
        return [...prevFiles, ...fileDataArray];
      });
    } catch (error) {
      toast.error("An error occurred while uploading files. Please try again.");
    }
  };

  const handleSaveInternlaNotes = async () => {
    setSavingLoading(true);
    const internalNoteObj = {
      attachments: files,
      content: value,
      ticket: storingTicketId ? storingTicketId : `${uuid}`,
    };
    try {
      if (value.length > 0) {

        const result = await trigger(internalNoteObj).unwrap();
        setInitialShow(true);
        setSavingLoading(false);
      } else {
        setInitialShow(true);
        setSavingLoading(false);
      }

    } catch (error) {
      toast.error("Failed to save note.");
      setSavingLoading(false);
    }
  };

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  console.log(files, "file");
  console.log(value, "fileValue");

  return (
    <div className="card-body">
      {!initialsShow ? (
        <>
          <div>
            <label className="fs-5 fw-bold">Internal Notes</label>
            <textarea
              className="form-control form-control-lg form-control-solid mb-2 mt-2"
              name="account_name"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              rows={4}
              placeholder="Enter internal notes here"
            />
            {files.length > 0 && (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.file_name}
                    onDelete={handleDeleteFile(file)}
                  />
                ))}
              </Box>
            )}

            <div className="d-flex justify-content-end">
              <Button
                component="label"
                className={`btn btn-active-light-primary btn-icon ${value ? "" : "disabled"
                  }`}
                title="You can add attachments for the following 
           file types: xls,pdf,csv,doc,jpg,png up to a maximum size of 15MB"
              >
                <i className="bi bi-paperclip align-self-center text-dark fs-2 mx-2 "></i>
                {/* Add Attachment */}
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleFileChange}
                  disabled={!value}
                />
              </Button>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-sm btn-white me-3"
                onClick={closeStatusModal}
                disabled={savingLoading}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-sm btn-primary"
                onClick={handleSaveInternlaNotes}
                disabled={savingLoading}
              >
                {savingLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="fs-5 fw-bold">
            Are you sure you want to change the status?
          </span>
          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-sm btn-white me-3"
              onClick={closeStatusModal}
              disabled={isLoading}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChangeStatusModal;
