import React from "react";
import { CloseButton } from "react-bootstrap";
import { useLeadInfoQuery } from "../../../Services/lead/Lead";

interface IProps {
  closeEditModal: () => void;
  id: any;
  leadTrailData: any;
}

const LeadTrailEditModal: React.FC<IProps> = ({
  closeEditModal,
  leadTrailData,
  id,
}) => {
  const { data: TrailData } = useLeadInfoQuery(
    {
      id: leadTrailData?.id,
      activity: leadTrailData?.lead_activity,
    },
    { skip: !leadTrailData }
  );

  //Format data and time
  const formatDateTime = (dateString: string) => {
    if (!dateString) return "";
    return new Intl.DateTimeFormat("en-IN", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    }).format(new Date(dateString));
  };

  return (
    <form>
      <div className="text-end">
        <CloseButton onClick={closeEditModal} />
      </div>
      <div className="text-center mb-4">
        <label className="form-label fs-4 fw-bolder text-dark card-title">
          Trail details
        </label>
      </div>

      <div className="w-100 mb-4">
        <label>Lead id</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_details?.id || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Created by</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_details?.created_by?.username || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Assigned</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_details?.assignee?.username || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Channel name</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_details?.channel_name || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Account name</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_details?.account_name || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Lead activity</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_activity || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Status type</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={TrailData?.[0]?.lead_details?.status_type || ""}
          disabled
        />
      </div>

      <div className="w-100 mb-4">
        <label>Trail created at</label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          autoComplete="off"
          value={formatDateTime(TrailData?.[0]?.created || "")}
          disabled
        />
      </div>
    </form>
  );
};

export default LeadTrailEditModal;
