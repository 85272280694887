import React, { useContext, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import AddMetaModal from './AddMetaModal'
import SidePanelItem from './SidePanelItem'
import { createInitials } from '../../utils/functions/createInitials'
import { useNavigate } from 'react-router-dom'
import { IChatbotState } from '../../utils/interfaces/IChatbotState'

interface IProps {
  // sidePanelOpened: boolean
  setState: any
  chatRoomId: number
  chatRoomMetaData: any
  markChatroomAsResolved: (chatRoomId: number, status: string, remark?: string) => Promise<unknown>
  getAssignedChatrooms: any
  setTotalUnreadAssignedChats: any
  setUpdateChatRoomsFlag: any
  chatRoomsArrayRef: any
  type: 'Unassigned' | 'Assigned' | 'Resolved' | 'Chat-Report'
  data2: any
  sendMessageToEndUser: any
  currentUser: any
}

const SidePanel: React.FC<IProps> = ({
  // sidePanelOpened,
  setState,
  chatRoomId,
  chatRoomMetaData: data,
  markChatroomAsResolved,
  getAssignedChatrooms,
  setTotalUnreadAssignedChats,
  setUpdateChatRoomsFlag,
  chatRoomsArrayRef,
  type,
  data2,
  sendMessageToEndUser,
  currentUser
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAddMetaOpen, setIsAddMetaOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-30%, -60%)'
    }
  }

  const openAddMeta = () => {
    setIsAddMetaOpen(true)
  }

  const afterOpenAddMeta = () => {
    // subtitle.style.color = '#f00'
  }

  const closeAddMeta = () => {
    setIsAddMetaOpen(false)
  }
  const data1 = {
    content: {
      id: 27,
      message: 'Rating and Review',
      metadata: {
        payload: [
          {
            buttons: [
              {
                name: 'Submit review',
                action: {
                  type: 'null',
                  trigger: 26
                },
                message: 'Response Submitted '
              }
            ]
          }
        ],
        templateId: 30
      },
      chatId: chatRoomId,
      side: 'dash-user'
    },
    side: 'dash-user',
    time: new Date().toUTCString(),
    sender: {
      type: 'dash-user',
      id: currentUser.id,
      name: currentUser.name
    }
  }
  const handleChatStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    const allStatus = {
      1: 'ACTIVE',
      2: 'RESOLVED',
      3: 'SPAM'
    }
    const currentStatus = (allStatus as any)[value]
    markChatroomAsResolved(chatRoomId, currentStatus).then((res: any) => {
      setIsLoading(false)
      if (!!res && res.messageResponse === 'SUCCESS') {
        toast.success('Successfully updated status')
        sendMessageToEndUser(data1)
        getAssignedChatrooms().then((resAssigned: any) => {
          setIsLoading(false)
          if (!!resAssigned) {
            console.warn(resAssigned)
            const assignedChatrooms = resAssigned.assigned_chatrooms
            const assignedChatRoomsArray = assignedChatrooms[0]
              // if (assignedChatrooms[0] !== null) {
              // assignedChatRoomsArray = assignedChatrooms[0]
              .filter((chatRoom: any) => !!chatRoom)
              .map((chatRoom: any) => {
                let initials

                if (chatRoom.chatRoomMeta && chatRoom.chatRoomMeta.length > 0) {
                  const metaName = chatRoom.chatRoomMeta.find(
                    (metaData: any) => metaData.name === 'Name'
                  )
                  if (metaName) {
                    initials = createInitials(metaName.value)
                  } else if (chatRoom.id) {
                    initials = createInitials(chatRoom.id)
                  } else {
                    initials = createInitials('Unnamed')
                  }
                } else if (chatRoom.id) {
                  initials = createInitials(chatRoom.id)
                } else {
                  initials = createInitials('Unnamed')
                }
                return {
                  ...chatRoom,
                  initials
                }
              })
              .sort((a: any, b: any) => {
                // Sort by putting last chatRoomID in first
                return parseInt(b.id) - parseInt(a.id)
              })
            // }
            console.log(assignedChatRoomsArray)
            setTotalUnreadAssignedChats(assignedChatRoomsArray.length)
            chatRoomsArrayRef.current = assignedChatRoomsArray
            setUpdateChatRoomsFlag(true)
            console.log('Set to call useEffect to load assigned chatRooms')

            setState((prevState: IChatbotState) => ({
              ...prevState,
              // panelBasedConversation: prevState?.panelBasedConversation?.filter(
              //   (conversation: any) => conversation?.chatRoomId !== chatRoomId
              // )
            }))

            // Remove chatRoomId from the URL if status is RESOLVED or SPAM
            if (currentStatus === 'RESOLVED' || currentStatus === 'SPAM') {
              const searchParams = new URLSearchParams(window.location.search)
              searchParams.delete('chatRoomId')
              navigate(`${window.location.pathname}?${searchParams.toString()}`)
              // For React Router v6, use: navigate(`${window.location.pathname}?${searchParams.toString()}`);
            }
          }
        })
      } else {
        toast.error('Unable to update status')
      }
    })
  }

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = String(date.getFullYear()).slice(-2) // Get last two digits of the year
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year}, ${hours}:${minutes}`
  }

  const handleEndDate = () => {
    if (
      !!data2?.sessionsByChatRoomId[0]?.endDate &&
      data2?.sessionsByChatRoomId[0]?.endDate.length > 0
    ) {
      return formatDate(data2?.sessionsByChatRoomId[0]?.endDate)
    } else {
      return 'Not Logged Yet'
    }
  }

  const handleStartDate = () => {
    if (
      !!data2?.sessionsByChatRoomId[0]?.startDate &&
      data2?.sessionsByChatRoomId[0]?.startDate.length > 0
    ) {
      return formatDate(data2?.sessionsByChatRoomId[0]?.startDate)
    } else {
      return 'Not Logged Yet'
    }
  }

  return (
    // <div className='card mb-4 border border-1' id='chat_side_panel'>
    //   <div className='card-header d-flex justify-content-center align-items-center'>
    //     <div className='fs-4 fw-bolder'>Customer Information</div>
    //     {/* <i className='bi bi-pencil-fill mx-n5 fs-3 px-4' onClick={openAddMeta}></i> */}
    //   </div>
    //   <div className='card-body px-9 pt-2' id='chat_side_panel_body'>
    //     <div className='scroll-y mh-700px scroll-y me-n5 pe-5'>
    //       <div className='text-start mt-3 fs-6 d-flex'>
    //         <p className='text-gray-700 fw-bold' style={{ width: '35%' }}>
    //           ChatRoom Id:
    //         </p>
    //         <p className='fs-6 text-black'>{chatRoomId}</p>
    //       </div>
    //       {type === 'Resolved' && (
    //         <>
    //           <div className='text-start mt-3 fs-6 d-flex'>
    //             <p className='text-gray-700 fw-bold' style={{ width: '35%' }}>
    //               Bot Name:
    //             </p>
    //             <p className='fs-6 text-black'>{data2?.sessionsByChatRoomId[0]?.botName}</p>
    //           </div>
    //           <div className='text-start mt-3 fs-6 d-flex'>
    //             <p className='text-gray-700 fw-bold' style={{ width: '35%' }}>
    //               Agent Name:
    //             </p>
    //             <p className='fs-6 text-black'>
    //               {data2?.sessionsByChatRoomId[0]?.agentName || 'No agent assigned'}
    //             </p>
    //           </div>
    //           <div className='text-start mt-3 fs-6 d-flex'>
    //             <p className='text-gray-700 fw-bold' style={{ width: '35%' }}>
    //               Start Date:
    //             </p>
    //             <p className='fs-6 text-black'>
    //               {formatDate(data2?.sessionsByChatRoomId[0]?.startDate)}
    //             </p>
    //           </div>
    //           <div className='text-start mt-3 fs-6 d-flex'>
    //             <p className='text-gray-700 fw-bold' style={{ width: '35%' }}>
    //               End Date:
    //             </p>
    //             <p className='fs-6 text-black'>{handleEndDate()}</p>
    //           </div>
    //         </>
    //       )}

    //       <div className='my-10'>
    //         <label className='form-label fw-bold'>Chat Status:</label>
    //         <div className='w-100 position-relative'>
    //           <select
    //             className='form-select form-select-solid'
    //             role='button'
    //             defaultValue={type === 'Resolved' ? '2' : '1'}
    //             onChange={handleChatStatus}
    //             disabled={type === 'Resolved'}
    //           >
    //             <option value='1'>Active</option>
    //             <option value='2'>Resolved</option>
    //             <option value='3'>Mark as Spam</option>
    //           </select>
    //         </div>
    //       </div>

    //       {data && (
    //         <form className='fs-4 text-gray-900 text-left fw-bold mt-3 mb-1'>
    //           {!!data.chatRoomMetaByChatRoomId &&
    //             data.chatRoomMetaByChatRoomId.length > 0 &&
    //             data.chatRoomMetaByChatRoomId.map(
    //               (item: { id: string; name: string; value: string }, index: number) => (
    //                 <SidePanelItem
    //                   key={new Date().getTime() * index}
    //                   type={type}
    //                   item={item}
    //                   chatRoomId={chatRoomId}
    //                   setIsLoading={setIsLoading}
    //                 />
    //               )
    //             )}
    //           <p role='button' onClick={openAddMeta} className='btn btn-sm btn-secondary my-4'>
    //             Add Data
    //           </p>
    //           <ReactModal
    //             isOpen={isAddMetaOpen}
    //             onAfterOpen={afterOpenAddMeta}
    //             onRequestClose={closeAddMeta}
    //             style={customModalStyles}
    //             contentLabel='Add Meta Data'
    //           >
    //             <AddMetaModal
    //               type={type}
    //               chatRoomId={chatRoomId}
    //               setIsLoading={setIsLoading}
    //               closeAddMeta={closeAddMeta}
    //             />
    //           </ReactModal>
    //           <br />
    //           {/* <button
    //             type='submit'
    //             className='btn btn-sm btn-secondary mb-5'
    //             onClick={(e) => e.preventDefault()}
    //           >
    //             Send Transcript
    //           </button> */}
    //         </form>
    //       )}
    //     </div>
    //   </div>
    // </div>
    <div className='card card-flush mb-2'>
      <div className='card card-flush'>
        <div
          className='card-header align-items-center mb-1 '
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 8px 0px',
            borderRadius: '6px',
            minHeight: '60px'
          }}
        >
          <div className='fs-4 '>Customer Information</div>
          {/* <div onClick={openAddMeta}>
            <i className='bi bi-pencil-fill mx-n5 fs-3'></i>
          </div> */}
        </div>
      </div>
      <div className='card card-flush'>
        <div
          className='card-header justify-content-start align-items-center px-9'
          style={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            borderRadius: '6px',
            minHeight: '60px'
          }}
        >
          <div className='text-gray-700 fs-5 fw-bold' style={{ width: '50%' }}>
            Chat Room Id:
          </div>
          <div className='fs-6 text-black'>{chatRoomId}</div>
        </div>
      </div>
      {(type === 'Resolved' || type === 'Chat-Report') && (
        <>
          <div className='card card-flush'>
            <div
              className='card-header justify-content-start align-items-center'
              style={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '6px',
                minHeight: '60px'
              }}
            >
              <div className='text-gray-700 fs-5 fw-bold' style={{ width: '50%' }}>
                Bot Name:
              </div>
              <div className='fs-6 text-black'>
                {data2?.sessionsByChatRoomId[0]?.botName || 'Not Logged Yet'}
              </div>
            </div>
          </div>
          <div className='card card-flush'>
            <div
              className='card-header justify-content-start align-items-center'
              style={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '6px',
                minHeight: '60px'
              }}
            >
              <div className='text-gray-700 fs-5 fw-bold' style={{ width: '50%' }}>
                Agent Name:
              </div>
              <div className='fs-6 text-black'>
                {' '}
                {data2?.sessionsByChatRoomId[0]?.agentName ||
                  data2?.sessionsByChatRoomId[0]?.assignedAgentId ||
                  'No Agent Assigned'}
              </div>
            </div>
          </div>
          <div className='card card-flush'>
            <div
              className='card-header justify-content-start align-items-center'
              style={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '6px',
                minHeight: '60px'
              }}
            >
              <div className='text-gray-700 fs-5 fw-bold' style={{ width: '50%' }}>
                Start Date:
              </div>
              <div className='fs-6 text-black'>{handleStartDate()}</div>
            </div>
          </div>
          <div className='card card-flush'>
            <div
              className='card-header justify-content-start align-items-center'
              style={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '6px',
                minHeight: '60px'
              }}
            >
              <div className='text-gray-700 fs-5 fw-bold' style={{ width: '50%' }}>
                End Date:
              </div>
              <div className='fs-6 text-black'>{handleEndDate()}</div>
            </div>
          </div>
        </>
      )}
      <div className='card'>
        <div
          className='d-flex flex-column justify-content-start align-items-center px-9 py-4'
          style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: '6px' }}
        >
          <label className='text-gray-700 text-start fs-5 fw-bold' style={{ width: '100%' }}>
            Chat Status:
          </label>
          <div className='w-100 position-relative'>
            <select
              className='form-select form-select-solid'
              role='button'
              defaultValue={type === 'Resolved' || type === 'Chat-Report' ? '2' : '1'}
              onChange={handleChatStatus}
              disabled={type === 'Resolved' || type === 'Chat-Report' || chatRoomId === 0}
            >
              <option value='1'>Active</option>
              <option value='2'>Resolved</option>
              <option value='3'>Mark as Spam</option>
            </select>
          </div>
        </div>
      </div>
      {data && (
        <div className='fs-4 text-gray-900 text-left fw-bold'>
          {!!data.chatRoomMetaByChatRoomId &&
            data.chatRoomMetaByChatRoomId.length > 0 &&
            data.chatRoomMetaByChatRoomId.map(
              (item: { id: string; name: string; value: string }, index: number) => (
                <SidePanelItem
                  key={new Date().getTime() * index}
                  type={type}
                  item={item}
                  chatRoomId={chatRoomId}
                  setIsLoading={setIsLoading}
                />
              )
            )}
          {type !== 'Chat-Report' && chatRoomId > 0 && (
            <div
              className='card'
              style={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '6px',
                minHeight: '60px'
              }}
            >
              <p
                role='button'
                onClick={openAddMeta}
                className='btn btn-sm btn-secondary my-4 mx-8'
                style={{ width: '30%' }}
              >
                Add Data
              </p>
            </div>
          )}
          <ReactModal
            isOpen={isAddMetaOpen}
            onAfterOpen={afterOpenAddMeta}
            onRequestClose={closeAddMeta}
            style={customModalStyles}
            contentLabel='Add Meta Data'
          >
            <AddMetaModal
              type={type}
              chatRoomId={chatRoomId}
              setIsLoading={setIsLoading}
              closeAddMeta={closeAddMeta}
            />
          </ReactModal>
        </div>
      )}
    </div>
  )
}

export default SidePanel
